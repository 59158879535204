import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Input, Divider, Image, Button, Icon } from "semantic-ui-react";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetBrandingQuery } from "@api/apiV6";
import { useFeatures } from "flagged";

import axios from "axios";
import { baseUrl } from "@api/apiV6";

import { Div } from "@components/Generics.react";
import AuthLayout from "@layouts/AuthLayout.react";
import Carousel from "@components/Carousel.react";
import { darkStyle } from "@utilities/helpers";
import { settings } from "@config/settings/app.settings";

export default function Register(props) {
    const { team } = useParams();
    const { data: branding } = useGetBrandingQuery(team || "Company");

    const [username, setUsername] = useState("");
    const [validUsername, setValidUsername] = useState(false);
    const [unameHelp, setUnameHelp] = useState(false);
    const [fnameHelp, setFnameHelp] = useState(false);
    const [passwordHelp, setPasswordHelp] = useState(false);
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(false);
    const [validPassword, setValidPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    // const [validPassword, setValidPassword] = useState(false);
    const [details, setDetails] = useState({});
    const detailParams = settings.detailParams;
    const features = useFeatures();
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation("common");

    const wPageHt = `${window.innerHeight}px`;
    const isPortrait = window.innerHeight > window.innerWidth;

    useEffect(() => {
        (async () => {
            if (username.length > 3) {
                const response = await axios({
                    method: "get",
                    url: `${baseUrl}api/v3/users/check/${username}`,
                });
                response.data.available
                    ? setValidUsername(true)
                    : setValidUsername(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username]);
    useEffect(() => {
        // eslint-disable-next-line
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
            ? setValidEmail(true)
            : setValidEmail(false);
    }, [email]);

    useEffect(() => {
        // eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
            password
        )
            ? setValidPassword(true)
            : setValidPassword(false);
    }, [password]);

    const tryRegister = async () => {
        const nameSplit = fullName.split(" ");
        let formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);
        formData.append("email", email);
        formData.append("first_name", nameSplit[0]);
        formData.append("last_name", nameSplit[1] || " ");
        formData.append("creatorLicense", team == null && features.tenanting.multiTenanted);
        formData.append("details", JSON.stringify(details));
        formData.append(
            "team",
            team == null && features.tenanting.multiTenanted
                ? username.replace(/[&\\#, +()$~%.'":*?<>{}]/g, "_")
                : team || "Company"
        );

        try {
            const signupResponse = await axios({
                method: "post",
                url: `${baseUrl}api/v3/auth/register`,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            });
            if (signupResponse.status === 201) {
                if (features.tenanting.teamCourses) {
                    await axios({
                        method: "post",
                        url: `${baseUrl}api/v3/users/${signupResponse.data._id}/team_enrollment`,
                    });
                }
                navigate("/builder/registered");
            }
        } catch (error) {
            console.log(error);
            setError(error.response.data.message);
        }
    };

    const checkStyle = { float: "right", margin: "-30px 10px 0 0" };

    const registrationBox = (
        <Div fluid superpad ht={wPageHt} flex centered maxWd="480px">
            <Div wd="60%" gutter>
                <Image src={branding?.image} />
            </Div>
            <Div fluid white fullHt superpad rounded>
                <Div massive altText fluid center-align gutter>
                    SIGN UP
                </Div>
                {error && (
                    <Div padded fluid danger gutter>
                        {error}
                    </Div>
                )}
                <Div fluid gutter>
                    <Input
                        fluid
                        label={t("auth.registration.usernameLabel")}
                        placeholder={t("auth.registration.usernamePlaceholder")}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        onFocus={() => setUnameHelp(true)}
                        onBlur={() => setUnameHelp(false)}
                    />
                    {!validUsername && username?.length > 3 && (
                        <Div relaxed danger fluid>
                            <i className="fa fa-times" /> &nbsp;{" "}
                            {t("auth.registration.usernameUnavailable")}
                        </Div>
                    )}
                    {validUsername && (
                        <Div txtColor="success" style={checkStyle}>
                            <i className="fa fa-check-circle" />
                        </Div>
                    )}
                    {unameHelp && (
                        <Div charcoal padded small italics>
                            {t("auth.registration.usernameHelp")}
                        </Div>
                    )}
                </Div>
                <Div fluid gutter>
                    <Input
                        fluid
                        label={t("auth.registration.nameLabel")}
                        placeholder={t("auth.registration.namePlaceholder")}
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        onFocus={() => setFnameHelp(true)}
                        onBlur={() => setFnameHelp(false)}
                    />
                    {fnameHelp && (
                        <Div charcoal padded small italics>
                            {t("auth.registration.nameHelp")}
                        </Div>
                    )}
                </Div>
                <Div fluid gutter>
                    <Input
                        fluid
                        label="Email"
                        type="email"
                        placeholder="Where can we contact you?"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {!validEmail && email?.length > 0 && (
                        <Div relaxed danger fluid>
                            <i className="fa fa-times" /> &nbsp; Malformed email
                            address.
                        </Div>
                    )}
                    {validEmail && (
                        <Div txtColor="success" style={checkStyle}>
                            <i className="fa fa-check-circle" />
                        </Div>
                    )}
                </Div>
                <Div fluid gutter>
                    <Input
                        fluid
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Input Password"
                        onChange={(e) => setPassword(e.target.value)}
                        icon={
                            <Icon
                                name={showPassword ? "eye slash" : "eye"}
                                link
                                onClick={() => setShowPassword(!showPassword)}
                            />
                        }
                        onFocus={() => setPasswordHelp(true)}
                        onBlur={() => setPasswordHelp(false)}
                    />
                    {passwordHelp && (
                        <Div charcoal padded small italics>
                            {t("auth.registration.passwordHelp")}
                        </Div>
                    )}
                    {!validPassword && password?.length > 0 && (
                        <Div relaxed danger fluid>
                            <i className="fa fa-times" /> &nbsp; Password is
                            invalid
                        </Div>
                    )}
                </Div>
                {settings.functionality.registrationDetailParams && <Div fluid gutter>
                    <Divider horizontal>User Additional Details</Divider>
                        {_.map(_.keys(detailParams), (dp, idx) => {
                            return (
                            <Div fluid snug key={`detail-param-${idx}`}>
                                <Input
                                    fluid
                                    label={detailParams[dp].name}
                                    value={details[dp]}
                                    onChange={(e) =>
                                        setDetails({
                                        ...details,
                                        [dp]: e.target.value,
                                        })
                                    }
                                />
                            </Div>
                            );
                        })}
                </Div>}
                <Div fluid gutter>
                    <Button
                        fluid
                        primary
                        content="SUBMIT"
                        onClick={tryRegister}
                        disabled={
                            username.length < 3 ||
                            !validUsername ||
                            !validEmail ||
                            password.length < 3
                        }
                    />
                    <Div padTop flex column gapped clickable>
                        {settings.socialauth.length > 0 &&
                            _.map(settings.socialauth, (btn, idx) => {
                                return (
                                    <Image
                                        key={`social-auth-${idx}`}
                                        fluid
                                        src={btn.image}
                                    />
                                );
                            })}
                    </Div>
                    <Divider horizontal>OR</Divider>
                    <Div center-align>
                        <NavLink
                            to={team == null ? "/login" : `/${team}/login`}
                        >
                            <Button success={+true} content={t("auth.login")} />
                        </NavLink>
                    </Div>
                </Div>
            </Div>
        </Div>
    );

    const hasLoggedIn = window.localStorage.getItem("firstLogin") != null;

    // ========================= Render Function =================================
    if (
        team == null ||
        branding == null ||
        branding.cards == null ||
        branding.cards?.length === 0
    ) {
        return (
            <AuthLayout
                title={`Register on ${t("appName")}`}
                team={team}
                teamLogin={true}
            >
                <Div
                    fluid
                    flex
                    ht={wPageHt}
                    maxWd={isPortrait ? "480px" : "100%"}
                    spaceAround
                >
                    <Div wd="40%" megapad>
                        <Image
                            fluid
                            src={
                                "/assets/images/configurable/full-logo-inverted.png"
                            }
                        />
                    </Div>
                    <Div wd="480px">{registrationBox}</Div>
                </Div>
            </AuthLayout>
        );
    } else {
        return (
            <AuthLayout
                title={`Register on ${t("appName")}`}
                team={team}
                teamLogin={true}
            >
                <Carousel
                    lazyLoad
                    slidesToShow={1}
                    dots
                    initialSlide={hasLoggedIn ? branding.cards?.length : 0}
                >
                    <Div
                        superpad
                        fluid
                        flex
                        ht={wPageHt}
                        maxWd="480px"
                        centered
                    >
                        <Image src={branding?.image} />
                    </Div>
                    {_.map(branding?.cards, (page, idx) => {
                        return (
                            <Div
                                fluid
                                superpad
                                large
                                ht={wPageHt}
                                maxWd="480px"
                                centered
                                key={`wpage-${idx}`}
                                style={{ color: branding.textColor }}
                            >
                                <Div rounded noOverflow gutter>
                                    <Image fluid src={page.image} />
                                </Div>
                                <Div
                                    big
                                    bold
                                    gutter
                                    gapTop
                                    style={darkStyle(
                                        branding.mainColor,
                                        "to top right"
                                    )}
                                    medpad
                                    rounded
                                >
                                    {page.header}
                                </Div>
                                <Div trench>{page.content}</Div>
                            </Div>
                        );
                    })}
                    {registrationBox}
                </Carousel>
            </AuthLayout>
        );
    }
}
