import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSubmitTicketMutation } from "@api/apiV6";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { toast } from "react-toastify";
import {
    loadCaptchaEnginge,
    LoadCanvasTemplate,
    validateCaptcha,
} from "react-simple-captcha";

import { Dropdown, Input, TextArea, Icon as XIcon } from "semantic-ui-react";
import { Div, Icon, Button } from "@components/Generics.react";
import { supportSchema } from "@schemas/supportSchema";
export default function TicketForm(props) {
    const user = useSelector((state) => state.auth.user);
    const [submitTicket] = useSubmitTicketMutation();
    const { t } = useTranslation("common");

    const [tktCategory, setTktCategory] = useState("");
    const [tktProblem, setTktProblem] = useState("");
    const [tktDetails, setTktDetails] = useState("");
    const [captchaVerified, setCaptchaVerified] = useState(false);

    useEffect(() => {
        loadCaptchaEnginge(6);
    }, []);

    function verifyCaptcha() {
        let user_captcha_value =
            document.getElementById("user_captcha_input").value;

        if (validateCaptcha(user_captcha_value, false)) {
            setCaptchaVerified(true);
        } else {
            alert("Captcha Does Not Match");
        }
    }

    async function handleSubmit() {
        if (captchaVerified) {
            await submitTicket({
                email: user.email,
                category: tktCategory,
                problem: tktProblem,
                description: tktDetails,
            });
            toast(t("helpdesk.submitted"));
        } else {
            toast(t("helpdesk.error"));
        }
        setTktCategory("");
        setTktProblem("");
        setTktDetails("");
        setCaptchaVerified(false);
        loadCaptchaEnginge(6);
    }

    const categoryOptions = _.map(supportSchema.ticketCategory, (cat) => ({
        key: cat,
        value: cat,
        text: cat,
    }));
    const problemOptions = _.map(supportSchema.problemCategory, (cat) => ({
        key: cat,
        value: cat,
        text: cat,
    }));

    const requiredAsterisk = <span style={{ color: "red" }}>*</span>;

    // ========================= Render Function =================================
    return (
        <Div basepad white fullht>
            <Div clearfix>
                <Div giant float-left rimmed>
                    <Icon name="headphones" />
                </Div>
                <Div>
                    <Div large altText uppercase gapBottom>
                        {t("helpdesk.canWeHelp")}
                    </Div>
                    <Div>{t("helpdesk.helpText")}</Div>
                </Div>
            </Div>
            <Div vapor basepad>
                {/* <Div gutter italics>
                    {t("helpdesk.allFields")}
                </Div> */}
                <Div gutter>
                    {t("helpdesk.selectCategory")}
                    {requiredAsterisk}
                    <Dropdown
                        placeholder={t("helpdesk.selectCategory")}
                        fluid
                        selection
                        options={categoryOptions}
                        onChange={(e, { value }) => setTktCategory(value)}
                        value={tktCategory}
                        required
                    />
                </Div>

                <Div gutter>
                    {t("helpdesk.selectProblem")}
                    {requiredAsterisk}
                    <Dropdown
                        placeholder={t("helpdesk.selectProblem")}
                        fluid
                        selection
                        options={problemOptions}
                        onChange={(e, { value }) => setTktProblem(value)}
                        value={tktProblem}
                        required
                    />
                </Div>

                <Div>
                    {t("helpdesk.tellAbout")}
                    {requiredAsterisk}
                    <TextArea
                        name="description"
                        rows="2"
                        required
                        onChange={(e) => setTktDetails(e.target.value)}
                        value={tktDetails}
                        style={{ width: "100%" }}
                    />
                </Div>
                {captchaVerified ? (
                    <Div medpad ash>
                        <XIcon inverted circular success name="check" />
                        {t("helpdesk.isVerified")}
                    </Div>
                ) : (
                    <Div medpad ash>
                        <Div gapBottom>{t("helpdesk.captcha")}</Div>
                        <LoadCanvasTemplate />
                        <Div gapTop>
                            <Input
                                type="text"
                                placeholder={t("helpdesk.captcha")}
                                action
                            >
                                <input id="user_captcha_input" />
                                <Button
                                    secondary
                                    size="small"
                                    onClick={verifyCaptcha}
                                >
                                    {t("helpdesk.verify")}
                                </Button>
                            </Input>
                        </Div>
                    </Div>
                )}
                <Div gapTop>
                    <Button
                        primary
                        disabled={
                            tktCategory === "" ||
                            tktProblem === "" ||
                            tktDetails === "" ||
                            !captchaVerified
                        }
                        onClick={handleSubmit}
                    >
                        {t("helpdesk.submit")}
                    </Button>
                </Div>
            </Div>
        </Div>
    );
}
