import React, { useState } from "react";
import { useGetTicketListQuery, useUpdateTicketMutation } from "@api/apiV6";
import { useTranslation } from "react-i18next";
import { desnaked } from "@utilities/helpers";
import { toast } from "react-toastify";
import _ from "lodash";

import ReactTable from "react-table-v6";

import { Icon, Loader, Popup } from "semantic-ui-react";
import { Div, Button } from "@components/Generics.react";
export default function AdminTicketsTable(props) {
    const { data: ticketdata, isSuccess, refetch } = useGetTicketListQuery();
    const { t } = useTranslation("common");
    const [ticket, setTicket] = useState(null);
    const [updateTicket] = useUpdateTicketMutation();
    const showData =
        isSuccess &&
        ticket &&
        _.pick(_.find(ticketdata, { _id: ticket }), [
            "id",
            "email",
            "createdAt",
            "category",
            "problem",
            "description",
            "status",
        ]);

    async function tryUpdate() {
        const updateResponse = await updateTicket({
            id: ticket,
            status: "Resolved",
        });
        if (updateResponse) {
            toast("Ticket Resolved");
        }
    }

    const fullColumns = [
        {
            Header: t("helpdesk.createdAt"),
            accessor: "createdAt",
            Cell: (row) => new Date(row.value).toLocaleDateString("en-IN"),
            maxWidth: 90,
        },
        {
            Header: "Status",
            accessor: "status",
            width: 100,
        },
        {
            Header: "User",
            accessor: "email",
        },
        {
            Header: t("helpdesk.priority"),
            accessor: "priority",
            maxWidth: 50,
        },
        {
            Header: t("helpdesk.problem"),
            accessor: "description",
        },
        {
            Header: "",
            accessor: "category",
            Cell: (row) => (
                <Popup
                    position="right center"
                    on="hover"
                    trigger={
                        <Div
                            txtMain
                            clickable
                            onClick={() => setTicket(row.original._id)}
                        >
                            <Icon name="id card" />
                        </Div>
                    }
                >
                    <Div bold>{row.original.category}</Div>
                    <Div small italics gapBottom>
                        {row.original.problem}
                    </Div>
                    <Popup.Content>{row.original.description}</Popup.Content>
                </Popup>
            ),
            maxWidth: 50,
        },
    ];

    function getDataTable(data) {
        return _.map(_.toPairs(data), (row, idx) => {
            return (
                <Div fluid flex key={`user-det-${idx}`}>
                    <Div
                        padded
                        ash={idx % 2 === 0}
                        smoke={idx % 2 === 1}
                        wd="100px"
                        title={row[0].length > 10 ? desnaked(row[0]) : null}
                    >
                        {desnaked(row[0])}
                    </Div>
                    <Div
                        padded
                        ivory
                        wd="calc(100% - 100px)"
                        noOverflow
                        title={_.isString(row[1]) ? row[1] : null}
                    >
                        {row[1] === true
                            ? "Yes"
                            : row[1] === false
                            ? "No"
                            : row[1]}
                    </Div>
                </Div>
            );
        });
    }
    // ========================= Render Function =================================
    return (
        <Div basepad white fullht flex top-aligned>
            <Div wd="calc(100% - 360px)" fullht basepad>
                <Div big bold uppercase gutter>
                    <Div float-right clickable tiny>
                        <Icon
                            name="refresh"
                            circular
                            inverted
                            onClick={refetch}
                        />
                    </Div>
                    Ticket Center
                </Div>
                <Div ht="95%" autoOverflowY>
                    {isSuccess ? (
                        <ReactTable
                            key={`table`}
                            data={_.orderBy(
                                ticketdata,
                                ["createdAt"],
                                ["desc"]
                            )}
                            filterable
                            columns={fullColumns}
                            pageSizeOptions={[10, 15, 30]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                        />
                    ) : (
                        <Loader />
                    )}
                </Div>
            </Div>
            <Div wd="360px" fullht basepad ash>
                {ticket == null ? (
                    <Div fullht basepad vapor flex>
                        Click on the icon to select a ticket
                    </Div>
                ) : (
                    <Div white fullht padded>
                        <Div bold gutter padded>
                            Viewing Ticket
                        </Div>
                        {getDataTable(showData)}
                        <Button
                            primary
                            fluid
                            content="Close Ticket"
                            onClick={tryUpdate}
                        />
                    </Div>
                )}
            </Div>
        </Div>
    );
}
