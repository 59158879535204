// Import from NPM
// -------------------------------------
import React from "react";
import { Div } from "@components/Generics.react";
import { Checkbox } from "semantic-ui-react";

export default function SettingsToggle(props) {
    const { deck, setDeck, targetKey, label } = props;
    // ========================= Render Function =================================
    return (
        <Div compact>
            <Checkbox
                toggle
                label={label}
                checked={deck[targetKey]}
                onChange={() =>
                    setDeck({
                        ...deck,
                        ...{ [targetKey]: !deck[targetKey] },
                    })
                }
            />
        </Div>
    );
}
