import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { Div, Button } from "@components/Generics.react";
import Accordion from "@components/Accordion.react";
import { Image, Input } from "semantic-ui-react";
import ArticleCard from "@components/ArticleCard.react";

export default function FaqHome(props) {
    const { header, description, tags, image, subPage = false } = props;
    const allArticles = useSelector((state) => state.articles);
    const [searchText, setSearchText] = useState("");
    const navigate = useNavigate();
    const { t } = useTranslation("common");
    const isPortrait = window.innerHeight > window.innerWidth;

    const proppedTags = _.filter(
        tags,
        (tag) => allArticles.taggedList[tag]?.articles.length > 0
    );

    const selectedArticles = useMemo(
        () =>
            _.map(
                _.uniqBy(
                    _.flatten(
                        _.map(
                            proppedTags,
                            (tag) => allArticles.taggedList[tag]?.articles
                        )
                    ),
                    "_id"
                ),
                (article) => ({
                    identifier: article.identifier,
                    title: article.name.toUpperCase(),
                    content: article.description,
                    image: article.image,
                })
            ),
        [proppedTags, allArticles]
    );
    const searchedArticles = useMemo(
        () =>
            searchText === ""
                ? null
                : _.filter(
                      allArticles.list,
                      (article) =>
                          _.includes(
                              _.toLower(article.name),
                              _.toLower(searchText)
                          ) &&
                          _.includes(
                              ["productcarddeck", "productgriddeck"],
                              article.category
                          )
                  ),
        [searchText, allArticles]
    );

    // ========================= Render Function =================================
    return (
        <Div>
            {!subPage && (
                <Div>
                    <Div fluid relative rimmed>
                        <Div rounded noOverflow>
                            <Image fluid src={image} />
                        </Div>
                        <Div
                            absolute
                            big
                            bold
                            uppercase
                            topRounded
                            className="root-layout-header"
                        >
                            {header}
                        </Div>
                    </Div>
                    <Div white padSides gapBottom>
                        <Div gapTop gutter>
                            {description}
                        </Div>
                    </Div>
                </Div>
            )}
            <Div medpad ash>
                <Input
                    fluid
                    placeholder={t("builder.dic.searchWithin")}
                    icon={{ name: "search" }}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </Div>
            {searchText === "" ? (
                <Div ash medpad big>
                    <Accordion
                        fluid
                        compact
                        allOpen
                        panels={_.map(selectedArticles, (art, idx) => {
                            return {
                                key: `art-section-${idx}`,
                                title: art.title,
                                content: (
                                    <Div
                                        key={`carousel-index-${idx}`}
                                        smoke={!isPortrait}
                                        gutter={!isPortrait}
                                        onClick={() =>
                                            navigate(
                                                `/content/${tags[0]}/${art?.identifier}`
                                            )
                                        }
                                    >
                                        <Image src={art.image} />
                                        <Div ivory medpad big>
                                            {art.content}
                                        </Div>
                                        <Button
                                            fluid
                                            primary
                                            content={t(
                                                "home.productsHome.buttonText"
                                            )}
                                        />
                                    </Div>
                                ),
                            };
                        })}
                    />
                </Div>
            ) : (
                <Div basepad>
                    {_.map(searchedArticles, (article, idx) => {
                        return (
                            <ArticleCard
                                key={`article-${article._id}-${idx}`}
                                article={article}
                            />
                        );
                    })}
                </Div>
            )}
        </Div>
    );
}
