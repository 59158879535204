import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Input } from "semantic-ui-react";
import { Div, Image } from "@components/Generics.react";

import { useSendUserSelfUpdateMutation } from "@api/apiV6";

import MasterLayout from "@layouts/MasterLayout.react";
import AvatarCreator from "../user/AvatarCreator.react";
import RecommendedArticles from "@components/RecommendedArticles.react";

export default function GuestHome(props) {
    const user = useSelector((state) => state.auth.user);
    const redirectPath = localStorage.getItem("redirectPath");

    const { t } = useTranslation("common");
    const navigate = useNavigate();
    const [updateProfile] = useSendUserSelfUpdateMutation();

    const [avatarConfig, setAvatarConfig] = useState(null);
    const [first, setFirst] = useState(user?.first_name);
    const [last, setLast] = useState(user?.last_name);

    function changeAvatar(avatarData) {
        setAvatarConfig(avatarData);
    }
    const handleUpdate = async () => {
        try {
            await updateProfile({
                id: user._id,
                first_name: first,
                last_name: last,
            });
            await updateProfile({
                id: user._id,
                image: "remove",
                avatarConfig: avatarConfig,
            });
            navigate(redirectPath);
        } catch (error) {
            console.log(error);
        }
    };

    const pageContent = (
        <Div fluid top-align>
            <Div white superpad dropShadow rounded>
                <Div flex fuid spaceBetween>
                    <Div wd="45%">
                        <Div headline bold uppercase gutter>
                            Welcome!
                        </Div>
                        <Div gutter>
                            Please give us your name to start off. And edit your
                            avatar to reflect your own personality...
                        </Div>
                        <Div>
                            <Div uppercase bold gutter>
                                {t("navbar.editName")}
                            </Div>
                            <Div fluid spaceBottom>
                                <Input
                                    fluid
                                    placeholder={t("navbar.firstName")}
                                    label="First name"
                                    labelPosition="left"
                                    onChange={(e) => setFirst(e.target.value)}
                                    value={first}
                                />
                            </Div>
                            <Input
                                fluid
                                placeholder={t("navbar.lastName")}
                                label="Last name"
                                labelPosition="left"
                                onChange={(e) => setLast(e.target.value)}
                                value={last}
                            />
                        </Div>
                    </Div>
                    <Div wd="50%" small charcoal superpad rounded>
                        {user.image ? (
                            <Div pureCircle noOverflow centered fit-content>
                                <Image src={user.image} />
                            </Div>
                        ) : (
                            <AvatarCreator
                                avatarConfig={user.avatarConfig}
                                onChange={changeAvatar}
                            />
                        )}
                    </Div>
                </Div>
                <Button
                    primary
                    disabled={first === "Guest" || last === "User"}
                    content={"Save My Profile"}
                    onClick={handleUpdate}
                />
            </Div>
        </Div>
    );

    // ========================= Render Function =================================
    return (
        <MasterLayout
            title={`Coming Soon`}
            mainPanel={pageContent}
            rightPanelName={t(`components.recommended`)}
            rightPanel={<RecommendedArticles />}
            startFullScreen={true}
            rootPage={false}
        />
    );
}
