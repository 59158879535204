import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";

import { TemplateList } from "@schemas/templateSchema";
import { LayoutContext } from "@layouts/MasterLayout.react";
import { SlideLayout } from "@layouts/SlideLayout.react";
import "@styles/readers.scss";

import { Div } from "@components/Generics.react";
import DeckToolbar from "@components/DeckToolbar.react";

export default function QdfReader(props) {
    const { deck, recordAttempt, isPreview, slideNum } = props;
    const { fullScreen, setFullScreen } = useContext(LayoutContext);
    const isPortrait = window.innerHeight > window.innerWidth;

    useEffect(() => {
        if (!isPortrait && setFullScreen) {
            setFullScreen(true);
        }
        return () => {
            if (setFullScreen) setFullScreen(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //---------------------------------------------------------------------------
    // Slide Navigation
    //---------------------------------------------------------------------------
    const [pageNumber, setPageNumber] = useState(0);
    const [numPages, setNumPages] = useState(1);
    const [maxRead, setMaxRead] = useState(0);
    const [slideData, setSlideData] = useState(null);

    useEffect(() => {
        if (numPages !== deck.content?.length)
            setNumPages(deck.content?.length);
        const currentSlide = _.find(deck.content, {
            slideSeq: pageNumber,
        });
        setSlideData(currentSlide);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, deck, numPages]);

    //---------------------------------------------------------------------------
    // Creator Slide Navigation
    //---------------------------------------------------------------------------
    useEffect(() => {
        if (slideNum != null && pageNumber !== slideNum)
            setPageNumber(slideNum);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slideNum, pageNumber]);

    //---------------------------------------------------------------------------
    // Slide Content
    //---------------------------------------------------------------------------
    const templateSchema = TemplateList[slideData?.template];
    const SlideComponent = templateSchema?.template;

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        if (maxRead <= pageNumber + 1) setMaxRead(pageNumber);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber]);

    useEffect(() => {
        if (numPages > 0) {
            recordAttempt({
                viewedPercentage: Math.min(
                    100,
                    parseInt(((maxRead + 1) * 100) / numPages, 0)
                ),
                completion: maxRead >= numPages - 1,
                pointsDelta: 0,
                score: null,
                showEnding: maxRead >= numPages,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numPages, maxRead]);

    // ========================= Render Function =================================
    return (
        <Div
            fluid
            ht={
                isPreview
                    ? "100%"
                    : `calc(100vh - ${
                          isPortrait
                              ? /iPad|iPhone|iPod/.test(navigator.userAgent)
                                  ? 195
                                  : 155
                              : 125
                      }px)`
            }
            centered
            center-align
            noOverflow={isPreview}
            className="fullscreen-target"
        >
            <SlideLayout
                className="slide-container"
                title={templateSchema?.showHeader ? slideData.data.title : ""}
                subtitle={
                    templateSchema?.showHeader ? slideData.data.subtitle : ""
                }
                image={
                    templateSchema?.showHeaderImg
                        ? slideData?.data?.image
                        : null
                }
                sequence={slideData?.slideSeq}
                colors={deck?.colors}
                backgroundImage={deck?.backgroundImage}
                isPreview={isPreview}
            >
                {SlideComponent && (
                    <SlideComponent
                        {...slideData}
                        deckId={deck._id}
                        setPageNumber={setPageNumber}
                        pageNumber={pageNumber}
                    />
                )}
            </SlideLayout>
            {!isPreview && (
                <DeckToolbar
                    {...{
                        deck,
                        pageNumber,
                        setPageNumber,
                        numPages,
                        fullScreen,
                        setFullScreen,
                    }}
                />
            )}
        </Div>
    );
}
