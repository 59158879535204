// Import from NPM
// -------------------------------------
import React from "react";
import _ from "lodash";
import { Div, Icomoon } from "@components/Generics.react";
import "@styles/editor.scss";

export default function RowBtns(props) {
    const { btns, name, leftBordered = true } = props;
    // ========================= Render Function =================================
    return (
        <Div fit-content className="ribbon-btn" leftBordered={leftBordered}>
            <Div ivory slightShadow snubbed fit-content center-align flex>
                {_.map(btns, (btn, idx) => {
                    return (
                        <Div
                            basepad
                            leftBordered={idx !== 0}
                            className={`sub-btn ${btn.active ? "active" : ""}`}
                            key={`${btn.name.replace(" ", "-")}-${idx}`}
                            clickable={!btn.active}
                            onClick={btn.action}
                        >
                            <Div large>
                                <Icomoon name={btn.icon} />
                            </Div>
                            <Div small>{btn.name}</Div>
                        </Div>
                    );
                })}
            </Div>
            <Div small center-align gapTop>
                {name}
            </Div>
        </Div>
    );
}
