import React, { useState, useContext } from "react";
import { LayoutContext } from "@layouts/MasterLayout.react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { trackQuestion } from "@reducers/trackerSlice";
import { Div, HDiv, Button } from "@components/Generics.react";
import ResponseOverlay from "@components/ResponseOverlay.react";
import { settings } from "@config/settings/app.settings";

export default function SingleSelect(props) {
    const {
        data,
        masterSeq,
        slideSeq,
        trackData,
        deckId,
        pageNumber,
        setPageNumber,
        mustOverlay = false,
    } = props;
    const [correct, setCorrect] = useState(null);
    const [showOverlay, setShowOverlay] = useState(false);
    const [thisResponse, setThisResponse] = useState(null);
    const userId = useSelector((state) => state.auth.user._id);
    const dispatch = useDispatch();
    const { t } = useTranslation("common");
    const { fullScreen } = useContext(LayoutContext);
    const isPortrait = window.innerHeight > window.innerWidth || !fullScreen;

    function trackInteraction(selected) {
        const isCorrect = data.options[selected]["correct"] === "checked";
        setCorrect(isCorrect);
        if (
            mustOverlay ||
            (data.feedback != null &&
                data.feedback.length > 0 &&
                data.purpose !== "Provide Insight")
        ) {
            setThisResponse({
                correct: isCorrect,
                response: selected,
            });
            setShowOverlay(true);
        } else {
            setPageNumber(pageNumber + 1);
            if (trackData) {
                trackData({
                    correct: isCorrect,
                    response: selected,
                });
            }
        }

        dispatch(
            trackQuestion({
                deckId: deckId,
                slideId: masterSeq == null ? slideSeq : masterSeq,
                userId: userId,
                response: data.options[selected].text,
            })
        );
    }

    function closeOverlay() {
        setPageNumber(pageNumber + 1);
        if (trackData) {
            trackData(thisResponse);
        }
    }

    const getOptions = _.map(data.options, (option, index) => {
        if (settings.functionality.latexEnabled) {
            const numRegex = /\$(\d+)\$/g;
            const optText = option.text.toString().replace(numRegex, "$1");
            return (
                <HDiv
                    className="qoption"
                    content={optText}
                    fluid
                    primary
                    medpad
                    snubbed
                    clickable
                    key={"button-" + index}
                    onClick={() => trackInteraction(index)}
                    style={{ marginBottom: "3px", textAlign: "left" }}
                />
            );
        } else
            return (
                <Button
                    content={option.text}
                    fluid
                    normal={isPortrait ? undefined : +true}
                    primary
                    key={"button-" + index}
                    onClick={() => trackInteraction(index)}
                    style={{ marginBottom: "3px", textAlign: "left" }}
                />
            );
    });

    // ========================= Render Function =================================
    return (
        <Div wd={isPortrait ? "100%" : "50%"} fullht zoom superpad centered>
            <HDiv
                big={data.question?.length < 120}
                gutter
                content={data.question}
            />
            <Div small italics>
                {t("deck.qdf.singleSelect")}
            </Div>
            <Div padTop>{getOptions}</Div>
            {showOverlay && (
                <ResponseOverlay
                    feedback={data.feedback}
                    correct={correct}
                    closeResponse={closeOverlay}
                />
            )}
        </Div>
    );
}
