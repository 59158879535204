import React, { useState, useMemo } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useFeatures } from "flagged";
import { Link, useNavigate } from "react-router-dom";
import { useGetUserCountQuery } from "@api/apiV6";
import {
    Grid,
    Button,
    Icon,
    Label,
    Divider,
    Card,
    Image,
    Modal,
} from "semantic-ui-react";
import ReactPlayer from "react-player/lazy";
import CreatorLayout from "@layouts/CreatorLayout.react";
import { Div, Button as XButton } from "@components/Generics.react";
import { settings } from "@config/settings/app.settings";
import { Tutorials } from "../../../config/settings/app.tutorials";

export default function BuilderHome(props) {
    const { t } = useTranslation("common");
    const { data, isSuccess } = useGetUserCountQuery();

    const features = useFeatures();
    const navigate = useNavigate();

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);

    const filteredSet = useMemo(() => {
        const isUserCountSmall =
            isSuccess && data?.userCount < settings.largeUserbase;
        const canShowUsers = features.creatorAdmin && isUserCountSmall;
        const canShowAnalytics =
            !features.tenanting.multiTenanted && isUserCountSmall;

        const visibilityConditions = {
            social: features.social,
            contests: features.contests,
            users: canShowUsers,
            account: features.general.subscribable,
            design:
                features.tenanting.multiTenanted &&
                features.ability.builder.appDesign,
            analytics: canShowAnalytics,
        };

        return _.filter(settings.sidebar, (elm) => {
            return _.get(visibilityConditions, elm, true);
        });
    }, [features, data, isSuccess]);

    const handleCardClick = (video) => {
        setSelectedVideo(video);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedVideo(null);
    };

    const pageContent = (
        <Div fullht superpad vapor autoOverflow>
            <Div superpad fluid white rounded>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={10}>
                            <Div wd="256px" gutter>
                                <Image
                                    src={
                                        "/assets/images/configurable/logo-full.png"
                                    }
                                />
                            </Div>
                            <Div headline altText gutter>
                                {t("builder.welcome.title")}
                            </Div>
                            <Div big bold trench txtCharcoal>
                                {t("builder.welcome.context")}
                            </Div>
                            <Button.Group>
                                <Link to="/" target="_blank">
                                    <XButton
                                        secondary
                                        content={t(
                                            "builder.welcome.learnerView"
                                        )}
                                    />
                                </Link>
                                {features.ability.adminPanel.visible && (
                                    <XButton
                                        danger={+true}
                                        content={t(
                                            "builder.welcome.adminPanel"
                                        )}
                                        onClick={() => navigate("/adminPanel")}
                                    />
                                )}
                            </Button.Group>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Div large bold uppercase gapBottom>
                                {t(`builder.welcome.whatToDo`)}
                            </Div>
                            <Div fluid flex flexStart>
                                {_.map(filteredSet, (elm, idx) => (
                                    <Div fluid snug key={`panel-btn-${idx}`}>
                                        <Button
                                            fluid
                                            as="div"
                                            labelPosition="right"
                                            onClick={() =>
                                                navigate(
                                                    t(
                                                        `builder.sidebar.${elm}.link`
                                                    )
                                                )
                                            }
                                        >
                                            <Button
                                                icon
                                                style={{
                                                    padding: "7.5px 15px",
                                                    background:
                                                        settings.colors.primary,
                                                    color: settings.colors
                                                        .primaryText,
                                                }}
                                            >
                                                <Icon
                                                    name={t(
                                                        `builder.sidebar.${elm}.icon`
                                                    )}
                                                />{" "}
                                                <Div
                                                    leftAlign
                                                    inlineBlock
                                                    minWd="100px"
                                                >
                                                    {t(
                                                        `builder.sidebar.${elm}.header`
                                                    )}
                                                </Div>
                                            </Button>
                                            <Label
                                                as="a"
                                                pointing="left"
                                                style={{ width: "200px" }}
                                            >
                                                {t(
                                                    `builder.sidebar.${elm}.tooltip`
                                                )}
                                            </Label>
                                        </Button>
                                    </Div>
                                ))}
                            </Div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Div>
            <Divider horizontal>
                <Div flex wd="500px">
                    <Div large gapTop wd="250px">
                        {t(`builder.welcome.howWorks`)}
                    </Div>
                    <Div
                        gapTop
                        clickable
                        onClick={() => navigate("/builder/helpdesk")}
                    >
                        <XButton
                            secondary
                            small={+true}
                            content={t(`builder.welcome.tutorials`)}
                        />
                    </Div>
                </Div>
            </Divider>
            <Div fluid grey basepad rounded>
                <Card.Group itemsPerRow={4}>
                    {_.map(_.filter(Tutorials, "home"), (card, idx) => {
                        return (
                            <Card
                                key={`tutorial-${idx}`}
                                onClick={() => handleCardClick(card.video)}
                            >
                                <Div fluid relative clickable>
                                    <Image fluid src={card.image} />
                                    <Div
                                        absolute
                                        snubbed
                                        style={{
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                        }}
                                    >
                                        <Icon
                                            name="youtube play"
                                            color="red"
                                            size="huge"
                                        />
                                    </Div>
                                </Div>
                                <Card.Content>
                                    <Card.Header>{card.name}</Card.Header>
                                    <Card.Meta>{card.meta}</Card.Meta>
                                    <Card.Description>
                                        {card.description}
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        );
                    })}
                </Card.Group>
            </Div>
            {selectedVideo && (
                <Modal open={modalOpen} closeIcon onClose={handleCloseModal}>
                    <Div>
                        {selectedVideo && (
                            <ReactPlayer
                                url={selectedVideo}
                                width="100%"
                                height="30em"
                                controls
                            />
                        )}
                    </Div>
                </Modal>
            )}
        </Div>
    );

    // ========================= Render Function =================================
    return (
        <CreatorLayout
            title={t("appCreator")}
            navName={"Home"}
            pageCode="home"
            mainPanel={pageContent}
            rootPage={true}
        />
    );
}
