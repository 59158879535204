import React, { useEffect, useState } from "react";
import { List, Button } from "semantic-ui-react";

export function ItemArranger({ items, onArrange }) {
    const [arrangedItems, setArrangedItems] = useState([...items]);

    const handleMoveUp = (index) => {
        if (index > 0) {
            const updatedItems = [...arrangedItems];
            [updatedItems[index - 1], updatedItems[index]] = [
                updatedItems[index],
                updatedItems[index - 1],
            ];
            setArrangedItems(updatedItems);
        }
    };

    const handleMoveDown = (index) => {
        if (index < arrangedItems.length - 1) {
            const updatedItems = [...arrangedItems];
            [updatedItems[index], updatedItems[index + 1]] = [
                updatedItems[index + 1],
                updatedItems[index],
            ];
            setArrangedItems(updatedItems);
        }
    };

    useEffect(() => {
        onArrange(arrangedItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [arrangedItems]);

    useEffect(() => {
        setArrangedItems([...items]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    return (
        <List>
            {arrangedItems.map((item, index) => (
                <List.Item key={index}>
                    <List.Content floated="right">
                        <Button.Group basic size="mini">
                            <Button
                                icon="arrow up"
                                onClick={() => handleMoveUp(index)}
                                disabled={index === 0}
                            />
                            <Button
                                icon="arrow down"
                                onClick={() => handleMoveDown(index)}
                                disabled={index === arrangedItems.length - 1}
                            />
                        </Button.Group>
                    </List.Content>
                    <List.Content>{item}</List.Content>
                </List.Item>
            ))}
        </List>
    );
}
