// Import from NPM
// -------------------------------------
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "lodash";

// Import from Config
// -------------------------------------
import { useGetAppLeaderboardQuery } from "@api/apiV6";
import { settings } from "@config/settings/app.settings";
import "@styles/components.scss";
import MasterLayout from "@layouts/MasterLayout.react";

// Import Components
// -------------------------------------
import { Div } from "@components/Generics.react";
import Leaderboard from "@components/Leaderboard.react";
import RecommendedArticles from "@components/RecommendedArticles.react";

export default function AppLeaderboard(props) {
    const thisUser = useSelector((state) => state.auth.user);
    const { t } = useTranslation("common");

    const {
        data: leaderboardData,
        isLoading,
        isSuccess,
        isError,
        error,
        refetch,
    } = useGetAppLeaderboardQuery();

    const isPortrait = window.innerWidth < window.innerHeight;

    function getUserLeaderboard(users, userDetail) {
        // Filter users based on the userDetail
        const filteredUsers = users.filter((user) => {
            // Check if userDetail is provided and match the corresponding value
            if (
                !user.user_id ||
                !user.user_id.active ||
                _.keys(user.currentState).length === 0 ||
                user.currentState?.points === 0 ||
                user.currentState?.points === null
            )
                return false;
            // Select only users that belong to the same team
            if (
                user.user_id.team == null ||
                thisUser.team == null ||
                user.user_id.team === thisUser.team 
            )
                return true; // Return all users if userDetail is not provided or empty
            return false;
        });

        // Sort the filtered users based on points in descending order
        filteredUsers.sort(
            (a, b) => b.currentState.points - a.currentState.points
        );

        // Map the filtered users to the desired response format
        const leaderboard = filteredUsers.map((user) => {
            return {
                _id: user.user_id._id,
                first_name: user.user_id.first_name,
                last_name: user.user_id.last_name,
                points: user.currentState.points,
                avatarConfig: user.user_id.avatarConfig,
                image: user.user_id.image,
            };
        });

        // Return the leaderboard array
        return leaderboard;
    }

    const leaderboardConfig = settings.appLeaderboard;

    const sortedLeaderboard = useMemo(() => {
        if (isSuccess) {
            let sortedData = _.orderBy(
                getUserLeaderboard(leaderboardData, leaderboardConfig),
                ["points", "completionDate"],
                ["desc", "asc"]
            );
            return sortedData;
        } else {
            return [];
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, leaderboardData, leaderboardConfig]);

    const leaderboard = (
        <Leaderboard
            leaderboardData={sortedLeaderboard}
            header={t("appLeaderboard.header")}
            isLoading={isLoading}
            isError={isError}
            isSuccess={isSuccess}
            error={error}
            refetch={refetch}
        />
    );

    const mainPanel = (
        <Div fullht>
            <Div wd={"calc(100% - 375px)"} fullht float-left>
                <Div white superpad spaced rounded>
                    <Div massive altText padTop uppercase txtCharcoal gutter>
                        {t("appLeaderboard.helpHeader")}
                    </Div>
                    <Div padTop>{t("appLeaderboard.help")}</Div>
                    <Div padTop small>
                        <ul>
                            {_.map(
                                t("appLeaderboard.helpPoints").split("||"),
                                (pointer, idx) => {
                                    return (
                                        <li key={`pointer-${idx}`}>
                                            {pointer}
                                        </li>
                                    );
                                }
                            )}
                        </ul>
                    </Div>
                    <Div padTop>{t("appLeaderboard.helpBottom")}</Div>
                </Div>
            </Div>
            <Div wd={"360px"} fullht float-right relative>
                {leaderboard}
            </Div>
        </Div>
    );

    // ========================= Render Function =================================
    return (
        <MasterLayout
            title={"Welcome"}
            mainPanel={isPortrait ? leaderboard : mainPanel}
            rightPanelName={t(`components.recommended`)}
            rightPanel={<RecommendedArticles />}
            rootPage={true}
        />
    );
}
