import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Tab } from "semantic-ui-react";
import {
    useGetArticleAnalyticsQuery,
    useGetArticleLearnerDataQuery,
} from "@api/apiV6";
import abilityMap from "@config/features/ability.json";
import ArticleSummary from "./panels/articles/ArticleSummary.react";
import ArticleAudience from "./panels/articles/ArticleAudience.react";
import ArticleComments from "./panels/articles/ArticleComments.react";
import ArticleContent from "./panels/articles/ArticleContent.react";
import ArticleQuiz from "./panels/articles/ArticleQuiz.react";
import ArticleSurvey from "./panels/articles/ArticleSurvey.react";
import ArticleGame from "./panels/articles/ArticleGame.react";

export default function ArticleDashboard(props) {
    const { article } = props;
    const user = useSelector((state) => state.auth.user);
    const {
        data: audienceData,
        isSuccess,
        refetch,
    } = useGetArticleAnalyticsQuery({
        id: article._id,
        scope: _.includes(
            abilityMap.analytics.universal,
            user.license.license_type
        )
            ? "universe"
            : "team",
    });
    const { data: learnerData, refetch: dataSuccess } =
        useGetArticleLearnerDataQuery({
            articleId: article._id,
            scope: _.includes(
                abilityMap.analytics.universal,
                user.license.license_type
            )
                ? "universe"
                : "team",
        });

    const aggregateData = useMemo(
        () =>
            dataSuccess && learnerData
                ? {
                      enrolled_team: learnerData.length,
                      accessed_by: _.filter(learnerData, (learner) => {
                          return parseInt(learner.view_attempts, 0) > 0;
                      }).length,
                      completed_by: _.filter(learnerData, "complete").length,
                      total_views: _.sumBy(learnerData, "view_attempts"),
                      not_started_by: _.filter(learnerData, (learner) => {
                          return learner.completion === 0;
                      }).length,
                      time_spent: _.sumBy(learnerData, "time_spent"),
                      average_score: parseInt(
                          _.sumBy(learnerData, "score") / learnerData.length,
                          0
                      ),
                      average_completion:
                          learnerData.length > 0
                              ? parseInt(
                                    _.sumBy(learnerData, "completion") /
                                        learnerData.length,
                                    0
                                )
                              : 0,
                  }
                : {},
        [learnerData, dataSuccess]
    );

    const { t } = useTranslation("common");

    const dashMap = {
        summary: ArticleSummary,
        audience: ArticleAudience,
        comments: ArticleComments,
        content: ArticleContent,
        quiz: article.category === "assessment" && ArticleQuiz,
        survey: article.category === "survey" && ArticleSurvey,
        game: article.category === "game" && ArticleGame,
    };

    const panes = isSuccess
        ? _.map(
              _.filter(_.keys(dashMap), (key) => dashMap[key]),
              (key) => ({
                  menuItem: t(`analytics.${key}`),
                  render: () => {
                      const PaneComponent = dashMap[key];
                      return (
                          <Tab.Pane>
                              <PaneComponent
                                  {...{
                                      article,
                                      audienceData,
                                      learnerData,
                                      aggregateData,
                                      refetch,
                                  }}
                              />
                          </Tab.Pane>
                      );
                  },
              })
          )
        : [];

    return <Tab panes={panes} />;
}
