import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player/lazy";

import "@styles/readers.scss";

import { Div } from "@components/Generics.react";

export default function ReelReader(props) {
    const { deck, recordAttempt } = props;
    const isPortrait = window.innerHeight > window.innerWidth;

    //---------------------------------------------------------------------------
    // Stop resizing to allow for Reel FullScreening
    //---------------------------------------------------------------------------
    useEffect(() => {
        window.doNotResize = true;
        return () => (window.doNotResize = false);
    });

    //---------------------------------------------------------------------------
    // Time and Play tracking
    //---------------------------------------------------------------------------
    const [playing, setPlaying] = useState(true);
    function trackPlay() {
        setPlaying(true);
    }
    //---------------------------------------------------------------------------
    // Reel Tracking
    //---------------------------------------------------------------------------
    const [viewedPercentage, setViewedPercentage] = useState(0);
    const [finished, setFinished] = useState(false);
    function trackProgress(obj) {
        setViewedPercentage(parseInt(obj.played * 100, 0));
        if (viewedPercentage > 90) setFinished(true);
    }
    function trackFinish() {
        setFinished(true);
    }

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: viewedPercentage,
            completion: finished,
            pointsDelta: 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewedPercentage, finished]);

    // ========================= Render Function =================================
    return (
        <Div
            ht={isPortrait ? "100%" : `${window.innerHeight - 120}px`}
            wd={isPortrait ? "85%" : `${(window.innerHeight - 120) * 0.5625}px`}
            padTop
            centered
            noOverflow
            relative
        >
            <Div rounded gutter className="video-responsive-portrait">
                <Div
                    absolute
                    style={{ top: 0, left: 0, width: "auto", height: "100%" }}
                >
                    <ReactPlayer
                        url={deck.content}
                        className="react-player"
                        playing={playing}
                        width="100%"
                        height="100%"
                        controls
                        onProgress={trackProgress}
                        onPlay={trackPlay}
                        onEnded={trackFinish}
                    />
                </Div>
            </Div>
        </Div>
    );
}
