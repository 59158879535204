// Import from NPM
// -------------------------------------
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useFeatures } from "flagged";
import { Icon, Divider } from "semantic-ui-react";

// Import from Config
// -------------------------------------
import "@styles/layouts.scss";
import { Div } from "@components/Generics.react";
import { darkStyle } from "@utilities/helpers";
import { settings } from "@config/settings/app.settings";

import LeftMenu from "@components/LeftMenu.react";
import BottomMenu from "@components/BottomMenu.react";

export default function CommonPanel(props) {
    const {
        mainPanel,
        rightPanel,
        rightPanelName,
        fullScreen,
        hidePanelToggle,
        bottomBuffer,
        isPreview,
    } = props;
    const user = useSelector((state) => state.auth.user);
    const features = useFeatures();
    const [showRightMenu, setShowRightMenu] = useState(false);
    const isPortrait = window.innerHeight > window.innerWidth;

    return isPortrait ? (
        <Div
            className="player-view"
            layer={-1}
            fluid
            basepad={showRightMenu}
            rounded
        >
            {showRightMenu && (
                <Icon
                    name="close"
                    circular
                    inverted
                    className="right-panel-close"
                    onClick={() => setShowRightMenu(false)}
                />
            )}
            <Div
                className={`${showRightMenu ? "right" : "main"}-panel`}
                relative
                fitted
                vapor={!showRightMenu}
                ash={showRightMenu}
                fullht
                fluid
                trench
                autoOverflowY={!showRightMenu}
                noOverflow={showRightMenu || isPreview}
            >
                {showRightMenu ? rightPanel : mainPanel}
                {bottomBuffer && <Divider />}
                {bottomBuffer && (
                    <Div
                        ht={`${
                            /iPad|iPhone|iPod/.test(navigator.userAgent)
                                ? 90
                                : 50
                        }px`}
                    />
                )}
            </Div>
            <Div
                className="bottom-menu"
                style={{ pointerEvents: isPreview ? "none" : "auto" }}
            >
                {!showRightMenu && !hidePanelToggle && !isPreview && (
                    <Div
                        className="bottom-panel-opener"
                        style={darkStyle(user.branding?.colors?.main || settings.colors.main)}
                        onClick={() => setShowRightMenu(true)}
                    >
                        {rightPanelName} <Icon name="chevron up" />
                    </Div>
                )}
                {!isPreview &&
                    (user.license.termsAccepted ||
                        !features.general.acceptTerms) && <BottomMenu />}
            </Div>
        </Div>
    ) : (
        <Div className="player-view" layer={-1} fluid fullht>
            <Div className="left-panel" relative float-left fullht>
                <LeftMenu />
            </Div>
            <Div
                fullht
                float-left
                className={`main-panel-viewport ${fullScreen && "fullscreen"}`}
            >
                <Div
                    className="main-panel"
                    relative
                    rounded
                    padded
                    spaced
                    fullht
                    big
                    autoOverflowY
                >
                    {mainPanel}
                </Div>
            </Div>
            {!fullScreen && (
                <Div
                    className="right-panel"
                    relative
                    float-left
                    topRounded
                    padded
                    fullht
                    ash
                >
                    {rightPanel}
                </Div>
            )}
        </Div>
    );
}
