const GameSchema = [
    {
        name: "The Builder",
        folder: "the_builder",
        restricted: true,
        premium: false,
        description:
            "Capture builder to exorcise the haunted house and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Capture Ghosts",
                image: "the_builder1.jpg",
                text: "Capture ghosts by tapping on them, before your courage runs out. Your courage goes down faster if more ghosts are visible",
            },
            {
                header: "Control your Fear",
                image: "the_builder2.jpg",
                text: "When your courage reaches zero, your game is over. Click the Question Flask to get a question. Answer it correctly and your courage gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "the_builder3.jpg",
                text: "As you capture ghosts, you will level up. With each level you go up, the courage drops a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Iceberk Penguine",
        folder: "iceberk_penguine",
        restricted: true,
        premium: false,
        description:
            "A Spin Board game where you answer questions to win spins and earn money.",
        instructions: [
            {
                header: "Spin it",
                image: "iceberk_penguine1.jpg",
                text: "Spin the wheel to collect as many coins as you can, before your turns run out",
            },
            {
                header: "Watch the Timer",
                image: "iceberk_penguine2.jpg",
                text: "You will get a question every time you land on a special section of the wheel. Answer the questions carefully. They can make or break your game.",
            },
            {
                header: "Extra coins",
                image: "iceberk_penguine3.jpg",
                text: "Beware of the bankrupt icon. If you gives wrong answer, you will lose all distance that you have covered so far!",
            },
            {
                header: "Levelling Up",
                image: "iceberk_penguine4.jpg",
                text: "As you collect points you level up. Try your luck and reach the highest level you can...",
            },
        ],
    },
    {
        name: "Mountain Rush",
        folder: "mountain_rush",
        restricted: false,
        premium: false,
        description:
            "Climb the mountain, collect that money and answer questions to get a boost.",
        instructions: [
            {
                header: "Climb Upwards and Onwards",
                image: "mountain_rush1.jpg",
                text: "Click or tap to jump between the two buildings and collect the money. Crash into a protrusion and it is game over!",
            },
            {
                header: "Get a Promotion",
                image: "mountain_rush2.jpg",
                text: "Jump onto a question mark to get a question. Answer it correctly to get immunity and a speed boost for a short while.",
            },
            {
                header: "Levelling Up",
                image: "mountain_rush3.jpg",
                text: "As you collect points, you will level up. With each level you go up, your speed increases and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Thief Runner",
        folder: "thief_runner",
        restricted: false,
        premium: false,
        description:
            "Climb the  ladder, collect that money and answer questions to get a boost.",
        instructions: [
            {
                header: "Climb Upwards and Onwards",
                image: "thief_runner1.jpg",
                text: "Click or tap to jump between the two buildings and collect the money. Crash into a protrusion and it is game over!",
            },
            {
                header: "Get a Promotion",
                image: "thief_runner2.jpg",
                text: "Jump onto a question mark to get a question. Answer it correctly to get immunity and a speed boost for a short while.",
            },
            {
                header: "Levelling Up",
                image: "thief_runner3.jpg",
                text: "As you collect points, you will level up. With each level you go up, your speed increases and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Quad Race",
        folder: "quad_race",
        restricted: true,
        premium: false,
        description:
            "Drive down the casino, delivery food and avoid traffic and answer questions to top up your time regularly.",
        instructions: [
            {
                header: "Drive through Traffic",
                image: "quad_race1.jpg",
                text: "Click or tap to change lanes as you drive. Crash into a bus and it is game over!",
            },
            {
                header: "Watch the time",
                image: "quad_race2.jpg",
                text: "When the time reaches zero, your game is over. Stop in the right lane next to a bus stop to get a question. Answer it correctly to top up your time.",
            },
            {
                header: "Levelling Up",
                image: "quad_race3.jpg",
                text: "As you collect points, you will level up. With each level you go up, the traffic on the casino road increases and the game gets a little tougher...",
            },
        ],
    },
    
    {
        name: "The Cafe",
        folder: "the_cafe",
        restricted: true,
        premium: false,
        description:
            "Capture cafe to exorcise the haunted house and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Capture Ghosts",
                image: "the_cafe1.jpg",
                text: "Capture ghosts by tapping on them, before your courage runs out. Your courage goes down faster if more ghosts are visible",
            },
            {
                header: "Control your Fear",
                image: "the_cafe2.jpg",
                text: "When your courage reaches zero, your game is over. Click the Question Flask to get a question. Answer it correctly and your courage gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "the_cafe3.jpg",
                text: "As you capture ghosts, you will level up. With each level you go up, the courage drops a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Kite Runner",
        folder: "kite_runner",
        restricted: false,
        premium: false,
        description:
            "Pop those kites to collect stars and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "kite_runner1.jpg",
                text: "Collect stars by popping the balloons that are flying up. Different balloons give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "kite_runner2.jpg",
                text: "When the timer reaches zero, your game is over. Pop the Time balloon to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "kite_runner3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Ninja Run",
        folder: "ninja_run",
        restricted: false,
        premium: false,
        description:
            "Climb the ninja ladder, collect that money and answer questions to get a boost.",
        instructions: [
            {
                header: "Climb Upwards and Onwards",
                image: "ninja_run1.jpg",
                text: "Click or tap to jump between the two buildings and collect the money. Crash into a protrusion and it is game over!",
            },
            {
                header: "Get a Promotion",
                image: "ninja_run2.jpg",
                text: "Jump onto a question mark to get a question. Answer it correctly to get immunity and a speed boost for a short while.",
            },
            {
                header: "Levelling Up",
                image: "ninja_run3.jpg",
                text: "As you collect points, you will level up. With each level you go up, your speed increases and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Catch The Clown",
        folder: "catch_the_clown",
        restricted: true,
        premium: false,
        description:
            "Capture clown to exorcise the haunted house and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Capture Ghosts",
                image: "catch_the_clown1.jpg",
                text: "Capture ghosts by tapping on them, before your courage runs out. Your courage goes down faster if more ghosts are visible",
            },
            {
                header: "Control your Fear",
                image: "catch_the_clown2.jpg",
                text: "When your courage reaches zero, your game is over. Click the Question Flask to get a question. Answer it correctly and your courage gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "catch_the_clown3.jpg",
                text: "As you capture ghosts, you will level up. With each level you go up, the courage drops a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: " Castle",
        folder: "castle",
        restricted: true,
        premium: false,
        description:
            "A Spin Board game where you answer questions to win spins and earn money.",
        instructions: [
            {
                header: "Spin it",
                image: "castle1.jpg",
                text: "Spin the wheel to collect as many coins as you can, before your turns run out",
            },
            {
                header: "Watch the Timer",
                image: "castle2.jpg",
                text: "You will get a question every time you land on a special section of the wheel. Answer the questions carefully. They can make or break your game.",
            },
            {
                header: "Extra coins",
                image: "castle3.jpg",
                text: "Beware of the bankrupt icon. If you gives wrong answer, you will lose all distance that you have covered so far!",
            },
            {
                header: "Levelling Up",
                image: "castle4.jpg",
                text: "As you collect points you level up. Try your luck and reach the highest level you can...",
            },
        ],
    },
    {
        name: "Bird Pop",
        folder: "bird_pop",
        restricted: true,
        premium: false,
        description:
            "Brust those birds to collect stars and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "bird_pop1.jpg",
                text: "Collect stars by bursting the rockets that are flying up. Different rockets give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "bird_pop2.jpg",
                text: "When the timer reaches zero, your game is over. Pop the Time Lantern to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "bird_pop3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Auto Driver",
        folder: "auto_driver",
        restricted: true,
        premium: false,
        description:
            "Drive auto and avoid traffic and answer questions to top up your time regularly.",
        instructions: [
            {
                header: "Drive through Traffic",
                image: "auto_driver1.jpg",
                text: "Click or tap to change lanes as you drive. Crash into a bus and it is game over!",
            },
            {
                header: "Watch the time",
                image: "auto_driver2.jpg",
                text: "When the time reaches zero, your game is over. Stop in the right lane next to a bus stop to get a question. Answer it correctly to top up your time.",
            },
            {
                header: "Levelling Up",
                image: "auto_driver3.jpg",
                text: "As you collect points, you will level up. With each level you go up, the traffic on the casino road increases and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "JetPop",
        folder: "jetpop",
        restricted: false,
        premium: false,
        description:
            "Pop those jetpop to collect stars and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "jetpop1.jpg",
                text: "Collect stars by popping the balloons that are flying up. Different balloons give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "jetpop2.jpg",
                text: "When the timer reaches zero, your game is over. Pop the Time balloon to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "jetpop3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Pirates Treasure",
        folder: "pirate_treasure",
        restricted: true,
        premium: false,
        description:
            "A Spin Board game where you answer questions to win spins and earn money.",
        instructions: [
            {
                header: "Spin it",
                image: "pirate_treasure1.jpg",
                text: "Spin the wheel to collect as many coins as you can, before your turns run out",
            },
            {
                header: "Watch the Timer",
                image: "pirate_treasure2.jpg",
                text: "You will get a question every time you land on a special section of the wheel. Answer the questions carefully. They can make or break your game.",
            },
            {
                header: "Extra coins",
                image: "pirate_treasure3.jpg",
                text: "Beware of the bankrupt icon. If you gives wrong answer, you will lose all distance that you have covered so far!",
            },
            {
                header: "Levelling Up",
                image: "pirate_treasure4.jpg",
                text: "As you collect points you level up. Try your luck and reach the highest level you can...",
            },
        ],
    },
    {
        name: "Zombie In The City",
        folder: "zombie",
        restricted: true,
        premium: false,
        description:
            "A Spin Board game where you answer questions to win spins and earn money.",
        instructions: [
            {
                header: "Spin it",
                image: "zombie1.jpg",
                text: "Spin the wheel to collect as many coins as you can, before your turns run out",
            },
            {
                header: "Watch the Timer",
                image: "zombie2.jpg",
                text: "You will get a question every time you land on a special section of the wheel. Answer the questions carefully. They can make or break your game.",
            },
            {
                header: "Extra coins",
                image: "zombie3.jpg",
                text: "Beware of the bankrupt icon. If you gives wrong answer, you will lose all distance that you have covered so far!",
            },
            {
                header: "Levelling Up",
                image: "zombie4.jpg",
                text: "As you collect points you level up. Try your luck and reach the highest level you can...",
            },
        ],
    },
    {
        name: "University Game",
        folder: "university_game",
        restricted: true,
        premium: false,
        description:
            "A Spin Board game where you answer questions to win spins and earn money.",
        instructions: [
            {
                header: "Spin it",
                image: "university_game1.jpg",
                text: "Spin the wheel to collect as many coins as you can, before your turns run out",
            },
            {
                header: "Watch the Timer",
                image: "university_game2.jpg",
                text: "You will get a question every time you land on a special section of the wheel. Answer the questions carefully. They can make or break your game.",
            },
            {
                header: "Extra coins",
                image: "university_game3.jpg",
                text: "Beware of the bankrupt icon. If you gives wrong answer, you will lose all distance that you have covered so far!",
            },
            {
                header: "Levelling Up",
                image: "university_game4.jpg",
                text: "As you collect points you level up. Try your luck and reach the highest level you can...",
            },
        ],
    },
    {
        name: "Butterfly",
        folder: "butterfly",
        restricted: false,
        premium: false,
        description:
            "Pop those butterfly to collect stars and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "balloon_burst1.jpg",
                text: "Collect stars by popping the balloons that are flying up. Different balloons give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "balloon_burst2.jpg",
                text: "When the timer reaches zero, your game is over. Pop the Time balloon to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "balloon_burst3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "F1 Racing",
        folder: "f1_racing",
        restricted: true,
        premium: false,
        description:
            "A Spin Board game where you answer questions to win spins and earn money.",
        instructions: [
            {
                header: "Spin it",
                image: "f1_racing1.jpg",
                text: "Spin the wheel to collect as many coins as you can, before your turns run out",
            },
            {
                header: "Watch the Timer",
                image: "f1_racing2.jpg",
                text: "You will get a question every time you land on a special section of the wheel. Answer the questions carefully. They can make or break your game.",
            },
            {
                header: "Extra coins",
                image: "f1_racing3.jpg",
                text: "Beware of the bankrupt icon. If you gives wrong answer, you will lose all distance that you have covered so far!",
            },
            {
                header: "Levelling Up",
                image: "f1_racing4.jpg",
                text: "As you collect points you level up. Try your luck and reach the highest level you can...",
            },
        ],
    },
    {
        name: "Food Delivery",
        folder: "food_delivery",
        restricted: false,
        premium: false,
        description:
            "Drive down the road, avoid traffic and answer questions to top up your fuel regularly.",
        instructions: [
            {
                header: "Drive through Traffic",
                image: "food_delivery1.jpg",
                text: "Click or tap to change lanes as you drive. Crash into a car and it is game over!",
            },
            {
                header: "Watch the Fuel",
                image: "food_delivery2.jpg",
                text: "When the fuel reaches zero, your game is over. Stop in the left lane next to a gas station to get a question. Answer it correctly to top up your fuel.",
            },
            {
                header: "Levelling Up",
                image: "food_delivery3.jpg",
                text: "As you collect points, you will level up. With each level you go up, the traffic on the road increases and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "The Mechanic",
        folder: "the_mechanic",
        restricted: true,
        premium: false,
        description:
            "Shoot down sweets to collect points and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "the_mechanic1.jpg",
                text: "Collect stars by shooting down sweets on the shelves. Different sweets give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "the_mechanic2.jpg",
                text: "When the timer reaches zero, your game is over. Pop the Time sweet to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "the_mechanic3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "JetSkii",
        folder: "jetski",
        restricted: false,
        premium: false,
        description:
            "Drive down the River, avoid traffic and answer questions to top up your fuel regularly.",
        instructions: [
            {
                header: "Drive through Traffic",
                image: "jetski1.jpg",
                text: "Click or tap to change lanes as you drive. Crash into a car and it is game over!",
            },
            {
                header: "Watch the Fuel",
                image: "jetski2.jpg",
                text: "When the fuel reaches zero, your game is over. Stop in the left lane next to a gas station to get a question. Answer it correctly to top up your fuel.",
            },
            {
                header: "Levelling Up",
                image: "jetski3.jpg",
                text: "As you collect points, you will level up. With each level you go up, the traffic on the road increases and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Tarzen",
        folder: "tarzen",
        restricted: false,
        premium: false,
        description:
            "Climb the corporate ladder, collect that money and answer questions to get a boost.",
        instructions: [
            {
                header: "Climb Upwards and Onwards",
                image: "tarzen1.jpg",
                text: "Click or tap to jump between the two buildings and collect the money. Crash into a protrusion and it is game over!",
            },
            {
                header: "Get a Promotion",
                image: "tarzen2.jpg",
                text: "Jump onto a question mark to get a question. Answer it correctly to get immunity and a speed boost for a short while.",
            },
            {
                header: "Levelling Up",
                image: "tarzen3.jpg",
                text: "As you collect points, you will level up. With each level you go up, your speed increases and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Truck Driver",
        folder: "truck_driver",
        restricted: false,
        premium: false,
        description:
            "Drive down the road, avoid traffic and answer questions to top up your fuel regularly.",
        instructions: [
            {
                header: "Drive through Traffic",
                image: "truck_driver1.jpg",
                text: "Click or tap to change lanes as you drive. Crash into a car and it is game over!",
            },
            {
                header: "Watch the Fuel",
                image: "truck_driver2.jpg",
                text: "When the fuel reaches zero, your game is over. Stop in the left lane next to a gas station to get a question. Answer it correctly to top up your fuel.",
            },
            {
                header: "Levelling Up",
                image: "truck_driver3.jpg",
                text: "As you collect points, you will level up. With each level you go up, the traffic on the road increases and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Store Stuff",
        folder: "store_stuf",
        restricted: true,
        premium: false,
        description:
            "Shoot down casino to collect points and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "store_stuf1.jpg",
                text: "Collect stars by shooting down casino on the shelves. Different casino give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "store_stuf2.jpg",
                text: "When the timer reaches zero, your game is over. Pop the Time sweet to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "store_stuf3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Cave Man",
        folder: "cave_man",
        restricted: true,
        premium: false,
        description:
            "Capture Cave Man to exorcise the haunted house and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Capture Ghosts",
                image: "cave_man1.jpg",
                text: "Capture ghosts by tapping on them, before your courage runs out. Your courage goes down faster if more ghosts are visible",
            },
            {
                header: "Control your Fear",
                image: "cave_man2.jpg",
                text: "When your courage reaches zero, your game is over. Click the Question Flask to get a question. Answer it correctly and your courage gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "cave_man3.jpg",
                text: "As you capture ghosts, you will level up. With each level you go up, the courage drops a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Rythm",
        folder: "rythm",
        restricted: false,
        premium: false,
        description:
            "Pop those rythm to collect stars and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "rythm1.jpg",
                text: "Collect stars by popping the balloons that are flying up. Different balloons give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "rythm2.jpg",
                text: "When the timer reaches zero, your game is over. Pop the Time balloon to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "rythm3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Shuriken Ninja",
        folder: "shuriken_ninja",
        restricted: false,
        premium: false,
        description:
            "Pop those balloons to collect stars and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "shuriken_ninja1.jpg",
                text: "Collect stars by popping the balloons that are flying up. Different balloons give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "shuriken_ninja2.jpg",
                text: "When the timer reaches zero, your game is over. Pop the Time balloon to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "shuriken_ninja3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Ambulance Drive",
        folder: "kenko",
        restricted: false,
        premium: false,
        description:
            "Drive down the road, avoid traffic and answer questions to top up your fuel regularly.",
        instructions: [
            {
                header: "Drive through Traffic",
                image: "kenko1.jpg",
                text: "Click or tap to change lanes as you drive. Crash into a car and it is game over!",
            },
            {
                header: "Watch the Fuel",
                image: "kenko2.jpg",
                text: "When the fuel reaches zero, your game is over. Stop in the left lane next to a gas station to get a question. Answer it correctly to top up your fuel.",
            },
            {
                header: "Levelling Up",
                image: "kenko3.jpg",
                text: "As you collect points, you will level up. With each level you go up, the traffic on the road increases and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Catch the Squirrel",
        folder: "catch_the_squirrel",
        restricted: true,
        premium: false,
        description:
            "Capture the clawn to exorcise the haunted house and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Capture crused doll",
                image: "catch_the_squirrel1.jpg",
                text: "Capture ghosts by tapping on them, before your courage runs out. Your courage goes down faster if more ghosts are visible",
            },
            {
                header: "Control your Fear",
                image: "catch_the_squirrel2.jpg",
                text: "When your courage reaches zero, your game is over. Click the Question Flask to get a question. Answer it correctly and your courage gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "catch_the_squirrel3.jpg",
                text: "As you capture ghosts, you will level up. With each level you go up, the courage drops a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Catch the Dog",
        folder: "catch_the_dog",
        restricted: true,
        premium: false,
        description:
            "Capture the clawn to exorcise the haunted house and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Capture crused doll",
                image: "catch_the_dog1.jpg",
                text: "Capture ghosts by tapping on them, before your courage runs out. Your courage goes down faster if more ghosts are visible",
            },
            {
                header: "Control your Fear",
                image: "catch_the_dog2.jpg",
                text: "When your courage reaches zero, your game is over. Click the Question Flask to get a question. Answer it correctly and your courage gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "catch_the_dog3.jpg",
                text: "As you capture ghosts, you will level up. With each level you go up, the courage drops a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Ninja Dash",
        folder: "ninja_dash",
        restricted: false,
        premium: false,
        description:
            "Climb the corporate ladder, collect that money and answer questions to get a boost.",
        instructions: [
            {
                header: "Climb Upwards and Onwards",
                image: "ninja_dash1.jpg",
                text: "Click or tap to jump between the two buildings and collect the money. Crash into a protrusion and it is game over!",
            },
            {
                header: "Get a Promotion",
                image: "ninja_dash2.jpg",
                text: "Jump onto a question mark to get a question. Answer it correctly to get immunity and a speed boost for a short while.",
            },
            {
                header: "Levelling Up",
                image: "ninja_dash3.jpg",
                text: "As you collect points, you will level up. With each level you go up, your speed increases and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Crused Doll",
        folder: "crused_doll",
        restricted: true,
        premium: false,
        description:
            "Capture crused doll to exorcise the haunted house and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Capture crused doll",
                image: "crused_doll1.jpg",
                text: "Capture ghosts by tapping on them, before your courage runs out. Your courage goes down faster if more ghosts are visible",
            },
            {
                header: "Control your Fear",
                image: "crused_doll2.jpg",
                text: "When your courage reaches zero, your game is over. Click the Question Flask to get a question. Answer it correctly and your courage gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "crused_doll3.jpg",
                text: "As you capture ghosts, you will level up. With each level you go up, the courage drops a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Man In The Window",
        folder: "man_window",
        restricted: true,
        premium: false,
        description:
            "Capture man to exorcise the haunted house and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Capture Man",
                image: "man_window1.jpg",
                text: "Capture ghosts by tapping on them, before your courage runs out. Your courage goes down faster if more ghosts are visible",
            },
            {
                header: "Control your Fear",
                image: "man_window2.jpg",
                text: "When your courage reaches zero, your game is over. Click the Question Flask to get a question. Answer it correctly and your courage gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "man_window3.jpg",
                text: "As you capture ghosts, you will level up. With each level you go up, the courage drops a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Pick Packet",
        folder: "pick_packet",
        restricted: false,
        premium: false,
        description:
            "Pop those balloons to collect stars and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "pick_packet1.jpg",
                text: "Collect stars by popping the balloons that are flying up. Different balloons give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "pick_packet2.jpg",
                text: "When the timer reaches zero, your game is over. Pop the Time balloon to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "pick_packet3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Food Ladder",
        folder: "food_ladder",
        restricted: false,
        premium: false,
        description:
            "Climb the corporate ladder, collect that money and answer questions to get a boost.",
        instructions: [
            {
                header: "Climb Upwards and Onwards",
                image: "food_ladder1.jpg",
                text: "Click or tap to jump between the two buildings and collect the money. Crash into a protrusion and it is game over!",
            },
            {
                header: "Get a Promotion",
                image: "food_ladder2.jpg",
                text: "Jump onto a question mark to get a question. Answer it correctly to get immunity and a speed boost for a short while.",
            },
            {
                header: "Levelling Up",
                image: "food_ladder3.jpg",
                text: "As you collect points, you will level up. With each level you go up, your speed increases and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "The Grate Wall",
        folder: "grate_wall",
        restricted: true,
        premium: false,
        description:
            "A Spin Board game where you answer questions to win spins and earn money.",
        instructions: [
            {
                header: "Spin it",
                image: "grate_wall1.jpg",
                text: "Spin the wheel to collect as many coins as you can, before your turns run out",
            },
            {
                header: "Watch the Timer",
                image: "grate_wall2.jpg",
                text: "You will get a question every time you land on a special section of the wheel. Answer the questions carefully. They can make or break your game.",
            },
            {
                header: "Extra coins",
                image: "grate_wall3.jpg",
                text: "Beware of the bankrupt icon. If you gives wrong answer, you will lose all distance that you have covered so far!",
            },
            {
                header: "Levelling Up",
                image: "grate_wall4.jpg",
                text: "As you collect points you level up. Try your luck and reach the highest level you can...",
            },
        ],
    },
    {
        name: "Ufo",
        folder: "ufo",
        restricted: true,
        premium: false,
        description:
            "Brust those fireworks to collect stars and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "ufo1.jpg",
                text: "Collect stars by bursting the rockets that are flying up. Different rockets give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "ufo2.jpg",
                text: "When the timer reaches zero, your game is over. Pop the Time Lantern to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "ufo3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Monkey Kong",
        folder: "monkey_kong",
        restricted: false,
        premium: false,
        description:
            "Climb the corporate ladder, collect that money and answer questions to get a boost.",
        instructions: [
            {
                header: "Climb Upwards and Onwards",
                image: "monkey_kong1.jpg",
                text: "Click or tap to jump between the two buildings and collect the money. Crash into a protrusion and it is game over!",
            },
            {
                header: "Get a Promotion",
                image: "monkey_kong2.jpg",
                text: "Jump onto a question mark to get a question. Answer it correctly to get immunity and a speed boost for a short while.",
            },
            {
                header: "Levelling Up",
                image: "monkey_kong3.jpg",
                text: "As you collect points, you will level up. With each level you go up, your speed increases and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "RockJock",
        folder: "rockjoke",
        restricted: false,
        premium: false,
        description:
            "Climb the corporate ladder, collect that money and answer questions to get a boost.",
        instructions: [
            {
                header: "Climb Upwards and Onwards",
                image: "rockjoke1.jpg",
                text: "Click or tap to jump between the two buildings and collect the money. Crash into a protrusion and it is game over!",
            },
            {
                header: "Get a Promotion",
                image: "rockjoke2.jpg",
                text: "Jump onto a question mark to get a question. Answer it correctly to get immunity and a speed boost for a short while.",
            },
            {
                header: "Levelling Up",
                image: "rockjoke3.jpg",
                text: "As you collect points, you will level up. With each level you go up, your speed increases and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Armory",
        folder: "armory",
        restricted: false,
        premium: false,
        description:
            "Shoot down bottles to collect points and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "armory1.jpg",
                text: "Collect stars by shooting down bottles on the shelves. Different balloons give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "armory2.jpg",
                text: "When the timer reaches zero, your game is over. Pop the Time bottle to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "armory3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Zipline Hero",
        folder: "zipline_hero",
        restricted: true,
        premium: false,
        description:
            "A Spin Board game where you answer questions to win spins and earn money.",
        instructions: [
            {
                header: "Spin it",
                image: "zipline_hero1.jpg",
                text: "Spin the wheel to collect as many coins as you can, before your turns run out",
            },
            {
                header: "Watch the Timer",
                image: "zipline_hero2.jpg",
                text: "You will get a question every time you land on a special section of the wheel. Answer the questions carefully. They can make or break your game.",
            },
            {
                header: "Extra coins",
                image: "zipline_hero3.jpg",
                text: "Beware of the bankrupt icon. If you gives wrong answer, you will lose all distance that you have covered so far!",
            },
            {
                header: "Levelling Up",
                image: "zipline_hero4.jpg",
                text: "As you collect points you level up. Try your luck and reach the highest level you can...",
            },
        ],
    },
    {
        name: "Farm Truck",
        folder: "farm_truck",
        restricted: true,
        premium: false,
        description:
            "Drive down the casino, delivery food and avoid traffic and answer questions to top up your time regularly.",
        instructions: [
            {
                header: "Drive through Traffic",
                image: "farm_truck1.jpg",
                text: "Click or tap to change lanes as you drive. Crash into a bus and it is game over!",
            },
            {
                header: "Watch the time",
                image: "farm_truck2.jpg",
                text: "When the time reaches zero, your game is over. Stop in the right lane next to a bus stop to get a question. Answer it correctly to top up your time.",
            },
            {
                header: "Levelling Up",
                image: "farm_truck3.jpg",
                text: "As you collect points, you will level up. With each level you go up, the traffic on the casino road increases and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Bus Drive",
        folder: "bus_drive",
        restricted: true,
        premium: false,
        description:
            "Drive down the casino, delivery food and avoid traffic and answer questions to top up your time regularly.",
        instructions: [
            {
                header: "Drive through Traffic",
                image: "bus_drive1.jpg",
                text: "Click or tap to change lanes as you drive. Crash into a bus and it is game over!",
            },
            {
                header: "Watch the time",
                image: "bus_drive2.jpg",
                text: "When the time reaches zero, your game is over. Stop in the right lane next to a bus stop to get a question. Answer it correctly to top up your time.",
            },
            {
                header: "Levelling Up",
                image: "bus_drive3.jpg",
                text: "As you collect points, you will level up. With each level you go up, the traffic on the casino road increases and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Catch The Doc",
        folder: "catch_the_doc",
        restricted: true,
        premium: false,
        description:
            "Capture ghosts to exorcise the haunted house and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Capture Ghosts",
                image: "catch_the_doc1.jpg",
                text: "Capture ghosts by tapping on them, before your courage runs out. Your courage goes down faster if more ghosts are visible",
            },
            {
                header: "Control your Fear",
                image: "catch_the_doc2.jpg",
                text: "When your courage reaches zero, your game is over. Click the Question Flask to get a question. Answer it correctly and your courage gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "catch_the_doc3.jpg",
                text: "As you capture ghosts, you will level up. With each level you go up, the courage drops a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Go Kart Go",
        folder: "go_kart_go",
        restricted: false,
        premium: false,
        description:
            "Drive down the road, avoid traffic and answer questions to top up your fuel regularly.",
        instructions: [
            {
                header: "Drive through Traffic",
                image: "go_kart_go1.jpg",
                text: "Click or tap to change lanes as you drive. Crash into a car and it is game over!",
            },
            {
                header: "Watch the Fuel",
                image: "go_kart_go2.jpg",
                text: "When the fuel reaches zero, your game is over. Stop in the left lane next to a gas station to get a question. Answer it correctly to top up your fuel.",
            },
            {
                header: "Levelling Up",
                image: "go_kart_go3.jpg",
                text: "As you collect points, you will level up. With each level you go up, the traffic on the road increases and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Speed Furious",
        folder: "speed_furious",
        restricted: false,
        premium: false,
        description:
            "Drive down the road, avoid traffic and answer questions to top up your fuel regularly.",
        instructions: [
            {
                header: "Drive through Traffic",
                image: "speed_furious1.jpg",
                text: "Click or tap to change lanes as you drive. Crash into a car and it is game over!",
            },
            {
                header: "Watch the Fuel",
                image: "speed_furious2.jpg",
                text: "When the fuel reaches zero, your game is over. Stop in the left lane next to a gas station to get a question. Answer it correctly to top up your fuel.",
            },
            {
                header: "Levelling Up",
                image: "speed_furious3.jpg",
                text: "As you collect points, you will level up. With each level you go up, the traffic on the road increases and the game gets a little tougher...",
            },
        ],
    },

    {
        name: "Mexican Bar",
        folder: "mexican_bar",
        restricted: false,
        premium: false,
        description:
            "Shoot down bottles to collect points and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "mexican_bar1.jpg",
                text: "Collect stars by shooting down bottles on the shelves. Different balloons give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "mexican_bar2.jpg",
                text: "When the timer reaches zero, your game is over. Pop the Time bottle to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "mexican_bar3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Movie Spin",
        folder: "movie_spin",
        restricted: true,
        premium: false,
        description:
            "A Slot Machine game where you answer questions to win spins and earn money. ",
        instructions: [
            {
                header: "Spin it",
                image: "movie_spin1.jpg",
                text: "Click or Tap on handle to collect as many coins as you can, before your turns run out.",
            },
            {
                header: "Watch the Timer",
                image: "movie_spin2.jpg",
                text: "You will get a question every time you land on a Question Mark Icon of the Display. Answer the questions carefully. This will top up your spins and earn more coins.",
            },
            {
                header: "Watch the Timer",
                image: "movie_spin3.jpg",
                text: "You will get a question every time you land on a Question Mark Icon of the Display. Answer the questions carefully. This will top up your spins and earn more coins.",
            },
            {
                header: "Levelling Up",
                image: "movie_spin4.jpg",
                text: "As you collect points, you level up. Try your luck and reach the highest level you can...",
            },
        ],
    },
    {
        name: "Jackpot Bus",
        folder: "jackpot_bus",
        restricted: true,
        premium: false,
        description:
            "A Spin Board game where you answer questions to win spins and earn money.",
        instructions: [
            {
                header: "Spin it",
                image: "jackpot_bus1.jpg",
                text: "Spin the wheel to collect as many coins as you can, before your turns run out",
            },
            {
                header: "Watch the Timer",
                image: "jackpot_bus2.jpg",
                text: "You will get a question every time you land on a special section of the wheel. Answer the questions carefully. They can make or break your game.",
            },
            {
                header: "Special icons",
                image: "jackpot_bus3.jpg",
                text: "The special icons gives you some jackpot, double or extra coins, etc.",
            },
            {
                header: "Levelling Up",
                image: "jackpot_bus4.jpg",
                text: "As you collect points you level up and the character will move forward. Help each character to complete the rope path. Try your luck and reach the highest level you can...",
            },
        ],
    },
    {
        name: "Hotel Jackpot",
        folder: "hotel_jackpot",
        restricted: true,
        premium: false,
        description:
            "Shoot down casino to collect points and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "hotel_jackpot1.jpg",
                text: "Collect stars by shooting down casino on the shelves. Different casino give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "hotel_jackpot2.jpg",
                text: "When the timer reaches zero, your game is over. Pop the Time sweet to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "hotel_jackpot3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Tight Rope",
        folder: "tight_rope_icici",
        restricted: true,
        premium: false,
        description:
            "A Spin Board game where you answer questions to win spins and earn money.",
        instructions: [
            {
                header: "Spin it",
                image: "tight_rope_icici1.jpg",
                text: "Spin the wheel to collect as many coins as you can, before your turns run out",
            },
            {
                header: "Watch the Timer",
                image: "tight_rope_icici2.jpg",
                text: "You will get a question every time you land on a special section of the wheel. Answer the questions carefully. They can make or break your game.",
            },
            {
                header: "Extra coins",
                image: "tight_rope_icici3.jpg",
                text: "Beware of the bankrupt icon. If you gives wrong answer, you will lose all distance that you have covered so far!",
            },
            {
                header: "Levelling Up",
                image: "tight_rope_icici4.jpg",
                text: "As you collect points you level up. Try your luck and reach the highest level you can...",
            },
        ],
    },
    {
        name: "Haunted Castle",
        folder: "haunted_castle",
        restricted: true,
        premium: false,
        description:
            "Capture ghosts to exorcise the haunted house and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Capture Ghosts",
                image: "haunted_castle1.jpg",
                text: "Capture ghosts by tapping on them, before your courage runs out. Your courage goes down faster if more ghosts are visible",
            },
            {
                header: "Control your Fear",
                image: "haunted_castle2.jpg",
                text: "When your courage reaches zero, your game is over. Click the Question Flask to get a question. Answer it correctly and your courage gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "haunted_castle3.jpg",
                text: "As you capture ghosts, you will level up. With each level you go up, the courage drops a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Cross The Street",
        folder: "cross_the_street",
        restricted: true,
        premium: false,
        description:
            "A Spin Board game where you answer questions to win spins and earn money.",
        instructions: [
            {
                header: "Spin it",
                image: "cross_the_street1.jpg",
                text: "Spin the wheel to collect as many coins as you can, before your turns run out",
            },
            {
                header: "Watch the Timer",
                image: "cross_the_street2.jpg",
                text: "You will get a question every time you land on a special section of the wheel. Answer the questions carefully. They can make or break your game.",
            },
            {
                header: "Extra coins",
                image: "cross_the_street3.jpg",
                text: "Beware of the bankrupt icon. If you gives wrong answer, you will lose all distance that you have covered so far!",
            },
            {
                header: "Levelling Up",
                image: "cross_the_street4.jpg",
                text: "As you collect points you level up. Try your luck and reach the highest level you can...",
            },
        ],
    },
    {
        name: "Gear Up",
        folder: "gear_up",
        restricted: true,
        premium: false,
        description:
            "Tap on gear ups to collect points and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "gear_up1.jpg",
                text: "Collect stars by tapping on gear ups on the shelves. Different gear ups give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "gear_up2.jpg",
                text: "When the timer reaches zero, your game is over. Tap the Helmet to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "gear_up3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Sweet Surprise",
        folder: "sweet_surprise",
        restricted: true,
        premium: false,
        description:
            "Shoot down sweets to collect points and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "sweet_surprise1.jpg",
                text: "Collect stars by shooting down sweets on the shelves. Different sweets give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "sweet_surprise2.jpg",
                text: "When the timer reaches zero, your game is over. Pop the Time sweet to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "sweet_surprise3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Yeti Busters",
        folder: "yeti_busters",
        restricted: true,
        premium: false,
        description:
            "Collect yetis to get points and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Yetis",
                image: "yeti_busters1.jpg",
                text: "Collect yetis by tapping on them, before you runs out of time. Your time goes down faster if more yetis are visible",
            },
            {
                header: "Watch the Timer",
                image: "yeti_busters2.jpg",
                text: "When your time reaches zero, your game is over. Click the Question Flask to get a question. Answer it correctly and your courage gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "yeti_busters3.jpg",
                text: "As you collect yetis, you will level up. With each level you go up, the time drops a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Cruise Ship",
        folder: "cruise_ship",
        restricted: true,
        premium: false,
        description:
            "Drive down the road, avoid traffic and answer questions to top up your fuel regularly.",
        instructions: [
            {
                header: "Drive through Traffic",
                image: "cruise_ship1.jpg",
                text: "Click or tap to change lanes as you drive. Crash into a car and it is game over!",
            },
            {
                header: "Watch the Fuel",
                image: "cruise_ship2.jpg",
                text: "When the fuel reaches zero, your game is over. Stop in the right lane next to a fuel station to get a question. Answer it correctly to top up your fuel.",
            },
            {
                header: "Levelling Up",
                image: "cruise_ship3.jpg",
                text: "As you collect points, you will level up. With each level you go up, the traffic on the road increases and the game gets a little tougher...",
            },
        ],
    },
    // {
    //     name: "Quiz Spin",
    //     folder: "carnival_slots",
    //     restricted: true,
    //     premium: false,
    //     description:
    //         "A Slot Machine game where you answer questions to win spins and earn money. ",
    //     instructions: [],
    // },
    // {
    //     name: "Product Launch Roulette",
    //     folder: "product_launch_roulette",
    //     restricted: true,
    //     premium: false,
    //     description:
    //         "A Spin Board game where you answer questions to win spins and earn money.",
    //     instructions: [
    //         {
    //             header: "Spin it",
    //             image: "product_launch_roulette1.jpg",
    //             text: "Spin the wheel to collect as many coins as you can, before your turns run out",
    //         },
    //         {
    //             header: "Watch the Timer",
    //             image: "product_launch_roulette2.jpg",
    //             text: "You will get a question every time you land on a special section of the wheel. Answer the questions carefully. They can make or break your game.",
    //         },
    //         {
    //             header: "Extra coins",
    //             image: "product_launch_roulette3.jpg",
    //             text: "Beware of the bankrupt icon. If you gives wrong answer, you will lose all distance that you have covered so far!",
    //         },
    //         {
    //             header: "Levelling Up",
    //             image: "product_launch_roulette4.jpg",
    //             text: "As you collect points you level up. Try your luck and reach the highest level you can...",
    //         },
    //     ],
    // },
    {
        name: "Strategy Popper",
        folder: "strategy_popper",
        restricted: true,
        premium: false,
        description:
            "Brust those fireworks to collect stars and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "stratergy_popper1.jpg",
                text: "Collect stars by bursting the rockets that are flying up. Different rockets give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "stratergy_popper2.jpg",
                text: "When the timer reaches zero, your game is over. Pop the Time Lantern to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "stratergy_popper3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    // {
    //     name: "The Berkshire Buffet",
    //     folder: "berkshire_buffet",
    //     restricted: true,
    //     premium: false,
    //     description:
    //         "Run through the casino, collect that points and answer questions to get a boost.",
    //     instructions: [
    //         {
    //             header: "Click to jump Upwards and move forward",
    //             image: "the_berkshire_buffet1.jpg",
    //             text: "Click or tap to jump and collect the points. Crash into a obstacle and it is game over!",
    //         },
    //         {
    //             header: "Get a Promotion",
    //             image: "the_berkshire_buffet2.jpg",
    //             text: "Jump and collect a tip jar to get a question. Answer it correctly to get immunity and a speed boost for a short while.",
    //         },
    //         {
    //             header: "Levelling Up",
    //             image: "the_berkshire_buffet3.jpg",
    //             text: "As you collect points, you will level up. With each level you go up, your speed increases and the game gets a little tougher...",
    //         },
    //     ],
    // },
    {
        name: "Balloon Burst",
        folder: "balloon_burst",
        restricted: false,
        premium: false,
        description:
            "Pop those balloons to collect stars and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "balloon_burst1.jpg",
                text: "Collect stars by popping the balloons that are flying up. Different balloons give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "balloon_burst2.jpg",
                text: "When the timer reaches zero, your game is over. Pop the Time balloon to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "balloon_burst3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    // {
    //     name: "Digital Marketing Slot",
    //     folder: "digital_marketing_slot",
    //     restricted: true,
    //     premium: false,
    //     description:
    //         "A Slot Machine game where you answer questions to win spins and earn money. ",
    //     instructions: [
    //         {
    //             header: "Spin it",
    //             image: "digital_marketing_slot1.jpg",
    //             text: "Click or Tap on handle to collect as many coins as you can, before your turns run out.",
    //         },
    //         {
    //             header: "Watch the Timer",
    //             image: "digital_marketing_slot2.jpg",
    //             text: "You will get a question every time you land on a Question Mark Icon of the Display. Answer the questions carefully. This will top up your spins and earn more coins.",
    //         },
    //         {
    //             header: "Levelling Up",
    //             image: "digital_marketing_slot3.jpg",
    //             text: "As you collect points, you level up. Try your luck and reach the highest level you can...",
    //         },
    //     ],
    // },
    {
        name: "Supply Run",
        folder: "supply_run",
        restricted: true,
        premium: false,
        description:
            "Drive down the casino, delivery food and avoid traffic and answer questions to top up your time regularly.",
        instructions: [
            {
                header: "Drive through Traffic",
                image: "supply_run1.jpg",
                text: "Click or tap to change lanes as you drive. Crash into a bus and it is game over!",
            },
            {
                header: "Watch the time",
                image: "supply_run2.jpg",
                text: "When the time reaches zero, your game is over. Stop in the right lane next to a bus stop to get a question. Answer it correctly to top up your time.",
            },
            {
                header: "Levelling Up",
                image: "supply_run3.jpg",
                text: "As you collect points, you will level up. With each level you go up, the traffic on the casino road increases and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Bottle Breaker",
        folder: "bottle_breaker",
        restricted: false,
        premium: false,
        description:
            "Shoot down bottles to collect points and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "bottle_breaker1.jpg",
                text: "Collect stars by shooting down bottles on the shelves. Different balloons give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "bottle_breaker2.jpg",
                text: "When the timer reaches zero, your game is over. Pop the Time bottle to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "bottle_breaker3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "CorpoLadder",
        folder: "corpoladder",
        restricted: false,
        premium: false,
        description:
            "Climb the corporate ladder, collect that money and answer questions to get a boost.",
        instructions: [
            {
                header: "Climb Upwards and Onwards",
                image: "corpoladder1.jpg",
                text: "Click or tap to jump between the two buildings and collect the money. Crash into a protrusion and it is game over!",
            },
            {
                header: "Get a Promotion",
                image: "corpoladder2.jpg",
                text: "Jump onto a question mark to get a question. Answer it correctly to get immunity and a speed boost for a short while.",
            },
            {
                header: "Levelling Up",
                image: "corpoladder3.jpg",
                text: "As you collect points, you will level up. With each level you go up, your speed increases and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Taxi Driver",
        folder: "taxi_driver",
        restricted: false,
        premium: false,
        description:
            "Drive down the road, avoid traffic and answer questions to top up your fuel regularly.",
        instructions: [
            {
                header: "Drive through Traffic",
                image: "taxi_driver1.jpg",
                text: "Click or tap to change lanes as you drive. Crash into a car and it is game over!",
            },
            {
                header: "Watch the Fuel",
                image: "taxi_driver2.jpg",
                text: "When the fuel reaches zero, your game is over. Stop in the left lane next to a gas station to get a question. Answer it correctly to top up your fuel.",
            },
            {
                header: "Levelling Up",
                image: "taxi_driver3.jpg",
                text: "As you collect points, you will level up. With each level you go up, the traffic on the road increases and the game gets a little tougher...",
            },
        ],
    },
    // {
    //     name: "StarFall",
    //     folder: "starfall",
    //     restricted: false,
    //     premium: false,
    //     description:
    //         "Connect the stars to destroy them, and answer questions to gain more time to do it in.",
    //     instructions: [
    //         {
    //             header: "Connect the Stars",
    //             image: "starfall1.jpg",
    //             text: "Connect the stars to destroy them. The longer the chain, the more points you get.",
    //         },
    //         {
    //             header: "Watch the Timer",
    //             image: "starfall2.jpg",
    //             text: "When the timer reaches zero, your game is over. Connect the question marks to get a question. Answer it correctly to get more time. The longer the chain, the more time you get.",
    //         },
    //         {
    //             header: "Levelling Up",
    //             image: "starfall3.jpg",
    //             text: "As you collect points, you will level up. With each level you go up, the stars fall a little slower and the game gets a little tougher...",
    //         },
    //     ],
    // },
    // {
    //     name: "Freedom Rocks",
    //     folder: "freedom_rocks",
    //     restricted: true,
    //     premium: false,
    //     description:
    //         "Connect the rocks to build chains, and answer questions to gain more time to do it in.",
    //     instructions: [
    //         {
    //             header: "Connect the Rocks",
    //             image: "freedom_rocks1.jpg",
    //             text: "Connect similar symbols to build chains. The longer the chain, the more points you get.",
    //         },
    //         {
    //             header: "Watch the Timer",
    //             image: "freedom_rocks2.jpg",
    //             text: "When the timer reaches zero, your game is over. Connect the question marks to get a question. Answer it correctly to get more time. The longer the chain, the more time you get.",
    //         },
    //         {
    //             header: "Levelling Up",
    //             image: "freedom_rocks3.jpg",
    //             text: "As you collect points, you will level up. With each level you go up, the rocks fall a little slower and the game gets a little tougher...",
    //         },
    //     ],
    // },
    {
        name: "Ghost Dusters",
        folder: "ghost_dusters",
        restricted: true,
        premium: false,
        description:
            "Capture ghosts to exorcise the haunted house and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Capture Ghosts",
                image: "ghost_dusters1.jpg",
                text: "Capture ghosts by tapping on them, before your courage runs out. Your courage goes down faster if more ghosts are visible",
            },
            {
                header: "Control your Fear",
                image: "ghost_dusters2.jpg",
                text: "When your courage reaches zero, your game is over. Click the Question Flask to get a question. Answer it correctly and your courage gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "ghost_dusters3.jpg",
                text: "As you capture ghosts, you will level up. With each level you go up, the courage drops a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Fireworks",
        folder: "fireworks",
        restricted: true,
        premium: false,
        description:
            "Burst those fireworks to collect stars and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "fireworks1.jpg",
                text: "Collect stars by bursting the rockets that are flying up. Different rockets give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "fireworks2.jpg",
                text: "When the timer reaches zero, your game is over. Pop the Time Lantern to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "fireworks3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Energy Saver",
        folder: "energy_saver",
        restricted: true,
        premium: false,
        description:
            "Save Energy by tapping on windows who's light in on and answer questions to gain more time to do it in. ",
        instructions: [
            {
                header: "Save Energy",
                image: "energy_saver1.jpg",
                text: "Turn off the lights by tapping on them to save light energy, before your battery runs out. Your battery goes down faster if  more light energy are visible.",
            },
            {
                header: "Watch the Battery",
                image: "energy_saver2.jpg",
                text: "When your battery reaches zero, you game is over. Click the question icon to get quiz. Answer it correctly and your battery gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "energy_saver3.jpg",
                text: "As you save light energy,  you will level up. With each level you go up, the battery drops a little faster and the game gets a little tougher.",
            },
        ],
    },
];

export { GameSchema };
