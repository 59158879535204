import React, { useState } from "react";
import {
    useGetCourseLearnersQuery,
    useSendRemoveLearnerMutation,
} from "@api/apiV6";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import ReactTable from "react-table-v6";

import { Icon, Loader, Modal, Button } from "semantic-ui-react";
import { Div } from "@components/Generics.react";
export default function LearnerTable(props) {
    const { course, search } = props;
    const { data: courseLearners, isSuccess } = useGetCourseLearnersQuery(
        course._id
    );
    const [removeLearners] = useSendRemoveLearnerMutation();

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const { t } = useTranslation("common");

    function unEnrollUser() {
        removeLearners({ id: course?._id, user_id: selectedUserId });
        setModalOpen(false);
    }

    const selectedLearners = isSuccess
        ? _.filter(
              courseLearners,
              (learner) =>
                  learner.first_name.includes(search) ||
                  learner.last_name.includes(search) ||
                  learner.username.includes(search)
          )
        : [];

    const fullColumns = [
        {
            Header: t("builder.dic.name"),
            accessor: "name",
            Cell: (row) =>
                `${row.original.first_name} ${row.original.last_name}`,
            filterMethod: (filter, row) => {
                const fullName = `${row._original.first_name} ${row._original.last_name}`;
                return _.includes(
                    fullName.toLowerCase(),
                    filter.value.toLowerCase()
                );
            },
        },
        {
            Header: t("builder.dic.username"),
            accessor: "username",
            filterMethod: (filter, row) =>
                _.includes(
                    row._original.username.toLowerCase(),
                    filter.value.toLowerCase()
                ),
        },
        {
            Header: t("builder.dic.actions"),
            accessor: "_id",
            width: 80,
            Cell: (row) => (
                <Div fluid center-align clickable>
                    <Div txtDanger inline>
                        <Icon
                            name="trash"
                            onClick={() => {
                                setModalOpen(true);
                                setSelectedUserId(row.original._id);
                            }}
                            title={t("components.delete")}
                            disabled={row._id === course.owner._id}
                        />
                        <Modal
                            size="small"
                            open={modalOpen}
                            onClose={() => setModalOpen(false)}
                        >
                            <Modal.Header>
                                Last chance to reconsider...
                            </Modal.Header>
                            <Modal.Content>
                                <p>Are you sure you want to unenroll?</p>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    negative
                                    onClick={() => setModalOpen(false)}
                                >
                                    No
                                </Button>
                                <Button
                                    positive
                                    onClick={() => unEnrollUser(selectedUserId)}
                                >
                                    Yes
                                </Button>
                            </Modal.Actions>
                        </Modal>
                    </Div>
                </Div>
            ),
        },
    ].filter(Boolean);

    // ========================= Render Function =================================
    return (
        <Div>
            {isSuccess ? (
                <ReactTable
                    key={`table-${search}`}
                    data={selectedLearners}
                    filterable
                    columns={fullColumns}
                    pageSizeOptions={[10, 15, 30]}
                    defaultPageSize={10}
                    className="-striped -highlight"
                />
            ) : (
                <Loader />
            )}
        </Div>
    );
}
