import { useGetActivityLogQuery } from "@api/apiV6";
import ReactTable from "react-table-v6";
import { Loader } from "semantic-ui-react";
import { Div } from "@components/Generics.react";

const dateOpts = {
    year: "numeric",
    month: "short",
    day: "numeric",
};
const timeOpts = {
    hour: "2-digit",
    minute: "2-digit",
};

export default function ActivityLog(props) {
    const { userId } = props;
    const {
        data: logData,
        isSuccess: isLogDataSuccess,
        isError: isLogDataError,
        isLoading: isLogDataLoading,
    } = useGetActivityLogQuery(userId);

    let fullColumns = [
        {
            Header: "Event Time",
            accessor: "time",
            Cell: ({ value }) =>
                `${new Date(value).toLocaleDateString(
                    "en-US",
                    dateOpts
                )} ${new Date(value).toLocaleTimeString("en-US", timeOpts)}`,
            style: { textAlign: "left" },
            width: 160,
        },
        {
            Header: "Event",
            accessor: "event",
            Cell: ({ value }) => value,
            style: { textAlign: "left", whiteSpace: "unset" },
        },
    ];

    if (isLogDataLoading) {
        return <Loader />;
    } else if (isLogDataSuccess && !isLogDataError) {
        return (
            <ReactTable
                data={logData}
                filterable
                defaultFilterMethod={(filter, row, column) => {
                    const id = filter.pivotId || filter.id;
                    return row[id] !== undefined
                        ? String(row[id])
                              .toLowerCase()
                              .includes(filter.value.toLowerCase())
                        : true;
                }}
                columns={fullColumns}
                pageSizeOptions={[5, 10]}
                defaultPageSize={10}
                className="-striped -highlight"
            />
        );
    } else {
        return <Div>Error</Div>;
    }
}
