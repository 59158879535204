import React, { useState } from "react";
import {
    useGetProgramInventoryQuery,
    useDeleteProgramMutation,
} from "@api/apiV6";
import _ from "lodash";
import ReactTable from "react-table-v6";

import { Div, Button } from "@components/Generics.react";
import { Loader, Icon, Confirm } from "semantic-ui-react";
import UpsertProgramModal from "./UpsertProgramModal.react";

export default function AdminProgramManagement(props) {
    const {
        data: entityList,
        isSuccess,
        isLoading,
        refetch,
    } = useGetProgramInventoryQuery();
    const [deleteProgram] = useDeleteProgramMutation();

    const [newModalOpen, setNewModalOpen] = useState(false);
    const [selectedProgram, setSelectedProgram] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [targetProgram, setTargetProgram] = useState(null);

    let fullColumns = [
        {
            Header: "Program Name",
            id: "name",
            accessor: "name",
            Cell: ({ row }) => {
                return (
                    <Div
                        txtMain
                        clickable
                        onClick={() => {
                            setSelectedProgram(row._original);
                            setNewModalOpen(true);
                        }}
                    >
                        {row._original.name}
                    </Div>
                );
            },
            filterMethod: (filter, row) =>
                _.includes(row.name.toLowerCase(), filter.value.toLowerCase()),
        },
        {
            Header: "Type",
            id: "type",
            accessor: "owner",
            Cell: ({ row }) => (
                <Div center-align>{row._original.list_type}</Div>
            ),
            filterMethod: (filter, row) =>
                _.includes(
                    row.original.list_type.toLowerCase(),
                    filter.value.toLowerCase()
                ),
        },
        {
            Header: "Owner",
            id: "owner",
            accessor: "owner",
            Cell: ({ row }) => <Div center-align>{row.owner?.username}</Div>,
            filterMethod: (filter, row) =>
                _.includes(
                    row.owner?.username.toLowerCase(),
                    filter.value.toLowerCase()
                ),
        },
        {
            Header: "Team",
            id: "team",
            accessor: "owner",
            Cell: ({ row }) => <Div center-align>{row.owner?.team}</Div>,
            filterMethod: (filter, row) =>
                _.includes(
                    row.owner?.team.toLowerCase(),
                    filter.value.toLowerCase()
                ),
        },
        {
            Header: "Content Count",
            id: "node_list",
            accessor: "node_list",
            Cell: ({ row }) => <Div center-align>{row.node_list.length}</Div>,
            filterMethod: (filter, row) =>
                filter.value === "0"
                    ? row.node_list.length === 0
                    : row.node_list.length >= filter.value,
        },
        {
            Header: "Actions",
            id: "id",
            accessor: "id",
            Cell: ({ row }) => {
                const deletable = row._original.node_list.length === 0;
                return (
                    <Div
                        center-align
                        txtDanger
                        clickable={deletable}
                        title={
                            !deletable
                                ? "Remove all courses first to delete"
                                : "Click to delete"
                        }
                    >
                        <Icon
                            name={deletable ? "trash" : "lock"}
                            onClick={() => {
                                if (deletable) {
                                    setTargetProgram(row._original._id);
                                    setConfirmDelete(true);
                                }
                            }}
                        />
                    </Div>
                );
            },
        },
    ];

    function closeModal() {
        setNewModalOpen(false);
        setSelectedProgram(null);
    }
    // ========================= Render Function =================================
    if (isLoading || !isSuccess) return <Loader active />;
    else
        return (
            <Div fullht>
                <Div fluid fullht>
                    <Div white medpad ht="calc(100% - 123px)">
                        <Div fluid ash rimmed flex spaceBetween>
                            <Button
                                size="small"
                                secondary
                                icon="refresh"
                                labelPosition="left"
                                content={"Refresh Programs List"}
                                onClick={refetch}
                            />
                            <Div>
                                <Button
                                    size="small"
                                    primary
                                    icon="plus"
                                    labelPosition="left"
                                    content={"Create New Program"}
                                    onClick={() => setNewModalOpen(true)}
                                />
                            </Div>
                        </Div>
                        <ReactTable
                            data={entityList}
                            filterable
                            defaultFilterMethod={(filter, row, column) => {
                                const id = filter.pivotId || filter.id;
                                return row[id] !== undefined
                                    ? String(row[id])
                                          .toLowerCase()
                                          .includes(filter.value.toLowerCase())
                                    : true;
                            }}
                            columns={fullColumns}
                            pageSizeOptions={[5, 8]}
                            defaultPageSize={8}
                            className="-striped -highlight"
                        />
                    </Div>
                </Div>
                <Confirm
                    open={confirmDelete}
                    onCancel={() => setConfirmDelete(false)}
                    onConfirm={() => {
                        deleteProgram(targetProgram);
                        setConfirmDelete(false);
                    }}
                />
                {newModalOpen && (
                    <UpsertProgramModal
                        key={
                            selectedProgram
                                ? selectedProgram._id
                                : "new-program"
                        }
                        isOpen={newModalOpen}
                        setOpen={closeModal}
                        program={selectedProgram}
                    />
                )}
            </Div>
        );
}
