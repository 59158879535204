import React, { Suspense, useLayoutEffect, useState, useEffect } from "react";
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
    Outlet,
    useLocation,
} from "react-router-dom";
import _ from "lodash";
import jwt from "jsonwebtoken";
import { useSelector } from "react-redux";
import { store } from "./config/store/app.store";

import { I18nextProvider, initReactI18next } from "react-i18next";
import i18next from "i18next";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FlagsProvider } from "flagged";
import featureMap from "./config/features/features.json";
import abilityMap from "./config/features/ability.json";
import clientMap from "./config/features/tenants.json";
import { planMap } from "./config/features/licenses/planMap.js";

import common_de from "./config/translations/de/common.json";
import common_en from "./config/translations/en/common.json";
import common_hi from "./config/translations/hi/common.json";
import common_mr from "./config/translations/mr/common.json";
import common_bn from "./config/translations/bn/common.json";
import common_kn from "./config/translations/kn/common.json";
import common_ml from "./config/translations/ml/common.json";
import common_ta from "./config/translations/ta/common.json";
import common_te from "./config/translations/te/common.json";

import "semantic-ui-css/semantic.min.css";
import "./index.css";
import "@styles/overrule.scss";
import { devMode } from "./utilities/helpers";
import { settings } from "./config/settings/app.settings";
import { apiV6, targetRoute } from "./config/api/apiV6";
import { logout } from "@reducers/authSlice";

//-----------------------------------------------------------------------------
// Player Modules
//-----------------------------------------------------------------------------
import Register from "./pages/authentication/Registration.react";
import Login from "./pages/authentication/Login.react";
import Logout from "./pages/authentication/Logout.react";
import ForgotPassword from "./pages/authentication/ForgotPassword.react";
import ChangePassword from "./pages/authentication/ChangePassword.react";
import Home from "./pages/player/home/Home.react";
import Library from "./pages/player/courses/Library.react";
import Course from "./pages/player/courses/Course.react";
import SingleCourse from "./pages/player/courses/SingleCourse.react";
import Program from "./pages/player/courses/Program.react";
import Article from "./pages/player/articles/Article.react";
import ArticleListing from "./pages/player/articles/ArticleListing.react";
import Social from "./pages/player/social/Social.react";
import AppReports from "./pages/player/reports/AppReports.react";
import AppLeaderboard from "./pages/player/reports/AppLeaderboard.react";
import AppCollectibles from "./pages/player/reports/AppCollectibles.react";
import Contest from "./pages/player/contests/Contest.react";
import EditProfile from "./pages/player/user/EditProfile.react";
import AppHome from "./pages/player/home/AppHome.react";
import SearchResults from "./pages/player/home/panels/SearchResults.react";
import GamifixPerformance from "./pages/player/client/ekart/GamifixPerformance.react";
import CleanAppLeaderboard from "./pages/player/reports/CleanAppLeaderboard.react";
import CleanAppCollectibles from "./pages/player/reports/CleanAppCollectibles.react";
import ComingSoon from "./pages/player/home/ComingSoon.react";

import HotSpotEditor from "./pages/creator/courses/HotSpotEditor.react";
import ArticleEditor from "./pages/creator/articles/ArticleEditor.react";
import BuilderHome from "./pages/creator/home/BuilderHome.react";
import BuilderLibrary from "./pages/creator/library/BuilderLibrary.react";
import DeckEditor from "./pages/creator/decks/DeckEditor.react";
import CourseEditor from "./pages/creator/courses/CourseEditor.react";
import BuilderAnalytics from "./pages/creator/analytics/BuilderAnalytics.react.jsx";
import UnauthArticle from "./pages/player/articles/UnauthArticle.react";
import ProgramEditor from "./pages/creator/programs/ProgramEditor.react";
import Unauthorized from "./components/Unauthorized.react";
import ContestEditor from "./pages/creator/contests/ContestEditor.react";
import ContestsLibrary from "./pages/creator/library/ContestsLibrary.react";
import CreatorHelpDesk from "./pages/helpdesk/CreatorHelpDesk.react";
import LearnerHelpDesk from "./pages/helpdesk/LearnerHelpDesk.react";
import CommunityManager from "./pages/creator/social/CommunityManager.react.jsx";
import RootPage from "./pages/player/home/RootPage.react.jsx";
import UserManagement from "./pages/creator/admin/UserManagement.react.jsx";
// import OrgAnalytics from "./pages/creator/analytics/OrgAnalytics.react.jsx";
import Favorites from "./pages/player/articles/Favorites.react.jsx";
import TeamDesign from "./pages/creator/admin/TeamDesign.react.jsx";
import SuperAdmin from "./pages/admin/SuperAdmin.react.jsx";
import SingleProgram from "./pages/player/courses/SingleProgram.react.jsx";
import MyAccount from "./pages/creator/account/MyAccount.react.jsx";
import Registered from "./pages/authentication/Registered.react.jsx";
import TutorialsPage from "./pages/creator/home/TutorialsPage.react.jsx";
import GuestLogin from "./pages/authentication/GuestLogin.react.jsx";
import CodeLogin from "./pages/authentication/CodeLogin.react.jsx";
import ArticleLanding from "./pages/authentication/ArticleLanding.react.jsx";
import BuilderRepo from "./pages/creator/library/BuilderRepo.react.jsx";
import GuestHome from "./pages/player/home/GuestHome.react.jsx";

// Notes for route specifications
// - If the id is course id, please use :courseId
// - If the id is topic id, please use :topicId
// - If the id is deck id, please use :deckId

function App() {
    const isUserLoggedIn = useSelector((state) => state.auth.isUserLoggedIn);
    const userToken = useSelector((state) => state.auth.token);
    const userLang = useSelector((state) => state.auth.user?.lang);
    const userLicense = useSelector((state) => state.auth.user?.license);
    const userTeam = useSelector((state) => state.auth.user?.team);
    const userName = useSelector(
        (state) =>
            `${state.auth.user?.first_name} ${state.auth.user?.last_name}`
    );

    const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
    const isPortrait = window.innerHeight > window.innerWidth;

    const searchParams = new URLSearchParams(document.location.search);
    const authToken = searchParams.get("token");
    const hasToken = authToken !== null;

    const storedTeam = window.localStorage.getItem("team");

    let loginPath =
        featureMap.tenanting.multiTenanted &&
        storedTeam &&
        !featureMap.tenanting.commonLogin
            ? settings.auth.routes.teamLogin.replace(":team", storedTeam)
            : settings.auth.routes.login;
    const fromBlacklist = ["/logout"];

    i18next.use(initReactI18next).init({
        interpolation: { escapeValue: false }, // React already does escaping
        lng: userLang, // language to use
        fallbackLng: "en",
        resources: {
            en: {
                common: common_en, // 'common' is our custom namespace
            },
            de: {
                common: common_de,
            },
            hi: {
                common: common_hi,
            },
            mr: {
                common: common_mr,
            },
            bn: {
                common: common_bn,
            },
            kn: {
                common: common_kn,
            },
            ml: {
                common: common_ml,
            },
            ta: {
                common: common_ta,
            },
            te: {
                common: common_te,
            },
        },
    });

    function loadLibraries() {
        store.dispatch(apiV6.endpoints.getArticles.initiate());
        store.dispatch(apiV6.endpoints.getCourseLibrary.initiate());
    }

    useEffect(() => {
        if (isUserLoggedIn) {
            setTimeout(() => {
                loadLibraries();
            }, 5000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUserLoggedIn]);

    function doLogin(location) {
        const teamRoute = /^\/a\/[^\/]+\/[^\/]+$/.test(location.pathname);
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        if (hasToken) loginPath += `?token=${authToken}`;

        const isUnauthRoute = _.includes(location.pathname, "login");
        if (!isUnauthRoute)
            localStorage.setItem("redirectPath", location.pathname);

        return (
            <Navigate
                to={
                    loginPath.startsWith("http")
                        ? (window.location.href = loginPath)
                        : featureMap.tenanting.multiTenanted && teamRoute
                        ? settings.auth.routes.teamLogin.replace(
                              ":team",
                              location.pathname.split("/a/")[1].split("/")[0]
                          )
                        : settings.auth.routes.login
                }
                state={{
                    from: fromBlacklist.includes(location.pathname)
                        ? null
                        : location,
                }}
                replace
            />
        );
    }
    function RequireAuth() {
        const location = useLocation();
        // Decode the token and get the expiration date
        const decodedToken = jwt.decode(hasToken ? authToken : userToken, {
            complete: true,
        });
        const expirationDate =
            decodedToken && decodedToken.payload.exp
                ? new Date(decodedToken.payload.exp * 1000)
                : null;
        const currentDate = new Date();
        const isTokenValid = currentDate <= expirationDate;
        if (isTokenValid) {
            if (!isUserLoggedIn) return doLogin(location);
            else if (userName === "Guest User") {
                localStorage.setItem("redirectPath", location.pathname);
                return <GuestHome />;
            } else return <Outlet />;
        } else {
            store.dispatch(logout());
            return doLogin(location);
        }
    }
    function DoNotRequireAuth() {
        const location = useLocation();
        if (!isUserLoggedIn) {
            return <Outlet />;
        } else if (location.pathname === loginPath) {
            if (hasToken) {
                return <Outlet />;
            } else
                return (
                    <Navigate
                        to={
                            !isPortrait &&
                            featureMap.general.builderRouting &&
                            _.includes(
                                abilityMap.builder.visible,
                                userLicense.license_type
                            )
                                ? "/builder"
                                : "/"
                        }
                        replace
                    />
                );
        } else if (
            location.pathname.includes("worksheet") ||
            location.pathname.includes("register")
        ) {
            return <Outlet />;
        }
        return false;
    }
    function RequireBuilder() {
        const isBuilder = abilityMap.builder.visible.includes(
            userLicense.license_type
        );
        if (isBuilder) {
            return <Outlet />;
        }
        return <Unauthorized />;
    }
    function RequireAdmin() {
        const isAdmin = abilityMap.adminPanel.visible.includes(
            userLicense.license_type
        );
        if (isAdmin) {
            return <Outlet />;
        }
        return <Unauthorized />;
    }

    // Handle Resizing in the app
    useLayoutEffect(() => {
        const isMobileLandscape =
            window.innerHeight < window.innerWidth && window.innerHeight < 400;
        if (!isMobileLandscape || !settings.mobileBuild) {
            setTimeout(function () {
                let viewheight = window.innerHeight;
                let viewwidth = window.innerWidth;
                let viewport = document.querySelector("meta[name=viewport]");
                viewport.setAttribute(
                    "content",
                    `height=${viewheight}px, width=${viewwidth}px, initial-scale=1`
                );
            }, 300);
        }
    }, []);

    // Handle Resizing in the app
    useLayoutEffect(() => {
        function updateSize() {
            if (!window.doNotResize && devMode)
                setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateSize);
        if (window.innerWidth !== size[0] || window.innerHeight !== size[1])
            updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, [size]);

    // Handle License based access in the app
    function buildLicenseMap(abilityObject, role) {
        const result = {};

        // Loop through each entity (e.g., "program" and "course") in the ability object
        for (const entity in abilityObject) {
            if (abilityObject.hasOwnProperty(entity)) {
                result[entity] = {};
                // Loop through each action for the current entity
                for (const action in abilityObject[entity]) {
                    if (abilityObject[entity].hasOwnProperty(action)) {
                        // Check if the role has permission for the current action
                        result[entity][action] =
                            abilityObject[entity][action].includes(role);
                    }
                }
            }
        }

        return result;
    }
    const featuresMap =
        settings.functionality.plansActive &&
        userLicense &&
        _.includes(_.keys(planMap), userLicense.license_name)
            ? planMap[userLicense.license_name]
            : featureMap;
    const licenseMap =
        isUserLoggedIn && buildLicenseMap(abilityMap, userLicense.license_type);
    const tenantMap =
        isUserLoggedIn && clientMap[userTeam] ? clientMap[userTeam] : "default";

    return (
        <FlagsProvider
            features={{
                ...featuresMap,
                ...{ ability: licenseMap },
                ...{ tenantMap: tenantMap },
            }}
        >
            <I18nextProvider i18n={i18next}>
                <Suspense fallback="loading" size={size}>
                    <BrowserRouter basename={targetRoute}>
                        <Routes>
                            <Route element={<DoNotRequireAuth />}>
                                <Route
                                    path="/:team/login"
                                    element={<Login />}
                                />
                                <Route path="/login" element={<Login />} />
                                <Route
                                    path="/register"
                                    element={<Register />}
                                />
                                <Route
                                    path="/:team/register"
                                    element={<Register />}
                                />
                                <Route
                                    path="/guest/login"
                                    element={<GuestLogin />}
                                />
                                <Route
                                    path="/:team/guest/login"
                                    element={<GuestLogin />}
                                />
                                <Route
                                    path="/code/:ctype"
                                    element={<CodeLogin />}
                                />
                                <Route
                                    path="/:team/code/:ctype"
                                    element={<CodeLogin />}
                                />
                                <Route path="/code" element={<CodeLogin />} />
                                <Route
                                    path="/forgot"
                                    element={<ForgotPassword />}
                                />
                                <Route
                                    path="/play/:identifier/:name"
                                    element={<UnauthArticle />}
                                />
                                <Route
                                    path="/builder/registered"
                                    element={<Registered />}
                                />
                                <Route
                                    path="/worksheet/:code"
                                    element={<ArticleLanding />}
                                />
                            </Route>
                            <Route element={<RequireAuth />}>
                                <Route
                                    path="/"
                                    element={
                                        settings.functionality.hasHome &&
                                        isPortrait &&
                                        featureMap.learningCenter ? (
                                            <AppHome />
                                        ) : featureMap.learningCenter ? (
                                            <Home />
                                        ) : (
                                            <Library />
                                        )
                                    }
                                />
                                <Route
                                    path="/goto/:section"
                                    element={<RootPage />}
                                />
                                <Route
                                    path="/learningcenter"
                                    element={<Home />}
                                />
                                <Route
                                    path="/tags/:name"
                                    element={<ArticleListing />}
                                />
                                <Route
                                    path="/favorites"
                                    element={<Favorites />}
                                />
                                <Route
                                    path="/content/:readerType/:articleIdentifier"
                                    element={<Article />}
                                />
                                <Route
                                    path="/:readerType/:articleIdentifier"
                                    element={<Article />}
                                />
                                <Route
                                    path="/a/:articleIdentifier"
                                    element={<Article />}
                                />
                                <Route
                                    path="/a/:team/:articleIdentifier"
                                    element={<Article />}
                                />
                                <Route path="/library" element={<Library />} />
                                <Route
                                    path="/programs/:programId"
                                    element={<Program />}
                                />
                                <Route
                                    path="/programs/:programId/isolated"
                                    element={<SingleProgram />}
                                />
                                <Route
                                    path="/programs/listing"
                                    element={<SingleProgram />}
                                />
                                <Route path="/library" element={<Library />} />
                                <Route path="/social" element={<Social />} />
                                <Route
                                    path="/courses/:courseId"
                                    element={<Course />}
                                />
                                <Route
                                    path="/c/:courseIdentifier"
                                    element={<Course />}
                                />
                                <Route
                                    path="/explore/:courseId"
                                    element={<SingleCourse />}
                                />
                                <Route
                                    path="/e/:courseIdentifier"
                                    element={<SingleCourse />}
                                />
                                <Route path="/logout" element={<Logout />} />
                                <Route
                                    path="/search"
                                    element={<SearchResults />}
                                />
                                <Route
                                    path="/app/leaderboard"
                                    element={<AppLeaderboard />}
                                />
                                <Route
                                    path="/view/leaderboard"
                                    element={<CleanAppLeaderboard />}
                                />
                                <Route
                                    path="/app/rewards"
                                    element={<AppCollectibles />}
                                />
                                <Route
                                    path="/view/rewards"
                                    element={<CleanAppCollectibles />}
                                />
                                <Route
                                    path="/app/reports"
                                    element={<AppReports />}
                                />
                                <Route
                                    path="/app/helpdesk"
                                    element={<LearnerHelpDesk />}
                                />
                                <Route
                                    path="/editProfile/:isolated"
                                    element={<EditProfile />}
                                />
                                <Route path="/contests" element={<Contest />} />
                                <Route
                                    path="/social/:postId"
                                    element={<Social />}
                                />
                                <Route
                                    path="/setpass"
                                    element={<ChangePassword />}
                                />
                                <Route
                                    path="/comingsoon"
                                    element={<ComingSoon />}
                                />

                                <Route
                                    path="/clients/ekart/dashboard"
                                    element={<GamifixPerformance />}
                                />
                                {/* Creator Routes */}
                                {/* ------------------------------------------ */}
                                <Route element={<RequireBuilder />}>
                                    <Route
                                        path="/builder"
                                        element={<BuilderHome />}
                                    />
                                    <Route
                                        path="/builder/tutorials"
                                        element={<TutorialsPage />}
                                    />
                                    <Route
                                        path="/builder/library"
                                        element={<BuilderLibrary />}
                                    />
                                    <Route
                                        path="/builder/repository"
                                        element={<BuilderRepo />}
                                    />
                                    <Route
                                        path="/builder/contests"
                                        element={<ContestsLibrary />}
                                    />
                                    <Route
                                        path="/builder/programs/:id/:section"
                                        element={<ProgramEditor />}
                                    />
                                    <Route
                                        path="/builder/courses/:id/:section"
                                        element={<CourseEditor />}
                                    />
                                    <Route
                                        path="/builder/contests/:id/:section"
                                        element={<ContestEditor />}
                                    />
                                    <Route
                                        path="/builder/teamdesign"
                                        element={<TeamDesign />}
                                    />
                                    <Route
                                        path="/builder/articles/:readerType/:id/:section"
                                        element={<ArticleEditor />}
                                    />
                                    <Route
                                        path="/builder/editor/:readerType/:id/:breadcrumbs"
                                        element={<DeckEditor />}
                                    />
                                    <Route
                                        path="/builder/social"
                                        element={<CommunityManager />}
                                    />
                                    <Route
                                        path="/builder/helpdesk"
                                        element={<CreatorHelpDesk />}
                                    />
                                    <Route
                                        path="/builder/analytics"
                                        element={<BuilderAnalytics />}
                                    />
                                    <Route
                                        path="/builder/users"
                                        element={<UserManagement />}
                                    />
                                    <Route
                                        path="/builder/account"
                                        element={<MyAccount />}
                                    />
                                    {/* Admin Routes */}
                                    {/* ------------------------------------------ */}
                                    <Route element={<RequireAdmin />}>
                                        <Route
                                            path="/hotspotter"
                                            element={<HotSpotEditor />}
                                        />
                                        <Route
                                            path="/adminPanel"
                                            element={<SuperAdmin />}
                                        />
                                    </Route>
                                </Route>
                            </Route>
                        </Routes>
                    </BrowserRouter>
                    <ToastContainer
                        position={isPortrait ? "top-center" : "bottom-left"}
                        autoClose={5000}
                        closeOnClick={true}
                    />
                </Suspense>
            </I18nextProvider>
        </FlagsProvider>
    );
}

export default App;
