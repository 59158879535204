import React, { useState, useMemo } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Tab, Dropdown } from "semantic-ui-react";
import { Div } from "@components/Generics.react";
import SurveyLearnersResponses from "../surveys/SurveyLearnersResponses.react";
import SurveyQuestionsResponses from "../surveys/SurveyQuestionsResponses.react";

export default function CourseSurveys(props) {
    const { course } = props;
    const [selectedFile, setSelectedFile] = useState(null);
    const { t } = useTranslation("common");
    const isPortrait = window.innerHeight > window.innerWidth;

    const fileList = useMemo(
        () =>
            _.flatMap(course.locations, (topic) => {
                return _.map(
                    _.filter(topic.contentList, { readerType: "survey" }),
                    (deck) => {
                        return {
                            key: deck._id,
                            text: topic.name + " :: " + deck.name,
                            value: deck._id,
                            readertype: deck.readerType,
                            dname: deck.name,
                        };
                    }
                );
            }),
        [course]
    );

    const panes = [
        {
            menuItem: "Learner Responses",
            render: () => (
                <Tab.Pane>
                    <SurveyLearnersResponses deckId={selectedFile} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Question Responses",
            render: () => (
                <Tab.Pane>
                    <SurveyQuestionsResponses deckId={selectedFile} />
                </Tab.Pane>
            ),
        },
    ];

    return (
        <>
            <Div flex clearfix gapBottom spaceBetween>
                <Div wd="190px" big uppercase bold>
                    {t("analytics.selectSurvey")}
                </Div>
                <Div wd={`calc(100% - 210px)`} bold>
                    <Dropdown
                        placeholder={t("analytics.selectResponse")}
                        search
                        fluid
                        selection
                        upward={isPortrait}
                        options={fileList}
                        value={selectedFile}
                        onChange={(e, { value }) => setSelectedFile(value)}
                    />
                </Div>
            </Div>
            {selectedFile != null ? (
                <Tab panes={panes} />
            ) : (
                <Div center-align large bold megapad>
                    {t("analytics.selectResponse")}
                </Div>
            )}
        </>
    );
}
