// -------------------------------------
// Import from NPM
// -------------------------------------
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Label, Search, Icon } from "semantic-ui-react";
import _ from "lodash";
import { Div } from "@components/Generics.react";
import { useSendSearchHistoryMutation } from "@api/apiV6";

// -------------------------------------
// Import Styles
// -------------------------------------
import "@styles/components.scss";
import { deckFormats } from "@schemas/deckFormats";

// -------------------------------------
// Constants
// -------------------------------------
// prettier-ignore
const blacklist = ["","and","or","but","nor","for","yet","so","in","on","at","by","to","from","with","over","under","into","through","after","before","during","about","above","below","behind","beside","beyond","since","until","unless","although","because","if","where","whether","while","than","throughout","whereas","wherever","though","whenever","whilst","however","notwithstanding","provided","providing","as","that","inasmuch","till","lest","much","order","only","even","rather","when",
];

export default function SearchField(props) {
    const { t } = useTranslation("common");
    const { placeholder = t("search.placeholder"), exSearch = "" } = props;
    const articles = useSelector((state) => state.articles);
    const [searchTerm, setSearchTerm] = useState(exSearch);
    const [filteredArticles, setFilteredArticles] = useState([]);

    const navigate = useNavigate();
    const [saveSearch] = useSendSearchHistoryMutation();

    const allCategories = _.map(_.values(deckFormats), "category");
    const allReaderTypes = _.map(_.values(deckFormats), "readerType");

    useEffect(() => {
        if (exSearch !== "undefined") {
            if (_.includes(allCategories, exSearch))
                setSearchTerm(deckFormats[exSearch].name);
            else if (_.includes(allReaderTypes, exSearch))
                setSearchTerm(
                    _.find(deckFormats, { readerType: exSearch }).name
                );
            else setSearchTerm(_.capitalize(exSearch));
        } else {
            setSearchTerm("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exSearch]);

    const handleSearch = (searchText) => {
        const findWordWithSubstring = (str, substr) => {
            const cleanedStr = str.replace(/[^a-zA-Z0-9_ -]/g, "");
            const cleanedSubstr = substr.replace(/[^a-zA-Z0-9_ -]/g, "");
            const substrWords = cleanedSubstr.split(" ");

            // Split the string into an array of words
            const words = cleanedStr.split(" ");

            const foundSubstrings = [];

            for (let i = 0; i <= words.length - substrWords.length; i++) {
                const windowWords = words.slice(i, i + substrWords.length);
                const windowString = windowWords.join(" ");

                if (
                    windowString
                        .toLowerCase()
                        .startsWith(cleanedSubstr.toLowerCase())
                ) {
                    foundSubstrings.push(_.capitalize(windowString));
                }
            }

            return foundSubstrings.join(" ");
        };

        const countUniqueElements = (arr) => {
            // Use lodash's `countBy` function to get the count of each element
            const elementCountMap = _.countBy(arr);

            // Convert the elementCountMap to the desired format
            return Object.entries(elementCountMap).map(
                ([title, count]) => ({ title, count, searchfor: searchText })
            );

        };
        const lowerCaseSearchTerm = searchText.toLowerCase();
        const searchResultTerms = _.map(
            _.orderBy(
                _.filter(articles.list, (article) => {
                    return article.name.toLowerCase().includes(lowerCaseSearchTerm);
                }),
                ["priority"],
                ["desc"]
            ),
            (thisArticle) => findWordWithSubstring(thisArticle.name, searchText)
        ).slice(0, 7);

        const customComparator = (a, b) =>
            _.isEqual(a.toLowerCase(), b.toLowerCase());
        let fArticles = _.orderBy(
            countUniqueElements(
                _.differenceWith(searchResultTerms, blacklist, customComparator)
            ),
            ["count"],
            ["desc"]
        );

        const allTags = _.keys(articles.taggedList);
        const matchingTags =
            lowerCaseSearchTerm.length > 2
                ? _.uniqBy(
                      _.map(
                          _.filter(allTags, (tag) =>
                              tag.includes(lowerCaseSearchTerm)
                          ),
                          (thisTag, idx) => {
                            let title;
                            if (_.includes(allCategories, thisTag)) {
                                title = _.find(_.values(deckFormats), {
                                    category: thisTag
                                })
                                    .name;
                            } else if (_.includes(allReaderTypes, thisTag)) {
                                title = _.find(_.values(deckFormats), { readerType: thisTag }).name;
                            } else {
                                title = _.capitalize(thisTag).replace(/_/g, " ");
                            }
                            return {
                                key: `taggy-${idx}`, title, searchfor: thisTag
                              };
                          }
                      ),
                      "title"
                  )
                : [];
        setFilteredArticles(_.union(matchingTags, fArticles));
        setSearchTerm(searchText);
    };

    const handleKeyUp = (e) => {
        if (e.key === "Enter" && searchTerm.length > 2) {
            handleSubmit(searchTerm);
        }
    };

    const handleResultSelect = (event, { result }) => {
        setSearchTerm(result.title);
        handleSubmit(result);
    };

    function handleSubmit(term) {
        saveSearch(term.title);
        if (term.searchfor) navigate(`/search?text=${term.searchfor}`);
        else navigate(`/search?text=${term}`);
    }

    return (
        <Div inline>
            <Search
                fluid
                input={{
                    icon: (
                        <Icon
                            name="search"
                            link
                            onClick={() => handleSubmit(searchTerm)}
                        />
                    ),
                }}
                placeholder={placeholder}
                minCharacters={3}
                value={searchTerm}
                onSearchChange={(event, data) => handleSearch(data.value)}
                onResultSelect={handleResultSelect}
                onKeyUp={handleKeyUp}
                results={filteredArticles}
                resultRenderer={({ title, count }) => (
                    <div key={"results"}>
                        {title}
                        {count ? (
                            <Label style={{ float: "right" }}>{count}</Label>
                        ) : (
                            <Icon style={{ float: "right" }} name="tag" />
                        )}
                    </div>
                )}
            />
        </Div>
    );
}
