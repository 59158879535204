import _ from "lodash";

export const updateCourse = (
    currentState,
    attempt,
    topicId,
    topicCompletion
) => {
    const { completion, timeSpent, pointsDelta, date, deckIsFinalExam, score } =
        attempt;

    const presentState = {
        viewedPercentage: currentState.viewedPercentage ?? 0,
        viewAttempts: currentState.viewAttempts ?? 0,
        timeSpent: currentState.timeSpent ?? 0,
        score: currentState.score,
        points: currentState.points ?? 0,
        completion: currentState.completion ?? false,
        completionDate: currentState.completionDate,
        subStates: currentState.subStates,
    };

    //---------------------------------------------------------------------------
    // Viewed percentage of Course is percentage of completed topics in the course
    //---------------------------------------------------------------------------
    let subStates = { ...presentState.subStates };
    subStates[topicId] = subStates[topicId] || topicCompletion; // If topic is already marked complete or is completed now
    subStates = _.omit(subStates, ["null", "false", "undefined"]);
    const totalLength = _.keys(subStates).length;
    const completedLength = _.filter(
        _.values(subStates),
        (topicIdStatus) => topicIdStatus === true
    ).length;
    const viewedPercentage = getPercent(completedLength, totalLength);

    //---------------------------------------------------------------------------
    // Completion of course is completion of final exam or percentage of completed decks
    //---------------------------------------------------------------------------
    const courseCompleted = deckIsFinalExam
        ? completion
        : viewedPercentage === 100;

    //---------------------------------------------------------------------------
    // If this deck is a final exam, score of course is higher of current score and past score
    //---------------------------------------------------------------------------
    const courseScore = deckIsFinalExam
        ? Math.max(presentState.score, score)
        : presentState.score;

    const courseCompletionDate =
        courseCompleted && presentState.completionDate == null
            ? date
            : presentState.completionDate;

    return {
        viewedPercentage: viewedPercentage,
        viewAttempts: presentState.viewAttempts + 1,
        timeSpent: presentState.timeSpent + (timeSpent ?? 0),
        score: courseScore,
        points: presentState.points + (pointsDelta ?? 0),
        completion: courseCompleted,
        completionDate: courseCompletionDate,
        lastPosition: topicId,
        subStates: subStates,
    };
};

const getPercent = (x, y) => Math.min(100, Math.round((x * 100) / y));
