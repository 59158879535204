// Import from NPM
// -------------------------------------
import React from "react";
import { useSelector } from "react-redux";
import { Icon, Menu, Tab } from "semantic-ui-react";

// Import from Config
// -------------------------------------
import "@styles/layouts.scss";
import { Div } from "@components/Generics.react";
import { darkStyle } from "@utilities/helpers";
import { settings } from "@config/settings/app.settings";

export default function IsolatedPortrait(props) {
    const { course, mainPanel, rightPanel } = props;
    const user = useSelector((state) => state.auth.user);

    function tabBtn(icon, name) {
        const footerBtnWd = (window.innerWidth - 154) / 2;
        return (
            <Div
                relative
                wd={`${footerBtnWd}px`}
                className="footer-btn"
                style={darkStyle(
                    user.branding?.colors?.main || settings.colors.main,
                    "to top"
                )}
            >
                <Div className="footer-btn-content">
                    <Icon name={icon} />
                    <Div>{name}</Div>
                </Div>
            </Div>
        );
    }
    const panes = rightPanel && [
        {
            menuItem: (
                <Menu.Item key="main-panel-trigger">
                    <Div
                        className="mobile-tab-course-img"
                        style={darkStyle(
                            user.branding?.colors?.main ||
                                settings.colors.main?.primary,
                            "to top"
                        )}
                    >
                        <Div
                            className="mobile-tab-course-img-inner"
                            fluid
                            fullht
                            style={{
                                backgroundImage: `url(${
                                    course?.image ||
                                    "/assets/images/configurable/missing_course.jpg"
                                })`,
                                backgroundSize: "cover",
                                backgroundPosition: "center center",
                            }}
                        />
                    </Div>
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane attached="top" className="mobile-view-tab">
                    <Div
                        className="main-panel"
                        relative
                        fitted
                        white
                        fullht
                        autoOverflow
                    >
                        {mainPanel}
                    </Div>
                </Tab.Pane>
            ),
        },
        {
            menuItem: (
                <Menu.Item key="right-panel-trigger">
                    {tabBtn(
                        rightPanel.primary.icon,
                        rightPanel.primary.btnText
                    )}
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane attached="top" className="mobile-view-tab">
                    {rightPanel.primary.component}
                </Tab.Pane>
            ),
        },
        {
            menuItem: (
                <Menu.Item key="left-panel-trigger">
                    {tabBtn(
                        rightPanel.secondary.icon,
                        rightPanel.secondary.btnText
                    )}
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane attached="top" className="mobile-view-tab">
                    {rightPanel.secondary.component}
                </Tab.Pane>
            ),
        },
    ];

    return (
        <Div className="single-course-mobile player-view" layer={-1} fluid>
            {rightPanel ? (
                <Tab menu={{ attached: "bottom" }} panes={panes} />
            ) : (
                <Div
                    className="main-panel"
                    relative
                    fitted
                    white
                    fullht
                    autoOverflow
                >
                    {mainPanel}
                </Div>
            )}
        </Div>
    );
}
