import React, { useContext, useState } from "react";
import _ from "lodash";
import { clientAssetsPath } from "@api/apiV6";
import { Div, Image, Button, Icon } from "@components/Generics.react";
import Carousel from "@components/Carousel.react";
import { LayoutContext } from "@layouts/MasterLayout.react";

export default function PopupList(props) {
    const { popupitems } = props.data;
    const { fullScreen } = useContext(LayoutContext);
    const [showItems, setShowItems] = useState(false);
    const [targetIndex, setTargetIndex] = useState(0);
    const isPortrait = window.innerHeight > window.innerWidth || !fullScreen;

    return (
        <Div wd={isPortrait ? "100%" : "33%"} zoom centered={!isPortrait}>
            {showItems ? (
                <Carousel
                    slidesToShow={1}
                    enableButtons
                    initialSlide={targetIndex}
                >
                    {_.map(popupitems, (card, idx) => {
                        return (
                            <Div
                                key={`list-item-${idx}`}
                                slightShadow
                                left-aligned
                                rounded
                                noOverflow
                                relative
                                flex={!isPortrait}
                            >
                                <Image
                                    fluid
                                    src={
                                        _.startsWith(card.image, "data:image")
                                            ? card.image
                                            : `${clientAssetsPath}/uploads/qdf/slides/${card.image.id}${card.image.extension}`
                                    }
                                />
                                <Icon
                                    name="close"
                                    absolute
                                    huge
                                    clickable
                                    half
                                    onClick={() => setShowItems(false)}
                                    style={{
                                        top: 0,
                                        right: 0,
                                        padding: "13px 10px",
                                    }}
                                />
                                <Div basepad half big={isPortrait}>
                                    {card.option}
                                </Div>
                                <Div basepad ash big={isPortrait}>
                                    {card.caption}
                                </Div>
                            </Div>
                        );
                    })}
                </Carousel>
            ) : (
                <Div padTop basepad>
                    {_.map(popupitems, (option, index) => {
                        return (
                            <Button
                                content={option.option}
                                fluid
                                primary
                                key={"button-" + index}
                                onClick={() => {
                                    setTargetIndex(index);
                                    setShowItems(true);
                                }}
                                style={{
                                    marginBottom: "3px",
                                    textAlign: "left",
                                }}
                                big={!isPortrait}
                            />
                        );
                    })}
                </Div>
            )}
        </Div>
    );
}
