import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import moment from "moment";

import { baseUrl, useGetVersionListQuery } from "@api/apiV6";

import { Icon, Input, Loader } from "semantic-ui-react";
import { Div, Button } from "@components/Generics.react";
export default function VersionList(props) {
    const { course, showVersions = false } = props;
    const {
        data: versionList,
        isSuccess,
        refetch,
    } = useGetVersionListQuery(course._id);

    const [searchText, setSearchText] = useState("");
    const [reportsList, setReportsList] = useState("");

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showVersions]);

    useEffect(() => {
        const sortedReports = _.orderBy(versionList, [(r) => r], ["desc"]);
        setReportsList(
            _.map(sortedReports, (r) => ({
                name: r,
                date: moment(
                    r.split("_")[1].split(".")[0].split("-").join(" "),
                    "DDMMYY hhmm a"
                ).format("DD MMM YYYY hh:mm a"),
            }))
        );
    }, [versionList]);

    const selectedReports = useMemo(() => {
        return _.orderBy(
            _.filter(
                reportsList,
                (r) =>
                    r.date.includes(searchText) || r.name.includes(searchText)
            ),
            [(r) => moment(r.date)],
            ["desc"]
        );
    }, [reportsList, searchText]);

    // ========================= Render Function =================================
    return (
        <Div>
            {reportsList.length > 0 && (
                <Div ash basepad gapTop>
                    <Div bold uppercase gutter flex spaceBetween>
                        <Div wd="calc(100% - 128px)" flex gapped flexStart>
                            <Div bold wd="150px">
                                Old Version Data
                            </Div>
                            <Input
                                placeholder={"Search for a report date"}
                                icon={{
                                    name: "search",
                                }}
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </Div>
                        <Div clickable wd="128px" right-aligned>
                            <Icon
                                name="refresh"
                                circular
                                inverted
                                onClick={refetch}
                                title="Refresh List"
                            />
                        </Div>
                    </Div>
                    {isSuccess ? (
                        selectedReports.length > 0 ? (
                            _.map(selectedReports, (r, idx) => {
                                return (
                                    <Div
                                        padded
                                        white
                                        snug
                                        flex
                                        spaceBetween
                                        key={`reportName-${idx}`}
                                    >
                                        <Div wd="150px">
                                            {moment(r.date).format(
                                                "DD MMM YYYY hh:mm a"
                                            )}
                                        </Div>
                                        <Div wd="calc(100% - 300px)" noOverflow>
                                            {r.name}
                                        </Div>
                                        {r.name.includes("processing") ? (
                                            <Div>Processing...</Div>
                                        ) : (
                                            <Div>
                                                <a
                                                    href={`${baseUrl}public/reports/summary/${r.name}`}
                                                >
                                                    <Button
                                                        primary
                                                        small={+true}
                                                        content="Download"
                                                        icon={"download"}
                                                        labelPosition={"right"}
                                                    />
                                                </a>
                                            </Div>
                                        )}
                                    </Div>
                                );
                            })
                        ) : (
                            <Div fullht fluid flex>
                                No Reports Available
                            </Div>
                        )
                    ) : (
                        <Loader />
                    )}
                </Div>
            )}
        </Div>
    );
}
