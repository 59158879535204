import React, { useState } from "react";
import { useCreateTeamMutation } from "@api/apiV6";
import { toast } from "react-toastify";

import { Div, Button } from "@components/Generics.react";
import { Modal, Icon, Input } from "semantic-ui-react";

export default function AddTeamModal(props) {
    const { isOpen, setOpen } = props;

    const [teamName, setTeamName] = useState("");
    const [identifier, setIdentifier] = useState("");
    const [createTeam] = useCreateTeamMutation();

    async function tryRegister() {
        const response = await createTeam({
            name: teamName,
            identifier: identifier,
            colors: {
                main: "#2288bb",
                primary: "#ffbf00",
                primaryText: "#000000",
            },
        });
        if (response) {
            toast("Team Created");
            setOpen(false);
        }
    }

    // ========================= Render Function =================================
    return (
        <Modal size="small" open={isOpen} onClose={() => setOpen(false)}>
            <Modal.Header>
                Add Team
                <Div float-right clickable>
                    <Icon
                        name="times"
                        onClick={() => {
                            setOpen(false);
                        }}
                    />
                </Div>
            </Modal.Header>
            <Modal.Content>
                <Div white basepad>
                    <Div fluid gutter>
                        <Input
                            fluid
                            label={"Team Name"}
                            placeholder={"Enter team name"}
                            value={teamName}
                            onChange={(e) => setTeamName(e.target.value)}
                        />
                        <Input
                            fluid
                            label={"Team Identifier"}
                            placeholder={"Enter team identifier"}
                            value={identifier}
                            onChange={(e) => setIdentifier(e.target.value)}
                        />
                    </Div>
                </Div>
                <Button
                    primary
                    content="Create Team"
                    onClick={tryRegister}
                    disabled={teamName.length < 3 || identifier.length < 3}
                />
            </Modal.Content>
        </Modal>
    );
}
