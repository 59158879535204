import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Dropdown, Icon } from "semantic-ui-react";
import ReactTable from "react-table-v6";
import { useGetDeckBuilderQuery, useGetQuestionsDataQuery } from "@api/apiV6";
import { Div } from "@components/Generics.react";
export default function QuizQuestionsResponses(props) {
    const { deckId, isCorrect } = props;
    const {
        data: questionData,
        isSuccess,
        refetch,
    } = useGetQuestionsDataQuery({
        deckId: deckId,
        groupBy: "questions",
    });
    const { data: deckData, isSuccess: deckSuccess } =
        useGetDeckBuilderQuery(deckId);
    const [question, setQuestion] = useState(null);

    const { t } = useTranslation("common");
    const isPortrait = window.innerHeight > window.innerWidth;
    const reactTableRef = useRef(null);

    const questionList =
        isSuccess && deckSuccess
            ? _.map(questionData.questions, (l) => ({
                  key: l._id,
                  value: l._id,
                  text: _.find(deckData.content, {
                      slideSeq: parseInt(l._id.split("#")[1], 0),
                  })?.data.question.replace(/<[^>]*>/g, ""),
              }))
            : [];

    const selectedQuestion =
        isSuccess &&
        question &&
        _.find(questionData.questions, {
            _id: question,
        });
    const questionId = selectedQuestion?._id;
    const questionResponses = selectedQuestion?.learners;

    const fullColumns = [
        {
            Header: "Learner",
            accessor: "user",
            minWidth: 128,
            Cell: ({ row }) =>
                `${row._original.user?.first_name} ${row._original.user?.last_name}`,
            filterMethod: (filter, row) => {
                return _.includes(
                    `${row[filter.id]?.first_name} ${
                        row[filter.id]?.last_name
                    }`.toLowerCase(),
                    filter.value.toLowerCase()
                );
            },
            style: { textAlign: "left", whiteSpace: "unset" },
        },
        {
            Header: "Response",
            id: "response",
            accessor: (d) => d.response,
            Cell: ({ row }) =>
                `"${
                    _.isArray(row.response)
                        ? row.response.join(" | ")
                        : row.response
                }"`,
            style: { textAlign: "left", whiteSpace: "unset" },
        },
        {
            Header: "Correct",
            id: "correct",
            accessor: "response",
            Cell: ({ row }) => {
                if (deckData) {
                    return isCorrect(deckData, questionId, row.response)
                        ? "Correct"
                        : "Incorrect";
                } else {
                    return "";
                }
            },
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                }
                if (filter.value === "true") {
                    return isCorrect(
                        deckData,
                        row._original.slideId,
                        row[filter.id]
                    );
                }
                return !isCorrect(
                    deckData,
                    row._original.slideId,
                    row[filter.id]
                );
            },
            Filter: ({ filter, onChange }) => (
                <select
                    onChange={(event) => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">Show All</option>
                    <option value="true">Correct</option>
                    <option value="false">Incorrect</option>
                </select>
            ),
            style: { textAlign: "center", whiteSpace: "unset" },
        },
    ];

    return (
        <Div maxHt={`${window.innerHeight - 400}px`} autoOverflowY>
            <Div flex clearfix gapBottom spaceBetween>
                <Div wd="210px" big uppercase bold>
                    <Div inline small clickable>
                        <Icon circular inverted name="sync" onClick={refetch} />
                    </Div>{" "}
                    {t("analytics.selectQuestion")}
                </Div>
                <Div wd={`calc(100% - 220px)`} bold>
                    <Dropdown
                        placeholder={t("analytics.selectQuestion")}
                        search
                        fluid
                        selection
                        upward={isPortrait}
                        options={questionList}
                        value={question}
                        onChange={(e, { value }) => setQuestion(value)}
                    />
                </Div>
            </Div>
            <Div fluid>
                {question != null ? (
                    <ReactTable
                        ref={reactTableRef}
                        data={questionResponses || []}
                        filterable
                        defaultFilterMethod={(filter, row, column) => {
                            const id = filter.pivotId || filter.id;
                            return row[id] !== undefined
                                ? String(row[id])
                                      .toLowerCase()
                                      .includes(filter.value.toLowerCase())
                                : true;
                        }}
                        pageSizeOptions={[5, 10, 30, 50]}
                        columns={fullColumns}
                        defaultPageSize={5}
                        className="-striped -highlight"
                    />
                ) : (
                    <Div center-align large bold megapad>
                        {t("analytics.selectQuestionToProceed")}
                    </Div>
                )}
            </Div>
        </Div>
    );
}
