import React from "react";
import { PieChart, Pie, Cell } from "recharts";
import { Div, CenteredContent } from "@components/Generics.react";

export default function RadialProgressChart(props) {
    const {
        width = 200,
        height = 200,
        color = "#121212",
        noText = false,
        showAbsolute = false,
        value,
        maxValue = 100,
    } = props;

    let data = [
        {
            name: isNaN(value)
                ? "-"
                : showAbsolute
                ? value
                : Math.round(value) + "%",
            value: isNaN(value) ? 0 : Math.round((value * 100) / maxValue),
        },
        {
            name: "",
            value: isNaN(value)
                ? 100
                : 100 - Math.round((value * 100) / maxValue),
        },
    ];

    return (
        <Div relative wd={`${width}px`} fit-content centered>
            <PieChart width={width} height={height} title={`${value}%`}>
                <Pie
                    data={data}
                    cx={"50%"}
                    cy={"50%"}
                    innerRadius={width / 2 - (noText ? width / 4 : width / 6)}
                    outerRadius={width / 2}
                    fill={color}
                    paddingAngle={5}
                    dataKey="value"
                >
                    {data.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={index === 0 ? color : "#cccccc"}
                        />
                    ))}
                </Pie>
            </PieChart>
            {!noText && (
                <CenteredContent style={{ fontSize: `${width / 5}px` }}>
                    {data[0].name}
                </CenteredContent>
            )}
        </Div>
    );
}

// Data Format
// -------------------------------
/*
    <RadialProgressChart
        value={60}
        maxValue={100}
        width={40}
        height={40}
        color={"#ffbf00"}
        noText
        showAbsolute
    />;
*/
