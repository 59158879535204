// Import from NPM
// -------------------------------------
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Div } from "@components/Generics.react";
import { Input, Checkbox } from "semantic-ui-react";
export default function CheckField(props) {
    const { noRadio = false, value, setValue, height } = props;
    const [isMounted, setIsMounted] = useState(false);
    const [inputValue, setInputValue] = useState(value.text);
    const [checkValue, setCheckValue] = useState(value.correct === "checked");
    const { t } = useTranslation("common");

    // Debouncing the user input
    //==================================
    useEffect(() => {
        setIsMounted(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setInputValue(value.text);
        setCheckValue(value.correct === "checked");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (isMounted)
                setValue({
                    text: inputValue,
                    correct: checkValue ? "checked" : "",
                });
        }, 500);
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue, checkValue]);

    // ========================= Render Function =================================
    return (
        <Div minHt={height} ht={height} rimmed snug>
            <Div
                smoke
                ht={"calc(100% - 60px)"}
                fluid
                flex
                spaceBetween
                padSides={!noRadio}
            >
                {!noRadio && (
                    <Checkbox
                        label={
                            checkValue
                                ? t("builder.deck.correct")
                                : t("builder.deck.incorrect")
                        }
                        checked={checkValue}
                        onChange={(e, data) => setCheckValue(data.checked)}
                    />
                )}
                <Div wd={`calc(100% - ${noRadio ? 0 : 100}px)`}>
                    <Input
                        fluid
                        value={inputValue || ""}
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                </Div>
            </Div>
        </Div>
    );
}
