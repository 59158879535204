import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFeatures } from "flagged";
import {
    useGetRecentViewsQuery,
    useSendUserSelfUpdateMutation,
} from "@api/apiV6";

import { Dropdown } from "semantic-ui-react";
import MasterLayout from "@layouts/MasterLayout.react";
import { Div, Image, Button, Icon } from "@components/Generics.react";
import { settings } from "@config/settings/app.settings";
import SearchField from "@components/SearchField.react";
import { darkStyle, gradient } from "@utilities/helpers";
import RecommendedArticles from "@components/RecommendedArticles.react";
import ArticleCarousel from "@components/ArticleCarousel.react";
import TermsPage from "./panels/TermsPage.react";

export default function AppHome(props) {
    const user = useSelector((state) => state.auth.user);
    const { t } = useTranslation("common");
    const { data: recents, isSuccess } = useGetRecentViewsQuery();
    const [showingRecents, setShowingRecents] = useState(false);
    const [updateProfile] = useSendUserSelfUpdateMutation();
    const features = useFeatures();

    const stages = settings.gamification.stages;
    const showStage = _.keys(stages).length > 1 && !features.general.hideStage;
    const userStage = settings.home[user.stage || stages.length];
    const isPortrait = window.innerHeight > window.innerWidth;

    const activeRecents = isSuccess
        ? _.filter(recents.article, { published: true })
        : [];

    async function handleUpdateLanguage(lang) {
        try {
            await updateProfile({
                id: user?._id,
                lang: lang,
            });
        } catch (error) {
            console.log(error);
        }
    }

    const pageContent = user.license.termsAccepted || !features.general.acceptTerms ? (
        <Div
            basepad
            fullht
            style={{ background: gradient("#dddddd", "to top") }}
        >
            <Div fluid flex>
                {userStage && !userStage.hideSearch && <SearchField />}
                {settings.languages && settings.languages.length > 1 && (
                    <Div nudgeLeft primary className="lang-dropdown">
                        <Dropdown
                            text={
                                <Div float-right nudgeLeft>
                                    <Icon name="chevron down" />
                                </Div>
                            }
                            icon="language"
                            direction="left"
                            scrolling
                            button
                            className="icon"
                        >
                            <Dropdown.Menu>
                                <Dropdown.Header>
                                    Select Language
                                </Dropdown.Header>
                                {_.map(settings.languages, (lang, langIdx) => {
                                    return (
                                        <Dropdown.Item
                                            key={`lang-${langIdx}`}
                                            onClick={() =>
                                                handleUpdateLanguage(lang.code)
                                            }
                                        >
                                            <Div wd="150px">
                                                {lang.name}{" "}
                                                <Icon
                                                    name="chevron right"
                                                    float-right
                                                />
                                            </Div>
                                        </Dropdown.Item>
                                    );
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Div>
                )}
            </Div>
            <br />
            {showStage && (
                <Div
                    topRounded
                    relaxed
                    charcoal
                    large
                    wd="240px"
                    centered
                    center-align
                    uppercase
                    style={darkStyle("#666666", "to top")}
                >
                    {`${t("home.stage")} ${t(`stages.${user.stage}`)}`}
                </Div>
            )}
            <Div relative fluid style={{ height: "0", paddingBottom: "133%" }}>
                {userStage &&
                    _.map(userStage.images, ({ src, coords }, idx) => {
                        return (
                            <NavLink
                                to={t(`home.links.${src}`)}
                                key={`homeelm-${idx}`}
                            >
                                <Image
                                    src={t(`home.images.${src}`)}
                                    style={{
                                        position: "absolute",
                                        left: `${coords[0]}%`,
                                        top: `${coords[1]}%`,
                                        width: `${coords[2]}%`,
                                    }}
                                />
                            </NavLink>
                        );
                    })}
            </Div>
            {isSuccess &&
                activeRecents.length > 0 &&
                settings.home[user.stage].recents && (
                    <Div>
                        <Button
                            fluid
                            primary
                            icon={
                                showingRecents ? "chevron up" : "chevron down"
                            }
                            labelPosition="right"
                            content="CONTINUE WHERE YOU LEFT OFF..."
                            onClick={() => setShowingRecents(!showingRecents)}
                        />
                        {showingRecents && (
                            <Div
                                key={`recent-carousel`}
                                compact
                                padTop
                                rounded
                                smoke={!isPortrait}
                                gutter={!isPortrait}
                                small
                            >
                                <ArticleCarousel
                                    header={t("home.recently")}
                                    articles={activeRecents}
                                    noSort
                                />
                            </Div>
                        )}
                    </Div>
                )}
        </Div>
    ) : (
        <TermsPage />
    );
    // ========================= Render Function =================================
    return (
        <MasterLayout
            title={"Welcome"}
            mainPanel={pageContent}
            rightPanelName={t(`components.recommended`)}
            rightPanel={<RecommendedArticles />}
            rootPage={true}
            hidePanelToggle
        />
    );
}
