import React from "react";
import { Div } from "@components/Generics.react";
import PreviewLayout from "@layouts/PreviewLayout.react";
import ArticleDashboard from "../../analytics/ArticleDashboard.react";
import BuilderHeader from "@components/BuilderHeader.react";

export default function ArticleAnalytics(props) {
    const { article } = props;
    return (
        <Div fluid flex spaceAround top-align>
            <Div wd="calc(100% - 400px)" basepad>
                <BuilderHeader
                    image={article?.image}
                    header={article?.name}
                    description={article?.description}
                    imgWidth="300px"
                />
                <Div vapor rounded>
                    <ArticleDashboard article={article} />
                </Div>
            </Div>
            <Div pageht wd="400px">
                <PreviewLayout
                    url={`content/${article.category}/${article.identifier}`}
                />
            </Div>
        </Div>
    );
}
