// -------------------------------------
// Import from NPM
// -------------------------------------
import React, { useState, useMemo } from "react";
import { useSearchParams, useNavigate, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { Icon, Grid, Input, Divider } from "semantic-ui-react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import ReactTable from "react-table-v6";
import { ReactTree, useReactTreeApi } from "@naisutech/react-tree";
import { useFeatures } from "flagged";

// -------------------------------------
// Import APIs
// -------------------------------------
import { useGetTagsQuery } from "@api/apiV6";

// -------------------------------------
// Components
// -------------------------------------
import { Div } from "@components/Generics.react";
import MasterLayout from "@layouts/MasterLayout.react";
import ArticleCarousel from "@components/ArticleCarousel.react";
import ArticleCard from "@components/ArticleCard.react";
import SearchField from "@components/SearchField.react";

// -------------------------------------
// Import Styles
// -------------------------------------
import "@styles/searchresult.scss";
import "react-table-v6/react-table.css";

export default function SearchResults(props) {
    const articles = useSelector((state) => state.articles);
    const [searchParams] = useSearchParams();
    const features = useFeatures();

    const [view, setView] = useState("list");
    const [searchText, setSearchText] = useState("");

    const { t } = useTranslation("common");
    const treeApi = useReactTreeApi();
    const navigate = useNavigate();

    const isPortrait = window.innerHeight > window.innerWidth;

    const { data: tags } = useGetTagsQuery();

    // ------------------------------------------
    // Search Results
    // ------------------------------------------

    let selectedWord = searchParams.get("text").toLowerCase();
    const searchResults = useMemo(() => {
        return _.filter(articles.list, (article) => {
            const tagNames = _.map(article.tags, (tag) =>
                tag.name.toLowerCase()
            );
            return (
                article.name.toLowerCase().includes(selectedWord) ||
                tagNames.includes(selectedWord) ||
                article.category.includes(selectedWord)
            );
        });
    }, [selectedWord, articles]);

    const fullColumns = [
        {
            Header: "Type",
            accessor: "category",
            Cell: (row) => (
                <div style={{ textAlign: "center" }}>
                    {_.capitalize(row.value)}
                </div>
            ),
            maxWidth: 128,
        },
        {
            Header: "Name",
            accessor: "name",
            Cell: (row) => (
                <NavLink
                    to={`/content/${row.original?.category}/${row.original?.identifier}`}
                    title={_.capitalize(row.value)}
                >
                    {_.capitalize(row.value)}
                </NavLink>
            ),
        },
    ];

    const categoryGroup = _.groupBy(searchResults, "category");
    const categories = _.keys(categoryGroup);

    const dataSrc = _.union(
        [
            {
                id: "root",
                label: "All Tags",
                parentId: null,
            },
        ],
        _.map(tags, (t) => {
            return {
                id: t._id,
                label: t.name,
                parentId: t.parent != null ? t.parent._id : "root",
            };
        })
    );
    const dataTree = treeify(dataSrc, null);
    const [folderStructure, setfolderStructure] = useState(dataTree);

    function treeify(arr) {
        var out = [];
        let processedItems = [];
        for (let i = 0; i < arr.length; i++) {
            let proccessedAlready = processedItems.filter(
                (p) => p.id === arr[i].id
            );
            if (proccessedAlready.length === 0) {
                let items = arr.filter((a) => {
                    if (a.parentId === arr[i].id) {
                        let included = out.filter((o) => {
                            if (o.id === a.id) return o;
                            else return false;
                        });
                        let subitems = arr.filter((item) => {
                            if (item.parentId === a.id) return item;
                            else return false;
                        });
                        if (included.length === 0 && subitems.length === 0) {
                            processedItems.push(a);
                            a.leaf = true;
                            return a;
                        }
                    }
                    return false;
                });

                arr[i].items = items;
                out.push(arr[i]);
            }
        }
        return out;
    }

    const onFilterMouseUp = (e) => {
        if (e.key === "Enter") {
            startSearch(e);
        } else {
            setSearchText(e.target.value.trim());
        }
    };

    const startSearch = (e) => {
        const filter = searchText;
        if (filter !== "") {
            let filtered = filterTree(dataTree, filter);
            let finalData = addParentNodes(filtered.filteredData);
            setfolderStructure(finalData);
        } else {
            setfolderStructure(dataTree);
        }
        treeApi.current.toggleAllNodesOpenState("open");
    };

    const filterTree = (data, filter) => {
        let addPrevParent = false;
        let filteredData = [];
        data.forEach((node) => {
            if (node.id === "root") {
                node.items = [];
                filteredData.push(node);
            }
            if (node.leaf && node.id !== "root") {
                if (node.label.toLowerCase().includes(filter.toLowerCase())) {
                    addPrevParent = true;
                    filteredData.push(node);
                }
            } else if (node.items && node.items.length > 0) {
                let response = filterTree(node.items, filter);
                if (response.filteredData && response.filteredData.length > 0) {
                    node.items = response.filteredData;
                    filteredData.push(node);
                } else if (response.addPrevParent) {
                    filteredData.push(node);
                }
            }
        });
        return {
            filteredData: filteredData,
            addPrevParent: addPrevParent,
        };
    };

    function addParentNodes(filteredData) {
        let data = filteredData;
        data.forEach((item) => {
            if (item.parentId !== "root" && item.id !== "root") {
                let itemParentPresent = data.find((i) => {
                    return item.parentId === i.id;
                });
                if (!itemParentPresent) {
                    let parentNode = dataSrc.find(
                        (t) => t.id === item.parentId
                    );
                    if (parentNode) {
                        parentNode.items = [];
                        filteredData.push(parentNode);
                    }
                }
            }
        });

        return data;
    }

    const onToggle = (node) => {
        if (node.leaf) {
            navigate(`/search?tag=${node.label}`);
        }
    };

    function toggleView() {
        if (view === "list") {
            setView("grid");
        } else {
            setView("list");
        }
    }

    //----------------------------------------------
    // Views
    //----------------------------------------------

    const gridView = (
        <ReactTable
            key={`table-${selectedWord}`}
            data={searchResults}
            filterable
            defaultFilterMethod={(filter, row, column) => {
                const id = filter.pivotId || filter.id;
                return row[id] !== undefined
                    ? String(row[id])
                          .toLowerCase()
                          .includes(filter.value.toLowerCase())
                    : true;
            }}
            columns={fullColumns}
            pageSizeOptions={[10, 15, 30]}
            defaultPageSize={10}
            className="-striped -highlight"
        />
    );

    const listView = (
        <>
            {features.articles.categorizedListing
                ? _.map(categories, (category, carouselIndex) => {
                      return (
                          <Div
                              key={`carousel-index-${carouselIndex}`}
                              compact
                              padTop
                              rounded
                              smoke={!isPortrait}
                              gutter={!isPortrait}
                              small
                          >
                              <ArticleCarousel
                                  header={category}
                                  libLink={`/tags/${category}?text=${selectedWord}`}
                                  articles={categoryGroup[category]}
                              />
                          </Div>
                      );
                  })
                : _.map(searchResults, (article, articleIndex) => {
                      return (
                          <Div
                              float-left
                              wd={
                                  window.innerWidth > 1280
                                      ? "33%"
                                      : isPortrait
                                      ? "100%"
                                      : "50%"
                              }
                              gapBottom
                              key={`article-${articleIndex}`}
                          >
                              <ArticleCard article={article} feedStyle />
                          </Div>
                      );
                  })}
        </>
    );

    const pageContent = (
        <Div padded>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={isPortrait ? 12 : 13}>
                        <Div padTop gutter big bold txtCharcoal>
                            {t("search.header")}
                        </Div>
                        {isPortrait ? (
                            <Div ash padded>{selectedWord}</Div>
                        ) : (
                            <SearchField exSearch={selectedWord} />
                        )}
                    </Grid.Column>
                    <Grid.Column width={isPortrait ? 4 : 3}>
                        <Div
                            basepad
                            centered
                            flex
                            column
                            primary
                            rounded
                            fullht
                            clickable
                            className="view-toggle-button"
                            onClick={toggleView}
                        >
                            <Icon
                                name={view === "list" ? "list" : "image"}
                                size="large"
                            ></Icon>
                            <Div tiny>
                                {view === "list"
                                    ? t("search.viewTextList")
                                    : t("search.viewTextGrid")}
                            </Div>
                        </Div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <br />
            {isPortrait && (
                <Div centered>
                    <Divider horizontal>
                        <SearchField placeholder="Search again..." />
                    </Divider>
                </Div>
            )}
            <Div fluid padTop={!isPortrait}>
                {view === "grid" && gridView}
                {view === "list" && listView}
            </Div>
        </Div>
    );

    const rightContent = (
        <Div nopad fullht>
            <Div big padTop gutter bold uppercase>
                {t("search.rightHeader")}
            </Div>
            <Div ash fluid>
                <Div>
                    <Input
                        fluid
                        action={{
                            color: "grey",
                            icon: "search",
                            onClick: startSearch,
                        }}
                        placeholder="Search..."
                        onKeyUp={onFilterMouseUp}
                    />
                </Div>
                <Div>
                    <ReactTree
                        ref={treeApi}
                        nodes={folderStructure}
                        messages={{
                            loading: "Loading...",
                            noData: "No data to show",
                            emptyItems: "-",
                        }}
                        RenderNode={node}
                        noIcons={true}
                        truncateLongText={true}
                    />
                </Div>
            </Div>
        </Div>
    );

    function node({ node }) {
        const iconType =
            node.id === "root" ? "sitemap" : node.items ? "tags" : "tag";
        const iconColor =
            node.id === "root" ? "grey" : node.items ? "teal" : "yellow";
        return (
            <Div
                normal
                clickable
                priText
                midnight={node.active}
                onClick={() => onToggle(node)}
            >
                <Icon color={iconColor} name={iconType} />
                {node.label}
            </Div>
        );
    }

    // ========================= Render Function =================================
    return (
        <MasterLayout
            title={"Welcome"}
            mainPanel={pageContent}
            rightPanelName={t("search.rightHeader")}
            rightPanel={rightContent}
        />
    );
}
