import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFeatures } from "flagged";
import Tree from "rc-tree";
import "rc-tree/assets/index.css";
import { Input, Image, Icon as XIcon, Dimmer, Loader } from "semantic-ui-react";

import "@styles/components.scss";
import { lightStyle, text_truncate } from "@utilities/helpers";
import { Div, Icon, Button } from "@components/Generics.react";
import { deckFormats } from "../schemas/deckFormats";

export default function BrowserBar(props) {
    const {
        name,
        image,
        activeItem,
        contentSections,
        setTarget,
        btnSections,
        setSection,
        refetch,
        localStore,
        isLoading = false,
        showRecents = true,
        showListing = false,
        maxRecents = 5,
        noAdd = false,
    } = props;
    const user = useSelector((state) => state.auth.user);
    const [panel, setPanel] = useState(contentSections[0].name);
    const [searchText, setSearchText] = useState("");
    const [recents, setRecents] = useState(null);
    const [clickedItem, setClickedItem] = useState(null);
    const features = useFeatures();
    const { t } = useTranslation("common");

    useEffect(() => {
        const recentItems = window.localStorage.getItem(localStore);
        if (recentItems) {
            setRecents(JSON.parse(recentItems));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeItem]);

    function filterTree(node) {
        if (searchText !== "") {
            if (!node.children) {
                // Leaf node, check if it matches the search text
                return node.title
                    .toLowerCase()
                    .includes(searchText.toLowerCase());
            }

            // Non-leaf node, filter its children and keep if any child matches
            node.children = node.children.filter(filterTree);

            // Keep the node if it has matching children or if it matches the search text itself
            return node.children.length > 0;
        } else {
            return true;
        }
    }

    function buildTree(src, section, groupBy) {
        return groupBy
            ? _.chain(
                  _.groupBy(src, (item) =>
                      item.parent ? item.parent.name : null
                  )
              )
                  .map((group, title) => ({
                      key: group[0].parent._id,
                      title: title,
                      type: "parent",
                      section: section,
                      children: _.chain(
                          _.groupBy(
                              src,
                              (item) =>
                                  _.find(deckFormats, {
                                      readerType: item[groupBy],
                                  })?.name || _.startCase(item[groupBy])
                          )
                      )
                          .map((subgroup, title) => ({
                              key: subgroup[0][groupBy],
                              title: title,
                              type: "parent",
                              section: section,
                              children: _.chain(subgroup)
                                  .map((leaf) => ({
                                      key: leaf._id,
                                      type: "child",
                                      section: section,
                                      title: leaf.name,
                                  }))
                                  .sortBy("title") // Sort children array alphabetically by 'title'
                                  .value(),
                          }))
                          .filter(filterTree)
                          .sortBy("title")
                          .value(),
                  }))
                  .filter(filterTree)
                  .sortBy("title")
                  .value()
            : _.chain(
                  _.groupBy(src, (item) =>
                      item.parent ? item.parent.name : null
                  )
              )
                  .map((group, title) => ({
                      key: group[0].parent._id,
                      title: title,
                      type: "parent",
                      section: section,
                      children: _.chain(group)
                          .map((leaf) => ({
                              key: leaf._id,
                              type: "child",
                              section: section,
                              title: leaf.name,
                          }))
                          .sortBy("title") // Sort children array alphabetically by 'title'
                          .value(),
                  }))
                  .filter(filterTree)
                  .sortBy("title")
                  .value();
    }

    function handleBlanks(srcTree, extra, section) {
        if (extra && extra.length > 0 && !noAdd)
            return _.concat(
                srcTree,
                _.map(extra, (xItem) => {
                    return {
                        key: xItem._id,
                        section: section,
                        title: xItem.name,
                        type: "parent",
                        children: [
                            {
                                key: xItem.name,
                                type: "blank",
                                section: "home",
                                title: t("builder.dic.addNew"),
                            },
                        ],
                    };
                })
            );
        else return srcTree;
    }

    function treeify(raw) {
        let displayTree = [];
        if (raw.pin.length > 0) {
            const pinnedList = _.filter(raw.data, (c) =>
                _.includes(raw.pin, c.parent.name)
            );
            displayTree = buildTree(pinnedList, raw.section, raw.groupBy);

            if (!features.courses.onlyPublic || raw.section !== "courses") {
                const commonList = _.difference(raw.data, pinnedList);
                const commonTree = handleBlanks(
                    buildTree(commonList, raw.section),
                    raw.extra,
                    raw.section
                );
                displayTree.push({
                    key: raw.collection,
                    title: raw.collection,
                    type: "parent",
                    section: "home",
                    children: commonTree,
                });
            }
        } else {
            displayTree = handleBlanks(
                buildTree(raw.data, raw.section, raw.groupBy),
                raw.extra,
                raw.section
            );
        }
        return displayTree;
    }

    function onSelect(selectedKeys, info) {
        if (info.node.type === "child") {
            const storedRecents = window.localStorage.getItem(localStore);
            let libraryRecents = [];
            if (storedRecents) {
                libraryRecents = JSON.parse(storedRecents);
            }
            libraryRecents = _.uniqBy(
                _.concat(
                    [],
                    [_.pick(info.node, ["title", "key", "type", "section"])],
                    libraryRecents
                ),
                "key"
            ).slice(0, maxRecents);
            window.localStorage.setItem(
                localStore,
                JSON.stringify(libraryRecents)
            );
            setRecents(libraryRecents);
        }
        setTarget(_.pick(info.node, ["key", "type", "section"]));
    }

    function itemRow(item, idx, section) {
        return (
            <Div
                key={`sec-item-${idx}`}
                smoke={item.active !== false && activeItem !== item._id}
                half={activeItem === item._id}
                danger={item.active === false}
                padded
                snug
                clickable
                onClick={() => {
                    onSelect(null, {
                        node: {
                            key: item._id,
                            type: "child",
                            title: item.name,
                            section: section.section,
                        },
                    });
                    setClickedItem(item);
                }}
                title={item.name}
            >
                {text_truncate(item.name, 30)}
                <Div float-right>
                    <Icon
                        name={
                            clickedItem === item || activeItem === item._id
                                ? "chevron right"
                                : ""
                        }
                    />
                </Div>
            </Div>
        );
    }

    const browseSection = (section, open, idx, onlySection) => {
        const limitedCourses =
            (section.section === "courses" &&
                features.courses.maxCourses !== "Inf") ||
            showListing;
        return (
            <Div
                key={`browser-bar-${idx}`}
                ht={
                    open
                        ? `calc(100% - ${
                              (contentSections.length - 1) * 40 +
                              btnSections.length * 42 +
                              (recents && showRecents
                                  ? recents.length * 25 + 10
                                  : 0) +
                              80
                          }px)`
                        : "auto"
                }
            >
                <Div
                    big
                    left-align
                    balanced
                    style={lightStyle(
                        open ? user.branding?.colors?.primary : "#999999"
                    )}
                    clickable
                    onClick={() => {
                        setPanel(section.name === panel ? null : section.name);
                        setSection(
                            section.name === panel ? null : section.name
                        );
                    }}
                >
                    {section.name}
                    <Icon
                        float-right
                        name={`chevron ${open ? "up" : "down"}`}
                    />
                </Div>
                {open && (
                    <Div fullht fluid>
                        {section.data.length > 0 || section.extra.length > 0 ? (
                            <Div
                                left-align
                                compact
                                white
                                ht={`calc(100% - 50px)`}
                            >
                                <Input
                                    fluid
                                    placeholder={t("builder.dic.searchWithin")}
                                    icon={{ name: "search" }}
                                    value={searchText}
                                    onChange={(e) =>
                                        setSearchText(e.target.value)
                                    }
                                />
                                <Div
                                    padded={!limitedCourses}
                                    ht={`calc(100% - 50px)`}
                                    autoOverflowY
                                >
                                    {!limitedCourses ? (
                                        <Tree
                                            key={`section-${searchText !== ""}`}
                                            className="myCls"
                                            showLine
                                            selectable={true}
                                            expandAction="click"
                                            defaultExpandAll={searchText !== ""}
                                            onSelect={onSelect}
                                            treeData={treeify(section)}
                                        />
                                    ) : (
                                        <Div>
                                            {_.map(
                                                _.filter(section.data, (item) =>
                                                    item.name
                                                        .toLowerCase()
                                                        .includes(
                                                            searchText.toLowerCase()
                                                        )
                                                ),
                                                (item, idx) => {
                                                    return itemRow(
                                                        item,
                                                        idx,
                                                        section
                                                    );
                                                }
                                            )}
                                        </Div>
                                    )}
                                </Div>
                            </Div>
                        ) : (
                            <Div ht={`calc(100% - 50px)`} fluid flex relative>
                                {t("builder.welcome.waitingForContent")}
                            </Div>
                        )}
                    </Div>
                )}
            </Div>
        );
    };

    return (
        <>
            <Div
                fluid
                ht={window.innerHeight > 720 ? "calc(100% - 150px)" : "100%"}
            >
                {window.innerHeight > 720 && (
                    <Div slightShadow ht={"145px"} noOverflow relative>
                        <Image
                            fluid
                            src={`/assets/images/configurable/${image}`}
                        />
                        <Div
                            absolute
                            bold
                            large
                            uppercase
                            className="browser-bar-header"
                        >
                            {name}
                        </Div>
                        <Div
                            absolute
                            big
                            pureCircle
                            primary
                            clickable
                            rimmed
                            dropShadow
                            className="browser-bar-resync"
                        >
                            <XIcon name="sync" onClick={refetch} />
                        </Div>
                    </Div>
                )}
                <Div fullht relative>
                    {recents && showRecents && (
                        <Div medpad vapor>
                            <Div rimmed bold big uppercase>
                                {t("builder.dic.recentItems")}
                            </Div>
                            {_.map(recents, (recentItm, idx) => {
                                return (
                                    <Div
                                        key={`recent-item-${idx}`}
                                        white={activeItem !== recentItm.key}
                                        compact
                                        snug
                                        clickable
                                        onClick={() =>
                                            setTarget(
                                                _.omit(recentItm, ["title"])
                                            )
                                        }
                                        style={
                                            activeItem === recentItm.key
                                                ? lightStyle(
                                                      user.branding?.colors
                                                          ?.primary
                                                  )
                                                : {}
                                        }
                                        title={recentItm.title}
                                    >
                                        {text_truncate(recentItm.title, 30)}
                                        <Div float-right>
                                            <Icon name="chevron right" />
                                        </Div>
                                    </Div>
                                );
                            })}
                        </Div>
                    )}
                    <Div peat ht="5px" />
                    {!isLoading ? (
                        _.map(contentSections, (section, idx) =>
                            browseSection(
                                section,
                                section.name === panel,
                                idx,
                                contentSections.length === 1
                            )
                        )
                    ) : (
                        <Dimmer active>
                            <Loader content="Loading" />
                        </Dimmer>
                    )}
                    <Div peat ht="2px" />
                    <Div white medpad={btnSections.length > 0}>
                        {_.map(btnSections, (button, idx) => (
                            <Button
                                key={`browser-btn-${idx}`}
                                icon={button.icon}
                                labelPosition="right"
                                primary
                                fluid
                                content={button.name}
                                onClick={button.action}
                            />
                        ))}
                    </Div>
                    <Div charcoal ht="8px" />
                </Div>
            </Div>
        </>
    );
}
