import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Dropdown, Icon } from "semantic-ui-react";
import ReactTable from "react-table-v6";
import { useGetDeckBuilderQuery, useGetQuestionsDataQuery } from "@api/apiV6";
import { Div, Button } from "@components/Generics.react";
export default function QuizLearnersResponses(props) {
    const { deckId, isCorrect } = props;
    const {
        data: learnerData,
        isSuccess,
        refetch,
    } = useGetQuestionsDataQuery({
        deckId: deckId,
        groupBy: "learners",
    });
    const { data: deckData, isSuccess: deckSuccess } =
        useGetDeckBuilderQuery(deckId);
    const [learner, setLearner] = useState(null);

    const { t } = useTranslation("common");
    const isPortrait = window.innerHeight > window.innerWidth;
    const reactTableRef = useRef(null);

    const learnerList =
        isSuccess && deckSuccess
            ? _.map(learnerData.learners, (l) => ({
                  key: l.user?._id,
                  value: l.user?._id,
                  text: `${l.user?.first_name} ${l.user?.last_name}`,
              }))
            : [];

    const learnerResponses =
        isSuccess &&
        learner &&
        _.find(learnerData.learners, {
            _id: learner,
        }).questions;

    const fullColumns = [
        {
            Header: "Question",
            accessor: "slideId",
            minWidth: 128,
            Cell: ({ row }) =>
                deckData
                    ? _.find(deckData.content, {
                          slideSeq: parseInt(row.slideId.split("#")[1], 0),
                      })?.data.question.replace(/<[^>]*>/g, "")
                    : row.value,
            filterMethod: (filter, row) => {
                return _.includes(
                    deckData
                        ? _.find(deckData.content, {
                              slideSeq: parseInt(
                                  row[filter.id].split("#")[1],
                                  0
                              ),
                          })?.data.question?.toLowerCase()
                        : row[filter.id].value.toLowerCase(),
                    filter.value.toLowerCase()
                );
            },
            style: { textAlign: "left", whiteSpace: "unset" },
        },
        {
            Header: "Response",
            id: "response",
            accessor: (d) => d.response,
            Cell: ({ row }) =>
                _.isArray(row.response)
                    ? row.response.join("; ")
                    : row.response,
            style: { textAlign: "left", whiteSpace: "unset" },
        },
        {
            Header: "Correct",
            id: "correct",
            accessor: "response",
            Cell: ({ row }) => {
                if (deckData) {
                    return isCorrect(deckData, row.slideId, row.response)
                        ? "Correct"
                        : "Incorrect";
                } else {
                    return "";
                }
            },
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                }
                if (filter.value === "true") {
                    return isCorrect(
                        deckData,
                        row._original.slideId,
                        row[filter.id]
                    );
                }
                return !isCorrect(
                    deckData,
                    row._original.slideId,
                    row[filter.id]
                );
            },
            Filter: ({ filter, onChange }) => (
                <select
                    onChange={(event) => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">Show All</option>
                    <option value="true">Correct</option>
                    <option value="false">Incorrect</option>
                </select>
            ),
            style: { textAlign: "center", whiteSpace: "unset" },
        },
    ];
    const downloadFullCSV = () => {
        const header = _.concat(
            ["Username", "Name"],
            _.map(
                deckData.content,
                (c) => `"${c.data.question.replace(/<[^>]*>/g, "")}"`
            )
        );
        let headerLine = header.join(",");
        let csv = [headerLine];
        _.each(learnerData.learners, (learner) => {
            let thisRow = [
                learner.user?.username || "deleteduser",
                `${learner.user?.first_name || "Deleted"} ${learner.user?.last_name || "User"}`,
            ]; 
            _.each(deckData.content, (question) => {
                const qResponse = _.find(learner.questions, {
                    slideId: `${deckId}#${question.slideSeq}`,
                });
                if (qResponse) {
                    thisRow.push(
                        `"${
                            _.isArray(qResponse.response)
                                ? qResponse.response.join(" | ")
                                : qResponse.response
                        }"`
                    );
                } else {
                    thisRow.push("");
                }
            });
            csv.push(thisRow.join(","));
        });
        csv = csv.join("\r\n");
        var hiddenElement = document.createElement("a");
        hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
        hiddenElement.target = "_blank";
        hiddenElement.download = `QuizFullData-${deckId}.csv`;
        hiddenElement.click();
    };

    return (
        <Div relative>
            {isSuccess && (
                <Div absolute style={{ top: "-57px", right: "-20px" }}>
                    <Button
                        secondary
                        content="Download Report"
                        onClick={downloadFullCSV}
                    />
                </Div>
            )}
            <Div maxHt={`${window.innerHeight - 400}px`} autoOverflowY>
                <Div flex clearfix gapBottom spaceBetween>
                    <Div wd="190px" big uppercase bold>
                        <Div inline small clickable>
                            <Icon
                                circular
                                inverted
                                name="sync"
                                onClick={refetch}
                            />
                        </Div>{" "}
                        {t("analytics.selectLearner")}
                    </Div>
                    <Div wd={`calc(100% - 200px)`} bold>
                        <Dropdown
                            placeholder={t("analytics.selectLearner")}
                            search
                            fluid
                            selection
                            upward={isPortrait}
                            options={learnerList}
                            value={learner}
                            onChange={(e, { value }) => setLearner(value)}
                        />
                    </Div>
                </Div>
                <Div fluid>
                    {learner != null ? (
                        <ReactTable
                            ref={reactTableRef}
                            data={learnerResponses || []}
                            filterable
                            defaultFilterMethod={(filter, row, column) => {
                                const id = filter.pivotId || filter.id;
                                return row[id] !== undefined
                                    ? String(row[id])
                                          .toLowerCase()
                                          .includes(filter.value.toLowerCase())
                                    : true;
                            }}
                            pageSizeOptions={[5, 10, 30, 50]}
                            columns={fullColumns}
                            defaultPageSize={5}
                            className="-striped -highlight"
                        />
                    ) : (
                        <Div center-align large bold megapad>
                            {t("analytics.selectLearnerToProceed")}
                        </Div>
                    )}
                </Div>
            </Div>
        </Div>
    );
}
