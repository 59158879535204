import React, { useState, useRef } from "react";

const HotSpotEditor = () => {
    const [image, setImage] = useState(null);
    const [hotspots, setHotspots] = useState([]);
    const [sequence, setSequence] = useState(0);

    const imageRef = useRef(null);
    const hotspotRef = useRef(null);
    const dragIndexRef = useRef(null);
    const dragStartRef = useRef(null);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = () => {
            const img = new Image();
            img.src = reader.result;
            img.onload = () => {
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");
                const aspectRatio = 3 / 2;
                const desiredHeight = img.width / aspectRatio;

                canvas.width = img.width;
                canvas.height = desiredHeight;

                context.drawImage(img, 0, 0, canvas.width, canvas.height);

                const croppedImage = canvas.toDataURL();
                setImage(croppedImage);
            };
        };

        reader.readAsDataURL(file);
    };

    const handleImageClick = (event) => {
        const rect = imageRef.current.getBoundingClientRect();
        const offsetX = event.clientX - rect.left;
        const offsetY = event.clientY - rect.top;

        const hotspot = {
            sequence,
            top: (offsetY / rect.height) * 100,
            left: (offsetX / rect.width) * 100,
        };

        setHotspots((prevHotspots) => [...prevHotspots, hotspot]);
        setSequence((prevSequence) => prevSequence + 1);
    };

    const handleDotDragStart = (index, event) => {
        event.stopPropagation();
        hotspotRef.current.style.zIndex = "2";

        dragIndexRef.current = index;
        dragStartRef.current = {
            x: event.clientX,
            y: event.clientY,
        };
    };

    const handleDotDrag = (event) => {
        event.stopPropagation();
        const rect = imageRef.current.getBoundingClientRect();

        const offsetX = event.clientX - rect.left;
        const offsetY = event.clientY - rect.top;
        const top = parseInt((offsetY / rect.height) * 100, 0);
        const left = parseInt((offsetX / rect.width) * 100, 0);

        if (top > 5 && left > 5 && top < 95 && left < 95) {
            const updatedHotspots = hotspots.map((hotspot, index) => {
                if (index === dragIndexRef.current) {
                    return {
                        ...hotspot,
                        top: top,
                        left: left,
                    };
                }
                return hotspot;
            });
            setHotspots(updatedHotspots);
        }
    };

    const handleDotDragEnd = () => {
        hotspotRef.current.style.zIndex = "1";
        dragIndexRef.current = null;
        dragStartRef.current = null;
    };

    const handleDownload = () => {
        const data = JSON.stringify(hotspots);
        const blob = new Blob([data], { type: "application/json" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "hotspots.json";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    return (
        <div>
            <input type="file" accept="image/*" onChange={handleImageUpload} />
            {image && (
                <div style={{ position: "relative", width: "75%" }}>
                    <img
                        ref={imageRef}
                        src={image}
                        alt="Cropped"
                        onClick={handleImageClick}
                        style={{ width: "100%", height: "auto" }}
                    />
                    {hotspots.map((hotspot, index) => (
                        <div
                            key={index}
                            style={{
                                position: "absolute",
                                top: `${hotspot.top}%`,
                                left: `${hotspot.left}%`,
                                transform: "translate(-50%, -50%)",
                                cursor: "pointer",
                                zIndex: 1,
                            }}
                            ref={hotspotRef}
                            draggable
                            onDragStart={(event) =>
                                handleDotDragStart(index, event)
                            }
                            onDrag={(event) => handleDotDrag(event)}
                            onDragEnd={handleDotDragEnd}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "32px",
                                    height: "32px",
                                    borderRadius: "50%",
                                    backgroundColor: "red",
                                    color: "white",
                                }}
                            >
                                {hotspot.sequence}
                            </div>
                        </div>
                    ))}
                    <button onClick={handleDownload}>Download</button>
                </div>
            )}
        </div>
    );
};

export default HotSpotEditor;
