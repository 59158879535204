import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFeatures } from "flagged";
import moment from "moment";

import { Label } from "semantic-ui-react";
import { Div, Icon } from "@components/Generics.react";
import "@styles/components.scss";
import { text_truncate } from "@utilities/helpers";

import CompletionIcon from "./CompletionIcon.react";

export default function CourseCard(props) {
    const { course, isLocked, targetIsolated = false } = props;
    const user = useSelector((state) => state.auth.user);
    const navigate = useNavigate();
    const features = useFeatures();
    const { t } = useTranslation("common");

    function convertToISO(serialDate) {
        const baseDate = moment("1900-01-01");

        // Excel incorrectly treats 1900 as a leap year, so we need to adjust by 1 day for dates after February 28, 1900
        const adjustedSerialDate = serialDate - 2; // Subtract 2 instead of 1 to account for 1-based indexing and the leap year bug

        // Add the serial date to the base date
        const isoDate = baseDate
            .add(adjustedSerialDate, "days")
            .toISOString();
        return isoDate;
    }

    let locked = false;
    let lockMsg = "";

    if (isLocked) {
        locked = true;
        lockMsg = t("course.prereqLock");
    } else if (course.enable_timing) {
        if (course.vintage != null && course.vintage > 0) {
            const dateOfJoining = convertToISO(user.details.date_of_joining);
            const doj = moment(dateOfJoining);
            const today = moment(new Date(), "YYYY-MM-DD");
            const daysSince = today.diff(dateOfJoining, "days");
            const availableOn = doj.add(course.vintage, "days").format("DD-MMM-YYYY");
            if (daysSince < course.vintage) {
                locked = true;
                lockMsg = t("course.vintageLock")
                    .replace("$vintage", course.vintage)
                    .replace("$date", availableOn);
            }
        }
        if (course.dates) {
            const start_date = new Date(course.dates.start_date); // Replace this with your start_date
            const end_date = new Date(course.dates.end_date); // Replace this with your end_date
            const now = new Date();
            let localeDate;
            let localeTime;

            if (now < start_date) {
                localeDate = start_date.toLocaleDateString();
                localeTime = start_date.toLocaleTimeString();
                locked = true;
                lockMsg = t("course.notStarted")
                    .replace("$date", localeDate)
                    .replace("$time", localeTime);
            } else if (now > end_date) {
                localeDate = end_date.toLocaleDateString();
                localeTime = end_date.toLocaleTimeString();
                locked = true;
                lockMsg = t("course.hasEnded")
                    .replace("$date", localeDate)
                    .replace("$time", localeTime);
            }
        }
    }

    // ========================= Render Function =================================
    return (
        <Div
            className="course-card"
            snubbed
            white
            slightShadow
            noOverflow
            clickable={!locked}
            relative
            onClick={() =>
                locked
                    ? null
                    : navigate(
                          `/${targetIsolated ? "explore" : "courses"}/${
                              course._id
                          }`
                      )
            }
        >
            <Div
                float-left
                className="course-card-img-container"
                style={{
                    backgroundImage: `url(${
                        !course?.image
                            ? "/assets/images/configurable/missing.jpg"
                            : course.image
                    })`,
                }}
            >
                {features.courses.hasExams && (
                    <Div rimmed>
                        <Label
                            size="tiny"
                            color={
                                course.course_type === "exam"
                                    ? "orange"
                                    : "teal"
                            }
                            content={
                                course.course_type === "exam"
                                    ? t("components.exam")
                                    : t("components.course")
                            }
                        />
                    </Div>
                )}
            </Div>
            <Div float-left className="card-content">
                <Div compact small padTop>
                    <Div bold fitted title={course.name}>
                        {text_truncate(course.name, 40)}
                    </Div>
                    <Div
                        small
                        fitted
                        wd="80%"
                        gutter
                        title={course.description}
                    >
                        {text_truncate(course.description, 40)}
                    </Div>
                </Div>
                <Div className="category-progress-radial">
                    <CompletionIcon
                        completed={course?.currentState?.completion}
                        percentage={course?.currentState?.viewedPercentage}
                    />
                </Div>
            </Div>
            {locked && (
                <Div absolute fullht fluid overlaid flex relaxed>
                    <Div small flex spaceBetween>
                        <Icon huge nudge-right float-left name="lock" />
                        <Div wd="calc(100% - 60px)">{lockMsg}</Div>
                    </Div>
                </Div>
            )}
        </Div>
    );
}
