import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFeatures } from "flagged";
import { useGetTeamsListQuery, useEditUserMutation } from "@api/apiV6";
import _ from "lodash";
import { toast } from "react-toastify";

import { Div, Button } from "@components/Generics.react";
import { Modal, Icon, Input, Dropdown, Progress } from "semantic-ui-react";

export default function EditUserModal(props) {
    const { isOpen, setOpen, user, users } = props;
    const features = useFeatures();
    const { data: teamsList, isSuccess } = useGetTeamsListQuery();
    const { t } = useTranslation("common");
    const [org, setOrg] = useState(user ? user.org : "");
    const [fullName, setFullName] = useState(
        `${user ? user.first_name : ""} ${user ? user.last_name : ""}`
    );
    const [email, setEmail] = useState(user ? user.email : "");
    const [validEmail, setValidEmail] = useState(true);
    const [team, setTeam] = useState(user ? user.team : null);
    const [stage, setStage] = useState(user ? user.stage : 1);
    const [percent, setPercent] = useState(0);
    const [error] = useState(null);

    const [editUser] = useEditUserMutation();

    useEffect(() => {
        // eslint-disable-next-line
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
            ? setValidEmail(true)
            : setValidEmail(false);
    }, [email]);

    async function tryUpdate() {
        if (user != null) {
            const updateResponse = await editUser({
                id: user._id,
                email: email,
                org: org,
                name: fullName,
                team: team,
                stage: stage,
            });
            if (updateResponse) {
                toast("User Updated");
                setOpen(false);
            }
        }
        if (users != null) {
            let processedUsers = 0;
            for (let i = 0; i < users.length; i++) {
                const updateResponse = await editUser({
                    id: users[i],
                    org: org,
                    team: team,
                    stage: stage,
                });
                if (updateResponse) processedUsers++;
                setPercent(parseInt((processedUsers * 100) / users.length, 0));
            }
            toast(`${processedUsers} users Updated`);
            setOpen(false);
        }
    }

    const handleStageChange = (e, { value }) => {
        // Use a regular expression to allow only numeric values
        const numericValue = value.replace(/\D/g, "");
        // Ensure the value is not below 1
        const sanitizedValue =
            numericValue === ""
                ? "1"
                : Math.max(1, parseInt(numericValue, 10)).toString();
        setStage(sanitizedValue);
    };

    const checkStyle = { float: "right", margin: "-30px 10px 0 0" };

    // ========================= Render Function =================================
    return (
        <Modal size="small" open={isOpen} onClose={() => setOpen(false)}>
            <Modal.Header>
                {t("admin.userMgmt.editUser")}
                <Div float-right clickable>
                    <Icon name="times" onClick={() => setOpen(false)} />
                </Div>
            </Modal.Header>
            <Modal.Content>
                <Div white basepad>
                    {error && (
                        <Div padded fluid danger gutter>
                            {error}
                        </Div>
                    )}
                    {user && (
                        <Div fluid gutter>
                            <Input
                                fluid
                                label={t("auth.registration.usernameLabel")}
                                placeholder={t(
                                    "auth.registration.usernamePlaceholder"
                                )}
                                disabled
                                value={user.username}
                            />
                        </Div>
                    )}
                    {user && (
                        <Div fluid gutter>
                            <Input
                                fluid
                                label={t("auth.registration.nameLabel")}
                                placeholder={t(
                                    "auth.registration.namePlaceholder"
                                )}
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                            />
                        </Div>
                    )}
                    <Div fluid gutter>
                        <Input
                            fluid
                            label={t("auth.registration.orgLabel")}
                            placeholder={t("auth.registration.orgPlaceholder")}
                            value={org}
                            onChange={(e) => setOrg(e.target.value)}
                        />
                    </Div>
                    {user && (
                        <Div fluid gutter>
                            <Input
                                fluid
                                label="Email"
                                type="email"
                                placeholder="Where can we contact you?"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {!validEmail && email?.length > 0 && (
                                <Div relaxed danger fluid>
                                    <i className="fa fa-times" /> &nbsp;
                                    Malformed email address.
                                </Div>
                            )}
                            {validEmail && (
                                <Div txtColor="success" style={checkStyle}>
                                    <i className="fa fa-check-circle" />
                                </Div>
                            )}
                        </Div>
                    )}
                    <Div fluid>
                        <Input
                            fluid
                            label="Stage"
                            type="number"
                            placeholder="Stage"
                            value={stage}
                            onChange={handleStageChange}
                        />
                    </Div>
                    <br />
                    {isSuccess && features.ability.adminPanel.visible && (
                        <Div padded gapTop smoke>
                            <Div rimmed>{t("admin.userMgmt.selectTeam")}</Div>
                            <Dropdown
                                placeholder={t("admin.userMgmt.selectTeam")}
                                fluid
                                selection
                                options={_.map(teamsList, (l) => ({
                                    key: l.identifier,
                                    text: l.name,
                                    value: l.identifier,
                                }))}
                                value={team}
                                onChange={(e, { value }) => setTeam(value)}
                            />
                        </Div>
                    )}
                </Div>
                {users && (
                    <Div gapTop>
                        <Progress size="large" percent={percent} indicating />
                    </Div>
                )}
                <br />
                <Button
                    primary
                    content="Update User"
                    onClick={tryUpdate}
                    disabled={user && !validEmail}
                />
            </Modal.Content>
        </Modal>
    );
}
