import React, { useState } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { Input, Image } from "semantic-ui-react";
import { Div, Button } from "@components/Generics.react";
import ImageUploader from "@components/ImageUploader.react";

import PreviewLayout from "@layouts/PreviewLayout.react";
import { MapList } from "@schemas/programMapSchema";
import { useFeatures } from "flagged";

export default function ProgramSetup(props) {
    const { program, updateProgram } = props;
    const features = useFeatures();
    const [search, setSearch] = useState("");
    const wrapperList = _.filter(_.keys(MapList), (template) =>
        template.includes(search)
    );

    const { t } = useTranslation("common");
    const [programName, setProgramName] = useState(program.name);
    const [programDescription, setProgramDescription] = useState(
        program.description
    );
    const [programImage, setProgramImage] = useState(program.image);
    const [imgEditorOpen, setImgEditorOpen] = useState(false);

    function saveProgramImage(image) {
        setProgramImage(image);
        setImgEditorOpen(false);
    }

    async function saveProgram() {
        await updateProgram({
            id: program._id,
            name: programName,
            image: programImage,
            description: programDescription,
        }).unwrap();
        toast(t("components.saved"));
    }

    async function changeWrapper(selectedWrapper) {
        await updateProgram({
            id: program._id,
            wrapper: selectedWrapper,
        }).unwrap();
        toast(t("components.saved"));
    }

    // ========================= Render Function =================================
    return (
        <Div fluid fullht padTop flex noWrap spaceAround>
            <Div fullht wd="calc(100% - 320px)" basepad>
                <Div large uppercase gutter bold>
                    {t("components.editing")}: {program?.name}
                </Div>
                <Div ivory fluid gutter snubbed noOverflow flex>
                    <Div grey relative wd="300px" minHt="105px">
                        <Image src={programImage} />
                        <Div
                            absolute
                            layer={3}
                            style={{ bottom: "10px", right: "10px" }}
                        >
                            <Div flex clickable gapped>
                                <Button
                                    size="mini"
                                    content={t("components.uploadImage")}
                                    secondary
                                    onClick={() => setImgEditorOpen(true)}
                                />
                            </Div>
                            <ImageUploader
                                modalOpen={imgEditorOpen}
                                handleModalClose={() => setImgEditorOpen(false)}
                                handleModalSave={saveProgramImage}
                                value={programImage}
                                aspectRatio={0.5}
                                mWidth={640}
                            />
                        </Div>
                    </Div>
                    <Div basepad wd="calc(100% - 300px)">
                        <Input
                            fluid
                            label={t("builder.dic.name")}
                            value={programName}
                            onChange={(e, { value }) => setProgramName(value)}
                        />
                        <Input
                            fluid
                            label={t("builder.dic.description")}
                            value={programDescription}
                            onChange={(e, { value }) =>
                                setProgramDescription(value)
                            }
                        />
                        <Div fluid gapTop small>
                            <Button
                                primary
                                content={t("components.update")}
                                onClick={saveProgram}
                            />
                        </Div>
                    </Div>
                </Div>
                <Div smoke fluid>
                    {features.programs.theming && (
                        <Div ht="400px" autoOverflowY>
                            <Div bold medpad flex spaceBetween>
                                <Div big>
                                    {t("builder.program.selectTheme")}:
                                </Div>
                                <Input
                                    icon="search"
                                    placeholder={t("components.search")}
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </Div>
                            {program.node_list.length < 13 ? (
                                <Div clear flex flexStart gapped>
                                    {_.map(wrapperList, (wrapper, idx) => {
                                        return (
                                            <Div
                                                wd="calc(33.33% - 7.5px)"
                                                key={`wrapper-${idx}`}
                                                float-left
                                                medpad
                                                rounded
                                                clickable
                                                vapor={
                                                    wrapper !== program.wrapper
                                                }
                                                charcoal={
                                                    wrapper === program.wrapper
                                                }
                                                onClick={() =>
                                                    changeWrapper(wrapper)
                                                }
                                            >
                                                <Image
                                                    src={`/assets/images/programs/${wrapper}.jpg`}
                                                />
                                                <Div
                                                    uppercase
                                                    center-align
                                                    gapTop
                                                >
                                                    {wrapper.replace(/_/g, " ")}
                                                </Div>
                                            </Div>
                                        );
                                    })}
                                </Div>
                            ) : (
                                <Div
                                    ht="calc(100% - 100px)"
                                    flex
                                    fluid
                                    center-align
                                    bold
                                    big
                                >
                                    {t("builder.program.themeHelp")}
                                </Div>
                            )}
                        </Div>
                    )}
                </Div>
            </Div>
            <PreviewLayout
                key={`program-preview-${program.wrapper}`}
                url={`programs/${program._id}`}
                message={
                    program.wrapper &&
                    program.wrapper !== "none" &&
                    t("builder.dic.previewHelp")
                }
            />
        </Div>
    );
}
