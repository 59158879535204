// Import from NPM
// -------------------------------------
import React from "react";
import _ from "lodash";
import { Div } from "@components/Generics.react";
import "@styles/editor.scss";

const palettes = [
    {
        base: "#ffebee",
        baseText: "#000000",
        primary: "#ef5350",
        primaryText: "#ffffff",
        primaryVariation: "#b71c1c",
        secondary: "#454545",
        secondaryText: "#ffffff",
    },
    {
        base: "#fce4ec",
        baseText: "#000000",
        primary: "#ec407a",
        primaryText: "#ffffff",
        primaryVariation: "#880e4f",
        secondary: "#454545",
        secondaryText: "#ffffff",
    },
    {
        base: "#f3e5f5",
        baseText: "#000000",
        primary: "#ab47bc",
        primaryText: "#000000",
        primaryVariation: "#9c27b0",
        secondary: "#454545",
        secondaryText: "#ffffff",
    },
    {
        base: "#ede7f6",
        baseText: "#000000",
        primary: "#9575cd",
        primaryText: "#ffffff",
        primaryVariation: "#7e57c2",
        secondary: "#454545",
        secondaryText: "#ffffff",
    },
    {
        base: "#e8eaf6",
        baseText: "#000000",
        primary: "#7986cb",
        primaryText: "#ffffff",
        primaryVariation: "#5c6bc0",
        secondary: "#454545",
        secondaryText: "#ffffff",
    },
    {
        base: "#e3f2fd",
        baseText: "#000000",
        primary: "#1e88e5",
        primaryText: "#ffffff",
        primaryVariation: "#1565c0",
        secondary: "#454545",
        secondaryText: "#ffffff",
    },
    {
        base: "#e0f2f1",
        baseText: "#000000",
        primary: "#0097a7",
        primaryText: "#ffffff",
        primaryVariation: "#00838f",
        secondary: "#454545",
        secondaryText: "#ffffff",
    },
    {
        base: "#f1f8e9",
        baseText: "#000000",
        primary: "#558b2f",
        primaryText: "#000000",
        primaryVariation: "#33691e",
        secondary: "#454545",
        secondaryText: "#ffffff",
    },
    {
        base: "#f9fbe7",
        baseText: "#000000",
        primary: "#d4e157",
        primaryText: "#ffffff",
        primaryVariation: "#c0ca33",
        secondary: "#454545",
        secondaryText: "#ffffff",
    },
    {
        base: "#fffde7",
        baseText: "#000000",
        primary: "#fdd835",
        primaryText: "#ffffff",
        primaryVariation: "#fbc02d",
        secondary: "#454545",
        secondaryText: "#ffffff",
    },
    {
        base: "#fff3e0",
        baseText: "#000000",
        primary: "#ffcc80",
        primaryText: "#ffffff",
        primaryVariation: "#ff9800",
        secondary: "#454545",
        secondaryText: "#ffffff",
    },
    {
        base: "#efebe9",
        baseText: "#000000",
        primary: "#a1887f",
        primaryText: "#ffffff",
        primaryVariation: "#5d4037",
        secondary: "#454545",
        secondaryText: "#ffffff",
    },
    {
        base: "#fafafa",
        baseText: "#000000",
        primary: "#757575",
        primaryText: "#000000",
        primaryVariation: "#424242",
        secondary: "#454545",
        secondaryText: "#ffffff",
    },
    {
        base: "#757575",
        baseText: "#000000",
        primary: "#fafafa",
        primaryText: "#ffffff",
        primaryVariation: "#bdbdbd",
        secondary: "#454545",
        secondaryText: "#ffffff",
    },
];
export default function PaletteSelector(props) {
    const { name, deck, setDeck, leftBordered = true } = props;

    function setColors(index) {
        setDeck({ ...deck, ...{ colors: palettes[index] } });
    }

    // ========================= Render Function =================================
    return (
        <Div fit-content className="ribbon-btn" leftBordered={leftBordered}>
            <Div
                medpad
                white
                slightShadow
                snubbed
                fit-content
                center-align
                clickable
                flex
                gapped
            >
                {_.map(palettes, (theme, idx) => (
                    <Div
                        key={`theme-palette-${idx}`}
                        ht="50.5px"
                        wd="30px"
                        bgColor={theme.base}
                        slightShadow
                        hoverBold
                        onClick={() => setColors(idx)}
                    >
                        <Div fluid ht="15px" bgColor={theme.primary} />
                        <Div fluid ht="5px" bgColor={theme.primaryVariation} />
                    </Div>
                ))}
            </Div>
            <Div small center-align gapTop>
                {name}
            </Div>
        </Div>
    );
}
