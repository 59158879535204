import React, { useState } from "react";
import {
    useGetCourseInventoryQuery,
    useGetCreatorsQuery,
    useGetArticleInventoryQuery,
    useGetActivityLogQuery,
} from "@api/apiV6";
import _ from "lodash";
import ReactTable from "react-table-v6";
import moment from "moment";

import { Divider, Tab } from "semantic-ui-react";
import { Div } from "@components/Generics.react";
import { useFeatures } from "flagged";
import { deckFormats } from "@schemas/deckFormats";
export default function AnalyticsCreators(props) {
    const { data: audienceData, isSuccess } = useGetCreatorsQuery();
    const [selectedUser, setSelectedUser] = useState(null);

    let fullColumns = [
        {
            Header: "Username",
            id: "username",
            accessor: "name",
            Cell: ({ row }) => {
                return (
                    <Div
                        txtMain
                        clickable
                        onClick={() => {
                            setSelectedUser(row._original);
                        }}
                    >
                        {row._original.username}
                    </Div>
                );
            },
            filterMethod: (filter, row) =>
                _.includes(row.name.toLowerCase(), filter.value.toLowerCase()),
        },
        {
            Header: "Name",
            id: "fullname",
            accessor: (d) => `${d.first_name} ${d.last_name}`,
        },
        {
            Header: "Team",
            id: "team",
            accessor: (d) => d.team,
        },
        {
            Header: "Team Size",
            id: "teamUsers",
            accessor: "teamUsers",
            Cell: ({ row }) => <Div center-align>{row.teamUsers.length}</Div>,
            filterMethod: (filter, row) =>
                filter.value === "0"
                    ? row.node_list.length === 0
                    : row.node_list.length >= filter.value,
            width: 80,
        },
        {
            Header: "Last Accessed",
            id: "activity",
            accessor: (d) => d.activity.last_session,
            Cell: ({ row }) =>
                row.activity
                    ? moment(
                          new Date(row.activity),
                          "D/M/YYYY, h:mm:ss a"
                      ).format("Do MMM YY h:mm a")
                    : "Never accessed",
            sortType: "datetime",
            width: 160,
        },
    ];

    // ========================= Render Function =================================
    return (
        <Div basepad fullht>
            {isSuccess && (
                <Div flex spaceBetween top-aligned>
                    <Div white wd="calc(100% - 400px)">
                        <ReactTable
                            data={_.orderBy(
                                audienceData,
                                [(a) => a.teamUsers.length],
                                ["desc"]
                            )}
                            filterable
                            defaultFilterMethod={(filter, row, column) => {
                                const id = filter.pivotId || filter.id;
                                return row[id] !== undefined
                                    ? String(row[id])
                                          .toLowerCase()
                                          .includes(filter.value.toLowerCase())
                                    : true;
                            }}
                            columns={fullColumns}
                            pageSizeOptions={[10, 50, 100]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                        />
                    </Div>
                    <Div smoke wd="380px" medpad>
                        {selectedUser && (
                            <IndividualCreator
                                key={`show-creator-${selectedUser._id}`}
                                user={selectedUser}
                            />
                        )}
                    </Div>
                </Div>
            )}
        </Div>
    );
}

function IndividualCreator(props) {
    const { user } = props;
    const { data: courseList } = useGetCourseInventoryQuery();
    const { data: articleList } = useGetArticleInventoryQuery();
    const { data: activityLog, isSuccess: logSuccess } = useGetActivityLogQuery(
        user._id
    );
    const features = useFeatures();

    function showUserList(users) {
        return _.map(
            _.orderBy(
                users,
                [(a) => moment(a.activity?.last_session)],
                ["desc"]
            ),
            (t, idx) => {
                return (
                    <Div flex key={`team-user-${idx}`}>
                        <Div wd="50%">
                            {t.first_name} {t.last_name}
                        </Div>
                        <Div wd="50%">
                            {t.activity?.last_session
                                ? moment(
                                      new Date(t.activity.last_session),
                                      "D/M/YYYY, h:mm:ss a"
                                  ).format("Do MMM YY h:mm a")
                                : "Never accessed"}
                        </Div>
                    </Div>
                );
            }
        );
    }
    const panes = [
        {
            menuItem: "Team",
            render: () => (
                <Tab.Pane>
                    <Div bold gutter>
                        Active Team
                    </Div>
                    {showUserList(
                        _.filter(
                            user.teamUsers,
                            (a) => a.active && a.activity?.last_session != null
                        )
                    )}
                    <Divider />
                    <Div bold gutter>
                        Inactive Team
                    </Div>
                    {showUserList(
                        _.filter(
                            user.teamUsers,
                            (a) => a.active && a.activity?.last_session == null
                        )
                    )}
                    <Divider />
                    <Div bold gutter>
                        Deactivated Users
                    </Div>
                    {showUserList(_.filter(user.teamUsers, (a) => !a.active))}
                </Tab.Pane>
            ),
        },
        features.courseware && {
            menuItem: "Courses",
            render: () => (
                <Tab.Pane>
                    {_.map(
                        _.filter(courseList, (c) => c.owner._id === user._id),
                        (c, idx) => (
                            <Div vapor medpad snug key={`course-name-${idx}`}>
                                <Div bold>{c.name}:</Div>
                                <Div gapTop>
                                    {_.map(c.locations, (loc, idy) => (
                                        <Div key={`course-topic-${idx}-${idy}`}>
                                            - {loc.name}
                                        </Div>
                                    ))}
                                </Div>
                            </Div>
                        )
                    )}
                </Tab.Pane>
            ),
        },
        features.learningCenter && {
            menuItem: "Articles",
            render: () => (
                <Tab.Pane>
                    {_.map(
                        _.filter(articleList, (c) => c.owner._id === user._id),
                        (c, idx) => (
                            <Div vapor medpad snug key={`article-name-${idx}`}>
                                <Div bold>{c.name}</Div>
                                {_.find(deckFormats, {
                                    category: c.category,
                                })?.name ||
                                    _.find(deckFormats, {
                                        readerType: c.category,
                                    })?.name}
                            </Div>
                        )
                    )}
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Activity",
            render: () => (
                <Tab.Pane>
                    {logSuccess && (
                        <Div medpad smoke slightShadow>
                            {_.map(activityLog, (logEntry, idx) => (
                                <Div key={`log-${idx}`} white snug padded>
                                    <Div bold>
                                        {moment(
                                            new Date(logEntry.time),
                                            "D/M/YYYY, h:mm:ss a"
                                        ).format("Do MMM YY h:mm a")}
                                    </Div>
                                    <Div>{logEntry.event}</Div>
                                </Div>
                            ))}
                        </Div>
                    )}
                </Tab.Pane>
            ),
        },
    ].filter(Boolean);
    // ========================= Render Function =================================
    return <Tab panes={panes} />;
}
