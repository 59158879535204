import React, { useState } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import {
    useSendTeamEnrolMutation,
    useGetCourseInventoryQuery,
} from "@api/apiV6";

import { Div, Button } from "@components/Generics.react";
import { Modal, Icon, Dropdown, Loader } from "semantic-ui-react";

export default function AdminTeamEnrolModal(props) {
    const { isOpen, setOpen, teams } = props;
    const { data: courseList, isSuccess: courseListSuccess } =
        useGetCourseInventoryQuery();
    const [enrolTeam] = useSendTeamEnrolMutation();
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);

    async function tryUpdate() {
        const updateResponse = await enrolTeam({
            id: selectedCourse,
            team: selectedTeam,
        });
        if (updateResponse) {
            toast("Team Enrolled");
            setOpen(false);
        }
    }

    // ========================= Render Function =================================
    if (courseListSuccess)
        return (
            <Modal size="small" open={isOpen} onClose={() => setOpen(false)}>
                <Modal.Header>
                    Enrol into Course
                    <Div float-right clickable>
                        <Icon name="times" onClick={() => setOpen(false)} />
                    </Div>
                </Modal.Header>
                <Modal.Content>
                    <Dropdown
                        placeholder={"Select Course"}
                        fluid
                        selection
                        search
                        options={_.map(courseList, (l) => ({
                            key: l._id,
                            text: l.name,
                            value: l._id,
                        }))}
                        value={selectedCourse}
                        onChange={(e, { value }) => setSelectedCourse(value)}
                    />
                    <Dropdown
                        placeholder={"Select Team"}
                        fluid
                        selection
                        search
                        options={_.map(teams, (l) => ({
                            key: l.identifier,
                            text: l.name,
                            value: l.identifier,
                        }))}
                        value={selectedTeam}
                        onChange={(e, { value }) => setSelectedTeam(value)}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        primary
                        fluid
                        content="Enroll"
                        onClick={tryUpdate}
                    />
                </Modal.Actions>
            </Modal>
        );
    else return <Loader />;
}
