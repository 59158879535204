import React, { useState } from "react";
import _ from "lodash";
import {
    useCreateNewContentMutation,
    useUpdateArticleMutation,
    useGetAllCreatorsQuery,
} from "@api/apiV6";
import { toast } from "react-toastify";

import { Div, Button } from "@components/Generics.react";
import { Modal, Icon, Input, Dropdown } from "semantic-ui-react";

export default function UpsertArticleModal(props) {
    const { isOpen, setOpen, article } = props;
    const { data: audienceData, isSuccess } = useGetAllCreatorsQuery();

    const [articleName, setArticleName] = useState(article?.name);
    const [articleOwner, setArticleOwner] = useState(article?.owner?._id);

    const [createArticle] = useCreateNewContentMutation();
    const [updateArticle] = useUpdateArticleMutation();

    async function tryUpsert() {
        if (article == null) {
            const response = await createArticle({
                name: articleName,
                owner: articleOwner,
                contentType: "articles",
            });
            if (response) {
                toast("Article Created");
                setOpen(false);
            }
        } else {
            const response = await updateArticle({
                id: article._id,
                name: articleName,
                owner: articleOwner,
            });
            if (response) {
                toast("Article Updated");
                setOpen(false);
            }
        }
    }

    // ========================= Render Function =================================
    return (
        <Modal size="small" open={isOpen} onClose={() => setOpen(false)}>
            <Modal.Header>
                {article == null ? "Add" : "Edit"} Article
                <Div float-right clickable>
                    <Icon
                        name="times"
                        onClick={() => {
                            setOpen(false);
                        }}
                    />
                </Div>
            </Modal.Header>
            <Modal.Content>
                <Div white basepad>
                    <Div fluid gutter>
                        <Input
                            fluid
                            label={"Article Name"}
                            placeholder={"Enter article name"}
                            value={articleName}
                            onChange={(e) => setArticleName(e.target.value)}
                        />
                        {isSuccess ? (
                            <Dropdown
                                placeholder={"Select Owner"}
                                fluid
                                selection
                                search
                                options={_.map(audienceData, (l) => ({
                                    key: l._id,
                                    text: l.username,
                                    value: l._id,
                                }))}
                                value={articleOwner}
                                onChange={(e, { value }) =>
                                    setArticleOwner(value)
                                }
                            />
                        ) : (
                            <Div relaxed>Fetching Owners...</Div>
                        )}
                    </Div>
                </Div>
                <Button
                    primary
                    content={`${article == null ? "Add" : "Edit"} Article`}
                    onClick={tryUpsert}
                />
            </Modal.Content>
        </Modal>
    );
}
