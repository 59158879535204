import React, { useEffect, useState } from "react";
import _ from "lodash";
import axios from "axios";
import { baseUrl } from "@api/apiV6";
import "@styles/readers.scss";
import { Div, HDiv, Button } from "@components/Generics.react";
import CalculatorField from "./panels/CalculatorField.react";
import { Divider } from "semantic-ui-react";

export default function CalculatorReader(props) {
    const { deck, recordAttempt } = props;
    const [varList, setVarList] = useState(
        _.map(deck?.content?.variables, (field) => ({
            key: field.var,
            value: parseInt(
                (parseInt(field.max, 0) + parseInt(field.min, 0)) / 2,
                0
            ),
        }))
    );
    const [result, setResult] = useState(null);

    function setValue(field, val) {
        setResult(null);
        const newVarList = _.cloneDeep(varList);
        const updateIndex = _.findIndex(newVarList, { key: field.var });
        newVarList[updateIndex] = {
            key: field.var,
            value: val,
        };
        setVarList(newVarList);
    }
    function formatNumber(num) {
        // Check if the number is an integer
        if (Number.isInteger(num)) {
            // If it's an integer, return the number without decimal points
            return num.toString();
        } else {
            // If it's not an integer, round it to two decimal points
            return num.toFixed(2);
        }
    }
    async function calculate() {
        let formula = deck?.content?.formula;
        _.each(varList, (field) => {
            let replacee = new RegExp(field.key, "g");
            formula = formula.replace(replacee, field.value);
        });
        const response = await axios({
            method: "post",
            url: `${baseUrl}api/v3/utilities/eval_formula`,
            data: { formula: formula },
        });
        setResult(response.data.result);
    }

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function showFeedback() {
        const rows = deck?.content?.feedback;
        if (result && rows) {
            let message = null;
            for (let i = 0; i < rows.length; i++) {
                if (message == null) {
                    if (
                        result >= parseInt(rows[i].min, 0) &&
                        result <= parseInt(rows[i].max, 0)
                    ) {
                        message = {
                            header: rows[i].header,
                            message: rows[i].message,
                        };
                    }
                }
            }
            return message;
        } else return null;
    }
    const feedback = showFeedback();

    // ========================= Render Function =================================
    return (
        <Div
            fluid
            fullht
            centered
            relative
            maxWd="400px"
            center-align
            white
            superpad
            rounded
        >
            <HDiv gutter content={deck.content?.instructions} />
            <Div trench fluid>
                {_.map(deck?.content?.variables, (field, idx) => {
                    return (
                        <CalculatorField
                            key={`slider-${idx}`}
                            field={field}
                            value={_.find(varList, { key: field.var }).value}
                            setValue={setValue}
                        />
                    );
                })}
            </Div>
            <Divider />
            <Button primary fluid content="Calculate" onClick={calculate} />
            <br />
            {result && (
                <Div basepad smoke center-align rounded slightShadow>
                    <Div gapTop>{deck?.content?.result}</Div>
                    <Div massive gutter bold gapTop>
                        {formatNumber(result)}
                        {deck?.content?.suffix}
                    </Div>
                    {feedback && (
                        <Div white basepad snubbed>
                            <Div altTxt big bold>
                                {feedback.header}
                            </Div>
                            <HDiv gapTop content={feedback.message} />
                        </Div>
                    )}
                </Div>
            )}
        </Div>
    );
}
