import React from "react";
import { useGetTicketsQuery } from "@api/apiV6";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "lodash";

import ReactTable from "react-table-v6";

import { Icon, Loader, Popup } from "semantic-ui-react";
import { Div } from "@components/Generics.react";
export default function TicketsTable(props) {
    const user = useSelector((state) => state.auth.user);
    const { data: ticketdata, isSuccess, refetch } = useGetTicketsQuery(user._id);
    const { t } = useTranslation("common");

    const fullColumns = [
        {
            Header: t("helpdesk.createdAt"),
            accessor: "createdAt",
            Cell: (row) => new Date(row.value).toLocaleDateString("en-IN"),
            maxWidth: 90,
        },
        {
            Header: t("helpdesk.priority"),
            accessor: "priority",
            maxWidth: 50,
        },
        {
            Header: t("helpdesk.problem"),
            accessor: "description",
        },
        {
            Header: t("helpdesk.status"),
            accessor: "status",
            maxWidth: 50,
        },
        {
            Header: "",
            accessor: "category",
            Cell: (row) => (
                <Popup
                    position="right center"
                    on="hover"
                    trigger={
                        <Div clickable fit-content>
                            <Icon name="id card" />
                        </Div>
                    }
                >
                    <Div bold>{row.original.category}</Div>
                    <Div small italics gapBottom>
                        {row.original.problem}
                    </Div>
                    <Popup.Content>{row.original.description}</Popup.Content>
                </Popup>
            ),
            maxWidth: 50,
        },
    ];

    // ========================= Render Function =================================
    return (
        <Div basepad white fullht>
            <Div big bold uppercase gutter>
                <Div float-right clickable tiny>
                    <Icon name="refresh" circular inverted onClick={refetch} />
                </Div>
                {t("helpdesk.tableHeader")}:
            </Div>
            <Div ht="95%" autoOverflowY>
                {isSuccess ? (
                    <ReactTable
                        key={`table`}
                        data={_.orderBy(ticketdata, ["createdAt"], ["desc"])}
                        filterable
                        columns={fullColumns}
                        pageSizeOptions={[10, 15, 30]}
                        defaultPageSize={10}
                        className="-striped -highlight"
                    />
                ) : (
                    <Loader />
                )}
            </Div>
        </Div>
    );
}
