// Import from NPM
// -------------------------------------
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Icon } from "semantic-ui-react";

// Import from Config
// -------------------------------------
import { useGetDeckLeaderboardQuery } from "@api/apiV6";
import "@styles/components.scss";
import { darkStyle } from "@utilities/helpers";

// Import Components
// -------------------------------------
import { Div } from "@components/Generics.react";
import Leaderboard from "@components/Leaderboard.react";

export default function DeckLeaderboard(props) {
    const { deck, score, closeScreens } = props;
    const thisUser = useSelector((state) => state.auth.user);
    const { t } = useTranslation("common");
    const {
        data: leaderboard,
        isLoading,
        isSuccess,
        isError,
        error,
        refetch,
    } = useGetDeckLeaderboardQuery(deck._id);

    function getUserLeaderboard(users) {
        // Filter users based on the userDetail
        let filteredUsers = _.cloneDeep(users).filter((user) => {
            // Check if userDetail is provided and match the corresponding value
            if (
                !user.user ||
                _.keys(user.parameters).length === 0 ||
                user.parameters?.score === 0 ||
                user.parameters?.score === null
            )
                return false;
            return true; // Return all users if userDetail is not provided or empty
        });
        let currentUser = _.find(filteredUsers, { userId: thisUser._id });
        if (currentUser) {
            currentUser.parameters.score = Math.max(
                currentUser.parameters.score || 0,
                score || 0
            );
        } else {
            filteredUsers.push({
                userId: thisUser._id,
                user: thisUser,
                parameters: { score: score },
            });
        }

        // Sort the filtered users based on points in descending order
        filteredUsers.sort((a, b) => b.parameters.score - a.parameters.score);

        // Map the filtered users to the desired response format
        const filteredLeaderboard = filteredUsers.map((user) => {
            return {
                _id: user.user._id,
                first_name: user.user.first_name,
                last_name: user.user.last_name,
                score: user.parameters.score,
            };
        });

        // Return the filteredLeaderboard array
        return filteredLeaderboard;
    }

    const sortedLeaderboard = useMemo(() => {
        if (isSuccess) {
            let sortedData = _.orderBy(
                getUserLeaderboard(leaderboard),
                ["score", "completionDate"],
                ["desc", "asc"]
            );

            // Remove owner from list
            sortedData = _.filter(sortedData, (usr) => usr._id !== deck.owner);
            return sortedData;
        } else {
            return [];
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, leaderboard, deck]);

    // ========================= Render Function =================================
    return (
        <Div
            fullht
            fluid
            maxWd="400px"
            compact
            centered
            relative
            style={darkStyle(thisUser.branding.colors.main, "to top")}
        >
            <Div
                absolute
                clickable
                huge
                onClick={closeScreens}
                style={{ top: "10px", right: "10px" }}
            >
                <Icon name="close" />
            </Div>
            <Div big>
                <Leaderboard
                    leaderboardData={sortedLeaderboard}
                    leaderboardType={"score"}
                    header={t("deck.scoreboard.header")}
                    isLoading={isLoading}
                    isError={isError}
                    isSuccess={isSuccess}
                    refetch={refetch}
                    error={error}
                    noPodium={true}
                />
            </Div>
        </Div>
    );
}
