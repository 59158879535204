import React from "react";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

import "@styles/readers.scss";
import { Div } from "@components/Generics.react";
import { Input } from "semantic-ui-react";

export default function CalculatorField(props) {
    const { field, value, setValue } = props;
    const max = parseInt(field.max);
    const min = parseInt(field.min);

    function handleInput(value) {
        setValue(field, value[1]);
    }

    // ========================= Render Function =================================
    return (
        <Div gutter fluid>
            <Div gutter flex spaceBetween>
                {field.name}
                <Input
                    value={value}
                    onChange={(e) => setValue(field, e.target.value)}
                    style={{ maxWidth: "128px" }}
                />
            </Div>
            <RangeSlider
                className="single-thumb"
                thumbsDisabled={[true, false]}
                rangeSlideDisabled={true}
                value={[0, value]}
                min={min}
                max={max}
                onInput={handleInput}
            />
        </Div>
    );
}
