import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Div } from "@components/Generics.react";
import ArticleCard from "@components/ArticleCard.react";
import { desnaked } from "@utilities/helpers";
import Accordion from "@components/Accordion.react";
import { Image, Input } from "semantic-ui-react";

export default function ListHome(props) {
    const { header, description, tags, image } = props;
    const taggedList = useSelector((state) => state.articles.taggedList);
    const [searchText, setSearchText] = useState("");
    const { t } = useTranslation("common");
    const isPortrait = window.innerHeight > window.innerWidth;

    const proppedTags = _.filter(
        tags,
        (tag) => taggedList[tag]?.articles.length > 0
    );
    const selectedTags = _.map(proppedTags, (tag) => ({
        title: desnaked(tag).toUpperCase(),
        content:
            searchText === ""
                ? taggedList[tag]?.articles
                : _.filter(taggedList[tag]?.articles, (article) =>
                      article.name
                          .toLowerCase()
                          .includes(searchText.toLowerCase())
                  ),
    }));

    // ========================= Render Function =================================
    return (
        <Div>
            <Div fluid relative rimmed>
                <Div snubbed noOverflow>
                    <Image fluid src={image} />
                </Div>
                <Div
                    absolute
                    big
                    bold
                    uppercase
                    className="root-layout-header"
                >
                    {header}
                </Div>
            </Div>
            <Div white padSides gapBottom>
                <Div gapTop gutter>
                    {description}
                </Div>
            </Div>
            <Div medpad ash>
                <Input
                    fluid
                    placeholder={t("builder.dic.searchWithin")}
                    icon={{ name: "search" }}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </Div>
            <Accordion
                fluid
                compact
                allOpen={searchText !== ""}
                panels={_.map(selectedTags, (tag, idx) => {
                    return {
                        key: `tag-section-${idx}`,
                        title: tag.title,
                        content: (
                            <Div
                                key={`carousel-index-${idx}`}
                                smoke={!isPortrait}
                                gutter={!isPortrait}
                            >
                                {_.map(tag.content, (article, idx2) => {
                                    return (
                                        <ArticleCard
                                            key={`article-right-${article?._id}-${idx2}`}
                                            article={article}
                                            feedStyle
                                        />
                                    );
                                })}
                            </Div>
                        ),
                    };
                })}
            />
        </Div>
    );
}
