// Import from NPM
// -------------------------------------
import React from "react";
import { Div } from "@components/Generics.react";
import "@styles/courseLayout.scss";

export default function Marker(props) {
    const {
        id,
        locIndex,
        locCoords,
        content,
        status,
        clickable,
        handleClick,
        sequential,
    } = props;

    let locationStyle = {
        position: "absolute",
        top: parseInt(locCoords.top, 0) + "%",
        left: parseInt(locCoords.left, 0) + "%",
        cursor: clickable ? "pointer" : "normal",
    };
    // ========================= Render Function =================================
    return (
        <Div
            style={locationStyle}
            id={id}
            onClick={() => clickable && handleClick(locIndex)}
        >
            <Div
                className={`marker-icon ${status} ${
                    sequential ? "sequential" : ""
                }`}
            >
                <Div className="beacon" />
                <Div className="marker-text">{content.sequence + 1}</Div>
            </Div>
        </Div>
    );
}
