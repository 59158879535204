import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFeatures } from "flagged";
import _ from "lodash";
import { Grid, Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import MasterLayout from "@layouts/MasterLayout.react";
import { Div } from "@components/Generics.react";
import Banner from "@components/Banner.react";
import { useGetBannersQuery, useGetCourseLibraryQuery } from "@api/apiV6";
import CategoryCard from "@components/CategoryCard.react";
import RecommendedArticles from "@components/RecommendedArticles.react";
import FilterField from "@components/FilterField.react";
import CourseCard from "@components/CourseCard.react";

export default function Library(props) {
    const { data: banners, isSuccess: bannerSuccess } = useGetBannersQuery();
    const { data: library, isSuccess: librarySuccess } =
        useGetCourseLibraryQuery();
    const user = useSelector((state) => state.auth.user);
    const navigate = useNavigate();
    const features = useFeatures();
    const { t } = useTranslation("common");
    const [courses, setCourses] = useState(library?.courses);
    const [fetched, setFetched] = useState(false);

    const isPortrait = window.innerHeight > window.innerWidth;
    useEffect(() => {
        if (features.general.autoRouting) {
            if (
                library?.courses &&
                _.keys(_.pickBy(library?.courses, { launch: true })).length ===
                    1
            ) {
                navigate(
                    `/explore/${
                        _.keys(_.pickBy(library?.courses, { launch: true }))[0]
                    }`
                );
                } else if (
                    features.programs.noPrograms ||
                (library?.categories && _.keys(library.categories).length === 1)
                ) {
                if (library?.categories[0])
                    navigate(
                        `/programs/${library?.categories[0]?._id}/isolated`
                    );
                else navigate(`/programs/listing`);
            } else {
                setFetched(true);
            }
        } else {
            setFetched(true);
        }
    
        if (librarySuccess) {
            setCourses(library?.courses);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [librarySuccess, library, library?.courses]);

    const pageContent = (
        <Div fluid trench>
            <Grid stackable divided="vertically">
                <Grid.Row>
                    {bannerSuccess && (
                        <Grid.Column width={features.learningCenter ? 8 : 6}>
                            <Banner ads={banners} />
                        </Grid.Column>
                    )}
                    <Grid.Column
                        width={
                            bannerSuccess
                                ? features.learningCenter
                                    ? 8
                                    : 10
                                : 16
                        }
                    >
                        <Div headline altText gutter>
                            {t("library.title")}
                        </Div>
                        <Div>{t("library.context")}</Div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Div gutter>
                <Divider horizontal>
                    {!features.programs.hasSequential && (
                        <FilterField
                            srcData={library?.courses}
                            setResult={setCourses}
                            searchKey="name"
                        />
                    )}
                </Divider>
            </Div>
            {librarySuccess && (
                <Div padded={isPortrait}>
                    {_.keys(courses)?.length > 0 ? (
                        <Grid
                            centered
                            columns={
                                courses?.length === library?.courses?.length
                                    ? isPortrait
                                        ? 2
                                        : features.learningCenter
                                        ? 3
                                        : 4
                                    : isPortrait
                                    ? 1
                                    : 2
                            }
                        >
                            {courses?.length === library?.courses?.length ? (
                                <Grid.Row>
                                    {librarySuccess &&
                                        _.map(
                                            _.orderBy(
                                                _.filter(
                                                    library.categories,
                                                    (category) =>
                                                        _.some(
                                                            _.map(
                                                                category.node_list,
                                                                "launch"
                                                            )
                                                        ) &&
                                                        (category.languages ==
                                                            null ||
                                                            category.languages
                                                                .length === 0 ||
                                                            (user.lang &&
                                                                category.languages.includes(
                                                                    user.lang
                                                                )))
                                                ),
                                                ["priority"],
                                                ["desc"]
                                            ),
                                            (category, idx) => {
                                                const completion =
                                                    _.meanBy(
                                                        _.map(
                                                            _.map(
                                                                category.node_list,
                                                                "_id"
                                                            ),
                                                            (courseId) =>
                                                                library
                                                                    ?.courses[
                                                                    courseId
                                                                ].currentState
                                                                    ? library
                                                                          ?.courses[
                                                                          courseId
                                                                      ]
                                                                          .currentState
                                                                          .completion
                                                                    : false
                                                        ),
                                                        _.identity
                                                    ) * 100;
                                                return (
                                                    <Grid.Column
                                                        key={`category-card-${idx}`}
                                                    >
                                                        <CategoryCard
                                                            category={category}
                                                            completion={
                                                                completion
                                                            }
                                                        />
                                                    </Grid.Column>
                                                );
                                            }
                                        )}
                                </Grid.Row>
                            ) : (
                                <Grid.Row>
                                    {_.map(courses, (course, idx) => {
                                        return (
                                            <Grid.Column
                                                key={`course-card-${idx}`}
                                            >
                                                <CourseCard
                                                    course={{
                                                        ...course,
                                                        ...{
                                                            currentState:
                                                                library
                                                                    ?.courses[
                                                                    course._id
                                                                ].currentState,
                                                        },
                                                    }}
                                                />
                                            </Grid.Column>
                                        );
                                    })}
                                </Grid.Row>
                            )}
                        </Grid>
                    ) : (
                        <Div
                            fluid
                            ht={isPortrait ? "100px" : "200px"}
                            ash
                            flex
                            bold
                            uppercase
                            big
                            rounded
                        >
                            {t("builder.welcome.waitingForContent")}
                        </Div>
                    )}
                </Div>
            )}
        </Div>
    );

    // ========================= Render Function =================================
    if (fetched) {
        return (
            <MasterLayout
                title={"Welcome"}
                mainPanel={pageContent}
                rightPanelName={t(`components.recommended`)}
                rightPanel={<RecommendedArticles />}
                startFullScreen={!isPortrait && !features.learningCenter}
            />
        );
    } else return <Div />;
}
