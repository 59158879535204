import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import _ from "lodash";
import { Icon } from "semantic-ui-react";
import { useFeatures } from "flagged";
import { toast } from "react-toastify";

import {
    useGetArticleInventoryQuery,
    useGetCourseInventoryQuery,
    useGetProgramInventoryQuery,
    useGetTeamUsersQuery,
} from "@api/apiV6";

import CreatorLayout from "@layouts/CreatorLayout.react";
import { Div } from "@components/Generics.react";
import BrowserBar from "@components/BrowserBar.react";
import BuilderHeader from "@components/BuilderHeader.react";
import ArticleDashboard from "./ArticleDashboard.react";
import MainCourseAnalytics from "./MainCourseAnalytics.react";
import UserAnalytics from "./panels/users/UserAnalytics.react";

export default function BuilderAnalytics(props) {
    const user = useSelector((state) => state.auth.user);
    const features = useFeatures();

    const {
        data: articles,
        isSuccess: articlesSuccess,
        refetch: articleRefetch,
    } = useGetArticleInventoryQuery();
    const {
        data: courses,
        isSuccess: coursesSuccess,
        refetch: courseRefetch,
    } = useGetCourseInventoryQuery();
    const {
        data: programs,
        isSuccess: programsSuccess,
        refetch: programRefetch,
    } = useGetProgramInventoryQuery();
    const {
        data: audienceData,
        isSuccess,
        refetch: userRefetch,
    } = useGetTeamUsersQuery(
        features.tenanting.multiTenanted ? user.team : "overall"
    );

    const [searchParams, setSearchParams] = useSearchParams();
    const { t } = useTranslation("common");

    async function refreshData() {
        await articleRefetch();
        await courseRefetch();
        await programRefetch();
        await userRefetch();
        toast(t("builder.welcome.pageRefreshed"));
    }
    // Content Structure passed has to be {_id, name, parent:{_id, name}}
    const contentSections = [
        features.courseware && {
            section: "users",
            name: t("builder.welcome.userLibrary"),
            data: isSuccess
                ? _.map(
                      _.orderBy(
                          _.filter(audienceData, { active: true }),
                          ["first_name", "last_name"],
                          ["asc", "asc"]
                      ),
                      (l) => {
                          return {
                              ...l,
                              name: `${l.first_name} ${l.last_name} (${l.username})`,
                              parent: {
                                  _id: t("analytics.learners"),
                                  name: t("analytics.learners"),
                              },
                          };
                      }
                  )
                : [],
            extra: [],
            pin: [],
            collection: t("builder.dic.programs"),
            groupBy: null,
        },
        features.courseware && {
            section: "courses",
            name: t("courseware"),
            data: coursesSuccess
                ? features.courses.maxCourses === "Inf"
                    ? _.filter(
                          courses,
                          (c) =>
                              c.course_type !== "exam" &&
                              c.owner?.team === user.team
                      )
                    : _.filter(
                          courses,
                          (c) =>
                              c.course_type !== "exam" &&
                              c.owner?.team === user.team
                      ).slice(0, features.courses.maxCourses)
                : [],
            extra: programsSuccess
                ? _.filter(
                      programs,
                      (program) =>
                          program.node_list.length === 0 &&
                          program.list_type === "Course" &&
                          program.owner?.team === user.team
                  )
                : [],
            pin: [t("builder.dic.pubLib")],
            collection: t("builder.dic.programs"),
            groupBy: null,
        },
        features.learningCenter && {
            section: "articles",
            name: t("learningCenter"),
            data: articlesSuccess
                ? _.map(
                      features.tenanting.multiTenanted &&
                          !features.ability.builder.crossAccess
                          ? _.filter(
                                articles,
                                (article) =>
                                    article.owner._id === user._id ||
                                    _.includes(
                                        user.branding.repos,
                                        article.owner?.team
                                    )
                            )
                          : articles,
                      (article) => {
                          if (article.owner?._id === user._id) {
                              return {
                                  ...article,
                                  parent:
                                      article.parent != null
                                          ? article.parent
                                          : {
                                                _id: t(
                                                    "builder.dic.unallocated"
                                                ),
                                                name: t(
                                                    "builder.dic.unallocated"
                                                ),
                                            },
                              };
                          } else
                              return {
                                  ...article,
                                  parent: {
                                      _id: t("builder.dic.generalContent"),
                                      name: t("builder.dic.generalContent"),
                                  },
                              };
                      }
                  )
                : [],
            extra: programsSuccess
                ? _.filter(
                      programs,
                      (program) =>
                          program.node_list.length === 0 &&
                          program.list_type === "Article"
                  )
                : [],
            pin: [t("builder.dic.generalContent")],
            collection: t("builder.dic.series"),
            groupBy: "category",
        },
        features.courseware &&
            features.courses.hasExams && {
                section: "exams",
                name: t("exams"),
                data: coursesSuccess
                    ? _.filter(
                          courses,
                          (c) =>
                              c.course_type === "exam" &&
                              c.owner?.team === user.team
                      )
                    : [],
                extra: [],
                pin: [],
                collection: t("builder.dic.programs"),
                groupBy: null,
            },
    ].filter(Boolean);

    const btnSections = [];

    const [target, setTarget] = useState(null);
    const [activeSection, setActiveSection] = useState(contentSections[0].name);

    useEffect(() => {
        const thisTarget = searchParams.get("target");
        const thisSection = searchParams.get("section");
        if (thisTarget != null && thisSection != null) {
            setTarget({
                key: thisTarget,
                type: "child",
                section: thisSection,
            });
        }
    }, [searchParams]);

    const setSelected = (selectedTarget) => {
        setSearchParams(
            selectedTarget.type === "child"
                ? {
                      target: selectedTarget.key,
                      section: selectedTarget.section,
                  }
                : {}
        );
        setTarget(selectedTarget);
    };

    const activeItem = searchParams.get("target");

    function displaySection() {
        let component = (
            <Div ht="calc(100% - 60px)" fluid>
                <BuilderHeader
                    image={"/assets/images/configurable/appanalytics.jpg"}
                    header={t("builder.headers.analytics.header")}
                    description={t("builder.headers.analytics.help")}
                />
                <Div flex ht="calc(100% - 200px)" fluid>
                    <Div ash megapad center-align slightShadow wd="500px">
                        <Icon
                            size="huge"
                            name="pie chart"
                            circular
                            inverted
                            color="grey"
                        />
                        <Div padTop headline uppercase fluid altText gutter>
                            {t("builder.welcome.analyticsHeader")}
                        </Div>
                        <Div large>{t("builder.welcome.selectAnalytics")}</Div>
                    </Div>
                </Div>
            </Div>
        );
        if (target && target.key.length === 24)
            switch (target.section) {
                case "users":
                    const thisUser = _.find(audienceData, {
                        _id: target.key,
                    });
                    component =
                        target.type === "parent" ? (
                            component
                        ) : (
                            <Div padSides>
                                <BuilderHeader
                                    image={
                                        "/assets/images/configurable/appusers.jpg"
                                    }
                                    header={`${thisUser?.first_name} ${thisUser?.last_name}`}
                                    description={thisUser?.username}
                                    forceImage
                                />
                                {thisUser != null && (
                                    <UserAnalytics
                                        user={thisUser}
                                        courses={courses}
                                    />
                                )}
                            </Div>
                        );
                    break;
                case "courses":
                    const thisCourse = _.find(courses, {
                        _id: target.key,
                    });
                    component =
                        target.type === "parent" ? (
                            component
                        ) : (
                            <Div padSides>
                                <BuilderHeader
                                    image={thisCourse?.image}
                                    header={thisCourse?.name}
                                    description={thisCourse?.description}
                                    forceImage
                                />
                                {thisCourse != null && (
                                    <MainCourseAnalytics course={thisCourse} />
                                )}
                            </Div>
                        );
                    break;
                case "articles":
                    const thisArticle = _.find(articles, {
                        _id: target.key,
                    });
                    component =
                        target.type === "parent" ? (
                            component
                        ) : (
                            <Div padSides>
                                <BuilderHeader
                                    image={thisArticle?.image}
                                    header={thisArticle?.name}
                                    description={thisArticle?.description}
                                    forceImage
                                />
                                <ArticleDashboard article={thisArticle} />
                            </Div>
                        );
                    break;
                case "exams":
                    const thisExam = _.find(courses, {
                        _id: target.key,
                    });
                    component =
                        target.type === "parent" ? (
                            component
                        ) : (
                            <Div padSides>
                                <BuilderHeader
                                    image={thisExam?.image}
                                    header={thisExam?.name}
                                    description={thisExam?.description}
                                    forceImage
                                />
                                {thisExam != null && (
                                    <MainCourseAnalytics course={thisExam} />
                                )}
                            </Div>
                        );
                    break;
                default:
            }
        return component;
    }

    const pageContent = (
        <>
            <Div
                fullht
                wd="300px"
                float-left
                vapor
                relative
                layer={2}
                slightShadow
            >
                <BrowserBar
                    name={t("builder.welcome.analyticsHeader")}
                    contentSections={contentSections}
                    btnSections={btnSections}
                    setTarget={setSelected}
                    activeSection={activeSection}
                    setSection={setActiveSection}
                    activeItem={activeItem}
                    localStore="analyticsRecents"
                    maxRecents={3}
                    image="appanalytics.jpg"
                    noAdd
                    refetch={refreshData}
                />
            </Div>
            <Div fullht wd="calc(100% - 300px)" float-left ivory>
                {displaySection()}
            </Div>
        </>
    );

    // ========================= Render Function =================================
    return (
        <CreatorLayout
            title={t("appCreator")}
            navName={t("builder.welcome.analytics")}
            pageCode={"analytics"}
            mainPanel={pageContent}
            rootPage={true}
        />
    );
}
