import React from "react";
import { useTranslation } from "react-i18next";
import { Tab } from "semantic-ui-react";

import MasterLayout from "@layouts/MasterLayout.react";
import TicketsTable from "./panels/TicketsTable.react";
import TicketForm from "./panels/TicketForm.react";
import HelpArticles from "./panels/HelpArticles.react";

export default function LearnerHelpDesk(props) {
    const { t } = useTranslation("common");

    return (
        <MasterLayout
            title={t("helpdesk.header")}
            navName={t("helpdesk.header")}
            mainPanel={
                <Tab
                    panes={[
                        {
                            menuItem: t("helpdesk.submit"),
                            render: () => (
                                <Tab.Pane>
                                    <TicketForm />
                                </Tab.Pane>
                            ),
                        },
                        {
                            menuItem: t("helpdesk.tableHeader"),
                            render: () => (
                                <Tab.Pane>
                                    <TicketsTable />
                                </Tab.Pane>
                            ),
                        },
                    ]}
                />
            }
            rightPanel={<HelpArticles />}
            rightPanelName={t("helpdesk.helpArticles")}
            rootPage={false}
        />
    );
}
