import React from "react";

import { Loader } from "semantic-ui-react";
import { useGetCourseBuilderQuery } from "@api/apiV6";
import CourseDashboard from "./CourseDashboard.react";

export default function MainCourseAnalytics(props) {
    const { course } = props;
    const { data: fullCourse, isSuccess } = useGetCourseBuilderQuery(
        course._id
    );
    if (isSuccess) return <CourseDashboard course={fullCourse} />;
    else return <Loader />;
}
