import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Div, Image } from "@components/Generics.react";
import { Input } from "semantic-ui-react";

import ArticleCarousel from "@components/ArticleCarousel.react";
import ArticleCard from "@components/ArticleCard.react";

export default function CarouselHome(props) {
    const { header, description, tags, image } = props;
    const allArticles = useSelector((state) => state.articles);
    const taggedList = useSelector((state) => state.articles.taggedList);
    const [searchText, setSearchText] = useState("");
    const { t } = useTranslation("common");
    const isPortrait = window.innerHeight > window.innerWidth;

    const proppedTags = _.filter(
        tags,
        (tag) => allArticles.taggedList[tag]?.articles.length > 0
    );

    const selectedArticles = useMemo(
        () =>
            _.uniqBy(
                _.flatten(
                    _.map(
                        proppedTags,
                        (tag) => allArticles.taggedList[tag]?.articles
                    )
                ),
                "_id"
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [proppedTags, allArticles]
    );
    const searchedArticles = useMemo(
        () =>
            searchText === ""
                ? null
                : _.filter(selectedArticles, (article) =>
                      _.includes(_.toLower(article.name), _.toLower(searchText))
                  ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [searchText, allArticles]
    );
    // ========================= Render Function =================================
    return (
        <>
            <Div fluid relative rimmed>
                <Div snubbed noOverflow>
                    <Image fluid src={image} />
                </Div>
                <Div absolute big bold uppercase className="root-layout-header">
                    {header}
                </Div>
            </Div>
            <Div white padSides gapBottom>
                <Div gapTop gutter>
                    {description}
                </Div>
            </Div>
            <Div medpad ash>
                <Input
                    fluid
                    placeholder={t("builder.dic.searchWithin")}
                    icon={{ name: "search" }}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </Div>
            {searchText === "" ? (
                _.map(proppedTags, (tag, carouselIndex) => {
                    return (
                        <Div
                            key={`carousel-index-${carouselIndex}`}
                            compact
                            padTop
                            rounded
                            smoke={!isPortrait}
                            gutter={!isPortrait}
                            small
                        >
                            <ArticleCarousel
                                header={
                                    !_.startsWith(t(`tags.${tag}`), "tags")
                                        ? t(`tags.${tag}`)
                                        : tag
                                }
                                libLink={`/tags/${tag}`}
                                articles={taggedList[tag]?.articles}
                                noLimit
                            />
                        </Div>
                    );
                })
            ) : (
                <Div basepad>
                    {_.map(searchedArticles, (article, idx) => {
                        return (
                            <ArticleCard
                                feedStyle
                                key={`article-${article.identifier}-${idx}`}
                                article={article}
                            />
                        );
                    })}
                </Div>
            )}
        </>
    );
}
