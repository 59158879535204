import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useEditUserMutation } from "@api/apiV6";
import _ from "lodash";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import { Div, Button } from "@components/Generics.react";
import { Modal, Icon, Input, Dropdown, Progress } from "semantic-ui-react";

export default function EditLicenseModal(props) {
    const { isOpen, setOpen, user, users } = props;
    const { t } = useTranslation("common");
    const [lType, setLType] = useState(
        user ? user.license.license_type : "Learner"
    );
    const [lUsers, setLUsers] = useState(user ? user.license.maxUsers : 10);
    const [lDecks, setLDecks] = useState(user ? user.license.maxDecks : 10);
    const [lCourses, setLCourses] = useState(
        user ? user.license.maxCourses : 99999
    );
    const [lArticles, setLArticles] = useState(
        user ? user.license.maxArticles : 99999
    );
    const [lCredits, setLCredits] = useState(user ? user.license.aiCredits : 0);

    const today = new Date();
    const [lStartDate, setLStartDate] = useState(
        user && user.license.start_date ? user.license.start_date : today
    );
    const [lEndDate, setLEndDate] = useState(
        user && user.license.end_date
            ? user.license.end_date
            : today.setFullYear(today.getFullYear() + 1)
    );
    const [percent, setPercent] = useState(0);

    const [editUser] = useEditUserMutation();

    async function tryUpdate() {
        if (user != null) {
            const updateResponse = await editUser({
                id: user._id,
                license: {
                    license_type: lType,
                    maxUsers: lUsers,
                    maxDecks: lDecks,
                    maxCourses: lCourses,
                    maxArticles: lArticles,
                    aiCredits: lCredits,
                    start_date: lStartDate,
                    end_date: lEndDate,
                },
            });
            if (updateResponse) {
                toast("User Updated");
                setOpen(false);
            }
        }
        if (users != null) {
            let processedUsers = 0;
            for (let i = 0; i < users.length; i++) {
                const updateResponse = await editUser({
                    id: users[i],
                    license: {
                        license_type: lType,
                        maxUsers: lUsers,
                        maxDecks: lDecks,
                        maxCourses: lCourses,
                        maxArticles: lArticles,
                        aiCredits: lCredits,
                        start_date: lStartDate,
                        end_date: lEndDate,
                    },
                });
                if (updateResponse) processedUsers++;
                setPercent(parseInt((processedUsers * 100) / users.length, 0));
            }
            toast(`${processedUsers} users Updated`);
            setOpen(false);
        }
    }

    // ========================= Render Function =================================
    return (
        <Modal size="small" open={isOpen} onClose={() => setOpen(false)}>
            <Modal.Header>
                {t("admin.userMgmt.editUser")} License
                <Div float-right clickable>
                    <Icon name="times" onClick={() => setOpen(false)} />
                </Div>
            </Modal.Header>
            <Modal.Content>
                <Div white basepad>
                    {user && (
                        <Div fluid gutter>
                            <Input
                                fluid
                                label={t("auth.registration.usernameLabel")}
                                placeholder={t(
                                    "auth.registration.usernamePlaceholder"
                                )}
                                disabled
                                value={user.username}
                            />
                        </Div>
                    )}
                    <Div fluid>
                        <Input
                            fluid
                            label="Maximum Users"
                            type="number"
                            placeholder="Maximum Users"
                            value={lUsers}
                            onChange={(e) => setLUsers(e.target.value)}
                        />
                    </Div>
                    <Div fluid>
                        <Input
                            fluid
                            label="Maximum Decks per Topic"
                            type="number"
                            placeholder="Maximum Decks"
                            value={lDecks}
                            onChange={(e) => setLDecks(e.target.value)}
                        />
                    </Div>
                    <Div fluid>
                        <Input
                            fluid
                            label="Maximum Courses per Topic"
                            type="number"
                            placeholder="Maximum Courses"
                            value={lCourses}
                            onChange={(e) => setLCourses(e.target.value)}
                        />
                    </Div>
                    <Div fluid>
                        <Input
                            fluid
                            label="Maximum Articles per Topic"
                            type="number"
                            placeholder="Maximum Articles"
                            value={lArticles}
                            onChange={(e) => setLArticles(e.target.value)}
                        />
                    </Div>
                    <br />
                    <Div flex gapped spaceBetween>
                        <Div flex wd="49%">
                            <Div wd="50%" padded ash fullht>
                                Start Date
                            </Div>
                            <Div wd="50%" vapor bordered>
                                <DatePicker
                                    dateFormat={"dd MMMM yyyy"}
                                    selected={moment(lStartDate).toDate()}
                                    onChange={(date) => setLStartDate(date)}
                                    showTimeSelect={false}
                                />
                            </Div>
                        </Div>
                        <Div flex wd="49%">
                            <Div wd="50%" padded ash fullht>
                                End Date
                            </Div>
                            <Div wd="50%" vapor bordered>
                                <DatePicker
                                    dateFormat={"dd MMMM yyyy"}
                                    selected={moment(lEndDate).toDate()}
                                    onChange={(date) => setLEndDate(date)}
                                    showTimeSelect={false}
                                />
                            </Div>
                        </Div>
                    </Div>
                    <br />
                    <Div fluid>
                        <Input
                            fluid
                            label="AI Credits"
                            type="number"
                            placeholder="AI Credits"
                            value={lCredits}
                            onChange={(e) => setLCredits(e.target.value)}
                        />
                    </Div>
                    <br />
                    <Div padded gapTop smoke>
                        <Div rimmed>Select License Type</Div>
                        <Dropdown
                            fluid
                            selection
                            options={_.map(
                                [
                                    "Superadmin",
                                    "Admin",
                                    "Creator",
                                    "Manager",
                                    "Trainer",
                                    "Learner",
                                ],
                                (l) => ({
                                    key: l,
                                    text: l,
                                    value: l,
                                })
                            )}
                            value={lType}
                            onChange={(e, { value }) => setLType(value)}
                        />
                    </Div>
                </Div>
                {users && (
                    <Div gapTop>
                        <Progress size="large" percent={percent} indicating />
                    </Div>
                )}
                <br />
                <Button primary content="Update User" onClick={tryUpdate} />
            </Modal.Content>
        </Modal>
    );
}
