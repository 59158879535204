import React from "react";
import { useTranslation } from "react-i18next";
import { useGetFavoritesQuery } from "@api/apiV6";

import _ from "lodash";
import { Div } from "@components/Generics.react";

import MasterLayout from "@layouts/MasterLayout.react";
import RecommendedArticles from "@components/RecommendedArticles.react";
import ArticleCard from "@components/ArticleCard.react";

export default function Favorites(props) {
    const { data: favorites, isSuccess: favSuccess } = useGetFavoritesQuery();
    const { t } = useTranslation("common");
    const isPortrait = window.innerHeight > window.innerWidth;

    // ========================= Render Function =================================
    return (
        <MasterLayout
            title={t("article.favorites")}
            mainPanel={
                <Div padded fluid>
                    <Div large padTop gutter uppercase>
                        {t("article.favorites")}
                    </Div>
                    {favSuccess &&
                        _.map(
                            _.filter(favorites.favorites, { published: true }),
                            (article, articleIndex) => {
                                return (
                                    <Div
                                        float-left={!isPortrait}
                                        wd={
                                            window.innerWidth > 1280
                                                ? "33%"
                                                : isPortrait
                                                ? "100%"
                                                : "50%"
                                        }
                                        gapBottom
                                        key={`article-${articleIndex}`}
                                    >
                                        <ArticleCard
                                            article={article}
                                            feedStyle
                                        />
                                    </Div>
                                );
                            }
                        )}
                </Div>
            }
            rightPanelName={t(`components.recommended`)}
            rightPanel={<RecommendedArticles />}
        />
    );
}
