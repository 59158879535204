// Import from NPM
// -------------------------------------
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Papa from "papaparse";

import _ from "lodash";
import { Accordion, Modal, Message } from "semantic-ui-react";
import { Div, Button, Icon } from "@components/Generics.react";
import "@styles/editor.scss";

export default function SubformBlock(props) {
    const { value, setValue, field, hasStructure } = props;
    const [contentArray, setContentArray] = useState(value);
    const [modalOpen, setModalOpen] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [hasFile, setHasFile] = useState(null);

    const FieldComponent = field.formField[0];
    useEffect(() => {
        if (contentArray !== value)
            setValue(
                field.target ? { [field.target]: contentArray } : contentArray
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentArray]);
    const { t } = useTranslation("common");

    function updateContentArray(data, index) {
        if (field.radioField) {
            let newContentArray = [...contentArray];
            if (data.correct === "checked") {
                newContentArray = newContentArray.map((item, index) => ({
                    ...item,
                    correct: "",
                }));
            }
            newContentArray[index] = data;
            setContentArray(newContentArray);
        } else {
            let newContentArray = [...contentArray];
            newContentArray[index] = data;
            setContentArray(newContentArray);
        }
    }

    function updateSubform(data, index, fieldName) {
        let newContentArray = [...contentArray];
        newContentArray[index] = {
            ...newContentArray[index],
            ...{ [fieldName]: data[fieldName] },
        };
        setContentArray(newContentArray);
    }

    function addItem() {
        let newContentArray = contentArray ? [...contentArray] : [];
        newContentArray.push(hasStructure ? {} : "");
        setContentArray(newContentArray);
    }
    function deleteItem(index) {
        let newContentArray = [...contentArray];
        newContentArray.splice(index, 1);
        setContentArray(newContentArray);
    }

    const handleFileUpload = (event) => {
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                const desiredRows = _.flatten(
                    _.map(field.formField, (fld) =>
                        fld.target === "button"
                            ? ["buttonText", "buttonLink"]
                            : fld.target
                    )
                );
                const uploadedRows = _.keys(results.data[0]);
                if (_.isEqual(uploadedRows, desiredRows)) {
                    let mappedData = results.data;
                    if (_.includes(_.keys(results.data[0]), "buttonText")) {
                        const transformedArray = _.map(mappedData, (item) => {
                            const rest = _.omit(item, [
                                "buttonText",
                                "buttonLink",
                            ]);
                            return {
                                ...rest,
                                button: {
                                    text: item.buttonText,
                                    btnLink: item.buttonLink,
                                },
                            };
                        });
                        setHasFile(transformedArray);
                    } else setHasFile(mappedData);
                } else {
                    setHasError(true);
                }
            },
        });
    };

    const flatComponent =
        !hasStructure &&
        _.map(
            field.count && contentArray
                ? contentArray.slice(0, field.count)
                : contentArray,
            (subfieldContent, idy) => {
                return (
                    <Div
                        key={`form-subfield-${idy}`}
                        wd={field.width}
                        float-left={field.width != null}
                        clear={field.width == null}
                        relative
                        noOverflow={!field.showOverflow}
                    >
                        {!field.count && (
                            <Div
                                className="sub-block-delete"
                                absolute
                                rimmed
                                danger
                                layer={2}
                                dropShadow
                                clickable
                                onClick={() => deleteItem(idy)}
                            >
                                <Icon name="trash" />
                            </Div>
                        )}
                        <FieldComponent
                            {...field}
                            {...{ help: null, header: field.subheader }}
                            value={subfieldContent}
                            setValue={(data) => updateContentArray(data, idy)}
                        />
                    </Div>
                );
            }
        );

    const structuredComponent =
        hasStructure &&
        _.map(contentArray, (subfieldContent, idy) => ({
            key: `accordion-panel-${idy}`,
            title:
                subfieldContent[field.subheader] ||
                t("builder.deck.newSubform"),
            content: {
                content: (
                    <Div
                        key={`form-subfield-${idy}`}
                        wd={field.width}
                        float-left={field.width != null}
                        clear={field.width == null}
                        relative
                        noOverflow
                    >
                        <Div
                            className="sub-block-delete"
                            absolute
                            rimmed
                            danger
                            layer={2}
                            dropShadow
                            clickable
                            onClick={() => deleteItem(idy)}
                        >
                            <Icon name="trash" />
                        </Div>
                        <Div clearfix vapor bordered>
                            {_.map(field.formField, (sectionField, idz) => {
                                if (_.isArray(sectionField.formField)) {
                                    return (
                                        <Div
                                            key={`form-sectionField-${idz}`}
                                            wd={sectionField.width}
                                            float-left={
                                                sectionField.width != null
                                            }
                                            clear={sectionField.width == null}
                                        >
                                            <SubformBlock
                                                field={sectionField}
                                                hasStructure={
                                                    typeof sectionField
                                                        .formField[0] ===
                                                    "object"
                                                }
                                                value={
                                                    sectionField.target
                                                        ? subfieldContent[
                                                              sectionField
                                                                  .target
                                                          ]
                                                        : subfieldContent
                                                }
                                                setValue={(data) =>
                                                    updateSubform(
                                                        data,
                                                        idy,
                                                        sectionField.target
                                                    )
                                                }
                                            />
                                        </Div>
                                    );
                                } else {
                                    const FieldComponent =
                                        sectionField.formField;
                                    return (
                                        <Div
                                            key={`form-sectionField-${idz}`}
                                            wd={sectionField.width}
                                            float-left={
                                                sectionField.width != null
                                            }
                                            clear={sectionField.width == null}
                                        >
                                            <FieldComponent
                                                {...sectionField}
                                                value={subfieldContent}
                                                setValue={(data) =>
                                                    updateSubform(
                                                        data,
                                                        idy,
                                                        sectionField.target
                                                    )
                                                }
                                            />
                                        </Div>
                                    );
                                }
                            })}
                        </Div>
                    </Div>
                ),
            },
        }));

    const csvMap = _.flatten(
        _.map(field.formField, (fld) =>
            fld.target === "button" ? ["buttonText", "buttonLink"] : fld.target
        )
    );
    // ========================= Render Function =================================
    return (
        <Div snug medpad ivory>
            <Div big rimmed>
                {hasStructure &&
                    _.find(field.formField, { target: "buttons" }) == null && (
                        <Div float-right>
                            <Div
                                bold
                                clickable
                                small
                                onClick={() => setModalOpen(true)}
                                style={{ color: "#2288aa" }}
                            >
                                <Icon name="file" inline />
                                <u>{t("builder.deck.uploadCSV")}</u>
                            </Div>
                        </Div>
                    )}
                {field.header}
                {field.help && (
                    <Div tiny italics txtHalf gapSlice>
                        {field.help}
                    </Div>
                )}
            </Div>
            <Div small className="sub-block">
                {contentArray && contentArray.length > 0 ? (
                    <>
                        {hasStructure ? (
                            <Accordion
                                styled
                                fluid
                                panels={structuredComponent}
                            />
                        ) : (
                            flatComponent
                        )}{" "}
                    </>
                ) : (
                    <Div fluid relaxed ash>
                        {t("builder.deck.noContent")}
                    </Div>
                )}
                {(!field.count ||
                    !contentArray ||
                    (field.count && contentArray.length < field.count)) && (
                    <Button
                        fluid
                        extra={+true}
                        icon="plus"
                        labelPosition="right"
                        content={t("builder.deck.addItem")}
                        onClick={addItem}
                    />
                )}
            </Div>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                size="small"
            >
                <Modal.Header>
                    {t(`builder.deck.uploadCSVHeader`)}
                    <Div float-right clickable>
                        <Icon
                            name="times"
                            onClick={() => setModalOpen(false)}
                        />
                    </Div>
                </Modal.Header>
                <Div ash basepad>
                    <Div ivory basepad gutter>
                        {t("builder.deck.uploadCSVHelp1")}
                        <br />
                        {t("builder.deck.uploadCSVHelp2")}
                        <br />
                        <br />
                        <Div medpad ash>
                            {csvMap.join(",")}
                            <br />
                            {csvMap.join("1,")}1
                            <br />
                            {csvMap.join("2,")}2
                        </Div>
                        <br />
                        {t("builder.deck.uploadCSVHelp3")}
                    </Div>
                    <Div medpad white>
                        {hasFile && (
                            <Message
                                negative={hasError}
                                positive={!hasError}
                                content={
                                    hasError
                                        ? t("builder.deck.uploadCSVHelp4")
                                        : t("builder.deck.uploadCSVHelp5")
                                }
                            />
                        )}
                        <Div medpad smoke gutter>
                            <input
                                type="file"
                                accept="text/csv"
                                onChange={handleFileUpload}
                            />
                        </Div>
                        <Button
                            primary
                            disabled={!hasFile || hasError}
                            content={t("components.submit")}
                            icon="upload"
                            labelPosition="right"
                            onClick={() => {
                                setContentArray(hasFile);
                                setModalOpen(false);
                            }}
                        />
                    </Div>
                </Div>
            </Modal>
        </Div>
    );
}
