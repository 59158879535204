import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import axios from "axios";
import { Div, Button as XButton } from "@components/Generics.react";
import { baseUrl, useGetReportListQuery } from "@api/apiV6";
import { Loader, Icon, Input, Button, Confirm, Modal } from "semantic-ui-react";
import moment from "moment";
import { toast } from "react-toastify";
export default function ReportList(props) {
    const { section, filters, name, url } = props;
    const auth = useSelector((state) => state.auth);
    const { data, isSuccess, refetch } = useGetReportListQuery(section);
    const [searchText, setSearchText] = useState("");
    const [reportsList, setReportsList] = useState("");
    const [dateFilter, setDateFilter] = useState("All");
    const [confirm, setConfirm] = useState(false);
    const [genConfirm, setGenConfirm] = useState(false);

    function refresh() {
        refetch();
        toast("Report List Refreshed");
    }

    useEffect(() => {
        const sortedReports = _.orderBy(data, [(r) => r], ["desc"]);
        // Date structure - DDMMYY-hhmm-a
        setReportsList(
            _.map(sortedReports, (r) => ({
                name: r,
                date: moment(
                    r.split("_")[1].split(".")[0].split("-").join(" "),
                    "DDMMYY hhmm a"
                ).format("DD MMM YYYY hh:mm a"),
            }))
        );
    }, [data]);

    useEffect(() => {
        setDateFilter("All");
    }, [section]);

    const selectedReports = useMemo(() => {
        return _.orderBy(
            _.filter(
                reportsList,
                (r) =>
                    (r.date.includes(searchText) ||
                        r.name.includes(searchText)) &&
                    (dateFilter === "All" ||
                        r.name.toLowerCase().includes(dateFilter.toLowerCase()))
            ),
            [(r) => moment(r.date)],
            ["desc"]
        );
    }, [reportsList, searchText, dateFilter]);

    return (
        <Div fluid smoke basepad>
            <Div bold uppercase gutter flex spaceBetween>
                <Div wd="calc(100% - 128px)" flex gapped flexStart>
                    <Input
                        placeholder={"Search for a report date"}
                        icon={{ name: "search" }}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                    <Button.Group>
                        {_.map(filters, (f, idx) => (
                            <Button
                                key={`report-filter-btn-${idx}`}
                                primary={dateFilter !== f}
                                secondary={dateFilter === f}
                                content={f}
                                onClick={() => setDateFilter(f)}
                            />
                        ))}
                    </Button.Group>
                </Div>
                <Div clickable wd="128px" right-aligned>
                    <Icon
                        name="shipping fast"
                        circular
                        inverted
                        color="red"
                        onClick={() => setConfirm(true)}
                        title="Generate Now"
                    />
                    <Icon
                        name="refresh"
                        circular
                        inverted
                        onClick={refresh}
                        title="Refresh List"
                    />
                </Div>
            </Div>
            <Div ht={`${window.innerHeight - 370}px`} autoOverflowY>
                {isSuccess ? (
                    selectedReports.length > 0 ? (
                        _.map(selectedReports, (r, idx) => {
                            return (
                                <Div
                                    padded
                                    white
                                    snug
                                    flex
                                    spaceBetween
                                    key={`reportName-${idx}`}
                                >
                                    <Div>
                                        {moment(r.date).format(
                                            "DD MMM YYYY hh:mm a"
                                        )}
                                    </Div>
                                    <Div>{r.name}</Div>
                                    {r.name.includes("processing") ? (
                                        <Div>Processing...</Div>
                                    ) : (
                                        <Div>
                                            <a
                                                href={`${baseUrl}public/reports/${section}/${r.name}`}
                                            >
                                                <XButton
                                                    primary
                                                    small={+true}
                                                    content="Download"
                                                    icon={"download"}
                                                    labelPosition={"right"}
                                                />
                                            </a>
                                        </Div>
                                    )}
                                </Div>
                            );
                        })
                    ) : (
                        <Div fullht fluid flex>
                            No Reports Available
                        </Div>
                    )
                ) : (
                    <Loader />
                )}
                <Confirm
                    header={`Generate a ${
                        dateFilter === "All" ? "Lifetime" : dateFilter
                    } ${name} report immediately`}
                    content="P.S. Generating large organization reports during working hours can significantly affect performance across all users. Please ensure that you are using this only in an emergency."
                    open={confirm}
                    onCancel={() => setConfirm(false)}
                    cancelButton="Never mind"
                    confirmButton="Let's do it"
                    onConfirm={async () => {
                        const reponse = await axios({
                            method: "post",
                            url: `${baseUrl}${
                                url.includes(":timePeriod")
                                    ? url.replace(
                                          ":timePeriod",
                                          dateFilter === "All"
                                              ? "lifetime"
                                              : dateFilter.toLowerCase()
                                      )
                                    : url
                            }`,
                            headers: { "access-token": auth.token },
                        });
                        if (reponse) {
                            setGenConfirm(true);
                            setConfirm(false);
                            setTimeout(() => refresh(), 1000);
                        }
                    }}
                />
                <Modal
                    onClose={() => setGenConfirm(false)}
                    open={genConfirm}
                    size="small"
                >
                    <Modal.Header>
                        Generating{" "}
                        {dateFilter === "All" ? "Lifetime" : dateFilter} {name}{" "}
                        Report
                    </Modal.Header>
                    <Modal.Content>
                        The report is being generated, but will take some time.
                        Please come back in 10-15 minutes and refresh the list
                        to see the report.
                        <br />
                        <br />
                        P.S. The last row should contain an EOF marker in the
                        S.No. column. If you don't see it, the file is still
                        being processed.
                        <br />
                        <br />
                        <Button primary onClick={() => setGenConfirm(false)}>
                            OK
                        </Button>
                    </Modal.Content>
                </Modal>
            </Div>
        </Div>
    );
}
