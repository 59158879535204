import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFeatures } from "flagged";
import _ from "lodash";

import { Input, Image, Tab, Menu, Label } from "semantic-ui-react";
import { Div, Button } from "@components/Generics.react";
import { WrapperList } from "@schemas/wrapperSchema";

export default function ThemeSelector(props) {
    const { course, changeWrapper } = props;
    const [selectedWrapper, setSelectedWrapper] = useState(course.wrapper);
    const [search, setSearch] = useState("");
    const features = useFeatures();
    const { t } = useTranslation("common");

    function showWrappers(wType, locked) {
        const allWrappers = _.pickBy(WrapperList, { wrapperType: wType });
        const wrapperList = _.filter(_.keys(allWrappers), (template) =>
            template.includes(search)
        );
        return (
            <Div clear ht="260px" autoOverflowY>
                <Div flex flexStart gapped gutter>
                    {_.map(wrapperList, (wrapper, idx) => {
                        const thisWrapper =
                            wrapper === "none" ? "simple_list" : wrapper;
                        return (
                            <Div
                                wd="calc(33.33% - 7.5px)"
                                key={`wrapper-${idx}`}
                                medpad
                                rounded
                                clickable
                                charcoal={thisWrapper === course.wrapper}
                                vapor={
                                    thisWrapper !== selectedWrapper &&
                                    thisWrapper !== course.wrapper
                                }
                                primary={thisWrapper === selectedWrapper}
                                onClick={() => {
                                    setSelectedWrapper(thisWrapper);
                                }}
                            >
                                <Image
                                    src={`/assets/images/wrappers/${thisWrapper}/play_backdrop.png`}
                                />
                                <Div uppercase center-align gapTop>
                                    {thisWrapper.replace(/_/g, " ")}
                                </Div>
                            </Div>
                        );
                    })}
                </Div>
            </Div>
        );
    }
    const panes = [
        {
            menuItem: t("builder.course.themeFormal"),
            render: () => (
                <Tab.Pane>
                    {showWrappers("formal", true)}
                    <Button
                        primary
                        fluid
                        content={t("builder.course.applyTheme")}
                        onClick={() => changeWrapper(selectedWrapper)}
                        disabled={selectedWrapper === course.wrapper}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: (
                <Menu.Item key="themeFlat">
                    {t("builder.course.themeFlat")}
                    {!features.courses.standardThemes && (
                        <Label content="Premium" color="red" size="small" />
                    )}
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane>
                    {showWrappers("standard", features.courses.standardThemes)}
                    <Button
                        primary
                        fluid
                        content={t("builder.course.applyTheme")}
                        onClick={() =>
                            features.courses.standardThemes
                                ? changeWrapper(selectedWrapper)
                                : null
                        }
                        disabled={
                            selectedWrapper === course.wrapper ||
                            !features.courses.standardThemes
                        }
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: (
                <Menu.Item key="themeAnim">
                    {t("builder.course.themeAnim")}
                    {!features.courses.animatedThemes && (
                        <Label content="Premium" color="red" size="small" />
                    )}
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane>
                    {showWrappers("animated", features.courses.animatedThemes)}
                    <Button
                        primary
                        fluid
                        content={t("builder.course.applyTheme")}
                        onClick={() =>
                            features.courses.animatedThemes
                                ? changeWrapper(selectedWrapper)
                                : null
                        }
                        disabled={
                            selectedWrapper === course.wrapper ||
                            !features.courses.animatedThemes
                        }
                    />
                </Tab.Pane>
            ),
        },
    ];
    // ========================= Render Function =================================
    return (
        <Div>
            <Div bold flex spaceBetween>
                <Div big>{t("builder.course.selectTheme")}:</Div>
                <Input
                    icon="search"
                    placeholder={t("components.search")}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </Div>
            <Div gapTop fullht fluid relative>
                <Tab
                    panes={panes}
                    menu={{ fluid: true, vertical: true, pointing: true }}
                    onTabChange={() => setSelectedWrapper(course.wrapper)}
                />
                <Div absolute style={{ bottom: "20px" }}>
                    <Div snug>{t("builder.course.currentTheme")}:</Div>
                    <Div half padded uppercase snug snubbed>
                        {course.wrapper.replace(/_/g, " ")}
                    </Div>
                </Div>
            </Div>
        </Div>
    );
}
