// Import from NPM
// -------------------------------------
import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Div } from "@components/Generics.react";
import { Dropdown, Divider } from "semantic-ui-react";
import { settings } from "@config/settings/app.settings";
import SettingsToggle from "../formComponents/SettingsToggle.react";
import SettingsInput from "../formComponents/SettingsInput.react";

export default function AssessmentSettings(props) {
    const { deck, setDeck } = props;
    const { t } = useTranslation("common");
    // ========================= Render Function =================================
    return (
        <Div>
            {settings.languages && settings.languages.length > 1 && (
                <Div compact>
                    <Div gapBottom bold>
                        {t("builder.dic.selectLanguage")}:
                    </Div>
                    <Dropdown
                        placeholder={t("builder.dic.selectLanguage")}
                        fluid
                        selection
                        options={_.map(settings.languages, (l) => ({
                            key: l.code,
                            text: l.identifier,
                            value: l.code,
                        }))}
                        defaultValue={"en"}
                        value={deck.lang}
                        onChange={(e, { value }) =>
                            setDeck({
                                ...deck,
                                ...{ lang: value },
                            })
                        }
                    />
                </Div>
            )}
            <Divider />
            <Div bold compact gapBottom>
                {t("components.points")}
            </Div>
            <SettingsInput
                {...props}
                label={t("builder.deck.maxPoints")}
                targetKey="points"
                isType="number"
            />
            <Div compact>
                <Div italics gapBottom>
                    {t("builder.deck.pointsHelpAss1")}
                </Div>
                <Div compact vapor fit-content>
                    {t("builder.deck.pointsHelpAss2")}
                </Div>
            </Div>
            <Divider />
            <Div bold compact gapBottom>
                {t("builder.deck.qTiming")}
            </Div>
            <SettingsInput
                {...props}
                label={t("builder.deck.qTimingHelp")}
                targetKey="accessTime"
                isType="number"
            />
            <Divider />
            <Div gapBottom bold compact>
                {t("builder.dic.properties")}
            </Div>
            <SettingsInput
                {...props}
                label={t("builder.deck.passPercent")}
                targetKey="passingPercentage"
                isType="number"
            />
            <SettingsInput
                {...props}
                label={t("builder.deck.qCount")}
                targetKey="qcount"
                isType="number"
                maxValue={deck.content.length}
            />
            <Divider />
            <Div gapBottom bold compact>
                {t("builder.dic.funcToggle")}
            </Div>
            <SettingsToggle
                {...props}
                label={t("builder.deck.qShuffle")}
                targetKey={"enableShuffle"}
            />
            <SettingsToggle
                {...props}
                label={t("builder.deck.enableFeedback")}
                targetKey={"enableFeedback"}
            />
            <SettingsToggle
                {...props}
                label={t("builder.deck.qFinal")}
                targetKey={"isFinal"}
            />
            {deck.isFinal && (
                <SettingsInput
                    {...props}
                    label={t("builder.deck.attemptsAllowed")}
                    targetKey="attemptsAllowed"
                    isType="number"
                />
            )}
        </Div>
    );
}
