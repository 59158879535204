// Import from NPM
// -------------------------------------
import React from "react";
import { Div } from "@components/Generics.react";
import { Input } from "semantic-ui-react";

export default function SettingsInput(props) {
    const { deck, setDeck, targetKey, label, isType, maxValue } = props;
    // ========================= Render Function =================================
    return (
        <Div compact fluid>
            <Input
                fluid
                label={label}
                type={isType}
                value={
                    deck[targetKey] == null && isType === "number"
                        ? 0
                        : deck[targetKey]
                }
                onChange={(e) =>
                    setDeck({
                        ...deck,
                        ...{
                            [targetKey]:
                                isType === "number"
                                    ? maxValue == null
                                        ? Number(e.target.value)
                                        : Math.min(
                                              Number(e.target.value),
                                              maxValue
                                          )
                                    : e.target.value,
                        },
                    })
                }
            />
        </Div>
    );
}
