// Import from NPM
// -------------------------------------
import React, { useRef, useState } from "react";
import {
    useCreateNewContentMutation,
    useGetTagsQuery,
    useUpdateDeckMutation,
} from "@api/apiV6";

import _ from "lodash";
import { Modal, Dropdown } from "semantic-ui-react";

import { Div, Button } from "@components/Generics.react";
import "@styles/editor.scss";
import * as XLSX from "xlsx";
import Papa from "papaparse";

export default function BulkContentUploader(props) {
    const { isOpen, setOpen } = props;

    const { data: allTags } = useGetTagsQuery();
    const [createEntity] = useCreateNewContentMutation();
    const [updateDeck] = useUpdateDeckMutation();

    const addInputRef = useRef(null);

    const [csv, setCsv] = useState(null);
    const [artTags, setArtTags] = useState([]);

    const addQuestions = (e) => {
        Papa.parse(e.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                setCsv(results.data);
            },
        });
    };

    async function createArticles() {
        const recordsLength = csv.length;
        for (let i = 0; i < recordsLength; i++) {
            const rec = csv[i];

            const questionAnswerArray = [];
            let qNo = 0;
            Object.keys(rec).forEach((key) => {
                if (key.startsWith("wsQuestion")) {
                    const questionNumber = key.replace("wsQuestion", "");
                    const question = rec[key];
                    const answer = rec[`wsAnswer${questionNumber}`];

                    if (question && answer) {
                        qNo++;
                        questionAnswerArray.push({
                            template: "fill_in_the_blanks",
                            slideSeq: qNo,
                            nav: {
                                next: true,
                                prev: false,
                            },
                            data: {
                                title: "Fill in the blanks",
                                question: question,
                                answer: answer,
                                purpose: "Test Understanding",
                                feedback: [
                                    `That's correct. The answer is ${answer}`,
                                    `Oops! The correct answer is ${answer}`,
                                ],
                            },
                        });
                    }
                }
            });

            const artResponse = await createEntity({
                contentType: "articles",
                name: rec.wsTitle,
                description: rec.wsSubtitle,
                category: "assessment",
                readerType: "assessment",
                published: true,
                tags: artTags,
                design: {
                    cleanView: true,
                    noInteractions: true,
                },
                shareable: false,
            }).unwrap();
            const deckId = artResponse.decks[0];

            const deckResponse = await updateDeck({
                id: deckId,
                passingPercentage: 40,
                content: questionAnswerArray,
            });
            console.log(deckResponse);
        }
        setOpen(false);
    }

    // ========================= Render Function =================================
    return (
        <Modal size="small" open={isOpen} onClose={() => setOpen(false)}>
            <Modal.Header>Bulk Upload Quizzes </Modal.Header>
            <Modal.Content>
                <Div bold spaceBottom>
                    Upload File
                </Div>
                <input
                    ref={addInputRef}
                    type="file"
                    accept=".xlsx, .csv"
                    onChange={addQuestions}
                />
                <Div bold spaceBottom padTop>
                    Add Tags
                </Div>
                {allTags && (
                    <Dropdown
                        placeholder={"Select Tags"}
                        fluid
                        selection
                        multiple
                        search
                        options={_.map(allTags, (t) => ({
                            key: t._id,
                            text: t.name,
                            value: t._id,
                        }))}
                        value={_.map(artTags, (a) => (a._id ? a._id : a))}
                        onChange={(e, { value }) => setArtTags(value)}
                    />
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button content="Close" onClick={() => setOpen(false)} />
                <Button
                    primary
                    disabled={csv == null || artTags.length === 0}
                    content="Add"
                    onClick={createArticles}
                />
            </Modal.Actions>
        </Modal>
    );
}
