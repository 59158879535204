// Import from NPM
// -------------------------------------
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Div } from "@components/Generics.react";
import { Input, Radio } from "semantic-ui-react";
export default function RadioField(props) {
    const { noRadio = false, value, setValue, height, maxLength } = props;
    const [isMounted, setIsMounted] = useState(false);
    const [inputValue, setInputValue] = useState(value.text);
    const [checkValue, setCheckValue] = useState(value.correct);
    const { t } = useTranslation("common");

    // Debouncing the user input
    //==================================
    useEffect(() => {
        setIsMounted(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setInputValue(value.text);
        setCheckValue(value.correct);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (isMounted) setValue({ text: inputValue, correct: checkValue });
        }, 500);
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue, checkValue]);

    // ========================= Render Function =================================
    return (
        <Div minHt={height} ht={height} snug>
            <Div
                smoke
                ht={"calc(100% - 60px)"}
                fluid
                flex
                spaceBetween
                padSides={!noRadio}
            >
                {!noRadio && (
                    <Radio
                        label={
                            checkValue === "checked"
                                ? t("builder.deck.correct")
                                : t("builder.deck.incorrect")
                        }
                        name="radioGroup"
                        checked={checkValue === "checked"}
                        onChange={(e) =>
                            setCheckValue(
                                checkValue === "correct" ? "" : "checked"
                            )
                        }
                    />
                )}
                <Div wd={`calc(100% - ${noRadio ? 0 : 100}px)`} relative>
                    {inputValue && maxLength && (
                        <Div
                            small
                            absolute
                            compact
                            ash={inputValue.length < maxLength}
                            danger={inputValue.length > maxLength}
                            style={{ top: "-5px", right: "25px", zIndex: 2 }}
                            slightShadow
                        >
                            {inputValue.length}/{maxLength}
                        </Div>
                    )}
                    <Input
                        fluid
                        value={inputValue || ""}
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                </Div>
            </Div>
        </Div>
    );
}
