import React, { useState } from "react";
import _ from "lodash";
import {
    useCreateNewContentMutation,
    useUpdateCourseMutation,
    useGetAllCreatorsQuery,
} from "@api/apiV6";
import { toast } from "react-toastify";

import { Div, Button } from "@components/Generics.react";
import { Modal, Icon, Input, Dropdown } from "semantic-ui-react";

export default function UpsertCourseModal(props) {
    const { isOpen, setOpen, course } = props;
    const { data: audienceData, isSuccess } = useGetAllCreatorsQuery();

    const [courseName, setCourseName] = useState(course?.name);
    const [courseOwner, setCourseOwner] = useState(course?.owner?._id);

    const [createCourse] = useCreateNewContentMutation();
    const [updateCourse] = useUpdateCourseMutation();

    async function tryUpsert() {
        if (course == null) {
            const response = await createCourse({
                name: courseName,
                owner: courseOwner,
                contentType: "courses",
            });
            if (response) {
                toast("Course Created");
                setOpen(false);
            }
        } else {
            const response = await updateCourse({
                id: course._id,
                name: courseName,
                owner: courseOwner,
            });
            if (response) {
                toast("Course Updated");
                setOpen(false);
            }
        }
    }

    // ========================= Render Function =================================
    return (
        <Modal size="small" open={isOpen} onClose={() => setOpen(false)}>
            <Modal.Header>
                {course == null ? "Add" : "Edit"} Course
                <Div float-right clickable>
                    <Icon
                        name="times"
                        onClick={() => {
                            setOpen(false);
                        }}
                    />
                </Div>
            </Modal.Header>
            <Modal.Content>
                <Div white basepad>
                    <Div fluid gutter>
                        <Input
                            fluid
                            label={"Course Name"}
                            placeholder={"Enter course name"}
                            value={courseName}
                            onChange={(e) => setCourseName(e.target.value)}
                        />
                        {isSuccess ? (
                            <Dropdown
                                placeholder={"Select Owner"}
                                fluid
                                selection
                                search
                                options={_.map(audienceData, (l) => ({
                                    key: l._id,
                                    text: l.username,
                                    value: l._id,
                                }))}
                                value={courseOwner}
                                onChange={(e, { value }) =>
                                    setCourseOwner(value)
                                }
                            />
                        ) : (
                            <Div relaxed>Fetching Owners...</Div>
                        )}
                    </Div>
                </Div>
                <Button
                    primary
                    content={`${course == null ? "Add" : "Edit"} Course`}
                    onClick={tryUpsert}
                />
            </Modal.Content>
        </Modal>
    );
}
