export const MapList = {
    none: {},
    xanadu: {
      sequenceInset: true,
      gifMap: [],
      positions: [
        { sequence: 0, top: 75, left: 76 },
        { sequence: 1, top: 69, left: 82 },
        { sequence: 2, top: 52, left: 82 },
        { sequence: 3, top: 56, left: 68 },
        { sequence: 4, top: 52, left: 57 },
        { sequence: 5, top: 55, left: 44 },
        { sequence: 6, top: 63, left: 35 },
        { sequence: 7, top: 58, left: 11 },
        { sequence: 8, top: 15, left: 14 },
        { sequence: 9, top: 15, left: 31 },
        { sequence: 10, top: 14, left: 63 },
        { sequence: 11, top: 15, left: 80 },
      ],
    },
    underdam: {
      sequenceInset: true,
      gifMap: [],
      positions: [
        { sequence: 0, top: 74, left: 47 },
        { sequence: 1, top: 62, left: 11 },
        { sequence: 2, top: 57, left: 31 },
        { sequence: 3, top: 53, left: 51 },
        { sequence: 4, top: 51, left: 81 },
        { sequence: 5, top: 39, left: 64 },
        { sequence: 6, top: 32, left: 42 },
        { sequence: 7, top: 20, left: 21 },
        { sequence: 8, top: 6, left: 34 },
        { sequence: 9, top: 11, left: 51 },
        { sequence: 10, top: 26, left: 78 },
        { sequence: 11, top: 6, left: 72 },
      ],
    },
    flyisland: {
      sequenceInset: true,
      gifMap: [],
      positions: [
        { sequence: 0, top: 81, left: 64 },
        { sequence: 1, top: 61, left: 36 },
        { sequence: 2, top: 78, left: 20 },
        { sequence: 3, top: 59, left: 7 },
        { sequence: 4, top: 46, left: 20 },
        { sequence: 5, top: 32, left: 30 },
        { sequence: 6, top: 42, left: 38 },
        { sequence: 7, top: 29, left: 47 },
        { sequence: 8, top: 47, left: 55 },
        { sequence: 9, top: 35.46580688952995, left: 67.38955823293172 },
        { sequence: 10, top: 58, left: 77 },
        { sequence: 11, top: 36, left: 94 },
      ],
    },
    redburgh: {
      sequenceInset: true,
      gifMap: [],
      positions: [
        { sequence: 0, top: 71, left: 17 },
        { sequence: 1, top: 71, left: 44 },
        { sequence: 2, top: 65, left: 74 },
        { sequence: 3, top: 51, left: 81 },
        { sequence: 4, top: 56, left: 66 },
        { sequence: 5, top: 55, left: 50 },
        { sequence: 6, top: 51, left: 32 },
        { sequence: 7, top: 41, left: 22 },
        { sequence: 8, top: 34, left: 74 },
        { sequence: 9, top: 33, left: 49 },
        { sequence: 10, top: 19, left: 46 },
        { sequence: 11, top: 6, left: 52 },
        { sequence: 12, top: 21.830043177404455, left: 87.14859437751004 },
      ],
    },
    whiogs: {
      sequenceInset: true,
      gifMap: [],
      positions: [
        { sequence: 0, top: 80, left: 37 },
        { sequence: 1, top: 66, left: 31 },
        { sequence: 2, top: 64, left: 53 },
        { sequence: 3, top: 62, left: 81 },
        { sequence: 4, top: 46, left: 85 },
        { sequence: 5, top: 53, left: 66 },
        { sequence: 6, top: 51, left: 33 },
        { sequence: 7, top: 33.052397382959064, left: 30.522088353413658 },
        { sequence: 8, top: 27, left: 51 },
        { sequence: 9, top: 34, left: 72 },
        { sequence: 10, top: 20, left: 66 },
        { sequence: 11, top: 10, left: 48 },
      ],
    },
    rearen: {
      sequenceInset: true,
      gifMap: [],
      positions: [
        { sequence: 0, top: 80, left: 55 },
        { sequence: 1, top: 54, left: 69 },
        { sequence: 2, top: 50.54961630559798, left: 48.83534136546185 },
        { sequence: 3, top: 68, left: 25 },
        { sequence: 4, top: 50, left: 39 },
        { sequence: 5, top: 19, left: 14 },
        { sequence: 6, top: 24, left: 32 },
        { sequence: 7, top: 29, left: 56 },
        { sequence: 8, top: 35.827818315515586, left: 80.08032128514057 },
        { sequence: 9, top: 9, left: 39 },
        { sequence: 10, top: 18, left: 78 },
        { sequence: 11, top: 6, left: 61 },
      ],
    },
    radialania: {
      sequenceInset: true,
      gifMap: [],
      positions: [
        { sequence: 0, top: 77, left: 86 },
        { sequence: 1, top: 75, left: 62 },
        { sequence: 2, top: 57, left: 42 },
        { sequence: 3, top: 46, left: 21 },
        { sequence: 4, top: 18, left: 20 },
        { sequence: 5, top: 10, left: 31 },
        { sequence: 6, top: 18, left: 46 },
        { sequence: 7, top: 35, left: 55 },
        { sequence: 8, top: 41, left: 70 },
        { sequence: 9, top: 42.344023983256974, left: 89.79919678714859 },
        { sequence: 10, top: 22, left: 88 },
        { sequence: 11, top: 8, left: 68 },
      ],
    },
    misnix: {
      sequenceInset: true,
      gifMap: [],
      positions: [
        { sequence: 0, top: 86.3887474781756, left: 62.570281124497996 },
        { sequence: 1, top: 65, left: 94 },
        { sequence: 2, top: 27, left: 89 },
        { sequence: 3, top: 58, left: 68 },
        { sequence: 4, top: 56, left: 51 },
        { sequence: 5, top: 43, left: 28 },
        { sequence: 6, top: 44.39542206384222, left: 9.63855421686747 },
        { sequence: 7, top: 14.469144182363255, left: 7.8714859437751 },
        { sequence: 8, top: 7.71159756396478, left: 22.48995983935743 },
        { sequence: 9, top: 14, left: 42 },
        { sequence: 10, top: 14, left: 62 },
        { sequence: 11, top: 6.866904236664969, left: 82.08835341365462 },
      ],
    },
    nightshade: {
      sequenceInset: true,
      gifMap: [],
      positions: [
        { sequence: 0, top: 71, left: 66 },
        { sequence: 1, top: 61, left: 46 },
        { sequence: 2, top: 63, left: 18 },
        { sequence: 3, top: 48, left: 8 },
        { sequence: 4, top: 39, left: 19 },
        { sequence: 5, top: 51, left: 37 },
        { sequence: 6, top: 27, left: 37 },
        { sequence: 7, top: 23, left: 56 },
        { sequence: 8, top: 50, left: 65 },
        { sequence: 9, top: 42, left: 84 },
        { sequence: 10, top: 19, left: 82 },
        { sequence: 11, top: 8, left: 57 },
      ],
    },
    misken: {
      sequenceInset: true,
      gifMap: [],
      positions: [
        { sequence: 0, top: 77, left: 52 },
        { sequence: 1, top: 80.35522371174841, left: 28.353413654618475 },
        { sequence: 2, top: 73, left: 13 },
        { sequence: 3, top: 42, left: 9 },
        { sequence: 4, top: 49, left: 34 },
        { sequence: 5, top: 49, left: 61 },
        { sequence: 6, top: 45, left: 80 },
        { sequence: 7, top: 25, left: 81 },
        { sequence: 8, top: 16, left: 62 },
        { sequence: 9, top: 17, left: 34 },
        { sequence: 10, top: 11.331711823821106, left: 12.208835341365463 },
        { sequence: 11, top: 6.504892810679337, left: 88.59437751004016 },
      ],
    },
    icetown: {
      sequenceInset: true,
      gifMap: [],
      positions: [
        { sequence: 0, top: 69, left: 47 },
        { sequence: 1, top: 61, left: 33 },
        { sequence: 2, top: 32, left: 18 },
        { sequence: 3, top: 38, left: 43 },
        { sequence: 4, top: 53, left: 72 },
        { sequence: 5, top: 43, left: 66 },
        { sequence: 6, top: 32, left: 90 },
        { sequence: 7, top: 17, left: 72 },
        { sequence: 8, top: 19, left: 54 },
        { sequence: 9, top: 19, left: 38 },
        { sequence: 10, top: 9, left: 23 },
        { sequence: 11, top: 6, left: 60 },
      ],
    },
    magrath: {
      sequenceInset: true,
      gifMap: [],
      positions: [
        { sequence: 0, top: 84, left: 26 },
        { sequence: 1, top: 74, left: 64 },
        { sequence: 2, top: 62, left: 42 },
        { sequence: 3, top: 52, left: 18 },
        { sequence: 4, top: 41, left: 64 },
        { sequence: 5, top: 34, left: 80 },
        { sequence: 6, top: 27, left: 50 },
        { sequence: 7, top: 22, left: 39 },
        { sequence: 8, top: 15.313837509663063, left: 16.626506024096386 },
        { sequence: 9, top: 6.504892810679337, left: 31.16465863453815 },
        { sequence: 10, top: 5.660199483379527, left: 56.62650602409639 },
        { sequence: 11, top: 9, left: 77 },
      ],
    },
    metropole: {
      sequenceInset: true,
      gifMap: [],
      positions: [
        { sequence: 0, top: 79, left: 34 },
        { sequence: 1, top: 56, left: 25 },
        { sequence: 2, top: 34, left: 13 },
        { sequence: 3, top: 13, left: 23 },
        { sequence: 4, top: 32, left: 37 },
        { sequence: 5, top: 42, left: 57 },
        { sequence: 6, top: 63, left: 48 },
        { sequence: 7, top: 83, left: 84 },
        { sequence: 8, top: 32, left: 84 },
        { sequence: 9, top: 15, left: 70 },
        { sequence: 10, top: 14, left: 48 },
        { sequence: 11, top: 6.384222335350794, left: 37.99196787148594 },
      ],
    },
    greter: {
      sequenceInset: true,
      gifMap: [],
      positions: [
        { sequence: 0, top: 85, left: 14 },
        { sequence: 1, top: 33, left: 15 },
        { sequence: 2, top: 50, left: 37 },
        { sequence: 3, top: 72, left: 52 },
        { sequence: 4, top: 58, left: 87 },
        { sequence: 5, top: 52, left: 62 },
        { sequence: 6, top: 38, left: 88 },
        { sequence: 7, top: 27.260214567188946, left: 57.91164658634538 },
        { sequence: 8, top: 16, left: 31 },
        { sequence: 9, top: 6.02221090936516, left: 20 },
        { sequence: 10, top: 9, left: 52 },
        { sequence: 11, top: 8, left: 71 },
      ],
    },
    giaend: {
      sequenceInset: true,
      gifMap: [],
      positions: [
        { sequence: 0, top: 83, left: 42 },
        { sequence: 1, top: 66, left: 63 },
        { sequence: 2, top: 51, left: 37 },
        { sequence: 3, top: 59, left: 19 },
        { sequence: 4, top: 39, left: 6 },
        { sequence: 5, top: 34, left: 30 },
        { sequence: 6, top: 36, left: 50 },
        { sequence: 7, top: 36, left: 69 },
        { sequence: 8, top: 49.46358202764108, left: 83.77510040160642 },
        { sequence: 9, top: 12, left: 82 },
        { sequence: 10, top: 22, left: 58 },
        { sequence: 11, top: 7, left: 14 },
      ],
    },
    ethdam: {
      sequenceInset: true,
      gifMap: [],
      positions: [
        { sequence: 0, top: 69, left: 81 },
        { sequence: 1, top: 56, left: 70 },
        { sequence: 2, top: 61, left: 56 },
        { sequence: 3, top: 61, left: 37 },
        { sequence: 4, top: 55, left: 11 },
        { sequence: 5, top: 42, left: 24 },
        { sequence: 6, top: 41, left: 50 },
        { sequence: 7, top: 43, left: 81 },
        { sequence: 8, top: 30, left: 72 },
        { sequence: 9, top: 30, left: 29 },
        { sequence: 10, top: 25, left: 47 },
        { sequence: 11, top: 7, left: 54 },
      ],
    },
    easbai: {
      sequenceInset: true,
      gifMap: [],
      positions: [
        { sequence: 0, top: 64, left: 66 },
        { sequence: 1, top: 53, left: 81 },
        { sequence: 2, top: 37, left: 80 },
        { sequence: 3, top: 40, left: 55 },
        { sequence: 4, top: 53, left: 37 },
        { sequence: 5, top: 62, left: 20 },
        { sequence: 6, top: 44, left: 12 },
        { sequence: 7, top: 34, left: 24 },
        { sequence: 8, top: 27.98423741916021, left: 48.674698795180724 },
        { sequence: 9, top: 22, left: 68 },
        { sequence: 10, top: 14, left: 44 },
        { sequence: 11, top: 15, left: 24 },
      ],
    },
    dreves: {
      sequenceInset: true,
      gifMap: [],
      positions: [
        { sequence: 0, top: 66, left: 27 },
        { sequence: 1, top: 79, left: 41 },
        { sequence: 2, top: 81, left: 67 },
        { sequence: 3, top: 62, left: 53 },
        { sequence: 4, top: 41, left: 51 },
        { sequence: 5, top: 35, left: 22 },
        { sequence: 6, top: 10, left: 15 },
        { sequence: 7, top: 22, left: 34 },
        { sequence: 8, top: 36, left: 74 },
        { sequence: 9, top: 24, left: 83 },
        { sequence: 10, top: 15, left: 61 },
        { sequence: 11, top: 7, left: 79 },
      ],
    },
    darmouth: {
      sequenceInset: true,
      gifMap: [],
      positions: [
        { sequence: 0, top: 80, left: 68 },
        { sequence: 1, top: 64, left: 50 },
        { sequence: 2, top: 59, left: 32 },
        { sequence: 3, top: 30, left: 13 },
        { sequence: 4, top: 17, left: 23 },
        { sequence: 5, top: 15, left: 37 },
        { sequence: 6, top: 27, left: 46 },
        { sequence: 7, top: 30, left: 68 },
        { sequence: 8, top: 57, left: 77 },
        { sequence: 9, top: 36, left: 90 },
        { sequence: 10, top: 9, left: 84 },
        { sequence: 11, top: 6, left: 48 },
      ],
    },
    cyberia: {
      sequenceInset: true,
      gifMap: [],
      positions: [
        { sequence: 0, top: 78, left: 25 },
        { sequence: 1, top: 66, left: 41 },
        { sequence: 2, top: 76, left: 55 },
        { sequence: 3, top: 65, left: 76 },
        { sequence: 4, top: 55, left: 69 },
        { sequence: 5, top: 55, left: 49 },
        { sequence: 6, top: 45, left: 33 },
        { sequence: 7, top: 33, left: 24 },
        { sequence: 8, top: 24, left: 35 },
        { sequence: 9, top: 31, left: 50 },
        { sequence: 10, top: 37, left: 65 },
        { sequence: 11, top: 43, left: 80 },
      ],
    },
    colisle: {
      sequenceInset: true,
      gifMap: [],
      positions: [
        { sequence: 0, top: 54.049060090125764, left: 18.714859437751006 },
        { sequence: 1, top: 28.10490789448875, left: 18.634538152610443 },
        { sequence: 2, top: 51.39430963289778, left: 32.93172690763052 },
        { sequence: 3, top: 46.92950204574165, left: 45.38152610441767 },
        { sequence: 4, top: 22.071384128061542, left: 47.06827309236948 },
        { sequence: 5, top: 46.20547919377039, left: 59.518072289156635 },
        { sequence: 6, top: 14, left: 57 },
        { sequence: 7, top: 33.4144088089447, left: 71.56626506024097 },
        { sequence: 8, top: 55.617776269396835, left: 70.60240963855422 },
        { sequence: 9, top: 74, left: 86 },
        { sequence: 10, top: 45.964138243113304, left: 91.40562248995984 },
        { sequence: 11, top: 8.314949940607502, left: 73.65461847389558 },
      ],
    },
    copkesh: {
      sequenceInset: true,
      gifMap: [],
      positions: [
        { sequence: 0, top: 72, left: 52 },
        { sequence: 1, top: 61, left: 8 },
        { sequence: 2, top: 78, left: 30 },
        { sequence: 3, top: 64, left: 67 },
        { sequence: 4, top: 57, left: 47 },
        { sequence: 5, top: 54, left: 31 },
        { sequence: 6, top: 36, left: 49 },
        { sequence: 7, top: 41, left: 36 },
        { sequence: 8, top: 28, left: 19 },
        { sequence: 9, top: 20, left: 45 },
        { sequence: 10, top: 30, left: 67 },
        { sequence: 11, top: 47.291513471727285, left: 72.61044176706827 },
      ],
    },
    casamerde: {
      sequenceInset: true,
      gifMap: [],
      positions: [
        { sequence: 0, top: 70.58091521013633, left: 61.606425702811244 },
        { sequence: 1, top: 45, left: 88 },
        { sequence: 2, top: 30.03563549974546, left: 92.28915662650601 },
        { sequence: 3, top: 64.30605049305203, left: 15.180722891566264 },
        { sequence: 4, top: 51.75632105888342, left: 29.71887550200803 },
        { sequence: 5, top: 48.01553632369855, left: 76.06425702811245 },
        { sequence: 6, top: 45, left: 46 },
        { sequence: 7, top: 32, left: 56 },
        { sequence: 8, top: 18, left: 54 },
        { sequence: 9, top: 15, left: 79 },
        { sequence: 10, top: 3.246789976808643, left: 86.42570281124497 },
        { sequence: 11, top: 17.485906065576863, left: 29.63855421686747 },
      ],
    },
    broenna: {
      sequenceInset: true,
      gifMap: [],
      positions: [
        { sequence: 0, top: 79, left: 73 },
        { sequence: 1, top: 69, left: 53 },
        { sequence: 2, top: 59, left: 71 },
        { sequence: 3, top: 57.78984482531063, left: 50.52208835341365 },
        { sequence: 4, top: 72, left: 35 },
        { sequence: 5, top: 62.49599336312386, left: 26.104417670682732 },
        { sequence: 6, top: 39, left: 20 },
        { sequence: 7, top: 40, left: 28 },
        { sequence: 8, top: 45, left: 40 },
        { sequence: 9, top: 34, left: 51 },
        { sequence: 10, top: 37, left: 63 },
        { sequence: 11, top: 14, left: 54 },
      ],
    },
    anckok: {
      sequenceInset: true,
      gifMap: [],
      positions: [
        { sequence: 0, top: 72, left: 51 },
        { sequence: 1, top: 58, left: 65 },
        { sequence: 2, top: 46, left: 56 },
        { sequence: 3, top: 44, left: 43 },
        { sequence: 4, top: 34, left: 34 },
        { sequence: 5, top: 30, left: 52 },
        { sequence: 6, top: 34, left: 81 },
        { sequence: 7, top: 23, left: 65 },
        { sequence: 8, top: 19, left: 50 },
        { sequence: 9, top: 19, left: 36 },
        { sequence: 10, top: 23, left: 21 },
        { sequence: 11, top: 45, left: 21 },
      ],
    },
  };
  
  export const PickedLocations = {
    1: [0],
    2: [0, 11],
    3: [0, 5, 11],
    4: [0, 4, 8, 11],
    5: [0, 3, 6, 9, 11],
    6: [0, 2, 4, 6, 9, 11],
    7: [0, 2, 4, 6, 8, 10, 11],
    8: [0, 1, 2, 4, 6, 8, 10, 11],
    9: [0, 1, 2, 5, 6, 7, 9, 10, 11],
    10: [0, 1, 2, 4, 5, 6, 7, 9, 10, 11],
    11: [0, 1, 2, 3, 4, 6, 7, 8, 9, 10, 11],
    12: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  };
  