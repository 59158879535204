import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { Div } from "@components/Generics.react";
import { Card, Image, Label } from "semantic-ui-react";
import "@styles/components.scss";
import { text_truncate, snaked, formatDateAgo } from "@utilities/helpers";
import AbstractImageGenerator from "./AbstractImageGenerator.react";
import { deckFormats } from "@schemas/deckFormats";

export default function ArticleCard(props) {
    const { article, noImage, feedStyle } = props;
    const navigate = useNavigate();
    const { t } = useTranslation("common");
    const icon =
        _.find(deckFormats, { category: article?.category })?.icons[0] ||
        _.find(deckFormats, { readerType: article?.category })?.icons[0];

    const imgSection = !noImage && (
        <Div className="article-image-container">
            {article?.image ? (
                <Image src={article?.image} wrapped ui={false} />
            ) : (
                <AbstractImageGenerator
                    name={article?.name}
                    readerType={article?.category}
                />
            )}
        </Div>
    );

    const categorySection = (
        <Div uppercase altText snug small fit-content>
            {_.find(deckFormats, { category: article?.category })?.name ||
                _.find(deckFormats, { readerType: article?.category })?.name}
        </Div>
    );
    const headerSection = (
        <Div ht="2.5em" noOverflow snug bold>
            {!feedStyle && (
                <Div float-left middle massive ht={"1.5em"} snug>
                    <i className={`icon icm icon-${icon}`} />
                </Div>
            )}
            <Div
                wd={feedStyle ? "100%" : "calc(100% - 60px)"}
                float-left
                middle
                ht={!feedStyle && "2em"}
                lineHt={!feedStyle && "2em"}
                gutter={!feedStyle}
            >
                {text_truncate(article?.name, feedStyle ? 60 : 40)}
            </Div>
        </Div>
    );

    const tagsSection =
        article?.tags?.length > 0 ? (
            <Div ht="1.5em" noOverflow clear>
                {_.map(article?.tags, (tag, idx) => {
                    return (
                        <Label
                            key={`article-tag-${article?.id}-${idx}`}
                            horizontal
                            size="mini"
                        >
                            {!_.startsWith(
                                t(`tags.${snaked(tag.name)}`),
                                "tags"
                            )
                                ? t(`tags.${snaked(tag.name)}`)
                                : tag.name}
                        </Label>
                    );
                })}
            </Div>
        ) : (
            <Div />
        );
    const metaSection = `${article?.uniques || 0} View${
        article?.uniques === 1 ? "" : "s"
    } | ${formatDateAgo(article?.updatedAt)}`;
    // ========================= Render Function =================================
    if (feedStyle)
        return (
            <Div
                wd={"calc(100% - 15px)"}
                spaced
                white
                clearfix
                snubbed
                slightShadow
                clickable
                left-align
                className="article-card"
                title={article?.name}
                onClick={() =>
                    navigate(
                        `/content/${article?.category}/${article?.identifier}`
                    )
                }
            >
                <Div snubbed noOverflow clearfix>
                    <Div wd="33%" float-left noOverflow>
                        <Div wd="300%" relative style={{ marginLeft: "-20%" }}>
                            {imgSection}
                        </Div>
                    </Div>
                    <Div
                        wd="67%"
                        white
                        float-left
                        padded
                        noOverflow
                        relative
                        style={{
                            height: 0,
                            paddingBottom: "30%",
                            fontSize: "0.95rem",
                        }}
                    >
                        {categorySection}
                        {headerSection}
                        <Div small>{!feedStyle && tagsSection}</Div>
                        <Div
                            absolute
                            clear
                            txtHalf
                            tiny
                            fluid
                            flex
                            style={{
                                bottom: "5px",
                                left: "15px",
                                justifyContent: "space-between",
                            }}
                        >
                            <Div fit-content style={{ marginTop: "2px" }}>
                                <i className={`icon icm icon-${icon}`} />
                            </Div>
                            <Div fit-content style={{ marginRight: "30px" }}>
                                {metaSection}
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
        );
    else
        return (
            <Div
                fluid
                fitted
                small
                rounded
                noOverflow
                clickable
                onClick={() =>
                    navigate(
                        `/content/${article?.category}/${article?.identifier}`
                    )
                }
            >
                <Card fluid className="article-card">
                    {imgSection}
                    <Card.Content>
                        <Card.Description>
                            {headerSection}
                            {tagsSection}
                        </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                        <Div right-aligned>{metaSection}</Div>
                    </Card.Content>
                </Card>
            </Div>
        );
}
