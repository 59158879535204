import React from "react";

import { Div } from "@components/Generics.react";
import LearnerTable from "../components/LearnerTable.react";

export default function ArticleContent(props) {
    const { learnerData, refetch } = props;

    return (
        <Div fluid maxHt={`${window.innerHeight - 330}px`} autoOverflowY>
            <LearnerTable
                header={"Content Analytics"}
                data={learnerData}
                refetch={refetch}
                rows={5}
            />
        </Div>
    );
}
