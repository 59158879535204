import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    useGetPaginatedUsersQuery,
    useDeleteUserMutation,
    useDeactivateUserMutation,
    useActivateUserMutation,
} from "@api/apiV6";
import _ from "lodash";
import { toast } from "react-toastify";

import { Div, Button } from "@components/Generics.react";
import { Checkbox, Confirm } from "semantic-ui-react";
import AddUserModal from "./AddUserModal.react";
import UserDetails from "./UserDetails.react";
import EditLicenseModal from "./EditLicenseModal.react";
import EditUserModal from "./EditUserModal.react";
import EditDetailsModal from "./EditDetailsModal.react";
import AdminEnrolModal from "./AdminEnrolModal.react";
import AdminUnenrolModal from "./AdminUnenrolModal.react";
import PaginatedTable from "../../../components/PaginatedTable.react";

export default function AdminLargeUserManagement(props) {
    const user = useSelector((state) => state.auth.user);
    const { t } = useTranslation("common");

    const [tableRef, setTableRef] = useState(null);
    const [newModalOpen, setNewModalOpen] = useState(false);
    const [confirmFn, setConfirmFn] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [pickedUsers, setPickedUsers] = useState([]);
    const [pickedAll, setPickedAll] = useState(false);

    const [deleteUser] = useDeleteUserMutation();
    const [deactivateUser] = useDeactivateUserMutation();
    const [activateUser] = useActivateUserMutation();

    useEffect(() => {
        if (pickedAll) {
            const items = _.map(
                tableRef.current.getResolvedState().sortedData,
                "_original"
            );
            setPickedUsers(
                _.map(
                    _.filter(items, (item) => item.username !== "sysadmin"),
                    "_id"
                )
            );
        } else {
            setPickedUsers([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pickedAll]);

    async function bulkActivate() {
        let processedUsers = 0;
        for (let i = 0; i < pickedUsers.length; i++) {
            const updateResponse = await activateUser(pickedUsers[i]);
            if (updateResponse) processedUsers++;
        }
        toast(`${processedUsers} users Activated`);
        setConfirmFn(false);
    }
    async function bulkDeactivate() {
        let processedUsers = 0;
        for (let i = 0; i < pickedUsers.length; i++) {
            const updateResponse = await deactivateUser(pickedUsers[i]);
            if (updateResponse) processedUsers++;
        }
        toast(`${processedUsers} users Deactivated`);
        setConfirmFn(false);
    }
    async function bulkDelete() {
        let processedUsers = 0;
        for (let i = 0; i < pickedUsers.length; i++) {
            const updateResponse = await deleteUser(pickedUsers[i]);
            if (updateResponse) processedUsers++;
        }
        toast(`${processedUsers} users Deleted`);
        setConfirmFn(false);
    }

    let fullColumns = [
        {
            Header: ({ row }) => (
                <Checkbox
                    checked={pickedAll}
                    onClick={() => setPickedAll(!pickedAll)}
                />
            ),
            id: `check`,
            accessor: (row) => row._id,
            Cell: ({ row }) =>
                row._original.username !== "sysadmin" ? (
                    <Checkbox
                        checked={_.includes(pickedUsers, row._original._id)}
                        onClick={() =>
                            setPickedUsers(
                                _.includes(pickedUsers, row._original._id)
                                    ? _.without(pickedUsers, row._original._id)
                                    : _.concat(pickedUsers, row._original._id)
                            )
                        }
                    />
                ) : (
                    <Div />
                ),
            maxWidth: 50,
            style: { textAlign: "center" },
        },
        {
            Header: "Username",
            id: "username",
            accessor: "username",
            Cell: ({ row }) => {
                return (
                    <Div
                        txtMain
                        clickable
                        onClick={() => setSelectedUser(row._original._id)}
                    >
                        {row._original.username}
                    </Div>
                );
            },
            minWidth: 240,
        },
        {
            Header: "Name",
            id: "name",
            accessor: (u) => `${u.first_name} ${u.last_name}`,
            minWidth: 128,
        },
        {
            Header: "Team",
            id: "team",
            accessor: "team",
            maxWidth: 128,
        },
        {
            Header: "License",
            id: "license",
            accessor: "license",
            maxWidth: 100,
            Cell: ({ row }) => row._original.license.license_type,
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                }
                if (filter.value === "admin") {
                    return ["SuperAdmin", "Admin"].includes(
                        row[filter.id].license_type
                    );
                }
                if (filter.value === "creator") {
                    return ["Creator"].includes(row[filter.id].license_type);
                }
                if (filter.value === "learner") {
                    return ["Learner"].includes(row[filter.id].license_type);
                }
                return row[filter.id] === 0;
            },
            Filter: ({ filter, onChange }) => (
                <select
                    onChange={(event) => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">All</option>
                    <option value="admin">Admins</option>
                    <option value="creator">Creators</option>
                    <option value="learner">Learners</option>
                </select>
            ),
        },
        {
            Header: "Active",
            id: "active",
            accessor: "active",
            maxWidth: 100,
            Cell: ({ row }) => (row._original.active ? "Yes" : "No"),
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                }
                if (filter.value === "Yes") {
                    return row[filter.id];
                }
                if (filter.value === "No") {
                    return !row[filter.id];
                }
                return row[filter.id];
            },
            Filter: ({ filter, onChange }) => (
                <select
                    onChange={(event) => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">All</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            ),
        },
    ];

    // ========================= Render Function =================================
    return (
        <Div fullht clearfix>
            <Div wd="calc(100% - 400px)" fullht float-left>
                <Div white ht="calc(100% - 123px)">
                    <Div ht={`${window.innerHeight - 300}px`} autoOverflowY>
                        <PaginatedTable
                            queryFn={useGetPaginatedUsersQuery}
                            queryArgs={{}} // Any additional static args for the query
                            columns={fullColumns}
                            setTableRef={setTableRef}
                        />
                    </Div>
                </Div>
            </Div>
            <Div wd="400px" fullht basepad peat float-right>
                {selectedUser == null && pickedUsers.length === 0 ? (
                    <Div fullht basepad vapor flex>
                        {t("admin.userMgmt.clickToSelect")}
                    </Div>
                ) : pickedUsers.length === 0 ? (
                    <UserDetails userId={selectedUser} />
                ) : (
                    <Div basepad half flex column gapped>
                        <Div large gutter>
                            <Div bold>BULK OPERATIONS</Div>
                            <Div tiny italics>
                                These are dangerous operations. Please ensure
                                that you know what you are doing.
                            </Div>
                        </Div>
                        <Button
                            fluid
                            primary
                            content={`Set License for ${pickedUsers.length} users`}
                            onClick={() => setConfirmFn("license")}
                        />
                        <Button
                            fluid
                            primary
                            content={`Set Profile for ${pickedUsers.length} users`}
                            onClick={() => setConfirmFn("profile")}
                        />
                        <Button
                            fluid
                            primary
                            content={`Set Details for ${pickedUsers.length} users`}
                            onClick={() => setConfirmFn("details")}
                        />
                        <Button
                            fluid
                            success={+true}
                            content={`Activate ${pickedUsers.length} users`}
                            onClick={() => setConfirmFn("activate")}
                        />
                        <Button
                            fluid
                            secondary
                            content={`Deactivate ${pickedUsers.length} users`}
                            onClick={() => setConfirmFn("deactivate")}
                        />
                        <Button
                            fluid
                            primary
                            content={`Enroll ${pickedUsers.length} users`}
                            onClick={() => setConfirmFn("enroll")}
                        />
                        <Button
                            fluid
                            secondary
                            content={`Unenroll ${pickedUsers.length} users`}
                            onClick={() => setConfirmFn("unenroll")}
                        />
                        <Button
                            fluid
                            danger={+true}
                            content={`Delete ${pickedUsers.length} users`}
                            onClick={() => setConfirmFn("delete")}
                        />
                        <br />
                        <Div ash relaxed snubbed gutter>
                            P.S. Remember to refresh user list to see the
                            updates you make here.
                        </Div>
                    </Div>
                )}
                <Button
                    size="small"
                    fluid
                    primary
                    icon="plus"
                    labelPosition="left"
                    content={t("admin.userMgmt.addUser")}
                    onClick={() => setNewModalOpen(true)}
                />
            </Div>
            <AddUserModal
                isOpen={newModalOpen}
                setOpen={setNewModalOpen}
                ownerTeam={user.team}
            />

            <EditLicenseModal
                key={`bulk-edit-license`}
                isOpen={confirmFn === "license"}
                setOpen={setConfirmFn}
                users={pickedUsers}
            />
            <EditUserModal
                key={`bulk-edit-user`}
                isOpen={confirmFn === "profile"}
                setOpen={setConfirmFn}
                users={pickedUsers}
            />
            <EditDetailsModal
                key={`bulk-edit-details`}
                isOpen={confirmFn === "details"}
                setOpen={setConfirmFn}
                users={pickedUsers}
            />
            <AdminEnrolModal
                key={`bulk-admin-enroll`}
                isOpen={confirmFn === "enroll"}
                setOpen={setConfirmFn}
                users={pickedUsers}
            />
            <AdminUnenrolModal
                key={`bulk-admin-unenroll`}
                isOpen={confirmFn === "unenroll"}
                setOpen={setConfirmFn}
                users={pickedUsers}
            />
            <Confirm
                open={confirmFn === "activate"}
                onCancel={() => setConfirmFn(false)}
                onConfirm={bulkActivate}
            />
            <Confirm
                open={confirmFn === "deactivate"}
                onCancel={() => setConfirmFn(false)}
                onConfirm={bulkDeactivate}
            />
            <Confirm
                open={confirmFn === "delete"}
                onCancel={() => setConfirmFn(false)}
                onConfirm={bulkDelete}
            />
        </Div>
    );
}
