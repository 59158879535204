import React from "react";
import { Icon } from "semantic-ui-react";
import { Div, Image } from "@components/Generics.react";
import { darkStyle } from "@utilities/helpers";
import "@styles/components.scss";

export default function Reward(props) {
    const { deck, disabled, small = false } = props;

    // ========================= Render Function =================================
    return (
        <Div
            className="reward"
            rounded
            fluid
            maxWd={small && "240px"}
            superpad={!small}
            basepad={small}
            left-aligned
            relative
            centered
            dropShadow
            big
            style={darkStyle("#666666")}
        >
            <Div bold gutter>
                {deck.content?.name}
            </Div>
            <Div centered gutter>
                <Image src={deck.content?.image} />
            </Div>
            <Div trench={!small} gutter={small} small={small}>
                {deck.content?.header}
            </Div>
            {small ? <hr /> : <Div gutter compact wd="70px" primary />}
            <Div small>{deck.content?.content}</Div>
            {disabled && (
                <div className="reward-overlay">
                    <Icon name="lock" inverted size="huge" />
                </div>
            )}
        </Div>
    );
}
