// Import from NPM
// -------------------------------------
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Div, Image, Icomoon } from "@components/Generics.react";
import ImageUploader from "@components/ImageUploader.react";
import "@styles/editor.scss";

export default function BackgroundSelector(props) {
    const { name, deck, setDeck, leftBordered = true } = props;
    const { t } = useTranslation("common");
    const [imgEditorOpen, setImgEditorOpen] = useState(false);

    function setImage(image) {
        setDeck({
            ...deck,
            ...{ backgroundImage: image },
        });
        setImgEditorOpen(false);
    }

    // ========================= Render Function =================================
    return (
        <Div className="ribbon-btn" leftBordered={leftBordered}>
            <Div
                medpad
                white
                slightShadow
                snubbed
                fit-content
                center-align
                clickable
                flex
                flexStart
                gapped
            >
                <Div wd="38px" ht="50px" grey snubbed noOverflow>
                    <Image src={deck.backgroundImage} />
                </Div>
                <Div left-align leftBordered style={{ marginTop: "2.5px" }}>
                    <Div
                        compact
                        small
                        className="sub-text-btn"
                        onClick={() => setImgEditorOpen(true)}
                    >
                        <Div inline>
                            <Icomoon name={"image"} />
                        </Div>{" "}
                        <Div inline>{t(`builder.ribbon.set`)}</Div>
                    </Div>
                    <Div
                        compact
                        small
                        className="sub-text-btn"
                        onClick={() => setImage(null)}
                    >
                        <Div inline>
                            <Icomoon name={"close"} />
                        </Div>{" "}
                        <Div inline>{t(`builder.ribbon.remove`)}</Div>
                    </Div>
                </Div>
            </Div>
            <Div small center-align gapTop>
                {name}
            </Div>
            <ImageUploader
                modalOpen={imgEditorOpen}
                handleModalClose={() => setImgEditorOpen(false)}
                handleModalSave={(image) => setImage(image)}
                value={deck.backgroundImage}
                aspectRatio={1.777}
                mWidth={256}
            />
        </Div>
    );
}
