import React from "react";
import _ from "lodash";
import { Divider } from "semantic-ui-react";
import { Div, HDiv } from "@components/Generics.react";

export default function LineBulletList(props) {
    const { bullets, caption, paragraph } = props.data;
    const getOptions = _.map(bullets, (item, index) => {
        return <li key={index}>{item}</li>;
    });

    return (
        <Div superpad big>
            <HDiv fluid bold zoom content={paragraph || caption} />
            <Divider />
            <ul style={{ marginLeft: "-15px" }}>{getOptions}</ul>
        </Div>
    );
}
