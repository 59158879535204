import _ from "lodash";

export const updateTopic = (currentState, attempt) => {
    const { deckId, completion, timeSpent, pointsDelta, date } = attempt;

    const presentState = {
        viewedPercentage: currentState.viewedPercentage ?? 0,
        viewAttempts: currentState.viewAttempts ?? 0,
        timeSpent: currentState.timeSpent ?? 0,
        score: currentState.score,
        points: currentState.points ?? 0,
        completion: currentState.completion ?? false,
        completionDate: currentState.completionDate,
        subStates: currentState.subStates,
    };

    let subStates = { ...presentState.subStates };
    subStates[deckId] = subStates[deckId] || completion; // If deck is already marked complete or is completed now
    subStates = _.omit(subStates, ["null", "false", "undefined"]);

    const totalLength = _.keys(subStates).length;
    const completedLength = _.filter(
        _.values(subStates),
        (deckIdStatus) => deckIdStatus === true
    ).length;

    const viewedPercentage = getPercent(completedLength, totalLength);

    return {
        viewedPercentage: viewedPercentage,
        viewAttempts: presentState.viewAttempts + 1,
        timeSpent: presentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: presentState.points + (pointsDelta ?? 0),
        completion: presentState.completion || viewedPercentage === 100,
        completionDate: presentState.date || date,
        lastPosition: deckId,
        subStates: subStates,
    };
};

const getPercent = (x, y) => Math.min(100, Math.round((x * 100) / y));
