import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useGetQuestionResponseQuery } from "@api/apiV6";
import { TemplateList } from "@schemas/templateSchema";
import { SlideLayout } from "@layouts/SlideLayout.react";
import "@styles/readers.scss";

import { Div } from "@components/Generics.react";
import DeckToolbar from "@components/DeckToolbar.react";

export default function AssignmentReader(props) {
    const { deck, recordAttempt, isPreview, slideNum } = props;
    const userId = useSelector((state) => state.auth.user._id);
    const isPortrait = window.innerHeight > window.innerWidth;

    const { data: qResponses, isSuccess } = useGetQuestionResponseQuery({
        deckId: deck.id,
        userId: userId,
    });

    //---------------------------------------------------------------------------
    // Slide Navigation
    //---------------------------------------------------------------------------
    const [pageNumber, setPageNumber] = useState(0);
    const [numPages, setNumPages] = useState(1);
    const [maxRead, setMaxRead] = useState(0);
    const [slideData, setSlideData] = useState(null);
    const [slideResponse, setSlideResponse] = useState(null);

    useEffect(() => {
        if (isSuccess) {
            if (numPages !== deck.content.length)
                setNumPages(deck.content.length);
            const currentSlide = _.find(deck.content, {
                slideSeq: pageNumber,
            });
            setSlideData(currentSlide);
            const currentResponse = _.find(qResponses, {
                seq: pageNumber,
            });
            if (currentResponse)
                setSlideResponse(currentResponse.responses.slice(-1)[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, deck, numPages, isSuccess]);
    //---------------------------------------------------------------------------
    // Creator Slide Navigation
    //---------------------------------------------------------------------------
    useEffect(() => {
        if (slideNum != null && pageNumber !== slideNum)
            setPageNumber(slideNum);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slideNum, pageNumber]);

    //---------------------------------------------------------------------------
    // Slide Content
    //---------------------------------------------------------------------------
    const templateSchema = TemplateList[slideData?.template];
    const SlideComponent = templateSchema?.template;

    //---------------------------------------------------------------------------
    // Slide Response
    //---------------------------------------------------------------------------
    const hasResponse = slideResponse != null;
    const beenMarked = slideResponse?.s != null;

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        if (maxRead <= pageNumber + 1) setMaxRead(pageNumber);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber]);

    useEffect(() => {
        if (numPages > 0) {
            recordAttempt({
                viewedPercentage: Math.min(
                    100,
                    parseInt(((maxRead + 1) * 100) / numPages, 0)
                ),
                completion: maxRead >= numPages - 1,
                pointsDelta: 0,
                sre: null,
                showEnding: maxRead >= numPages,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numPages, maxRead]);

    // ========================= Render Function =================================
    return (
        <Div
            fluid
            ht={
                isPreview
                    ? "100%"
                    : `calc(100vh - ${
                          isPortrait
                              ? /iPad|iPhone|iPod/.test(navigator.userAgent)
                                  ? 195
                                  : 155
                              : 125
                      }px)`
            }
            centered
            center-align
            relative
            noOverflow={isPreview || hasResponse}
            className="fullscreen-target assignment-reader"
        >
            <SlideLayout
                className="slide-container"
                title={templateSchema?.showHeader ? slideData.data.title : ""}
                subtitle={
                    templateSchema?.showHeader ? slideData.data.subtitle : ""
                }
                image={slideData?.data?.image}
                sequence={slideData?.slideSeq}
                colors={deck?.colors}
                backgroundImage={deck?.backgroundImage}
                isPreview={isPreview}
            >
                {SlideComponent && (
                    <SlideComponent
                        {...slideData}
                        deckId={deck._id}
                        setPageNumber={setPageNumber}
                        pageNumber={pageNumber}
                        noFeedback={true}
                    />
                )}
                {hasResponse && beenMarked && (
                    <Div absolute fluid fullht className="remarks-overlay">
                        <Div className="pattern-overlay" />
                        <Div
                            absolute
                            charcoal
                            basepad
                            fluid
                            style={{ bottom: 0, left: 0 }}
                        >
                            <Div midnight className="remarks-header">
                                Evaluation:
                            </Div>
                            <Div>Response: {slideResponse.r}</Div>
                            <Div>Marks: {slideResponse.s}</Div>
                            <Div gapTop bold>
                                Remarks:
                            </Div>
                            <Div>{slideResponse.m}</Div>
                        </Div>
                    </Div>
                )}
            </SlideLayout>
            {!isPreview && (
                <DeckToolbar
                    {...{
                        deck,
                        pageNumber,
                        setPageNumber,
                        numPages,
                    }}
                />
            )}
        </Div>
    );
}
