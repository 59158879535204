// Import from NPM
// -------------------------------------
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { toast } from "react-toastify";

import { Div, Button } from "@components/Generics.react";
import "@styles/navbars.scss";

import { Loader, Divider } from "semantic-ui-react";
import {
    useGetDeckBuilderQuery,
    useUpdateDeckMutation,
} from "@config/api/apiV6";
import CreatorLayout from "@layouts/CreatorLayout.react";
import Ribbon from "./ribbon/Ribbon.react";
import { deckFormats } from "@schemas/deckFormats";
import { isStringifiedJSON } from "@utilities/helpers";

import FormBuilder from "./editors/FormBuilder.react";
import DeckBuilder from "./editors/DeckBuilder.react";
import DeckPreviewer from "./editors/slideEditorComponents/DeckPreviewer.react";
import SlideSorter from "./editors/slideEditorComponents/SlideSorter.react";
import DeckNotes from "./editors/slideEditorComponents/DeckNotes.react";
import DeckSettings from "./editors/slideEditorComponents/DeckSettings.react";
import shortid from "shortid";
import AIQGenerator from "./editors/slideEditorComponents/AIQGenerator.react";
import AIQTopic from "./editors/slideEditorComponents/AIQTopic.react";
import AIQTranslate from "./editors/slideEditorComponents/AIQTranslate.react";
import AISGenerator from "./editors/slideEditorComponents/AISGenerator.react";
import AISTopic from "./editors/slideEditorComponents/AISTopic.react";
import AISTranslate from "./editors/slideEditorComponents/AISTranslate.react";

export default function DeckEditor(props) {
    const { readerType, id, breadcrumbs } = useParams();
    const { data: serverDeck, isSuccess } = useGetDeckBuilderQuery(id);
    const [uploadDeck] = useUpdateDeckMutation();
    const navigate = useNavigate();
    const { t } = useTranslation("common");

    const deckSchema = {
        language: "en",
        name: "",
        courseId: "",
        backgroundImage: "",
        presenter: "",
        feedback: false,
        navEnabled: true,
        voEnabled: false,
        jumpAhead: false,
        translationEnabled: false,
        colors: {
            base: "#ffffff",
            baseText: "#212121",
            primary: "#0097a7",
            primaryText: "#fff",
            primaryVariation: "#00838f",
            secondary: "#454545",
            secondaryText: "#ffffff",
        },
        content: null,
        isAssessment: false,
        passingPercentage: 60,
        currentSlide: 0,
        comments: [],
    };

    const [deck, setDeck] = useState(null);
    const [slideNum, setSlideNum] = useState(0);
    const [rightPanel, setRightPanel] = useState("mobile");
    const [hashKey, setHashKey] = useState(shortid.generate());

    useEffect(() => {
        resetDeck();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    useEffect(() => {
        function ctrlsFunction(e) {
            if (
                e.key === "s" &&
                (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)
            ) {
                e.preventDefault();
                saveDeck();
            }
        }
        document.addEventListener("keydown", ctrlsFunction, false);
        return () =>
            document.removeEventListener("keydown", ctrlsFunction, false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deck]);

    function resetDeck() {
        if (isSuccess) {
            let combinedDeck = _.assign(
                { id: id, readerType: readerType },
                deckSchema,
                isStringifiedJSON(serverDeck.content)
                    ? _.omit(JSON.parse(serverDeck.content), [
                          "id",
                          "readerType",
                      ])
                    : _.omit(serverDeck, ["id", "readerType"])
            );
            if (combinedDeck.content === "") combinedDeck.content = [];
            setDeck(combinedDeck);
        }
    }

    async function saveDeck() {
        await uploadDeck({ id, ...deck }).unwrap();
        toast(t("components.saved"));
    }
    async function saveDeckAndExit() {
        await saveDeck();
        setTimeout(() => {
            navigate(-1);
        }, 1000);
    }
    function cancelExit() {
        navigate(-1);
    }
    function updateDeck(data) {
        setDeck({ ...deck, ...data });
        setHashKey(shortid.generate());
    }

    const deckFormat = _.find(deckFormats, { readerType });
    const fields = deckFormat.contentSchema;
    const slideEditor = deckFormat.ribbon != null;

    const rightPanelMap = {
        mobile: DeckPreviewer,
        sorter: SlideSorter,
        notes: DeckNotes,
        aiqtrans: AIQTranslate,
        aistrans: AISTranslate,
        aiqgen: AIQGenerator,
        aisgen: AISGenerator,
        aiqtopic: AIQTopic,
        aistopic: AISTopic,
        settings: DeckSettings,
    };

    const ShowRightPanel = rightPanelMap[rightPanel];

    const mainPanel =
        isSuccess && deck ? (
            <Div>
                {slideEditor && (
                    <Ribbon
                        {...{
                            id,
                            readerType,
                            breadcrumbs,
                            deck,
                            updateDeck,
                            resetDeck,
                            saveDeck,
                            saveDeckAndExit,
                            cancelExit,
                            rightPanel,
                            setRightPanel,
                        }}
                    />
                )}
                <Div ht="calc(100vh - 225px)" fluid flex>
                    <Div fullht wd="calc(100% - 400px)">
                        {slideEditor && deck.content ? (
                            <Div fluid>
                                <DeckBuilder
                                    key={`deck-content-${slideNum}`}
                                    {...{
                                        id,
                                        readerType,
                                        deck,
                                        setDeck,
                                        updateDeck,
                                        slideNum,
                                        setSlideNum,
                                        setRightPanel,
                                    }}
                                />
                            </Div>
                        ) : (
                            <Div fluid basepad>
                                <Div fluid>
                                    <Div compact uppercase>
                                        {t("components.editing")}{" "}
                                        {deckFormat.category}:
                                    </Div>
                                    <Div large gutter compact bold>
                                        {breadcrumbs}
                                    </Div>
                                    <Div
                                        medpad
                                        white
                                        snubbed
                                        ht="calc(100vh - 250px)"
                                        autoOverflowY
                                        fluid
                                    >
                                        <FormBuilder
                                            deck={deck}
                                            setDeck={updateDeck}
                                            fields={fields}
                                        />
                                    </Div>
                                </Div>
                                <Divider />
                                <Button
                                    danger={+true}
                                    content={t("components.cancelExit")}
                                    onClick={cancelExit}
                                />
                                <Div float-right>
                                    <Button
                                        secondary
                                        content={t("components.saveExit")}
                                        onClick={saveDeckAndExit}
                                    />
                                </Div>
                                <Div float-right>
                                    <Button
                                        primary
                                        content={t("components.save")}
                                        onClick={saveDeck}
                                    />
                                </Div>
                            </Div>
                        )}
                    </Div>
                    <Div wd="400px" fullht>
                        <ShowRightPanel
                            key={`right-pane-${hashKey}`}
                            {...{
                                id,
                                readerType,
                                deck,
                                setDeck,
                                breadcrumbs,
                                slideEditor,
                                slideNum,
                                setSlideNum,
                                setRightPanel,
                            }}
                        />
                    </Div>
                </Div>
            </Div>
        ) : (
            <Loader active inverted />
        );

    // ========================= Render Function =================================
    return (
        <CreatorLayout
            title={`${t("components.editing")} ${deck?.name || breadcrumbs}`}
            mainPanel={mainPanel}
            navName={t("components.editContent")}
            pageCode={"library"}
            rootPage={false}
            editMode={true}
        />
    );
}
