import { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import "@styles/readers.scss";
import { Div, HDiv, Image } from "@components/Generics.react";

export default function ProductCatalog(props) {
    const { deck, currentState, recordAttempt } = props;
    const imageRef = useRef(null);

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentState]);

    return (
        <Div
            fluid
            fullht
            ash
            scrollOverflow
            priText
            normal
            className="product-catalog-reader"
        >
            <Div relative topRounded noOverflow>
                <div ref={imageRef}>
                    <Image fluid src={deck?.content?.image} />
                    <Div
                        midnight
                        centered
                        bold
                        topRounded
                        className="product-name"
                        uppercase
                    >
                        {deck?.content?.name}
                    </Div>
                </div>
            </Div>
            <HDiv vapor basepad content={deck?.content?.description} />
            <Div
                className={`catalog-main-section ${
                    deck?.content?.products?.length < 3 && "two-divs"
                }`}
            >
                {_.map(deck?.content?.products, (product, idx) => {
                    const card = (
                        <Div
                            white
                            rounded
                            clickable
                            className="product-catalog-card"
                        >
                            <Image snubbed src={product.image} />
                            <Div padded padTop bold>
                                {product.title != null
                                    ? product.title
                                    : product.name}
                            </Div>
                            <Div padded mini>
                                {product.description}
                            </Div>
                            <Div padded bold txtColor="#FFA500">
                                {product.meta}
                                <br />
                                <br />
                            </Div>
                        </Div>
                    );
                    if (product.link) {
                        if (_.includes(product.link, "http"))
                            return (
                                <a
                                    href={product.link}
                                    target="_blank"
                                    rel="noreferrer"
                                    key={`prod-card-link-${idx}`}
                                >
                                    {card}
                                </a>
                            );
                        else
                            return (
                                <NavLink
                                    to={product.link}
                                    key={`prod-card-link-${idx}`}
                                >
                                    {card}
                                </NavLink>
                            );
                    }
                })}
            </Div>
        </Div>
    );
}
