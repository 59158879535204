import React, { useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useFeatures } from "flagged";
import { Input, Icon, Card, Image, Modal } from "semantic-ui-react";
import ReactPlayer from "react-player/lazy";
import { Div } from "@components/Generics.react";
import { Tutorials } from "@config/settings/app.tutorials";

export default function TutorialsPage(props) {
    const { t } = useTranslation("common");
    const features = useFeatures();
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [searchText, setSearchText] = useState("");

    function checkFor(config, target, group) {
        return group == null
            ? _.includes(config, target) && features[target]
            : _.includes(config, target) && features[group][target];
    }

    const filteredTutorials = _.filter(
        Tutorials,
        (item) =>
            (item.name.toLowerCase().includes(searchText.toLowerCase()) ||
                item.description
                    .toLowerCase()
                    .includes(searchText.toLowerCase())) &&
            (checkFor(item.meta, "learningCenter") ||
                checkFor(item.meta, "courseware") ||
                checkFor(item.meta, "social") ||
                _.includes(item.meta, "common"))
    );
    const handleCardClick = (video) => {
        setSelectedVideo(video);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedVideo(null);
    };

    // ========================= Render Function =================================
    return (
        <Div fluid fullht>
            <Input
                fluid
                placeholder={t("components.search")}
                icon={{ name: "search" }}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
            />
            <Div ht="calc(100% - 50px)" autoOverflowY rimmed small vapor>
                {_.map(filteredTutorials, (card, idx) => {
                    return (
                        <Card
                            key={`tutorial-${idx}`}
                            onClick={() => handleCardClick(card.video)}
                        >
                            <Div fluid relative clickable>
                                <Image fluid src={card.image} />
                                <Div
                                    absolute
                                    snubbed
                                    style={{
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                    }}
                                >
                                    <Icon
                                        name="youtube play"
                                        color="red"
                                        size="huge"
                                    />
                                </Div>
                            </Div>
                            <Card.Content>
                                <Card.Header>{card.name}</Card.Header>
                                <Card.Meta>
                                    {_.map(card.meta, (m) =>
                                        t(`helpdesk.meta.${m}`)
                                    ).join(", ")}
                                </Card.Meta>
                                <Card.Description>
                                    {card.description}
                                </Card.Description>
                            </Card.Content>
                        </Card>
                    );
                })}
            </Div>
            {selectedVideo && (
                <Modal open={modalOpen} closeIcon onClose={handleCloseModal}>
                    <Div>
                        {selectedVideo && (
                            <ReactPlayer
                                url={selectedVideo}
                                width="100%"
                                height="30em"
                                controls
                            />
                        )}
                    </Div>
                </Modal>
            )}
        </Div>
    );
}
