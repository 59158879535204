// Import from NPM
// -------------------------------------
import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useFeatures } from "flagged";

import { Div } from "@components/Generics.react";
import { deckFormats } from "@schemas/deckFormats";
import "@styles/editor.scss";

import { Tab } from "semantic-ui-react";
import HomeMenu from "./panels/HomeMenu.react";
import DesignMenu from "./panels/DesignMenu.react";
import GeneratorMenu from "./panels/GeneratorMenu.react";

export default function Ribbon(props) {
    const { breadcrumbs, readerType } = props;
    const { t } = useTranslation("common");
    const features = useFeatures();

    const ribbonSchema = _.find(deckFormats, { readerType }).ribbon;
    if (readerType === "qdf" && !features.ability.builder.aiAssistant)
        ribbonSchema.generate = null;
    const ribbon = ribbonSchema;

    let panes = [];
    if (ribbon.home)
        panes.push({
            menuItem: t("builder.ribbon.home"),
            render: () => (
                <Tab.Pane>
                    <HomeMenu {...props} config={ribbon.home} />
                </Tab.Pane>
            ),
        });
    if (ribbon.design)
        panes.push({
            menuItem: t("builder.ribbon.design"),
            render: () => (
                <Tab.Pane>
                    <DesignMenu {...props} config={ribbon.design} />
                </Tab.Pane>
            ),
        });
    if (ribbon.generate)
        panes.push({
            menuItem: t("builder.ribbon.generate"),
            render: () => (
                <Tab.Pane>
                    <GeneratorMenu {...props} config={ribbon.generate} />
                </Tab.Pane>
            ),
        });

    // ========================= Render Function =================================
    return (
        <Div className="ribbon-menu">
            <Div fit-content flex gapped className="breadcrumbs">
                <Div fit-content padSides>
                    {breadcrumbs.replace("_", " ").replace("::", " > ")}
                </Div>
                <Div charcoal fit-content className="deck-format">
                    {_.find(deckFormats, { readerType }).name}
                </Div>
            </Div>
            <Div sharpShadow>
                <Tab panes={panes} />
            </Div>
        </Div>
    );
}
