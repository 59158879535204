import React, { useEffect } from "react";
import "@styles/readers.scss";

import { Div } from "@components/Generics.react";

export default function IframeReader(props) {
    const { deck, recordAttempt } = props;

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // ========================= Render Function =================================
    return (
        <Div fluid ht="500px" rounded>
            <iframe
                className="iframe-container"
                title="This is an iframe Readertype"
                src={deck?.content}
                width={"100%"}
                height={"100%"}
            />
        </Div>
    );
}
