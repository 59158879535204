import React, { useState } from "react";
import _ from "lodash";
import {
    useSendUserSearchMutation,
    useSendAddLearnerMutation,
    useCreateInNotificationMutation,
    useCreateEmailNotificationMutation,
} from "@api/apiV6";
import { useSelector } from "react-redux";
import { useFeatures } from "flagged";

import { Div, Button } from "@components/Generics.react";
import { notificationConfig } from "@config/notifications/config.notification";
import { settings } from "@config/settings/app.settings";

import {
    Message,
    Modal,
    Input,
    List,
    Icon,
    Grid,
    Progress,
} from "semantic-ui-react";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";

export default function EnrollLearnerModal(props) {
    const { isOpen, setOpen, isMultiOpen, setMultiOpen, course } = props;
    const ownerUser = useSelector((state) => state.auth.user);
    const features = useFeatures();

    const [searchData, { isLoading }] = useSendUserSearchMutation();
    const [addLearner] = useSendAddLearnerMutation();
    const [triggerInNotification] = useCreateInNotificationMutation();
    const [triggerEmailNotification] = useCreateEmailNotificationMutation();

    const [loading, setLoading] = useState(false);
    const [searchFor, setSearchFor] = useState("");
    const [results, setResults] = useState([]);
    const [showingResults, setShowingResults] = useState(false);
    const [alreadyEnrolled, setAlreadyEnrolled] = useState(null);
    const [processingStatus, setProcessingStatus] = useState([]);
    const [progress, setProgress] = useState(0);
    const [bulkUsersId, setBulkUsersId] = useState([]);
    const [multiEnrollmentComplete, setMultiEnrollmentComplete] =
        useState(false);
    const [multiEnrollmentLoading, setMultiEnrollmentLoading] = useState(false);

    function handleSearchChange() {
        if (searchFor) {
            let users = searchData(searchFor);
            setLoading(isLoading);
            users.then((res) => {
                if (features.ability.builder.crossAccess) setResults(res.data);
                else setResults(_.filter(res.data, { team: ownerUser.team }));
            });
            setShowingResults(true);
        }
    }

    function handleEnterKeyPress(e) {
        if (e.key === "Enter") {
            handleSearchChange();
        }
    }

    function enrollUser(result) {
        // let addLearnerInCousre = addLearner({ id: course?._id, user_id: result.id });
        // addLearnerInCousre.then((user) => setAlreadyEnrolled(user?.error.data));
        addLearner({ id: course?._id, user_id: result.id }).then((user) => {
            if (user?.error) {
                setAlreadyEnrolled(
                    user?.error?.originalStatus === 422
                        ? result.username
                        : user?.error.data
                );
            } else {
                setOpen(false);
                setMultiOpen(false);
                setSearchFor("");
                setAlreadyEnrolled(null);
                setResults([]);
            }
        });
        if (notificationConfig.enrolUser.inAppNotification || notificationConfig.enrolUser.emailNotification || course.autoNotification || course.launch) {
            if (notificationConfig.enrolUser.inAppNotification && course.autoNotification && course.launch) {
                let message = _.pick(
                    notificationConfig.enrolUser,
                    "type",
                    "title",
                    "description",
                    "link"
                );
                message.description = _.replace(message.description, "$User", ownerUser?.username);
                message.description = _.replace(message.description, "$courseName", course?.name);
                message.created_at = new Date();
                message.link = "/courses/"+course?._id;
                triggerInNotification({
                    _id: result?._id,
                    message: message,
                });
            }
            if (notificationConfig.enrolUser.emailNotification && course.autoNotification && course.launch) {
                triggerEmailNotification({
                    _id: result?._id,
                    route: notificationConfig.enrolUser.route,
                    message: "",
                    emailType: "user_enrollment",
                    course: course,
                    courseAssignor: ownerUser?.username,
                });
            }
        }
    }

    const handleXLSFileLoad = (e) => {
        const uploadedFile = e.target.files[0];
        if (uploadedFile) {
            const reader = new FileReader();
            try {
                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: "array" });
                    const sheet = workbook.Sheets[workbook.SheetNames[1]];
                    const parsedUsers = XLSX.utils.sheet_to_json(sheet, {
                        header: 1,
                    });
                    const usersData = parsedUsers.slice(1); // Remove header row
                    setBulkUsersId(usersData);
                };
                reader.readAsArrayBuffer(uploadedFile);
            } catch (error) {
                console.error("Error parsing Excel file", error);
            }
        }
    };

    async function enrollMultiUsers() {
        try {
            setMultiEnrollmentLoading(true);

            for (let usr = 0; usr < bulkUsersId.length; usr++) {
                setProgress(Math.round(((usr + 1) / bulkUsersId.length) * 100));

                const username = bulkUsersId[usr][0];

                const userSearchResult = await searchData(username);
                await new Promise((resolve) => setTimeout(resolve, 100));

                if (
                    !userSearchResult.data ||
                    userSearchResult.data.length === 0
                ) {
                    setProcessingStatus((prevState) => [
                        {
                            username,
                            status: false,
                            result: "User not present in the system",
                        },
                        ...prevState,
                    ]);
                    continue;
                }

                const userData = _.find(
                    userSearchResult.data,
                    _.matchesProperty("username", username)
                );

                if (userData?.id) {
                    const enrollmentResult = await addLearner({
                        id: course?._id,
                        user_id: userData?.id,
                    });
                    
                    if (notificationConfig.enrolUser.inAppNotification || notificationConfig.enrolUser.emailNotification || course.autoNotification) {
                        if (notificationConfig.enrolUser.inAppNotification && course.autoNotification) {
                            let message = _.pick(
                                notificationConfig.enrolUser,
                                "type",
                                "title",
                                "description",
                                "link"
                            );
                            message.description = _.replace(message.description, "$User", ownerUser?.username);
                            message.description = _.replace(message.description, "$courseName", course?.name);
                            message.created_at = new Date();
                            message.link = "/courses/"+course?._id;
                            triggerInNotification({
                                _id: userData?.id,
                                message: message,
                            });
                        }
                        if (notificationConfig.enrolUser.emailNotification && course.autoNotification) {
                            triggerEmailNotification({
                                _id: userData?.id,
                                route: notificationConfig.enrolUser.route,
                                message: "",
                                emailType: "user_enrollment",
                                course: course,
                                courseAssignor: ownerUser?.username,
                            });
                        }
                    }

                    setProcessingStatus((prevState) => [
                        {
                            username,
                            first_name: userData?.first_name,
                            last_name: userData?.last_name,
                            status: enrollmentResult?.error ? false : true,
                            result: enrollmentResult?.error
                                ? enrollmentResult?.error?.originalStatus ===
                                  422
                                    ? "Already Enrolled"
                                    : enrollmentResult?.error.data
                                : "Enrolled",
                        },
                        ...prevState,
                    ]);
                    await new Promise((resolve) => setTimeout(resolve, 100));
                }
            }
        } catch (error) {
            console.error("Error during multi-user enrollment:", error);
        } finally {
            setMultiEnrollmentLoading(false);
            setMultiEnrollmentComplete(true);
        }
    }

    let processed = _.filter(processingStatus, (user) => user.status);
    let unprocessed = _.filter(processingStatus, (user) => !user.status);

    const downloadCSV = (users, fileName) => {
        let csvContent = "username,first_name,last_name,result\r\n";
        users.forEach((user) => {
            csvContent += `${user.username},${user.first_name},${user.last_name},${user.result}\r\n`;
        });

        const file = new File([csvContent], fileName, {
            type: "text/csv;charset=utf-8",
        });

        FileSaver.saveAs(file);
    };

    const downloadSuccess = () => {
        downloadCSV(processed, "successful.csv");
    };

    const downloadFailure = () => {
        downloadCSV(unprocessed, "unsuccessful.csv");
    };
    // ========================= Render Function =================================
    return (
        <Modal
            size="small"
            open={isOpen || isMultiOpen}
            onClose={() => {
                setOpen(false);
                setMultiOpen(false);
                setSearchFor("");
                setAlreadyEnrolled(null);
                setResults([]);
                setProcessingStatus([]);
                setMultiEnrollmentComplete(false);
            }}
        >
            <Modal.Header>
                {isOpen ? "Enroll User" : "Bulk Enroll Users"}
                <Div small light>
                  User notifications will be sent only if the course is published *  
                </Div>
                <Div float-right clickable>
                    <Icon
                        name="times"
                        onClick={() => {
                            setOpen(false);
                            setMultiOpen(false);
                            setSearchFor("");
                            setAlreadyEnrolled(null);
                            setResults([]);
                            setProcessingStatus([]);
                            setMultiEnrollmentComplete(false);
                        }}
                    />
                </Div>
            </Modal.Header>
            <Modal.Content>
                {isOpen && (
                    <Div>
                        <Div fluid gutter>
                            <Input
                                fluid
                                action={{
                                    color: "teal",
                                    labelPosition: "left",
                                    icon: "search",
                                    content: "Search",
                                    onClick: handleSearchChange,
                                    loading: loading,
                                }}
                                placeholder="Search by username..."
                                value={searchFor}
                                onChange={(e) => setSearchFor(e.target.value)}
                                onKeyPress={handleEnterKeyPress}
                            />
                        </Div>
                        {alreadyEnrolled && (
                            <Message
                                error
                                content={`${alreadyEnrolled} is already enrolled`}
                            />
                        )}
                        {showingResults && (
                            <Div fluid ht="300px" autoOverflow basepad ivory>
                                {results.length > 0 ? (
                                    <List divided>
                                        {results.map((result, idx) => {
                                            return (
                                                <List.Item
                                                    key={idx}
                                                    style={{
                                                        lineHeight: "30px",
                                                    }}
                                                >
                                                    {result.first_name}{" "}
                                                    {result.last_name} (
                                                    {result.username} )
                                                    <Div key={idx} float-right>
                                                        <Button
                                                            size="small"
                                                            primary
                                                            content="Enroll"
                                                            onClick={() =>
                                                                enrollUser(
                                                                    result
                                                                )
                                                            }
                                                        />
                                                    </Div>
                                                </List.Item>
                                            );
                                        })}
                                    </List>
                                ) : (
                                    <Div>No results found...</Div>
                                )}
                            </Div>
                        )}
                    </Div>
                )}
                {isMultiOpen && (
                    <Div flex top-aligned>
                        {!loading && processingStatus.length === 0 && (
                            <Div medpad wd="50%" gutter>
                                <Div large bold>
                                    Step 1: Download Sample File
                                </Div>
                                <Button
                                    fluid
                                    primary
                                    content="Sample File"
                                    href={
                                        settings.baseUrl.endsWith("/")
                                            ? settings.baseUrl +
                                              "public/samples/user_enrolment_format.xlsx"
                                            : settings.baseUrl +
                                              "/public/samples/user_enrolment_format.xlsx"
                                    }
                                />
                            </Div>
                        )}
                        {!loading && processingStatus.length === 0 && (
                            <Div medpad wd="50%" gutter>
                                <Div large bold gutter>
                                    Upload Excel File with Users
                                </Div>
                                <input
                                    type="file"
                                    onChange={handleXLSFileLoad}
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                />
                            </Div>
                        )}

                        {processingStatus.length > 0 && (
                            <Div fluid bgColor="#f5f5f5">
                                <Progress
                                    percent={progress}
                                    indicating
                                    progress
                                />
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={8}>
                                            <Div big bold>
                                                <Icon
                                                    name="download"
                                                    circular
                                                    inverted
                                                    color="yellow"
                                                    onClick={downloadSuccess}
                                                    size="small"
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />{" "}
                                                Successfully Processed
                                            </Div>
                                            <List
                                                divided
                                                style={{
                                                    width: "100%",
                                                    fontSize: "1.25em",
                                                    maxHeight: "15em",
                                                    overflow: "auto",
                                                }}
                                            >
                                                {processed.map((user, idx) => (
                                                    <List.Item
                                                        key={`upload-item-${idx}`}
                                                    >
                                                        {`${user.username}: ${user.result}`}
                                                    </List.Item>
                                                ))}
                                            </List>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <Div big bold>
                                                <Icon
                                                    name="download"
                                                    circular
                                                    inverted
                                                    color="yellow"
                                                    onClick={downloadFailure}
                                                    size="small"
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />{" "}
                                                Unsuccessful Upload
                                            </Div>
                                            <List
                                                divided
                                                style={{
                                                    width: "100%",
                                                    fontSize: "1.25em",
                                                    maxHeight: "15em",
                                                    overflow: "auto",
                                                }}
                                            >
                                                {unprocessed.map(
                                                    (user, idx) => (
                                                        <List.Item
                                                            key={`not-upload-item-${idx}`}
                                                        >
                                                            {`${user.username}: ${user.result}`}
                                                        </List.Item>
                                                    )
                                                )}
                                            </List>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Div>
                        )}
                        {!multiEnrollmentComplete && (
                            <Div fluid>
                                <Button
                                    fluid
                                    primary
                                    content="Enroll Users"
                                    onClick={() => enrollMultiUsers()}
                                    loading={multiEnrollmentLoading}
                                />
                            </Div>
                        )}
                    </Div>
                )}
            </Modal.Content>
        </Modal>
    );
}
