// Import from NPM
// -------------------------------------
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dimmer, Loader, Icon } from "semantic-ui-react";
import _ from "lodash";
import moment from "moment";

// Import from Config
// -------------------------------------
import { apiV6, useGetCourseQuery, useGetSessionsQuery } from "@api/apiV6";
import {
    startCourseAttempt,
    startTopicAttempt,
    startSession,
} from "@reducers/attemptSlice";
import { WrapperList, PickedLocations } from "@schemas/wrapperSchema";
import { deckFormats } from "@schemas/deckFormats";
import { darkStyle } from "@utilities/helpers";
import { settings } from "@config/settings/app.settings";

// Import Components
// -------------------------------------
import { Div } from "@components/Generics.react";
import MasterLayout from "@layouts/MasterLayout.react";
import VisualGame from "./panels/VisualGame.react";
import Deck from "../decks/Deck.react";
import FormalWrapper from "./panels/FormalWrapper.react";
import CourseLeaderboard from "./panels/CourseLeaderboard.react";
import CourseRewards from "./panels/CourseRewards.react";
import ExamWrapper from "./panels/ExamWrapper.react";
import ErrorPage from "@components/ErrorPage.react";

export default function Course(props) {
    const { courseId, courseIdentifier } = useParams();
    const [search] = useSearchParams();
    const [locData, setLocData] = useState([]);
    const [activeLoc, setActiveLoc] = useState(null);
    const [locMenuOpen, setLocMenuOpen] = useState(false);
    const [activeDeck, setActiveDeck] = useState(null);
    const [singleDeckCourse, setSingleDeckCourse] = useState(true);
    const [showRewardsPanel, setShowRewardsPanel] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const attempt = useSelector((state) => state.attempt);
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const hideCourseLeaderboard = settings.functionality.hideCourseLeaderboard;
    const { t } = useTranslation("common");

    const isPortrait = window.innerHeight > window.innerWidth;
    const isPreview = search.get("preview") === "true";

    const {
        data: course,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetCourseQuery({
        id: courseId || courseIdentifier,
        user_id: user._id,
    });
    const { data: sessions } = useGetSessionsQuery(user._id);
    useEffect(() => {
        return () => {
            dispatch(apiV6.util.resetApiState());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (course) {
            updateLocData();
            if (attempt.course !== courseId)
                dispatch(
                    startCourseAttempt({
                        id: courseId,
                        currentState: course.currentState,
                        history: course.history,
                    })
                );
            dispatch(startSession(sessions));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [course, attempt.course, sessions]);

    const contentLength = useMemo(
        () => _.sumBy(course?.locations, (l) => l.contentList.length),
        [course]
    );
    // ------------------------------------------
    // Location Status Mapping
    // ------------------------------------------
    function updateLocData() {
        // Set Maximum Locations allowed in a wrapper
        const maxLocations = 12;
        // Get Course Locations with non-empty contentlist
        const nonEmptyTopics = _.filter(course.locations, (location) => {
            return location.contentList.length > 0;
        });
        // Pick the appropriate location array [1,3,5] based on the number of locations with content in the course
        const locationMap = PickedLocations[nonEmptyTopics.length];

        // Create the location array
        let locData = [];
        let currentFound = false;
        if (locationMap) {
            for (let i = 0; i < maxLocations; i++) {
                let mapIndex = locationMap.indexOf(i); // The index of this node amongst the highlighted locations
                if (mapIndex !== -1) {
                    // If this location is active
                    let location = _.cloneDeep(course.locations[mapIndex]);
                    location.status = location.currentState?.completion
                        ? "complete"
                        : currentFound
                        ? "incomplete"
                        : "current";
                    if (location.status === "current") currentFound = true;
                    locData.push(location);
                    if (location.contentList.length > 1)
                        setSingleDeckCourse(false); // If the location contains more than one deck, its a multideck course
                } else {
                    // If this location is disabled
                    locData.push(null);
                }
            }
        }
        setLocData(locData);
        if (activeLoc == null && locData.length > 0) {
            const currentLoc = locData.indexOf(
                _.find(locData, { _id: course.currentState?.lastPosition })
            );
            const nowActive = currentLoc === -1 ? 0 : currentLoc;
            setActiveLoc(nowActive);
            dispatch(
                startTopicAttempt({
                    id: locData[nowActive]._id,
                    currentState: locData[nowActive].currentState,
                })
            );
        }
        if (course.course_type === "exam") {
            dispatch(
                startTopicAttempt({
                    id: locData[0]._id,
                    currentState: locData[0].currentState,
                })
            );
        }
    }

    // ------------------------------------------
    // On location click
    // ------------------------------------------
    function openLocation(index) {
        const topic = locData[index];
        // When Topic is opened, add topic to the attempt stack
        if (attempt.topic !== topic?._id)
            dispatch(
                startTopicAttempt({
                    id: topic?._id,
                    currentState: topic?.currentState,
                })
            );
        // If the course only has single-deck topics, open deck else open menu
        //    setActiveLoc(index);
        if (singleDeckCourse && isPortrait)
            openDeckView(
                topic?.contentList[0]?._id,
                topic?.contentList[0]?.readerType
            );
        else {
            setActiveLoc(index);
            setLocMenuOpen(true);
        }
    }

    // ------------------------------------------
    // Show and hide deck view
    // ------------------------------------------
    function openDeckView(id, readerType) {
        // setActiveLoc(null);
        setActiveDeck({
            id: id,
            readerType: readerType,
            inPlayer: deckFormats[readerType]?.inPlayer,
        });
    }
    function closeDeckView() {
        setActiveDeck(null);
    }

    function convertToISO(serialDate) {
        const baseDate = moment("1900-01-01");

        // Excel incorrectly treats 1900 as a leap year, so we need to adjust by 1 day for dates after February 28, 1900
        const adjustedSerialDate = serialDate - 2; // Subtract 2 instead of 1 to account for 1-based indexing and the leap year bug

        // Add the serial date to the base date
        const isoDate = baseDate
            .add(adjustedSerialDate, "days")
            .toISOString();
        return isoDate;
    }
    // ------------------------------------------
    // Select Main Panel Component
    // ------------------------------------------
    function checkLock() {
        let locked = false;

        if (course.enable_timing) {
            if (course.vintage != null && course.vintage > 0) {
                const dateOfJoining = convertToISO(user.details.date_of_joining);
                const today = moment(new Date(), "YYYY-MM-DD");
                const daysSince = today.diff(dateOfJoining, "days");
                if (daysSince < course.vintage) {
                    locked = true;
                }
            }
            if (course.dates) {
                const start_date = new Date(course.dates.start_date); // Replace this with your start_date
                const end_date = new Date(course.dates.end_date); // Replace this with your end_date
                const now = new Date();

                if (now < start_date) {
                    locked = true;
                } else if (now > end_date) {
                    locked = true;
                }
            }
        }

        return locked;
    }
    function mainPanel() {
        if (!isPreview && (!course.launch || checkLock())) {
            return (
                <Div fullht fluid flex>
                    <Div wd="360px" center-align big bold superpad ash rounded>
                        <Icon name="lock" size="huge" />
                        <br />
                        <br />
                        {t("course.isInactive")}
                    </Div>
                </Div>
            );
        } else if (!isPreview && contentLength === 0) {
            return (
                <Div fullht fluid flex>
                    <Div wd="360px" center-align big bold superpad ash rounded>
                        <Icon name="battery empty" size="huge" />
                        <br />
                        {t("course.isEmpty")}
                    </Div>
                </Div>
            );
        } else if (activeDeck)
            // If showing deck
            return (
                <Div
                    fluid
                    rounded
                    style={isPortrait ? {} : darkStyle("#666666")}
                >
                    <Deck
                        deckHash={activeDeck}
                        colors={course?.colors}
                        closeDeck={closeDeckView}
                        openDeckView={openDeckView}
                        setIsSaving={setIsSaving}
                        inCourse={true}
                    />
                </Div>
            );
        // If showing course
        else {
            const hasWrapper =
                WrapperList[course?.wrapper] &&
                WrapperList[course?.wrapper].wrapperType !== "formal";
            // If course has a formal wrapper
            if (!hasWrapper)
                if (activeLoc != null || isPortrait) {
                    return course.course_type === "exam" ? (
                        <ExamWrapper
                            course={course}
                            locData={locData}
                            activeLoc={activeLoc}
                            showMenu={locMenuOpen || !isPortrait}
                            closeMenu={() => setLocMenuOpen(false)}
                            openDeck={openDeckView}
                            setActiveLoc={openLocation}
                        />
                    ) : (
                        <FormalWrapper
                            course={course}
                            locData={locData}
                            activeLoc={activeLoc}
                            showMenu={locMenuOpen || !isPortrait}
                            closeMenu={() => setLocMenuOpen(false)}
                            openDeck={openDeckView}
                            setActiveLoc={openLocation}
                        />
                    );
                } else
                    return (
                        <Dimmer active>
                            <Loader>Updating Data...</Loader>
                        </Dimmer>
                    );
            // If course has a construct weapper
            if (hasWrapper?.constructWrapper) return <div />;
            // If course has a visual weapper
            return (
                <VisualGame
                    course={course}
                    locData={locData}
                    activeLoc={activeLoc}
                    showMenu={locMenuOpen}
                    closeMenu={() => setLocMenuOpen(false)}
                    openDeck={openDeckView}
                    setActiveLoc={openLocation}
                />
            );
        }
    }
    // ------------------------------------------
    // Select Main Panel Component
    // ------------------------------------------
    function rightPanel() {
        if (showRewardsPanel) {
            return (
                <Div fullht>
                    <CourseRewards course={course} />
                    <Div
                        center-align
                        className="alt-flip-button"
                        onClick={() => setShowRewardsPanel(false)}
                    >
                        Leaderboard
                    </Div>
                </Div>
            );
        } else {
            return (
                <Div fullht>
                    <CourseLeaderboard course={course} />
                    <Div
                        center-align
                        className="alt-flip-button"
                        onClick={() => setShowRewardsPanel(true)}
                    >
                        See Rewards
                    </Div>
                </Div>
            );
        }
    }
    // ========================= Render Function =================================
    if (isLoading) return <Loader active />;
    if (isSaving) return <Loader active>Saving Data...</Loader>;
    if (isError) return <ErrorPage errorMsg={error.data.response} />;
    if (isSuccess)
        return (
            <MasterLayout
                title={course.name}
                description={course.description}
                image={course.image}
                openDeckView={openDeckView}
                mainPanel={mainPanel()}
                rightPanelName={t("course.scoreboard.triggerText")}
                rightPanel={!hideCourseLeaderboard && course.launch ? rightPanel() : <Div />}
                rootPage={false}
                closeDeck={activeDeck != null ? closeDeckView : null}
                hidePanelToggle={hideCourseLeaderboard || activeDeck != null}
                bottomBuffer={activeDeck == null}
                startFullScreen={!isPortrait && hideCourseLeaderboard}
            />
        );
    return <div />;
}
