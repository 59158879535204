// Import from NPM
// -------------------------------------
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Tab, Menu, Progress, Image } from "semantic-ui-react";

// Import from Config
// -------------------------------------
import { darkStyle } from "@utilities/helpers";
import "@styles/layouts.scss";
import MasterLayout from "@layouts/MasterLayout.react";
import { useGetCollectiblesQuery } from "@api/apiV6";
import { settings } from "@config/settings/app.settings";

// Import Components
// -------------------------------------
import { Div } from "@components/Generics.react";
import Badge from "@components/Badge.react";
import Certificate from "@components/Certificate.react";
import Carousel from "@components/Carousel.react";
import Reward from "@components/Reward.react";
import RecommendedArticles from "@components/RecommendedArticles.react";

export default function AppCollectibles(props) {
    const user = useSelector((state) => state.auth.user);
    const points = useSelector(
        (state) => state.attempt?.learnerState?.currentState?.points
    );

    const { data: collectibles, isSuccess } = useGetCollectiblesQuery(user._id);
    const { t } = useTranslation("common");

    const isPortrait = window.innerHeight > window.innerWidth;

    const levels = settings.gamification.levels;

    function findLevel(arr, number) {
        if (!number) number = 0;
        const result = _.find(
            arr,
            ({ min, max }) => number >= min && number <= max
        );
        if (result) {
            const range = result.max - result.min;
            const percentage = ((number - result.min) / range) * 100;
            return { name: result.name, percentage, rank: result.rank };
        }
        return null;
    }

    // ------------------------------------------
    // Collectibles Pane Mapping
    // ------------------------------------------
    function showCollectibles(readerType, Collectible) {
        return _.map(
            _.filter(collectibles, { readerType: readerType }),
            (elm, i) => {
                return (
                    <Div
                        inline
                        className="small"
                        key={`${readerType}-reward-${i}`}
                        large={readerType === "certdeck"}
                    >
                        <Collectible deck={elm?.blob} disabled={false} small />
                    </Div>
                );
            }
        );
    }
    const panes = [
        {
            menuItem: (
                <Menu.Item key="badges">{t(`course.rewards.badges`)}</Menu.Item>
            ),
            render: () => (
                <Tab.Pane>
                    <Div
                        wd="90%"
                        centered
                        center-aligned
                        ht="280px"
                        autoOverflowY
                    >
                        {showCollectibles("badgedeck", Badge)}
                    </Div>
                </Tab.Pane>
            ),
        },
        {
            menuItem: (
                <Menu.Item key="certificates">
                    {t(`course.rewards.certificates`)}
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane>
                    <Carousel dots arrows>
                        {showCollectibles("certdeck", Certificate)}
                    </Carousel>
                </Tab.Pane>
            ),
        },
        {
            menuItem: (
                <Menu.Item key="rewards">
                    {t(`course.rewards.rewards`)}
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane>
                    <Carousel dots arrows>
                        {showCollectibles("rewarddeck", Reward)}
                    </Carousel>
                </Tab.Pane>
            ),
        },
    ];

    const levelDetails = findLevel(levels.intervals, points);
    const rewardsPanel = (
        <Div
            fullht
            fluid
            topRounded
            padded
            small
            style={darkStyle(user.branding?.colors?.main || settings.colors.main)}
        >
            <br />
            <Div
                fit-content
                large
                centered
                relaxed
                circled
                style={darkStyle("#666666")}
            >
                {t("appCollectibles.header")}
            </Div>
            <Div center-align centered wd={"100px"}>
                <Image
                    fluid
                    centered
                    src={`/assets/images/configurable/levels/${levelDetails.rank}.png`}
                />
            </Div>
            <Div large altText center-align>
                <Div altText txtPrimary>
                    {Math.round(points) || 0} {t("appCollectibles.points")}
                </Div>
                <Div fit-content centered gapTop>
                    {t("appCollectibles.level")} {levelDetails.rank}:{" "}
                    <Div uppercase inline txtPrimary>
                        {t(`levels.${levelDetails.rank}`)}
                    </Div>
                </Div>
                <Div relaxed>
                    <Progress percent={levelDetails.percentage} indicating />
                </Div>
            </Div>
            {isSuccess && (
                <Div style={{ marginTop: "-15px" }}>
                    <Tab
                        className="welcome-kit"
                        menu={{ widths: 3 }}
                        panes={panes}
                    />
                </Div>
            )}
        </Div>
    );

    const mainPanel = (
        <Div fullht>
            <Div wd={"calc(100% - 375px)"} fullht float-left>
                <Div white superpad spaced rounded>
                    <Div massive altText padTop uppercase txtCharcoal gutter>
                        {t("appCollectibles.helpHeader")}
                    </Div>
                    <Div padTop>{t("appCollectibles.help")}</Div>
                    <Div padTop small>
                        <ul>
                            {_.map(
                                t("appCollectibles.helpPoints").split("||"),
                                (pointer, idx) => {
                                    return (
                                        <li key={`pointer-${idx}`}>
                                            {pointer}
                                        </li>
                                    );
                                }
                            )}
                        </ul>
                    </Div>
                    <Div padTop>{t("appCollectibles.helpBottom")}</Div>
                </Div>
            </Div>
            <Div wd={"360px"} fullht float-right>
                {rewardsPanel}
            </Div>
        </Div>
    );

    // ========================= Render Function =================================
    return (
        <MasterLayout
            title={"Welcome"}
            mainPanel={isPortrait ? rewardsPanel : mainPanel}
            rightPanelName={t(`components.recommended`)}
            rightPanel={<RecommendedArticles />}
            rootPage={true}
        />
    );
}
