import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import { Div, Icon } from "@components/Generics.react";
import "@styles/components.scss";

export default function Banner(props) {
    const { ads } = props; // ads: {name, description, image, link}
    const [sequence, setSequence] = useState(0);
    const speed = 5000;

    useEffect(() => {
        ads.forEach((picture) => {
            const img = new Image();
            img.src = picture.image;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ads]);

    useEffect(() => {
        const carouselTimer = setTimeout(() => {
            setSequence((sequence + 1) % ads.length);
        }, speed);
        return () => clearInterval(carouselTimer);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sequence, ads.length]);

    const sortedAds = [...ads].sort((a, b) => b.priority - a.priority);
    // ========================= Render Function =================================
    return (
        <Div fluid midnight rounded noOverflow relative className="banner">
            <Div relative className="banner-img">
                {sortedAds[sequence].image && (
                    <Div className="resp-banner-img-container">
                        <img
                            src={sortedAds[sequence].image}
                            className="fade-in-image"
                            alt={sortedAds[sequence].name}
                        />
                    </Div>
                )}
                {sortedAds[sequence].name && (
                    <Div
                        charcoal
                        bold
                        fit-content
                        small
                        className="banner-title"
                    >
                        {sortedAds[sequence].name}
                    </Div>
                )}
                {ads.length > 1 && (
                    <Div className="dots">
                        {_.map(sortedAds, (elm, n) => (
                            <Div
                                key={`dot-${n}`}
                                className={`dot ${n === sequence && "active"}`}
                                onClick={() => setSequence(n)}
                            />
                        ))}
                    </Div>
                )}
            </Div>
            {sortedAds[sequence].description && (
                <Div minHt="80px" small bottomRounded half basepad>
                    {sortedAds[sequence].description}
                </Div>
            )}
            {sortedAds[sequence].link && sortedAds[sequence].link.includes("http") && (
                <Div className="banner-link">
                    <a
                        href={sortedAds[sequence].link}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Icon
                            name="chevron right"
                            size="large"
                            rounded
                            inverted
                            primary
                            padded
                        />
                    </a>
                </Div>
            )}
            {sortedAds[sequence].link && !sortedAds[sequence].link.includes("http") && (
                <Div className="banner-link">
                    <NavLink to={sortedAds[sequence].link}>
                        <Icon
                            name="chevron right"
                            size="large"
                            rounded
                            inverted
                            primary
                            padded
                        />
                    </NavLink>
                </Div>
            )}
        </Div>
    );
}