// Import from NPM
// -------------------------------------
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import { Div } from "@components/Generics.react";
export default function DateField(props) {
    const {
        target,
        header,
        help,
        value,
        setValue,
        height,
        width,
        showTime = false,
    } = props;

    const autoWidth = width == null;

    // ========================= Render Function =================================
    return (
        <Div minHt={height} ht={height} basepad ivory snug flex>
            <Div big rimmed wd={autoWidth ? "50%" : "100%"}>
                {header}
                {help && (
                    <Div tiny italics txtHalf gapSlice>
                        {help}
                    </Div>
                )}
            </Div>
            <Div
                smoke
                large={autoWidth}
                wd={autoWidth ? "50%" : "100%"}
                right-align={autoWidth}
                className={!autoWidth && "dp-stacked"}
            >
                <DatePicker
                    dateFormat={
                        showTime ? "dd MMMM yyyy h:mm a" : "dd MMMM yyyy"
                    }
                    selected={
                        value && value !== ""
                            ? target
                                ? moment(value[target]).toDate()
                                : moment(value).toDate()
                            : null
                    }
                    onChange={(date) =>
                        setValue(target ? { [target]: date } : date)
                    }
                    showTimeSelect={showTime}
                />
            </Div>
        </Div>
    );
}
