import React from "react";
import { Div } from "@components/Generics.react";
import { useTranslation } from "react-i18next";
import RadialProgressChart from "@components/charts/RadialProgressChart.react";

export default function CompletionRadials(props) {
    const { user, aggregateData, size = 80 } = props;
    const { t } = useTranslation("common");

    return (
        <Div flex column flexStart top-align center-align padSides>
            <Div fluid gutter>
                <RadialProgressChart
                    value={parseInt(
                        (aggregateData.accessed_by * 100) /
                            aggregateData.enrolled_team,
                        0
                    )}
                    width={size}
                    height={size}
                    color={user.branding?.colors?.main}
                />
                <Div uppercase gapTop>
                    {t("analytics.accessedBy")}
                </Div>
            </Div>
            <Div fluid gutter>
                <RadialProgressChart
                    value={parseInt(
                        (aggregateData.completed_by * 100) /
                            aggregateData.enrolled_team,
                        0
                    )}
                    width={size}
                    height={size}
                    color={user.branding?.colors?.main}
                />
                <Div uppercase gapTop>
                    {t("analytics.completedBy")}
                </Div>
            </Div>
            <Div fluid gutter>
                <RadialProgressChart
                    value={parseInt(aggregateData.average_completion, 0)}
                    width={size}
                    height={size}
                    color={user.branding?.colors?.main}
                />
                <Div uppercase gapTop>
                    {t("analytics.averCompletion")}
                </Div>
            </Div>
        </Div>
    );
}
