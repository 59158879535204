// Import from NPM
// -------------------------------------
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import axios from "axios";
import { baseUrl, useGetCreditsQuery } from "@api/apiV6";

import { Div, HDiv, Button } from "@components/Generics.react";
import {
    Icon,
    Modal,
    Dimmer,
    Loader,
    Divider,
    Dropdown,
} from "semantic-ui-react";
import { settings } from "@config/settings/app.settings";

export default function AITranslator(props) {
    const { modalOpen, handleModalClose, handleSave, inputContent } = props;
    const token = useSelector((state) => state.auth.token);
    const { data: aiStatus, isSuccess, refetch } = useGetCreditsQuery();

    const { t } = useTranslation("common");

    const [langValue, setLangValue] = useState("English");
    const [fetching, setFetching] = useState(false);
    const [resultValue, setResultValue] = useState("");

    const creditsNeeded = Math.ceil(inputContent.length / 300);

    async function getAIResponse() {
        setFetching(true);
        const response = await axios({
            method: "post",
            url: `${baseUrl}api/v3/ai/generic`,
            data: {
                question: `Translate into ${langValue} and return as properly formatted html:  ${inputContent}`,
                cost: creditsNeeded,
            },
            headers: { "access-token": token },
        });
        setResultValue(response.data.response.message.content);
        setFetching(false);
        refetch();
    }

    // ========================= Render Function =================================
    return (
        <Modal
            open={modalOpen}
            onClose={() => handleModalClose(false)}
            size="tiny"
        >
            <Modal.Header>
                <Icon name="language" />
                Translate content with AI
                <Div float-right clickable>
                    <Icon
                        name="times"
                        onClick={() => handleModalClose(false)}
                    />
                </Div>
            </Modal.Header>
            {isSuccess && (
                <Modal.Content>
                    {!fetching && resultValue === "" && (
                        <Div>
                            <Div gutter>
                                <Div bold gutter>
                                    Translate:
                                </Div>
                                <HDiv medpad ash content={inputContent} />
                                <Divider horizontal>into language</Divider>
                                <Dropdown
                                    placeholder={t(
                                        "builder.dic.selectLanguage"
                                    )}
                                    fluid
                                    selection
                                    options={_.map(settings.languages, (l) => ({
                                        key: l.code,
                                        text: l.identifier,
                                        value: l.identifier,
                                    }))}
                                    value={langValue}
                                    onChange={(e, { value }) =>
                                        setLangValue(value)
                                    }
                                />
                            </Div>
                            <Button
                                disabled={aiStatus.credits <= 0}
                                primary
                                onClick={getAIResponse}
                            >
                                Translate ({creditsNeeded}{" "}
                                {creditsNeeded === 1 ? "credit" : "credits"})
                            </Button>
                            <Div italics float-right ht="40px" flex>
                                {aiStatus.credits} generative credits left
                            </Div>
                        </Div>
                    )}
                    {fetching && (
                        <Div superpad ivory>
                            <Dimmer active>
                                <Loader>
                                    This will take time. Please be patient...
                                </Loader>
                            </Dimmer>
                        </Div>
                    )}
                    {resultValue !== "" && (
                        <>
                            <Div basepad ivory>
                                <Div bold gutter>
                                    Translated:
                                </Div>
                                <HDiv content={resultValue} />
                            </Div>
                            <Button
                                fluid
                                primary
                                content="Generate again"
                                onClick={() => setResultValue("")}
                            />
                        </>
                    )}
                </Modal.Content>
            )}
            {resultValue !== "" && (
                <Modal.Actions>
                    <Div float-left>
                        <Button
                            danger={+true}
                            onClick={() => handleModalClose(false)}
                            content="Cancel"
                            icon="delete"
                            labelPosition="left"
                        />
                    </Div>
                    <Button
                        primary
                        content="Save"
                        icon="checkmark"
                        labelPosition="right"
                        onClick={() => {
                            handleSave(resultValue);
                            handleModalClose(false);
                        }}
                    />
                </Modal.Actions>
            )}
        </Modal>
    );
}
