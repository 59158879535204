// Import from NPM
// -------------------------------------
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dropdown, Label, Icon as XIcon, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFeatures } from "flagged";

// Import from Config
// -------------------------------------
import {
    apiV6,
    useSendTrackerMutation,
    useSendErrorsMutation,
    useGetSessionsQuery,
    useGetUnreadCountQuery,
    useSendUserSelfUpdateMutation,
} from "@api/apiV6";

import { trackClear } from "@reducers/trackerSlice";
import { clearAttempt } from "@reducers/attemptSlice";

import { darkStyle, text_truncate } from "@utilities/helpers";
import { Div, Icon, Button } from "@components/Generics.react";
import "@styles/navbars.scss";
import { settings } from "@config/settings/app.settings";

// Import Components
// -------------------------------------
import UserAvatar from "../UserAvatar.react";
import SearchField from "../SearchField.react";
import Notifications from "./Notifications.react";
import LogoBlock from "./LogoBlock.react";
import AppName from "./AppName.react";
import NavDropdown from "./NavDropdown.react";

export default function Navbar(props) {
    const { logo, name, rootPage = false, isolated = false, closeDeck } = props;
    const user = useSelector((state) => state.auth.user);
    const tracker = useSelector((state) => state.tracker);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const features = useFeatures();
    const { t } = useTranslation("common");
    const [updateProfile] = useSendUserSelfUpdateMutation();

    const stages = settings.gamification.stages;
    const username = `${user?.first_name} ${user?.last_name}`;
    const isPortrait = window.innerWidth < window.innerHeight;
    const isIframe = window.self !== window.top;
    const userStage = user ? settings.home[user?.stage || stages.length] : 1;

    const [notifications, setNotifications] = useState(false);
    const [searchShow, setSearchShow] = useState(false);
    const { data: unread } = useGetUnreadCountQuery(user?._id);
    //---------------------------------------------------------------------------
    // Sync Data with server when the tracker is set to pushable or when called manually. Also sync sessions.
    // Please Note: This makes the Navbar a control element. It must always be included in all pages.
    //---------------------------------------------------------------------------
    useGetSessionsQuery(user?._id);
    const [sendTracker] = useSendTrackerMutation();
    const [sendErrors] = useSendErrorsMutation();

    useEffect(() => {
        (async () => {
            if (tracker.pushable) await syncData();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tracker.pushable]);

    async function syncData() {
        try {
            await sendTracker({ trackers: tracker }).unwrap();
            await dispatch(trackClear());
            await dispatch(clearAttempt());
        } catch (err) {
            console.error(`Failed to send trackers to server. Reason: ${err}`);
            await sendErrors({ trackers: tracker }).unwrap();
            await dispatch(trackClear());
            await dispatch(clearAttempt());
        }
    }
    async function resyncData() {
        try {
            await syncData();
            await dispatch(apiV6.util.resetApiState());
            await dispatch(apiV6.endpoints.getArticles.initiate());
            await dispatch(apiV6.endpoints.getCourseLibrary.initiate());
            // navigate("/");
            toast("Progress updated to server and content refreshed!");
        } catch (err) {
            console.error(`Failed to send trackers to server. Reason: ${err}`);
        }
    }
    async function handleUpdateLanguage(lang) {
        try {
            await updateProfile({
                id: user?._id,
                lang: lang,
            });
        } catch (error) {
            console.log(error);
        }
    }
    // ========================= Render Function =================================
    return (
        <Div
            fluid
            className="qd-nav"
            style={isPortrait && darkStyle(user.branding?.colors?.main || settings.colors.main)}
        >
            <Div fullht className="nav-inner" padded={!isPortrait}>
                {isPortrait && !rootPage && !isIframe && (
                    <Icon
                        name="chevron left"
                        large
                        float-left
                        fitted
                        padTop
                        onClick={() => (closeDeck ? closeDeck() : navigate(-1))}
                    />
                )}
                <LogoBlock {...{ isolated, user, logo }} />
                <AppName
                    {...{
                        isolated,
                        user,
                        logo,
                        rootPage,
                        closeDeck,
                        appName: name || "TeachiQuo",
                    }}
                />

                {(user.license.termsAccepted ||
                    !features.general.acceptTerms) &&
                    !isIframe && (
                        <Div float-right small={window.innerWidth < 360}>
                            {!isPortrait &&
                                !isolated &&
                                userStage &&
                                !userStage.hideSearch && (
                                    <Div inlineBlock nudgeRight>
                                        <SearchField />
                                    </Div>
                                )}
                            <Div inlineBlock padTop fitted>
                                {!isolated && isPortrait && (
                                    <Div inlineBlock big fitted clickable>
                                        <XIcon
                                            name="search"
                                            onClick={() => setSearchShow(true)}
                                        />
                                    </Div>
                                )}
                                {!isPortrait &&
                                    settings.languages &&
                                    settings.languages.length > 1 && (
                                        <Dropdown
                                            inline
                                            trigger={
                                                <Div nudgeRight>
                                                    <Icon name="language" />
                                                </Div>
                                            }
                                            className="notification-icon"
                                            direction="left"
                                            scrolling
                                        >
                                            <Dropdown.Menu>
                                                {_.map(
                                                    settings.languages,
                                                    (lang, langIdx) => {
                                                        return (
                                                            <Dropdown.Item
                                                                key={`lang-${langIdx}`}
                                                                onClick={() =>
                                                                    handleUpdateLanguage(
                                                                        lang.code
                                                                    )
                                                                }
                                                            >
                                                                <Div wd="150px">
                                                                    {lang.name}{" "}
                                                                    <Icon
                                                                        name="chevron right"
                                                                        float-right
                                                                    />
                                                                </Div>
                                                            </Dropdown.Item>
                                                        );
                                                    }
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    )}
                                {!isolated && (
                                    <Dropdown
                                        inline
                                        trigger={
                                            <Div>
                                                <Icon name="bell" />
                                                {unread?.count > 0 && (
                                                    <Label
                                                        color="blue"
                                                        size="mini"
                                                        floating
                                                    >
                                                        {unread?.count}
                                                    </Label>
                                                )}
                                            </Div>
                                        }
                                        className="notification-icon"
                                        direction="left"
                                        scrolling
                                        onClick={() => setNotifications(true)}
                                        onClose={() => setNotifications(false)}
                                    >
                                        <Dropdown.Menu>
                                            {notifications ? (
                                                <Notifications />
                                            ) : (
                                                <Div />
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                )}
                                {!isPortrait && (
                                    <Div
                                        inlineBlock
                                        normalSize
                                        clickable
                                        className="notification-icon"
                                        nudgeRight
                                    >
                                        <Icon
                                            name="sync"
                                            onClick={resyncData}
                                        />
                                    </Div>
                                )}
                                <span className="username">
                                    {text_truncate(username, 18)}
                                </span>
                                <UserAvatar
                                    name={username}
                                    image={user?.image}
                                    avatarConfig={user?.avatarConfig}
                                    size={32}
                                />
                                <NavDropdown
                                    {...{ isolated, user, resyncData }}
                                />
                            </Div>
                        </Div>
                    )}
            </Div>
            <Modal
                basic
                onClose={() => setSearchShow(false)}
                open={searchShow}
                size="small"
                style={{ textAlign: "center" }}
            >
                <Modal.Header>{t("navbar.searchEverywhere")}</Modal.Header>
                <Modal.Content>
                    <Div centered gutter>
                        <SearchField />
                    </Div>
                    <Button inverted onClick={() => setSearchShow(false)}>
                        {t("components.cancel")}
                    </Button>
                </Modal.Content>
            </Modal>
        </Div>
    );
}
