import React from "react";
import _ from "lodash";
import {
    BarChart as QBarChart,
    ResponsiveContainer,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
} from "recharts";

export default function BarChart(props) {
    const {
        height = 200,
        data,
        series,
        skipXlabels = false,
        customTooltip = null,
    } = props;

    return (
        <ResponsiveContainer width="96%" height={height}>
            <QBarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="name"
                    label={{
                        value: _.startCase(series[0].dataKey),
                        position: "bottom",
                    }}
                    hide={skipXlabels}
                />
                <YAxis />
                {customTooltip !== null ? (
                    <Tooltip content={customTooltip} />
                ) : (
                    <Tooltip />
                )}

                {series.map((ds, index) => (
                    <Bar
                        dataKey={ds.dataKey}
                        fill={ds.fill}
                        key={"bar-" + index}
                    />
                ))}
            </QBarChart>
        </ResponsiveContainer>
    );
}

// Data Format
// -------------------------------
/*
    <BarChart
        data={[
            {
                name: "Page A",
                uv: 4000,
                pv: 2400,
            },
        ]}
        series={[
            {
                dataKey: "uv",
                fill: "#ffbf00",
            },
            {
                dataKey: "pv",
                fill: "#ffbf00",
            },
        ]}
    />;
*/
