import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import "@styles/readers.scss";
import { useTranslation } from "react-i18next";

import { Div, HDiv, Button } from "@components/Generics.react";
import Accordion from "@components/Accordion.react";
import { Image } from "semantic-ui-react";
import Leaderboard from "@components/Leaderboard.react";
export default function ChallengeReader(props) {
    const { deck, recordAttempt } = props;
    const [showFront, setShowFront] = useState(true);
    const imageRef = useRef(null);
    const { t } = useTranslation("common");

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let panels = _.map(deck?.content?.sections, (item, idx) => {
        return {
            title: <Div small>{item?.title}</Div>,
            content: (
                <Div>
                    <HDiv content={item?.description} />
                    <br />
                    {item?.button &&
                        (_.includes(item?.button.btnLink, "http") ? (
                            <a
                                href={item?.button.btnLink}
                                target="_blank"
                                rel="noreferrer"
                                key={`offer-link-${idx}`}
                            >
                                <Button
                                    primary
                                    fluid
                                    content={item?.button.text}
                                />
                            </a>
                        ) : (
                            <a
                                href={item?.button.btnLink}
                                key={`offer-link-${idx}`}
                            >
                                <Button
                                    primary
                                    fluid
                                    content={item?.button.text}
                                />
                            </a>
                        ))}
                </Div>
            ),
        };
    });
    // ========================= Render Function =================================
    return (
        <Div fluid fullht minHt="600px" ash>
            <Div className="challenge-card" clickable>
                <Div className={`flip-card ${showFront ? "" : "flipped"}`}>
                    <Div className="flip-card-inner" rounded>
                        <Div className="flip-card-front">
                            <Div relative topRounded noOverflow>
                                <div ref={imageRef}>
                                    <Image fluid src={deck?.content?.image} />
                                </div>
                                <Div
                                    midnight
                                    centered
                                    center-align
                                    bold
                                    uppercase
                                    basepad
                                    large
                                >
                                    {deck?.content?.name}
                                </Div>
                                <HDiv
                                    centered
                                    center-align
                                    half
                                    bold
                                    basepad
                                    content={deck?.content?.description}
                                />
                                <Div medpad ash>
                                    <Button
                                        primary
                                        fluid
                                        content="View Leaderboard"
                                        onClick={() => setShowFront(!showFront)}
                                    />
                                </Div>
                            </Div>
                            <Div
                                fluid
                                ht={
                                    imageRef.current
                                        ? `calc(100% - ${imageRef.current?.clientHeight}px)`
                                        : "calc(100% - 140px)"
                                }
                                relative
                                big
                            >
                                <Accordion panels={panels}></Accordion>
                            </Div>
                        </Div>
                        <Div
                            main
                            big
                            className="flip-card-back"
                            fullht
                            onClick={() => setShowFront(true)}
                        >
                            <Div
                                fluid
                                maxWd="400px"
                                centered
                                smoke
                                rounded
                                center-align
                            >
                                <Leaderboard
                                    leaderboardData={_.orderBy(
                                        _.map(
                                            deck?.content?.leaderboard,
                                            (l, idx) => {
                                                if (l.name) {
                                                    const nameSplit =
                                                        l.name.split(" ");
                                                    return {
                                                        _id: "leader-" + idx,
                                                        first_name:
                                                            nameSplit[0],
                                                        last_name:
                                                            nameSplit[1] || "",
                                                        points: l.points,
                                                        image: l.image,
                                                    };
                                                } else {
                                                    return {
                                                        _id: "leader-" + idx,
                                                        first_name: "",
                                                        last_name: "",
                                                        points: 0,
                                                    };
                                                }
                                            }
                                        ),
                                        ["points"],
                                        ["desc"]
                                    )}
                                    header={t("appLeaderboard.header")}
                                    isLoading={false}
                                    isError={false}
                                    isSuccess={true}
                                    error={null}
                                />
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Div>
    );
}
