import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFeatures } from "flagged";
import _ from "lodash";
import { toast } from "react-toastify";

import {
    useGetBrandingQuery,
    useSendBrandingMutation,
    useGetTeamsListQuery,
} from "@api/apiV6";

import { Div, Button } from "@components/Generics.react";
import Carousel from "@components/Carousel.react";
import { Input, Image, Checkbox, Dropdown } from "semantic-ui-react";
import ImageUploader from "@components/ImageUploader.react";
import ColorBlock from "@components/ColorBlock.react";
import { darkStyle } from "@utilities/helpers";

export default function AdminTeamDesign(props) {
    const { team, closePanel } = props;
    const { data: branding, isSuccess } = useGetBrandingQuery(team);
    const { data: teamsList, isSuccess: listSuccess } = useGetTeamsListQuery();

    const [sendBranding] = useSendBrandingMutation();
    const features = useFeatures();

    const [logoEditOpen, setLogoEditOpen] = useState(false);
    const [slogoEditOpen, setSlogoEditOpen] = useState(false);
    const [welcomeEditOpen, setWelcomeEditOpen] = useState(false);
    const [welcomePages, setWelcomePages] = useState([
        { image: null, header: "", content: "" },
    ]);

    const [appName, setAppName] = useState("");
    const [logo, setLogo] = useState(null);
    const [slogo, setSlogo] = useState(null);
    const [mainColor, setMainColor] = useState("#2288bb");
    const [accentColor, setAccentColor] = useState("#ffbf00");
    const [textColor, setTextColor] = useState("#ffffff");
    const [shareable, setShareable] = useState(false);
    const [allocatedTeams, setAllocatedTeams] = useState([]);
    const [targetImg, setTargetImg] = useState(null);

    const { t } = useTranslation("common");
    const wPageHt = `${window.innerHeight - 280}px`;

    useEffect(() => {
        if (isSuccess && branding) {
            setAppName(branding.name);
            setLogo(branding.image);
            setSlogo(branding.logo);
            setMainColor(branding.colors?.main);
            setAccentColor(branding.colors?.primary);
            setShareable(branding.shareable);
            setAllocatedTeams(branding.repos || []);
            setWelcomePages(
                branding.cards.length < 1
                    ? [{ image: null, header: "", content: "" }]
                    : branding.cards
            );
        }
    }, [branding, isSuccess]);

    function addWelcomePage() {
        setWelcomePages([
            ...welcomePages,
            { image: null, header: "", content: "" },
        ]);
    }

    function removeWelcomePage(idx) {
        let newWelcomePages = _.cloneDeep(welcomePages);
        newWelcomePages[idx] = undefined;
        setWelcomePages(newWelcomePages.filter(Boolean));
    }
    function editWelcomePage(idx, target, value) {
        let newWelcomePages = _.cloneDeep(welcomePages);
        newWelcomePages[idx][target] = value;
        setWelcomePages(newWelcomePages);
    }

    async function saveBranding() {
        await sendBranding({
            id: branding._id,
            name: appName,
            image: logo,
            logo: slogo,
            colors: {
                main: mainColor,
                primary: accentColor,
            },
            cards: !features.general.noWelcome ? welcomePages : undefined,
            shareable: shareable,
            repos: allocatedTeams,
        });
        toast("Updated App Branding");
    }

    const mainPanel = (
        <Div wd="calc(100% - 360px)" fullht float-left>
            <Div ash medpad fullht gutter>
                <Div white medpad fullht autoOverflow>
                    <Div gutter flex spaceBetween>
                        <Div wd="60%">
                            <Input
                                fluid
                                label="App Name"
                                labelPosition="left"
                                placeholder="Enter the app name here"
                                value={appName}
                                onChange={(e) => setAppName(e.target.value)}
                            />
                        </Div>
                        <Div wd="30%" nudgeLeft>
                            <Checkbox
                                toggle
                                label="Make Shareable Repo"
                                checked={shareable}
                                onChange={() => setShareable(!shareable)}
                            />
                        </Div>
                    </Div>
                    <Div vapor medpad gutter>
                        <Div bold gapBottom rimmed>
                            Branding Setup
                        </Div>
                        <Div fluid flex spaceBetween gapBottom>
                            <Div wd="calc(50% - 5px)" medpad white>
                                <Button
                                    fluid
                                    primary
                                    content="Upload Square Logo"
                                    onClick={() => setSlogoEditOpen(true)}
                                />
                                <ImageUploader
                                    modalOpen={slogoEditOpen}
                                    handleModalClose={() =>
                                        setSlogoEditOpen(false)
                                    }
                                    handleModalSave={(img) => {
                                        setSlogo(img);
                                        setSlogoEditOpen(false);
                                    }}
                                    value={slogo}
                                    aspectRatio={1}
                                    mWidth={300}
                                />
                            </Div>
                            <Div wd="calc(50% - 5px)" medpad white>
                                <Button
                                    fluid
                                    primary
                                    content="Upload Full Logo"
                                    onClick={() => setLogoEditOpen(true)}
                                />
                                <ImageUploader
                                    modalOpen={logoEditOpen}
                                    handleModalClose={() =>
                                        setLogoEditOpen(false)
                                    }
                                    handleModalSave={(img) => {
                                        setLogo(img);
                                        setLogoEditOpen(false);
                                    }}
                                    value={logo}
                                    aspectRatio={0.33}
                                    mWidth={640}
                                />
                            </Div>
                        </Div>
                        <Div white fluid medpad flex spaceBetween>
                            <Div wd="200px">Colors:</Div>
                            <Div
                                flex
                                padSides
                                spaceBetween
                                wd="calc(100% - 200px)"
                            >
                                <ColorBlock
                                    color={mainColor}
                                    colorName={"Main Color"}
                                    onChange={(name, colorPick) =>
                                        setMainColor(colorPick.hex)
                                    }
                                />
                                <ColorBlock
                                    color={textColor}
                                    colorName={"Text Color"}
                                    onChange={(name, colorPick) =>
                                        setTextColor(colorPick.hex)
                                    }
                                />
                                <ColorBlock
                                    color={accentColor}
                                    colorName={"Accent Color"}
                                    onChange={(name, colorPick) =>
                                        setAccentColor(colorPick.hex)
                                    }
                                />
                            </Div>
                        </Div>
                    </Div>
                    {!features.general.noWelcome && (
                        <Div vapor medpad gutter>
                            <Div bold gapBottom rimmed>
                                Welcome Pages
                            </Div>
                            <Div fluid gapBottom>
                                {_.map(welcomePages, (page, idx) => {
                                    return (
                                        <Div
                                            rimmed
                                            white
                                            flex
                                            spaceBetween
                                            key={`editor-${idx}`}
                                        >
                                            <Div wd="130px">
                                                <Button
                                                    fluid
                                                    primary
                                                    content="Upload Image"
                                                    onClick={() => {
                                                        setTargetImg(idx);
                                                        setWelcomeEditOpen(
                                                            true
                                                        );
                                                    }}
                                                />
                                            </Div>
                                            <Div wd="250px">
                                                <Input
                                                    fluid
                                                    label="Header"
                                                    labelPosition="left"
                                                    placeholder="Enter the page header"
                                                    value={page.header}
                                                    onChange={(e) =>
                                                        editWelcomePage(
                                                            idx,
                                                            "header",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </Div>
                                            <Div wd="calc(100% - 440px)">
                                                <Input
                                                    fluid
                                                    label="Content"
                                                    labelPosition="left"
                                                    placeholder="Enter the page content"
                                                    value={page.content}
                                                    onChange={(e) =>
                                                        editWelcomePage(
                                                            idx,
                                                            "content",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </Div>
                                            <Div wd={"40px"}>
                                                <Button
                                                    disabled={
                                                        welcomePages.length < 2
                                                    }
                                                    fluid
                                                    danger={+true}
                                                    icon="trash"
                                                    onClick={() =>
                                                        removeWelcomePage(idx)
                                                    }
                                                />
                                            </Div>
                                        </Div>
                                    );
                                })}
                                {targetImg != null && (
                                    <ImageUploader
                                        key={`editor-img-${targetImg}`}
                                        modalOpen={welcomeEditOpen}
                                        handleModalClose={() =>
                                            setWelcomeEditOpen(false)
                                        }
                                        handleModalSave={(img) => {
                                            editWelcomePage(
                                                targetImg,
                                                "image",
                                                img
                                            );
                                            setWelcomeEditOpen(false);
                                        }}
                                        value={welcomePages[targetImg].image}
                                        aspectRatio={1}
                                        mWidth={300}
                                    />
                                )}
                                <Button
                                    fluid
                                    primary
                                    content="Add a Welcome Page"
                                    onClick={addWelcomePage}
                                />
                            </Div>
                        </Div>
                    )}
                    {listSuccess && (
                        <Div vapor medpad gutter>
                            <Div rimmed>Allocate Content Repos</Div>
                            <Dropdown
                                fluid
                                selection
                                multiple
                                options={_.map(
                                    _.filter(
                                        teamsList,
                                        (elm) =>
                                            elm.shareable &&
                                            elm.identifier !== team
                                    ),
                                    (l) => ({
                                        key: l.identifier,
                                        text: l.name,
                                        value: l.identifier,
                                    })
                                )}
                                value={allocatedTeams}
                                onChange={(e, { value }) =>
                                    setAllocatedTeams(value)
                                }
                            />
                        </Div>
                    )}
                </Div>
            </Div>
            <Div float-left>
                <Button
                    icon="chevron left"
                    secondary
                    labelPosition="left"
                    content={"Back to Teams List"}
                    onClick={() => closePanel(false)}
                />
            </Div>
            <Div float-right>
                <Button
                    icon="save"
                    primary
                    labelPosition="right"
                    content={t("components.save")}
                    onClick={saveBranding}
                />
            </Div>
        </Div>
    );
    const loginBox = (
        <Div fluid superpad ht={`${wPageHt - 20}px`} flex key="login-card">
            <Div wd="60%" gutter>
                <Image src={logo} />
            </Div>
            <Div fluid flex white superpad rounded dropShadow>
                <Div clear fitted fluid center-align>
                    <Div fluid white fullHt>
                        <Div massive altText fluid center-align gutter>
                            SIGN IN
                        </Div>
                        <Div fluid gutter>
                            <Input
                                fluid
                                label="Username"
                                placeholder="Input Username"
                            />
                            <br />
                            <Input
                                fluid
                                label="Password"
                                type="password"
                                placeholder="Input Password"
                            />
                            <br />
                            <Button fluid primary content="SUBMIT" />
                        </Div>
                    </Div>
                </Div>
                <br />
            </Div>
        </Div>
    );
    const sidePanel = (
        <Div wd="360px" fullht padSides float-right>
            <Div
                fluid
                fullht
                rounded
                slightShadow
                style={darkStyle(mainColor, "to top left")}
            >
                <Div
                    ht="60px"
                    fluid
                    ash
                    style={darkStyle(mainColor)}
                    flex
                    flexStart
                    gapped
                >
                    <Div white rimmed wd="60px" fullht float-left>
                        <Image src={slogo} />
                    </Div>
                    <Div altText massive padSides>
                        {appName}
                    </Div>
                </Div>
                <Div fluid ht={wPageHt}>
                    {features.general.noWelcome ? (
                        loginBox
                    ) : (
                        <Carousel
                            lazyLoad
                            slidesToShow={1}
                            dots
                            children={_.flatten([
                                <Div
                                    superpad
                                    fluid
                                    flex
                                    ht={`${wPageHt - 120}px`}
                                    key="brand-splash"
                                >
                                    <Image src={logo} />
                                </Div>,
                                _.map(welcomePages, (page, idx) => {
                                    return (
                                        <Div
                                            fluid
                                            superpad
                                            big
                                            ht={`${wPageHt - 120}px`}
                                            key={`wpage-${idx}`}
                                            style={{ color: textColor }}
                                        >
                                            <Div rounded noOverflow gutter>
                                                <Image src={page.image} />
                                            </Div>
                                            <Div
                                                big
                                                bold
                                                gutter
                                                gapTop
                                                style={darkStyle(
                                                    mainColor,
                                                    "to top right"
                                                )}
                                                medpad
                                                rounded
                                            >
                                                {page.header}
                                            </Div>
                                            <Div trench>{page.content}</Div>
                                        </Div>
                                    );
                                }),
                                loginBox,
                            ])}
                        />
                    )}
                </Div>
            </Div>
        </Div>
    );
    // ========================= Render Function =================================
    return (
        <Div fluid fullht clearfix>
            {mainPanel}
            {sidePanel}
        </Div>
    );
}
