import React from "react";
import _ from "lodash";
import { Div } from "@components/Generics.react";
import { clientAssetsPath } from "@api/apiV6";
import Carousel from "@components/Carousel.react";

export default function AnimatedBulletList(props) {
    const { bullets, image } = props.data;
    const img =
        image && !_.startsWith(image, "data:image")
            ? `${clientAssetsPath}/uploads/qdf/slides/${image.id}${image.extension}`
            : image;
    return (
        <Div
            fluid
            zoom
            superpad
            style={
                image
                    ? {
                          backgroundImage: `url(${img})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center center",
                      }
                    : {}
            }
        >
            <Carousel slidesToShow={1} arrows dots>
                {_.map(bullets, (bullet, idx) => {
                    return (
                        <Div
                            key={`list-item-${idx}`}
                            peat
                            superpad
                            slightShadow
                            left-aligned
                            spaced
                            rounded
                            big
                        >
                            {bullet}
                        </Div>
                    );
                })}
            </Carousel>
        </Div>
    );
}
