import React from "react";
import { useGetLearnerPerformanceQuery } from "@api/apiV6";
import { useSelector } from "react-redux";
// import { useTranslation } from "react-i18next";
import RadialProgressChart from "@components/charts/RadialProgressChart.react";
import ScatterChart from "@components/charts/ScatterChart.react";
import { Div } from "@components/Generics.react";
import { settings } from "@config/settings/app.settings";

export default function UserDashboard(props) {
    const { id, data } = props;
    const user = useSelector((state) => state.auth.user);
    const { data: performanceData, isSuccess } =
        useGetLearnerPerformanceQuery(id);
    // const { t } = useTranslation("common");

    return (
        <Div fluid>
            <Div fluid flex gapped>
                <Div
                    center-align
                    half
                    rounded
                    basepad
                    wd="calc(25% - 10px)"
                    ht="180px"
                    flex
                    column
                >
                    <Div center-align compact bold uppercase>
                        Total Courses
                    </Div>
                    <Div giant>{data.enrolled_courses}</Div>
                </Div>
                <Div
                    flex
                    vapor
                    basepad
                    wd="calc(25% - 10px)"
                    ht="180px"
                    rounded
                >
                    <Div fluid center-align compact bold uppercase gapBottom>
                        Completed
                    </Div>
                    <RadialProgressChart
                        width={100}
                        height={100}
                        color={
                            user.branding?.colors?.main || settings.colors.main
                        }
                        value={data.completed}
                    />
                </Div>
                <Div
                    flex
                    vapor
                    basepad
                    wd="calc(25% - 10px)"
                    ht="180px"
                    rounded
                >
                    <Div fluid center-align compact bold uppercase gapBottom>
                        Started
                    </Div>
                    <RadialProgressChart
                        width={100}
                        height={100}
                        color={
                            user.branding?.colors?.main || settings.colors.main
                        }
                        value={data.started}
                    />
                </Div>
                <Div
                    flex
                    vapor
                    basepad
                    wd="calc(25% - 10px)"
                    ht="180px"
                    rounded
                >
                    <Div fluid center-align compact bold uppercase gapBottom>
                        Not Started
                    </Div>
                    <RadialProgressChart
                        width={100}
                        height={100}
                        color={
                            user.branding?.colors?.main || settings.colors.main
                        }
                        value={parseInt(
                            (data.notstarted_courses * 100) /
                                data.enrolled_courses,
                            0
                        )}
                    />
                </Div>
            </Div>
            {isSuccess && (
                <Div fluid flex spaceBetween medpad>
                    <Div wd="calc(40% - 15px)">
                        <Div basepad vapor rounded flex center-align ht="265px">
                            <Div center-align compact bold uppercase gapBottom>
                                Average Course Score
                            </Div>
                            <RadialProgressChart
                                width={120}
                                height={120}
                                color={settings.colors.main}
                                value={Math.round(
                                    performanceData.average_final_score
                                )}
                            />
                        </Div>
                    </Div>
                    <Div wd="60%">
                        <Div medpad vapor rounded center-align ht="265px">
                            <Div gapTop big uppercase gapBottom bold>
                                Score vs Content Coverage
                            </Div>
                            <ScatterChart
                                color={settings.colors.main}
                                data={performanceData.score_completion}
                                width={600}
                                series={[
                                    {
                                        type: "number",
                                        dataKey: "completion",
                                        name: "Coverage",
                                        unit: "%",
                                    },
                                    {
                                        type: "number",
                                        dataKey: "score",
                                        name: "Score",
                                        unit: "%",
                                    },
                                ]}
                            />
                        </Div>
                    </Div>
                </Div>
            )}
        </Div>
    );
}
