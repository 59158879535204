import React, { useState } from "react";
import _ from "lodash";
import { useCreateTagMutation, useUpdateTagMutation } from "@api/apiV6";
import { toast } from "react-toastify";

import { Div, Button } from "@components/Generics.react";
import { Modal, Icon, Input } from "semantic-ui-react";
import { settings } from "@config/settings/app.settings";

export default function UpsertTagModal(props) {
    const { isOpen, setOpen, tag } = props;

    const [tagName, setTagName] = useState(tag?.name);
    const [tagTranslations, setTagTranslations] = useState(
        tag?.translations || {}
    );
    const [createTag] = useCreateTagMutation();
    const [updateTag] = useUpdateTagMutation();

    async function tryUpsert() {
        if (tag == null) {
            const response = await createTag({
                name: tagName,
                translations: tagTranslations,
            });
            if (response) {
                toast("Tag Created");
                setOpen(false);
            }
        } else {
            const response = await updateTag({
                id: tag._id,
                name: tagName,
                translations: tagTranslations,
            });
            if (response) {
                toast("Tag Updated");
                setOpen(false);
            }
        }
    }

    function handleTranslation(code, value) {
        let translationBlock = _.cloneDeep(tagTranslations);
        translationBlock[code] = value;
        setTagTranslations(translationBlock);
    }

    // ========================= Render Function =================================
    return (
        <Modal size="small" open={isOpen} onClose={() => setOpen(false)}>
            <Modal.Header>
                {tag == null ? "Add" : "Edit"} Tag
                <Div float-right clickable>
                    <Icon
                        name="times"
                        onClick={() => {
                            setOpen(false);
                        }}
                    />
                </Div>
            </Modal.Header>
            <Modal.Content>
                <Div white basepad>
                    <Div fluid gutter>
                        <Input
                            fluid
                            label={"Tag Name"}
                            placeholder={"Enter tag name"}
                            value={tagName}
                            onChange={(e) => setTagName(e.target.value)}
                        />
                        {settings.languages.length > 0 && (
                            <Div ash>
                                {_.map(
                                    _.filter(
                                        settings.languages,
                                        (l) => l.code !== "en"
                                    ),
                                    (lang, idx) => (
                                        <Input
                                            key={`tag-translate-${idx}`}
                                            fluid
                                            label={lang.identifier}
                                            placeholder={
                                                "Enter tag translation"
                                            }
                                            value={tagTranslations[lang.code]}
                                            onChange={(e) =>
                                                handleTranslation(
                                                    lang.code,
                                                    e.target.value
                                                )
                                            }
                                        />
                                    )
                                )}
                            </Div>
                        )}
                    </Div>
                </Div>
                <Button
                    primary
                    content={`${tag == null ? "Add" : "Edit"} Tag`}
                    onClick={tryUpsert}
                />
            </Modal.Content>
        </Modal>
    );
}
