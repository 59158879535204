import React from "react";
import { useTranslation } from "react-i18next";
import { Div } from "@components/Generics.react";
import LearnerTable from "../components/LearnerTable.react";
export default function CourseLearners(props) {
    const { course, courseData, refetch } = props;
    const { t } = useTranslation("common");

    return (
        <Div
            maxHt={`${window.innerHeight - 280}px`}
            autoOverflowY
            rounded
            compact
            gapTop
        >
            <LearnerTable
                course={course}
                header={t("analytics.learnerAnalytics")}
                data={courseData}
                refetch={refetch}
                showIndividual
            />
        </Div>
    );
}
