import React from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { Div } from "@components/Generics.react";
import { Image } from "semantic-ui-react";
import "@styles/components.scss";
import { text_truncate, formatDateAgo } from "@utilities/helpers";
import AbstractImageGenerator from "./AbstractImageGenerator.react";
import { deckFormats } from "@schemas/deckFormats";

export default function PortraitCard(props) {
    const { article, noImage } = props;
    const navigate = useNavigate();

    const arStyle = {
        width: "100%",
        height: "0",
        paddingBottom: "133.33%",
    };
    const imgSection = !noImage && (
        <Div style={arStyle}>
            {article?.image ? (
                <Div rounded relative noOverflow style={arStyle}>
                    <Image
                        src={article?.image}
                        style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            height: "100%",
                            width: "auto",
                            maxWidth: "500%",
                            transform: "translate(-25%)",
                        }}
                    />
                </Div>
            ) : (
                <Div rounded danger noOverflow style={arStyle}>
                    <AbstractImageGenerator
                        name={article?.name}
                        readerType={article?.category}
                        iconRows={false}
                    />
                </Div>
            )}
        </Div>
    );

    const headerSection = (
        <Div fluid big bold style={{ margin: "5px auto" }}>
            {text_truncate(article?.name, 40)}
        </Div>
    );

    const metaSection = `${article?.uniques || 0} View${
        article?.uniques === 1 ? "" : "s"
    } | ${formatDateAgo(article?.updatedAt)}`;
    // ========================= Render Function =================================

    return (
        <Div
            tiny
            relative
            clickable
            onClick={() =>
                navigate(`/content/${article?.category}/${article?.identifier}`)
            }
            className="portrait-article-card"
        >
            <Div relative>
                {imgSection}
                <Div
                    absolute
                    primary
                    rimmed
                    uppercase
                    altText
                    big
                    style={{
                        top: "10px",
                        right: "0",
                        borderRadius: "2px 0 0 2px",
                        lineHeight: 1,
                        zIndex: "99",
                    }}
                >
                    {_.find(deckFormats, { category: article?.category })
                        ?.name ||
                        _.find(deckFormats, { readerType: article?.category })
                            ?.name}
                </Div>
                <Div
                    absolute
                    rounded
                    style={{ ...arStyle, top: 0 }}
                    className="pc-overlay"
                >
                    <Div
                        medpad
                        txtIvory
                        left-align
                        absolute
                        style={{ bottom: 0 }}
                    >
                        {headerSection}
                        {metaSection}
                    </Div>
                </Div>
            </Div>
            {/* 
                {tagsSection}
                 */}
        </Div>
    );
}
