import React, { useRef } from "react";
import _ from "lodash";
import ReactTable from "react-table-v6";
import { Div, Button } from "@components/Generics.react";
import { Icon } from "semantic-ui-react";
import CourseIndividual from "../courses/CourseIndividual.react";
import { useState } from "react";
export default function CoursesStatusTable(props) {
    const {
        header,
        data,
        user,
        courses,
        refetch,
        showIndividual = false,
        rows = 7,
    } = props;

    const [open, setOpen] = useState(false);
    const [targetCourse, setTargetCourse] = useState(null);
    const reactTableRef = useRef(null);

    let fullColumns = [
        {
            Header: "Course Name",
            accessor: "entity_name",
            minWidth: 128,
        },
        {
            Header: "Accessed",
            id: "accessed",
            accessor: (d) => d.currentState.viewAttempts,
            Cell: ({ value }) =>
                value === "0" ? "No" : "Yes (" + value + " views)",
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                }
                if (filter.value === "yes") {
                    return parseInt(row[filter.id]) !== 0;
                }
                if (filter.value === "no") {
                    return parseInt(row[filter.id]) === 0;
                }
                return row[filter.id] === 0;
            },
            Filter: ({ filter, onChange }) => (
                <select
                    onChange={(e) => onChange(e.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">Show All</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
            ),
            style: { textAlign: "center" },
        },
        {
            Header: "Completion",
            id: "completion",
            accessor: (d) =>
                d.currentState.completion
                    ? 100
                    : d.currentState.viewedPercentage,
            Cell: ({ value }) => (value === null ? "0%" : value + "%"),
            filterMethod: (filter, row) => {
                const rowVal = parseInt(row[filter.id], 0);
                switch (filter.value) {
                    case "zero":
                        return rowVal === 0;
                    case "low":
                        return rowVal < 40 && rowVal > 0;
                    case "medium":
                        return rowVal < 80 && rowVal > 40;
                    case "high":
                        return rowVal < 100 && rowVal > 80;
                    case "full":
                        return rowVal === 100;
                    case "all":
                    default:
                        return true;
                }
            },
            Filter: ({ filter, onChange }) => (
                <select
                    onChange={(e) => onChange(e.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">Show All</option>
                    <option value="zero">0%</option>
                    <option value="low">0-40%</option>
                    <option value="medium">40-80%</option>
                    <option value="high">80-99%</option>
                    <option value="full">100%</option>
                </select>
            ),
            style: { textAlign: "center" },
        },
        {
            Header: "Score",
            id: "score",
            accessor: (d) => d.currentState.score,
            Cell: ({ value }) => (value === null ? "-" : value + "%"),
            filterMethod: (filter, row) => {
                const rowVal = parseInt(row[filter.id], 0);
                switch (filter.value) {
                    case "zero":
                        return rowVal === 0;
                    case "low":
                        return rowVal < 40 && rowVal > 0;
                    case "medium":
                        return rowVal < 80 && rowVal > 40;
                    case "high":
                        return rowVal < 100 && rowVal > 80;
                    case "full":
                        return rowVal === 100;
                    case "all":
                    default:
                        return true;
                }
            },
            Filter: ({ filter, onChange }) => (
                <select
                    onChange={(event) => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">Show All</option>
                    <option value="zero">0%</option>
                    <option value="low">0-40%</option>
                    <option value="medium">40-80%</option>
                    <option value="high">80-99%</option>
                    <option value="full">100%</option>
                </select>
            ),
            style: { textAlign: "center" },
        },
        {
            Header: "Time Spent",
            id: "time_spent",
            accessor: (d) => d.currentState.timeSpent,
            Cell: ({ value }) =>
                value === null
                    ? "0 secs"
                    : value < 60
                    ? value + " secs"
                    : Math.round(value / 60, 0) + " mins",
            filterMethod: (filter, row) => row[filter.id] >= filter.value,
            style: { textAlign: "center" },
        },
        showIndividual && {
            Header: ">",
            accessor: "id",
            maxWidth: 80,
            Cell: ({ row }) => {
                return (
                    <Div center-align txtMain clickable>
                        <Icon
                            name="area chart"
                            onClick={() => {
                                setTargetCourse(
                                    _.find(courses, {
                                        _id: row._original.entity_id,
                                    })
                                );
                                setOpen(true);
                            }}
                        />
                    </Div>
                );
            },
        },
    ].filter(Boolean);
    const downloadCSV = () => {
        const items = _.map(
            reactTableRef.current.getResolvedState().sortedData,
            "_original"
        );

        if (items.length > 0) {
            const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
            const header = Object.keys(items[0]);
            let csv = items.map((row) =>
                header
                    .map((fieldName) =>
                        JSON.stringify(row[fieldName], replacer)
                    )
                    .join(",")
            );
            csv.unshift(header.join(","));
            csv = csv.join("\r\n");
            var hiddenElement = document.createElement("a");
            hiddenElement.href =
                "data:text/csv;charset=utf-8," + encodeURI(csv);
            hiddenElement.target = "_blank";
            hiddenElement.download = "LearnerSummaryData.csv";
            hiddenElement.click();
        }
    };
    return (
        <Div rounded compact>
            {header && (
                <Div flex spaceBetween gapBottom>
                    <Div bold left-align big uppercase>
                        <Div inline small clickable>
                            <Icon
                                circular
                                inverted
                                name="sync"
                                onClick={refetch}
                            />
                        </Div>{" "}
                        {header}
                    </Div>
                    <Button
                        primary
                        small={+true}
                        onClick={downloadCSV}
                        icon="download"
                        labelPosition="right"
                        content="Download"
                    />
                </Div>
            )}
            <ReactTable
                ref={reactTableRef}
                data={data.courseStatus}
                filterable
                defaultFilterMethod={(filter, row, column) => {
                    const id = filter.pivotId || filter.id;
                    return row[id] !== undefined
                        ? String(row[id])
                              .toLowerCase()
                              .includes(filter.value.toLowerCase())
                        : true;
                }}
                pageSizeOptions={[5, 7, 10, 15, 30, 50]}
                columns={fullColumns}
                defaultPageSize={rows}
                className="-striped -highlight"
            />
            {targetCourse && (
                <CourseIndividual
                    course={targetCourse}
                    user={{
                        ...user,
                        name: `${user.first_name} ${user.last_name}`,
                    }}
                    setOpen={setOpen}
                    open={open}
                />
            )}
        </Div>
    );
}
