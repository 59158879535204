// Import from NPM
// -------------------------------------
import React from "react";
import { Div, Icomoon } from "@components/Generics.react";
import "@styles/editor.scss";

export default function SingleBtn(props) {
    const { icon, name, action, leftBordered = true, title = null } = props;
    // ========================= Render Function =================================
    return (
        <Div fit-content className="ribbon-btn" leftBordered={leftBordered}>
            <Div
                basepad
                ivory
                slightShadow
                snubbed
                fit-content
                center-align
                clickable
                className="sub-btn"
                onClick={action}
                title={title}
            >
                <Div headline>
                    <Icomoon name={icon} />
                </Div>
                <Div small>{name}</Div>
            </Div>
        </Div>
    );
}
