import React from "react";
import { Div } from "@components/Generics.react";

export default function Splash(props) {
    const { splash } = props.data;
    return (
        <Div fluid flex large bold fullht superpad>
            <Div superpad>{splash}</Div>
        </Div>
    );
}
