import React, { useEffect, useRef } from "react";
import _ from "lodash";

import "@styles/readers.scss";
import { Div, HDiv, Button } from "@components/Generics.react";
import Accordion from "@components/Accordion.react";
import { Image } from "semantic-ui-react";

export default function OfferReader(props) {
    const { deck, recordAttempt } = props;
    const imageRef = useRef(null);

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let panels = _.map(deck?.content?.sections, (item, idx) => {
        return {
            title: <Div small>{item?.title}</Div>,
            content: (
                <Div>
                    <HDiv content={item?.description} />
                    <br />
                    {item?.button &&
                        (_.includes(item?.button.btnLink, "http") ? (
                            <a
                                href={item?.button.btnLink}
                                target="_blank"
                                rel="noreferrer"
                                key={`offer-link-${idx}`}
                            >
                                <Button
                                    primary
                                    fluid
                                    content={item?.button.text}
                                />
                            </a>
                        ) : (
                            <a
                                href={item?.button.btnLink}
                                key={`offer-link-${idx}`}
                            >
                                <Button
                                    primary
                                    fluid
                                    content={item?.button.text}
                                />
                            </a>
                        ))}
                </Div>
            ),
        };
    });

    return (
        <Div
            fluid
            flex
            maxWd="480px"
            centered
            fullht
            priText
            autoOverflowY
            className="product-faq-reader"
            peat
            charcoal
            basepad
            rounded
        >
            <Div relative topRounded noOverflow>
                <div ref={imageRef}>
                    <Image fluid src={deck?.content?.image} />
                </div>
                <Div smoke centered center-align bold uppercase basepad big>
                    {deck?.content?.name}
                </Div>
                <HDiv
                    centered
                    center-align
                    half
                    bold
                    uppercase
                    basepad
                    content={deck?.content?.description}
                />
            </Div>
            <Div
                fluid
                ht={
                    imageRef.current
                        ? `calc(100% - ${imageRef.current?.clientHeight}px)`
                        : "calc(100% - 140px)"
                }
                relative
            >
                <Accordion panels={panels}></Accordion>
            </Div>
        </Div>
    );
}
