import React, { useEffect, useMemo } from "react";
import _ from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useGetCourseLibraryQuery } from "@api/apiV6";
import "@styles/readers.scss";

import { Div, Button } from "@components/Generics.react";

export default function CourseArticleReader(props) {
    const { deck, currentState, recordAttempt } = props;
    const { data: library, isSuccess } = useGetCourseLibraryQuery();
    const user = useSelector((state) => state.auth.user);

    const { t } = useTranslation("common");

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentState]);

    const parentProgram = useMemo(() => {
        let targetProgram =
            isSuccess &&
            _.cloneDeep(
                _.find(library.categories, (item) => {
                    return _.some(item.node_list, { _id: deck._id });
                })
            );
        if (targetProgram?.sequential)
            targetProgram.node_list = _.orderBy(
                _.orderBy(targetProgram?.node_list, ["priority"], ["desc"]),
                (course) =>
                    library.courses[course._id].currentState?.completion,
                "desc"
            );
        return targetProgram;
    }, [isSuccess, library, deck]);

    const locked = useMemo(() => {
        let isLocked = false,
            lockMsg = "";

        const courseIdx = _.findIndex(parentProgram?.node_list, {
            _id: deck?._id,
        });
        const course =
            isSuccess &&
            library.courses[parentProgram?.node_list[courseIdx]._id];
        const prereqLock =
            isSuccess &&
            parentProgram &&
            parentProgram?.sequential &&
            courseIdx &&
            courseIdx - 1 > 0 &&
            deck?._id
                ? !library.courses[parentProgram.node_list[courseIdx - 1]._id]
                      .currentState?.completion
                : false;
        if (prereqLock) {
            isLocked = true;
            lockMsg = t("course.prereqLock");
        } else if (course && course.enable_timing) {
            if (course.vintage != null && course.vintage > 0) {
                const createdAt = moment(
                    new Date(user.createdAt),
                    "YYYY-MM-DD"
                );
                const today = moment(new Date(), "YYYY-MM-DD");
                const daysSince = today.diff(createdAt, "days");
                const availableOn = createdAt.add(course.vintage, "days");
                if (daysSince < course.vintage) {
                    isLocked = true;
                    lockMsg = t("course.vintageLock")
                        .replace("$vintage", course.vintage)
                        .replace("$date", availableOn);
                }
            }
            if (course.dates) {
                const start_date = new Date(course.dates.start_date); // Replace this with your start_date
                const end_date = new Date(course.dates.end_date); // Replace this with your end_date
                const now = new Date();
                let localeDate;
                let localeTime;

                if (now < start_date) {
                    localeDate = start_date.toLocaleDateString();
                    localeTime = start_date.toLocaleTimeString();
                    isLocked = true;
                    lockMsg = t("course.notStarted")
                        .replace("$date", localeDate)
                        .replace("$time", localeTime);
                } else if (now > end_date) {
                    localeDate = end_date.toLocaleDateString();
                    localeTime = end_date.toLocaleTimeString();
                    isLocked = true;
                    lockMsg = t("course.hasEnded")
                        .replace("$date", localeDate)
                        .replace("$time", localeTime);
                }
            }
        }
        return [isLocked, lockMsg];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, parentProgram, library, deck]);

    return (
        <Div superpad gutter center-align>
            <Div superpad gutter center-align ash relaxed snubbed>
                {parentProgram?.name}
                &nbsp;&gt; {deck.name}
            </Div>
            {deck.description}
            <br />
            <br />
            {locked[0] ? (
                <Div>
                    <Div gutter bold uppercase>
                        {t("course.locked")}
                    </Div>
                    <Div snubbed danger fit-content relaxed centered>
                        {locked[1]}
                    </Div>
                </Div>
            ) : (
                <Div gapBottom bold uppercase>
                    <NavLink to={`/courses/${deck._id}`}>
                        <Button
                            fluid
                            primary
                            content={t("course.open_article")}
                        />
                    </NavLink>
                </Div>
            )}
            <br />
            <NavLink to={`/programs/${parentProgram?._id}`}>
                {t("components.view")} {parentProgram?.name}
            </NavLink>
        </Div>
    );
}
