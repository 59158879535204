import React from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Statistic } from "semantic-ui-react";
import { Div } from "@components/Generics.react";
import { Icon } from "semantic-ui-react";
import { deckFormats } from "@schemas/deckFormats";
import RadialProgressChart from "@components/charts/RadialProgressChart.react";

export default function ArticleSummary(props) {
    const { article, audienceData, aggregateData } = props;
    const user = useSelector((state) => state.auth.user);
    const totalTimeSpent = aggregateData.time_spent;
    const articleType =
        _.find(deckFormats, { category: article.category }) ||
        _.find(deckFormats, { readerType: article.category });
    return (
        <Div className="article-summary">
            <Div flex center-align gapTop spaceBetween big uppercase gutter>
                <Div midnight wd="calc(25% - 7.5px)" basepad altText>
                    {articleType?.name}
                </Div>
                <Div half wd="calc(25% - 7.5px)" basepad altText>
                    {article.shareable ? "Shareable" : "Non Shareable"}
                </Div>
                <Div half wd="calc(25% - 7.5px)" basepad altText>
                    {article.design?.cleanView ? "Clean View" : "Full View"}
                </Div>
                <Div half wd="calc(25% - 7.5px)" basepad altText>
                    {article.design?.noInteractions
                        ? "Interactions Off"
                        : "Interactions On"}
                </Div>
            </Div>
            <Div gutter flex>
                <Div wd="33%" ht="200px" white medpad>
                    <Div big uppercase padded altText center-align>
                        Visitors / Total Users
                    </Div>
                    <RadialProgressChart
                        value={Math.ceil(
                            (audienceData.viewers.length * 100) /
                                audienceData.userCount
                        )}
                        width={140}
                        height={140}
                        color={user.branding?.colors?.main}
                    />
                </Div>
                <Div wd="67%">
                    <Statistic.Group inverted size="tiny" widths={3}>
                        <Statistic>
                            <Statistic.Value>
                                <Icon name="users" />{" "}
                                {audienceData.viewers.length}
                            </Statistic.Value>
                            <Statistic.Label>Visitors</Statistic.Label>
                        </Statistic>
                        <Statistic>
                            <Statistic.Value>
                                <Icon name="eye" /> {aggregateData.total_views}
                            </Statistic.Value>
                            <Statistic.Label>Views</Statistic.Label>
                        </Statistic>
                        <Statistic>
                            <Statistic.Value>
                                {totalTimeSpent === null
                                    ? "0 secs"
                                    : totalTimeSpent < 60
                                    ? totalTimeSpent + " secs"
                                    : Math.round(totalTimeSpent / 60, 0) +
                                      " mins"}
                            </Statistic.Value>
                            <Statistic.Label>Time Spent</Statistic.Label>
                        </Statistic>
                        <Statistic>
                            <Statistic.Value>
                                <Icon name="thumbs up" /> {audienceData.likes}
                            </Statistic.Value>
                            <Statistic.Label>Likes</Statistic.Label>
                        </Statistic>
                        <Statistic>
                            <Statistic.Value>
                                <Icon name="thumbs down" />{" "}
                                {audienceData.dislikes}
                            </Statistic.Value>
                            <Statistic.Label>Dislikes</Statistic.Label>
                        </Statistic>
                        <Statistic>
                            <Statistic.Value>
                                <Icon name="comments" /> {audienceData.comments}
                            </Statistic.Value>
                            <Statistic.Label>Comments</Statistic.Label>
                        </Statistic>
                    </Statistic.Group>
                </Div>
            </Div>
            <Div
                fluid
                danger={!article.published}
                success={article.published}
                basepad
                huge
                uppercase
                center-align
            >
                {article.published ? "Status: Published" : "Status: Draft"}
            </Div>
        </Div>
    );
}
