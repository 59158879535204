import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import _ from "lodash";
import { Divider, Icon } from "semantic-ui-react";
import { useFeatures } from "flagged";

import {
    useGetArticleInventoryQuery,
    useGetCourseInventoryQuery,
    useGetProgramInventoryQuery,
} from "@api/apiV6";

import CreatorLayout from "@layouts/CreatorLayout.react";
import { Div, Button } from "@components/Generics.react";
import BrowserBar from "@components/BrowserBar.react";
import CourseView from "./panels/CourseView.react";
import ArticleView from "./panels/ArticleView.react";
import ProgramView from "./panels/ProgramView.react";
import NewContentModal from "./panels/NewContentModal.react";
import BuilderHeader from "@components/BuilderHeader.react";
import { toast } from "react-toastify";

export default function BuilderLibrary(props) {
    const user = useSelector((state) => state.auth.user);
    const {
        data: articles,
        isLoading: articlesLoading,
        isSuccess: articlesSuccess,
        refetch: articleRefetch,
    } = useGetArticleInventoryQuery();
    const {
        data: courses,
        isLoading: coursesLoading,
        isSuccess: coursesSuccess,
        refetch: courseRefetch,
    } = useGetCourseInventoryQuery();
    const {
        data: programs,
        isLoading: programsLoading,
        isSuccess: programsSuccess,
        refetch: programRefetch,
    } = useGetProgramInventoryQuery();
    const [searchParams, setSearchParams] = useSearchParams();
    const features = useFeatures();
    const { t } = useTranslation("common");

    async function refreshData() {
        await articleRefetch();
        await courseRefetch();
        await programRefetch();
        toast(t("builder.welcome.pageRefreshed"));
    }
    // Content Structure passed has to be {_id, name, parent:{_id, name}}
    const contentSections = [
        features.learningCenter &&
            features.ability.articles.create && {
                section: "articles",
                name: t("learningCenter"),
                data: articlesSuccess
                    ? _.map(
                          features.tenanting.multiTenanted &&
                              !features.ability.builder.crossAccess
                              ? _.filter(
                                    articles,
                                    (article) =>
                                        article.owner?._id === user._id ||
                                        _.includes(
                                            user.branding.repos,
                                            article.owner?.team
                                        )
                                )
                              : articles,
                          (article) => {
                              if (article.owner?._id === user._id) {
                                  return {
                                      ...article,
                                      parent:
                                          article.parent != null
                                              ? article.parent
                                              : {
                                                    _id: t(
                                                        "builder.dic.unallocated"
                                                    ),
                                                    name: t(
                                                        "builder.dic.unallocated"
                                                    ),
                                                },
                                  };
                              } else
                                  return {
                                      ...article,
                                      parent: {
                                          _id: t("builder.dic.generalContent"),
                                          name: t("builder.dic.generalContent"),
                                      },
                                  };
                          }
                      )
                    : [],
                extra:
                    // programsSuccess
                    //     ? _.filter(
                    //           programs,
                    //           (program) =>
                    //               program.node_list.length === 0 &&
                    //               program.list_type === "Article" &&
                    //               program.owner?.team === user.team
                    //       )
                    //     :
                    [],
                pin: [t("builder.dic.generalContent")],
                collection: t("builder.dic.series"),
                groupBy: "category",
            },
        features.courseware && {
            section: "courses",
            name: t("courseware"),
            data: coursesSuccess
                ? features.courses.maxCourses === "Inf"
                    ? _.filter(
                          courses,
                          (c) =>
                              c.course_type !== "exam" &&
                              c.owner?.team === user.team
                      )
                    : _.filter(
                          courses,
                          (c) =>
                              c.course_type !== "exam" &&
                              c.owner?.team === user.team
                      ).slice(0, features.courses.maxCourses)
                : [],
            extra: programsSuccess
                ? _.filter(
                      programs,
                      (program) =>
                          program.node_list.length === 0 &&
                          program.list_type === "Course" &&
                          program.owner?.team === user.team
                  )
                : [],
            pin: [t("builder.dic.pubLib")],
            collection: t("builder.dic.programs"),
            groupBy: null,
        },
        features.courseware &&
            features.courses.hasExams && {
                section: "exams",
                name: t("exams"),
                data: coursesSuccess
                    ? _.filter(
                          courses,
                          (c) =>
                              c.course_type === "exam" &&
                              c.owner?.team === user.team
                      )
                    : [],
                extra: [],
                pin: [],
                collection: t("builder.dic.programs"),
                groupBy: null,
            },
    ].filter(Boolean);

    const btnSections = [
        {
            name: t("builder.dic.createNew"),
            icon: "add",
            action: () => setNewModalOpen(true),
        },
    ];

    const [target, setTarget] = useState(null);
    const [activeSection, setActiveSection] = useState(contentSections[0].name);
    const [newModalOpen, setNewModalOpen] = useState(false);

    useEffect(() => {
        const thisTarget = searchParams.get("target");
        const thisSection = searchParams.get("section");
        if (thisTarget != null && thisSection != null) {
            setTarget({
                key: thisTarget,
                type: "child",
                section: thisSection,
            });
        }
    }, [searchParams]);

    const setSelected = (selectedTarget) => {
        setSearchParams(
            selectedTarget.type === "child"
                ? {
                      target: selectedTarget.key,
                      section: selectedTarget.section,
                  }
                : {}
        );
        setTarget(selectedTarget);
    };

    const activeItem = searchParams.get("target");

    function displaySection() {
        let component = (
            <Div ht="calc(100% - 60px)" fluid>
                <BuilderHeader
                    image={"/assets/images/configurable/appcontent.jpg"}
                    header={t("builder.headers.content.header")}
                    description={t("builder.headers.content.help")}
                />
                <Div flex ht="calc(100% - 150px)" fluid>
                    <Div ash megapad center-align slightShadow wd="500px">
                        <Icon
                            size="huge"
                            name="sitemap"
                            circular
                            inverted
                            color="grey"
                        />
                        <Div padTop headline uppercase fluid altText gutter>
                            {t("builder.welcome.contentLibrary")}
                        </Div>
                        <Div large>{t("builder.welcome.selectFile")}</Div>
                        <Divider horizontal>{t("components.or")}</Divider>
                        <Button
                            primary
                            content={t("builder.dic.createNew")}
                            onClick={() => setNewModalOpen(true)}
                        />
                    </Div>
                </Div>
            </Div>
        );
        if (target && target.key.length === 24)
            switch (target.section) {
                case "courses":
                    component =
                        target.type === "parent" ? (
                            <ProgramView
                                id={target.key}
                                type={t("builder.dic.program")}
                                setTarget={setSelected}
                            />
                        ) : (
                            <CourseView
                                id={target.key}
                                setTarget={setSelected}
                            />
                        );
                    break;
                case "exams":
                    component =
                        target.type === "parent" ? (
                            <ProgramView
                                id={target.key}
                                type={t("builder.dic.program")}
                                setTarget={setSelected}
                            />
                        ) : (
                            <CourseView
                                id={target.key}
                                setTarget={setSelected}
                            />
                        );
                    break;
                case "articles":
                    component =
                        target.type === "parent" ? (
                            <ProgramView
                                id={target.key}
                                type={t("builder.dic.serie")}
                                setTarget={setSelected}
                            />
                        ) : (
                            <ArticleView
                                id={target.key}
                                setTarget={setSelected}
                            />
                        );
                    break;
                default:
                    component = (
                        <ProgramView
                            id={target.key}
                            type={t("builder.dic.program")}
                            setTarget={setSelected}
                        />
                    );
            }
        return component;
    }

    const pageContent = (
        <>
            <Div
                fullht
                wd="300px"
                float-left
                vapor
                relative
                layer={2}
                slightShadow
            >
                <BrowserBar
                    name={t("builder.welcome.browserHeader")}
                    contentSections={contentSections}
                    btnSections={btnSections}
                    setTarget={setSelected}
                    setSection={setActiveSection}
                    activeItem={activeItem}
                    localStore="libraryRecents"
                    maxRecents={3}
                    image="appcontent.jpg"
                    refetch={refreshData}
                    isLoading={
                        articlesLoading || programsLoading || coursesLoading
                    }
                />
            </Div>
            <Div fullht wd="calc(100% - 300px)" float-left ivory>
                {displaySection()}
            </Div>
            <NewContentModal
                key={_.now()}
                programs={programs}
                newModalOpen={newModalOpen}
                setNewModalOpen={setNewModalOpen}
                setSelected={setSelected}
                activeSection={activeSection}
                courseCount={courses?.length || 0}
                articleCount={articles?.length || 0}
            />
        </>
    );

    // ========================= Render Function =================================
    return (
        <CreatorLayout
            title={t("appCreator")}
            navName={t("builder.welcome.library")}
            pageCode={"library"}
            mainPanel={pageContent}
            rootPage={true}
        />
    );
}
