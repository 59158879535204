// Import from NPM
// -------------------------------------
import React, { useState, createContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

// Import from Config
// -------------------------------------
import "@styles/layouts.scss";
import { Div } from "@components/Generics.react";
import gcss from "@styles/variables.module.scss";
import { parsePx } from "@utilities/helpers";

import Navbar from "@components/navbar/Navbar.react";
import IsolatedPortrait from "./panels/IsolatedPortrait.react";
import IsolatedLandscape from "./panels/IsolatedLandscape.react";
import CommonPanel from "./panels/CommonPanel.react";
import { settings } from "@config/settings/app.settings";
import { Image } from "semantic-ui-react";

export const LayoutContext = createContext({});

export default function MasterLayout(props) {
    const {
        title,
        description,
        image,
        course,
        mainPanel,
        rightPanel,
        rightPanelName,
        navLogo,
        navName,
        closeDeck,
        rootPage = false,
        isolated = false,
        hidePanelToggle = settings.functionality.hideMobileToggle,
        bottomBuffer = true,
        startFullScreen = false,
    } = props;
    const [search] = useSearchParams();
    const [fullScreen, setFullScreen] = useState(startFullScreen);
    const isPreview =
        search.get("preview") === "true" || window.self !== window.top;
    const isPortrait = window.innerHeight > window.innerWidth;
    const isMobileLandscape =
        window.innerHeight < window.innerWidth && window.innerHeight < 400;
    // ------------------------------------------
    // Page Sizing
    // ------------------------------------------
    const pgHt = Math.min(
        window.innerHeight - parsePx(gcss.navbarHt),
        parsePx(gcss.maxHt) - parsePx(gcss.navbarHt)
    );

    useEffect(() => {
        if (isPortrait) {
            if (fullScreen) {
                const targetDiv = document.querySelector(".fullscreen-target");
                targetDiv.requestFullscreen();
                window.screen.orientation.lock("landscape-primary");
            } else {
                if (document.fullscreenElement) document.exitFullscreen();
            }
        }
    }, [fullScreen, isPortrait]);

    // ========================= Render Function =================================
    if (isMobileLandscape && !fullScreen && settings.mobileBuild)
        return (
            <Div flex bold big uppercase ht="360px">
                <Div wd="30%" center-align>
                    <Div wd="128px" centered gutter>
                        <Image src="/assets/images/switchPortrait.png" />
                    </Div>
                    Please turn to portrait view to continue
                </Div>
            </Div>
        );
    else
        return (
            <Div
                fluid
                className={`${isolated ? "isolated" : ""} ${
                    isPreview ? "is-preview" : ""
                } master-container`}
                autoOverflowY={isPortrait}
                pageht={isPreview}
            >
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:image" content={image} />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content="@QuoDeck" />
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={description} />
                    <meta name="twitter:image" content={image} />
                    <link rel="canonical" href={window.location.href} />
                    <meta name="theme-color" content={settings.colors.main} />
                </Helmet>
                <Navbar
                    logo={navLogo}
                    name={navName}
                    rootPage={rootPage}
                    isolated={isolated}
                    closeDeck={closeDeck}
                />
                <LayoutContext.Provider
                    value={{ fullScreen, setFullScreen, height: pgHt }}
                >
                    {isolated ? (
                        isPortrait ? (
                            <IsolatedPortrait
                                course={course}
                                mainPanel={mainPanel}
                                rightPanel={rightPanel}
                                hidePanelToggle={hidePanelToggle}
                            />
                        ) : (
                            <IsolatedLandscape
                                course={course}
                                mainPanel={mainPanel}
                                rightPanel={rightPanel}
                                fullScreen={fullScreen}
                            />
                        )
                    ) : (
                        <CommonPanel
                            mainPanel={mainPanel}
                            rightPanel={rightPanel}
                            rightPanelName={rightPanelName}
                            hidePanelToggle={hidePanelToggle}
                            fullScreen={fullScreen}
                            bottomBuffer={bottomBuffer}
                            isPreview={isPreview}
                        />
                    )}
                </LayoutContext.Provider>
            </Div>
        );
}
