import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFeatures } from "flagged";
import _ from "lodash";
import { useGetProgramInventoryQuery } from "@api/apiV6";

import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import {
    Input,
    Image,
    Tab,
    Checkbox,
    Divider,
    Dropdown,
} from "semantic-ui-react";
import { Div, Button } from "@components/Generics.react";
import ImageUploader from "@components/ImageUploader.react";

import PreviewLayout from "@layouts/PreviewLayout.react";
import ThemeSelector from "./ThemeSelector.react";
import { WrapperList } from "@schemas/wrapperSchema";

export default function CourseSetup(props) {
    const { course, updateCourse } = props;
    const user = useSelector((state) => state.auth.user);
    const { data: programs, isSuccess } = useGetProgramInventoryQuery();

    const [courseParent, setCourseParent] = useState(
        _.isString(course.parent) ? course.parent : course.parent?._id
    );
    const [courseName, setCourseName] = useState(course.name);
    const [courseDescription, setCourseDescription] = useState(
        course.description
    );
    const [courseImage, setCourseImage] = useState(course.image);
    const [imgEditorOpen, setImgEditorOpen] = useState(false);
    const features = useFeatures();
    const { t } = useTranslation(`common`);
    const isExam = course.course_type === "exam";
    const cType = isExam ? "exam" : "course";

    async function saveCourseImage(image) {
        setCourseImage(image);
        setImgEditorOpen(false);
        await updateCourse({
            id: course._id,
            image: image != null ? image : undefined,
        }).unwrap();
        toast(t(`components.saved`));
    }

    async function saveCourse() {
        await updateCourse({
            id: course._id,
            name: courseName !== "" ? courseName : undefined,
            description:
                courseDescription !== "" ? courseDescription : undefined,
            parent: courseParent,
        }).unwrap();
        updateRecents();
        toast(t(`components.saved`));
    }

    function updateRecents() {
        const storedRecents = window.localStorage.getItem("libraryRecents");
        let libraryRecents = [];
        if (storedRecents) {
            libraryRecents = JSON.parse(storedRecents);
        }
        const thisCourse = _.find(libraryRecents, { key: course._id });
        thisCourse.title = courseName;
        window.localStorage.setItem(
            "libraryRecents",
            JSON.stringify(libraryRecents)
        );
    }

    async function changeWrapper(selectedWrapper) {
        await updateCourse({
            id: course._id,
            wrapper: selectedWrapper,
        }).unwrap();
        toast(t(`builder.${cType}.themeUpdated`));
    }

    async function toggleTiming() {
        await updateCourse({
            id: course._id,
            enable_timing: !course.enable_timing,
        }).unwrap();
        toast(
            course.enable_timing
                ? `${t(`builder.${cType}.timed`)} ${t(`components.disabled`)}!`
                : `${t(`builder.${cType}.timed`)} ${t(`components.enabled`)}!`
        );
    }

    async function toggleSequential() {
        await updateCourse({
            id: course._id,
            sequential: !course.sequential,
        }).unwrap();
        toast(
            course.sequential
                ? `${t(`builder.${cType}.sequential`)} ${t(
                      "components.disabled"
                  )}!`
                : `${t(`builder.${cType}.sequential`)} ${t(
                      "components.enabled"
                  )}!`
        );
    }

    async function toggleAutoNotification() {
        await updateCourse({
            id: course._id,
            autoNotification: !course.autoNotification,
        }).unwrap();
        toast(
            course.autoNotification
                ? `${t(`builder.${cType}.autoNot`)} ${t(
                      `components.disabled`
                  )}!`
                : `${t(`builder.${cType}.autoNot`)} ${t(`components.enabled`)}!`
        );
    }

    // async function toggleReminders() {
    //     await updateCourse({
    //         id: course._id,
    //         notifications: {
    //             ...course.notifications,
    //             ...{ reminders: !course.notifications.reminders },
    //         },
    //     }).unwrap();
    //     toast(
    //         course.notifications.reminders
    //             ? `${t(`builder.${cType}.reminders`)} ${t(
    //                   "components.disabled"
    //               )}!`
    //             : `${t(`builder.${cType}.reminders`)} ${t(
    //                   `components.enabled`
    //               )}!`
    //     );
    // }

    async function updateCourseDate(thisDate, dateType) {
        await updateCourse({
            id: course._id,
            dates: { ...course.dates, ...{ [dateType]: thisDate } },
        }).unwrap();
        toast(t(`builder.${cType}.accessDtUpdated`));
    }

    async function setPriority(value) {
        await updateCourse({
            id: course._id,
            priority: value,
        }).unwrap();
        toast(t(`builder.${cType}.priorityUpdated`));
    }

    async function updateVintage(days) {
        await updateCourse({
            id: course._id,
            vintage: days,
        }).unwrap();
        toast(t(`builder.${cType}.vintageUpdated`));
    }

    async function updateReminderProps(value, target) {
        await updateCourse({
            id: course._id,
            notifications: { ...course.notifications, ...{ [target]: value } },
        }).unwrap();
        toast(t(`builder.${cType}.remindersUpdated`));
    }

    const panes = [
        !isExam &&
            features.courses.theming && {
                menuItem: t(`builder.${cType}.theme`),
                render: () => (
                    <Tab.Pane>
                        <ThemeSelector
                            course={course}
                            changeWrapper={changeWrapper}
                        />
                    </Tab.Pane>
                ),
            },
        {
            menuItem: t(`builder.${cType}.access`),
            render: () => (
                <Tab.Pane>
                    <Div ht="400px" autoOverflowY>
                        {!isExam && (
                            <Div basepad ivory snug>
                                <Div bold big>
                                    {t(`builder.${cType}.setSequential`)}:
                                </Div>
                                <Div italics gapBottom>
                                    {t(`builder.${cType}.sequentialHelp`)}
                                </Div>
                                <Checkbox
                                    toggle
                                    label={t(`builder.${cType}.isSequential`)}
                                    checked={course.sequential}
                                    onChange={toggleSequential}
                                />
                            </Div>
                        )}
                        <Div basepad ivory snug>
                            <Div big bold>
                                {t(
                                    `builder.${
                                        isExam ? "exam" : "course"
                                    }.setPriority`
                                )}
                            </Div>
                            <Div italics gapBottom>
                                {t(
                                    `builder.${
                                        isExam ? "exam" : "course"
                                    }.priorityHelp`
                                )}
                            </Div>
                            <Input
                                type="number"
                                value={course.priority}
                                onChange={(e) => setPriority(e.target.value)}
                            />
                        </Div>
                    </Div>
                </Tab.Pane>
            ),
        },
        features.courses.timing && {
            menuItem: t(`builder.${cType}.timing`),
            render: () => (
                <Tab.Pane>
                    <Div bold medpad flex spaceBetween>
                        <Div big>{t(`builder.${cType}.setTiming`)}:</Div>
                    </Div>
                    <Div medpad>
                        <Div gutter>
                            <Checkbox
                                toggle
                                label={t(`builder.${cType}.checkTiming`)}
                                checked={course.enable_timing}
                                onChange={toggleTiming}
                            />
                        </Div>
                        {course.enable_timing && (
                            <Div basepad ivory>
                                <Div flex spaceBetween gapped gutter>
                                    <Div wd="49%">
                                        <Div bold gapBottom>
                                            {t(`builder.${cType}.startDt`)}
                                        </Div>
                                        <DatePicker
                                            dateFormat="dd MMMM yyyy h:mm a"
                                            selected={
                                                course.dates?.start_date &&
                                                moment(
                                                    course.dates.start_date
                                                ).toDate()
                                            }
                                            onChange={(date) =>
                                                updateCourseDate(
                                                    date,
                                                    "start_date"
                                                )
                                            }
                                            showTimeSelect={true}
                                        />
                                    </Div>
                                    <Div wd="49%">
                                        <Div bold gapBottom>
                                            {t(`builder.${cType}.endDt`)}
                                        </Div>
                                        <DatePicker
                                            dateFormat="dd MMMM yyyy h:mm a"
                                            selected={
                                                course.dates?.end_date &&
                                                moment(
                                                    course.dates.end_date
                                                ).toDate()
                                            }
                                            onChange={(date) =>
                                                updateCourseDate(
                                                    date,
                                                    "end_date"
                                                )
                                            }
                                            showTimeSelect={true}
                                        />
                                    </Div>
                                </Div>
                                <Divider horizontal>
                                    {t(`components.or`)}
                                </Divider>
                                <Div center-align>
                                    <Input
                                        label={t(`builder.${cType}.vintage`)}
                                        type="number"
                                        value={course.vintage}
                                        max={5}
                                        onChange={(e) =>
                                            updateVintage(e.target.value)
                                        }
                                    />{" "}
                                    {t(`components.days`)}
                                    <Div gapTop italics gutter>
                                        {t(`builder.${cType}.vintageHelp`)}
                                    </Div>
                                </Div>
                            </Div>
                        )}
                    </Div>
                </Tab.Pane>
            ),
        },
        {
            menuItem: t(`builder.${cType}.notifications`),
            render: () => (
                <Tab.Pane>
                    <Div bold medpad flex spaceBetween>
                        <Div big>{t(`builder.${cType}.notiSettings`)}:</Div>
                    </Div>
                    <Div medpad>
                        <Div gapBottom>
                            <Checkbox
                                toggle
                                label={t(`builder.${cType}.notiSend`)}
                                checked={course.autoNotification}
                                onChange={toggleAutoNotification}
                            />
                        </Div>
                        {/* <Div gutter>
                            <Checkbox
                                toggle
                                label={t(`builder.${cType}.notiReminders`)}
                                checked={course.notifications?.reminders}
                                onChange={toggleReminders}
                            />
                        </Div> */}
                        {course.notifications?.reminders && (
                            <Div medpad ivory>
                                <Div>
                                    <Input
                                        label={t(`builder.${cType}.notiAfter`)}
                                        type="number"
                                        value={course.notifications?.inactivity}
                                        onChange={(e) =>
                                            updateReminderProps(
                                                e.target.value,
                                                "inactivity"
                                            )
                                        }
                                    />{" "}
                                    {t(`builder.${cType}.notiInactivity`)}
                                </Div>
                                <Div>
                                    <Input
                                        label={t(`builder.${cType}.notiMax`)}
                                        type="number"
                                        value={course.notifications?.max}
                                        onChange={(e) =>
                                            updateReminderProps(
                                                e.target.value,
                                                "max"
                                            )
                                        }
                                    />{" "}
                                    {t(`builder.${cType}.notiMaxNo`)}
                                </Div>
                            </Div>
                        )}
                    </Div>
                </Tab.Pane>
            ),
        },
    ].filter(Boolean);

    // ========================= Render Function =================================
    return (
        <Div fluid fullht padTop flex noWrap spaceAround>
            <Div fullht wd="calc(100% - 320px)" basepad>
                <Div large uppercase gutter bold>
                    {t(`components.editing`)}: {course?.name}
                </Div>
                <Div ivory fluid gutter snubbed noOverflow flex>
                    <Div grey relative wd="320px" minHt="160px">
                        <Image src={courseImage} />
                        <Div
                            absolute
                            layer={3}
                            style={{ bottom: "10px", right: "10px" }}
                        >
                            <Div flex clickable gapped>
                                <Button
                                    size="mini"
                                    content={t(`builder.dic.changeImage`)}
                                    secondary
                                    onClick={() => setImgEditorOpen(true)}
                                />
                            </Div>
                            <ImageUploader
                                modalOpen={imgEditorOpen}
                                handleModalClose={() => setImgEditorOpen(false)}
                                handleModalSave={saveCourseImage}
                                value={courseImage}
                                aspectRatio={0.5}
                                mWidth={640}
                            />
                        </Div>
                    </Div>
                    <Div medpad wd="calc(100% - 320px)">
                        <Input
                            fluid
                            label={t(`builder.dic.name`)}
                            value={courseName}
                            onChange={(e, { value }) => setCourseName(value)}
                        />
                        <Input
                            fluid
                            label={t(`builder.dic.description`)}
                            value={courseDescription}
                            onChange={(e, { value }) =>
                                setCourseDescription(value)
                            }
                        />
                        {isSuccess && (
                            <Div gapBottom clearfix>
                                <Div wd="30%" float-left medpad smoke snubbed>
                                    {t(`builder.dic.parent`)}
                                </Div>
                                <Div wd="70%" float-left>
                                    <Dropdown
                                        fluid
                                        selection
                                        search
                                        placeholder={`${t(
                                            `components.select`
                                        )} ${t(`builder.dic.parent`)}`}
                                        value={courseParent}
                                        options={_.map(
                                            _.filter(
                                                programs,
                                                (program) =>
                                                    program.list_type ===
                                                        "Course" &&
                                                    program.owner?.team ===
                                                        user.team
                                            ),
                                            (p) => {
                                                return {
                                                    key: p._id,
                                                    value: p._id,
                                                    text: p.name,
                                                };
                                            }
                                        )}
                                        onChange={(e, { value }) =>
                                            setCourseParent(value)
                                        }
                                    />
                                </Div>
                            </Div>
                        )}
                        <Div fluid small>
                            <Button
                                primary
                                content={t(`components.update`)}
                                onClick={saveCourse}
                            />
                        </Div>
                    </Div>
                </Div>
                <Div smoke fluid>
                    <Tab panes={panes} />
                </Div>
            </Div>
            <PreviewLayout
                key={`course-preview-${course.wrapper}`}
                url={`courses/${course._id}`}
                message={
                    course.wrapper &&
                    WrapperList[course.wrapper] &&
                    WrapperList[course.wrapper].wrapperType !== "formal" &&
                    t(`builder.dic.previewHelp`)
                }
            />
        </Div>
    );
}
