import React, { useContext } from "react";
import { Div, HDiv, Button } from "@components/Generics.react";
import { LayoutContext } from "@layouts/MasterLayout.react";

export default function ExternalLink(props) {
    const { paragraph } = props.data;
    const { fullScreen } = useContext(LayoutContext);
    const isPortrait = window.innerHeight > window.innerWidth || !fullScreen;
    return (
        <Div big superpad>
            <HDiv content={paragraph} gutter />
            <Div>
                <a
                    href={props.data.link?.btnLink || props.data.link}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Button
                        primary
                        fluid={isPortrait}
                        normal={isPortrait ? undefined : +true}
                    >
                        {props.data.link?.text || "Go"}
                    </Button>
                </a>
            </Div>
        </Div>
    );
}
