import React from "react";
import _ from "lodash";
import { Div } from "@components/Generics.react";
import { Image, Tab } from "semantic-ui-react";

export default function TabbedHome(props) {
    const { header, image, description, sections } = props;
    const panes = _.map(sections, (section) => ({
        menuItem: section.props.header,
        render: () => {
            const ShowPage = section.page;
            return (
                <Tab.Pane style={{ padding: 0 }}>
                    <ShowPage {...section.props} />
                </Tab.Pane>
            );
        },
    }));

    // ========================= Render Function =================================
    return (
        <Div className="tabbed-home">
            <Div fluid relative rimmed>
                <Div snubbed noOverflow>
                    <Image fluid src={image} />
                </Div>
                <Div
                    absolute
                    big
                    bold
                    uppercase
                    className="root-layout-header"
                >
                    {header}
                </Div>
            </Div>
            <Div white padSides gapBottom>
                <Div gapTop gutter>
                    {description}
                </Div>
            </Div>
            <Div>
                <Tab
                    className="root-layout-tabs"
                    menu={{
                        attached: false,
                        tabular: false,
                        pointing: true,
                    }}
                    panes={panes}
                />
            </Div>
        </Div>
    );
}
