import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useEditUserMutation } from "@api/apiV6";
import _ from "lodash";
import { toast } from "react-toastify";
import { settings } from "@config/settings/app.settings";

import { Div, Button } from "@components/Generics.react";
import { Modal, Icon, Input, Progress } from "semantic-ui-react";

export default function EditDetailsModal(props) {
    const { isOpen, setOpen, user, users } = props;
    const detailParams = settings.detailParams;

    const { t } = useTranslation("common");
    const [details, setDetails] = useState(
        user && user.details ? user.details : {}
    );
    const [percent, setPercent] = useState(0);
    const [manager, setManager] = useState(
        user && user.parent ? user.parent.username : ""
    );

    const [editUser] = useEditUserMutation();

    async function tryUpdate() {
        if (user != null) {
            const updateResponse = await editUser({
                id: user._id,
                details: _.omit(details, "_id"),
                manager: manager,
            });
            if (updateResponse) {
                toast("User Details Updated");
                setOpen(false);
            }
        }
        if (users != null) {
            let processedUsers = 0;
            for (let i = 0; i < users.length; i++) {
                const updateResponse = await editUser({
                    id: users[i],
                    details: _.omit(details, "_id"),
                    manager: manager,
                });
                if (updateResponse) processedUsers++;
                setPercent(parseInt((processedUsers * 100) / users.length, 0));
            }
            toast(`${processedUsers} users details Updated`);
            setOpen(false);
        }
    }

    // ========================= Render Function =================================
    return (
        <Modal size="small" open={isOpen} onClose={() => setOpen(false)}>
            <Modal.Header>
                {t("admin.userMgmt.editUser")} Details
                <Div float-right clickable>
                    <Icon name="times" onClick={() => setOpen(false)} />
                </Div>
            </Modal.Header>
            <Modal.Content>
                <Div wd="99%" snug key={`detail-param-manager`}>
                    <Input
                        fluid
                        label="Manager Username"
                        placeholder="Please put in the USERNAME of the manager on the system"
                        value={manager}
                        onChange={(e) => setManager(e.target.value)}
                    />
                </Div>
                {_.map(_.keys(detailParams), (dp, idx) => {
                    return (
                        <Div fluid snug key={`detail-param-${idx}`}>
                            <Input
                                fluid
                                label={detailParams[dp].name}
                                value={details[dp]}
                                onChange={(e) =>
                                    setDetails({
                                        ...details,
                                        [dp]: e.target.value,
                                    })
                                }
                            />
                        </Div>
                    );
                })}
                {users && (
                    <Div gapTop>
                        <Progress size="large" percent={percent} indicating />
                    </Div>
                )}
                <br />
                <Button primary content="Update User" onClick={tryUpdate} />
            </Modal.Content>
        </Modal>
    );
}
