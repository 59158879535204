import { Div } from "@components/Generics.react";
import { text_truncate } from "@utilities/helpers";
import CompletionIcon from "@components/CompletionIcon.react";

export default function CourseHeader(props) {
    const { course, compact, noTrench = false } = props;
    const isPortrait = window.innerHeight > window.innerWidth;

    // ========================= Render Function =================================
    return (
        <Div
            fluid
            style={{ fontSize: isPortrait || compact ? "0.9em" : "1em" }}
            trench={isPortrait && !noTrench}
        >
            <Div wd={"calc(100% - 80px)"}>
                <Div massive altText uppercase txtCharcoal gutter>
                    {text_truncate(course.name, 60)}
                </Div>
            </Div>
            <Div className="course-progress-radial" padded={isPortrait}>
                <CompletionIcon
                    completed={course.currentState.completion}
                    percentage={course?.currentState?.viewedPercentage}
                    size={isPortrait || compact ? 40 : 70}
                />
                {course.currentState.completion &&
                    course.currentState.score !== null &&
                    course.currentState.score > 0 && (
                        <Div
                            center-align
                            success
                            style={{
                                marginTop: "-40px",
                                width: "40px",
                                height: "40px",
                                position: "relative",
                                fontSize: "16px",
                                padding: "10px 6px",
                                borderRadius: "50%",
                            }}
                        >
                            {course.currentState.score}%
                        </Div>
                    )}
            </Div>
            {!isPortrait && course.description != null && (
                <Div clear trench>
                    {course.description}
                </Div>
            )}
        </Div>
    );
}
