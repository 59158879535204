// Import from NPM
// -------------------------------------
import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import { Div } from "@components/Generics.react";
import "@styles/navbars.scss";
import "@styles/icomoon.css";
import { darkStyle } from "@utilities/helpers";

import { useTranslation } from "react-i18next";
import { settings } from "@config/settings/app.settings";
import QuickLinks from "./QuickLinks.react";

export default function LeftMenu(props) {
    const user = useSelector((state) => state.auth.user);
    const { t } = useTranslation("common");

    // ========================= Render Function =================================
    return (
        <Div
            fullht
            className="qd-left-menu"
            style={darkStyle(user.branding?.colors?.main || settings.colors.main)}
        >
            <Div className="core-menu">
                {_.map(
                    _.filter(
                        settings.menu.core,
                        (tLink) =>
                            tLink.hideInStage == null ||
                            !tLink.hideInStage.includes(user?.stage.toString())
                    ),
                    (mainlink, index) => {
                        return (
                            <NavLink
                                key={`main-menu-link-${index}`}
                                to={mainlink.link}
                                className="core-link"
                            >
                                <i
                                    className={`icon icm icon-${mainlink.icon}`}
                                />
                                {t(`sidebar.core.${mainlink.transCode}`)}
                            </NavLink>
                        );
                    }
                )}
            </Div>
            <Div className="quick-menu">
                <QuickLinks vertical />
            </Div>
        </Div>
    );
}
