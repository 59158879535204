import React, { useState, useMemo } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useGetDeckLearnerDataQuery } from "@api/apiV6";
import { Dropdown } from "semantic-ui-react";
import abilityMap from "@config/features/ability.json";

import { Div } from "@components/Generics.react";
import CompletionRadials from "../components/CompletionRadials.react";
import LearnerTable from "../components/LearnerTable.react";

export default function CourseContent(props) {
    const { course } = props;
    const user = useSelector((state) => state.auth.user);
    const [selectedFile, setSelectedFile] = useState(null);

    const { data: learnerData, refetch } = useGetDeckLearnerDataQuery({
        userId: user._id,
        courseId: course._id,
        deckId: selectedFile,
        scope: _.includes(
            abilityMap.analytics.universal,
            user.license.license_type
        )
            ? "universe"
            : "team",
    });
    const { t } = useTranslation("common");
    const isPortrait = window.innerHeight > window.innerWidth;

    const fileList = useMemo(
        () =>
            _.flatMap(course.locations, (topic) => {
                return _.map(topic.contentList, (deck) => {
                    return {
                        key: deck._id,
                        text: topic.name + " :: " + deck.name,
                        value: deck._id,
                        readertype: deck.readerType,
                        dname: deck.name,
                    };
                });
            }),
        [course]
    );
    const aggregateData = useMemo(
        () =>
            learnerData
                ? {
                      enrolled_team: learnerData.length,
                      accessed_by: _.filter(learnerData, (learner) => {
                          return parseInt(learner.view_attempts, 0) > 0;
                      }).length,
                      completed_by: _.filter(learnerData, "complete").length,
                      total_views: _.sumBy(learnerData, "view_attempts"),
                      not_started_by: _.filter(learnerData, (learner) => {
                          return learner.completion === 0;
                      }).length,
                      time_spent: _.sumBy(learnerData, "time_spent"),
                      average_score: parseInt(
                          _.sumBy(learnerData, "score") / learnerData.length,
                          0
                      ),
                      average_completion:
                          learnerData.length > 0
                              ? parseInt(
                                    _.sumBy(learnerData, "completion") /
                                        learnerData.length,
                                    0
                                )
                              : 0,
                  }
                : {},
        [learnerData]
    );

    return (
        <Div>
            <Div flex clearfix gutter spaceBetween>
                <Div wd="190px" big uppercase bold>
                    {t("analytics.selectFile")}
                </Div>
                <Div wd={`calc(100% - 210px)`} bold>
                    <Dropdown
                        placeholder={t("analytics.selectContent")}
                        search
                        fluid
                        selection
                        upward={isPortrait}
                        options={fileList}
                        value={selectedFile}
                        onChange={(e, { value }) => setSelectedFile(value)}
                    />
                </Div>
            </Div>
            <Div flex spaceBetween>
                <CompletionRadials aggregateData={aggregateData} user={user} />
                <Div
                    wd="calc(100% - 150px)"
                    maxHt={`${window.innerHeight - 330}px`}
                    autoOverflowY
                >
                    {selectedFile == null ? (
                        <Div center-align large bold megapad>
                            {t("analytics.selectContent")}
                        </Div>
                    ) : (
                        <LearnerTable
                            course={course}
                            header={
                                _.find(fileList, { key: selectedFile }).dname
                            }
                            data={learnerData}
                            refetch={refetch}
                            rows={5}
                        />
                    )}
                </Div>
            </Div>
        </Div>
    );
}
