import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import "@styles/readers.scss";
import { Div } from "@components/Generics.react";

export default function PodcastReader(props) {
    let dummyData = {
        blob: {
            audio: "https://file-examples.com/storage/fec91aecf9647d0f79cc0bb/2017/11/file_example_MP3_700KB.mp3",
            transcript:
                "It can be easy to get lost in the world of sales terminology. As with any field, when you’re first introduced to the lingo, it can feel like learning a new language. Understanding the key differences between similar terms can be a challenge in its own right.",
        },
    };

    const { blob } = dummyData;

    const { currentState, recordAttempt } = props;
    const { t } = useTranslation("common");

    useEffect(() => {
        window.addEventListener("beforeunload", trackData);

        return () => {
            trackData();
            window.removeEventListener("beforeunload", trackData);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function trackData(status) {
        recordAttempt({
            completion: currentState.completion === 100 ? 100 : 0,
        });
    }

    return (
        <>
            <Div
                fluid
                fullht
                normal
                left-align
                white
                className="podcast-reader"
                relative
                centered
                scrollOverflow
                priText
            >
                <Div center-align centered rounded gutter className="audio-box">
                    <audio
                        id="podcast"
                        className="audio-player"
                        src={blob?.audio}
                        controls
                        controlsList="nodownload noplaybackrate"
                    />
                </Div>
                <Div big bold normal paletteColor="grey">
                    {t("deck.podcast.transcript")}
                </Div>
                <Div small centered left-align gutter padded>
                    {blob?.transcript}
                </Div>
            </Div>
        </>
    );
}
