import React, { useContext } from "react";
import _ from "lodash";
import { Div, Image } from "@components/Generics.react";
import { clientAssetsPath } from "@api/apiV6";
import { LayoutContext } from "@layouts/MasterLayout.react";

export default function IconList(props) {
    const { iconlist } = props.data;
    const { fullScreen } = useContext(LayoutContext);
    const isPortrait = window.innerHeight > window.innerWidth || !fullScreen;

    const getOptions = _.map(iconlist, (item, index) => {
        return (
            <li
                key={`list-item-${index}`}
                style={{
                    listStyle: "none",
                    clear: isPortrait ? "both" : "none",
                    width: isPortrait ? "100%" : "33%",
                    float: isPortrait ? "none" : "left",
                }}
            >
                <Div flex spaceBetween>
                    <Div wd="20%" gutter rounded noOverflow>
                        {item.image && (
                            <Image
                                fluid
                                src={
                                    _.startsWith(item.image, "data:image")
                                        ? item.image
                                        : `${clientAssetsPath}/uploads/qdf/slides/${item.image.id}${item.image.extension}`
                                }
                            />
                        )}
                    </Div>
                    <Div wd="75%" gutter big>
                        {item.text}
                    </Div>
                </Div>
            </li>
        );
    });

    return (
        <Div fluid fullht zoom padTop>
            <ul style={{ marginLeft: "-15px" }}>{getOptions}</ul>
        </Div>
    );
}
