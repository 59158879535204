// Import from NPM
// -------------------------------------
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFeatures } from "flagged";
import { Dropdown, Label, Icon as XIcon } from "semantic-ui-react";
import { toast } from "react-toastify";

// Import from Config
// -------------------------------------
import {
    apiV6,
    useSendTrackerMutation,
    useGetSessionsQuery,
    useGetUnreadCountQuery,
} from "@api/apiV6";
import { trackClear } from "@reducers/trackerSlice";
import { clearAttempt } from "@reducers/attemptSlice";

import { darkStyle, text_truncate } from "@utilities/helpers";
import { Div, Icon, Button } from "@components/Generics.react";
import "@styles/navbars.scss";
import { settings } from "@config/settings/app.settings";

// Import Components
// -------------------------------------
import UserAvatar from "./UserAvatar.react";
import Notifications from "./navbar/Notifications.react";

export default function Topbar(props) {
    const { name, rootPage = false, isolated = false, closeDeck } = props;
    const user = useSelector((state) => state.auth.user);
    const tracker = useSelector((state) => state.tracker);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const features = useFeatures();
    const { t } = useTranslation("common");

    const username = `${user?.first_name} ${user?.last_name}`;
    const isPortrait = window.innerWidth < window.innerHeight;

    const [notifications, setNotifications] = useState(false);
    const { data: unread } = useGetUnreadCountQuery(user._id);
    //---------------------------------------------------------------------------
    // Sync Data with server when the tracker is set to pushable or when called manually. Also sync sessions.
    // Please Note: This makes the Navbar a control element. It must always be included in all pages.
    //---------------------------------------------------------------------------
    useGetSessionsQuery(user._id);
    const [sendTracker] = useSendTrackerMutation();

    useEffect(() => {
        (async () => {
            if (tracker.pushable) await syncData();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tracker.pushable]);

    async function syncData() {
        try {
            await sendTracker({ trackers: tracker }).unwrap();
            await dispatch(trackClear());
            await dispatch(clearAttempt());
        } catch (err) {
            console.error(`Failed to send trackers to server. Reason: ${err}`);
        }
    }
    async function resyncData() {
        try {
            await syncData();
            await dispatch(apiV6.util.resetApiState());
            await dispatch(apiV6.endpoints.getArticles.initiate());
            await dispatch(apiV6.endpoints.getCourseLibrary.initiate());
            toast("Progress updated to server and content refreshed!");
        } catch (err) {
            console.error(`Failed to send trackers to server. Reason: ${err}`);
        }
    }
    // ========================= Render Function =================================
    return (
        <Div
            fluid
            className="qd-nav builder"
            style={darkStyle(user.branding?.colors?.main || settings.colors.main)}
        >
            <Div fullht className="nav-inner" slightShadow>
                {isPortrait && !rootPage && (
                    <Icon
                        name="chevron left"
                        large
                        float-left
                        fitted
                        padTop
                        onClick={() => (closeDeck ? closeDeck() : navigate(-1))}
                    />
                )}
                <Div compact fullht nudgeLeft className="pagename">
                    <Div inline altText>
                        {`${name ? name : t("appName")}${
                            features.tenanting.multiTenanted && user.branding?.name
                                ? " - " + user.branding?.name
                                : ""
                        }`}
                    </Div>
                </Div>
                <Div float-right nudgeRight style={{ marginTop: "-10px" }}>
                    <Div inlineBlock nudgeLeft fitted clickable>
                        <XIcon
                            name="sync"
                            title="Refresh platform data"
                            onClick={resyncData}
                        />
                    </Div>
                    <Div inlineBlock nudgeLeft padTop fitted>
                        {!isolated && (
                            <Dropdown
                                inline
                                trigger={
                                    <Div>
                                        <Icon name="bell" />
                                        {unread > 0 && (
                                            <Label
                                                color="blue"
                                                size="mini"
                                                floating
                                            >
                                                {unread.count}
                                            </Label>
                                        )}
                                    </Div>
                                }
                                className="notification-icon"
                                direction="left"
                                scrolling
                                onClick={() => setNotifications(true)}
                                onClose={() => setNotifications(false)}
                            >
                                <Dropdown.Menu>
                                    {notifications ? (
                                        <Notifications />
                                    ) : (
                                        <Div />
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                        {features.general.subscribable && (
                            <Div inlineBlock nudgeRight fitted clickable>
                                <Label>{user.license.license_name} Plan</Label>
                                <Button
                                    primary
                                    size="small"
                                    content={t("builder.welcome.upgrade")}
                                    icon={"caret square up"}
                                    labelPosition={"right"}
                                    onClick={() => navigate("/builder/account")}
                                />
                            </Div>
                        )}
                        <span className="username">
                            {text_truncate(username, 18)}
                        </span>
                        <UserAvatar
                            name={username}
                            image={user.image}
                            avatarConfig={user.avatarConfig}
                            size={32}
                        />
                        <Dropdown
                            pointing
                            direction="left"
                            icon="ellipsis vertical"
                            className="ellipsis"
                        >
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    icon={"pencil"}
                                    text={t("navbar.editProfile")}
                                    onClick={() =>
                                        navigate(
                                            `/editProfile${
                                                isolated ? "/update" : "/avatar"
                                            }`
                                        )
                                    }
                                />
                                <Dropdown.Item
                                    icon={"user"}
                                    text={t("navbar.learnerPanel")}
                                    as="a"
                                    href={`${settings.targetRoute}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                />
                                {features.ability.adminPanel.visible &&
                                    !isPortrait && (
                                        <Dropdown.Item
                                            icon={"dashboard"}
                                            text={t("navbar.adminPanel")}
                                            onClick={() =>
                                                navigate("/adminPanel")
                                            }
                                        />
                                    )}
                                {isPortrait &&
                                    settings.functionality.helpdeskActive && (
                                        <Dropdown.Item
                                            icon={"headphones"}
                                            text={t("navbar.needHelp")}
                                            onClick={() =>
                                                navigate("/app/helpdesk")
                                            }
                                        />
                                    )}
                                {!settings.functionality.noLogout && (
                                    <Dropdown.Item
                                        icon={"log out"}
                                        text={t("navbar.logout")}
                                        onClick={() => {
                                            const exitRoute =
                                                settings.auth.routes.logout;
                                            if (exitRoute.includes("http"))
                                                window.location.href =
                                                    exitRoute;
                                            else navigate(exitRoute);
                                        }}
                                    />
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Div>
                </Div>
            </Div>
        </Div>
    );
}
