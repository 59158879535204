import React, { useContext } from "react";
import _ from "lodash";
import { SlideContext } from "@layouts/SlideLayout.react";
import { Div, HDiv } from "@components/Generics.react";
import { gradient } from "@utilities/helpers";
import "@styles/readers.scss";
import { clientAssetsPath } from "@api/apiV6";

export default function BackgroundImage(props) {
    const { background, paragraph, position } = props.data;
    const { colors, isPreview } = useContext(SlideContext);
    const positioning = position?.split(" ") || ["center", "center"];
    const popAnimation =
        positioning[1] === "center" && !isPreview
            ? "zoom"
            : `slide${positioning[1] === "left" ? "Right" : "Left"}`;
    const img =
        background && !_.startsWith(background, "data:image")
            ? `url(${clientAssetsPath}/uploads/qdf/slides/${background.id}${background.extension})`
            : `url(${background})`;

    // ========================= Render Function =================================
    return (
        <Div
            fluid
            fullht
            big
            flex
            column
            relative
            style={{
                backgroundImage: img,
                backgroundSize: "cover",
            }}
            className="background-image-template"
        >
            <Div
                wd="80%"
                noOverflow
                className={`content-position ${positioning.join("-")}`}
            >
                <HDiv
                    superpad
                    popAnimation={popAnimation}
                    slowAnim
                    style={{
                        background: gradient(
                            colors?.primary,
                            "to top left",
                            true
                        ),
                        color: colors?.primaryText,
                    }}
                    content={paragraph}
                />
            </Div>
        </Div>
    );
}
