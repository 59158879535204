import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Input, Segment, Tab } from "semantic-ui-react";
import { Div, Button, Image } from "@components/Generics.react";
import UserAvatar from "@components/UserAvatar.react";

const SocialUploader = ({ onSubmit }) => {
    const user = useSelector((state) => state.auth.user);

    const [postType, setPostType] = useState("text");
    const [postContent, setPostContent] = useState("");
    const [postLink, setPostLink] = useState("");
    const [postImages, setPostImages] = useState([]);
    const [postVideo, setPostVideo] = useState("");
    const [category, setCategory] = useState("text");
    const [ownerId] = useState("");
    const [image, setImage] = useState(null);

    const username = `${user?.first_name} ${user?.last_name}`;

    const handleTabChange = (e, { activeIndex }) => {
        const postTypes = ["text", "link", "image", "video"];
        setPostType(postTypes[activeIndex]);
        setPostContent("");
        setPostLink("");
        setPostImages([]);
        setPostVideo("");

        switch (postTypes[activeIndex]) {
            case "text":
                setCategory("text");
                break;
            case "link":
                setCategory("link");
                break;
            case "image":
                setCategory("image");
                break;
            case "video":
                setCategory("video");
                break;
            default:
                setCategory("text");
        }
    };
    const handleTextChange = (e) => {
        setPostContent(e.target.value);
    };

    const handleLinkChange = (e) => {
        setPostLink(e.target.value);
    };

    // const handleImageChange = (editedImage) => {
    //     setPostImages([editedImage]);
    // };

    // const handleVideoChange = (video) => {
    //     setPostVideo(video);
    // };

    const handleUrlChange = (e) => {
        setPostLink(e.target.value);
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const base64Url = new FileReader();
        base64Url.onloadend = () => {
            setImage(base64Url.result);
        };
        base64Url.readAsDataURL(file);
    };

    const handleSubmit = () => {
        let content = postLink;
        if (category === "image") {
            content = [
                {
                    image: image,
                    _destroy: false,
                },
            ];
        }

        const postData = {
            category: category,
            content: content,
            description: postContent,
            owner_id: ownerId,
            postType,
            postContent,
            postLink,
            postImages,
            postVideo,
        };

        onSubmit(postData);

        setPostType("text");
        setPostContent("");
        setPostLink("");
        setPostImages([]);
        setPostVideo("");
        setImage(null);
    };

    // Helper function to determine if the current post type has empty attributes
    const isPostAttributesEmpty = () => {
        switch (postType) {
            case "text":
                return !postContent;
            case "link":
                return !postLink || !postContent;
            case "image":
                return !image || !postContent;
            case "video":
                return !postLink || !postContent;
            default:
                return false;
        }
    };

    const panes = [
        {
            menuItem: {
                key: "text",
                icon: "file text",
                content: "",
            },
            render: () => (
                <Tab.Pane>
                    <Segment>
                        <h5>Add your post</h5>
                        <Input
                            placeholder="Please type in your post here"
                            value={postContent}
                            onChange={handleTextChange}
                            rows={10}
                            style={{ width: "100%" }}
                        />
                    </Segment>
                    <Button
                        disabled={isPostAttributesEmpty()}
                        content="Post"
                        primary
                        onClick={handleSubmit}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: {
                key: "link",
                icon: "linkify",
                content: "",
            },
            render: () => (
                <Tab.Pane>
                    <Segment>
                        <h5>Add a link address</h5>
                        <Input
                            fluid
                            placeholder="Type in your Link here"
                            value={postLink}
                            onChange={handleLinkChange}
                        />
                        <h5>Add a note about the link</h5>
                        <Input
                            placeholder="Please write something about the link target here"
                            value={postContent}
                            onChange={handleTextChange}
                            rows={10}
                            style={{ width: "100%" }}
                        />
                    </Segment>
                    <Button
                        disabled={isPostAttributesEmpty()}
                        content="Post"
                        primary
                        onClick={handleSubmit}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: {
                key: "image",
                icon: "image",
                content: "",
            },
            render: () => (
                <Tab.Pane>
                    <Segment>
                        <h5>Add an image</h5>
                        <div>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageUpload}
                            />
                            {image && (
                                <Image
                                    className="social-image-post"
                                    src={image}
                                    alt="Uploaded Image"
                                />
                            )}
                        </div>
                        <Input
                            placeholder="Please write something about the image here"
                            value={postContent}
                            onChange={handleTextChange}
                            style={{ marginTop: "1rem", width: "100%" }}
                        />
                    </Segment>
                    <Button
                        disabled={isPostAttributesEmpty()}
                        content="Post"
                        primary
                        onClick={handleSubmit}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: {
                key: "video",
                icon: "video",
                content: "",
            },
            render: () => (
                <Tab.Pane>
                    <Segment>
                        <h5>Add a video URL</h5>
                        <Input
                            fluid
                            placeholder="Type in the URL of the video here"
                            value={postLink}
                            onChange={handleUrlChange}
                        />
                        <h5>Add a description for the video</h5>
                        <Input
                            placeholder="Please write something about the video here"
                            value={postContent}
                            onChange={handleTextChange}
                            rows={10}
                            style={{ width: "100%" }}
                        />
                    </Segment>
                    <Button
                        disabled={isPostAttributesEmpty()}
                        content="Post"
                        primary
                        onClick={handleSubmit}
                    />
                </Tab.Pane>
            ),
        },
    ];

    return (
        <Div>
            <Div fit-content float-left relative layer={2}>
                <UserAvatar
                    name={username}
                    image={user.image}
                    avatarConfig={user.avatarConfig}
                    size={64}
                />
            </Div>

            <Div white rounded gutter className="social-uploader-container">
                <Tab
                    className="social-uploader-container-tab"
                    menu={{ secondary: true, pointing: true }}
                    panes={panes}
                    activeIndex={panes.findIndex(
                        (pane) => pane.menuItem.key === postType
                    )}
                    onTabChange={handleTabChange}
                />
            </Div>
        </Div>
    );
};

export default SocialUploader;
