// Import from NPM
// -------------------------------------
import React from "react";
import _ from "lodash";
import { Div, Icomoon } from "@components/Generics.react";
import "@styles/editor.scss";

export default function OneTwoBtns(props) {
    const { btns, name, leftBordered = true } = props;
    // ========================= Render Function =================================
    return (
        <Div fit-content className="ribbon-btn" leftBordered={leftBordered}>
            <Div
                ivory
                slightShadow
                snubbed
                fit-content
                center-align
                clickable
                flex
            >
                <Div
                    basepad
                    ivory
                    snubbed
                    fit-content
                    center-align
                    clickable
                    className="sub-btn"
                    onClick={btns[0].action}
                >
                    <Div large>
                        <Icomoon name={btns[0].icon} />
                    </Div>
                    <Div small>{btns[0].name}</Div>
                </Div>
                <Div medpad left-align leftBordered style={{ marginTop: "2px" }}>
                    {_.map(btns.slice(1, 3), (btn, idx) => {
                        return (
                            <Div
                                compact
                                small
                                className="sub-text-btn"
                                key={`${btn.name.replace(" ", "-")}-${idx}`}
                                onClick={btn.action}
                            >
                                <Div inline>
                                    <Icomoon name={btn.icon} />
                                </Div>{" "}
                                <Div inline>{btn.name}</Div>
                            </Div>
                        );
                    })}
                </Div>
            </Div>
            <Div small center-align gapTop>
                {name}
            </Div>
        </Div>
    );
}
