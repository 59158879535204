import React, { useState, useContext } from "react";
import { LayoutContext } from "@layouts/MasterLayout.react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { trackQuestion } from "@reducers/trackerSlice";
import { clientAssetsPath } from "@api/apiV6";
import { Div, HDiv, Image } from "@components/Generics.react";
import ResponseOverlay from "@components/ResponseOverlay.react";

export default function PickPicture(props) {
    const {
        data,
        masterSeq,
        slideSeq,
        trackData,
        deckId,
        pageNumber,
        setPageNumber,
        mustOverlay = false,
    } = props;
    const [correct, setCorrect] = useState(null);
    const [showOverlay, setShowOverlay] = useState(false);
    const [thisResponse, setThisResponse] = useState(null);
    const userId = useSelector((state) => state.auth.user._id);
    const dispatch = useDispatch();
    const { t } = useTranslation("common");
    const { fullScreen } = useContext(LayoutContext);
    const isPortrait = window.innerHeight > window.innerWidth || !fullScreen;

    function trackInteraction(selected) {
        const isCorrect = data.imageOpts[selected]["correct"] === "checked";
        setCorrect(isCorrect);

        if (
            mustOverlay ||
            (data.feedback != null &&
                data.feedback.length > 0 &&
                data.purpose !== "Provide Insight")
        ) {
            setThisResponse({
                correct: isCorrect,
                response: selected,
            });
            setShowOverlay(true);
        } else {
            setPageNumber(pageNumber + 1);
            if (trackData) {
                trackData({
                    correct: isCorrect,
                    response: selected,
                });
            }
        }

        dispatch(
            trackQuestion({
                deckId: deckId,
                slideId: masterSeq == null ? slideSeq : masterSeq,
                userId: userId,
                response: selected,
            })
        );
    }

    function closeOverlay() {
        setPageNumber(pageNumber + 1);
        if (trackData) {
            trackData(thisResponse);
        }
    }

    const getOptions = _.map(
        _.filter(data.imageOpts, (c) => c.image != null),
        (card, idx) => {
            return (
                <Image
                    fluid
                    clickable
                    key={`list-item-${idx}`}
                    className="grid-item"
                    src={
                        card.image.id
                            ? `${clientAssetsPath}/uploads/qdf/slides/${card.image.id}${card.image.extension}`
                            : card.image
                    }
                    onClick={() => trackInteraction(idx)}
                />
            );
        }
    );

    // ========================= Render Function =================================
    return (
        <Div wd={isPortrait ? "100%" : "50%"} fullht zoom superpad centered>
            <HDiv
                big={data.question?.length < 120}
                gutter
                content={data.question}
            />
            <Div small italics gutter>
                {t("deck.qdf.singleSelect")}
            </Div>
            <Div fluid zoom className="grid-container">
                {getOptions}
            </Div>
            {showOverlay && (
                <ResponseOverlay
                    feedback={data.feedback}
                    correct={correct}
                    closeResponse={closeOverlay}
                />
            )}
        </Div>
    );
}
