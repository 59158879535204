import React, { useState, useContext } from "react";
import { LayoutContext } from "@layouts/MasterLayout.react";
import { Input } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { trackQuestion } from "@reducers/trackerSlice";
import { Div, HDiv, Button } from "@components/Generics.react";
import ResponseOverlay from "@components/ResponseOverlay.react";
export default function FITB(props) {
    const {
        data,
        masterSeq,
        slideSeq,
        trackData,
        deckId,
        pageNumber,
        setPageNumber,
        mustOverlay = false,
    } = props;
    const [correct, setCorrect] = useState(null);
    const [ansval, setAnsval] = useState("");
    const [showOverlay, setShowOverlay] = useState(false);
    const [thisResponse, setThisResponse] = useState(null);

    const userId = useSelector((state) => state.auth.user._id);
    const dispatch = useDispatch();
    const { t } = useTranslation("common");
    const { fullScreen } = useContext(LayoutContext);
    const isPortrait = window.innerHeight > window.innerWidth || !fullScreen;

    function handleChange(e) {
        setAnsval(e.target.value);
    }

    function trackInteraction() {
        const isCorrect =
            !data.answer ||
            data.answer.trim().toLowerCase() === ansval.trim().toLowerCase();
        setCorrect(isCorrect);

        if (
            mustOverlay ||
            (data.feedback != null &&
                data.feedback.length > 0 &&
                data.purpose !== "Provide Insight")
        ) {
            setThisResponse({
                correct: isCorrect,
                response: ansval,
            });
            setShowOverlay(true);
        } else {
            setPageNumber(pageNumber + 1);
            if (trackData) {
                trackData({
                    correct: isCorrect,
                    response: ansval,
                });
            }
        }
        dispatch(
            trackQuestion({
                deckId: deckId,
                slideId: masterSeq == null ? slideSeq : masterSeq,
                userId: userId,
                response: ansval,
            })
        );
    }

    function closeOverlay() {
        setPageNumber(pageNumber + 1);
        if (trackData) {
            trackData(thisResponse);
        }
    }

    // ========================= Render Function =================================
    return (
        <Div fullht zoom superpad wd={isPortrait ? "100%" : "50%"} centered>
            <HDiv
                big={data.question?.length < 120}
                gutter
                content={data.question}
            />
            <Div small italics>
                {t("deck.qdf.fitb")}
            </Div>
            <Div padTop>
                <Input
                    size="big"
                    fluid
                    placeholder={
                        data.answer ? data.answer.replace(/[^-\s]/g, "*") : ""
                    }
                    value={ansval}
                    onChange={handleChange}
                />
                <Button
                    content={"Submit"}
                    fluid
                    primary
                    normal={isPortrait ? undefined : +true}
                    onClick={trackInteraction}
                />
            </Div>
            {showOverlay && (
                <ResponseOverlay
                    feedback={data.feedback}
                    correct={correct}
                    closeResponse={closeOverlay}
                />
            )}
        </Div>
    );
}
