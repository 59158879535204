import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFeatures } from "flagged";
import { useSearchParams } from "react-router-dom";
import { useGetUserCountQuery } from "@api/apiV6";
import CreatorLayout from "@layouts/CreatorLayout.react";
import { Div, Button as XButton } from "@components/Generics.react";
import { Tab, Button, Menu } from "semantic-ui-react";
import { settings } from "@config/settings/app.settings";
import AdminUserManagement from "./panels/AdminUserManagement.react";
import AdminTeamManagement from "./panels/AdminTeamManagement.react";
import AdminTagManagement from "./panels/AdminTagManagement.react";
import AdminProgramManagement from "./panels/AdminProgramManagement.react";
import AdminArticleManagement from "./panels/AdminArticleManagement.react";
import AdminCourseManagement from "./panels/AdminCourseManagement.react";
import AdminBannerManagement from "./panels/AdminBannerManagement.react";
import AdminTicketsTable from "./panels/AdminTicketsTable.react";
import AnalyticsLearners from "./panels/AnalyticsLearners.react";
import AnalyticsCourses from "./panels/AnalyticsCourses.react";
import AnalyticsArticles from "./panels/AnalyticsArticles.react";
import OrgReports from "./panels/OrgReports.react";
import AnalyticsCreators from "./panels/AnalyticsCreators.react";
import AdminLargeUserManagement from "./panels/AdminLargeUserManagement.react";

export default function SuperAdmin(props) {
    const { t } = useTranslation("common");
    const { data, isSuccess } = useGetUserCountQuery();
    const features = useFeatures();
    const [searchParams, setSearchParams] = useSearchParams();
    const [analyticsView, setAnalyticsView] = useState(false);

    useEffect(() => {
        const thisSection = searchParams.get("section");
        setAnalyticsView(thisSection === "analytics");
    }, [searchParams]);

    const panes = analyticsView
        ? [
              {
                  menuItem: (
                      <Menu.Item
                          key="oreg-reports-tab"
                          style={{
                              background: settings.colors.main,
                              color: "#ffffff",
                              paddingRight: "60px",
                              paddingLeft: "60px",
                              borderRadius: "5px 5px 0 0",
                          }}
                      >
                          Org Reports
                      </Menu.Item>
                  ),
                  render: () => (
                      <Tab.Pane>
                          <Div big bold gutter uppercase>
                              Org Reports
                          </Div>
                          <OrgReports />
                      </Tab.Pane>
                  ),
              },
              //   features.general.subscribable &&
              data?.userCount < settings.largeUserbase && {
                  menuItem: "Creators",
                  render: () => (
                      <Tab.Pane>
                          <Div big bold gutter uppercase>
                              Creators Analytics
                          </Div>
                          <AnalyticsCreators />
                      </Tab.Pane>
                  ),
              },
              data?.userCount < settings.largeUserbase && {
                  menuItem: "Learners",
                  render: () => (
                      <Tab.Pane>
                          <Div big bold gutter uppercase>
                              Learners Analytics
                          </Div>
                          <AnalyticsLearners />
                      </Tab.Pane>
                  ),
              },
              data?.userCount < settings.largeUserbase && {
                  menuItem: "Courses",
                  render: () => (
                      <Tab.Pane>
                          <Div big bold gutter uppercase>
                              Course Analytics
                          </Div>
                          <AnalyticsCourses />
                      </Tab.Pane>
                  ),
              },
              data?.userCount < settings.largeUserbase && {
                  menuItem: "Articles",
                  render: () => (
                      <Tab.Pane>
                          <Div big bold gutter uppercase>
                              Article Analytics
                          </Div>
                          <AnalyticsArticles />
                      </Tab.Pane>
                  ),
              },
          ].filter(Boolean)
        : [
              {
                  menuItem: "Users",
                  render: () => (
                      <Tab.Pane>
                          {isSuccess &&
                          data?.userCount < settings.largeUserbase ? (
                              <AdminUserManagement />
                          ) : (
                              <AdminLargeUserManagement />
                          )}
                      </Tab.Pane>
                  ),
              },
              {
                  menuItem: "Teams",
                  render: () => (
                      <Tab.Pane>
                          <AdminTeamManagement />
                      </Tab.Pane>
                  ),
              },
              features.learningCenter && {
                  menuItem: "Tags",
                  render: () => (
                      <Tab.Pane>
                          <AdminTagManagement />
                      </Tab.Pane>
                  ),
              },
              !features.programs.noPrograms && {
                  menuItem: "Programs",
                  render: () => (
                      <Tab.Pane>
                          <AdminProgramManagement />
                      </Tab.Pane>
                  ),
              },
              features.learningCenter && {
                  menuItem: "Articles",
                  render: () => (
                      <Tab.Pane>
                          <AdminArticleManagement />
                      </Tab.Pane>
                  ),
              },
              features.courseware && {
                  menuItem: "Courses",
                  render: () => (
                      <Tab.Pane>
                          <AdminCourseManagement />
                      </Tab.Pane>
                  ),
              },
              {
                  menuItem: "Banners",
                  render: () => (
                      <Tab.Pane>
                          <AdminBannerManagement />
                      </Tab.Pane>
                  ),
              },
              {
                  menuItem: "Ticket Center",
                  render: () => (
                      <Tab.Pane>
                          <AdminTicketsTable />
                      </Tab.Pane>
                  ),
              },
          ].filter(Boolean);

    const mainPanel = (
        <Div fluid basepad fullht autoOverflowY relative>
            <Div gutter flex spaceBetween>
                <Div bold large uppercase>
                    Organization{" "}
                    {!analyticsView ? "Administration" : "Analytics"}
                </Div>
                <Div>
                    <Button.Group>
                        <XButton
                            primary={!analyticsView}
                            content="Administration"
                            onClick={() => {
                                setAnalyticsView(false);
                                setSearchParams({ section: "administration" });
                            }}
                        />
                        <Button.Or />
                        <XButton
                            primary={analyticsView}
                            content="Analytics"
                            onClick={() => {
                                setAnalyticsView(true);
                                setSearchParams({ section: "analytics" });
                            }}
                        />
                    </Button.Group>
                </Div>
            </Div>
            <Tab
                key={analyticsView ? "analytics-view" : "admin-view"}
                panes={panes}
            />
        </Div>
    );

    // ========================= Render Function =================================
    return (
        <CreatorLayout
            title={`${t("appCreator")} Admin`}
            navName={t("navbar.adminPanel")}
            mainPanel={mainPanel}
            rootPage={false}
            editMode={true}
        />
    );
}
