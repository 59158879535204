import React from "react";
import _ from "lodash";
import { Grid, Divider } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import MasterLayout from "@layouts/MasterLayout.react";
import { Div } from "@components/Generics.react";
import Banner from "@components/Banner.react";
import { useGetBannersQuery } from "@api/apiV6";
import GeneralHome from "./panels/GeneralHome.react";
import { settings } from "@config/settings/app.settings";
import QuickLinks from "@components/QuickLinks.react";
import RecommendedArticles from "@components/RecommendedArticles.react";
import SearchField from "@components/SearchField.react";
import ListHome from "./panels/ListHome.react";
import SwipeHome from "./panels/SwipeHome.react";
// import BlocksHome from "./panels/BlocksHome.react";
import FeedHome from "./panels/FeedHome.react";
import FaqHome from "./panels/FaqHome.react";
import CarouselHome from "./panels/CarouselHome.react";
import TabbedHome from "./panels/TabbedHome.react";

export default function RootPage(props) {
    const { data: banners, isSuccess: bannerSuccess } = useGetBannersQuery();
    const user = useSelector((state) => state.auth.user);
    const { t } = useTranslation("common");
    const { section } = useParams();

    const stages = settings.gamification.stages;
    const showStage = _.keys(stages).length > 1;

    const isPortrait = window.innerHeight > window.innerWidth;

    const pageMap = {
        home: {
            page: GeneralHome,
            props: null,
            banner: true,
            search: true,
        },
        learn: {
            page: CarouselHome,
            banner: false,
            search: false,
            props: {
                header: t("home.learnHome.title"),
                description: t("home.learnHome.description"),
                tags: t("home.learnHome.tags").split(", "),
                image: "/assets/images/configurable/rootPages/learn.jpg",
            },
        },
        products: {
            page: TabbedHome,
            banner: false,
            search: false,
            props: {
                header: t("home.pnoHome.title"),
                description: t("home.pnoHome.description"),
                tags: t("home.pnoHome.tags").split(", "),
                image: "/assets/images/configurable/rootPages/products.jpg",
                sections: [
                    {
                        page: FaqHome,
                        banner: false,
                        search: false,
                        props: {
                            subPage:true,
                            header: t("home.productsHome.title"),
                            // description: t("home.productsHome.description"),
                            tags: t("home.productsHome.tags").split(", "),
                            // image: "/assets/images/configurable/home/librarybanner.png",
                        },
                    },
                    {
                        page: FeedHome,
                        banner: false,
                        search: false,
                        props: {
                            subPage:true,
                            header: t("home.offersHome.title"),
                            // description: t("home.offersHome.description"),
                            tags: t("home.offersHome.tags").split(", "),
                            // image: "/assets/images/configurable/home/librarybanner.png",
                        },
                    },
                ],
            },
        },
        jobaids: {
            page: ListHome,
            banner: false,
            search: false,
            props: {
                header: t("home.aidsHome.title"),
                description: t("home.aidsHome.description"),
                tags: t("home.aidsHome.tags").split(", "),
                image: "/assets/images/configurable/rootPages/jobaids.jpg",
            },
        },
        challenges: {
            page: SwipeHome,
            banner: false,
            search: false,
            props: {
                header: t("home.challengesHome.title"),
                description: t("home.challengesHome.description"),
                tags: t("home.challengesHome.tags").split(", "),
                image: "/assets/images/configurable/rootPages/challenges.jpg",
            },
        },
    };

    const ShowPage = pageMap[section].page;

    const pageContent = (
        <Div padTop={isPortrait}>
            <Grid stackable>
                {pageMap[section].banner && (
                    <Grid.Row>
                        <Grid.Column width={8}>
                            {bannerSuccess && <Banner ads={banners} />}
                        </Grid.Column>
                        {!isPortrait && (
                            <Grid.Column width={8}>
                                <Div headline altText gutter>
                                    {t("home.title")}
                                </Div>
                                <Div trench>{t("home.context")}</Div>
                                {showStage && (
                                    <Div
                                        charcoal
                                        relaxed
                                        uppercase
                                        center-align
                                        rounded
                                    >
                                        {`${t("home.stage")} ${t(
                                            `stages.${user.stage}`
                                        )}`}
                                    </Div>
                                )}
                            </Grid.Column>
                        )}
                    </Grid.Row>
                )}
                {isPortrait && pageMap[section].search && (
                    <Grid.Row>
                        <Grid.Column width={16}>
                            {!settings.functionality.hasHome && <QuickLinks />}
                            <Divider horizontal style={{ margin: 0 }}>
                                <SearchField />
                            </Divider>
                        </Grid.Column>
                    </Grid.Row>
                )}
            </Grid>
            <br />
            {<ShowPage {...pageMap[section].props} />}
        </Div>
    );

    // ========================= Render Function =================================
    return (
        <MasterLayout
            title={`Welcome to ${t("appName")}`}
            mainPanel={pageContent}
            rightPanelName={t(`components.recommended`)}
            rightPanel={<RecommendedArticles />}
            hidePanelToggle
            rootPage={!isPortrait || !settings.functionality.hasHome}
        />
    );
}
