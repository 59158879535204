// Import from NPM
// -------------------------------------
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Div } from "@components/Generics.react";
import { Message } from "semantic-ui-react";
export default function FileField(props) {
    const { target, header, help, value, setValue, height, accepts, maximum } =
        props;
    const [error, setError] = useState(null);
    const { t } = useTranslation("common");

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > maximum * 1000) {
                setError(true);
            } else {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const base64String = e.target.result;
                    setValue(
                        target == null
                            ? base64String
                            : { [target]: base64String }
                    );
                };
                reader.readAsDataURL(file);
            }
        }
    };
    // ========================= Render Function =================================
    return (
        <Div minHt={height} snug basepad ivory>
            <Div big rimmed>
                {header}
                {help && (
                    <Div gapSlice tiny italics txtHalf>
                        {help}
                    </Div>
                )}
            </Div>
            {error && <Message error>{t("builder.deck.tooLarge")}</Message>}
            <Div smoke ht={"calc(100% - 60px)"} medpad relative>
                <input
                    type="file"
                    accept={accepts}
                    onChange={handleFileUpload}
                    style={{ width: "100%", cursor: "pointer" }}
                />
                <Div italics small gapTop>
                    {t("builder.deck.maxFile")}: {maximum}kB
                </Div>
                {value != null && value.length > 0 && (
                    <Div
                        wd="calc(100% - 15px)"
                        absolute
                        padded
                        secondary
                        slightShadow
                        style={{
                            top: "5px",
                            left: "5px",
                            pointerEvents: "none",
                        }}
                    >
                        {t("builder.deck.replaceFile")}
                    </Div>
                )}
            </Div>
        </Div>
    );
}
