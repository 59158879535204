import { useTranslation } from "react-i18next";
import { Divider, Popup } from "semantic-ui-react";
import "@styles/components.scss";
import "@styles/icomoon.css";

import { Div } from "@components/Generics.react";

export default function Badge(props) {
    const { deck, disabled } = props;
    const { t } = useTranslation("common");

    // ========================= Render Function =================================
    return (
        <Popup
            position="top center"
            on="click"
            trigger={
                <div className="badge">
                    <div
                        className="ring"
                        title={`${deck.content?.name}: ${deck.content?.description} - ${deck.content?.points} Points`}
                        style={{
                            background: disabled
                                ? "#666666"
                                : `radial-gradient(${deck.content?.bgColor}, ${deck.content?.bgColor}aa)`,
                        }}
                    >
                        <div
                            className="surface"
                            style={{
                                background: disabled
                                    ? "#454545"
                                    : `radial-gradient(${deck.content?.fgColor}66, ${deck.content?.fgColor})`,
                            }}
                        >
                            <i
                                className={`icm ${deck.content?.icon} icon`}
                                style={{
                                    color: disabled
                                        ? "#333333"
                                        : deck.content?.textColor,
                                }}
                            />
                        </div>
                    </div>
                </div>
            }
        >
            <Popup.Header>{deck.name}</Popup.Header>
            <Popup.Content>
                <Div>{deck.content?.description}</Div>
                <Divider />
                <Div float-right txtCharcoal>
                    {deck.content?.points} {t("appCollectibles.points")}
                </Div>
            </Popup.Content>
        </Popup>
    );
}
