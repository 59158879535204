import React from "react";
import _ from "lodash";
import { Div } from "@components/Generics.react";

export default function BulletList(props) {
    const { bullets } = props.data;
    const getOptions = _.map(bullets, (item, index) => {
        return <li key={index}>{item.bullets ? item.bullets : item}</li>;
    });

    return (
        <Div fluid fullht zoom big superpad>
            <ul style={{ marginLeft: "-15px" }}>{getOptions}</ul>
        </Div>
    );
}
