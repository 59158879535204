// Import from NPM
// -------------------------------------
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import axios from "axios";
import { baseUrl, useGetCreditsQuery } from "@api/apiV6";

import _ from "lodash";
import { Div, Button } from "@components/Generics.react";
import { Dropdown, Dimmer, Loader } from "semantic-ui-react";
import { settings } from "@config/settings/app.settings";

export default function AISTranslate(props) {
    const { deck, setDeck, setRightPanel } = props;
    const token = useSelector((state) => state.auth.token);
    const { data: aiStatus, isSuccess, refetch } = useGetCreditsQuery();
    const { t } = useTranslation("common");

    const [fetching, setFetching] = useState(false);
    const [langValue, setLangValue] = useState("English");
    const whitelist = [
        "title",
        "subtitle",
        "paragraph",
        "splash",
        "blurb",
        "bullets",
        "rebullets",
        "caption",
        "conversation",
        "question",
        "feedback",
        "text",
        "answer",
    ]; // keys you want to translate

    const creditsNeeded = Math.ceil(
        (JSON.stringify(deck.content).length - 50 * deck.content.length - 100) /
            50
    );

    // Function to send all texts to the translation API
    // async function translateAllTexts(texts) {
    //     // Replace this function with your actual translation API implementation (doTranslate)
    //     const translatedTexts = await doTranslate(texts);
    //     return translatedTexts;
    // }

    async function doTranslate(texts) {
        const response = await axios({
            method: "post",
            url: `${baseUrl}api/v3/ai/transSlidesJson`,
            data: {
                source: texts,
                lang: langValue,
                cost: creditsNeeded,
            },
            headers: { "access-token": token },
        });
        return JSON.parse(response.data.response.message.content);
    }

    async function getAIResponse() {
        setFetching(true);
        const transOut = await translateAndReconstructJson(deck.content);
        setDeck({
            ...deck,
            ...{ content: transOut },
        });
        setFetching(false);
        refetch();
        setRightPanel("mobile");
    }

    function extractValuesForTranslation(obj, keysToTranslate) {
        let values = [];
        let paths = [];

        function traverse(currentObj, currentPath) {
            if (Array.isArray(currentObj)) {
                currentObj.forEach((item, index) => {
                    traverse(item, currentPath.concat([index]));
                });
            } else if (typeof currentObj === "object" && currentObj !== null) {
                Object.keys(currentObj).forEach((key) => {
                    if (keysToTranslate.includes(key)) {
                        values.push(currentObj[key]);
                        paths.push(currentPath.concat([key]));
                    }
                    traverse(currentObj[key], currentPath.concat([key]));
                });
            }
        }

        traverse(obj, []);
        return { values, paths };
    }

    function reconstructWithTranslatedValues(obj, translatedValues, paths) {
        const result = JSON.parse(JSON.stringify(obj)); // deep copy

        paths.forEach((path, index) => {
            let currentObj = result;
            path.slice(0, -1).forEach((key) => {
                currentObj = currentObj[key];
            });
            currentObj[path[path.length - 1]] = translatedValues[index];
        });

        return result;
    }

    async function translateAndReconstructJson(jsonObj) {
        const { values, paths } = extractValuesForTranslation(
            jsonObj,
            whitelist
        );
        const translatedValues = await doTranslate(values);
        return reconstructWithTranslatedValues(
            jsonObj,
            translatedValues,
            paths
        );
    }

    // ========================= Render Function =================================
    return (
        <Div
            minWd="400px"
            wd="400px"
            fullht
            relative
            peat
            basepad
            ht={`calc(100vh - 225px)`}
            darkInset
        >
            <Div white fullht rounded basepad slightShadow>
                <Div bold large compact uppercase>
                    {t("builder.deck.aistrans")}:
                </Div>
                <Div italics gutter compact>
                    {t("builder.deck.aistransHelp")}
                </Div>
                {isSuccess && (
                    <Div rounded>
                        <Dropdown
                            placeholder={t("builder.dic.selectLanguage")}
                            fluid
                            selection
                            options={_.map(
                                _.filter(
                                    settings.languages,
                                    (l) => l.code !== "en"
                                ),
                                (l) => ({
                                    key: l.code,
                                    text: l.identifier,
                                    value: l.identifier,
                                })
                            )}
                            value={langValue}
                            onChange={(e, { value }) => setLangValue(value)}
                        />
                        <br />
                        <Button
                            size="small"
                            primary
                            onClick={getAIResponse}
                            disabled={aiStatus.credits <= 0}
                        >
                            Generate ({creditsNeeded}{" "}
                            {creditsNeeded === 1 ? "credit" : "credits"})
                        </Button>
                        <Div
                            small
                            italics
                            float-right
                            nudgeRight
                            ht="40px"
                            flex
                        >
                            {aiStatus.credits} generative credits left
                        </Div>
                    </Div>
                )}
                {fetching && (
                    <Div superpad ivory>
                        <Dimmer active>
                            <Loader>
                                This will take time. Please be patient...
                            </Loader>
                        </Dimmer>
                    </Div>
                )}
            </Div>
        </Div>
    );
}
