import { Div, Icon, Image } from "@components/Generics.react";
import { useSelector } from "react-redux";
import "@styles/components.scss";
import { text_truncate, darkStyle } from "@utilities/helpers";
import CompletionIcon from "./CompletionIcon.react";
import { settings } from "@config/settings/app.settings";

export default function TopicCard(props) {
    const {
        locIndex,
        topic,
        status,
        clickable,
        handleClick,
        cardStyle = false,
        feedStyle = false,
        descriptions = false,
        completed = false,
    } = props;
    const user = useSelector((state) => state.auth.user);

    const topicDetails = (
        <Div fluid>
            <Div float-left nudgeRight>
                {completed ? (
                    <Icon name="check circle" />
                ) : (
                    <CompletionIcon
                        completed={topic?.currentState?.completion}
                        percentage={topic?.currentState?.viewedPercentage}
                    />
                )}
            </Div>
            <Div float-left nudgeLeft className="topic-name">
                {text_truncate(topic.name, 40)}
            </Div>
            {!feedStyle && <Icon float-right big name="chevron right" />}
        </Div>
    );

    // ========================= Render Function =================================
    return (
        <Div
            hintShadow={!cardStyle && !feedStyle}
            slightShadow={cardStyle || feedStyle}
            rounded={cardStyle || feedStyle}
            noOverflow
            gutter={cardStyle || feedStyle}
            clickable
            compact={!cardStyle && !feedStyle}
            white={status === "complete"}
            primary={status === "current"}
            className={`topic-card ${!clickable && "disabled"}`}
            onClick={() => clickable && handleClick(locIndex)}
            style={
                status === "lastopen" &&
                darkStyle(user.branding?.colors?.main || settings.colors.main)
            }
        >
            {cardStyle ? (
                <Div fluid>
                    <Image fluid src={topic.image} />
                    <Div padded clearfix>
                        {topicDetails}
                    </Div>
                    {descriptions && (
                        <Div relaxed white>
                            {topic.description}
                        </Div>
                    )}
                </Div>
            ) : feedStyle ? (
                <Div fluid clearfix flex spaceBetween>
                    <Image fluid src={topic.image} wd="40%" />
                    <Div wd="55%">{topicDetails}</Div>
                </Div>
            ) : (
                <Div fluid>{topicDetails}</Div>
            )}
        </Div>
    );
}
