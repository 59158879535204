// Import from NPM
// -------------------------------------
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "lodash";

import { Div } from "@components/Generics.react";
import { Input, Dropdown } from "semantic-ui-react";
export default function ButtonField(props) {
    const {
        target,
        header,
        help,
        value,
        setValue,
        height,
        flatField = false,
    } = props;
    const articles = useSelector((state) => state.articles);

    const [isMounted, setIsMounted] = useState(false);
    const [textValue, setTextValue] = useState(
        target && value && !flatField ? value[target]?.text || "" : value?.text
    );
    const [linkValue, setLinkValue] = useState(
        target && value && !flatField
            ? value[target]?.btnLink || ""
            : value?.btnLink
    );
    const { t } = useTranslation("common");

    // Debouncing the user input
    //==================================
    useEffect(() => {
        setIsMounted(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        const updatedValue = { text: textValue, btnLink: linkValue };
        const timeoutId = setTimeout(() => {
            if (isMounted)
                setValue(
                    target && !flatField
                        ? { [target]: updatedValue }
                        : updatedValue
                );
        }, 500);
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textValue, linkValue]);

    // ========================= Render Function =================================
    return (
        <Div minHt={height} ht={height} basepad ivory snug>
            <Div big rimmed>
                {header}
                {help && (
                    <Div tiny italics txtHalf gapSlice>
                        {help}
                    </Div>
                )}
            </Div>
            <Div ivory rimmed ht={"calc(100% - 60px)"} fluid>
                <Div flex gapped>
                    <Div wd="calc(50% - 2.5px)">
                        <Input
                            fluid
                            value={textValue || ""}
                            label={t("builder.deck.buttonTxt")}
                            onChange={(e) => setTextValue(e.target.value)}
                        />
                    </Div>
                    <Div wd="calc(50% - 2.5px)">
                        <Input
                            fluid
                            value={linkValue || ""}
                            onChange={(e) => setLinkValue(e.target.value)}
                            label={
                                <Dropdown
                                    search
                                    placeholder={t("builder.article.selectArticle")}
                                    options={_.map(articles.list, (a) => ({
                                        key: a._id,
                                        text: a.name,
                                        value: `/${a.category}/${a.identifier}`,
                                    }))}
                                    onChange={(e, { value }) => setLinkValue(value)}
                                    className="link-input-field"
                                />
                            }
                        />
                    </Div>
                </Div>
            </Div>
        </Div>
    );
}
