import React, { useState } from "react";
import _ from "lodash";
import {
    Form,
    Modal,
    Input,
    Select,
    List,
    Icon
} from "semantic-ui-react";
import {
    useSendAddEnrollmentRuleMutation,
} from "@api/apiV6";

import { settings } from "@config/settings/app.settings";
import { Div, Button } from "@components/Generics.react";

const EnrollRuleModal = (props) => {
    const { isOpen, setOpen, course } = props;
    const [addEnrollmentRule] = useSendAddEnrollmentRuleMutation();

    const [searchObject, setSearchObject] = useState({});
    const [searchStructure] = useState(filterdetails(settings?.detailParams));

    const handleSubmit = () => {
        addEnrollmentRule({ id: course?._id, criteria: searchObject });
        setOpen(false);
        setSearchObject({});
    };

    const handleSearchObjectChange = (e, { name, value }) => {
        const updatedSearchObject = _.cloneDeep(searchObject);
        updatedSearchObject[name] = value === "" ? undefined : value;
        setSearchObject(updatedSearchObject);
    };

    return (
        <Modal
            size="small"
            open={isOpen}
            onClose={() => {
                setOpen(false);
                setSearchObject({});
            }}
        >
            <Modal.Header>
                {`Setup Enrollment Rules for ${course?.name}`}
                <Div float-right clickable>
                    <Icon
                        name="times"
                        onClick={() => {
                            setOpen(false);
                            setSearchObject({});
                        }}
                    />
                </Div>
            </Modal.Header>
            <Modal.Content>
                <Form onSubmit={handleSubmit}>
                    <Modal.Content>
                        <Div bgColor="#f5f5f5" compact gutter>
                            <Div bold big priText>Define criteria for enrolment into the course</Div>
                            <List style={{ fontSize: "0.85em" }}>
                                {_.map(_.keys(searchStructure), (key) => {
                                    if (searchStructure[key].enum === undefined) {
                                        return (
                                            <List.Item key={key}>
                                                <Form.Field inline>
                                                    <Input
                                                        fluid
                                                        name={key}
                                                        label={toProper(key).replace("_", " ")}
                                                        defaultValue={searchObject[key]}
                                                        onChange={handleSearchObjectChange}
                                                        placeholder="Put in Search Value"
                                                    />
                                                </Form.Field>
                                            </List.Item>
                                        );
                                    } else {
                                        return (
                                            <List.Item key={key}>
                                                <Form.Field inline>
                                                    <Select
                                                        fluid
                                                        clearable
                                                        name={key}
                                                        label={toProper(key).replace("_", " ")}
                                                        options={_.map(searchStructure[key].enum, (opt) => ({
                                                            key: opt,
                                                            value: opt,
                                                            text: opt
                                                        }))}
                                                        onChange={handleSearchObjectChange}
                                                        value={searchObject[key]}
                                                        placeholder={`${toProper(key).replace("_", " ")}: Put in Search Value`}
                                                    />
                                                </Form.Field>
                                            </List.Item>
                                        );
                                    }
                                })}
                            </List>
                        </Div>
                    </Modal.Content>
                    <Button
                        disabled={_.filter(_.keys(searchObject), (k) => searchObject[k] !== undefined).length === 0}
                        primary
                        fluid
                        content={"Submit"}
                    />
                </Form>
            </Modal.Content>
        </Modal>
    );
};

const toProper = (word) => {
    return (
        word.trim().charAt(0).toUpperCase() +
        word.trim().slice(1).toLowerCase()
    );
};

const filterdetails = (details) => {
    let filteredetails = {};
    let keys = Object.keys(details);

    for (let i = 0; i < keys.length; i++) {
        let key = settings.detailParams[keys[i]];
        if (key.enablerule) {
            filteredetails[keys[i]] = key;
        }
    }
    return filteredetails;
};

export default EnrollRuleModal;
