import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetTeamUsersQuery } from "@api/apiV6";
import _ from "lodash";

import { Div, Button } from "@components/Generics.react";
import { Loader, Input } from "semantic-ui-react";

export default function AdminTeamDetails(props) {
    const { selectedTeam, setShowDesign } = props;
    const { data, isSuccess } = useGetTeamUsersQuery(selectedTeam);
    const [searchText, setSearchText] = useState("");
    const { t } = useTranslation("common");

    // ========================= Render Function =================================
    return (
        <Div smoke basepad>
            <Div bold gutter>
                Team Members
            </Div>
            <Button
                primary
                fluid
                content="Update Team Design"
                onClick={() => setShowDesign(true)}
            />
            <br />
            <Input
                fluid
                placeholder={t("builder.dic.searchWithin")}
                icon={"search"}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
            />
            {isSuccess ? (
                _.map(
                    _.filter(data, (d) => _.includes(d.username, searchText)),
                    (tm, idx) => {
                        return (
                            <Div key={`tmn-${idx}`} padded white snug>
                                {tm.username}
                            </Div>
                        );
                    }
                )
            ) : (
                <Loader />
            )}
        </Div>
    );
}
