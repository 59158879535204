import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { toast } from "react-toastify";

import { Icon, Checkbox, Input } from "semantic-ui-react";
import { Div, Button } from "@components/Generics.react";
import LearnerTable from "./LearnerTable.react";
import { settings } from "@config/settings/app.settings";
export default function ContestLearners(props) {
    const { contest, updateContest } = props;

    const { t } = useTranslation("common");
    const [showLaunch, setShowLaunch] = useState(true);
    const [search, setSearch] = useState("");

    async function launchContest() {
        await updateContest({
            id: contest._id,
            launch: !contest.launch,
        }).unwrap();
        toast(
            contest.launch
                ? t("builder.contest.unlaunched")
                : t("builder.contest.launched")
        );
    }

    // ========================= Render Function =================================
    return (
        <Div fluid fullht padTop flex noWrap spaceAround>
            <Div fullht wd="calc(100% - 500px)" basepad>
                <Div large uppercase gutter bold>
                    {t("components.editing")}: {contest?.name}
                </Div>
                <Div ivory fluid snubbed noOverflow flex>
                    <Div
                        fluid
                        basepad
                        danger={!contest.launch}
                        success={contest.launch}
                        snug
                        snubbed
                    >
                        <Div
                            clickable
                            onClick={() => setShowLaunch(!showLaunch)}
                        >
                            <Div float-right large>
                                <Icon
                                    name={
                                        showLaunch
                                            ? "chevron down"
                                            : "chevron up"
                                    }
                                />
                            </Div>
                            <Div bold big>
                                {t("builder.contest.status")}:{" "}
                                {contest.launch
                                    ? t("builder.contest.published")
                                    : t("builder.contest.unpublished")}
                            </Div>
                            <Div italics gutter>
                                {contest.launch
                                    ? t("builder.contest.publishedHelp")
                                    : t("builder.contest.unpublishedHelp")}
                            </Div>
                        </Div>
                        {showLaunch && (
                            <Div vapor basepad snubbed>
                                <Checkbox
                                    toggle
                                    label={
                                        contest.launch
                                            ? t("builder.contest.launch")
                                            : t("builder.contest.unlaunch")
                                    }
                                    checked={contest.launch}
                                    onChange={launchContest}
                                />
                                <br />
                                <br />
                                <Div white small slightShadow basepad snubbed>
                                    <Div big bold>
                                        {t(
                                            `builder.contest.${
                                                contest.launch
                                                    ? "unLaunch"
                                                    : "doLaunch"
                                            }.header`
                                        )}
                                    </Div>
                                    <Div>
                                        {t(
                                            `builder.contest.${
                                                contest.launch
                                                    ? "unLaunch"
                                                    : "doLaunch"
                                            }.message`
                                        )}
                                    </Div>
                                    <ul>
                                        {_.map(
                                            t(
                                                `builder.contest.${
                                                    contest.launch
                                                        ? "unLaunch"
                                                        : "doLaunch"
                                                }.points`
                                            ).split("||"),
                                            (pt, idx) => {
                                                return (
                                                    <li
                                                        key={`launch-pt-${idx}`}
                                                    >
                                                        {pt}
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </Div>
                            </Div>
                        )}
                    </Div>
                </Div>
                <Div ivory fluid snubbed noOverflow>
                    <Div fluid basepad ash snug snubbed>
                        <Div bold big gutter>
                            {t("builder.contest.enruleHeader")}:
                        </Div>
                        <Div gapBottom>{t("builder.contest.enruleBody1")}</Div>
                        <Div gutter>{t("builder.contest.enruleBody2")}</Div>
                        <Button
                            primary
                            fluid
                            content={t("builder.contest.addEnrule")}
                        />
                    </Div>
                </Div>
            </Div>
            <Div fullht wd="500px" basepad>
                <Div
                    basepad
                    ivory
                    fluid
                    fullht
                    rounded
                    slightShadow
                    autoOverflowY
                >
                    <Div bold big uppercase>
                        {t("builder.contest.manageLearners")}
                    </Div>
                    <Div fluid gapTop flex gapped spaceBetween gutter>
                        <Input
                            icon="search"
                            placeholder={t("components.search")}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <Div>
                            <Button
                                primary
                                content={t("builder.contest.invite")}
                            />
                            <Button primary content={t("builder.dic.upload")} />
                        </Div>
                    </Div>
                    {contest.learnerCount > settings.largeUserbase ? (
                        <Div
                            white
                            fluid
                            center-align
                            superpad
                            flex
                            column
                            gapped
                            big
                            slightShadow
                        >
                            <Div gutter>{t("builder.contest.tooHigh")}</Div>
                            <Button
                                primary
                                content={t("builder.contest.checkEnrolled")}
                            />
                            <Button
                                primary
                                content={t("builder.contest.unenrollUser")}
                            />
                            <Button
                                primary
                                content={t("builder.contest.messageUser")}
                            />
                        </Div>
                    ) : (
                        <LearnerTable
                            contest={contest}
                            updateContest={updateContest}
                            search={search}
                        />
                    )}
                </Div>
            </Div>
        </Div>
    );
}
