import React, { useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "lodash";
import ReactTable from "react-table-v6";
import { Div, Button } from "@components/Generics.react";
import { Icon } from "semantic-ui-react";
import EngagementRadials from "../components/EngagementRadials.react";
export default function ArticleAudience(props) {
    const { article, audienceData, refetch } = props;
    const user = useSelector((state) => state.auth.user);
    const { t } = useTranslation("common");
    const reactTableRef = useRef(null);
    let fullColumns = [
        {
            Header: "Name",
            accessor: "name",
            minWidth: 128,
        },
        {
            Header: "Views",
            id: "view_attempts",
            accessor: (d) => d.view_attempts,
            style: { textAlign: "center" },
        },
        {
            Header: "Like",
            id: "likes",
            accessor: (d) => d.likes,
            style: { textAlign: "center" },
        },
        {
            Header: "Dislike",
            id: "dislikes",
            accessor: (d) => d.dislikes,
            style: { textAlign: "center" },
        },
    ].filter(Boolean);

    const aggregateData = useMemo(
        () =>
            audienceData
                ? {
                      liked_by: audienceData.likes,
                      disliked_by: audienceData.dislikes,
                      commented_by: audienceData.comments,
                      teamCount: audienceData.viewers.length,
                  }
                : {},
        [audienceData]
    );

    const downloadCSV = () => {
        const items = _.map(
            reactTableRef.current.getResolvedState().sortedData,
            "_original"
        );

        if (items.length > 0) {
            const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
            const header = Object.keys(_.omit(items[0], "time_spent"));
            let csv = items.map((row) =>
                header
                    .map((fieldName) =>
                        JSON.stringify(row[fieldName], replacer)
                    )
                    .join(",")
            );
            csv.unshift(header.join(","));
            csv = csv.join("\r\n");
            var hiddenElement = document.createElement("a");
            hiddenElement.href =
                "data:text/csv;charset=utf-8," + encodeURI(csv);
            hiddenElement.target = "_blank";
            hiddenElement.download = `ArticleAudience-${article._id}.csv`;
            hiddenElement.click();
        }
    };
    return (
        <Div
            maxHt={`${window.innerHeight - 280}px`}
            autoOverflowY
            rounded
            compact
            gapTop
        >
            <Div flex spaceBetween>
                <EngagementRadials aggregateData={aggregateData} user={user} />
                <Div
                    wd="calc(100% - 150px)"
                    maxHt={`${window.innerHeight - 330}px`}
                    autoOverflowY
                >
                    <Div rounded compact>
                        <Div flex spaceBetween gapBottom>
                            <Div bold left-align big uppercase>
                                <Div inline small clickable>
                                    <Icon
                                        circular
                                        inverted
                                        name="sync"
                                        onClick={refetch}
                                    />
                                </Div>{" "}
                                {t("analytics.audienceAnalytics")}
                            </Div>
                            <Button
                                primary
                                small={+true}
                                onClick={downloadCSV}
                                icon="download"
                                labelPosition="right"
                                content="Download"
                            />
                        </Div>
                        <ReactTable
                            ref={reactTableRef}
                            data={audienceData.viewers}
                            filterable
                            defaultFilterMethod={(filter, row, column) => {
                                const id = filter.pivotId || filter.id;
                                return row[id] !== undefined
                                    ? String(row[id])
                                          .toLowerCase()
                                          .includes(filter.value.toLowerCase())
                                    : true;
                            }}
                            pageSizeOptions={[5, 7, 10, 15, 30, 50]}
                            columns={fullColumns}
                            defaultPageSize={5}
                            className="-striped -highlight"
                        />
                    </Div>
                </Div>
            </Div>
        </Div>
    );
}
