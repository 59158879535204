import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Dropdown, Radio } from "semantic-ui-react";
import { avatarSchema, avatarLabels } from "@schemas/avatarSchema";
import Avatar from "avataaars";
import { Div } from "@components/Generics.react";
import ColorBlock from "@components/ColorBlock.react";
import { gradient } from "@utilities/helpers";

export default function AvatarCreator(props) {
    const { avatarConfig, onChange } = props;
    const { t } = useTranslation("common");

    const [male, setMale] = useState(true);
    const [avatarProps, setAvatarProps] = useState(
        avatarConfig || {
            background: "#ffffff",
            topType: "ShortHairShortFlat",
            accessoriesType: "Blank",
            hairColor: "BrownDark",
            facialHairType: "Blank",
            facialHairColor: "BrownDark",
            clotheType: "ShirtCrewNeck",
            clotheColor: "Black",
            eyeType: "Default",
            eyebrowType: "Default",
            mouthType: "Default",
            skinColor: "Light",
        }
    );

    useEffect(() => {
        onChange(avatarProps);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [avatarProps]);

    function handleChange(e, { name, value }) {
        setAvatarProps({
            ...avatarProps,
            [name]: value,
        });
    }
    function changeBgColor(name, color) {
        setAvatarProps({
            ...avatarProps,
            background: color.hex,
        });
    }
    function changeGender() {
        setMale(!male);
        if (!male)
            setAvatarProps({
                ...avatarProps,
                background: "#ffffff",
                topType: "ShortHairShortFlat",
                facialHairType: "BeardLight",
            });
        else
            setAvatarProps({
                ...avatarProps,
                topType: "LongHairBob",
                facialHairType: "Blank",
            });
    }
    return (
        <Div centered center-align>
            <Div
                centered
                pureCircle
                wd={"128px"}
                ht="128px"
                style={{ background: gradient(avatarProps.background) }}
            >
                <Avatar
                    style={{
                        width: `128px`,
                        height: `128px`,
                    }}
                    avatarStyle="transparent"
                    topType={avatarProps.topType}
                    accessoriesType={avatarProps.accessoriesType}
                    hairColor={avatarProps.hairColor}
                    facialHairType={avatarProps.facialHairType}
                    clotheType={avatarProps.clotheType}
                    clotheColor={avatarProps.clotheColor}
                    eyeType={avatarProps.eyeType}
                    eyebrowType={avatarProps.eyebrowType}
                    mouthType={avatarProps.mouthType}
                    skinColor={avatarProps.skinColor}
                />
            </Div>
            <br />
            <Div>
                <Div
                    ash
                    fit-content
                    centered
                    gutter
                    style={{ padding: "14px" }}
                >
                    <ColorBlock
                        color={avatarProps.background}
                        colorName="Circle Color"
                        onChange={changeBgColor}
                    />
                </Div>
                <Div
                    ash
                    relaxed
                    fit-content
                    gapBottom
                    centered
                    key={`gender-option`}
                >
                    <Div float-left nudgeRight>
                        {t("components.male")}{" "}
                    </Div>
                    <Radio slider onChange={changeGender} />
                    <Div float-right nudgeLeft>
                        {" "}
                        {t("components.female")}
                    </Div>
                </Div>
            </Div>
            <Div clear>
                <br />
                <hr />
                <br />
                {_.map(_.keys(avatarSchema), (option, idx) => {
                    const optionList = _.map(
                        avatarSchema[option][male ? "male" : "female"],
                        (elm) => {
                            return {
                                key: elm,
                                value: elm,
                                text: elm,
                            };
                        }
                    );
                    if (optionList.length > 0)
                        return (
                            <Div
                                fitted
                                inlineBlock
                                gapBottom
                                key={`avatar-option-${idx}`}
                            >
                                <Dropdown
                                    button
                                    name={option}
                                    className="icon"
                                    floating
                                    icon="chevron down"
                                    options={optionList}
                                    text={avatarLabels[idx]}
                                    onChange={handleChange}
                                    value={avatarProps[option]}
                                />
                            </Div>
                        );
                    else return <span key={`avatar-option-${idx}`} />;
                })}
            </Div>
        </Div>
    );
}
