import { configureStore, combineReducers } from "@reduxjs/toolkit";
import localforage from "localforage";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";

import { setupListeners } from "@reduxjs/toolkit/query";
import { apiV6 } from "../api/apiV6";

// Reducers from the App
import authReducer from "@reducers/authSlice";
import articlesReducer from "@reducers/articlesSlice";
import attemptReducer from "@reducers/attemptSlice";
import trackerReducer from "@reducers/trackerSlice";

const rootReducer = combineReducers({
    [apiV6.reducerPath]: apiV6.reducer,
    auth: authReducer,
    attempt: attemptReducer,
    articles: articlesReducer,
    tracker: trackerReducer,
});

const persistConfig = {
    key: "root",
    storage: localforage,
    blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }).concat(apiV6.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export const persistor = persistStore(store);
