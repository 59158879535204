// Import from NPM
// -------------------------------------
import React from "react";
import { useFeatures } from "flagged";
import { useNavigate } from "react-router-dom";

import { Div } from "@components/Generics.react";
import "@styles/courseLayout.scss";

export default function LogoBlock(props) {
    const { isolated, user, logo } = props;
    const features = useFeatures();
    const navigate = useNavigate();
    const isPortrait = window.innerWidth < window.innerHeight;

    // ========================= Render Function =================================
    return (
        <Div
            float-left
            rounded={!isPortrait}
            white
            className="logo-block"
            onClick={() => navigate(isolated ? "#" : "/")}
        >
            <img
                src={
                    features.tenanting.multiTenanted
                        ? user.branding?.logo ||
                          "/assets/images/configurable/logo.png"
                        : logo || "/assets/images/configurable/logo.png"
                }
                alt="Brand Logo"
                className="logo"
            />
        </Div>
    );
}
