import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
    Image,
    Modal,
    Progress,
    Icon,
    Statistic,
    Loader,
} from "semantic-ui-react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ReactTable from "react-table-v6";
import {
    useGetLearnerCourseProgressQuery,
    useGetAssessmentsStatusQuery,
} from "@api/apiV6";
import _ from "lodash";
import { toast } from "react-toastify";
import { Div } from "@components/Generics.react";
import "@styles/analytics.scss";
import { nameToHex, getAvatar } from "@utilities/helpers";
import Badge from "@components/Badge.react";
import Certificate from "@components/Certificate.react";
import Reward from "@components/Reward.react";
export default function CourseIndividual(props) {
    const { open, setOpen, user, course } = props;
    const userId = user.id || user._id;
    const reactTableRef = useRef(null);
    const {
        data: progressData,
        isSuccess,
        isLoading,
        refetch,
    } = useGetLearnerCourseProgressQuery({
        userId: userId,
        courseId: course._id,
    });
    const {
        data: assessmentsData,
        isSuccess: asmSuccess,
        refetch: asmFetch,
    } = useGetAssessmentsStatusQuery({
        userId: userId,
        courseId: course._id,
    });

    const [processing, setProcessing] = useState(false);

    const {
        history,
        complete,
        started,
        time_spent,
        view_attempts,
        content_coverage,
        points,
        completed_at,
        last_accessed_at,
        deck_status,
    } = { ...progressData };
    const { t } = useTranslation("common");

    function refresh() {
        asmFetch();
        refetch();
        toast(t("analytics.refetched"));
    }

    //TODO: Add the mail report function
    // function mailReportPage(name) {
    //     setProcessing(true);
    //     let reportPageView = document.querySelector(".report-page");
    //     html2canvas(reportPageView, { useCORS: true }).then((canvas) => {
    //         let imgData = canvas.toDataURL("image/png");
    //         var pdf = new jsPDF("p", "mm", "a4", true);
    //         var width = pdf.internal.pageSize.getWidth();
    //         var height = pdf.internal.pageSize.getHeight();
    //         pdf.addImage(imgData, "PNG", 0, 0, width, height, "", "SLOW");
    //         let sendPDF = pdf.output("datauristring", name + ".pdf");
    //         mailReportPageAction(sendPDF);
    //         setProcessing(false);
    //     });
    // }
    function downloadReportPage(name) {
        setProcessing(true);
        let reportPageView = document.querySelector(".report-page");
        html2canvas(reportPageView, { useCORS: true }).then((canvas) => {
            let imgData = canvas.toDataURL("image/png");
            var pdf = new jsPDF("p", "mm", "a4", true);
            var width = pdf.internal.pageSize.getWidth();
            var height = pdf.internal.pageSize.getHeight();
            pdf.addImage(imgData, "PNG", 0, 0, width, height, "", "SLOW");
            pdf.save((name + ".pdf").replace(/ /g, "_").toLowerCase());
            setProcessing(false);
        });
    }

    const banner = (
        <Div relative className="bannerSection">
            {user && (
                <Image
                    src={
                        "/assets/images/reportBanners/" +
                        nameToHex(user?.name)[2] +
                        ".jpg"
                    }
                />
            )}
            <Div className="overlay" />
            {user && (
                <Div className="profileImage">{getAvatar(user.name, 160)}</Div>
            )}
            <Div
                absolute
                right-aligned
                big
                uppercase
                wd="50%"
                style={{ right: "0", top: 0, zIndex: 1001 }}
                flex
            >
                <Div basepad wd="calc(100% - 112.5px)">
                    <Div huge altText>
                        {course.name}
                    </Div>
                </Div>
                <Div wd="107.5px" superpad charcoal>
                    <Image
                        fluid
                        src="/assets/images/configurable/logo-inverted.png"
                        alt="Brand Logo"
                    />
                </Div>
            </Div>
            <Div
                uppercase
                bold
                basepad
                txtMidnight
                absolute
                flex
                spaceBetween
                style={{
                    left: "190px",
                    bottom: "-75px",
                    width: "calc(100% - 200px)",
                }}
            >
                <Div massive>{user?.name}</Div>
                {processing ? (
                    <Div large>{t("analytics.processing")}</Div>
                ) : (
                    <Div large clickable>
                        <Icon circular inverted name="sync" onClick={refresh} />
                        <Icon
                            circular
                            inverted
                            name="print"
                            onClick={() =>
                                downloadReportPage(`${course._id}-${user?.id}`)
                            }
                        />
                        {/* <Icon
                            circular
                            inverted
                            name="mail"
                            onClick={mailReportPage}
                        /> */}
                    </Div>
                )}
            </Div>
        </Div>
    );

    const topicProgressList = (
        <Div wd="40%" padSides>
            <Div big bold uppercase gutter charcoal relaxed>
                {t("analytics.topicProgress")}
            </Div>
            <Div flex column gapped>
                {_.times(12, (idx) => {
                    const topic = course.locations[idx];
                    const topicCompleted =
                        topic && history?.subStates[topic._id];
                    return (
                        <Div
                            fluid
                            vapor={!topic}
                            success={topicCompleted}
                            ash={topic && !topicCompleted}
                            padded
                            ht="30px"
                            key={`topic-progress-${idx}`}
                        >
                            {topic?.name}
                            {topicCompleted && (
                                <Div float-right>
                                    <Icon name="check circle" />
                                </Div>
                            )}
                        </Div>
                    );
                })}
                <Div fluid vapor ht="100px" />
            </Div>
        </Div>
    );

    function showCollectibles(readerType, Collectible) {
        const allCollectibles = deck_status[readerType + "s"].decks;
        const history = _.map(deck_status[readerType + "s"].history, "id");
        return (
            <Div wd={`${allCollectibles.length * 128}px`}>
                {_.map(allCollectibles, (elm, i) => {
                    return (
                        <Div
                            inline
                            className="small"
                            key={`${readerType}-reward-${i}`}
                        >
                            <Collectible
                                deck={elm.blob}
                                disabled={!_.includes(history, elm._id)}
                                small
                            />
                        </Div>
                    );
                })}
            </Div>
        );
    }

    const collectiblesBlock = (name, readerType, Collectible) => {
        return (
            <Div
                white
                wd="calc(100% - 30px)"
                relative
                clearfix
                ht="95px"
                style={{ marginLeft: "30px" }}
            >
                <Div
                    wd="95px"
                    charcoal
                    center-align
                    padded
                    uppercase
                    className="collectible-tag"
                >
                    {t(`analytics.collectibles.${name}.name`)}
                </Div>
                {deck_status && (
                    <Div
                        fluid
                        fullht
                        medpad
                        left-align
                        style={{ marginTop: "-30px", overflow: "auto hidden" }}
                    >
                        {deck_status[readerType + "s"].count === 0
                            ? t(`analytics.collectibles.${name}.none`)
                            : showCollectibles(readerType, Collectible)}
                    </Div>
                )}
            </Div>
        );
    };

    const rightBlock = (
        <Div wd="60%" basepad vapor>
            <Div white basepad flex spaceBetween trench>
                <Div wd="150px">
                    {t("analytics.status")}
                    <Div uppercase big bold>
                        {complete
                            ? t("analytics.complete")
                            : started
                            ? t("analytics.wip")
                            : t("analytics.notStarted")}
                    </Div>
                </Div>
                <Div wd="calc(100% - 150px)">
                    <Progress
                        size="large"
                        percent={content_coverage}
                        indicating
                    />
                </Div>
            </Div>
            <Div trench>
                <Statistic.Group size="tiny" widths={3}>
                    <Statistic>
                        <Statistic.Value>
                            <Icon name="eye" /> {view_attempts}
                        </Statistic.Value>
                        <Statistic.Label>Views</Statistic.Label>
                    </Statistic>
                    <Statistic>
                        <Statistic.Value>
                            <Icon name="clock" />
                            {time_spent === null
                                ? "0 secs"
                                : time_spent < 60
                                ? time_spent + " secs"
                                : Math.round(time_spent / 60, 0) + " mins"}
                        </Statistic.Value>
                        <Statistic.Label>Time Spent</Statistic.Label>
                    </Statistic>
                    <Statistic>
                        <Statistic.Value>
                            <Icon name="trophy" /> {points}
                        </Statistic.Value>
                        <Statistic.Label>Points</Statistic.Label>
                    </Statistic>
                </Statistic.Group>
            </Div>
            <Div center-align gutter>
                <Div
                    relaxed
                    success={
                        complete ||
                        new Date() - new Date(last_accessed_at) < 604800000
                    }
                    danger={
                        !started ||
                        (!complete &&
                            new Date() - new Date(last_accessed_at) > 604800000)
                    }
                >
                    {complete
                        ? t("analytics.completedMsg").replace(
                              "$time",
                              new Date(completed_at).toLocaleString("en-IN")
                          )
                        : !started
                        ? t("analytics.notStartedMsg")
                        : t("analytics.lastAccessedMsg").replace(
                              "$time",
                              new Date(last_accessed_at).toLocaleString("en-IN")
                          )}
                </Div>
            </Div>
            <Div fluid basepad ash flex gapped>
                {collectiblesBlock("badge", "badgedeck", Badge)}
                {collectiblesBlock("cert", "certdeck", Certificate)}
                {collectiblesBlock("reward", "rewarddeck", Reward)}
            </Div>
        </Div>
    );

    let fullColumns = [
        {
            Header: "Assessment",
            id: "name",
            accessor: (d) => d.deck.name,
            minWidth: 160,
        },
        {
            Header: "Accessed",
            id: "accessed",
            accessor: (d) => d.currentState?.viewAttempts || "-",
            Cell: ({ value }) =>
                value === "-" || value === "0"
                    ? "No"
                    : "Yes (" + value + " views)",
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                }
                if (filter.value === "yes") {
                    return parseInt(row[filter.id]) !== 0;
                }
                if (filter.value === "no") {
                    return parseInt(row[filter.id]) === 0;
                }
                return row[filter.id] === 0;
            },
            Filter: ({ filter, onChange }) => (
                <select
                    onChange={(e) => onChange(e.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">Show All</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
            ),
            style: { textAlign: "center" },
        },
        {
            Header: "Completion",
            id: "completion",
            accessor: (d) =>
                d.currentState?.completion
                    ? 100
                    : d.currentState?.viewedPercentage || "-",
            Cell: ({ value }) => (value === "-" ? "-" : value + "%"),
            filterMethod: (filter, row) => {
                const rowVal = parseInt(row[filter.id], 0);
                switch (filter.value) {
                    case "zero":
                        return rowVal === 0;
                    case "low":
                        return rowVal < 40 && rowVal > 0;
                    case "medium":
                        return rowVal < 80 && rowVal > 40;
                    case "high":
                        return rowVal < 100 && rowVal > 80;
                    case "full":
                        return rowVal === 100;
                    case "all":
                    default:
                        return true;
                }
            },
            Filter: ({ filter, onChange }) => (
                <select
                    onChange={(e) => onChange(e.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">Show All</option>
                    <option value="zero">0%</option>
                    <option value="low">0-40%</option>
                    <option value="medium">40-80%</option>
                    <option value="high">80-99%</option>
                    <option value="full">100%</option>
                </select>
            ),
            style: { textAlign: "center" },
        },
        {
            Header: "Score",
            id: "score",
            accessor: (d) => d.currentState?.score || "-",
            Cell: ({ value }) => (value === "-" ? "-" : value + "%"),
            filterMethod: (filter, row) => {
                const rowVal = parseInt(row[filter.id], 0);
                switch (filter.value) {
                    case "zero":
                        return rowVal === 0;
                    case "low":
                        return rowVal < 40 && rowVal > 0;
                    case "medium":
                        return rowVal < 80 && rowVal > 40;
                    case "high":
                        return rowVal < 100 && rowVal > 80;
                    case "full":
                        return rowVal === 100;
                    case "all":
                    default:
                        return true;
                }
            },
            Filter: ({ filter, onChange }) => (
                <select
                    onChange={(event) => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">Show All</option>
                    <option value="zero">0%</option>
                    <option value="low">0-40%</option>
                    <option value="medium">40-80%</option>
                    <option value="high">80-99%</option>
                    <option value="full">100%</option>
                </select>
            ),
            style: { textAlign: "center" },
        },
        {
            Header: "Time Spent",
            id: "time_spent",
            accessor: (d) => d.currentState?.timeSpent || "-",
            Cell: ({ value }) =>
                value === "-"
                    ? "-"
                    : value < 60
                    ? value + " secs"
                    : Math.round(value / 60, 0) + " mins",
            filterMethod: (filter, row) => row[filter.id] >= filter.value,
            style: { textAlign: "center" },
        },
    ].filter(Boolean);

    const assessmentGraphs = asmSuccess && (
        <Div
            vapor
            basepad
            relative
            clearfix
            ht="430px"
            style={{
                margin: "10px 0 0 15px",
            }}
        >
            <Div bold uppercase gapBottom>
                {t("analytics.assessmentGraphsHeader")}
            </Div>
            <Div white rimmed>
                <ReactTable
                    ref={reactTableRef}
                    data={assessmentsData}
                    filterable
                    defaultFilterMethod={(filter, row, column) => {
                        const id = filter.pivotId || filter.id;
                        return row[id] !== undefined
                            ? String(row[id])
                                  .toLowerCase()
                                  .includes(filter.value.toLowerCase())
                            : true;
                    }}
                    pageSizeOptions={[7]}
                    columns={fullColumns}
                    defaultPageSize={7}
                    className="-striped -highlight"
                />
            </Div>
        </Div>
    );

    const pageContent = (
        <Div
            txtMidnight
            big
            style={{
                margin: "90px 25px 0 10px",
            }}
        >
            {/* <Div compact gutter nudgeLeft>
                <BuilderHeader
                    image={course?.image}
                    header={` ${course?.name}`}
                    description={` ${course?.description}`}
                />
            </Div> */}
            {isLoading ? (
                <Loader />
            ) : (
                <Div>
                    <Div flex top-align>
                        {topicProgressList}
                        {rightBlock}
                    </Div>
                    <Div>{assessmentGraphs}</Div>
                </Div>
            )}
        </Div>
    );

    return (
        <Modal
            basic
            className="analytics-page"
            onClose={() => setOpen(false)}
            open={open}
        >
            <Div
                className="close-btn"
                danger
                basepad
                rightRounded
                clickable
                onClick={() => setOpen(false)}
            >
                <Icon name="close" />
            </Div>
            <Div className="report-page">
                {banner}
                {isSuccess && pageContent}
            </Div>
        </Modal>
    );
}
