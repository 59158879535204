import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    useUpdateProgramMutation,
    useGetProgramBuilderQuery,
} from "@api/apiV6";

import { Loader } from "semantic-ui-react";
import CreatorLayout from "@layouts/CreatorLayout.react";
import ProgramSetup from "./panels/ProgramSetup.react";

export default function ProgramEditor(props) {
    const { id, section } = useParams();
    const { data: program, isSuccess } = useGetProgramBuilderQuery(id);
    const [updateProgram] = useUpdateProgramMutation();
    const { t } = useTranslation("common");

    const setupPanel = isSuccess && (
        <ProgramSetup program={program} updateProgram={updateProgram} />
    );

    const panelMap = {
        setup: setupPanel,
        // editor: contentPanel,
        // Add more sections and their respective panels as needed
    };

    // ========================= Render Function =================================
    if (isSuccess)
        return (
            <CreatorLayout
                title={`Editing: ${program.name}`}
                mainPanel={panelMap[section]}
                navName={`Program ${section}`}
                pageCode={"library"}
                rootPage={false}
                editMode={true}
            />
        );
    else return <Loader active>{t("components.loading")}</Loader>;
}
