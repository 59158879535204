import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Div, HDiv, Button } from "@components/Generics.react";
import { terms } from "@schemas/termsSchema";
import { useSendAcceptTermsMutation } from "@api/apiV6";

export default function TermsPage(props) {
    const user = useSelector((state) => state.auth.user);
    const { t } = useTranslation("common");
    const [acceptTerms] = useSendAcceptTermsMutation();

    // ========================= Render Function =================================
    return (
        <Div ash fluid fullht basepad>
            <HDiv
                white
                fluid
                ht="calc(100% - 40px)"
                autoOverflow
                basepad
                content={terms}
            />
            <Button
                fluid
                primary
                content={t("auth.terms.button")}
                onClick={() => acceptTerms(user._id)}
            />
        </Div>
    );
}
