import React, { useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { List, Label } from "semantic-ui-react";
import {
    useGetNotificationsQuery,
    useSendNotificationsReadMutation,
} from "@api/apiV6";
import { Div } from "@components/Generics.react";

export default function Notifications(props) {
    const user = useSelector((state) => state.auth.user);
    const { data: notifications } = useGetNotificationsQuery(user._id);
    const [markRead] = useSendNotificationsReadMutation();
    const { t } = useTranslation("common");
    const navigate = useNavigate();
    const isPortrait = window.innerHeight > window.innerWidth;

    useEffect(() => {
        return () => markRead(user._id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function goToTarget(link) {
        if (link != null) navigate(link);
    }

    const notificationList = _.orderBy(
        notifications,
        ["read", "createdAt"],
        ["desc", "desc"]
    );
    const readCount = _.filter(notificationList, { read: true }).length;

    // ========================= Render Function =================================
    return (
        <Div
            small
            padded
            wd={isPortrait ? "300px" : "360px"}
            peat
            ht={isPortrait ? "400px" : "auto"}
            autoOverflowY
        >
            <Div gapTop bold gutter>
                {t("components.notifications")}
                <Div float-right>
                    <Label>{notificationList.length - readCount}</Label>
                </Div>
            </Div>
            <List divided>
                {notificationList.map((item, idx) => (
                    <List.Item
                        key={`notification-${idx}`}
                        onClick={() => goToTarget(item.link)}
                    >
                        <List.Content>
                            <Div
                                relaxed
                                ash={item.read}
                                white={!item.read}
                                small
                                clickable={item.link != null}
                            >
                                <Div bold>{item.title}:</Div>
                                <Div small>{item.description}</Div>
                            </Div>
                        </List.Content>
                    </List.Item>
                ))}
            </List>
        </Div>
    );
}
