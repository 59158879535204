import React from "react";
import { useParams } from "react-router-dom";
import {
    useUpdateContestMutation,
    useUpdateDeckMutation,
    useMoveDeckMutation,
    useDeleteDeckMutation,
    useDeleteTopicMutation,
    useGetContestBuilderQuery,
} from "@api/apiV6";
import { useTranslation } from "react-i18next";

import { Loader } from "semantic-ui-react";
import CreatorLayout from "@layouts/CreatorLayout.react";
import ContestSetup from "./panels/ContestSetup.react";
import ContestContent from "./panels/ContestContent.react";
import ContestLearners from "./panels/ContestLearners.react";

export default function ContestEditor(props) {
    const { id, section } = useParams();
    const { data: contest, isSuccess } = useGetContestBuilderQuery(id);
    const [updateContest] = useUpdateContestMutation();
    const [deleteTopic] = useDeleteTopicMutation();
    const [updateDeck] = useUpdateDeckMutation();
    const [moveDeck] = useMoveDeckMutation();
    const [deleteDeck] = useDeleteDeckMutation();
    const { t } = useTranslation("common");
    // console.log(contest);

    const setupPanel = isSuccess && (
        <ContestSetup contest={contest} updateContest={updateContest} />
    );
    const contentPanel = isSuccess && (
        <ContestContent
            contest={contest}
            updateContest={updateContest}
            updateDeck={updateDeck}
            moveDeck={moveDeck}
            deleteDeck={deleteDeck}
            deleteTopic={deleteTopic}
        />
    );
    const learnersPanel = isSuccess && (
        <ContestLearners contest={contest} updateContest={updateContest} />
    );
    const panelMap = {
        setup: setupPanel,
        editor: contentPanel,
        learners: learnersPanel,
        // Add more sections and their respective panels as needed
    };

    // ========================= Render Function =================================
    if (isSuccess)
        return (
            <CreatorLayout
                title={`Editing: ${contest.name}`}
                mainPanel={panelMap[section]}
                navName={`Contest ${section}`}
                pageCode={"library"}
                rootPage={false}
                editMode={true}
            />
        );
    else return <Loader active>{t("components.loading")}</Loader>;
}
