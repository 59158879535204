import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import "@styles/readers.scss";
import { Div, HDiv, Button } from "@components/Generics.react";
import { Divider, Checkbox, Input } from "semantic-ui-react";

export default function CheckListForm(props) {
    const { deck, qResponse, saveData, setShowForm } = props;
    const { t } = useTranslation("common");

    const qResponsePieces =
        qResponse && _.last(qResponse.responses).r.split("||");

    const [title, setTitle] = useState(qResponse ? qResponsePieces[0] : "");
    const [fillDate, setFillDate] = useState(
        qResponse ? parse2date(qResponsePieces[1]) : new Date(_.now())
    );
    const [itemlist, setItemList] = useState(
        _.map(deck.content?.items, (item, index) => ({
            label: item,
            checked: qResponse ? qResponsePieces[2][index] === "1" : false,
            // checked: latestResponse.includes(index),
        })) || []
    );

    function parse2date(str) {
        var year = parseInt(str.substr(6, 2));
        var month = parseInt(str.substr(3, 2));
        var day = parseInt(str.substr(0, 2));

        return new Date(year < 20 ? 2000 + year : year, month - 1, day);
    }

    function handleSave() {
        saveData(
            `${title}||${moment(fillDate).format("DD-MM-YY")}||${_.map(
                itemlist,
                (itm) => (itm.checked ? 1 : 0)
            ).join("")}`
        );
    }

    function handleCheckboxChange(e, { value }) {
        const newItems = itemlist.map((item, sidx) => {
            return {
                label: item.label,
                checked: sidx === value ? !item.checked : item.checked,
            };
        });
        setItemList(newItems);
    }

    // function downloadAsPDF() {
    //     var opt = {
    //         margin: 1,
    //         filename: checklistTitle || name,
    //         image: { type: "jpeg", quality: 0.5 },
    //         html2canvas: { scale: 1.1 },
    //         jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    //     };
    //     const element = document.getElementById("checklist");
    //     html2pdf().set(opt).from(element).save();
    //     openDeckView(deck._id);
    // }

    return (
        <Div fluid left-align compact>
            <Div ash medpad flex column gapped flexStart gutter>
                Set Name and Date for the Checklist
                <Input
                    placeholder="Please enter a name for the checklist..."
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    style={{ width: "100%" }}
                />
                <DatePicker
                    dateFormat="dd MMMM yyyy"
                    selected={fillDate && moment(fillDate).toDate()}
                    onChange={(date) => setFillDate(date)}
                />
            </Div>
            <HDiv compact content={deck.content?.instructions} />
            <Divider />
            <Div fullht id="checklist" compact>
                <Div trench>
                    {itemlist.map((item, index) => {
                        return (
                            <Div key={`checklist-item-${index}`} gutter>
                                {" "}
                                <Checkbox
                                    {...item}
                                    value={index}
                                    onChange={handleCheckboxChange}
                                />
                            </Div>
                        );
                    })}
                </Div>
            </Div>
            <Div flex>
                <Button
                    secondary
                    labelPosition="left"
                    icon="chevron left"
                    content={t("components.back")}
                    onClick={() => setShowForm(false)}
                />
                <Button
                    primary
                    labelPosition="right"
                    icon="save outline"
                    content={t("deck.checklist.saveButtonText")}
                    disabled={title === ""}
                    onClick={handleSave}
                />
            </Div>
            <br />
        </Div>
    );
}
