import React from "react";

import { Div } from "@components/Generics.react";
import CourseDashboard from "../../analytics/CourseDashboard.react";
import LeaderboardWrapper from "../../analytics/panels/courses/CourseLeaderboard.react";
import BuilderHeader from "@components/BuilderHeader.react";

export default function CourseAnalytics(props) {
    const { course } = props;

    // ========================= Render Function =================================
    return (
        <Div fluid flex spaceAround top-align>
            <Div wd="calc(100% - 400px)" basepad>
                <BuilderHeader
                    image={course?.image}
                    header={course?.name}
                    description={course?.description}
                    imgWidth="190px"
                />
                <Div vapor rounded>
                    <CourseDashboard course={course} />
                </Div>
            </Div>
            <Div wd="400px">
                <LeaderboardWrapper />
            </Div>
        </Div>
    );
}
