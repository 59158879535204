// Import from NPM
// -------------------------------------
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Icon, Loader, Divider } from "semantic-ui-react";

// Import from Config
// -------------------------------------
import { text_truncate, lightOrDark, darkStyle } from "@utilities/helpers";
import "@styles/components.scss";

// Import Components
// -------------------------------------
import { Div, Image } from "@components/Generics.react";
import UserAvatar from "@components/UserAvatar.react";

export default function Leaderboard(props) {
    const {
        leaderboardData,
        header,
        isLoading,
        isError,
        isSuccess,
        error,
        refetch,
        leaderboardType = "points",
        noPodium = false,
    } = props;
    const user = useSelector((state) => state.auth.user);

    const { t } = useTranslation("common");

    // Set up this user's row
    const currentUserData = useMemo(() => {
        const thisUserData = _.find(leaderboardData, {
            _id: user._id,
        });
        return {
            data: thisUserData,
            rank: leaderboardData?.indexOf(thisUserData),
        };
    }, [leaderboardData, user]);

    function getPodium(position) {
        const thisUser = leaderboardData[position - 1];
        if (thisUser)
            return (
                <Div className={`standing placed-${position}`}>
                    <Image
                        className="top-icon"
                        src={`/assets/images/configurable/leaderboard/leaderboard_rank${position}.png`}
                    />
                    <Div className="podium-image">
                        <UserAvatar
                            name={`${thisUser.first_name} ${thisUser.last_name}`}
                            image={thisUser.image}
                            avatarConfig={thisUser.avatarConfig}
                            size={70}
                            border={
                                ["gold", "slategray", "chocolate"][position - 1]
                            }
                        />
                    </Div>
                    <Div className="podium-name">
                        {text_truncate(thisUser.first_name, 12)}
                        <br />
                        {text_truncate(thisUser.last_name, 12)}
                    </Div>
                    <Div className="podium-score">
                        {Math.round(
                            leaderboardData[position - 1][leaderboardType]
                        )}
                    </Div>
                </Div>
            );
    }
    function getLeaderboardRow(thisUser, index) {
        let username = `${thisUser?.first_name} ${thisUser?.last_name}`;
        return (
            <Div
                clear
                circled
                small
                wd="95%"
                key={"leader-" + index}
                className={
                    currentUserData.rank !== index + (noPodium ? 0 : 3) &&
                    index !== -1
                        ? "top-ten-row"
                        : "top-ten-row my-row"
                }
            >
                <Div inline spaceRight>
                    <UserAvatar
                        name={username}
                        avatarConfig={thisUser.avatarConfig}
                        image={thisUser.image}
                        size={27}
                    />
                </Div>
                <Div inline>{text_truncate(username, 18)}</Div>
                <Div lineht="1.5em" float-right bold altText big spaceRight>
                    {Math.round(thisUser[leaderboardType]) || 0}
                </Div>
            </Div>
        );
    }

    return (
        <Div
            fullht
            fluid
            relaxed
            topRounded
            style={
                noPodium
                    ? { background: "transparent" }
                    : darkStyle(user.branding?.colors?.main)
            }
        >
            <Div fullht autoOverflow padTop className="leaderboard">
                <Div
                    fit-content
                    big
                    centered
                    relaxed
                    circled
                    clickable
                    style={darkStyle("#666666")}
                >
                    {header}
                    {refetch && (
                        <Icon
                            name="refresh"
                            size="small"
                            color="grey"
                            circular
                            inverted
                            onClick={refetch}
                        />
                    )}
                </Div>
                {isLoading && (
                    <Loader
                        active
                        inverted={
                            lightOrDark(user.branding?.colors?.main) === "dark"
                        }
                        content={t("course.scoreboard.loading")}
                    />
                )}
                {isError && <div>{error.error}</div>}
                {isSuccess && (
                    <Div fluid fullHt>
                        <Div>
                            {leaderboardData &&
                            leaderboardData.length > 0 &&
                            !noPodium ? (
                                <Div className="podium">
                                    {getPodium(1)}
                                    {getPodium(2)}
                                    {getPodium(3)}
                                </Div>
                            ) : (
                                    <Div fluid>
                                        <Div className="podium">
                                            {getPodium(1)}
                                            {getPodium(2)}
                                            {getPodium(3)}
                                        </Div>
                                    {leaderboardData.length === 0 && (
                                        <Div
                                            wd="80%"
                                            center-align
                                            centered
                                            clearfix
                                        >
                                            <Image src="/assets/images/configurable/leaderboard/noleaderboard.png" />
                                            <h3>
                                                {t(
                                                    "course.scoreboard.noLeaders"
                                                )}
                                            </h3>
                                            <p>
                                                {t("course.scoreboard.beFirst")}
                                            </p>
                                        </Div>
                                    )}
                                </Div>
                            )}
                        </Div>
                        <Div clear left-align>
                            <br />
                            {_.map(
                                noPodium
                                    ? leaderboardData.slice(0, 9)
                                    : leaderboardData.slice(3, 10),
                                (thisUser, index) =>
                                    getLeaderboardRow(thisUser, index)
                            )}
                            <Divider />

                            {(currentUserData.rank > -1 ||
                                currentUserData._id === user._id) && (
                                <Div>
                                    <Div
                                        center-align
                                        uppercase
                                        altText
                                        gapBottom
                                    >
                                        {t("appLeaderboard.yourRank")}
                                        {currentUserData.rank + 1}
                                    </Div>
                                    <Div left-align>
                                        {getLeaderboardRow(
                                            currentUserData.data || user,
                                            -1
                                        )}
                                    </Div>
                                </Div>
                            )}
                            <Div ht="40px" />
                        </Div>
                    </Div>
                )}
            </Div>
        </Div>
    );
}
