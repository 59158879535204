import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Input, TextArea } from "semantic-ui-react";
import { useSendQuestionResponseMutation } from "@api/apiV6";
import { Div, Button } from "@components/Generics.react";
export default function AssignmentGradingModal(props) {
    const {
        deckId,
        slideId,
        markingModal,
        closeModal,
        question,
        learnerName,
        learnerId,
        response,
    } = props;

    const [updateResponse] = useSendQuestionResponseMutation();
    const { t } = useTranslation("common");
    const [qMark, setQMark] = useState(response.score);
    const [qRemark, setQRemark] = useState(response.remarks);

    async function sendResponse() {
        await updateResponse({
            deckId: deckId,
            userId: learnerId,
            slideId: parseInt(slideId.split("#")[1], 0),
            score: qMark,
            remark: qRemark,
        });
        closeModal();
    }

    return (
        <Modal open={markingModal} closeIcon size="small" onClose={closeModal}>
            <Modal.Header>{question}</Modal.Header>
            <Modal.Content>
                <Div gutter flex>
                    <Div ash padded wd="150px">
                        {learnerName}:
                    </Div>
                    <Div vapor padded wd="calc(100% - 150px)">
                        {response.response}
                    </Div>
                </Div>
                <Div gutter>
                    <Input
                        fluid
                        type="number"
                        label="Score"
                        labelPosition="left"
                        value={qMark}
                        onChange={(e) => setQMark(e.target.value)}
                    />
                    <Div padTop>
                        <Div bold gapBottom>
                            Remarks:
                        </Div>
                        <TextArea
                            value={qRemark}
                            onChange={(e) => setQRemark(e.target.value)}
                            style={{ width: "100%", padding: "10px" }}
                        />
                    </Div>
                </Div>
            </Modal.Content>
            <Modal.Actions>
                <Button primary onClick={sendResponse}>
                    {t("components.submit")}
                </Button>
            </Modal.Actions>
        </Modal>
    );
}
