import React, { useState, useContext } from "react";
import { LayoutContext } from "@layouts/MasterLayout.react";
import { Button, Divider } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { trackQuestion } from "@reducers/trackerSlice";
import { Div, HDiv, Button as XButton } from "@components/Generics.react";
import ResponseOverlay from "@components/ResponseOverlay.react";

export default function TrueFalse(props) {
    const {
        data,
        masterSeq,
        slideSeq,
        trackData,
        deckId,
        pageNumber,
        setPageNumber,
        mustOverlay = false,
    } = props;
    const [correct, setCorrect] = useState(null);
    const [showOverlay, setShowOverlay] = useState(false);
    const [thisResponse, setThisResponse] = useState(null);
    const userId = useSelector((state) => state.auth.user._id);
    const dispatch = useDispatch();
    const { t } = useTranslation("common");
    const { fullScreen } = useContext(LayoutContext);
    const isPortrait = window.innerHeight > window.innerWidth || !fullScreen;

    function trackInteraction(selected) {
        const isCorrect = data.choice[selected]["correct"] === "checked";
        setCorrect(isCorrect);

        if (
            mustOverlay ||
            (data.feedback != null &&
                data.feedback.length > 0 &&
                data.purpose !== "Provide Insight")
        ) {
            setThisResponse({
                correct: isCorrect,
                response: selected,
            });
            setShowOverlay(true);
        } else {
            setPageNumber(pageNumber + 1);
            if (trackData) {
                trackData({
                    correct: isCorrect,
                    response: selected,
                });
            }
        }
        dispatch(
            trackQuestion({
                deckId: deckId,
                slideId: masterSeq == null ? slideSeq : masterSeq,
                userId: userId,
                response: data.choice[selected].text,
            })
        );
    }

    function closeOverlay() {
        setPageNumber(pageNumber + 1);
        if (trackData) {
            trackData(thisResponse);
        }
    }

    // ========================= Render Function =================================
    return (
        <Div fullht zoom superpad wd={isPortrait ? "100%" : "50%"} centered>
            <HDiv
                big={data.question?.length < 120}
                gutter
                content={data.question}
            />
            <Div small italics>
                {t("deck.qdf.singleSelect")}
            </Div>
            {data.choice && (
                <Div>
                    {data.choice[0]?.text?.length +
                        data.choice[1]?.text?.length <
                    20 ? (
                        <Div padTop>
                            <Button.Group fluid size="huge">
                                <XButton
                                    content={data.choice[0]?.text || "True"}
                                    primary
                                    onClick={() => trackInteraction(0)}
                                />
                                <Button.Or
                                    style={{
                                        position: "relative",
                                        zIndex: "1",
                                    }}
                                />
                                <XButton
                                    content={data.choice[1]?.text || "False"}
                                    secondary
                                    onClick={() => trackInteraction(1)}
                                />
                            </Button.Group>
                        </Div>
                    ) : (
                        <Div padTop>
                            <XButton
                                fluid
                                content={data.choice[0]?.text}
                                primary
                                onClick={() => trackInteraction(0)}
                            />
                            <Divider horizontal>OR</Divider>
                            <XButton
                                fluid
                                content={data.choice[1]?.text}
                                secondary
                                onClick={() => trackInteraction(1)}
                            />
                        </Div>
                    )}
                </Div>
            )}
            {showOverlay && (
                <ResponseOverlay
                    feedback={data.feedback}
                    correct={correct}
                    closeResponse={closeOverlay}
                />
            )}
        </Div>
    );
}
