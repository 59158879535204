import React from "react";
import { Div, HDiv, Button } from "@components/Generics.react";
export default function InteralLink(props) {
    const { data, setPageNumber } = props;
    return (
        <Div superpad big>
            <HDiv content={data.paragraph} />
            <br />
            <br />
            {!data.link && data.slideLink && (
                <Button
                    primary
                    content="Go"
                    fluid
                    size="huge"
                    onClick={() => setPageNumber(data.slideLink)}
                />
            )}
            {data.link && (
                <Button
                    primary
                    content={data.link.text}
                    fluid
                    size="huge"
                    onClick={() => setPageNumber(data.link.btnLink)}
                />
            )}
        </Div>
    );
}
