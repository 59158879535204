// Import from NPM
// -------------------------------------
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

// Import from Config
// -------------------------------------
import { useGetCourseLeaderboardQuery } from "@api/apiV6";
import "@styles/components.scss";

// Import Components
// -------------------------------------
import Leaderboard from "@components/Leaderboard.react";

export default function CourseLeaderboard(props) {
    const { course } = props;
    const { t } = useTranslation("common");

    const {
        data: leaderboard,
        isLoading,
        isSuccess,
        isError,
        error,
        refetch,
    } = useGetCourseLeaderboardQuery(course._id);

    const sortedLeaderboard = useMemo(() => {
        let sortedData = _.orderBy(
            leaderboard,
            ["points", "completionDate"],
            ["desc", "asc"]
        );

        // Remove owner from list
        sortedData = _.filter(sortedData, (usr) => usr._id !== course.owner);
        return sortedData;
    }, [leaderboard, course]);

    // ========================= Render Function =================================
    return (
        <Leaderboard
            leaderboardData={sortedLeaderboard}
            header={t("course.scoreboard.header")}
            isLoading={isLoading}
            isError={isError}
            isSuccess={isSuccess}
            error={error}
            refetch={refetch}
        />
    );
}
