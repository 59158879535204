import React, { useRef, useEffect } from "react";
import _ from "lodash";

export default function FogOverlay(props) {
    const { width, height, visible } = props;
    const canvasRef = useRef(null);

    useEffect(() => {
        let canvas = canvasRef.current;
        let ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.globalAlpha = 0.9;
        ctx.globalCompositeOperation = "xor";
        ctx.fillStyle = "#121212";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        _.each(visible, (pos) => {
            let x = (parseInt(pos.left, 0) * canvas.width) / 100;
            let y = (parseInt(pos.top, 0) * canvas.height) / 100;
            let radius = canvas.height * 0.33;

            ctx.beginPath();
            let radialGradient = ctx.createRadialGradient(
                x,
                y,
                1,
                x,
                y,
                radius
            );
            radialGradient.addColorStop(0, "rgba(255,255,255,1)");
            radialGradient.addColorStop(1, "rgba(0,0,0,0)");

            ctx.globalCompositeOperation = "destination-out";

            ctx.fillStyle = radialGradient;
            ctx.arc(x, y, radius, 0, Math.PI * 2, false);
            ctx.fill();
            ctx.closePath();
        });
    });

    // ========================= Render Function =================================
    return <canvas ref={canvasRef} width={width} height={height} />;
}
