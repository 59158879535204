import React, { useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import {
    useGetUserQuery,
    useGetActivityLogQuery,
    useDeactivateUserMutation,
    useActivateUserMutation,
} from "@api/apiV6";
import { desnaked } from "@utilities/helpers";
import { Button as XButton, Confirm } from "semantic-ui-react";
import { toast } from "react-toastify";
import moment from "moment";

import { Div, Button } from "@components/Generics.react";
import UserAvatar from "@components/UserAvatar.react";
import EditUserModal from "./EditUserModal.react";

export default function UserDetails(props) {
    const { userId, setSelectedUser } = props;
    const { t } = useTranslation("common");
    const { data: baseData, isSuccess } = useGetUserQuery(userId);
    const { data: activityLog, isSuccess: logSuccess } =
        useGetActivityLogQuery(userId);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [deactivateUser] = useDeactivateUserMutation();
    const [activateUser] = useActivateUserMutation();

    const showData = _.pick(baseData, [
        "username",
        "first_name",
        "last_name",
        "email",
        "active",
    ]);

    async function tryDelete() {
        const updateResponse = await deactivateUser(userId);
        setSelectedUser(null);
        if (updateResponse) {
            toast("User Deleted");
        }
    }
    async function tryActivate() {
        const updateResponse = await activateUser(userId);
        setSelectedUser(null);
        if (updateResponse) {
            toast("User Activated");
        }
    }

    // ========================= Render Function =================================
    return (
        <Div>
            <Div
                white
                flex
                spaceBetween
                bold
                uppercase
                huge
                padded
                gutter
                slightShadow
            >
                {`${baseData?.first_name} ${baseData?.last_name}`}
                <Button
                    secondary
                    icon="close"
                    onClick={() => setSelectedUser(null)}
                />
            </Div>
            {isSuccess && (
                <Div flex>
                    <Div
                        flex
                        column
                        white
                        basepad
                        slightShadow
                        relative
                        layer={2}
                    >
                        <UserAvatar
                            name={`${baseData?.first_name} ${baseData?.last_name}`}
                            image={baseData?.image}
                            avatarConfig={baseData?.avatarConfig}
                            size={150}
                        />
                        <Div padTop flex column gapped>
                            {_.map(_.toPairs(showData), (row, idx) => {
                                return (
                                    <Div fluid flex key={`user-det-${idx}`}>
                                        <Div
                                            padded
                                            ash={idx % 2 === 0}
                                            smoke={idx % 2 === 1}
                                            wd="128px"
                                        >
                                            {row[0] === "active"
                                                ? "Status"
                                                : desnaked(row[0])}
                                        </Div>
                                        <Div
                                            padded
                                            ivory
                                            wd="calc(100% - 128px)"
                                        >
                                            {row[1] === true
                                                ? "Active"
                                                : row[1] === false
                                                ? "Deactivated"
                                                : row[1]}
                                        </Div>
                                    </Div>
                                );
                            })}
                            <XButton.Group fluid>
                                <Button
                                    primary
                                    content="Edit"
                                    onClick={() => setEditModalOpen(true)}
                                />
                                {baseData?.active ? (
                                    <Button
                                        negative
                                        content={t("admin.userMgmt.deactivate")}
                                        onClick={() => setDeleteConfirm(true)}
                                    />
                                ) : (
                                    <Button
                                        success={+true}
                                        content={t("admin.userMgmt.activate")}
                                        onClick={tryActivate}
                                    />
                                )}
                            </XButton.Group>
                        </Div>
                    </Div>
                    {logSuccess && (
                        <Div basepad smoke slightShadow>
                            <Div bold big uppercase gutter>
                                {t("admin.userMgmt.activityLog")}
                            </Div>
                            <Div ht="250px" autoOverflowY>
                                {_.map(activityLog, (logEntry, idx) => (
                                    <Div
                                        key={`log-${idx}`}
                                        flex
                                        white
                                        snug
                                        padded
                                    >
                                        <Div wd="160px">
                                            {moment(
                                                new Date(logEntry.time),
                                                "D/M/YYYY, h:mm:ss a"
                                            ).format("Do MMM YY h:mm a")}
                                        </Div>
                                        <Div wd="200px">{logEntry.event}</Div>
                                    </Div>
                                ))}
                            </Div>
                        </Div>
                    )}
                </Div>
            )}
            {isSuccess && (
                <Div>
                    <EditUserModal
                        key={baseData._id}
                        isOpen={editModalOpen}
                        setOpen={setEditModalOpen}
                        user={baseData}
                    />
                    <Confirm
                        open={deleteConfirm}
                        onCancel={() => setDeleteConfirm(false)}
                        onConfirm={() => {
                            tryDelete();
                            setDeleteConfirm(false);
                        }}
                    />
                </Div>
            )}
        </Div>
    );
}
