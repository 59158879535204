import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Divider, Loader } from "semantic-ui-react";
import { useParams } from "react-router-dom";

import { settings } from "@config/settings/app.settings";

import MasterLayout from "@layouts/MasterLayout.react";
import SocialUploader from "./panel/SocialUploader.react";
import { Div } from "@components/Generics.react";

import {
    useGetPostsQuery,
    useSendSocialPostMutation,
    useSendSocialCommentsMutation,
    useSendSocialLikesMutation,
} from "@api/apiV6";
import RecommendedArticles from "@components/RecommendedArticles.react";
import PostCard from "@components/PostCard.react";

export default function Social(props) {
    const { t } = useTranslation("common");

    const SocialPage = () => {
        const { postId } = useParams();
        const { data: posts, refetch: refetchPosts } = useGetPostsQuery();
        const [statePosts, setPosts] = useState([]);

        useEffect(() => {
            setPosts(posts);
        }, [posts]);

        const [sendSocialPost] = useSendSocialPostMutation();
        const [sendSocialComments] = useSendSocialCommentsMutation();
        const [sendSocialLikes] = useSendSocialLikesMutation();

        const handleLike = (postId) => {
            sendSocialLikes({ id: postId })
                .then((response) => {
                    console.log("Like added successfully", response);
                    refetchPosts();
                })
                .catch((error) => {
                    console.error("Error adding like", error);
                });
        };

        const handleCommentToggle = (postId) => {
            setPosts((prevPosts) =>
                prevPosts.map((post) =>
                    post._id === postId
                        ? { ...post, showCommentInput: !post.showCommentInput }
                        : post
                )
            );
        };

        const addCommentToPost = (postId, comment) => {
            sendSocialComments({ id: postId, comment })
                .then((response) => {
                    console.log("Comment added successfully", response);
                    refetchPosts();
                })
                .catch((error) => {
                    console.error("Error adding comment", error);
                });
        };

        const handlePostSubmit = (postData) => {
            const { category, content, description, owner_id } = postData;
            const newPost = {
                category,
                content,
                description,
                owner_id,
            };

            sendSocialPost(newPost)
                .then((response) => {
                    console.log("Post created successfully", response);
                    refetchPosts();
                })
                .catch((error) => {
                    console.error("Error creating post", error);
                });
        };

        if (postId) {
            let post = _.find(posts, { _id: postId });
            if (post)
                return (
                    <PostCard
                        key={post._id}
                        post={post}
                        onLike={handleLike}
                        onCommentToggle={handleCommentToggle}
                        onAddComment={addCommentToPost}
                    />
                );
            else return <Loader active={true} />;
        } else {
            return (
                <Div medpad>
                    <Div huge gapTop gutter>
                        COMMUNITY
                    </Div>
                    {settings.functionality.socialContribution && (
                        <Div ash padded rounded>
                            <SocialUploader onSubmit={handlePostSubmit} />
                        </Div>
                    )}
                    <Divider />
                    <Div fluid>
                        {statePosts &&
                            statePosts.map((post) => (
                                <PostCard
                                    key={`Post-${post._id}`}
                                    post={post}
                                    onLike={handleLike}
                                    onCommentToggle={handleCommentToggle}
                                    onAddComment={addCommentToPost}
                                />
                            ))}
                    </Div>
                </Div>
            );
        }
    };

    const pageContent = (
        <MasterLayout
            title={`${t("appName")} Community`}
            mainPanel={<SocialPage />}
            rightPanelName={t(`components.recommended`)}
            rightPanel={<RecommendedArticles />}
        />
    );

    return pageContent;
}
