// Import from NPM
// -------------------------------------
import React from "react";

import { Div } from "@components/Generics.react";
import { Dropdown } from "semantic-ui-react";
export default function SelectField(props) {
    const { target, header, help, value, setValue, height, options } = props;
    // ========================= Render Function =================================
    return (
        <Div minHt={height} ht={height} ivory basepad snug>
            <Div big rimmed>
                {header}
                {help && (
                    <Div tiny italics txtHalf gapSlice>
                        {help}
                    </Div>
                )}
            </Div>
            <Div smoke ht={"calc(100% - 60px)"} fluid>
                <Dropdown
                    placeholder={header}
                    fluid
                    selection
                    options={options}
                    value={target ? value[target] : value}
                    onChange={(e, { value }) => {
                        setValue(target ? { [target]: value } : value);
                    }}
                />
            </Div>
        </Div>
    );
}
