import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import { useFeatures } from "flagged";
import { Div } from "@components/Generics.react";
import "@styles/components.scss";
import Carousel from "@components/Carousel.react";
import ArticleCard from "@components/ArticleCard.react";
import PortraitCard from "@components/PortraitCard.react";

export default function ArticleCarousel(props) {
    const {
        articles,
        header,
        libLink,
        noSort = false,
        noLimit = false,
    } = props;
    const { t } = useTranslation("common");
    const features = useFeatures();

    const pageWidth = window.innerWidth;
    const isPortrait = window.innerHeight > window.innerWidth;

    const slidesToShow =
        pageWidth < 1000
            ? features.articles.portraitCard
                ? 3.2
                : 1.2
            : pageWidth < 1500
            ? features.articles.portraitCard
                ? 4.2
                : 2.2
            : features.articles.portraitCard
            ? 5.2
            : 3.2;
    const sortedArticles = useMemo(
        () =>
            noSort
                ? noLimit
                    ? articles
                    : articles.slice(0, 10)
                : noLimit
                ? _.orderBy(
                      articles,
                      ["priority", "updatedAt"],
                      ["desc", "desc"]
                  )
                : _.orderBy(
                      articles,
                      ["priority", "updatedAt"],
                      ["desc", "desc"]
                  ).slice(0, 10),
        [articles, noSort, noLimit]
    );
    const shortList = sortedArticles.length < slidesToShow;

    // ========================= Render Function =================================
    return (
        <Div fluid className="article-carousel">
            <Div
                fluid
                bold
                autoOverflow
                uppercase
                fitted
                clearfix
                big={isPortrait}
            >
                <Div float-left fit-content>
                    {header.replace(/_/g, " ")}
                </Div>
                {!shortList && libLink && (
                    <Div float-right fit-content>
                        <NavLink to={libLink}>{t("home.seeall")}</NavLink>
                    </Div>
                )}
            </Div>
            <Div
                clear
                snug
                noOverflow
                className={shortList ? "carousel-shortlist" : ""}
            >
                <Carousel
                    lazyLoad
                    slidesToShow={slidesToShow}
                    enableButtons={!isPortrait && !shortList}
                >
                    {_.map(sortedArticles, (article, idx) => {
                        return features.articles.portraitCard ? (
                            <PortraitCard
                                key={`article-${article._id}-${idx}`}
                                article={article}
                                feedStyle
                            />
                        ) : (
                            <ArticleCard
                                key={`article-${article._id}-${idx}`}
                                article={article}
                                feedStyle
                            />
                        );
                    })}
                </Carousel>
            </Div>
        </Div>
    );
}
