// Import from NPM
// -------------------------------------
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { Div } from "@components/Generics.react";

export default function ActionCard(props) {
    const { icon, header, content, link, locked = false } = props;
    const [hovered, setHovered] = useState(false);
    // console.log(noEdit)
    // ========================= Render Function =================================
    return (
        <NavLink to={link}>
            <Div
                white={!locked}
                txtCharcoal={!locked && !hovered}
                danger={locked}
                medpad
                flex
                spaceAround
                noWrap
                clickable={!locked}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                snug
            >
                <Div
                    headline
                    basepad
                    style={{ borderRight: "3px solid #ffbf00" }}
                >
                    <Icon name={icon} />
                </Div>
                <Div padSides>
                    <Div big bold uppercase>
                        {header}
                    </Div>
                    <Div>{content}</Div>
                </Div>
                {!locked && (
                    <Div huge>
                        <Icon
                            name="chevron right"
                            bordered
                            inverted
                            color="grey"
                        />
                    </Div>
                )}
            </Div>
        </NavLink>
    );
}
