// Import from NPM
// -------------------------------------
import React from "react";
import _ from "lodash";
import { Div } from "@components/Generics.react";
import "@styles/editor.scss";
import ColorBlock from "../ColorBlock.react";

const colorNames = {
    base: "Page Color",
    baseText: "Page Text",
    primary: "Main Color",
    primaryText: "Main Text",
    primaryVariation: "Accent",
    secondary: "Secondary",
};
export default function ColorSelector(props) {
    const { name, deck, setDeck, leftBordered = true } = props;

    function setColor(name, color) {
        setDeck({
            ...deck,
            ...{ colors: { ...deck.colors, ...{ [name]: color } } },
        });
    }

    // ========================= Render Function =================================
    return (
        <Div wd="300px" className="ribbon-btn" leftBordered={leftBordered}>
            <Div
                white
                slightShadow
                snubbed
                fit-content
                center-align
                clickable
                flex
                flexStart
                gapped
                style={{ padding: "7.22px 5px" }}
            >
                {_.map(
                    _.toPairs(_.omit(deck.colors, "secondaryText")),
                    (color, idx) => {
                        return (
                            <Div
                                wd="45%"
                                left-align
                                small
                                gapSides
                                key={`color-selector-${idx}`}
                            >
                                <ColorBlock
                                    color={color[1]}
                                    colorName={colorNames[color[0]]}
                                    onChange={(name, colorPick) =>
                                        setColor(color[0], colorPick.hex)
                                    }
                                />
                            </Div>
                        );
                    }
                )}
            </Div>
            <Div small center-align gapTop>
                {name}
            </Div>
        </Div>
    );
}
