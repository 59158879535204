// Import from NPM
// -------------------------------------
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Div } from "@components/Generics.react";
import { Dropdown, Divider, Input, Image } from "semantic-ui-react";
import { settings } from "@config/settings/app.settings";
import { GameSchema } from "@schemas/gameSchema";
import SettingsInput from "../formComponents/SettingsInput.react";

export default function GameSettings(props) {
    const { deck, setDeck } = props;
    const { t } = useTranslation("common");
    const [search, setSearch] = useState("");
    const gamesList = _.filter(
        GameSchema,
        (template) =>
            template.name.includes(search) ||
            template.description.includes(search)
    );
    // ========================= Render Function =================================
    return (
        <Div fullht>
            {settings.languages && settings.languages.length > 1 && (
                <Div compact>
                    <Div gapBottom bold>
                        {t("builder.dic.selectLanguage")}:
                    </Div>
                    <Dropdown
                        placeholder={t("builder.dic.selectLanguage")}
                        fluid
                        selection
                        options={_.map(settings.languages, (l) => ({
                            key: l.code,
                            text: l.identifier,
                            value: l.code,
                        }))}
                        defaultValue={"en"}
                        value={deck.lang}
                        onChange={(e, { value }) =>
                            setDeck({
                                ...deck,
                                ...{ lang: value },
                            })
                        }
                    />
                </Div>
            )}
            <Divider />
            <Div bold compact gapBottom>
                {t("components.points")}
            </Div>
            <SettingsInput
                {...props}
                label={t("builder.deck.maxPoints")}
                targetKey="points"
                isType="number"
            />
            <Div compact>
                <Div italics gapBottom>
                    {t("builder.deck.pointsHelpGame1")}
                </Div>
                <Div compact vapor fit-content>
                    {t("builder.deck.pointsHelpGame2")}
                </Div>
            </Div>
            <Divider />
            <Div bold compact>
                {t("builder.deck.selectGameTemplate")}:
            </Div>
            <Div compact gapBottom>
                <Div compact charcoal fit-content>
                    {t("builder.dic.selected")}:{" "}
                    {deck.quo_game
                        ? _.find(GameSchema, { folder: deck.quo_game }).name
                        : t("builder.dic.noSelected")}
                </Div>
            </Div>
            <Div rimmed ash>
                <Div compact>
                    <Input
                        icon="search"
                        placeholder={t("components.search")}
                        fluid
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </Div>
                <Div compact flex gapped>
                    {_.map(gamesList, (game, idx) => {
                        return (
                            <Div
                                key={`game-select-${idx}`}
                                fluid
                                ivory={deck.quo_game !== game.folder}
                                charcoal={deck.quo_game === game.folder}
                                slightShadow
                                flex
                                flexStart
                                clickable
                                onClick={() =>
                                    setDeck({
                                        ...deck,
                                        ...{
                                            quo_game: game.folder,
                                        },
                                    })
                                }
                            >
                                <Div wd="32px">
                                    <Image
                                        src={`/assets/images/games/backgrounds/${game.folder}.jpg`}
                                    />
                                </Div>
                                <Div wd="calc(100% - 32px)" padded>
                                    <Div bold>{game.name}</Div>
                                    <Div small> {game.description}</Div>
                                </Div>
                            </Div>
                        );
                    })}
                </Div>
            </Div>
        </Div>
    );
}
