import React, { useState } from "react";
import _ from "lodash";
import {
    useCreateNewContentMutation,
    useUpdateProgramMutation,
    useGetAllCreatorsQuery,
} from "@api/apiV6";
import { toast } from "react-toastify";

import { Div, Button } from "@components/Generics.react";
import { Modal, Icon, Input, Dropdown } from "semantic-ui-react";

export default function UpsertProgramModal(props) {
    const { isOpen, setOpen, program } = props;
    const { data: audienceData, isSuccess } = useGetAllCreatorsQuery();

    const [programName, setProgramName] = useState(program?.name);
    const [programOwner, setProgramOwner] = useState(program?.owner?._id);

    const [createProgram] = useCreateNewContentMutation();
    const [updateProgram] = useUpdateProgramMutation();

    async function tryUpsert() {
        if (program == null) {
            const response = await createProgram({
                name: programName,
                owner: programOwner,
                contentType: "programs",
            });
            if (response) {
                toast("Program Created");
                setOpen(false);
            }
        } else {
            const response = await updateProgram({
                id: program._id,
                name: programName,
                owner: programOwner,
            });
            if (response) {
                toast("Program Updated");
                setOpen(false);
            }
        }
    }

    // ========================= Render Function =================================
    return (
        <Modal size="small" open={isOpen} onClose={() => setOpen(false)}>
            <Modal.Header>
                {program == null ? "Add" : "Edit"} Program
                <Div float-right clickable>
                    <Icon
                        name="times"
                        onClick={() => {
                            setOpen(false);
                        }}
                    />
                </Div>
            </Modal.Header>
            <Modal.Content>
                <Div white basepad>
                    <Div fluid gutter>
                        <Input
                            fluid
                            label={"Program Name"}
                            placeholder={"Enter program name"}
                            value={programName}
                            onChange={(e) => setProgramName(e.target.value)}
                        />
                        {isSuccess ? (
                            <Dropdown
                                placeholder={"Select Owner"}
                                fluid
                                selection
                                search
                                options={_.map(audienceData, (l) => ({
                                    key: l._id,
                                    text: l.username,
                                    value: l._id,
                                }))}
                                value={programOwner}
                                onChange={(e, { value }) =>
                                    setProgramOwner(value)
                                }
                            />
                        ) : (
                            <Div relaxed>Fetching Owners...</Div>
                        )}
                    </Div>
                </Div>
                <Button
                    primary
                    content={`${program == null ? "Add" : "Edit"} Program`}
                    onClick={tryUpsert}
                />
            </Modal.Content>
        </Modal>
    );
}
