import React from "react";
import MasterLayout from "@layouts/MasterLayout.react";
import { Div, Image } from "@components/Generics.react";

export default function Contest(props) {

    const Contest = () => {
        return (
            <Div centered >
            <Image snubbed src="/assets/images/coming_soon.png" alt="Coming Soon" />
          </Div>
        );
      };
    

    const pageContent = (
        <MasterLayout
            title={"Welcome"}
            mainPanel={<Contest />}
            // rightPanelName={rightPanelName}
            // rightPanel={""}
            rootPage={true}
        />
    );

    return pageContent;
}