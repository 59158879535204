import { createSlice } from "@reduxjs/toolkit";
// import { apiV6 } from "@config/api/apiV6";
import _ from "lodash";
import { updateDeck } from "./aggregators/deckAggregator";
import { updateTopic } from "./aggregators/topicAggregator";
import { updateCourse } from "./aggregators/courseAggregator";
import { getHistory } from "@utilities/helpers";
import { updateLearner } from "./aggregators/learnerAggregator";

const initialState = {
    deck: null,
    readerType: null,
    topic: null,
    course: null,
    program: null,
    article: null,
    deckState: { currentState: {} },
    topicState: { currentState: {} },
    courseState: { currentState: {}, history: {} },
    programState: { currentState: {}, history: {} },
    learnerState: { currentState: {}, history: {} },
    articleState: { currentState: {}, history: {} },
};

export const attemptSlice = createSlice({
    name: "attempt",
    initialState,
    reducers: {
        clearAttempt: (state) => {
            state = initialState;
            return state;
        },
        startSession: (state, action) => {
            const { last_session, currentState, activity } = action.payload;
            const todayDate = new Date();
            const lastSessionDate = new Date(last_session);
            const addSession =
                todayDate.setHours(0, 0, 0, 0) >
                lastSessionDate.setHours(0, 0, 0, 0); // If it is a new day, add Session

            state.learnerState.currentState = updateLearner(currentState, {
                addSession: addSession,
            });
            state.learnerState.history = addSession
                ? getHistory(activity, 0, false, true)
                : activity;
            // state.learnerState.history = getHistory(activity, 0, false, true);
            return state;
        },
        startArticleAttempt: (state, action) => {
            const pastLearnerState = _.cloneDeep(state.learnerState);
            state = _.cloneDeep(initialState);
            state.article = action.payload.id;
            state.deck = action.payload.deckId;
            state.readerType = action.payload.readerType;
            state.articleState.currentState = action.payload.currentState;
            state.articleState.history = action.payload.history;
            state.learnerState = pastLearnerState;
            return state;
        },
        startCourseAttempt: (state, action) => {
            const pastLearnerState = _.cloneDeep(state.learnerState);
            state = _.cloneDeep(initialState);
            state.course = action.payload.id;
            state.courseState.currentState = action.payload.currentState;
            state.courseState.history = action.payload.history;
            state.learnerState = pastLearnerState;
            return state;
        },
        startTopicAttempt: (state, action) => {
            state.topic = action.payload.id;
            state.topicState.currentState = action.payload.currentState;
            return state;
        },
        startDeckAttempt: (state, action) => {
            state.deck = action.payload.id;
            state.readerType = action.payload.readerType;
            state.deckState.currentState = action.payload.currentState;
            return state;
        },
        completeDeck: (state, action) =>
            Object.assign({}, state, action.payload),
        abandonDeck: (state, action) =>
            Object.assign({}, state, action.payload),
    },
});

// Action creators are generated for each case reducer function
export const {
    clearAttempt,
    startSession,
    startArticleAttempt,
    startCourseAttempt,
    startTopicAttempt,
    startDeckAttempt,
    completeDeck,
    abandonDeck,
} = attemptSlice.actions;

export default attemptSlice.reducer;

export const getAttemptState = (presentState, data) => {
    let attemptState = _.cloneDeep(presentState);
    attemptState.deck = data.deckId;
    attemptState.deckState.currentState = updateDeck(
        attemptState.readerType,
        attemptState.deckState.currentState,
        data
    );
    // If the deck is inside a course and not inside an article
    //---------------------------------
    if (attemptState.course) {
        attemptState.topicState.currentState = updateTopic(
            attemptState.topicState.currentState,
            data
        );
        attemptState.courseState.currentState = updateCourse(
            attemptState.courseState.currentState,
            data,
            attemptState.topic,
            attemptState.topicState.currentState.completion
        );
        attemptState.courseState.history = getHistory(
            attemptState.courseState.history,
            data.timeSpent,
            true,
            false
        );
    }
    attemptState.learnerState.currentState = updateLearner(
        attemptState.learnerState.currentState,
        data
    );
    attemptState.learnerState.history = getHistory(
        attemptState.learnerState.history,
        data.timeSpent,
        false,
        false
    );
    return attemptState;
};
