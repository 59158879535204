import React, { useContext } from "react";
import { LayoutContext } from "@layouts/MasterLayout.react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Div, HDiv, Button } from "@components/Generics.react";

export default function Brancher(props) {
    const { data, setPageNumber } = props;
    const { t } = useTranslation("common");
    const { fullScreen } = useContext(LayoutContext);
    const isPortrait = window.innerHeight > window.innerWidth || !fullScreen;

    const getOptions = _.map(data.brancher, (option, index) => {
        return (
            <Button
                content={option.text}
                fluid
                normal={isPortrait ? undefined : +true}
                primary
                key={"button-" + index}
                style={{ marginBottom: "10px" }}
                onClick={() => setPageNumber(option.slideLink)}
            />
        );
    });

    return (
        <Div wd={isPortrait ? "100%" : "50%"} fullht zoom superpad centered>
            <HDiv big gutter content={data.paragraph} />
            <Div small italics>
                {t("deck.qdf.brancher")}
            </Div>
            <Div padTop>{getOptions}</Div>
        </Div>
    );
}
