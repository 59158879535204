import React from "react";
import { Tab } from "semantic-ui-react";
import SurveyLearnersResponses from "../surveys/SurveyLearnersResponses.react";
import SurveyQuestionsResponses from "../surveys/SurveyQuestionsResponses.react";

export default function ArticleSurvey(props) {
    const { article } = props;
    const selectedFile = article.decks[0];

    const panes = [
        {
            menuItem: "Learner Responses",
            render: () => (
                <Tab.Pane>
                    <SurveyLearnersResponses deckId={selectedFile} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Question Responses",
            render: () => (
                <Tab.Pane>
                    <SurveyQuestionsResponses deckId={selectedFile} />
                </Tab.Pane>
            ),
        },
    ];

    return <Tab panes={panes} />;
}
