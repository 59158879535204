import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import _ from "lodash";

import { useGetArticleInventoryQuery } from "@api/apiV6";

import CreatorLayout from "@layouts/CreatorLayout.react";
import { Div } from "@components/Generics.react";
import CourseView from "./panels/CourseView.react";
import ArticleView from "./panels/ArticleView.react";
import ProgramView from "./panels/ProgramView.react";
import RepoListing from "./panels/RepoListing.react";

export default function BuilderRepo(props) {
    const { data: articles } = useGetArticleInventoryQuery();

    const [searchParams, setSearchParams] = useSearchParams();
    const { t } = useTranslation("common");

    const [target, setTarget] = useState(null);

    useEffect(() => {
        const thisTarget = searchParams.get("target");
        const thisSection = searchParams.get("section");
        if (thisTarget != null && thisSection != null) {
            setTarget({
                key: thisTarget,
                type: "child",
                section: thisSection,
            });
        }
    }, [searchParams]);

    const setSelected = (selectedTarget) => {
        setSearchParams(
            selectedTarget.type === "child"
                ? {
                      target: selectedTarget.key,
                      section: selectedTarget.section,
                  }
                : {}
        );
        setTarget(selectedTarget);
    };

    function displaySection() {
        let component = (
            <RepoListing
                content={_.filter(articles, (a) => a.category !== "course")}
            />
        );
        if (target && target.key.length === 24)
            switch (target.section) {
                case "courses":
                    component =
                        target.type === "parent" ? (
                            <ProgramView
                                id={target.key}
                                type={t("builder.dic.program")}
                                setTarget={setSelected}
                            />
                        ) : (
                            <CourseView
                                id={target.key}
                                setTarget={setSelected}
                            />
                        );
                    break;
                case "exams":
                    component =
                        target.type === "parent" ? (
                            <ProgramView
                                id={target.key}
                                type={t("builder.dic.program")}
                                setTarget={setSelected}
                            />
                        ) : (
                            <CourseView
                                id={target.key}
                                setTarget={setSelected}
                            />
                        );
                    break;
                case "articles":
                    component =
                        target.type === "parent" ? (
                            <ProgramView
                                id={target.key}
                                type={t("builder.dic.serie")}
                                setTarget={setSelected}
                            />
                        ) : (
                            <ArticleView
                                id={target.key}
                                setTarget={setSelected}
                            />
                        );
                    break;
                default:
                    component = (
                        <ProgramView
                            id={target.key}
                            type={t("builder.dic.program")}
                            setTarget={setSelected}
                        />
                    );
            }
        return component;
    }

    const pageContent = (
        <Div fullht fluid ivory>
            {displaySection()}
        </Div>
    );

    // ========================= Render Function =================================
    return (
        <CreatorLayout
            title={t("appCreator")}
            navName={t("builder.welcome.repository")}
            pageCode={"repository"}
            mainPanel={pageContent}
            rootPage={true}
        />
    );
}
