import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Div } from "@components/Generics.react";
import PortraitCard from "@components/PortraitCard.react";
import Carousel from "@components/Carousel.react";
import { Image, Input } from "semantic-ui-react";

export default function SwipeHome(props) {
    const { header, description, tags, image } = props;
    const taggedList = useSelector((state) => state.articles.taggedList);
    const isPortrait = window.innerHeight > window.innerWidth;
    const [searchText, setSearchText] = useState("");
    const { t } = useTranslation("common");

    const proppedTags = _.filter(
        tags,
        (tag) => taggedList[tag]?.articles.length > 0
    );
    const selectedArticles = useMemo(
        () =>
            _.uniqBy(
                _.flatten(
                    _.map(proppedTags, (tag) => taggedList[tag]?.articles)
                ),
                "_id"
            ),
        [proppedTags, taggedList]
    );
    const searchedArticles = useMemo(
        () =>
            searchText === ""
                ? selectedArticles
                : _.filter(selectedArticles, (article) =>
                      _.includes(_.toLower(article.name), _.toLower(searchText))
                  ),
        [searchText, selectedArticles]
    );

    // ========================= Render Function =================================
    return (
        <Div>
            <Div fluid relative rimmed>
                <Div snubbed noOverflow>
                    <Image fluid src={image} />
                </Div>
                <Div absolute big bold uppercase className="root-layout-header">
                    {header}
                </Div>
            </Div>
            <Div white padSides gapBottom>
                <Div gapTop gutter>
                    {description}
                </Div>
            </Div>
            <Div medpad ash>
                <Input
                    fluid
                    placeholder={t("builder.dic.searchWithin")}
                    icon={{ name: "search" }}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </Div>
            <Div basepad large>
                {searchedArticles?.length > 0 ? (
                    <Carousel
                        lazyLoad
                        slidesToShow={1.1}
                        enableButtons={!isPortrait}
                    >
                        {_.map(selectedArticles, (article, idx) => {
                            return (
                                <PortraitCard
                                    key={`article-${article._id}-${idx}`}
                                    article={article}
                                />
                            );
                        })}
                    </Carousel>
                ) : (
                    <Div ht="300px" fluid ash flex>
                        No challenges yet
                    </Div>
                )}
            </Div>
        </Div>
    );
}
