import React, { useEffect } from "react";
import "@styles/readers.scss";
import { Div, Image } from "@components/Generics.react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export default function InfographicReader(props) {
    const { deck, currentState, recordAttempt } = props;
    const isPortrait = window.innerHeight > window.innerWidth;

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentState]);

    // ========================= Render Function =================================

    return (
        <Div
            fullht
            fluid
            centered
            center-align
            basepad
            autoOverflow
            relative
            half
            className="fullscreen-target"
            flex={!isPortrait}
        >
            <TransformWrapper>
                <TransformComponent>
                    <Image fluid src={deck.content} />
                </TransformComponent>
            </TransformWrapper>
        </Div>
    );
}
