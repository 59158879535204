import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useEditUserMutation } from "@api/apiV6";
import { toast } from "react-toastify";

import { Div, Button } from "@components/Generics.react";
import { Modal, Icon, Input } from "semantic-ui-react";

export default function EditUserModal(props) {
    const { isOpen, setOpen, user } = props;
    const { t } = useTranslation("common");
    const [fullName, setFullName] = useState(
        `${user.first_name} ${user.last_name}`
    );
    const [email, setEmail] = useState(user.email);
    const [validEmail, setValidEmail] = useState(true);
    const [error] = useState(null);

    const [editUser] = useEditUserMutation();

    useEffect(() => {
        // eslint-disable-next-line
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
            ? setValidEmail(true)
            : setValidEmail(false);
    }, [email]);

    async function tryUpdate() {
        const nameSplit = fullName.split(" ");
        const updateResponse = await editUser({
            id: user._id,
            email: email,
            first_name: nameSplit[0],
            last_name: nameSplit[1] == null ? " " : nameSplit[1],
        });
        if (updateResponse) {
            toast("User Updated");
            setOpen(false);
        }
    }

    const checkStyle = { float: "right", margin: "-30px 10px 0 0" };

    // ========================= Render Function =================================
    return (
        <Modal size="small" open={isOpen} onClose={() => setOpen(false)}>
            <Modal.Header>
                {t("admin.userMgmt.editUser")}
                <Div float-right clickable>
                    <Icon name="times" onClick={() => setOpen(false)} />
                </Div>
            </Modal.Header>
            <Modal.Content>
                <Div white basepad>
                    {error && (
                        <Div padded fluid danger gutter>
                            {error}
                        </Div>
                    )}
                    <Div fluid gutter>
                        <Input
                            fluid
                            label={t("auth.registration.usernameLabel")}
                            placeholder={t(
                                "auth.registration.usernamePlaceholder"
                            )}
                            disabled
                            value={user.username}
                        />
                    </Div>
                    <Div fluid gutter>
                        <Input
                            fluid
                            label={t("auth.registration.nameLabel")}
                            placeholder={t("auth.registration.namePlaceholder")}
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                        />
                    </Div>
                    <Div fluid gutter>
                        <Input
                            fluid
                            label="Email"
                            type="email"
                            placeholder="Where can we contact you?"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {!validEmail && email?.length > 0 && (
                            <Div relaxed danger fluid>
                                <i className="fa fa-times" /> &nbsp; Malformed
                                email address.
                            </Div>
                        )}
                        {validEmail && (
                            <Div txtColor="success" style={checkStyle}>
                                <i className="fa fa-check-circle" />
                            </Div>
                        )}
                    </Div>
                </Div>
                <Button
                    primary
                    content="Update User"
                    onClick={tryUpdate}
                    disabled={!validEmail}
                />
            </Modal.Content>
        </Modal>
    );
}
