// Import from NPM
// -------------------------------------
import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Dropdown, Input, Modal, Icon, Tab } from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import { useCreateNewContentMutation } from "@api/apiV6";
import { useFeatures } from "flagged";
import { useSelector } from "react-redux";
import { deckFormats } from "@schemas/deckFormats";

import { Div, Button } from "@components/Generics.react";
import "@styles/navbars.scss";
import ImageUploader from "@components/ImageUploader.react";

export default function NewContentModal(props) {
    const {
        newModalOpen,
        setNewModalOpen,
        programs,
        setSelected,
        activeSection,
        courseCount,
        articleCount,
    } = props;
    const features = useFeatures();
    const { t } = useTranslation("common");
    const user = useSelector((state) => state.auth.user);

    const publicLibrary = _.find(programs, { name: t("builder.dic.pubLib") });
    const newContentTypes = [
        features.ability.program.create && {
            name: t(`builder.welcome.program`),
            key: "programs",
            selectOptions: _.map(
                [
                    features.courseware && {
                        text: t(`builder.welcome.course`),
                        key: "Course",
                    },
                    features.learningCenter && {
                        text: t(`builder.welcome.article`),
                        key: "Article",
                    },
                ].filter(Boolean),
                (p) => {
                    return {
                        key: p.key,
                        value: p.key,
                        text: p.text,
                    };
                }
            ),
            selectName: t(`builder.welcome.programType`),
            selectKey: "list_type",
            aspectRatio: "0.5",
        },
        features.learningCenter &&
            (user.license.maxArticles == null ||
                articleCount < user.license.maxArticles) && {
                name: t(`builder.welcome.article`),
                sectionName: t(`learningCenter`),
                key: "articles",
                parentOptions: _.map(
                    _.filter(
                        programs,
                        (p) =>
                            p.list_type === "Article" &&
                            p.owner?.team === user.team
                    ),
                    (p) => {
                        return {
                            key: p._id,
                            value: p._id,
                            text: p.name,
                        };
                    }
                ),
                parentName: t(`builder.welcome.parent`),
                parentKey: "parent",
                selectOptions: _.map(
                    _.filter(
                        deckFormats,
                        (df) => features.articleFormats[df.category]
                    ),
                    (p, idx) => {
                        return {
                            key: idx,
                            value: p.readerType,
                            text: p.name,
                        };
                    }
                ),
                selectName: t(`builder.welcome.articleType`),
                selectKey: "category",
                aspectRatio: "0.33",
            },
        features.courseware &&
            (user.license.maxCourses == null ||
                courseCount < user.license.maxCourses) && {
                name: t(`builder.welcome.course`),
                sectionName: t(`courseware`),
                key: "courses",
                parentOptions: features.courses.onlyPublic
                    ? [
                          {
                              key: publicLibrary._id,
                              value: publicLibrary._id,
                              text: publicLibrary.name,
                          },
                      ]
                    : _.map(
                          _.filter(
                              programs,
                              (p) =>
                                  p.list_type === "Course" &&
                                  (p.owner?.team === user.team ||
                                      p._id === publicLibrary._id)
                          ),
                          (p) => {
                              return {
                                  key: p._id,
                                  value: p._id,
                                  text: p.name,
                              };
                          }
                      ),
                parentName: t(`builder.welcome.parent`),
                parentKey: "parent",
                aspectRatio: "0.5",
            },
        features.courseware &&
            features.courses.hasExams && {
                name: t(`builder.welcome.exam`),
                sectionName: t(`exams`),
                key: "exams",
                parentOptions: _.map(
                    _.filter(
                        programs,
                        (p) =>
                            p.list_type === "Course" &&
                            (p.owner?.team === user.team ||
                                p._id === publicLibrary._id)
                    ),
                    (p) => {
                        return {
                            key: p._id,
                            value: p._id,
                            text: p.name,
                        };
                    }
                ),
                parentName: t(`builder.welcome.parent`),
                parentKey: "parent",
                aspectRatio: "0.5",
            },
    ].filter(Boolean);

    const defaultType =
        activeSection != null
            ? _.find(newContentTypes, { sectionName: activeSection })
            : newContentTypes[0];

    const [entityKey, setEntityKey] = useState(defaultType.key);

    const [entityParentKey, setEntityParentKey] = useState(
        defaultType.parentKey
    );
    const [entityParent, setEntityParent] = useState(null);

    const [entitySelectKey, setEntitySelectKey] = useState(
        defaultType.selectKey
    );
    const [entityType, setEntityType] = useState(null);

    const [entityName, setEntityName] = useState("");
    const [entityDescription, setEntityDescription] = useState("");
    const [entityImage, setEntityImage] = useState(null);

    const [imgEditorOpen, setImgEditorOpen] = useState(false);
    const [aspectRatio, setAspectRatio] = useState(0.5);

    const [createEntity] = useCreateNewContentMutation();

    useEffect(() => {
        setEntityKey(defaultType.key);
        setEntityParentKey(defaultType.parentKey);
        setEntitySelectKey(defaultType.selectKey);
        setAspectRatio(defaultType.aspectRatio);
        setEntityType(null);
        setEntityParent(null);
        setEntityName("");
        setEntityDescription("");
        setEntityImage(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultType.key]);

    function handleTabChange(e, data) {
        setEntityKey(newContentTypes[data.activeIndex].key);
        setEntityParentKey(newContentTypes[data.activeIndex].parentKey);
        setEntitySelectKey(newContentTypes[data.activeIndex].selectKey);
        setAspectRatio(newContentTypes[data.activeIndex].aspectRatio);
        setEntityType(null);
        setEntityParent(null);
        setEntityName("");
        setEntityDescription("");
        setEntityImage(null);
    }
    async function saveNewContent() {
        const createResponse = await createEntity({
            name: entityName,
            description: entityDescription,
            image: entityImage,
            [entityParentKey]: entityParent,
            [entitySelectKey]: entityType,
            readerType: entityType,
            contentType: entityKey === "exams" ? "courses" : entityKey,
            course_type: entityKey === "exams" ? "exam" : "standard",
        }).unwrap();
        setNewModalOpen(false);
        if (entityKey !== "program") {
            const storedRecents = window.localStorage.getItem("libraryRecents");
            let libraryRecents = [];
            if (storedRecents) {
                libraryRecents = JSON.parse(storedRecents);
            }
            libraryRecents = _.uniqBy(
                _.concat(
                    [],
                    {
                        title: entityName,
                        key: createResponse._id,
                        type: "child",
                        section: entityKey,
                    },
                    libraryRecents
                ),
                "key"
            ).slice(0, 5);
            window.localStorage.setItem(
                "libraryRecents",
                JSON.stringify(libraryRecents)
            );
            setSelected({
                key: createResponse._id,
                type: "child",
                section: entityKey,
            });
        }
    }

    function saveImage(image) {
        setEntityImage(image);
        setImgEditorOpen(false);
    }

    function showPane(cType) {
        const { name, selectOptions, selectName, parentOptions, parentName } =
            cType;
        const noParent = parentOptions == null || parentOptions.length === 0;
        const singleParent = !noParent && parentOptions.length === 1;
        const noSelect = selectOptions == null || selectOptions.length === 0;
        const singleSelect = !noSelect && selectOptions.length === 1;
        if (singleParent) {
            setEntityParent(parentOptions[0].value);
        }
        if (singleSelect) {
            setEntityType(selectOptions[0].value);
        }
        return (
            <Div fullht>
                <Div uppercase large gutter altText>
                    {t(`components.new`)} {name}:
                </Div>
                {!noParent && !singleParent && (
                    <Div gapBottom clearfix>
                        <Div fit-content float-left medpad smoke snubbed>
                            {parentName}:
                        </Div>
                        <Div wd="60%" float-left nudgeLeft>
                            <Dropdown
                                fluid
                                selection
                                search
                                placeholder={`${t(
                                    `components.select`
                                )} ${parentName}`}
                                value={entityParent}
                                options={parentOptions}
                                onChange={(e, { value }) =>
                                    setEntityParent(value)
                                }
                            />
                        </Div>
                    </Div>
                )}
                {!noSelect && !singleSelect && (
                    <Div gapBottom clearfix>
                        <Div fit-content float-left medpad smoke snubbed>
                            {selectName}:
                        </Div>
                        <Div wd="60%" float-left nudgeLeft>
                            <Dropdown
                                fluid
                                selection
                                search
                                placeholder={`${t(
                                    `components.select`
                                )} ${selectName}`}
                                value={entityType}
                                options={selectOptions}
                                onChange={(e, { value }) =>
                                    setEntityType(value)
                                }
                            />
                        </Div>
                    </Div>
                )}
                <Div gapBottom clear>
                    <Input
                        fluid
                        label={t(`builder.dic.name`)}
                        value={entityName}
                        onChange={(e, { value }) => setEntityName(value)}
                    />
                </Div>
                <Div gapBottom>
                    <Input
                        fluid
                        label={t(`builder.dic.description`)}
                        value={entityDescription}
                        onChange={(e, { value }) => setEntityDescription(value)}
                    />
                </Div>
                <Div gapBottom>
                    <Button
                        fluid
                        icon="images"
                        labelPosition="left"
                        content={t(`components.uploadImage`)}
                        onClick={() => setImgEditorOpen(true)}
                    />
                    <ImageUploader
                        modalOpen={imgEditorOpen}
                        handleModalClose={() => setImgEditorOpen(false)}
                        handleModalSave={saveImage}
                        value={entityImage}
                        aspectRatio={aspectRatio}
                        mWidth={480}
                    />
                </Div>
                <br />
                <Div float-right>
                    <Button
                        primary
                        content={t(`components.submit`)}
                        disabled={
                            entityName === "" ||
                            (entityKey !== "programs" &&
                                entityParent == null &&
                                !noParent) ||
                            (entitySelectKey != null && entityType == null)
                        }
                        onClick={saveNewContent}
                    />
                </Div>
            </Div>
        );
    }

    // ========================= Render Function =================================
    return (
        <Modal open={newModalOpen} onClose={() => setNewModalOpen(false)}>
            <Modal.Header>
                {t(`builder.welcome.contentModalHeader`)}
                <Div float-right clickable>
                    <Icon name="times" onClick={() => setNewModalOpen(false)} />
                </Div>
            </Modal.Header>
            <Div ash basepad>
                <Tab
                    defaultActiveIndex={newContentTypes.indexOf(defaultType)}
                    onTabChange={handleTabChange}
                    menu={{ fluid: true, vertical: true }}
                    panes={_.map(newContentTypes, (cType) => {
                        return {
                            menuItem: cType.name,
                            render: () => (
                                <Tab.Pane>{showPane(cType)}</Tab.Pane>
                            ),
                        };
                    })}
                />
            </Div>
        </Modal>
    );
}
