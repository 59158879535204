import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Div } from "@components/Generics.react";
import ArticleCard from "@components/ArticleCard.react";
import { Image, Input } from "semantic-ui-react";

export default function FeedHome(props) {
    const { header, description, tags, image, subPage = false } = props;
    const taggedList = useSelector((state) => state.articles.taggedList);
    const [searchText, setSearchText] = useState("");
    const { t } = useTranslation("common");
    const isPortrait = window.innerHeight > window.innerWidth;

    const proppedTags = _.filter(
        tags,
        (tag) => taggedList[tag]?.articles.length > 0
    );
    const selectedArticles = useMemo(
        () =>
            _.uniqBy(
                _.flatten(
                    _.map(proppedTags, (tag) => taggedList[tag]?.articles)
                ),
                "_id"
            ),
        [proppedTags, taggedList]
    );
    const searchedArticles = useMemo(
        () =>
            searchText === ""
                ? selectedArticles
                : _.filter(selectedArticles, (article) =>
                      _.includes(_.toLower(article.name), _.toLower(searchText))
                  ),
        [searchText, selectedArticles]
    );

    // ========================= Render Function =================================
    return (
        <Div>
            {!subPage && (
                <Div>
                    <Div fluid relative rimmed>
                        <Div rounded noOverflow>
                            <Image fluid src={image} />
                        </Div>
                        <Div
                            absolute
                            big
                            bold
                            uppercase
                            topRounded
                            className="root-layout-header"
                        >
                            {header}
                        </Div>
                    </Div>
                    <Div white padSides gapBottom>
                        <Div gapTop gutter>
                            {description}
                        </Div>
                    </Div>
                </Div>
            )}
            <Div medpad ash>
                <Input
                    fluid
                    placeholder={t("builder.dic.searchWithin")}
                    icon={{ name: "search" }}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </Div>
            <Div rimmed smoke>
                {_.map(searchedArticles, (article, idx) => {
                    return (
                        <Div
                            float-left
                            wd={
                                window.innerWidth > 1280
                                    ? "33%"
                                    : isPortrait
                                    ? "100%"
                                    : "50%"
                            }
                            gapBottom
                            key={`article-${article._id}-${idx}`}
                        >
                            <ArticleCard article={article} feedStyle />
                        </Div>
                    );
                })}
            </Div>
        </Div>
    );
}
