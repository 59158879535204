import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFeatures } from "flagged";

import _ from "lodash";
import {
    Card,
    Image,
    Dropdown,
    Modal,
    Label,
    Icon,
    Input,
    Divider,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { Div, Button } from "@components/Generics.react";
import BuilderHeader from "@components/BuilderHeader.react";
import PreviewLayout from "@layouts/PreviewLayout.react";
import ShareWidget from "@components/ShareWidget.react";
import BulkContentUploader from "./BulkContentUploader.react";

export default function RepoListing(props) {
    const { content } = props;
    const user = useSelector((state) => state.auth.user);
    const { t } = useTranslation("common");
    const features = useFeatures();

    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const [shareModalOpen, setShareModalOpen] = useState(false);
    const [bulkQuizModalOpen, setBulkQuizModalOpen] = useState(false);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [filters, setFilters] = useState({
        subject: [],
        target: [],
        category: [],
    });

    const subjectOptions = useMemo(
        () =>
            _.chain(content)
                .map((art) => art.tags)
                .flatten()
                .map("name")
                .filter((name) => _.includes(name, "Subject"))
                .uniq()
                .map((s, idx) => ({
                    key: s + idx + "-subject",
                    value: s,
                    text: s.replace("Subject: ", ""),
                }))
                .value(),
        [content]
    );

    const targetOptions = useMemo(
        () =>
            _.chain(content)
                .map((art) => art.tags)
                .flatten()
                .map("name")
                .filter((name) => _.includes(name, "Grade"))
                .uniq()
                .map((s, idx) => ({
                    key: s + idx + "-grade",
                    value: s,
                    text: s.replace("Grade: ", ""),
                }))
                .value(),
        [content]
    );

    const categoryOptions = useMemo(
        () =>
            _.chain(content)
                .map((art) => art.category)
                .uniq()
                .map((s, idx) => ({
                    key: s + idx + "-category",
                    value: s,
                    text: _.capitalize(s.replace("deck", "")),
                }))
                .value(),
        [content]
    );

    const handleFilterChange = (name, value) => {
        setFilters({ ...filters, [name]: value });
    };
    const validFilters = _.filter(
        _.keys(filters),
        (k) => filters[k].length !== 0
    );
    const filteredFiles = _.filter(content, (article) => {
        const hasText =
            _.trim(searchText) === "" ||
            _.includes(article.name.toLowerCase(), searchText.toLowerCase());
        let filterCheck = true;
        _.each(validFilters, (k) => {
            if (
                !_.some(_.map(filters[k], _.toLower), (filter) =>
                    _.includes(
                        _.map(article.tags, (t) => _.toLower(t.name)),
                        filter
                    )
                ) &&
                !_.some(
                    _.map(filters[k], _.toLower),
                    (filter) => article.category === filter
                )
            )
                filterCheck = false;
        });

        return hasText && filterCheck;
    });
    function copyTextToClipboard(text) {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                toast("Text copied to clipboard");
            })
            .catch((err) => {
                console.error("Failed to copy text: ", err);
            });
    }

    return (
        <Div fullht fluid flex flexStart top-align>
            <Div fullht wd="300px" slightShadow>
                <Div slightShadow ht={"145px"} noOverflow relative>
                    <Image
                        fluid
                        src={`/assets/images/configurable/appcontent.jpg`}
                    />
                    <Div
                        absolute
                        bold
                        large
                        uppercase
                        className="browser-bar-header"
                    >
                        Worksheet Library
                    </Div>
                </Div>
                <Div large bold padded ash slightShadow spaceBottom>
                    Filters:
                </Div>
                <Div padded flex column gapped>
                    <Dropdown
                        placeholder="Select Subject"
                        fluid
                        selection
                        clearable
                        multiple
                        options={subjectOptions}
                        onChange={(e, { name, value }) =>
                            handleFilterChange(name, value)
                        }
                        name="subject"
                        value={filters.subject}
                    />
                    <Dropdown
                        placeholder="Select Grade"
                        fluid
                        selection
                        clearable
                        multiple
                        options={targetOptions}
                        onChange={(e, { name, value }) =>
                            handleFilterChange(name, value)
                        }
                        name="target"
                        value={filters.target}
                    />
                    <Dropdown
                        placeholder="Select Type"
                        fluid
                        selection
                        clearable
                        multiple
                        options={categoryOptions}
                        onChange={(e, { name, value }) =>
                            handleFilterChange(name, value)
                        }
                        name="category"
                        value={filters.category}
                    />
                    {features.ability.articles.create && (
                        <Div fluid>
                            <Divider />
                            <Button
                                primary
                                fluid
                                content="Bulk Upload Quizzes"
                                onClick={() => setBulkQuizModalOpen(true)}
                            />
                            <BulkContentUploader
                                isOpen={bulkQuizModalOpen}
                                setOpen={setBulkQuizModalOpen}
                            />
                        </Div>
                    )}
                </Div>
            </Div>
            <Div fullht wd="calc(100% - 300px)">
                <BuilderHeader
                    image={"/assets/images/configurable/appcontent.jpg"}
                    header={t("builder.headers.repo.header")}
                    description={t("builder.headers.repo.help")}
                />
                <Div medpad fullht>
                    <Input
                        fluid
                        placeholder={t("builder.dic.searchWithin")}
                        icon={{ name: "search" }}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                    <Div ht="calc(100% - 170px)" autoOverflowY medpad>
                        <Card.Group itemsPerRow={3}>
                            {_.map(filteredFiles, (article, index) => {
                                return (
                                    <Card key={`csv-article-${index}`}>
                                        {article.image && (
                                            <Image
                                                src={article.image}
                                                wrapped
                                                fluid
                                            />
                                        )}
                                        <Card.Content>
                                            <Card.Header>
                                                {_.capitalize(article.name)}
                                            </Card.Header>
                                            <Card.Meta>
                                                <span className="date">
                                                    {_.capitalize(
                                                        article.category.replace(
                                                            "deck",
                                                            ""
                                                        )
                                                    )}
                                                </span>
                                            </Card.Meta>
                                            <Card.Description>
                                                <Label>
                                                    <Icon name="user" />{" "}
                                                    {_.chain(article.tags)
                                                        .map("name")
                                                        .filter((name) =>
                                                            _.includes(
                                                                name,
                                                                "Grade"
                                                            )
                                                        )
                                                        .value()}
                                                </Label>
                                                <Label>
                                                    <Icon name="book" />{" "}
                                                    {_.chain(article.tags)
                                                        .map("name")
                                                        .filter((name) =>
                                                            _.includes(
                                                                name,
                                                                "Subject:"
                                                            )
                                                        )
                                                        .replace(
                                                            "Subject: ",
                                                            ""
                                                        )
                                                        .value()}
                                                </Label>
                                            </Card.Description>
                                        </Card.Content>
                                        <Card.Content
                                            extra
                                            style={{ background: "#efefef" }}
                                        >
                                            <Div fluid flex spaceBetween>
                                                <Button
                                                    size="tiny"
                                                    secondary
                                                    content="View"
                                                    icon="eye"
                                                    labelPosition="left"
                                                    onClick={() => {
                                                        setSelectedArticle(
                                                            article
                                                        );
                                                        setPreviewModalOpen(
                                                            true
                                                        );
                                                    }}
                                                />
                                                <Button
                                                    size="tiny"
                                                    primary
                                                    content="Share"
                                                    icon="share alternate"
                                                    labelPosition="right"
                                                    onClick={() => {
                                                        setSelectedArticle(
                                                            article
                                                        );
                                                        setShareModalOpen(true);
                                                    }}
                                                />
                                            </Div>
                                        </Card.Content>
                                    </Card>
                                );
                            })}
                        </Card.Group>
                    </Div>
                </Div>
            </Div>
            {selectedArticle && (
                <Modal
                    open={previewModalOpen}
                    onClose={() => setPreviewModalOpen(false)}
                    basic
                    style={{ height: "90vh", width: "auto" }}
                >
                    <Div fluid fullht>
                        <PreviewLayout
                            url={`a/${selectedArticle.identifier}`}
                        />
                        <Button
                            fluid
                            danger={+true}
                            content="Close"
                            onClick={() => setPreviewModalOpen(false)}
                        />
                    </Div>
                </Modal>
            )}
            {selectedArticle && (
                <Modal
                    open={shareModalOpen}
                    onClose={() => setShareModalOpen(false)}
                >
                    <Modal.Content>
                        <Div flex flexStart padSides>
                            <Div maxWd="256px">
                                {selectedArticle.image && (
                                    <Image
                                        fluid
                                        src={selectedArticle.image}
                                        wrapped
                                    />
                                )}
                            </Div>
                            <Div medpad>
                                <Div uppercase huge>
                                    {_.capitalize(selectedArticle.name)}
                                </Div>
                                <Div>
                                    {_.capitalize(
                                        selectedArticle.category?.replace(
                                            "deck",
                                            ""
                                        )
                                    )}
                                </Div>
                            </Div>
                        </Div>
                        <Divider />
                        <Div fluid flex spaceBetween medpad top-aligned>
                            <Div wd="50%" basepad>
                                <Div bold uppercase big gutter>
                                    Share Link
                                </Div>
                                <Div gutter>
                                    Pass on this link to your students to access
                                    this worksheet...
                                </Div>
                                <Div gutter>
                                    <Input
                                        fluid
                                        action={{
                                            icon: (
                                                <Icon
                                                    name="copy"
                                                    link
                                                    onClick={() =>
                                                        copyTextToClipboard(
                                                            `https://app.teachiquo.com/a/${user.team}/${selectedArticle.identifier}`
                                                        )
                                                    }
                                                />
                                            ),
                                            color: "teal",
                                        }}
                                        value={`https://app.teachiquo.com/a/${user.team}/${selectedArticle.identifier}`}
                                    />
                                </Div>
                                <ShareWidget
                                    url={`https://app.teachiquo.com/a/${user.team}/${selectedArticle.identifier}`}
                                    subject={selectedArticle.name}
                                />
                            </Div>
                            <Div wd="50%" basepad leftBordered>
                                <Div bold uppercase big gutter>
                                    Access with Code
                                </Div>
                                <Div gutter>
                                    Ask your students to visit this page...
                                </Div>
                                <Div gutter medpad ash>
                                    https://app.teachiquo.com/{user.team}/code
                                </Div>
                                <Div gutter flex spaceBetween>
                                    and enter this code into the field:{" "}
                                    <Div ash large bold medpad>
                                        {selectedArticle.identifier}
                                    </Div>
                                </Div>
                            </Div>
                        </Div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            danger={+true}
                            content="Close"
                            onClick={() => setShareModalOpen(false)}
                        />
                    </Modal.Actions>
                </Modal>
            )}
        </Div>
    );
}
