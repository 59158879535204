import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import features from "../config/features/features.json";
import tenantMap from "../config/features/tenants.json";
import { snaked } from "@utilities/helpers";

const initialState = { list: [], taggedList: {} };

export const articlesSlice = createSlice({
    name: "articles",
    initialState,
    reducers: {
        clearArticles: () => {
            return initialState;
        },
        organizeArticles: (state, action) => {
            const userTeam = action.payload.user.team;
            const tenant =
                tenantMap[userTeam] != null
                    ? tenantMap[userTeam]
                    : tenantMap.default;
            const allArticles = features.tenanting.multiTenanted
                ? _.filter(
                      action.payload.list,
                      (article) =>
                          // Either the article owner's team is the same as the user's team
                          // Or the article is from the tenant's whitelist and not one of the blacklisted articles
                          article.owner?.team === action.payload.user.team ||
                          (_.includes(tenant.whitelist, article.owner?.team) &&
                              !_.includes(
                                  tenant.blacklist,
                                  article.identifier
                              )) ||
                          _.includes(
                              action.payload.user.branding.repos,
                              article.owner?.team
                          )
                  )
                : action.payload.list;
            const articleList = _.filter(
                allArticles,
                (art) =>
                    features.articles.langNeutral ||
                    art.languages.length === 0 ||
                    _.includes(art.languages, action.payload.user.lang)
            );
            const tagList = _.chain(articleList)
                .flatMap((article) =>
                    article.tags.map((tag) => ({
                        tag_name: snaked(tag.name),
                        _id: tag._id,
                        article: article,
                    }))
                )
                .groupBy("tag_name")
                .mapValues((groupedTags) => ({
                    _id: groupedTags[0]._id,
                    articles: groupedTags.map((tag) => tag.article),
                }))
                .value();
            const categoryList = _(articleList)
                .groupBy("category")
                .mapValues((articles) => ({ articles }))
                .value();
            state.list = _.keyBy(articleList, "identifier");
            state.taggedList = _.merge(tagList, categoryList);
            return state;
        },
    },
});

// Action creators are generated for each case reducer function
export const { clearArticles, organizeArticles } = articlesSlice.actions;

export default articlesSlice.reducer;
