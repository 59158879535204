import React from "react";
import { useTranslation } from "react-i18next";
import { Div } from "@components/Generics.react";
import CreatorLayout from "@layouts/CreatorLayout.react";
import TicketsTable from "./panels/TicketsTable.react";
import TicketForm from "./panels/TicketForm.react";
import HelpFaqs from "./panels/HelpFaqs.react";
import BuilderHeader from "@components/BuilderHeader.react";
import { Tab, Image } from "semantic-ui-react";
import TutorialsPage from "../creator/home/TutorialsPage.react";

export default function CreatorHelpDesk(props) {
    const { t } = useTranslation("common");

    const pageContent = (
        <Div fluid fullht>
            <Div flex flexStart top-align fullht>
                <Div wd="300px" fullht vapor slightShadow>
                    {window.innerHeight > 720 && (
                        <Div
                            wd="300px"
                            ht="145px"
                            noOverflow
                            slightShadow
                            relative
                        >
                            <Image src="/assets/images/configurable/apphelpdesk.jpg" />
                            <Div
                                absolute
                                bold
                                large
                                uppercase
                                className="browser-bar-header"
                            >
                                {t("builder.welcome.helpdeskHeader")}
                            </Div>
                        </Div>
                    )}
                    <Div big bold uppercase basepad>
                        {t("helpdesk.resources")}
                    </Div>
                    <Div ht={`${window.innerHeight - 130}px`}>
                        <Tab
                            panes={[
                                {
                                    menuItem: "Tutorials",
                                    render: () => (
                                        <Tab.Pane style={{ height: "100%" }}>
                                            <TutorialsPage />
                                        </Tab.Pane>
                                    ),
                                },
                                {
                                    menuItem: "FAQs",
                                    render: () => (
                                        <Tab.Pane style={{ height: "100%" }}>
                                            <HelpFaqs />
                                        </Tab.Pane>
                                    ),
                                },
                            ]}
                            style={{ height: "100%" }}
                        />
                    </Div>
                </Div>
                <Div wd="calc(100% - 300px)" fullht>
                    <BuilderHeader
                        image="/assets/images/configurable/apphelpdesk.jpg"
                        header={t("builder.headers.helpdesk.header")}
                        description={t("builder.headers.helpdesk.help")}
                    />
                    <Div
                        flex
                        fluid
                        flexStart
                        ht="calc(100% - 150px)"
                        spaceBetween
                        top-align
                        basepad
                    >
                        <Div wd="360px" fullht medpad autoOverflowY white>
                            <TicketForm />
                        </Div>
                        <Div
                            wd="calc(100% - 370px)"
                            fullht
                            medpad
                            autoOverflowY
                            white
                        >
                            <TicketsTable />
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Div>
    );

    return (
        <CreatorLayout
            title={t("helpdesk.header")}
            navName={t("helpdesk.header")}
            pageCode={"helpdesk"}
            mainPanel={pageContent}
            rootPage={false}
        />
    );
}
