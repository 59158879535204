import React from "react";
import { HDiv } from "@components/Generics.react";
export default function Blurb(props) {
    const { blurb, paragraph } = props.data;
    const isPortrait = window.innerHeight > window.innerWidth;

    return (
        <HDiv
            fluid
            flex={isPortrait}
            superpad
            big
            content={paragraph || blurb}
        />
    );
}
