// Import from NPM
// -------------------------------------
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import axios from "axios";
import { baseUrl, useGetCreditsQuery } from "@api/apiV6";

import _ from "lodash";
import { Div, Button } from "@components/Generics.react";
import { Dropdown, Dimmer, Loader } from "semantic-ui-react";
import { settings } from "@config/settings/app.settings";

export default function AIQTranslate(props) {
    const { deck, setDeck, setRightPanel } = props;
    const token = useSelector((state) => state.auth.token);
    const { data: aiStatus, isSuccess, refetch } = useGetCreditsQuery();
    const { t } = useTranslation("common");

    const [fetching, setFetching] = useState(false);
    const [langValue, setLangValue] = useState("English");

    const creditsNeeded = Math.ceil(
        (JSON.stringify(deck.content).length - 50 * deck.content.length - 100) /
            75
    );
    async function getAIResponse() {
        setDeck({
            ...deck,
            ...{ content: [] },
        });
        setFetching(true);
        const response = await axios({
            method: "post",
            url: `${baseUrl}api/v3/ai/transQuestions`,
            data: {
                deckId: deck.id,
                lang: langValue,
                cost: creditsNeeded,
            },
            headers: { "access-token": token },
        });
        const output = JSON.parse(response.data.response.message.content);
        setDeck({
            ...deck,
            ...{ content: output },
        });
        setFetching(false);
        refetch();
        setRightPanel("mobile");
    }

    // ========================= Render Function =================================
    return (
        <Div
            minWd="400px"
            wd="400px"
            fullht
            relative
            peat
            basepad
            ht={`calc(100vh - 225px)`}
            darkInset
        >
            <Div white fullht rounded basepad slightShadow>
                <Div bold large compact uppercase>
                    {t("builder.deck.aiqtrans")}:
                </Div>
                <Div italics gutter compact>
                    {t("builder.deck.aiqtransHelp")}
                </Div>
                {isSuccess && (
                    <Div rounded>
                        <Dropdown
                            placeholder={t("builder.dic.selectLanguage")}
                            fluid
                            selection
                            options={_.map(
                                _.filter(
                                    settings.languages,
                                    (l) => l.code !== "en"
                                ),
                                (l) => ({
                                    key: l.code,
                                    text: l.identifier,
                                    value: l.identifier,
                                })
                            )}
                            value={langValue}
                            onChange={(e, { value }) => setLangValue(value)}
                        />
                        <br />
                        <Button
                            size="small"
                            primary
                            onClick={getAIResponse}
                            disabled={aiStatus.credits <= 0}
                        >
                            Generate ({creditsNeeded}{" "}
                            {creditsNeeded === 1 ? "credit" : "credits"})
                        </Button>
                        <Div
                            small
                            italics
                            float-right
                            nudgeRight
                            ht="40px"
                            flex
                        >
                            {aiStatus.credits} generative credits left
                        </Div>
                    </Div>
                )}
                {fetching && (
                    <Div superpad ivory>
                        <Dimmer active>
                            <Loader>
                                This will take time. Please be patient...
                            </Loader>
                        </Dimmer>
                    </Div>
                )}
            </Div>
        </Div>
    );
}
