import React, { useEffect, useState } from "react";
import _ from "lodash";
import "@styles/readers.scss";
import Carousel from "@components/Carousel.react";

import { Div, HDiv, Icon } from "@components/Generics.react";

export default function FlashCardReader(props) {
    const { deck, recordAttempt } = props;
    const [showFront, setShowFront] = useState(true);

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // ========================= Render Function =================================
    return (
        <Div fluid fullht maxWd="400px" centered flex>
            <Carousel
                slidesToShow={1}
                enableButtons
                handleChange={() => setShowFront(true)}
            >
                {_.map(deck?.content, (card, idx) => {
                    return (
                        <Div
                            className="flash-card"
                            key={`flash-card-${idx}`}
                            trench
                            clickable
                        >
                            <Div
                                className={`flip-card ${
                                    showFront ? "" : "flipped"
                                }`}
                            >
                                <Div className="flip-card-inner" rounded>
                                    <Div className="flip-card-front">
                                        <Div
                                            absolute
                                            fullht
                                            superpad
                                            flex
                                            fluid
                                            rounded
                                            style={{
                                                background: `url(${card.image})`,
                                                backgroundSize: "cover",
                                                backgroundPosition:
                                                    "center center",
                                            }}
                                            onClick={() => setShowFront(false)}
                                        >
                                            <Div
                                                basepad
                                                rounded
                                                big
                                                className="flip-card-question"
                                            >
                                                {card.question}
                                            </Div>
                                        </Div>
                                    </Div>
                                    <Div
                                        main
                                        big
                                        className="flip-card-back"
                                        onClick={() => setShowFront(true)}
                                    >
                                        <Div
                                            absolute
                                            fullht
                                            superpad
                                            flex
                                            fluid
                                        >
                                            <HDiv content={card.answer} />
                                        </Div>
                                    </Div>
                                </Div>
                            </Div>
                            <Div
                                pureCircle
                                primary
                                className="flipper"
                                dropShadow
                                onClick={() => setShowFront(!showFront)}
                            >
                                <Icon huge name="shield alternate" />
                            </Div>
                        </Div>
                    );
                })}
            </Carousel>
           
        </Div>
    );
}
