import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";
import { Div } from "@components/Generics.react";
import "@styles/components.scss";
const InlineTextarea = ({ text, handleSave }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedText, setEditedText] = useState(text);
    const { t } = useTranslation("common");

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleInputChange = (e) => {
        setEditedText(e.target.value);
    };

    const handleInputBlur = () => {
        setIsEditing(false);
        // Call handleSave when the textarea loses focus
        handleSave(editedText);
    };

    const handleInputKeyPress = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault(); // Prevent line break on Enter
            setIsEditing(false);
            // Call handleSave when Enter key is pressed
            handleSave(editedText);
        }
    };

    return (
        <div>
            {isEditing ? (
                <Div>
                    <textarea
                        autoFocus
                        value={editedText}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        onKeyPress={handleInputKeyPress}
                        rows="3" // Adjust the number of rows as needed
                        style={{ width: "100%", padding: "5px" }}
                    />
                    <Div italics light gapTop gutter>
                        {t("components.pressEnter")}
                    </Div>
                </Div>
            ) : (
                <Div flex flexStart onClick={handleEditClick}>
                    <Div wd="30px" large clickable>
                        <Icon
                            name="pencil square"
                            className="inline-edit-icon"
                        />
                    </Div>
                    <Div wd="calc(100% - 30px)" leftBordered padded>
                        {text}
                    </Div>
                </Div>
            )}
        </div>
    );
};

export default InlineTextarea;
