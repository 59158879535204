import React, { useState, useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import { Document, Page, pdfjs } from "react-pdf";

import "@styles/readers.scss";

import { Div } from "@components/Generics.react";
import DeckToolbar from "@components/DeckToolbar.react";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PDFViewer(props) {
    const { pdf } = props.data;

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);
    const [scale, setScale] = useState(1);
    const docref = useRef();
    const { events } = useDraggable(docref, {
        applyRubberBandEffect: true, // activate rubber band effect
    });

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const targetWidth = docref.current?.getBoundingClientRect().width - 15;
    const targetHeight = docref.current?.getBoundingClientRect().height - 15;

    // ========================= Render Function =================================

    return (
        <Div
            fullht
            fluid
            centered
            center-align
            compact
            noOverflow
            relative
            half
            gapTop
        >
            <div {...events} ref={docref} className="doc-viewport">
                <Document
                    // file={`https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf`}
                    file={`${pdf}`}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onContextMenu={(e) => e.preventDefault()} // Used to prevent download of the PDF
                >
                    <Page
                        pageNumber={pageNumber + 1}
                        width={targetWidth}
                        height={targetHeight}
                        scale={scale}
                    />
                </Document>
            </div>
            <DeckToolbar
                {...{
                    scale,
                    setScale,
                    pageNumber,
                    setPageNumber,
                    numPages,
                }}
            />
        </Div>
    );
}
