import React, { useEffect } from "react";
import _ from "lodash";
import { Grid, Divider } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFeatures } from "flagged";

import { useGetArticlesQuery } from "@api/apiV6";

import MasterLayout from "@layouts/MasterLayout.react";
import { Div } from "@components/Generics.react";
import Banner from "@components/Banner.react";
import { useGetBannersQuery } from "@api/apiV6";
import GeneralHome from "./panels/GeneralHome.react";
import { settings } from "@config/settings/app.settings";
import QuickLinks from "@components/QuickLinks.react";
import RecommendedArticles from "@components/RecommendedArticles.react";
import SearchField from "@components/SearchField.react";

export default function Home(props) {
    const { data: banners, isSuccess: bannerSuccess } = useGetBannersQuery();
    const { refetch } = useGetArticlesQuery();
    const features = useFeatures();

    useEffect(
        () => refetch(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const user = useSelector((state) => state.auth.user);
    const { t } = useTranslation("common");

    const stages = settings.gamification.stages;
    const showStage = _.keys(stages).length > 1 && !features.general.hideStage;

    const isPortrait = window.innerHeight > window.innerWidth;

    const pageContent = (
        <Div padTop={isPortrait}>
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column width={8}>
                        {bannerSuccess && <Banner ads={banners} />}
                    </Grid.Column>
                    {!isPortrait && (
                        <Grid.Column width={bannerSuccess ? 8 : 16}>
                            <Div headline altText gutter>
                                {t("home.title")}
                            </Div>
                            <Div trench>{t("home.context")}</Div>
                            {showStage && (
                                <Div
                                    charcoal
                                    relaxed
                                    uppercase
                                    center-align
                                    rounded
                                >
                                    {`${t("home.stage")} ${t(
                                        `stages.${user.stage}`
                                    )}`}
                                </Div>
                            )}
                        </Grid.Column>
                    )}
                </Grid.Row>
            </Grid>
            {isPortrait && (
                <Div padded>
                    {!settings.functionality.hasHome && <QuickLinks />}
                    <Div>
                        <Divider horizontal>
                            <SearchField />
                        </Divider>
                    </Div>
                </Div>
            )}
            <Div padTop={!isPortrait}>
                <GeneralHome />
            </Div>
        </Div>
    );

    // ========================= Render Function =================================
    return (
        <MasterLayout
            title={`Welcome to ${t("appName")}`}
            mainPanel={pageContent}
            rightPanelName={t(`components.recommended`)}
            rightPanel={<RecommendedArticles />}
            rootPage={!isPortrait || !settings.functionality.hasHome}
        />
    );
}
