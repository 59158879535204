import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Icon } from "semantic-ui-react";
import { Div } from "@components/Generics.react";

export default function DoDontList(props) {
    const { bullets, rebullets } = props.data;
    const [showDo, setShowDo] = useState(null);
    const { t } = useTranslation("common");

    // ========================= Render Function =================================
    return (
        <Div fluid fullht>
            <Div big center-aligned clickable>
                <Div
                    float-left
                    wd="50%"
                    basepad
                    success
                    onClick={() => setShowDo(true)}
                >
                    <Icon name="thumbs up" size={"small"} />
                    {t("deck.qdf.dos")}
                </Div>
                <Div
                    float-left
                    wd="50%"
                    basepad
                    failure
                    onClick={() => setShowDo(false)}
                >
                    <Icon name="thumbs down" size={"small"} />
                    {t("deck.qdf.donts")}
                </Div>
            </Div>
            {showDo === null ? (
                <Div clear superpad large>
                    {t("deck.qdf.clickguidelines")}
                </Div>
            ) : (
                <Div
                    clear
                    ht="calc(100% - 56.25px)"
                    superpad
                    big
                    success={showDo}
                    failure={!showDo}
                >
                    <ul style={{ marginLeft: "-15px" }}>
                        {_.map(showDo ? bullets : rebullets, (list, index) => {
                            return <li key={"dos-list" + index}>{list}</li>;
                        })}
                    </ul>
                </Div>
            )}
        </Div>
    );
}
