import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import {
    Input,
    Divider,
    Image,
    Button,
    Dimmer,
    Loader,
} from "semantic-ui-react";
import { useParams, NavLink, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetBrandingQuery } from "@api/apiV6";
import { useFeatures } from "flagged";
import shortid from "shortid";

import axios from "axios";
import { baseUrl } from "@api/apiV6";
import { login } from "@reducers/authSlice";

import { Div } from "@components/Generics.react";
import AuthLayout from "@layouts/AuthLayout.react";
import Carousel from "@components/Carousel.react";
import { darkStyle, devMode } from "@utilities/helpers";
import { settings } from "../../config/settings/app.settings";

export default function GuestLogin(props) {
    const { team } = useParams();
    const { data: branding } = useGetBrandingQuery(team || "Company");

    const [fullName, setFullName] = useState("");

    const features = useFeatures();
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const { t } = useTranslation("common");

    const wPageHt = `${window.innerHeight}px`;
    const isPortrait = window.innerHeight > window.innerWidth;

    const [search] = useSearchParams();
    const doAuto =
        features.tenanting.autoGuests && search && search.get("auto") != null;

    useEffect(() => {
        (async () => {
            if (doAuto) {
                await tryRegister();
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const tryRegister = async () => {
        const existingUser = window.localStorage.getItem("guestUsername");
        if (existingUser) {
            doLogin(existingUser);
        } else {
            const username = shortid.generate();
            const nameSplit = doAuto ? ["Guest", "User"] : fullName.split(" ");
            let formData = new FormData();
            formData.append("username", username);
            formData.append("password", username);
            formData.append("email", `${username}@guest.quodeck.com`);
            formData.append("first_name", nameSplit[0]);
            formData.append("last_name", nameSplit[1] || " ");
            formData.append("team", team == null ? "Company" : team);
            formData.append("active", true);

            try {
                const signupResponse = await axios({
                    method: "post",
                    url: `${baseUrl}api/v3/auth/register`,
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                });
                if (signupResponse.status === 201) {
                    window.localStorage.setItem("guestUsername", username);
                    if (features.tenanting.teamCourses) {
                        await axios({
                            method: "post",
                            url: `${baseUrl}api/v3/users/${signupResponse.data._id}/team_enrollment`,
                        });
                    }
                    doLogin(username);
                }
            } catch (error) {
                console.log(error);
                setError(error.response.data.message);
            }
        }
    };

    async function doLogin(uname) {
        let loginData = new FormData();
        loginData.append("username", uname);
        loginData.append("password", uname);
        const response = await axios({
            method: "post",
            url: `${baseUrl}api/v3/auth/sign_in`,
            data: loginData,
            headers: { "Content-Type": "multipart/form-data" },
        });
        await dispatch(
            login({
                user: response.data,
                token: response.headers["access-token"],
            })
        );
        window.localStorage.setItem("firstLogin", "done");
        if (team != null)
            window.localStorage.setItem("team", response.data.team);
        setTimeout(() => {
            let redirectPath = localStorage.getItem("redirectPath");
            if (redirectPath) {
                redirectPath =
                    redirectPath !== "/" && redirectPath !== "/logout"
                        ? `${
                              devMode ? "" : settings.targetRoute.slice(0, -1)
                          }${redirectPath}`
                        : "/";
            }
            const redirectUrl = redirectPath || "/";
            localStorage.removeItem("redirectPath");
            window.location.href = redirectUrl;
        }, 1000);
    }

    const registrationBox = (
        <Div fluid superpad ht={wPageHt} flex centered maxWd="480px">
            <Div fluid white fullHt superpad rounded>
                <Div massive altText fluid center-align gutter>
                    {t("auth.guest")}
                </Div>
                {error && (
                    <Div padded fluid danger gutter>
                        {error}
                    </Div>
                )}
                <Div fluid gutter>
                    <Input
                        fluid
                        label={t("auth.registration.nameLabel")}
                        placeholder={t("auth.registration.namePlaceholder")}
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                    />
                </Div>
                <Div fluid gutter>
                    <Button
                        fluid
                        primary
                        content="SUBMIT"
                        onClick={tryRegister}
                        disabled={fullName.length < 3}
                    />
                    <Divider horizontal>OR</Divider>
                    <Div center-align>
                        <NavLink
                            to={team == null ? "/login" : `/${team}/login`}
                        >
                            <Button success={+true} content={t("auth.login")} />
                        </NavLink>
                    </Div>
                </Div>
            </Div>
        </Div>
    );

    const hasLoggedIn = window.localStorage.getItem("firstLogin") != null;

    // ========================= Render Function =================================
    if (doAuto) {
        return (
            <Dimmer active>
                <Loader>Welcome...</Loader>
            </Dimmer>
        );
    } else if (
        team == null ||
        branding == null ||
        branding.cards == null ||
        branding.cards?.length === 0
    ) {
        return (
            <AuthLayout
                title={`Register on ${t("appName")}`}
                team={team}
                teamLogin={true}
            >
                <Div
                    fluid
                    flex
                    ht={wPageHt}
                    maxWd={isPortrait ? "480px" : "100%"}
                    spaceAround
                >
                    <Div wd="40%" megapad>
                        <Image
                            fluid
                            src={
                                "/assets/images/configurable/full-logo-inverted.png"
                            }
                        />
                    </Div>
                    <Div wd="480px">{registrationBox}</Div>
                </Div>
            </AuthLayout>
        );
    } else {
        return (
            <AuthLayout
                title={`Register on ${t("appName")}`}
                team={team}
                teamLogin={true}
            >
                <Carousel
                    lazyLoad
                    slidesToShow={1}
                    dots
                    initialSlide={hasLoggedIn ? branding.cards?.length : 0}
                >
                    <Div
                        superpad
                        fluid
                        flex
                        ht={wPageHt}
                        maxWd="480px"
                        centered
                    >
                        <Image src={branding?.image} />
                    </Div>
                    {_.map(branding?.cards, (page, idx) => {
                        return (
                            <Div
                                fluid
                                superpad
                                large
                                ht={wPageHt}
                                maxWd="480px"
                                centered
                                key={`wpage-${idx}`}
                                style={{ color: branding.textColor }}
                            >
                                <Div rounded noOverflow gutter>
                                    <Image fluid src={page.image} />
                                </Div>
                                <Div
                                    big
                                    bold
                                    gutter
                                    gapTop
                                    style={darkStyle(
                                        branding.mainColor,
                                        "to top right"
                                    )}
                                    medpad
                                    rounded
                                >
                                    {page.header}
                                </Div>
                                <Div trench>{page.content}</Div>
                            </Div>
                        );
                    })}
                    {registrationBox}
                </Carousel>
            </AuthLayout>
        );
    }
}
