import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Icon } from "semantic-ui-react";
import { Div } from "@components/Generics.react";
import "@styles/components.scss";

export default function InlineInput(props) {
    const { text, handleSave, showHelp = true, showIcon = true } = props;
    const { t } = useTranslation("common");
    const [isEditing, setIsEditing] = useState(false);
    const [editedText, setEditedText] = useState(text);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleInputChange = (e) => {
        setEditedText(e.target.value);
    };

    const handleInputBlur = () => {
        setIsEditing(false);
        // Call handleSave when the input loses focus
        handleSave(editedText);
    };

    const handleInputKeyPress = (e) => {
        if (e.key === "Enter") {
            setIsEditing(false);
            // Call handleSave when Enter key is pressed
            handleSave(editedText);
        }
    };

    return (
        <Div fluid>
            {isEditing ? (
                <Div>
                    <Input
                        autoFocus
                        fluid
                        value={editedText}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        onKeyPress={handleInputKeyPress}
                    />
                    {showHelp && (
                        <Div italics light gapTop gutter>
                            {t("components.pressEnter")}
                        </Div>
                    )}
                </Div>
            ) : (
                <Div flex flexStart onClick={handleEditClick}>
                    {showIcon && (
                        <Div wd="30px" large clickable>
                            <Icon
                                name="pencil square"
                                className="inline-edit-icon"
                            />
                        </Div>
                    )}
                    <Div
                        wd={showIcon ? "calc(100% - 30px)" : "100%"}
                        leftBordered
                        padded
                    >
                        {text}
                    </Div>
                </Div>
            )}
        </Div>
    );
}
