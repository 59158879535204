// Import from NPM
// -------------------------------------
import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
// Import from Config
// -------------------------------------
import { LayoutContext } from "@layouts/MasterLayout.react";
import { AbsWrapper, Container, Div, Image } from "@components/Generics.react";
import { MapList } from "@schemas/programMapSchema";
import "@styles/courseLayout.scss";

// Import Components
// -------------------------------------
import MapMarker from "@components/MapMarker.react";
import { ScrollDrag } from "@components/ScrollDrag.react";

export default function GamifiedProgram(props) {
    const { locData, program } = props;
    const { height } = useContext(LayoutContext);
    const [zoomLevel, setZoomLevel] = useState(90);
    const [zoomFinish, setZoomFinish] = useState(false);
    const [activeMarker, setActiveMarker] = useState(0);
    const navigate = useNavigate();

    const isPortrait = window.innerHeight > window.innerWidth;

    //---------------------------------------------------------------------------
    // Handle scrolling to active location
    //---------------------------------------------------------------------------
    useEffect(() => {
        let scroller = document.getElementById("scroller");
        let activeLocation = document.getElementById("location-active");
        let timer;
        if (scroller && zoomLevel < 100 && !zoomFinish && isPortrait) {
            timer = setTimeout(() => {
                if (activeLocation) {
                    scroller.scrollLeft =
                        activeLocation.offsetLeft - window.innerWidth / 2;
                    scroller.scrollTop =
                        activeLocation.offsetTop - scroller.offsetHeight / 2;
                }
                setZoomLevel(zoomLevel + 2);
            }, 10);
        } else if (!zoomFinish) {
            setZoomFinish(true);
        }
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zoomLevel, zoomFinish]);

    useEffect(() => {
        let scroller = document.getElementById("scroller");
        let activeLocation = document.getElementById("location-active");
        if (activeLocation) {
            scroller.scrollLeft =
                activeLocation.offsetLeft - window.innerWidth / 2;
            scroller.scrollTop =
                activeLocation.offsetTop - scroller.offsetHeight / 2;
        }
    }, [activeMarker]);

    let zoomFactor =
        window.location === window.parent.location // If this is not in an iFrame
            ? zoomLevel / 100
            : 1;
    let gameDimensions = {
        width: isPortrait ? 1.5 * height * zoomFactor : "100%",
        height: isPortrait ? height * zoomFactor : "0",
        paddingBottom: isPortrait ? "0" : "66.67%",
    };

    //---------------------------------------------------------------------------
    // Handle content overlays
    //---------------------------------------------------------------------------
    const gifOverlay = _.map(MapList[program.wrapper]?.gifMap, (img, index) => {
        return (
            <img
                key={"aGif-" + index}
                src={"/assets/images/giflib/" + img.name + ".gif"}
                style={{
                    position: "absolute",
                    top: img.top + "%",
                    left: img.left + "%",
                    width: img.width + "%",
                    opacity: img.opacity || 1,
                    transform: img.rotate && `rotate3d(1,1,1,${img.rotate}deg)`,
                }}
                alt="Visual embellishment"
            />
        );
    });
    let indexCounter = 0;
    const nodeList = _.orderBy(program.node_list, ["priority"], ["desc"]);
    const locationOverlay = locData.map((thisLoc, locIndex) => {
        if (thisLoc != null) {
            indexCounter++;
            const locCoords = MapList[program.wrapper].positions[locIndex]; // Get the location position details from WrapperSchema
            if (thisLoc.status === "current" && locIndex !== activeMarker)
                setActiveMarker(locIndex);
            return (
                <MapMarker
                    id={thisLoc.status === "current" ? "location-active" : ""}
                    key={"location-" + locIndex}
                    counter={indexCounter}
                    name={nodeList[locIndex].name}
                    locIndex={locIndex}
                    programColors={program.colors}
                    wrapper={program.wrapper}
                    locCoords={locCoords}
                    content={_.omit(thisLoc, "status")}
                    status={thisLoc.status}
                    clickable={
                        !program.sequential || thisLoc.status !== "incomplete"
                    }
                    sequential={program.sequential}
                    handleClick={() =>
                        navigate(`/courses/${nodeList[locIndex]._id}`)
                    }
                />
            );
        } else {
            return <Div key={"location-" + locIndex} className="no-loc" />;
        }
    });

    // ========================= Render Function =================================
    return (
        <Div fluid fullht padded padTop clear>
            <Container noOverflow id="viewport">
                <ScrollDrag rootClass="scroller" rootId="scroller">
                    <AbsWrapper noOverflow rounded style={gameDimensions}>
                        <Image
                            src={
                                "/assets/images/programs/" +
                                program.wrapper +
                                ".jpg"
                            }
                            className="wrapper-image"
                        />
                        <AbsWrapper>
                            {locationOverlay}
                            {gifOverlay}
                        </AbsWrapper>
                    </AbsWrapper>
                </ScrollDrag>
            </Container>
        </Div>
    );
}
