import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import _ from "lodash";

import "@styles/readers.scss";
import { Div, HDiv, Button } from "@components/Generics.react";
import Accordion from "@components/Accordion.react";
import { Image } from "semantic-ui-react";

export default function ProductFAQ(props) {
    const { deck, recordAttempt } = props;
    const imageRef = useRef(null);
    const isPortrait = window.innerHeight > window.innerWidth;

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let panels = _.map(deck?.content?.questions, (item) => {
        return {
            title: <Div small>{item?.title}</Div>,
            content: (
                <Div small>
                    <HDiv content={item?.description} />
                    {item?.button?.btnLink && (
                        <Div padTop>
                            {_.includes(item?.button?.btnLink, "http") ? (
                                <a
                                    href={item?.button?.btnLink}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Button
                                        primary
                                        fluid
                                        content={item?.button.text}
                                    />
                                </a>
                            ) : (
                                <NavLink to={item?.button?.btnLink}>
                                    <Button
                                        primary
                                        fluid
                                        content={item?.button.text}
                                    />
                                </NavLink>
                            )}
                        </Div>
                    )}
                </Div>
            ),
        };
    });

    return (
        <Div
            fluid
            flex
            maxWd="480px"
            fullht
            priText
            autoOverflowY
            centered
            padTop={!isPortrait}
            className="product-faq-reader"
        >
            <Div relative topRounded noOverflow>
                <div ref={imageRef}>
                    <Image fluid src={deck?.content?.image} />
                    <Div
                        midnight
                        centered
                        center-align
                        bold
                        topRounded
                        className="product-name"
                        uppercase
                    >
                        {deck?.content?.name}
                    </Div>
                </div>
            </Div>
            <Div
                fluid
                ht={
                    imageRef.current
                        ? `calc(100% - ${imageRef.current?.clientHeight}px)`
                        : "calc(100% - 140px)"
                }
                relative
                layer={2}
            >
                <Accordion panels={panels}></Accordion>
            </Div>
        </Div>
    );
}
