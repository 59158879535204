import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Div, HDiv, Button } from "@components/Generics.react";
import AuthLayout from "@layouts/AuthLayout.react";

export default function Registered(props) {
    const { t } = useTranslation("common");

    // ========================= Render Function =================================
    return (
        <AuthLayout title={"Registration complete"}>
            <Div fluid white fullHt>
                <Div huge altText fluid center-align gutter>
                    {t("auth.registration.registeredHeader")}
                </Div>
                <HDiv
                    big
                    trench
                    content={t("auth.registration.registeredInfo")}
                />
                <Div center-align>
                    <NavLink to="/login">
                        <Button success={+true} content={t("auth.login")} />
                    </NavLink>
                </Div>
            </Div>
        </AuthLayout>
    );
}
