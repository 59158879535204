// Import from NPM
// -------------------------------------
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import _ from "lodash";
import "@styles/icomoon.css";
import "@styles/editor.scss";

import { Div } from "@components/Generics.react";
import { Input } from "semantic-ui-react";
import { iconList } from "@schemas/iconSchema";

export default function IconSelectField(props) {
    const { target, header, help, value, setValue, height } = props;
    const [searchText, setSearchText] = useState("");
    const { t } = useTranslation("common");

    function getSelectedIcons(searchText) {
        return _.filter(iconList.iconSets[0].selection, (elm) => {
            return _.includes(elm.ligatures, searchText);
        });
    }

    const icons = _.map(getSelectedIcons(searchText), (elm) => {
        return { key: elm.name, value: elm.name, text: elm.name };
    });

    const iconGrid = () => {
        if (icons.length < 1) {
            return (
                <Div basepad white fluid>
                    {t("components.noResults")}
                </Div>
            );
        } else {
            return _.map(icons, (elm, idx) => {
                return (
                    <Div
                        large
                        clickable
                        float-left
                        bordered
                        key={`icon-box-${idx}`}
                        className="select-icon"
                        title={elm.value}
                        onClick={() =>
                            setValue(
                                target
                                    ? { [target]: `icon-${elm.value}` }
                                    : `icon-${elm.value}`
                            )
                        }
                    >
                        <i className={"icon icm icon-" + elm.value} />
                    </Div>
                );
            });
        }
    };

    // ========================= Render Function =================================
    return (
        <Div minHt={height} ht={height} ivory basepad snug>
            <Div big rimmed>
                <Div small float-right>
                    <Input
                        placeholder={t("components.search")}
                        icon={{ name: "search" }}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </Div>
                <Div
                    large
                    float-left
                    smoke
                    slightShadow
                    className="selected-icon"
                >
                    <i
                        className={`icon icm ${
                            target && value ? value[target] || "" : value
                        }`}
                    />
                </Div>
                <Div padSides float-left>
                    {header}
                    {help && (
                        <Div tiny italics txtHalf gapSlice>
                            {help}
                        </Div>
                    )}
                </Div>
            </Div>
            <Div
                smoke
                ht={"calc(100% - 60px)"}
                fluid
                maxHt="128px"
                autoOverflowY
            >
                {iconGrid()}
            </Div>
        </Div>
    );
}
