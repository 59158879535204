import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useGetQuestionResponseQuery } from "@api/apiV6";
import _ from "lodash";
import { toast } from "react-toastify";

import "@styles/readers.scss";
import { Div, HDiv, Button } from "@components/Generics.react";
import { Input } from "semantic-ui-react";
import { trackQuestion } from "@reducers/trackerSlice";
import CheckListForm from "./panels/CheckListForm.react";

export default function CheckListReader(props) {
    const { deck, recordAttempt } = props;
    const userId = useSelector((state) => state.auth.user._id);
    const dispatch = useDispatch();
    const { t } = useTranslation("common");

    const [showForm, setShowForm] = useState(false);
    const [seq, setSeq] = useState(null);  // Initialize to null to distinguish between new and edit
    const [searchText, setSearchText] = useState("");

    const { data: qResponses, isSuccess } = useGetQuestionResponseQuery({
        deckId: deck._id,
        userId: userId,
    });
    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Removed recordAttempt from dependencies

    const trackInteraction = useCallback((response) => {
        dispatch(
            trackQuestion({
                deckId: deck.id,
                slideId: seq,
                userId: userId,
                response: response,
                pushable: true,
            })
        );
        toast(t("deck.checklist.saved"));
    }, [dispatch, deck.id, seq, userId, t]);

    const handleNewButtonClick = () => {
        setSeq(qResponses ? qResponses.length : 0);  // Only set seq to the next index if creating a new one
        setShowForm(true);
    };

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    const renderResponses = () => {
        if (isSuccess && qResponses && qResponses.length > 0) {
            return (
                <Div gapTop>
                    <Input
                        fluid
                        placeholder={t("deck.checklist.search")}
                        icon={{ name: "search" }}
                        value={searchText}
                        onChange={handleSearchChange}
                    />
                    <Div white>
                        {qResponses.map((qResponse, idx) => {
                            const lastResponse = _.last(qResponse.responses)?.r || "";
                            if (_.includes(lastResponse, searchText)) {
                                const [firstPart, secondPart] = lastResponse.split("||");
                                return (
                                    <Div
                                        rimmed
                                        flex
                                        key={`qresponse-${idx}`}
                                        spaceBetween
                                        clickable
                                        vapor={idx % 2 === 0}
                                        onClick={() => {
                                            setSeq(idx);  // Set seq to the index of the selected response
                                            setShowForm(true);
                                        }}
                                    >
                                        <Div>{firstPart}</Div>
                                        <Div>{secondPart}</Div>
                                    </Div>
                                );
                            }
                            return null;
                        })}
                    </Div>
                </Div>
            );
        }
        return null;
    };

    return (
        <Div fullht fluid centered relative className="checklist-reader">
            {showForm ? (
                <CheckListForm
                    deck={deck}
                    seq={seq}
                    qResponse={seq !== null && qResponses ? qResponses[seq] : undefined}  // Pass the correct response or undefined
                    saveData={trackInteraction}
                    setShowForm={setShowForm}
                />
            ) : (
                <Div medpad ash>
                    <HDiv gutter content={deck.content?.instructions} />
                    <Button
                        fluid
                        primary
                        content={t("deck.checklist.new")}
                        onClick={handleNewButtonClick}
                    />
                    {renderResponses()}
                </Div>
            )}
        </Div>
    );
}