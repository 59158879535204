import React from "react";
import { useTranslation } from "react-i18next";

import MasterLayout from "@layouts/MasterLayout.react";
import { Div, Image } from "@components/Generics.react";
import RecommendedArticles from "@components/RecommendedArticles.react";

export default function ComingSoon(props) {
    const { t } = useTranslation("common");

    const pageContent = (
        <Div superpad white rounded centered center-align>
            <Image rounded trench src="/assets/images/coming_soon.png" />
            <Div huge bold>
                {t("components.comingsoon.header")}
            </Div>
            <hr />
            <Div large trench>
                {t("components.comingsoon.blurb")}
            </Div>
        </Div>
    );

    // ========================= Render Function =================================
    return (
        <MasterLayout
            title={`Coming Soon`}
            mainPanel={pageContent}
            rightPanelName={t(`components.recommended`)}
            rightPanel={<RecommendedArticles />}
            rootPage={false}
        />
    );
}
