// Import from NPM
// -------------------------------------
import React from "react";
import { useTranslation } from "react-i18next";

import { Div } from "@components/Generics.react";
import "@styles/editor.scss";

import PaletteSelector from "@components/ribbon/PaletteSelector.react";
import ColorSelector from "@components/ribbon/ColorSelector.react";
import BackgroundSelector from "@components/ribbon/BackgroundSelector.react";

export default function DesignMenu(props) {
    const { deck, updateDeck, config } = props;
    const { t } = useTranslation("common");

    // ========================= Render Function =================================
    return (
        <Div flex flexStart>
            {config.includes("palette") && (
                <PaletteSelector
                    name={t("builder.ribbon.colorPalette")}
                    deck={deck}
                    setDeck={updateDeck}
                    leftBordered={false}
                />
            )}
            {config.includes("colors") && (
                <ColorSelector
                    name={t("builder.ribbon.selectColor")}
                    deck={deck}
                    setDeck={updateDeck}
                />
            )}
            {config.includes("background") && (
                <BackgroundSelector
                    name={t("builder.ribbon.background")}
                    deck={deck}
                    setDeck={updateDeck}
                />
            )}
        </Div>
    );
}
