// Import from NPM
// -------------------------------------
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Div, Button, Icon } from "@components/Generics.react";
import { Divider, Input } from "semantic-ui-react";
import ReactTimeAgo from "timeago-react";

export default function DeckNotes(props) {
    const { deck, setDeck } = props;
    const user = useSelector((state) => state.auth.user);
    const [comment, setComment] = useState("");
    const { t } = useTranslation("common");

    function addComment() {
        setDeck({
            ...deck,
            ...{
                comments: _.concat(deck.comments ? deck.comments : [], [
                    [
                        comment,
                        `${user.first_name} ${user.last_name}`,
                        new Date(),
                    ],
                ]),
            },
        });
    }
    function deleteComment(idx) {
        const updatedComments = [...deck.comments];
        updatedComments.splice(idx, 1);
        setDeck({
            ...deck,
            ...{
                comments: updatedComments,
            },
        });
    }

    // ========================= Render Function =================================
    return (
        <Div
            minWd="400px"
            wd="400px"
            fullht
            relative
            peat
            basepad
            ht={`calc(100vh - 225px)`}
            darkInset
        >
            <Div white fullht rounded basepad slightShadow>
                <Div bold large compact uppercase>
                    {t("builder.deck.notes")}:
                </Div>
                <Div italics gutter compact>
                    {t("builder.deck.notesHelp")}
                </Div>
                <Div rounded>
                    <Div slightShadow>
                        <Input
                            fluid
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </Div>
                    <br />
                    <Button
                        primary
                        content={t("components.submit")}
                        onClick={addComment}
                    />
                </Div>
                <Divider />
                <Div medpad ash rounded ht={`calc(100% - 200px)`} autoOverflowY>
                    {deck.comments.length > 0 ? (
                        _.map(deck.comments, (comment, index) => {
                            return (
                                <Div
                                    key={`deck-comment-${index}`}
                                    white
                                    basepad
                                    spaced
                                >
                                    <Div bold>
                                        {comment[1]}
                                        <Div float-right txtDanger clickable>
                                            <Icon
                                                name="trash"
                                                onClick={() =>
                                                    deleteComment(index)
                                                }
                                            />
                                        </Div>
                                    </Div>
                                    <Div italics>
                                        <ReactTimeAgo datetime={comment[2]} />
                                    </Div>
                                    <Div gapTop>{comment[0]}</Div>
                                </Div>
                            );
                        })
                    ) : (
                        <Div>{t("builder.deck.noNotes")}</Div>
                    )}
                </Div>
            </Div>
        </Div>
    );
}
