import React, { useEffect, useState } from "react";
import _ from "lodash";
import "@styles/readers.scss";

import { Div, Icon } from "@components/Generics.react";

export default function GuidelinesReader(props) {
    const { deck, recordAttempt } = props;
    const [showDo, setShowDo] = useState(true);

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let doListData = _.map(deck?.content.dos, (list, index) => {
        return <li key={"do-list" + index}>{list}</li>;
    });

    let dontListData = _.map(deck?.content.donts, (list, index) => {
        return <li key={"dont-list" + index}>{list}</li>;
    });
    // ========================= Render Function =================================
    return (
        <Div rounded fluid fullht>
            <Div
                className="guidelines"
                rounded
                fluid
                fullht
                relative
                noOverflow
                style={{ background: showDo ? "#C1DC9E" : "#f0b7b7" }}
            >
                <Div superpad>
                    <Div massive bold gutter altText>
                        {showDo ? "DO" : "DON'T"}
                    </Div>
                    <Div>{showDo ? doListData : dontListData}</Div>
                </Div>
                <Div ht="100px" />
                <Div
                    className={`${showDo ? "dont" : "do"}-triangle`}
                    flex
                    clickable
                    onClick={() => setShowDo(!showDo)}
                >
                    <Div medpad center-align bold>
                        <Icon large name={`thumbs ${showDo ? "down" : "up"}`} />
                        <Div small>{!showDo ? "DO" : "DON'T"}</Div>
                    </Div>
                </Div>
            </Div>
        </Div>
    );
}
