import React, { useState, useEffect } from "react";
import { useFeatures } from "flagged";
import _ from "lodash";
import {
    Input,
    Modal,
    Segment,
    Tab,
    Form,
    List,
    Label,
    Divider,
    TextArea,
} from "semantic-ui-react";
import { Div, Button, Image } from "@components/Generics.react";
import { settings } from "@config/settings/app.settings";

const PostCreator = ({ onSubmit }) => {
    const features = useFeatures();

    const [postType, setPostType] = useState("text");
    const [postContent, setPostContent] = useState("");
    const [postLink, setPostLink] = useState("");
    const [postImages, setPostImages] = useState([]);
    const [postVideo, setPostVideo] = useState("");
    const [category, setCategory] = useState("text");
    const [ownerId] = useState("");
    const [image, setImage] = useState(null);
    const [rulesResult, setRulesResult] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);
    const [searchObject, setSearchObject] = useState({});
    const [objData] = useState(settings?.detailParams || {});
    const [searchStructureKeys, setSearchStructureKeys] = useState([]);

    useEffect(() => {
        setSearchStructureKeys(_.keys(objData));
    }, [objData]);

    const toProper = (str) => {
        // word.trim().charAt(0).toUpperCase() + word.trim().slice(1).toLowerCase()
        return str ? str.replace("_", " ") : "";
    };

    const handleSubmited = () => {
        let updatedRulesResult = [...rulesResult];
        updatedRulesResult.push(searchObject);
        setRulesResult(updatedRulesResult);
        setModalOpen(false);
        setSearchObject({});
    };

    const handleSearchObjectChange = (e) => {
        const { name, value } = e.target;
        setSearchObject((prevSearchObject) => ({
            ...prevSearchObject,
            [name]: value,
        }));
    };

    const handleTabChange = (e, { activeIndex }) => {
        const postTypes = ["text", "link", "image", "video", "reels"];
        setPostType(postTypes[activeIndex]);
        setPostContent("");
        setPostLink("");
        setPostImages([]);
        setPostVideo("");

        switch (postTypes[activeIndex]) {
            case "text":
                setCategory("text");
                break;
            case "reels":
                setCategory("reels");
                break;
            case "link":
                setCategory("link");
                break;
            case "image":
                setCategory("image");
                break;
            case "video":
                setCategory("video");
                break;
            default:
                setCategory("text");
        }
    };
    const handleTextChange = (e) => {
        setPostContent(e.target.value);
    };

    const handleLinkChange = (e) => {
        setPostLink(e.target.value);
    };

    const handleUrlChange = (e) => {
        setPostLink(e.target.value);
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const base64Url = new FileReader();
        base64Url.onloadend = () => {
            setImage(base64Url.result);
        };
        base64Url.readAsDataURL(file);
    };

    const handleSubmit = () => {
        let content = postLink;
        if (category === "image") {
            content = [
                {
                    image: image,
                    _destroy: false,
                },
            ];
        }

        const postData = {
            category: category,
            content: content,
            description: postContent,
            owner_id: ownerId,
            postType,
            postContent,
            postLink,
            postImages,
            postVideo,
        };

        onSubmit(postData);

        setPostType("text");
        setPostContent("");
        setPostLink("");
        setPostImages([]);
        setPostVideo("");
        setImage(null);
    };

    // Helper function to determine if the current post type has empty attributes
    const isPostAttributesEmpty = () => {
        switch (postType) {
            case "text":
                return !postContent;
            case "reels":
                return !postContent;
            case "link":
                return !postLink || !postContent;
            case "image":
                return !image || !postContent;
            case "video":
                return !postLink || !postContent;
            default:
                return false;
        }
    };

    const submitter = (
        <Div>
            <Button
                disabled={isPostAttributesEmpty()}
                content="Post"
                primary
                onClick={handleSubmit}
            />
            {features.social.cohorted && (
                <Button primary content="Add Groups" onClick={openModal} />
            )}
        </Div>
    );

    const panes = [
        {
            menuItem: {
                key: "text",
                icon: "file text",
                content: "",
            },
            render: () => (
                <Tab.Pane>
                    <h3>Create a Text Post</h3>
                    <Segment>
                        <h5>Add your post</h5>
                        <TextArea
                            placeholder="Please type in your post here"
                            value={postContent}
                            onChange={handleTextChange}
                            rows={10}
                            style={{ width: "100%", height: "100px" }}
                        />
                    </Segment>
                    {submitter}
                </Tab.Pane>
            ),
        },
        {
            menuItem: {
                key: "link",
                icon: "linkify",
                content: "",
            },
            render: () => (
                <Tab.Pane>
                    <h3>Create a Link Post</h3>
                    <Segment>
                        <h5>Add a link address</h5>
                        <Input
                            fluid
                            placeholder="Type in your Link here"
                            value={postLink}
                            onChange={handleLinkChange}
                        />
                        <h5>Add a note about the link</h5>
                        <TextArea
                            placeholder="Please write something about the link target here"
                            value={postContent}
                            onChange={handleTextChange}
                            rows={10}
                            style={{ width: "100%", height: "100px" }}
                        />
                    </Segment>
                    {submitter}
                </Tab.Pane>
            ),
        },
        {
            menuItem: {
                key: "image",
                icon: "image",
                content: "",
            },
            render: () => (
                <Tab.Pane>
                    <h3>Create a Image Post</h3>
                    <Segment>
                        <h5>Add an image</h5>
                        <div>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageUpload}
                            />
                            {image && (
                                <Image
                                    className="social-image-post"
                                    src={image}
                                    alt="Uploaded Image"
                                />
                            )}
                        </div>
                        <h5>Add a note about the Image</h5>
                        <TextArea
                            placeholder="Please write something about the image here"
                            value={postContent}
                            onChange={handleTextChange}
                            style={{ width: "100%", height: "100px" }}
                        />
                    </Segment>
                    {submitter}
                </Tab.Pane>
            ),
        },
        {
            menuItem: {
                key: "video",
                icon: "video",
                content: "",
            },
            render: () => (
                <Tab.Pane>
                    <h3>Create a Video Post</h3>
                    <Segment>
                        <h4>Upload a Youtube, Vimeo or Hosted Video Link</h4>
                        <h5>
                            The easiest way to add streaming videos is to just
                            add a YouTube, Vimeo or Hosted Video link. Just copy
                            and paste the url of the video you want.
                        </h5>
                        <Input
                            fluid
                            placeholder="Type in the URL of the video here"
                            value={postLink}
                            onChange={handleUrlChange}
                        />
                        <Div padTop>
                            <Divider horizontal>AND</Divider>
                        </Div>
                        <h5>Add a note about the video</h5>
                        <TextArea
                            placeholder="Please write something about the video here"
                            value={postContent}
                            onChange={handleTextChange}
                            rows={10}
                            style={{ width: "100%", height: "100px" }}
                        />
                    </Segment>
                    {submitter}
                </Tab.Pane>
            ),
        },
    ];
    return (
        <Div>
            <Div white rounded>
                <Tab
                    className="social-uploader-container-tab"
                    menu={{ secondary: true, pointing: true }}
                    panes={panes}
                    activeIndex={panes.findIndex(
                        (pane) => pane.menuItem.key === postType
                    )}
                    onTabChange={handleTabChange}
                />
            </Div>
            <Modal open={modalOpen} onClose={closeModal} closeIcon size="large">
                <h3 style={{ paddingLeft: "2%" }}>Post to Groups</h3>
                <Form onSubmit={handleSubmited}>
                    <Modal.Content>
                        <Segment basic style={{ background: "#f5f5f5" }}>
                            <Segment raised>
                                <h3>Define groups for the post</h3>
                                <List>
                                    {searchStructureKeys.map((key) => (
                                        <List.Item key={key}>
                                            <Form.Field inline>
                                                <Input
                                                    fluid
                                                    name={key}
                                                    label={toProper(
                                                        key
                                                    ).replace("_", " ")}
                                                    defaultValue={
                                                        searchObject[key] || ""
                                                    }
                                                    onChange={
                                                        handleSearchObjectChange
                                                    }
                                                    placeholder="Put in Search Value"
                                                />
                                            </Form.Field>
                                        </List.Item>
                                    ))}
                                </List>
                            </Segment>
                        </Segment>
                    </Modal.Content>
                    <Button
                        disabled={
                            _.filter(
                                _.keys(searchObject),
                                (k) => searchObject[k] !== undefined
                            ).length === 0
                        }
                        primary
                        fluid
                        size="huge"
                        content={"Submit"}
                    />
                </Form>
            </Modal>
            {rulesResult.length !== 0 && (
                <div>
                    <h3> Post Groups List:</h3>
                    {_.map(rulesResult, (rule, idx) => {
                        return (
                            <List.Item
                                key={`post-rule-${idx}`}
                                style={{
                                    background: "#cccccc",
                                    padding: "3px",
                                    marginBottom: "3px",
                                }}
                            >
                                {_.map(_.keys(rule), (key, idy) => {
                                    return (
                                        <Label key={`post-grp-elm-${idy}`}>
                                            {rule[key]}
                                        </Label>
                                    );
                                })}
                            </List.Item>
                        );
                    })}
                </div>
            )}
        </Div>
    );
};

export default PostCreator;
