import React, { useEffect } from "react";

import "@styles/readers.scss";

import { Div, HDiv } from "@components/Generics.react";

export default function HTMLReader(props) {
    const { deck, recordAttempt } = props;
    //-------------------------------------------   --------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // ========================= Render Function =================================
    return (
        <Div fullht centered compact noOverflow relative>
            <HDiv padTop className="html-reader" content={deck.content} />
        </Div>
    );
}
