// Import from NPM
// -------------------------------------
import React from "react";
import ColorBlock from "@components/ColorBlock.react";

import { Div } from "@components/Generics.react";

export default function ColorField(props) {
    const { target, header, value, setValue, height } = props;
    // ========================= Render Function =================================
    return (
        <Div big minHt={height} ht={height} ivory basepad snug>
            <ColorBlock
                color={target ? value[target] || "" : value}
                colorName={header}
                onChange={(name, color) =>
                    setValue(target ? { [target]: color.hex } : color.hex)
                }
            />
        </Div>
    );
}
