// Import from NPM
// -------------------------------------
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import axios from "axios";
import { baseUrl, useGetCreditsQuery } from "@api/apiV6";

import _ from "lodash";
import { Div, Button } from "@components/Generics.react";
import {
    Checkbox,
    Divider,
    Dropdown,
    Input,
    Dimmer,
    Loader,
} from "semantic-ui-react";
import { settings } from "@config/settings/app.settings";

export default function AIQTopic(props) {
    const { deck, setDeck, setRightPanel } = props;
    const token = useSelector((state) => state.auth.token);
    const { data: aiStatus, isSuccess, refetch } = useGetCreditsQuery();
    const { t } = useTranslation("common");

    const [source, setSource] = useState("");
    const [qCount, setQCount] = useState(5);

    const [fetching, setFetching] = useState(false);
    const [langValue, setLangValue] = useState("English");
    const [mode, setMode] = useState(true);

    const creditsNeeded = Math.ceil(qCount / 2);
    async function getAIResponse() {
        setFetching(true);
        const response = await axios({
            method: "post",
            url: `${baseUrl}api/v3/ai/genQuestions`,
            data: {
                source: source,
                qcount: qCount,
                lang: langValue,
                cost: creditsNeeded,
                basis: "topic",
            },
            headers: { "access-token": token },
        });
        const output = formatOutput(
            JSON.parse(response.data.response.message.content)
        );
        if (mode)
            setDeck({
                ...deck,
                ...{ content: output },
            });
        else
            setDeck({
                ...deck,
                ...{ content: _.concat(deck.content, output) },
            });
        setFetching(false);
        refetch();
        setRightPanel("mobile");
    }

    function formatOutput(data) {
        return _.map(data.questions, (row, rowNo) => {
            const templateKey =
                row.type === "true_or_false"
                    ? "true_false"
                    : row.type === "multiple_select"
                    ? "multiple_select"
                    : row.type === "multiple_choice"
                    ? "single_select"
                    : "fill_in_the_blanks";
            const headerKey =
                row.type === "true_or_false"
                    ? "State true or false"
                    : row.type === "multiple_select"
                    ? "Choose all the correct options"
                    : row.type === "multiple_choice"
                    ? "Choose the correct option"
                    : "Fill in the blank";
            const optKey =
                row.type === "true_or_false"
                    ? "choice"
                    : row.type === "multiple_select"
                    ? "multiselect"
                    : row.type === "multiple_choice"
                    ? "options"
                    : null;
            const answerKey =
                row.type === "multiple_select"
                    ? row.answer.join(", ")
                    : row.answer;
            return {
                template: templateKey,
                slideSeq: mode ? rowNo : rowNo + deck.content.length,
                nav: {
                    next: true,
                    prev: false,
                },
                data: {
                    title: headerKey,
                    question: row.question,
                    feedback: [
                        `The correct answer is ${answerKey}`,
                        `The correct answer is ${answerKey}`,
                    ],
                    purpose: "Test Understanding",
                    [optKey]:
                        row.options == null
                            ? row.type === "fill_in_the_blank"
                                ? undefined
                                : [
                                      {
                                          text: "True",
                                          correct:
                                              row.answer === true ||
                                              row.answer.toLowerCase() ===
                                                  "true"
                                                  ? "checked"
                                                  : "",
                                      },
                                      {
                                          text: "False",
                                          correct:
                                              row.answer === false ||
                                              row.answer.toLowerCase() ===
                                                  "false"
                                                  ? "checked"
                                                  : "",
                                      },
                                  ]
                            : _.map(row.options, (opt) => ({
                                  text: opt,
                                  correct:
                                      row.type === "multiple_select"
                                          ? row.answer.indexOf(opt) !== -1
                                              ? "checked"
                                              : ""
                                          : opt === row.answer
                                          ? "checked"
                                          : "",
                              })),
                    answer: row.answer,
                },
            };
        });
    }

    // ========================= Render Function =================================
    return (
        <Div
            minWd="400px"
            wd="400px"
            fullht
            relative
            peat
            basepad
            ht={`calc(100vh - 225px)`}
            darkInset
        >
            <Div white fullht rounded basepad slightShadow>
                <Div bold large compact uppercase>
                    {t("builder.deck.aiqgen")}:
                </Div>
                <Div italics gutter compact>
                    {t("builder.deck.aiqtopicHelp")}
                </Div>
                {isSuccess && (
                    <Div rounded>
                        <Div padBottom>
                            <Checkbox
                                toggle
                                label={
                                    mode
                                        ? "Replace the Quiz with"
                                        : "Add to the Quiz"
                                }
                                checked={mode}
                                onChange={() => setMode(!mode)}
                            />
                        </Div>
                        <Input
                            fluid
                            type="number"
                            label="Questions"
                            labelPosition="right"
                            value={qCount}
                            onChange={(e) =>
                                setQCount(Math.max(e.target.value, 4))
                            }
                        />
                        <Divider horizontal>based on</Divider>
                        <Input
                            fluid
                            label="Topic"
                            labelPosition="left"
                            value={source}
                            onChange={(e) => setSource(e.target.value)}
                        />
                        <Divider horizontal>in</Divider>
                        <Dropdown
                            placeholder={t("builder.dic.selectLanguage")}
                            fluid
                            selection
                            options={_.map(settings.languages, (l) => ({
                                key: l.code,
                                text: l.identifier,
                                value: l.identifier,
                            }))}
                            value={langValue}
                            onChange={(e, { value }) => setLangValue(value)}
                        />
                        <br />
                        <Button
                            size="small"
                            primary
                            onClick={getAIResponse}
                            disabled={aiStatus.credits <= 0}
                        >
                            Generate ({creditsNeeded}{" "}
                            {creditsNeeded === 1 ? "credit" : "credits"})
                        </Button>
                        <Div
                            small
                            italics
                            float-right
                            nudgeRight
                            ht="40px"
                            flex
                        >
                            {aiStatus.credits} generative credits left
                        </Div>
                    </Div>
                )}
                {fetching && (
                    <Div superpad ivory>
                        <Dimmer active>
                            <Loader>
                                This will take time. Please be patient...
                            </Loader>
                        </Dimmer>
                    </Div>
                )}
            </Div>
        </Div>
    );
}
