import React, { useState } from "react";
import { useGetAllUsersQuery } from "@api/apiV6";
import _ from "lodash";
import ProgressAnalytics from "./analytics/ProgressAnalytics.react";
import PerformanceAnalytics from "./analytics/PerformanceAnalytics.react";
import ActivityLog from "./analytics/ActivityLog.react";

import { Tab, Dropdown } from "semantic-ui-react";
import { Div } from "@components/Generics.react";
export default function AnalyticsLearners(props) {
    const [selectedUser, setSelectedUser] = useState(null);
    const { data: audienceData, isSuccess } = useGetAllUsersQuery();
    // ========================= Render Function =================================
    return (
        <Div basepad ash fullht>
            {isSuccess && (
                <Dropdown
                    placeholder={"Select a user"}
                    fluid
                    selection
                    search
                    options={_.map(audienceData, (l) => ({
                        key: l._id,
                        text: l.username,
                        value: l._id,
                    }))}
                    value={selectedUser}
                    onChange={(e, { value }) => setSelectedUser(value)}
                />
            )}
            <br />
            {selectedUser && <IndividualAnalytics id={selectedUser} />}
        </Div>
    );
}

function IndividualAnalytics(props) {
    const { id } = props;
    const panes = [
        {
            menuItem: "Progress",
            render: () => (
                <Tab.Pane>
                    <ProgressAnalytics userId={id} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Performance",
            render: () => (
                <Tab.Pane>
                    <PerformanceAnalytics userId={id} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Activity Log",
            render: () => (
                <Tab.Pane>
                    <ActivityLog userId={id} />
                </Tab.Pane>
            ),
        },
    ];
    // ========================= Render Function =================================
    return (
        <Tab
            panes={panes}
            menu={{ fluid: true, vertical: true, tabular: true }}
        />
    );
}
