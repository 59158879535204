import React from "react";
import _ from "lodash";
import { Dropdown } from "semantic-ui-react";

import { Div, Icon } from "@components/Generics.react";
import "@styles/components.scss";

export default function DeckToolbar(props) {
    const {
        deck,
        scale,
        setScale,
        pageNumber,
        setPageNumber,
        numPages,
        fullScreen,
        setFullScreen,
        navConfig = { prev: true, next: true },
    } = props;

    const notFirstPage = pageNumber > 0;
    const notLastPage = pageNumber < numPages;
    const isPortrait = window.innerHeight > window.innerWidth;

    const zoomOptions = [
        { key: "val-50", value: 0.5, text: "50%" },
        { key: "val-75", value: 0.75, text: "75%" },
        { key: "val-100", value: 1, text: "100%" },
        { key: "val-125", value: 1.25, text: "125%" },
        { key: "val-150", value: 1.5, text: "150%" },
        { key: "val-200", value: 2, text: "200%" },
        { key: "val-250", value: 2.5, text: "250%" },
        { key: "val-400", value: 4, text: "400%" },
    ];
    let pageOptions = [];
    _.times(numPages, (n) => {
        pageOptions.push({
            key: `slide-${n}`,
            value: n,
            text:
                !isPortrait && deck && deck?.content[n].data?.title
                    ? deck?.content[n].data.title
                    : `Slide ${n + 1}`,
        });
    });

    function prev() {
        if (notFirstPage && navConfig.prev) setPageNumber(pageNumber - 1);
    }
    function next() {
        if (notLastPage && navConfig.next) {
            setPageNumber(pageNumber + 1);
        }
    }
    function onZoomChange(e, { value }) {
        setScale(value);
    }
    function onPageChange(e, { value }) {
        setPageNumber(value);
    }

    // ========================= Render Function =================================
    return (
        <Div className="deck-toolbar">
            <Div
                float-left
                clickable={notFirstPage}
                className={`deck-toolbar-control prev ${
                    (!notFirstPage || !navConfig.prev || !deck?.navEnabled) &&
                    "disabled"
                }`}
                onClick={prev}
            >
                <Icon fitted name="chevron left" />
            </Div>
            <Div wd="72px" float-left half snug compact>
                {scale == null ? (
                    <span>100%</span>
                ) : (
                    <Dropdown
                        upward
                        pointing
                        trigger={<span>{scale * 100}%</span>}
                        options={zoomOptions}
                        onChange={onZoomChange}
                    />
                )}
            </Div>
            {!isPortrait && (
                <Div
                    float-left
                    clickable
                    nudgeLeft
                    txtWhite
                    className="deck-toolbar-btn"
                >
                    {setFullScreen && (
                        <Icon
                            nopad
                            name="expand"
                            onClick={() => setFullScreen(!fullScreen)}
                        />
                    )}
                </Div>
            )}
            <Div
                float-right
                clickable={notLastPage}
                className={`deck-toolbar-control next ${
                    (!notLastPage || !navConfig.next || !deck?.navEnabled) &&
                    "disabled"
                }`}
                onClick={next}
            >
                <Icon fitted name="chevron right" />
            </Div>
            <Div wd="72px" float-right half snug compact>
                {pageNumber + 1}/{numPages}
            </Div>
            {navConfig.prev && deck?.jumpAhead && (
                <Div
                    float-right
                    clickable
                    nudgeRight
                    txtWhite
                    className="deck-toolbar-btn"
                >
                    <Dropdown
                        upward
                        pointing
                        direction="left"
                        scrolling
                        trigger={<Icon nopad name="tasks" />}
                        options={pageOptions}
                        onChange={onPageChange}
                    />
                </Div>
            )}
        </Div>
    );
}
