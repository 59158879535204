// Import from NPM
// -------------------------------------
import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Loader, Icon } from "semantic-ui-react";
import _ from "lodash";

// Import from Config
// -------------------------------------
import {
    useGetArticlesQuery,
    useGetArticleQuery,
    useGetFavoritesQuery,
    useSendArticlesCommentsMutation,
    useGetSessionsQuery,
} from "@api/apiV6";
import { startArticleAttempt, startSession } from "@reducers/attemptSlice";
import { deckFormats } from "@schemas/deckFormats";
import { snaked } from "@utilities/helpers";

// Import Components
// -------------------------------------
import { Div } from "@components/Generics.react";
import MasterLayout from "@layouts/MasterLayout.react";
import ArticleCard from "@components/ArticleCard.react";
import ArticleViewer from "./ArticleViewer.react";
import RecommendedArticles from "@components/RecommendedArticles.react";

export default function Article(props) {
    const { articleIdentifier, readerType } = useParams();
    const { isSuccess: articlesSuccess } = useGetArticlesQuery();

    const attempt = useSelector((state) => state.attempt);
    const articles = useSelector((state) => state.articles);
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const { t } = useTranslation("common");
    const [sendArticlesComments] = useSendArticlesCommentsMutation();

    const articleId = articles.list[articleIdentifier]
        ? articles.list[articleIdentifier]._id
        : articleIdentifier;

    const [articleReadertype, setArticleReaderType] = useState(
        deckFormats[readerType]
            ? deckFormats[readerType].readerType
            : readerType
    );
    const [inPlayer, setInPlayer] = useState(
        deckFormats[readerType]
            ? deckFormats[readerType].inPlayer
            : _.find(deckFormats, { readerType: readerType })?.inPlayer
    );

    const {
        data: article,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetArticleQuery(
        {
            id: articleId,
        user_id: user._id,
            readerType: articleReadertype,
        },
        {
            enabled: false,
        }
    );
    const { data: favorites, isSuccess: favSuccess } = useGetFavoritesQuery();
    const { data: sessions } = useGetSessionsQuery(user._id);

    useEffect(() => {
        if (articlesSuccess && isSuccess && articleReadertype) {
            if (attempt.article !== articleId) {
                dispatch(
                    startArticleAttempt({
                        id: articleId,
                        deckId: article.decks[0],
                        currentState: article.currentState,
                        readerType: articleReadertype,
                        history: article.history,
                    })
                );
            dispatch(startSession(sessions));
        }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [articlesSuccess, isSuccess, attempt.article, articleReadertype]);

    useEffect(() => {
        if (
            isSuccess &&
            (articleReadertype === "a" || articleReadertype == null)
        ) {
            const uRT = deckFormats[article.category]
                ? deckFormats[article.category].readerType
                : article.category;
            const uIP = deckFormats[article.category]
                ? deckFormats[article.category].inPlayer
                : _.find(deckFormats, { category: article.category })?.inPlayer;
            setArticleReaderType(uRT);
            setInPlayer(uIP);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);
    const handleAddComment = async (commentText) => {
        try {
            await sendArticlesComments({
                id: article.id,
                comment: commentText,
            });
        } catch (error) {}
    };
    // ------------------------------------------
    // Select Main Panel Component
    // ------------------------------------------
    function mainPanel() {
        if (articlesSuccess && isSuccess && !article.published) {
            return (
                <Div fullht fluid flex>
                    <Div wd="360px" center-align big bold superpad ash rounded>
                        <Icon name="lock" size="huge" />
                        <br />
                        <br />
                        {t("article.isInactive")}
                    </Div>
                </Div>
            );
        } 
        else {
            return (
                articlesSuccess &&
                isSuccess && (
                    <ArticleViewer
                        articles={articles}
                        article={article}
                        readerType={articleReadertype}
                        inPlayer={inPlayer}
                        onAddComment={handleAddComment}
                        favorites={favSuccess && favorites}
                    />
                )
            );
        }
    }

    const associatedArticles = useMemo(() => {
        if (article?.tags) {
            const articleTags = _.map(article.tags, (tag) => snaked(tag.name));
            return _.filter(
                _.uniqBy(
                    _.flatten(
                        _.map(
                            articleTags,
                            (tag) => articles.taggedList[tag]?.articles
                        )
                    ),
                    "_id"
                ),
                (art) => art?._id !== article?._id
            );
        }
    }, [article, articles]);

    const rightPanelName = t("article.rightHeader");
    const rightContent = articlesSuccess &&
        isSuccess &&
        articles.list[articleIdentifier] && (
            <Div nopad fullht>
                <Div big padTop gutter bold uppercase>
                    {t("article.rightHeader")}
                </Div>
                <Div autoOverflow padBottom ht="calc(100% - 45px)">
                    {_.map(
                        _.filter(article.suggestedReading, Boolean),
                        (article, idx) => {
                            return (
                                <ArticleCard
                                    key={`article-right-suggested-${idx}`}
                                    article={articles.list[article.identifier]}
                                    feedStyle
                                />
                            );
                        }
                    )}
                    {_.map(
                        _.filter(associatedArticles, Boolean),
                        (article, idx) => {
                            return (
                                <ArticleCard
                                    key={`article-right-associated-${idx}`}
                                    article={articles.list[article.identifier]}
                                    feedStyle
                                />
                            );
                        }
                    )}
                </Div>
                </Div>
            );
    // ========================= Render Function =================================

    if (isLoading) return <Loader active />;
    if (isError) return <div>{error.error}</div>;
    if (articlesSuccess && isSuccess) {
        return (
            <MasterLayout
                title={article.name}
                description={article.description}
                image={article.image}
                mainPanel={mainPanel()}
                rightPanelName={rightPanelName}
                rightPanel={
                    (article.suggestedReading?.length || 0) +
                        (associatedArticles?.length || 0) ===
                    0 ? (
                        <RecommendedArticles />
                    ) : (
                        rightContent
                    )
                }
            />
        );
    } else {
        return <Loader active />;
    }
}
