import React, { useState } from "react";
import { useGetCourseInventoryQuery } from "@api/apiV6";
import _ from "lodash";

import { Dropdown } from "semantic-ui-react";
import { Div } from "@components/Generics.react";
import MainCourseAnalytics from "../../creator/analytics/MainCourseAnalytics.react";
export default function AnalyticsCourses(props) {
    const [selectedCourse, setSelectedCourse] = useState(null);
    const { data: courses, isSuccess } = useGetCourseInventoryQuery();
    // ========================= Render Function =================================
    return (
        <Div basepad ash fullht>
            {isSuccess && (
                <Dropdown
                    placeholder={"Select a course"}
                    fluid
                    selection
                    search
                    options={_.map(courses, (l) => ({
                        key: l._id,
                        text: l.name,
                        value: l._id,
                    }))}
                    value={selectedCourse}
                    onChange={(e, { value }) => setSelectedCourse(value)}
                />
            )}
            <br />
            {selectedCourse && (
                <MainCourseAnalytics course={{ _id: selectedCourse }} />
            )}
        </Div>
    );
}
