import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCreateUserMutation, useCreateInNotificationMutation, useCreateEmailNotificationMutation } from "@api/apiV6";
import * as XLSX from "xlsx";

import axios from "axios";
import { baseUrl } from "@api/apiV6";
import { settings } from "@config/settings/app.settings";
import { notificationConfig } from "@config/notifications/config.notification";
import _ from "lodash";
import { toast } from "react-toastify";

import { Div, Button } from "@components/Generics.react";
import { Modal, Icon, Input, Tab } from "semantic-ui-react";

export default function AddUserModal(props) {
    const { isOpen, setOpen, ownerTeam } = props;
    const { t } = useTranslation("common");
    const [username, setUsername] = useState("");
    const [validUsername, setValidUsername] = useState(false);
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(false);
    const [password, setPassword] = useState("");
    const [file, setFile] = useState(null);
    const [usersFromExcel, setUsersFromExcel] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [error] = useState(null);
    const [unameHelp, setUnameHelp] = useState(false);
    const [fnameHelp, setFnameHelp] = useState(false);
    const [passwordHelp, setPasswordHelp] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [createUser] = useCreateUserMutation();
    const [triggerInNotification] = useCreateInNotificationMutation();
    const [triggerEmailNotification] = useCreateEmailNotificationMutation();

    useEffect(() => {
        (async () => {
            if (username.indexOf(" ") !== -1) {
                setValidUsername(false);
            } else if (username.length > 3) {
                const response = await axios({
                    method: "get",
                    url: `${baseUrl}api/v3/users/check/${username}`,
                });
                response.data.available
                    ? setValidUsername(true)
                    : setValidUsername(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username]);
    useEffect(() => {
        // eslint-disable-next-line
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
            ? setValidEmail(true)
            : setValidEmail(false);
    }, [email]);

    useEffect(() => {
        if (usersFromExcel.length > 0) {
            const progress =
                ((uploadProgress + 1) / usersFromExcel.length) * 100;
            setUploadProgress(progress < 1 ? 0 : progress);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usersFromExcel]);

    async function tryRegister() {
        const nameSplit = fullName.split(" ");
        const signupResponse = await createUser({
            username: username,
            password: password,
            email: email,
            first_name: nameSplit[0],
            last_name: nameSplit[1] == null ? " " : nameSplit[1],
            team: ownerTeam,
            active: true,
        });
        if (notificationConfig.addUser.inAppNotification || notificationConfig.addUser.emailNotification) {
            if (notificationConfig.addUser.inAppNotification) {
                let message = _.pick(
                    notificationConfig.addUser,
                    "type",
                    "title",
                    "description",
                    "link"
                );
                message.created_at = new Date();
                triggerInNotification({
                    _id: signupResponse?.data?._id,
                    message: message,
                });
            }
            if (notificationConfig.addUser.emailNotification) {
                triggerEmailNotification({
                    _id: signupResponse?.data?._id,
                    route: notificationConfig.addUser.route,
                    emailType: "user_creation",
                });
            }
        }
        if (signupResponse) {
            toast("User Created");
            setOpen(false);
        }
    }

    const handleFileChange = (e) => {
        const uploadedFile = e.target.files[0];
        if (uploadedFile) {
            const reader = new FileReader();
            try {
                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: "array" });
                    const sheet = workbook.Sheets[workbook.SheetNames[1]];
                    const parsedUsers = XLSX.utils.sheet_to_json(sheet, {
                        header: 1,
                    });
                    const usersData = [];
                    const _parsedCsvHeaders = parsedUsers[0]; // get the index 0 or get the header fields of the Parsed CSV
                    for (let k = 1; k < parsedUsers.length; k++) {
                        let obj = {};
                        let _parsedCsValues = parsedUsers[k]; // value of the header field of the parsed CSV or the index 1
                        for (let c = 0; c < _parsedCsValues.length; c++) {
                            obj[_parsedCsvHeaders[c]] = _parsedCsValues[c]; // create key value pair, key is the index 0, and value is the index 1
                        }
                        usersData.push(obj); // push to Array the Created Object
                    }
                    setUsersFromExcel(usersData);
                    setFile(uploadedFile);
                };
                reader.readAsArrayBuffer(uploadedFile);
            } catch (error) {
                console.error("Error parsing Excel file", error);
            }
        }
    };

    async function registerMultiUsers() {
        let failedUsers = [];
        let passedUsers = [];

        for (let idx = 0; idx < usersFromExcel.length; idx++) {
            try {
                const { username, password, email, name } = usersFromExcel[idx];

                const nameSplit = name.split(" ");
                const signupResponse = await createUser({
                    username: username,
                    password: password,
                    email: email,
                    first_name: nameSplit[0],
                    last_name: nameSplit[1] == null ? " " : nameSplit[1],
                    team: ownerTeam,
                    active: true,
                });

                if (signupResponse.data) {
                    passedUsers.push([
                        username,
                        `Created user with username : ${username}`,
                    ]);
                } else {
                    failedUsers.push([
                        username,
                        signupResponse.error.data.message,
                    ]);
                }

                // Update the progress using parseInt
                const progress = parseInt(
                    (idx * 100) / usersFromExcel.length,
                    0
                );
                setUploadProgress(progress);
            } catch (err) {
                console.log("err", err);
                failedUsers.push([usersFromExcel[idx].username, err]);
            }
        }

        setOpen(false);
        setUploadProgress(0);
        setFile(null);
        setUsersFromExcel([]);
    }

    const closeModal = () => {
        setUploadProgress(0);
        setFile(null);
        setUsersFromExcel([]);
    };

    const checkStyle = { float: "right", margin: "-30px 10px 0 0" };

    const panes = [
        {
            menuItem: "Create Single User",
            render: () => (
                <Tab.Pane>
                    <Div white basepad>
                        {error && (
                            <Div padded fluid danger gutter>
                                {error}
                            </Div>
                        )}
                        <Div fluid gutter>
                            <Input
                                fluid
                                label={t("auth.registration.usernameLabel")}
                                placeholder={t(
                                    "auth.registration.usernamePlaceholder"
                                )}
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                onFocus={() => setUnameHelp(true)}
                                onBlur={() => setUnameHelp(false)}
                            />
                            {!validUsername && username?.length > 3 && (
                                <Div relaxed danger fluid>
                                    <i className="fa fa-times" /> &nbsp;{" "}
                                    {t("auth.registration.usernameUnavailable")}
                                </Div>
                            )}
                            {validUsername && (
                                <Div txtColor="success" style={checkStyle}>
                                    <i className="fa fa-check-circle" />
                                </Div>
                            )}
                            {unameHelp && (
                                <Div charcoal padded small italics>
                                    {t("auth.registration.usernameHelp")}
                                </Div>
                            )}
                        </Div>
                        <Div fluid gutter>
                            <Input
                                fluid
                                label={t("auth.registration.nameLabel")}
                                placeholder={t(
                                    "auth.registration.namePlaceholder"
                                )}
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                                onFocus={() => setFnameHelp(true)}
                                onBlur={() => setFnameHelp(false)}
                            />
                            {fnameHelp && (
                                <Div charcoal padded small italics>
                                    {t("auth.registration.nameHelp")}
                                </Div>
                            )}
                        </Div>
                        <Div fluid gutter>
                            <Input
                                fluid
                                label="Email"
                                type="email"
                                placeholder="Where can we contact you?"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onFocus={() => setEmailFocus(true)}
                                onBlur={() => setEmailFocus(false)}
                            />
                            {!emailFocus &&
                                !validEmail &&
                                email?.length > 0 && (
                                    <Div relaxed danger fluid>
                                        <i className="fa fa-times" /> &nbsp;
                                        Malformed email address.
                                    </Div>
                                )}
                        </Div>
                        <Div fluid gutter>
                            <Input
                                fluid
                                label={t("auth.registration.password")}
                                type={showPassword ? "text" : "password"}
                                placeholder="Input Password"
                                onChange={(e) => setPassword(e.target.value)}
                                icon={
                                    <Icon
                                        name={
                                            showPassword ? "eye slash" : "eye"
                                        }
                                        link
                                        onClick={() =>
                                            setShowPassword(!showPassword)
                                        }
                                    />
                                }
                                onFocus={() => setPasswordHelp(true)}
                                onBlur={() => setPasswordHelp(false)}
                            />
                            {passwordHelp && (
                                <Div charcoal padded small italics>
                                    {t("auth.registration.passwordHelp")}
                                </Div>
                            )}
                        </Div>
                    </Div>
                    <Button
                        primary
                        content="Create User"
                        onClick={tryRegister}
                        disabled={
                            username.length < 3 ||
                            !validUsername ||
                            !validEmail ||
                            password.length < 3
                        }
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Bulk Upload Users",
            render: () => (
                <Tab.Pane>
                    <Div ivory flex top-align>
                        <Div wd="50%" basepad>
                            <Div gutter bold big>
                                Step 1: Download Sample File
                            </Div>
                            <Button
                                fluid
                                content="Sample File"
                                primary
                                href={
                                    settings.baseUrl.endsWith("/")
                                        ? settings.baseUrl +
                                          "public/samples/register_user_upload_format.xlsx"
                                        : settings.baseUrl +
                                          "/public/samples/register_user_upload_format.xlsx"
                                }
                            />
                        </Div>
                        <Div wd="50%" basepad>
                            <Div gutter bold big>
                                Step 2: Upload Excel File with Users
                            </Div>
                            <Div fluid gutter>
                                <Input
                                    type="file"
                                    onChange={handleFileChange}
                                />
                            </Div>
                        </Div>
                    </Div>
                    <Div basepad vapor gutter>
                        <Div bold>{t("auth.registration.bulkGuidelines")}</Div>
                        <ul>
                            <li>
                                <b>{t("auth.registration.usernameLabel")}:</b>{" "}
                                {t("auth.registration.usernameHelp")}
                            </li>
                            <li>
                                <b>{t("auth.registration.nameLabel")}:</b>{" "}
                                {t("auth.registration.nameHelp")}
                            </li>
                            <li>
                                <b>{t("auth.registration.password")}</b>{" "}
                                {t("auth.registration.passwordHelp")}
                            </li>
                        </ul>
                    </Div>
                    <Button
                        primary
                        content="Create Users"
                        onClick={registerMultiUsers}
                        disabled={!file || uploadProgress > 0}
                    />
                </Tab.Pane>
            ),
        },
    ];
    // ========================= Render Function =================================
    return (
        <Modal
            size="small"
            open={isOpen}
            onClose={() => {
                setOpen(false);
                closeModal();
            }}
        >
            <Modal.Header>
                {t("admin.userMgmt.addUser")}
                <Div float-right clickable>
                    <Icon
                        name="times"
                        onClick={() => {
                            setOpen(false);
                            closeModal();
                        }}
                    />
                </Div>
            </Modal.Header>
            <Modal.Content>
                <Tab panes={panes} />
            </Modal.Content>
        </Modal>
    );
}
