import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useGetRecentViewsQuery } from "@api/apiV6";
import _ from "lodash";
import { Div, Image } from "@components/Generics.react";

import ArticleCarousel from "@components/ArticleCarousel.react";
import { settings } from "@config/settings/app.settings";

export default function GeneralHome(props) {
    const taggedList = useSelector((state) => state.articles.taggedList);
    const articlesList = useSelector((state) => state.articles.list);
    const user = useSelector((state) => state.auth.user);
    const { data: recents, isSuccess } = useGetRecentViewsQuery();
    const { t } = useTranslation("common");
    const isPortrait = window.innerHeight > window.innerWidth;

    const linkedHeaders = settings.home[user.stage].linkedHeaders;
    const selectedTags = _.filter(
        t(`home.carousels.${user.stage}`).split(","),
        (tag) => taggedList[tag]?.articles.length > 0
    );

    const activeRecents = isSuccess
        ? _.filter(recents.article, { published: true })
        : [];

    const newlyAdded = useMemo(() => {
        return _.reverse(
            _.values(articlesList).sort(function compare(a, b) {
                var dateA = new Date(a.updatedAt);
                var dateB = new Date(b.updatedAt);
                return dateA - dateB;
            })
        );
    }, [articlesList]);

    // ========================= Render Function =================================
    return (
        <>
            {linkedHeaders.length > 0 &&
                _.map(linkedHeaders, (headerImg, idx) => {
                    const itemSrc = t(`home.linkedHeaders.${headerImg}.src`);
                    const itemLink = t(`home.linkedHeaders.${headerImg}.link`);
                    if (itemLink.includes("http"))
                        return (
                            <a
                                href={itemLink}
                                target="_blank"
                                rel="noreferrer"
                                key={`linkedheader-${idx}`}
                            >
                                <Div padded={isPortrait} gutter>
                                    <Image fluid rounded src={itemSrc} />
                                </Div>
                            </a>
                        );
                    else
                        return (
                            <NavLink to={itemLink} key={`linkedheader-${idx}`}>
                                <Div padded={isPortrait} gutter>
                                    <Image fluid rounded src={itemSrc} />
                                </Div>
                            </NavLink>
                        );
                })}
            {isSuccess &&
                activeRecents.length > 0 &&
                settings.home[user.stage].recents && (
                    <Div
                        key={`recent-carousel`}
                        compact
                        padTop
                        rounded
                        smoke={!isPortrait}
                        gutter={!isPortrait}
                        small
                    >
                        <ArticleCarousel
                            header={t("home.recently")}
                            articles={activeRecents}
                            noSort
                        />
                    </Div>
                )}
            {settings.home[user.stage].newly && (
                <Div
                    key={`newly-carousel`}
                    compact
                    padTop
                    rounded
                    smoke={!isPortrait}
                    gutter={!isPortrait}
                    small
                >
                    <ArticleCarousel
                        header={t("home.newly")}
                        articles={newlyAdded}
                        noSort
                    />
                </Div>
            )}
            {_.map(selectedTags, (tag, carouselIndex) => {
                return (
                    <Div
                        key={`carousel-index-${carouselIndex}`}
                        compact
                        padTop
                        rounded
                        smoke={!isPortrait}
                        gutter={!isPortrait}
                        small
                    >
                        <ArticleCarousel
                            header={
                                !_.startsWith(t(`tags.${tag}`), "tags")
                                    ? t(`tags.${tag}`)
                                    : tag
                            }
                            libLink={`/tags/${tag}`}
                            articles={taggedList[tag]?.articles}
                        />
                    </Div>
                );
            })}
        </>
    );
}
