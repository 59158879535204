import React, { useEffect } from "react";
import _ from "lodash";
import { Image } from "semantic-ui-react";
import { lightOrDark } from "@utilities/helpers";

import "@styles/readers.scss";
import { Div } from "@components/Generics.react";

export default function MemeReader(props) {
    const { deck, currentState, recordAttempt } = props;

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentState]);

    return (
        <Div fluid fullht className="meme-reader" centered center-align>
            {_.map(deck.content, (meme, index) => {
                return (
                    <Div
                        key={`meme=${index}`}
                        gutter
                        maxWd="400px"
                        fluid
                        centered
                        huge
                        className="meme-container"
                    >
                        <Image fluid src={meme.image} />
                        <Div
                            fluid
                            bold
                            basepad
                            altText
                            center-align
                            className="meme-text-top"
                            style={{
                                color: meme.topTextColor || "#ffffff",
                                textShadow: `0 0 10px ${
                                    lightOrDark(meme.topTextColor) === "dark"
                                        ? "#ffffff"
                                        : "#000000"
                                }`,
                            }}
                        >
                            {meme.topText}
                        </Div>
                        <Div
                            fluid
                            bold
                            basepad
                            altText
                            center-align
                            className="meme-text-bottom"
                            style={{
                                color: meme.bottomTextColor || "#ffffff",
                                textShadow: `0 0 10px ${
                                    lightOrDark(meme.bottomTextColor) === "dark"
                                        ? "#ffffff"
                                        : "#000000"
                                }`,
                            }}
                        >
                            {meme.bottomText}
                        </Div>
                    </Div>
                );
            })}
        </Div>
    );
}
