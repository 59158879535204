// Import from NPM
// -------------------------------------
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "@components/Generics.react";
import { Input } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

// Import from Config
// -------------------------------------
import { baseUrl } from "@api/apiV6";
import AuthLayout from "@layouts/AuthLayout.react";
import { Div } from "@components/Generics.react";

// Import Components
// -------------------------------------

export default function ChangePassword(props) {
    const auth = useSelector((state) => state.auth);
    const [password, setPassword] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation("common");
    const { state } = useLocation();

    useEffect(() => {
        if (password.length > 0 && confirmPass.length > 0) {
            if (password !== confirmPass) setError("Passwords do not match...");
            else setError(null);
        }
    }, [password, confirmPass]);

    const tryReset = async () => {
        try {
            let formData = new FormData();
            formData.append("password", password);
            formData.append("password_confirmation", confirmPass);
            await axios({
                method: "post",
                url: `${baseUrl}api/v3/auth/change_password`,
                data: formData,
                headers: { "access-token": auth.token },
            });
            navigate(state?.from?.pathname || "/");
        } catch (error) {
            setError(error.response.data.message);
        }
    };
    // ========================= Render Function =================================
    return (
        <AuthLayout title={`Login to ${t("appName")}`}>
            <Div fluid white fullHt>
                <Div massive altText fluid center-align gutter>
                    CHANGE PASSWORD
                </Div>
                {error && (
                    <Div padded fluid danger gutter>
                        {error}
                    </Div>
                )}
                <Div fluid gutter>
                    <Input
                        fluid
                        label="Password"
                        type="password"
                        placeholder="Input Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <br />
                    <Input
                        fluid
                        label="Confirm Password"
                        placeholder="Re-enter Password"
                        value={confirmPass}
                        onChange={(e) => setConfirmPass(e.target.value)}
                    />
                    <br />
                    <Button
                        content="Cancel"
                        onClick={() => navigate(-1)}
                    />
                    <Button primary content="Submit" onClick={tryReset} />
                </Div>
            </Div>
        </AuthLayout>
    );
}
