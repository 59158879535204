import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { Icon, Dropdown, Image, Divider, List } from "semantic-ui-react";
import axios from "axios";
import _ from "lodash";
import RadialProgressChart from "@components/charts/RadialProgressChart.react";
import {
    BarChart,
    ResponsiveContainer,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
} from "recharts";
import MasterLayout from "@layouts/MasterLayout.react";
import { Div, Button } from "@components/Generics.react";
import { darkStyle, primaryStyle } from "@utilities/helpers";
import { settings } from "@config/settings/app.settings";

const filterOptions = [
    { key: "l-7", value: "weekly", text: "Last 7 Days" },
    { key: "l-15", value: "half-month", text: "Last 15 Days" },
    { key: "l-30", value: "monthly", text: "Last 30 Days" },
];

const dashConfig = {
    gamifixURL: "https://ekartpduat.quodeck.com",
    username: "sysadmin",
    password: "password",
};

export default function GamifixPerformance(props) {
    const user = useSelector((state) => state.auth.user);

    const [headers, setHeaders] = useState(null);
    const [userData, setUserData] = useState(null);

    const [selectValue, setSelectValue] = useState("weekly");
    const [historyData, setHistoryData] = useState(null);
    const [aggregateData, setAggregateData] = useState([]);

    const isPortrait = window.innerHeight > window.innerWidth;

    function formatHistoryData(srcData) {
        return _.map(srcData.data, (d) => {
            return {
                "CXNS Score": d.score,
                date: new Date(d.date).toLocaleDateString(),
            };
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            const date = new Date();
            const previousDay = date.setDate(date.getDate() - 2);
            const weekDate = new Date();
            const fromDate = weekDate.setDate(weekDate.getDate() - 8);
            try {
                const response = await axios.post(
                    `${dashConfig.gamifixURL}/api/v1/auth/sign_in`,
                    {
                        username: dashConfig.username,
                        password: dashConfig.password,
                    }
                );
                const gamifixHeaders = {
                    "Content-Type": "application/json",
                    client: response.headers.client,
                    "access-token": response.headers["access-token"],
                    expiry: response.headers.expiry,
                    expires: response.headers.expires,
                    uid: response.headers.uid,
                    "token-type": "Bearer",
                };
                setHeaders(gamifixHeaders);

                const options = {
                    method: "GET",
                    url: `${dashConfig.gamifixURL}/api/v1/performances/${user.username}/display/${previousDay}/show`,
                    headers: gamifixHeaders,
                };
                const getUserData = await axios(options);
                setUserData(getUserData.data);

                let optionsrange = {
                    method: "GET",
                    url: `${dashConfig.gamifixURL}/api/v1/performance/${user.username}/fetchrange/?start=${fromDate}&end=${previousDay}`,
                    headers: gamifixHeaders,
                };
                const getHistoryData = await axios(optionsrange);
                setHistoryData(getHistoryData.data);
                setAggregateData(formatHistoryData(getHistoryData.data));
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty dependency array to execute once on mount

    async function handleRun(fromDate, toDate) {
        let options = {
            method: "GET",
            url: `${dashConfig.gamifixURL}/api/v1/performance/${user.username}/fetchrange/?start=${fromDate}&end=${toDate}`,
            headers: headers,
        };
        const getHistoryData = await axios(options);
        setHistoryData(getHistoryData.data);
        setAggregateData(formatHistoryData(getHistoryData.data));
    }

    async function doExpand(data) {
        var predate = new Date();
        let previousDay = predate.setDate(predate.getDate() - 2);
        var date = new Date();
        if (data === "weekly") {
            let fromDate = date.setDate(date.getDate() - 8);
            await handleRun(fromDate, previousDay);
        }
        if (data === "half-month") {
            let fromDate = date.setDate(date.getDate() - 16);
            await handleRun(fromDate, previousDay);
        }
        if (data === "monthly") {
            let fromDate = date.setDate(date.getDate() - 31);
            await handleRun(fromDate, previousDay);
        }
    }

    var date = new Date();
    let previousDay = date.setDate(date.getDate() - 2);
    let currentDate = new Date(previousDay);
    let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
        currentDate
    );
    let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(
        currentDate
    );
    let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
        currentDate
    );
    let formatDate = `${da} ${mo} ${ye}`;
    let scoreData = userData ? userData.score : 0;

    function statisticBlock(icon, name, value) {
        return (
            <Div
                padded
                padTop
                padBottom
                rounded
                float-left
                wd="32%"
                style={darkStyle(user.branding?.colors?.main || settings.colors.main, "to top right")}
            >
                <Icon name={icon} size="big" />
                <Div padTop>{name}</Div>
                <Div big>{value ? value.toFixed(2) : 0.0}</Div>
            </Div>
        );
    }

    const mainPanel = (
        <Div padded center-align smoke maxWd={"400px"} centered>
            {user !== null ? (
                <Div padTop>
                    <Div
                        topRounded
                        relaxed
                        charcoal
                        fit-content
                        centered
                        center-align
                        uppercase
                        big
                        style={darkStyle("#666666", "to top")}
                    >
                        {formatDate} Report
                    </Div>
                    <Div topRounded padded white slightShadow>
                        <Div padTop flex>
                            <Div float-left fit-content>
                                {scoreData <= 20 ? (
                                    <Div padded ht="160px">
                                        <RadialProgressChart
                                            width={100}
                                            height={100}
                                            color={user.branding?.colors?.primary || settings.colors.primary}
                                            value={scoreData}
                                            maxValue={20}
                                            showAbsolute
                                        />
                                        <Div gapTop>CXNS Score</Div>
                                    </Div>
                                ) : (
                                    <Div padTop large bold center-align>
                                        CXNS Score
                                        <br />
                                        {scoreData}
                                    </Div>
                                )}
                            </Div>
                            <Div
                                float-left
                                wd="calc(100% - 128px)"
                                style={{ marginTop: "-50px" }}
                            >
                                <Div clearfix>
                                    {scoreData <= 20 && (
                                        <Div float-left wd="50%">
                                            <Image
                                                src={
                                                    scoreData === 0
                                                        ? "/assets/images/configurable/leaderboard/golden_cup.png"
                                                        : scoreData > 0 &&
                                                          scoreData <= 10
                                                        ? "/assets/images/configurable/leaderboard/silver_cup.png"
                                                        : "/assets/images/configurable/leaderboard/bronze_cup.png"
                                                }
                                            />
                                        </Div>
                                    )}
                                    <Div float-left padTop>
                                        <Div left-align nudgeLeft>
                                            RANK
                                        </Div>
                                        <Div
                                            charcoal
                                            padded
                                            rightRounded
                                            style={{ marginLeft: "-20px" }}
                                        >
                                            <Div padSides>
                                                {scoreData === 0
                                                    ? "GOLD"
                                                    : scoreData > 0 &&
                                                      scoreData <= 10
                                                    ? "SILVER"
                                                    : scoreData > 10 &&
                                                      scoreData <= 20
                                                    ? "BRONZE"
                                                    : "NA"}
                                            </Div>
                                        </Div>
                                    </Div>
                                </Div>
                            </Div>
                        </Div>
                        <Div
                            clear
                            smoke
                            flex
                            padded
                            clearfix
                            snubbed
                            gutter
                            style={{ marginTop: "-15px", gap: "5px" }}
                            small={!isPortrait}
                        >
                            <span>0: Gold ▪</span>
                            <span>1-10: Silver ▪</span>
                            <span>11-20: Bronze ▪</span>
                            <span>{`>21: NA`}</span>
                        </Div>
                        <Divider />
                        <Div clear>
                            <SubSection
                                section="conversion"
                                userData={userData}
                            />
                            <SubSection section="fakes" userData={userData} />
                            <SubSection
                                section="negatives"
                                userData={userData}
                            />
                        </Div>
                        <Divider />
                        <Div clear gutter>
                            <Div
                                large
                                left-align
                                flex
                                noOverflow
                                style={{
                                    ...darkStyle(
                                        user.branding?.colors?.main || settings.colors.main,
                                        "to top right"
                                    ),
                                    justifyContent: "space-between",
                                }}
                            >
                                <Div padSides>CXNS Score</Div>
                                <Div float-right charcoal padded>
                                    {userData?.details?.cxns_score}
                                </Div>
                            </Div>
                            <Div vapor padTop padBottom fluid left-align>
                                <Div padSides small>
                                    View History
                                </Div>
                                <Div compact>
                                    <Dropdown
                                        button
                                        fluid
                                        value={selectValue}
                                        options={filterOptions}
                                        onChange={(e, data) => {
                                            setSelectValue(data.value);
                                            doExpand(data.value);
                                        }}
                                    />
                                </Div>

                                {aggregateData.length !== 0 ? (
                                    <Div padded>
                                        <Div white padTop gutter>
                                            <Div
                                                padded
                                                style={{ marginLeft: "-30px" }}
                                            >
                                                <ResponsiveContainer
                                                    width="100%"
                                                    height={150}
                                                >
                                                    <BarChart
                                                        width={730}
                                                        height={250}
                                                        data={aggregateData}
                                                    >
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis
                                                            dataKey="date"
                                                            tick={false}
                                                        />
                                                        <YAxis />
                                                        <Tooltip />
                                                        <Bar
                                                            dataKey="CXNS Score"
                                                            fill={
                                                                user.branding
                                                                    .colors.main
                                                            }
                                                        />
                                                    </BarChart>
                                                </ResponsiveContainer>
                                            </Div>
                                        </Div>
                                        <Div
                                            fluid
                                            flex
                                            clearfix
                                            style={{
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            {statisticBlock(
                                                "sort amount down",
                                                "LOWEST",
                                                historyData?.highestScore
                                            )}
                                            {statisticBlock(
                                                "hourglass half",
                                                "AVERAGE",
                                                historyData?.averageScore
                                            )}
                                            {statisticBlock(
                                                "sort amount up",
                                                "BEST",
                                                historyData?.lowestScore
                                            )}
                                        </Div>
                                    </Div>
                                ) : (
                                    <h2
                                        style={{
                                            marginTop: "58px",
                                            height: "100vh",
                                        }}
                                    >
                                        No Data Found
                                    </h2>
                                )}
                            </Div>
                        </Div>
                        <Div gutter>
                            <NavLink to={"/app/reports"}>
                                <Button
                                    fluid
                                    primary
                                    content="View Learning Reports"
                                />
                            </NavLink>
                        </Div>
                    </Div>
                    <Div ht="60px" />
                </Div>
            ) : (
                <h2 style={{ marginTop: "58px", height: "100vh" }}>
                    No Data Found
                </h2>
            )}
        </Div>
    );
    return (
        <MasterLayout
            title={"Performance Dashboard"}
            mainPanel={mainPanel}
            navName={"My Performance"}
            rootPage
            hidePanelToggle
        />
    );
}

function SubSection(props) {
    const user = useSelector((state) => state.auth.user);
    const { section, userData } = props;
    const subSectionMap = {
        conversion: {
            name: "Conversion (%)",
            mainValue: "conversion",
            mainValueType: "calcPercent",
            items: [
                ["Total Attempts", "total_attempts"],
                ["Total Deliveries", "total_deliveries"],
            ],
        },
        fakes: {
            name: "Fake (%)",
            mainValue: "fake",
            mainValueType: "absPercent",
            items: [
                ["Forward Fake", "forward_fake"],
                ["Reverse Fake", "reverse_fake"],
            ],
        },
        negatives: {
            name: "Total Negative Feedback Count",
            mainValue: "total_negative_feedback_count",
            mainValueType: "absolute",
            items: [
                ["Bad Count", "bad_count"],
                ["UDBad Count", "udbad_count"],
                ["Escalations Count", "escalations_count"],
            ],
        },
    };
    const [showDrawer, setShowDrawer] = useState(false);
    const ssDetails = subSectionMap[section];
    return (
        <Div left-align white slightShadow snug>
            <Div
                padded
                clearfix
                onClick={() => setShowDrawer(!showDrawer)}
                style={primaryStyle(user.branding?.colors, "to top right")}
            >
                {ssDetails.name}
                {userData && (
                    <Div inline float-right>
                        {ssDetails.mainValueType === "absolute"
                            ? userData.details[ssDetails.mainValue]
                            : ssDetails.mainValueType === "absPercent"
                            ? userData.details[ssDetails.mainValue] + "%"
                            : userData.details[ssDetails.mainValue] * 100 + "%"}
                        ▼
                    </Div>
                )}
            </Div>
            {showDrawer && userData && (
                <Div compact>
                    <List divided>
                        {_.map(ssDetails.items, (itm, itmIdx) => {
                            return (
                                <List.Item key={`${ssDetails.name}-${itmIdx}`}>
                                    {itm[0]}
                                    <Div float-right inline>
                                        {userData.details[itm[1]] || "-"}
                                    </Div>
                                </List.Item>
                            );
                        })}
                    </List>
                </Div>
            )}
        </Div>
    );
}
