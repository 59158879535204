import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { apiV6, useSendTrackerMutation } from "@api/apiV6";
import { trackClear } from "@reducers/trackerSlice";
import { clearAttempt } from "@reducers/attemptSlice";
import { clearArticles } from "@reducers/articlesSlice";

import { logout } from "@reducers/authSlice";

import { Div, Button } from "@components/Generics.react";
import AuthLayout from "@layouts/AuthLayout.react";

export default function Logout(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation("common");
    const user = useSelector((state) => state.auth.user);
    const tracker = useSelector((state) => state.tracker);
    const [sendTracker] = useSendTrackerMutation();

    const tryLogout = async () => {
        try {
            await sendTracker({ trackers: tracker }).unwrap();
            await dispatch(trackClear());
            await dispatch(clearAttempt());
            await dispatch(clearArticles());
            await dispatch(apiV6.util.resetApiState());
            await dispatch(logout());
        } catch (e) {
            console.log(e);
            dispatch(apiV6.util.resetApiState());
            dispatch(logout());
        }
    };

    // ========================= Render Function =================================
    return (
        <AuthLayout title={"Logout"} team={user.team}>
            <Div fluid white fullHt>
                <Div massive altText fluid center-align gutter>
                    {t("auth.logout.header")}
                </Div>
                <Div big trench>
                    {t("auth.logout.question")}
                </Div>
                <hr />
                <br />
                <Div clear>
                    <Button
                        content={t("components.no")}
                        onClick={() => navigate(-1)}
                    />
                    <Button
                        danger={+true}
                        content={t("components.yes")}
                        onClick={tryLogout}
                    />
                </Div>
            </Div>
        </AuthLayout>
    );
}
