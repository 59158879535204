import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Comment } from "semantic-ui-react";
import ReactPlayer from "react-player/lazy";
import ReactTimeAgo from "timeago-react";
import { Div, Icon } from "@components/Generics.react";
import ShareWidget from "@components/ShareWidget.react";
import Carousel from "@components/Carousel.react";
import UserAvatar from "@components/UserAvatar.react";
import { settings } from "@config/settings/app.settings";

export default function PostCard(props) {
    const { post, onLike, onCommentToggle, onAddComment } = props;
    const currentUserId = useSelector((state) => state.auth.user);
    const idExists = _.includes(
        post.likes.map(_.toLower),
        String(currentUserId?._id).toLowerCase()
    );
    const { t } = useTranslation("common");
    // const [showOptions, setShowOptions] = useState(false);
    const [liked, setLiked] = useState(post.liked);
    const [showSharePanel, setShowSharePanel] = useState(false);

    useEffect(() => {
        setLiked(post.liked);
    }, [post.liked]);

    const handleLike = () => {
        onLike(post._id);
        setLiked(!liked);
    };

    const handleCommentToggle = () => {
        onCommentToggle(post._id);
    };

    const handleShareToggle = () => {
        setShowSharePanel(!showSharePanel);
    };

    const handleCommentSubmit = (e) => {
        e.preventDefault();
        const comment = e.target.comment.value;
        onAddComment(post._id, comment);
        e.target.comment.value = "";
    };

    // ========================= Render Function =================================
    return (
        <Div fluid white gutter slightShadow snubbed centered small>
            <Div padSides padTop className="post-header">
                <UserAvatar
                    name={`${post.owner.first_name} ${post.owner.last_name}`}
                    image={post.owner.image}
                    avatarConfig={post.owner.avatarConfig}
                    size={50}
                />
                <Div small bold gutter padded>
                    {post.owner &&
                        `${post.owner.first_name} ${post.owner.last_name}`}
                    <p>
                        <ReactTimeAgo datetime={post.createdAt} />
                    </p>
                </Div>
            </Div>
            <Div padSides gutter className="post-content">
                {post.category === "text" && <p>{post.description}</p>}
                {post.category === "image" && (
                    <Div maxWd="400px">
                        <p>{post.description}</p>
                        <img src={post.content[0].image} alt="Post" />
                    </Div>
                )}
                {post.category === "video" && (
                    <Div maxWd="400px">
                        <p>{post.description}</p>
                        <Div rounded gutter className="video-responsive">
                            <ReactPlayer
                                url={post.content}
                                className="react-player"
                                width="100%"
                                height="100%"
                                controls
                            />
                        </Div>
                    </Div>
                )}
                {post.category === "link" && (
                    <div>
                        <p>{post.description}</p>
                        <a
                            href={post.content}
                            target="_blank"
                            rel="noopener noreferrer"
                            title={post.content}
                            className="social-link"
                        >
                            <div className="social-link-text">
                                {post.content}
                            </div>
                        </a>
                    </div>
                )}
                {post.category === "gallery" && (
                    <Div trench>
                        <p>{post.description}</p>
                        <Div>
                            <Carousel activeSlide={0} enableButtons={true}>
                                {post.content.map((item, index) => (
                                    <img
                                        key={index}
                                        src={item.image}
                                        alt="Post"
                                    />
                                ))}
                            </Carousel>
                        </Div>
                    </Div>
                )}
            </Div>
            <Div
                fluid
                vapor
                flex
                left-align
                medpad
                inset
                clickable
                className="post-actions"
            >
                <Div onClick={handleLike}>
                    <Icon
                        inline
                        name={
                            idExists
                                ? "heart"
                                : liked
                                ? "heart"
                                : "heart outline"
                        }
                    />
                    {post.likes.length} {t("article.like")}
                    {post.likes.length > 1 ? "s" : ""}
                </Div>
                <Div onClick={handleCommentToggle}>
                    <Icon inline padLeft name="comment alternate outline" />
                    {post.comments.length} {t("article.comment")}
                    {post.comments.length > 1 ? "s" : ""}
                </Div>
                <Div onClick={handleShareToggle}>
                    <Icon inline padLeft name="share square outline" />
                    {t("article.share")}
                </Div>
            </Div>
            {showSharePanel && (
                <Div padded>
                    <ShareWidget
                        url={`${settings.baseUrl}/learner/social/${post._id}`}
                        subject="Checkout this interesting post!!"
                    />
                </Div>
            )}
            {post.showCommentInput && (
                <Div padded>
                    <form
                        onSubmit={handleCommentSubmit}
                        className="comment-form"
                    >
                        <input
                            type="text"
                            name="comment"
                            placeholder={t("article.commentCTA")}
                        />
                        <button type="submit">{t("article.post")}</button>
                    </form>
                </Div>
            )}
            <Div>
                {post.showCommentInput &&
                    post.comments &&
                    post.comments.map((comment) => (
                        <UserComment key={comment._id} comment={comment} />
                    ))}
            </Div>
        </Div>
    );
}

const UserComment = ({ comment }) => {
    return (
        <Div gutter padded>
            <Comment.Group>
                <Comment>
                    <Comment.Avatar
                        className="profile-picture"
                        src={
                            comment.owner?.userProfilePicture ||
                            "https://react.semantic-ui.com/images/avatar/small/stevie.jpg"
                        }
                    />
                    <Comment.Content>
                        <Div small gutter>
                            <Comment.Author as="a">
                                {comment.owner &&
                                    `${comment.owner.first_name} ${comment.owner.last_name}`}
                            </Comment.Author>
                            <Div small>
                                <ReactTimeAgo datetime={comment.createdAt} />
                            </Div>
                            <Comment.Text>{comment.comment}</Comment.Text>
                        </Div>
                    </Comment.Content>
                </Comment>
            </Comment.Group>
        </Div>
    );
};
