import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
    useUpdateArticleMutation,
    useGetArticleBuilderQuery,
    useGetTagsQuery,
    useGetArticlesQuery,
} from "@api/apiV6";

import { Loader } from "semantic-ui-react";
import CreatorLayout from "@layouts/CreatorLayout.react";
import ArticleSetup from "./panels/ArticleSetup.react";
import ArticleShare from "./panels/ArticleShare.react";
import ArticleAnalytics from "./panels/ArticleAnalytics.react";

export default function ArticleEditor(props) {
    const { id, section } = useParams();
    const { data: article, isSuccess } = useGetArticleBuilderQuery(id);
    const { data: allTags, isSuccess: tagsSuccess } = useGetTagsQuery();
    const { data: allArticles, isSuccess: articlesSuccess } =
        useGetArticlesQuery();
    const [updateArticle] = useUpdateArticleMutation();
    const { t } = useTranslation("common");
    const setupPanel = isSuccess && (
        <ArticleSetup
            article={article}
            updateArticle={updateArticle}
            allArticles={articlesSuccess ? allArticles.blog : []}
        />
    );
    const sharePanel = isSuccess && (
        <ArticleShare
            article={article}
            updateArticle={updateArticle}
            allTags={tagsSuccess ? allTags : []}
        />
    );
    const analyticsPanel = isSuccess && <ArticleAnalytics article={article} />;
    const panelMap = {
        setup: setupPanel,
        share: sharePanel,
        analytics: analyticsPanel,
        // Add more sections and their respective panels as needed
    };

    // ========================= Render Function =================================
    if (isSuccess)
        return (
            <CreatorLayout
                title={`Editing: ${article.name}`}
                mainPanel={panelMap[section]}
                navName={`Article ${section}`}
                pageCode={"library"}
                rootPage={false}
                editMode={true}
            />
        );
    else return <Loader active>{t("components.loading")}</Loader>;
}
