import React, { useState, useContext } from "react";
import { LayoutContext } from "@layouts/MasterLayout.react";
import _ from "lodash";
import { Checkbox } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { trackQuestion } from "@reducers/trackerSlice";
import { Div, HDiv, Button } from "@components/Generics.react";
import ResponseOverlay from "@components/ResponseOverlay.react";
import { settings } from "@config/settings/app.settings";
import "@styles/readers.scss";

export default function MultipleSelect(props) {
    const {
        data,
        masterSeq,
        slideSeq,
        trackData,
        deckId,
        pageNumber,
        setPageNumber,
        mustOverlay = false,
    } = props;
    const [correct, setCorrect] = useState(null);
    const [showOverlay, setShowOverlay] = useState(false);
    const userId = useSelector((state) => state.auth.user._id);
    const dispatch = useDispatch();
    const { t } = useTranslation("common");
    const { fullScreen } = useContext(LayoutContext);
    const isPortrait = window.innerHeight > window.innerWidth || !fullScreen;

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [thisResponse, setThisResponse] = useState(null);

    const handleCheckboxChange = (option) => {
        if (selectedOptions.includes(option)) {
            setSelectedOptions(
                selectedOptions.filter((item) => item !== option)
            );
        } else {
            setSelectedOptions([...selectedOptions, option]);
        }
    };

    function trackInteraction() {
        const correctSelected = _(data.multiselect)
            .filter({ correct: "checked" })
            .map("text")
            .value();
        const userSelected = _.map(selectedOptions, "text");

        const isCorrect =
            _.every(correctSelected, (item) => userSelected.includes(item)) &&
            _.every(userSelected, (item) => correctSelected.includes(item));
        setCorrect(isCorrect);

        if (
            mustOverlay ||
            (data.feedback != null &&
                data.feedback.length > 0 &&
                data.purpose !== "Provide Insight")
        ) {
            setThisResponse({
                correct: isCorrect,
                response: userSelected,
            });
            setShowOverlay(true);
        } else {
            setPageNumber(pageNumber + 1);
            if (trackData) {
                trackData({
                    correct: isCorrect,
                    response: userSelected,
                });
            }
        }

        dispatch(
            trackQuestion({
                deckId: deckId,
                slideId: masterSeq == null ? slideSeq : masterSeq,
                userId: userId,
                response: userSelected,
            })
        );
    }

    function closeOverlay() {
        setPageNumber(pageNumber + 1);
        if (trackData) {
            trackData(thisResponse);
        }
    }

    const getOptions = _.map(data.multiselect, (option, index) => {
        if (settings.functionality.latexEnabled)
            return (
                <Div clear spaced key={`ms-option-${index}`}>
                    <Checkbox
                        toggle
                        key={option.text}
                        label={
                            <label>
                                <HDiv
                                    content={option.text}
                                    clickable
                                    style={{ marginTop: "-3px" }}
                                />
                            </label>
                        }
                        checked={selectedOptions.includes(option)}
                        onChange={() => handleCheckboxChange(option)}
                    />
                </Div>
            );
        else
            return (
                <Div clear spaced key={`ms-option-${index}`}>
                    <Checkbox
                        toggle
                        key={option.text}
                        label={option.text}
                        checked={selectedOptions.includes(option)}
                        onChange={() => handleCheckboxChange(option)}
                    />
                </Div>
            );
    });

    // ========================= Render Function =================================
    return (
        <Div
            wd={isPortrait ? "100%" : "50%"}
            centered
            fullht
            zoom
            superpad
            className="multiselect"
        >
            <HDiv
                big={data.question?.length < 120}
                gutter
                content={data.question}
            />
            <Div small italics gutter>
                {t("deck.qdf.multiSelect")}
            </Div>
            <Div trench ash rounded compact padTop padBottom left-align>
                {getOptions}
            </Div>
            <Button
                content={"Submit"}
                fluid
                normal={isPortrait ? undefined : +true}
                primary
                onClick={trackInteraction}
            />
            {showOverlay && (
                <ResponseOverlay
                    feedback={data.feedback}
                    correct={correct}
                    closeResponse={closeOverlay}
                />
            )}
        </Div>
    );
}
