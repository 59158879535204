import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Div } from "@components/Generics.react";
import { Progress } from "semantic-ui-react";

import PieChart from "@components/charts/PieChart.react";
import { deckFormats } from "@schemas/deckFormats";

export default function CourseSummary(props) {
    const { course, contentMap, courseData } = props;
    const { t } = useTranslation("common");

    const completeCount = _.filter(courseData, { complete: true })?.length;
    const startedCount = _.filter(
        courseData,
        (u) => u.view_attempts > 0 && u.time_spent > 0 && !u.complete
    )?.length;
    const avCompletion = _.meanBy(courseData, (u) => parseInt(u.completion));
    const timeSpent = _.flatMap(courseData, (u) =>
        Math.round(parseInt(u.time_spent, 0) / 60, 0)
    );
    // Define the ranges
    const timeRanges = [
        { name: "0", min: 0, max: 0 },
        { name: "0 - 3", min: 1, max: 3 },
        { name: "3 - 5", min: 3, max: 5 },
        { name: "5 - 10", min: 5, max: 10 },
        { name: "10 - 30", min: 10, max: 30 },
        { name: "30 - 60", min: 30, max: 60 },
        { name: "60+", min: 60, max: Infinity },
    ];
    const timeMap = _.map(timeRanges, (range) => ({
        name: range.name,
        value: _.filter(
            timeSpent,
            (num) =>
                (num > range.min || range.min === 0) &&
                (range.max === Infinity ? true : num <= range.max)
        ).length,
    }));

    const progress = [
        { name: "Completed", value: completeCount },
        { name: "Started", value: startedCount },
        {
            name: "Not Started",
            value: courseData.length - completeCount - startedCount,
        },
    ];
    const contentDistribution = _.map(_.keys(contentMap), (k) => ({
        name: _.find(deckFormats, { readerType: k })?.name,
        value: contentMap[k],
    }));

    return (
        <Div>
            <Div flex ash center-align rimmed gapTop gapBottom spaceBetween>
                <Div wd="33%" ht="300px" white medpad>
                    <Div big uppercase padded altText>
                        {t("analytics.contentProfile")}
                    </Div>
                    <PieChart
                        width={100}
                        height={200}
                        flatLegend
                        data={contentDistribution}
                    />
                </Div>
                <Div wd="33%" ht="300px" white medpad>
                    <Div big uppercase padded altText>
                        {t("analytics.completionStatus")}
                    </Div>
                    <PieChart
                        width={100}
                        height={200}
                        flatLegend
                        data={progress}
                    />
                </Div>
                <Div wd="33%" ht="300px" white medpad>
                    <Div big uppercase padded altText>
                        {t("analytics.timeSpent")}
                    </Div>
                    <PieChart
                        width={100}
                        height={200}
                        flatLegend
                        data={timeMap}
                    />
                </Div>
            </Div>
            <Div flex center-align gapTop spaceBetween big uppercase>
                <Div ash wd="calc(25% - 7.5px)" basepad altText>
                    {course.sequential ? "Sequential" : "Non-sequential"}
                </Div>
                <Div ash wd="calc(25% - 7.5px)" basepad altText>
                    {`${course.locations.length || 0} ${t("analytics.topics")}`}
                </Div>
                <Div ash wd="calc(25% - 7.5px)" basepad altText>
                    {`${
                        _.flatMap(course.locations, "contentList").length || 0
                    } ${t("analytics.decks")}`}
                </Div>
                <Div ash wd="calc(25% - 7.5px)" basepad altText>
                    {`${course.learnerCount + 1 || 0} ${t(
                        "analytics.learners"
                    )}`}
                </Div>
            </Div>
            <Div
                fluid
                danger={!course.launch}
                success={course.launch}
                basepad
                huge
                uppercase
                center-align
            >
                {course.launch ? "Status: Launched" : "Status: Draft"}
            </Div>
            <Div
                fluid
                padTop
                uppercase
                altText
                center-align
                flex
                top-align
                spaceBetween
            >
                <Div large>{t("analytics.avCompletion")}</Div>
                <Div wd="80%">
                    <Progress percent={avCompletion} indicating />
                </Div>
            </Div>
        </Div>
    );
}
