// Import from NPM
// -------------------------------------
import React, { useState } from "react";
import _ from "lodash";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { Button } from "@components/Generics.react";
import { Input } from "semantic-ui-react";

// Import from Config
// -------------------------------------
import { baseUrl } from "@api/apiV6";
import AuthLayout from "@layouts/AuthLayout.react";
import { Div } from "@components/Generics.react";

// Import Components
// -------------------------------------

export default function ForgotPassword(props) {
    const [email, setEmail] = useState("");
    const [resetSent, setResetSent] = useState(false);
    const [error, setError] = useState(null);

    const tryReset = async () => {
        try {
            await axios({
                method: "post",
                url: `${baseUrl}api/v3/auth/reset_password`,
                data: JSON.stringify({
                    email: _.chain(email).trim().toLower().value(),
                }),
                headers: { "Content-Type": "application/json" },
            });
            setResetSent(true);
        } catch (error) {
            setError(error);
            console.log(error);
        }
    };

    // ========================= Render Function =================================
    return (
        <AuthLayout title="FORGOT PASSWORD?">
            <Div fluid white fullHt>
                <Div massive altText fluid center-align gutter>
                    FORGOT PASSWORD?
                </Div>
                <br />
                {error && (
                    <Div padded fluid danger gutter>
                        Error resetting password. Please contact your admin.
                    </Div>
                )}
                {resetSent ? (
                    <Div fluid gutter>
                        The reset link has been sent. Please check your email.
                    </Div>
                ) : (
                    <Div fluid gutter>
                        Please give us your email address and we will send you a
                        reset link...
                        <br />
                        <br />
                        <Input
                            fluid
                            label="Email Address"
                            placeholder="Input Email Address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <br />
                        <Button
                            fluid
                            primary
                            content="SUBMIT"
                            onClick={tryReset}
                        />
                    </Div>
                )}
                <br />
                <NavLink to="/login">Login now...</NavLink>
            </Div>
        </AuthLayout>
    );
}
