import React, { useState, useEffect } from "react";
import { Loader } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useFeatures } from "flagged";
import _ from "lodash";
import "@styles/components.scss";

import { MapList, PickedLocations } from "@schemas/programMapSchema";
import MasterLayout from "@layouts/MasterLayout.react";
import { useGetProgramQuery, useGetCourseLibraryQuery } from "@api/apiV6";
import FormalProgram from "./panels/FormalProgram.react";
import GamifiedProgram from "./panels/GamifiedProgram.react";
import CourseCard from "@components/CourseCard.react";
import { Div } from "@components/Generics.react";

export default function SingleProgram(props) {
    const { programId } = useParams();
    const { data: library, isSuccess } = useGetCourseLibraryQuery();

    // ========================= Render Function =================================
    if (isSuccess)
        return (
            <MasterLayout
                title={"Welcome"}
                mainPanel={
                    programId != null ? (
                        <FlatProgram {...{ programId, library }} />
                    ) : (
                        <IsolatedProgram {...{ library }} />
                    )
                }
                isolated={true}
                rootPage={true}
            />
        );
    else return <Loader />;
}

function FlatProgram(props) {
    const { library, programId } = props;
    const { data: program, isSuccess } = useGetProgramQuery(programId);
    const { t } = useTranslation("common");
    const features = useFeatures();
    const hasWrapper = program?.wrapper !== "none" && MapList[program?.wrapper];
    const [activeLoc, setActiveLoc] = useState(null);
    const isPortrait = window.innerHeight > window.innerWidth;

    const filteredNodeList = _.map(
        _.filter(
            program?.node_list,
            (crs) => _.includes(_.keys(library.courses), crs._id) && crs.launch
        ),
        (crs) => library.courses[crs._id]
    );

    useEffect(() => {
        if (program) {
            updateLocData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [program]);

    function updateLocData() {
        // Set Maximum Locations allowed in a wrapper
        const maxLocations = 12;
        // Pick the appropriate location array [1,3,5] based on the number of locations with content in the course
        const locationMap = PickedLocations[program.node_list.length];

        // Create the location array
        let lData = [];
        if (locationMap) {
            for (let i = 0; i < maxLocations; i++) {
                let mapIndex = locationMap.indexOf(i); // The index of this node amongst the highlighted locations
                if (mapIndex !== -1) {
                    lData.push(program.node_list[mapIndex]);
                } else {
                    // If this location is disabled
                    lData.push(null);
                }
            }
        }
        // setLocData(lData);
        if (activeLoc == null && lData.length > 0) {
            setActiveLoc(0);
        }
    }

    const courseListing = (
        <Div>
            <Div altText huge gutter spaced>
                {t("library.title")}
            </Div>
            {_.map(library?.courses, (course, idx) => (
                <Div
                    key={`course-card-${idx}`}
                    className="course-card-item"
                    wd={isPortrait ? "100%" : "47%"}
                    float-left={!isPortrait}
                    big={!isPortrait}
                    spaced={!isPortrait}
                    gapBottom={isPortrait}
                >
                    <CourseCard
                        course={{
                            ...course,
                            ...{
                                currentState:
                                    library?.courses[course._id].currentState,
                            },
                        }}
                        isLocked={false}
                        targetIsolated
                    />
                </Div>
            ))}
        </Div>
    );

    const pageContent = isSuccess ? (
        hasWrapper ? (
            filteredNodeList &&
            filteredNodeList.length > 0 && (
                <GamifiedProgram
                    {...{
                        program: { ...program, node_list: filteredNodeList },
                        library,
                        locData: filteredNodeList,
                    }}
                />
            )
        ) : (
            filteredNodeList &&
            filteredNodeList.length > 0 && (
                <FormalProgram
                    program={{ ...program, node_list: filteredNodeList }}
                    library={library}
                />
            )
        )
    ) : (
        <Loader active inverted>
            Loading Content...
        </Loader>
    );

    // ========================= Render Function =================================
    return (
        <Div basepad={!isPortrait}>
            {features.programs.noPrograms ? courseListing : pageContent}
        </Div>
    );
}

function IsolatedProgram(props) {
    const { library } = props;
    const { t } = useTranslation("common");
    const isPortrait = window.innerHeight > window.innerWidth;

    const courseListing = (
        <Div basepad>
            <Div altText huge gutter spaced>
                {t("library.title")}
            </Div>
            {_.map(
                _.filter(library?.courses, { launch: true }),
                (course, idx) => (
                    <Div
                        key={`course-card-${idx}`}
                        className="course-card-item"
                        wd={isPortrait ? "100%" : "48%"}
                        float-left={!isPortrait}
                        large={!isPortrait}
                        spaced={!isPortrait}
                        gapBottom
                    >
                        <CourseCard
                            course={{
                                ...course,
                                ...{
                                    currentState:
                                        library?.courses[course._id]
                                            .currentState,
                                },
                            }}
                            isLocked={false}
                            targetIsolated
                        />
                    </Div>
                )
            )}
        </Div>
    );

    // ========================= Render Function =================================
    return <Div superpad={!isPortrait}>{courseListing}</Div>;
}
