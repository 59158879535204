import React from "react";
import _ from "lodash";
import { Grid } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import { Div } from "@components/Generics.react";
import LocationMenu from "@components/LocationMenu.react";
import Banner from "@components/Banner.react";
import TopicCard from "@components/TopicCard.react";
import CourseHeader from "./CourseHeader.react";
import Carousel from "@components/Carousel.react";
export default function FormalWrapper(props) {
    const {
        locData,
        setActiveLoc,
        activeLoc,
        course,
        showMenu,
        closeMenu,
        openDeck,
        courseHeader = true,
    } = props;
    const isPortrait = window.innerHeight > window.innerWidth;
    const { t } = useTranslation("common");

    const hasCurrent =
        _.map(locData, "status").includes("current") || activeLoc;
    const imageSrc = !course?.image
        ? "/assets/images/configurable/missing.jpg"
        : course.image;

    function showDeck(deckId, targetReaderType) {
        setActiveLoc(activeLoc);
        openDeck(deckId, targetReaderType);
    }

    const selectHeader = (
        <Div fit-content primary circled widePad centered gutter bold big>
            {t("course.selectTopic")}
        </Div>
    );

    const formalMap = {
        none: (
            <Grid stackable divided="vertically">
                <Grid.Row>
                    <Grid.Column width={isPortrait ? 16 : 8}>
                        {courseHeader && (
                            <CourseHeader course={course} compact />
                        )}
                        <Banner ads={[{ image: imageSrc }]} />
                        <br />
                        {selectHeader}
                        <Div rounded noOverflow slightShadow>
                            {locData.map((thisLoc, locIndex) => {
                                if (thisLoc != null) {
                                    return (
                                        <TopicCard
                                            key={`topic-${locIndex}`}
                                            locIndex={locIndex}
                                            colors={course.colors}
                                            topic={_.omit(thisLoc, "status")}
                                            status={
                                                (!hasCurrent &&
                                                    locIndex === 0) ||
                                                activeLoc === locIndex
                                                    ? "lastopen"
                                                    : thisLoc.status
                                            }
                                            clickable={
                                                !course.sequential ||
                                                thisLoc.status !== "incomplete"
                                            }
                                            handleClick={setActiveLoc}
                                        />
                                    );
                                } else {
                                    return (
                                        <Div
                                            key={"location-" + locIndex}
                                            className="no-loc"
                                        />
                                    );
                                }
                            })}
                        </Div>
                    </Grid.Column>
                    <Grid.Column width={8}></Grid.Column>
                </Grid.Row>
            </Grid>
        ),
        stacked_cards: (
            <Grid stackable divided="vertically">
                <Grid.Row>
                    <Grid.Column width={isPortrait ? 16 : 8}>
                        {courseHeader && (
                            <CourseHeader course={course} compact />
                        )}
                        {selectHeader}
                        {locData.map((thisLoc, locIndex) => {
                            if (thisLoc != null) {
                                return (
                                    <TopicCard
                                        key={`topic-${locIndex}`}
                                        locIndex={locIndex}
                                        colors={course.colors}
                                        topic={_.omit(thisLoc, "status")}
                                        status={
                                            (!hasCurrent && locIndex === 0) ||
                                            activeLoc === locIndex
                                                ? "lastopen"
                                                : thisLoc.status
                                        }
                                        clickable={
                                            !course.sequential ||
                                            thisLoc.status !== "incomplete"
                                        }
                                        handleClick={setActiveLoc}
                                        cardStyle
                                        descriptions
                                    />
                                );
                            } else {
                                return (
                                    <Div
                                        key={"location-" + locIndex}
                                        className="no-loc"
                                    />
                                );
                            }
                        })}
                    </Grid.Column>
                    <Grid.Column width={8}></Grid.Column>
                </Grid.Row>
            </Grid>
        ),
        stacked_images: (
            <Grid stackable divided="vertically">
                <Grid.Row>
                    <Grid.Column width={isPortrait ? 16 : 8}>
                        {courseHeader && (
                            <CourseHeader course={course} compact />
                        )}
                        {selectHeader}
                        {locData.map((thisLoc, locIndex) => {
                            if (thisLoc != null) {
                                return (
                                    <TopicCard
                                        key={`topic-${locIndex}`}
                                        locIndex={locIndex}
                                        colors={course.colors}
                                        topic={_.omit(thisLoc, "status")}
                                        status={
                                            (!hasCurrent && locIndex === 0) ||
                                            activeLoc === locIndex
                                                ? "lastopen"
                                                : thisLoc.status
                                        }
                                        clickable={
                                            !course.sequential ||
                                            thisLoc.status !== "incomplete"
                                        }
                                        handleClick={setActiveLoc}
                                        cardStyle
                                    />
                                );
                            } else {
                                return (
                                    <Div
                                        key={"location-" + locIndex}
                                        className="no-loc"
                                    />
                                );
                            }
                        })}
                    </Grid.Column>
                    <Grid.Column width={8}></Grid.Column>
                </Grid.Row>
            </Grid>
        ),
        swipeable_cards: (
            <Grid stackable divided="vertically">
                <Grid.Row>
                    <Grid.Column width={isPortrait ? 16 : 8}>
                        {courseHeader && (
                            <CourseHeader course={course} compact />
                        )}
                        {selectHeader}
                        <Carousel slidesToShow={1} enableButtons>
                            {locData.map((thisLoc, locIndex) => {
                                if (thisLoc != null) {
                                    return (
                                        <Div padded key={`topic-${locIndex}`}>
                                            <TopicCard
                                                locIndex={locIndex}
                                                colors={course.colors}
                                                topic={_.omit(
                                                    thisLoc,
                                                    "status"
                                                )}
                                                status={
                                                    (!hasCurrent &&
                                                        locIndex === 0) ||
                                                    activeLoc === locIndex
                                                        ? "lastopen"
                                                        : thisLoc.status
                                                }
                                                clickable={
                                                    !course.sequential ||
                                                    thisLoc.status !==
                                                        "incomplete"
                                                }
                                                handleClick={setActiveLoc}
                                                cardStyle
                                                descriptions
                                            />
                                        </Div>
                                    );
                                } else {
                                    return undefined;
                                }
                            })}
                        </Carousel>
                    </Grid.Column>
                    <Grid.Column width={8}></Grid.Column>
                </Grid.Row>
            </Grid>
        ),
        feed_cards: (
            <Grid stackable divided="vertically">
                <Grid.Row>
                    <Grid.Column width={isPortrait ? 16 : 8}>
                        {courseHeader && (
                            <CourseHeader course={course} compact />
                        )}
                        <Banner ads={[{ image: imageSrc }]} />
                        <br />
                        {selectHeader}
                        {locData.map((thisLoc, locIndex) => {
                            if (thisLoc != null) {
                                return (
                                    <TopicCard
                                        key={`topic-${locIndex}`}
                                        locIndex={locIndex}
                                        colors={course.colors}
                                        topic={_.omit(thisLoc, "status")}
                                        status={
                                            (!hasCurrent && locIndex === 0) ||
                                            activeLoc === locIndex
                                                ? "lastopen"
                                                : thisLoc.status
                                        }
                                        clickable={
                                            !course.sequential ||
                                            thisLoc.status !== "incomplete"
                                        }
                                        handleClick={setActiveLoc}
                                        feedStyle
                                    />
                                );
                            } else {
                                return (
                                    <Div
                                        key={"location-" + locIndex}
                                        className="no-loc"
                                    />
                                );
                            }
                        })}
                    </Grid.Column>
                    <Grid.Column width={8}></Grid.Column>
                </Grid.Row>
            </Grid>
        ),
        background_listing: (
            <Grid stackable divided="vertically">
                <Grid.Row>
                    <Grid.Column width={isPortrait ? 16 : 8}>
                        {courseHeader && (
                            <CourseHeader course={course} compact />
                        )}
                        <Div
                            pageht
                            fluid
                            relative
                            noOverflow
                            style={{
                                backgroundImage: `url(${imageSrc})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center center",
                            }}
                        >
                            <Div
                                absolute
                                fluid
                                rounded
                                noOverflow
                                slightShadow
                                white
                                big
                                style={{
                                    right: "-40px",
                                    top: "30%",
                                    background: "rgba(0,0,0,0.8)",
                                    color: "#ffffff",
                                }}
                            >
                                {locData.map((thisLoc, locIndex) => {
                                    if (thisLoc != null) {
                                        return (
                                            <TopicCard
                                                key={`topic-${locIndex}`}
                                                locIndex={locIndex}
                                                colors={course.colors}
                                                topic={_.omit(
                                                    thisLoc,
                                                    "status"
                                                )}
                                                status={
                                                    (!hasCurrent &&
                                                        locIndex === 0) ||
                                                    activeLoc === locIndex
                                                        ? "lastopen"
                                                        : thisLoc.status
                                                }
                                                clickable={
                                                    !course.sequential ||
                                                    thisLoc.status !==
                                                        "incomplete"
                                                }
                                                handleClick={setActiveLoc}
                                            />
                                        );
                                    } else {
                                        return (
                                            <Div
                                                key={"location-" + locIndex}
                                                className="no-loc"
                                            />
                                        );
                                    }
                                })}
                            </Div>
                        </Div>
                    </Grid.Column>
                    <Grid.Column width={8}></Grid.Column>
                </Grid.Row>
            </Grid>
        ),
    };

    // ========================= Render Function =================================
    return (
        <Div
            fullht={isPortrait && showMenu}
            relaxed={!isPortrait}
            noOverflow={isPortrait && showMenu}
            className="ok"
        >
            {formalMap[course.wrapper] != null
                ? formalMap[course.wrapper]
                : formalMap["none"]}
            {isPortrait && showMenu && (
                <Div fullht className="overlay loc-menu" />
            )}
            <Div fullht>
                <LocationMenu
                    sequential={course.sequential}
                    showMenu={!isPortrait || showMenu}
                    content={locData[activeLoc]}
                    openDeck={showDeck}
                    closeMenu={isPortrait && closeMenu}
                />
            </Div>
        </Div>
    );
}
