import React from "react";
import {
    ScatterChart as QScatterChart,
    Scatter,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";

export default function ScatterChart(props) {
    const {
        height = 200,
        data,
        series,
        color = "#8884d8",
        customTooltip = null,
    } = props;

    return (
        <ResponsiveContainer width="96%" height={height}>
            <QScatterChart margin={{ top: 10, bottom: 25 }}>
                <CartesianGrid />
                <XAxis
                    label={{
                        value: series[0].name,
                        position: "bottom",
                    }}
                    type={series[0].type}
                    dataKey={series[0].dataKey}
                    name={series[0].name}
                    unit={series[0].unit}
                />
                <YAxis
                    label={{
                        value: series[1].name,
                        angle: -90,
                        position: "insideLeft",
                    }}
                    type={series[1].type}
                    dataKey={series[1].dataKey}
                    name={series[1].name}
                    unit={series[1].unit}
                />
                {customTooltip !== null ? (
                    <Tooltip content={customTooltip} />
                ) : (
                    <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                )}
                <Scatter name="Scatter" data={data} fill={color} />
            </QScatterChart>
        </ResponsiveContainer>
    );
}

// Data Format
// -------------------------------
/*
    <ScatterChart
        color={"#ffbf00"}
        data={{
            "completion": 100,
            "score": 50
        }}
        series={[
            {
                type: "number",
                dataKey: "completion",
                name: "Coverage",
                unit: "%",
            },
            {
                type: "number",
                dataKey: "score",
                name: "Score",
                unit: "%",
            },
        ]}
    />
*/
