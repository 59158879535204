import React from "react";
import { useGetContestLearnersQuery } from "@api/apiV6";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import ReactTable from "react-table-v6";

import { Icon, Loader } from "semantic-ui-react";
import { Div } from "@components/Generics.react";
export default function LearnerTable(props) {
    const { contest, search } = props;
    const { data: contestLearners, isSuccess } = useGetContestLearnersQuery(
        contest._id
    );
    const { t } = useTranslation("common");

    const selectedLearners = isSuccess
        ? _.filter(
              contestLearners,
              (learner) =>
                  learner.first_name.includes(search) ||
                  learner.last_name.includes(search) ||
                  learner.username.includes(search)
          )
        : [];

    const fullColumns = [
        {
            Header: t("builder.dic.name"),
            accessor: "name",
            Cell: (row) =>
                `${row.original.first_name} ${row.original.last_name}`,
        },
        {
            Header: t("builder.dic.username"),
            accessor: "username",
        },
        {
            Header: t("builder.dic.actions"),
            accessor: "_id",
            width: 80,
            Cell: (row) => (
                <Div fluid center-align clickable>
                    <Icon
                        name="bar chart"
                        onClick={() => null}
                        title={t("components.analytics")}
                    />
                    <Div txtDanger inline>
                        <Icon
                            name="trash"
                            onClick={() => null}
                            title={t("components.delete")}
                            disabled={row._id === contest.owner}
                        />
                    </Div>
                </Div>
            ),
        },
    ];

    // ========================= Render Function =================================
    return (
        <Div>
            {isSuccess ? (
                <ReactTable
                    key={`table-${search}`}
                    data={selectedLearners}
                    filterable
                    columns={fullColumns}
                    pageSizeOptions={[10, 15, 30]}
                    defaultPageSize={10}
                    className="-striped -highlight"
                />
            ) : (
                <Loader />
            )}
        </Div>
    );
}
