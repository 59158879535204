// Import from NPM
// -------------------------------------
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "lodash";

import { Div } from "@components/Generics.react";
import { Input, Dropdown } from "semantic-ui-react";
export default function LinkField(props) {
    const {
        target,
        header,
        help,
        value,
        setValue,
        height,
        maxLength,
        flatField = false,
    } = props;
    const articles = useSelector((state) => state.articles);

    const [isMounted, setIsMounted] = useState(false);
    const [inputValue, setInputValue] = useState(
        target && value && !flatField ? value[target] : value
    );
    const { t } = useTranslation("common");

    // Debouncing the user input
    //==================================
    useEffect(() => {
        setIsMounted(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (isMounted)
                setValue(
                    target && !flatField ? { [target]: inputValue } : inputValue
                );
        }, 500);
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);

    // ========================= Render Function =================================
    return (
        <Div minHt={height} ht={height} ivory medpad={!flatField} snug>
            {header && (
                <Div big rimmed>
                    {inputValue && maxLength && (
                        <Div
                            small
                            float-right
                            compact
                            slightShadow
                            ash={inputValue.length < maxLength}
                            danger={inputValue.length > maxLength}
                            style={{ marginTop: "-10px" }}
                        >
                            {inputValue.length}/{maxLength}
                        </Div>
                    )}
                    {header}
                    {help && (
                        <Div tiny italics txtHalf gapSlice>
                            {help}
                        </Div>
                    )}
                </Div>
            )}
            <Div ivory ht={"calc(100% - 60px)"} fluid>
                <Input
                    fluid
                    value={inputValue || ""}
                    onChange={(e) => setInputValue(e.target.value)}
                    label={
                        <Dropdown
                            search
                            placeholder={t("builder.article.selectArticle")}
                            options={_.map(articles.list, (a) => ({
                                key: a._id,
                                text: a.name,
                                value: `/${a.category}/${a.identifier}`,
                            }))}
                            onChange={(e, { value }) => setInputValue(value)}
                            className="link-input-field"
                        />
                    }
                />
            </Div>
        </Div>
    );
}
