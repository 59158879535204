import React from "react";
import { useParams } from "react-router-dom";
import {
    useUpdateCourseMutation,
    useLaunchCourseMutation,
    useUnlaunchCourseMutation,
    useUpdateDeckMutation,
    useMoveDeckMutation,
    useDeleteDeckMutation,
    useDeleteTopicMutation,
    useGetCourseBuilderQuery,
    useSendTeamEnrolMutation
} from "@api/apiV6";
import { useTranslation } from "react-i18next";

import { Loader } from "semantic-ui-react";
import CreatorLayout from "@layouts/CreatorLayout.react";
import CourseSetup from "./panels/CourseSetup.react";
import CourseContent from "./panels/CourseContent.react";
import CourseLearners from "./panels/CourseLearners.react";
import CourseAnalytics from "./panels/CourseAnalytics.react";

export default function CourseEditor(props) {
    const { id, section } = useParams();
    const { data: course, isSuccess } = useGetCourseBuilderQuery(id);
    const [updateCourse] = useUpdateCourseMutation();
    const [deleteTopic] = useDeleteTopicMutation();
    const [updateDeck] = useUpdateDeckMutation();
    const [moveDeck] = useMoveDeckMutation();
    const [deleteDeck] = useDeleteDeckMutation();
    const [launchCourse] = useLaunchCourseMutation();
    const [unlaunchCourse] = useUnlaunchCourseMutation();
    const [enrolTeam] = useSendTeamEnrolMutation();
    const { t } = useTranslation("common");

    const setupPanel = isSuccess && (
        <CourseSetup course={course} updateCourse={updateCourse} />
    );
    const contentPanel = isSuccess && (
        <CourseContent
            course={course}
            updateCourse={updateCourse}
            updateDeck={updateDeck}
            moveDeck={moveDeck}
            deleteDeck={deleteDeck}
            deleteTopic={deleteTopic}
        />
    );
    const learnersPanel = isSuccess && (
        <CourseLearners
            course={course}
            updateCourse={updateCourse}
            launchCourse={launchCourse}
            unlaunchCourse={unlaunchCourse}
            enrolTeam={enrolTeam}
        />
    );
    const analyticsPanel = isSuccess && <CourseAnalytics course={course} />;
    const panelMap = {
        setup: setupPanel,
        editor: contentPanel,
        learners: learnersPanel,
        analytics: analyticsPanel,
        // Add more sections and their respective panels as needed
    };

    // ========================= Render Function =================================
    if (isSuccess)
        return (
            <CreatorLayout
                title={`Editing: ${course.name}`}
                mainPanel={panelMap[section]}
                navName={`${t(
                    `components.${
                        course.course_type === "exam" ? "exam" : "course"
                    }`
                )} ${section}`}
                pageCode={"library"}
                rootPage={false}
                editMode={true}
            />
        );
    else return <Loader active>{t("components.loading")}</Loader>;
}
