import React, { useContext } from "react";
import _ from "lodash";
import { clientAssetsPath } from "@api/apiV6";
import { Div, Image } from "@components/Generics.react";
import Carousel from "@components/Carousel.react";
import { LayoutContext } from "@layouts/MasterLayout.react";

export default function Triptych(props) {
    const { diptych } = props.data;
    const { fullScreen } = useContext(LayoutContext);
    const isPortrait = window.innerHeight > window.innerWidth || !fullScreen;

    return (
        <Div wd={isPortrait ? "100%" : "33%"} zoom centered={!isPortrait}>
            <Carousel slidesToShow={1} enableButtons>
                {_.map(diptych, (card, idx) => {
                    return (
                        <Div
                            key={`list-item-${idx}`}
                            slightShadow
                            left-aligned
                            rounded={!isPortrait}
                            noOverflow
                        >
                            {card?.image && (
                                <Image
                                    fluid
                                    src={
                                        _.startsWith(card.image, "data:image")
                                            ? card.image
                                            : `${clientAssetsPath}/uploads/qdf/slides/${card.image.id}${card.image.extension}`
                                    }
                                />
                            )}
                            <Div superpad ash big={isPortrait}>
                                {card.text || card.caption}
                            </Div>
                        </Div>
                    );
                })}
            </Carousel>
        </Div>
    );
}
