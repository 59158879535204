import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Grid, Divider } from "semantic-ui-react";

import "@styles/components.scss";

import { Div } from "@components/Generics.react";
import Banner from "@components/Banner.react";
import CourseCard from "@components/CourseCard.react";
import { clientAssetsPath } from "@api/apiV6";
import FilterField from "@components/FilterField.react";

export default function FormalProgram(props) {
    const { program, library } = props;
    const [courses, setCourses] = useState(program?.node_list);

    useEffect(() => {
        setCourses(
            program?.sequential
                ? _.orderBy(program?.node_list, ["priority"], ["desc"])
                : _.orderBy(
                      program?.node_list,
                      ["priority", "createdAt"],
                      ["desc", "desc"]
                  )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [program]);

    const imageExtensions = ["png", "jpeg", "jpg"];
    const extension = program?.image?.split(".").pop().toLowerCase();
    const imageUrl = `${clientAssetsPath}/uploads/images/program/${program?._id}.${extension}`;
    const imageSrc = !imageExtensions.includes(extension)
        ? "/assets/images/configurable/missing.jpg"
        : imageUrl;

    const isPortrait = window.innerHeight > window.innerWidth;

    // ========================= Render Function =================================
    if (program.sequential)
        return (
            <Div trench>
                <Grid stackable divided="vertically">
                    <Grid.Row>
                        {program?.image && (
                            <Grid.Column width={8}>
                                {program?.image && (
                                    <Banner ads={[{ image: imageSrc }]} />
                                )}
                                <Divider />
                                <Div headline altText gutter>
                                    {program?.name}
                                </Div>
                                <Div>{program?.description}</Div>
                            </Grid.Column>
                        )}
                        <Grid.Column width={program?.image ? 8 : 16}>
                            {_.map(courses, (course, idx) => {
                                return (
                                    <Div
                                        padded
                                        key={`course-card-${idx}`}
                                        className="course-card-item"
                                    >
                                        <CourseCard
                                            course={{
                                                ...course,
                                                ...{
                                                    currentState:
                                                        library.courses[
                                                            course?._id
                                                        ]?.currentState,
                                                },
                                            }}
                                            isLocked={
                                                idx > 0
                                                    ? !library.courses[
                                                          courses[idx - 1]?._id
                                                      ]?.currentState
                                                          ?.completion
                                                    : false
                                            }
                                        />
                                    </Div>
                                );
                            })}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Div>
        );
    else
        return (
            <Div trench>
                <Grid stackable divided="vertically">
                    <Grid.Row>
                        {program?.image && (
                            <Grid.Column width={8}>
                                <Banner ads={[{ image: imageSrc }]} />
                            </Grid.Column>
                        )}
                        <Grid.Column width={program?.image ? 8 : 16}>
                            <Div headline altText gutter>
                                {program?.name}
                            </Div>
                            <Div>{program?.description}</Div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Div gutter>
                    <Divider horizontal>
                        <FilterField
                            srcData={program?.node_list}
                            setResult={setCourses}
                            searchKey="name"
                        />
                    </Divider>
                </Div>
                <Div padded>
                    <Grid
                        stackable
                        divided="vertically"
                        columns={isPortrait ? 1 : 2}
                    >
                        <Grid.Row>
                            {_.map(courses, (course, idx) => {
                                return (
                                    <Grid.Column
                                        key={`course-card-${idx}`}
                                        className="course-card-item"
                                    >
                                        <CourseCard
                                            course={{
                                                ...course,
                                                ...{
                                                    currentState:
                                                        library.courses[
                                                            course?._id
                                                        ]?.currentState,
                                                },
                                            }}
                                        />
                                    </Grid.Column>
                                );
                            })}
                        </Grid.Row>
                    </Grid>
                </Div>
            </Div>
        );
}
