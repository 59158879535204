// Import from NPM
// -------------------------------------
import React from "react";
import _ from "lodash";
import { Div } from "@components/Generics.react";
import SubformBlock from "./formComponents/SubformBlock.react";

export default function FormBuilder(props) {
    const { deck, setDeck, fields } = props;

    function transpileDeck(data) {
        const transpiled =
            typeof data === "string" ||
            data instanceof String ||
            _.isArray(data)
                ? { ...deck, ...{ content: data } }
                : { ...deck, ...{ content: { ...deck.content, ...data } } };
        setDeck(transpiled);
    }
    // ========================= Render Function =================================
    return (
        <Div clearfix fluid>
            {deck.content != null &&
                _.map(fields, (field, idx) => {
                    const noWidth = field.width == null;
                    if (_.isArray(field.formField)) {
                        return (
                            <Div
                                key={`form-field-${idx}`}
                                wd={field.width}
                                float-left={!noWidth}
                                clear={noWidth}
                                snug={noWidth}
                                gapBottom
                            >
                                <SubformBlock
                                    field={field}
                                    hasStructure={
                                        typeof field.formField[0] === "object"
                                    }
                                    value={
                                        field.target && deck?.content
                                            ? deck?.content[field.target]
                                            : deck?.content
                                    }
                                    setValue={transpileDeck}
                                />
                            </Div>
                        );
                    } else {
                        const FieldComponent = field.formField;
                        return (
                            <Div
                                key={`form-field-${idx}`}
                                wd={field.width}
                                float-left={field.width != null}
                                clear={noWidth}
                                snug={noWidth}
                                gapBottom
                            >
                                <FieldComponent
                                    {...field}
                                    value={deck.content}
                                    setValue={transpileDeck}
                                />
                            </Div>
                        );
                    }
                })}
        </Div>
    );
}
