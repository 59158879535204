import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Tab } from "semantic-ui-react";
import {
    useGetCourseLearnersDataQuery,
    useGetVersionListQuery,
} from "@api/apiV6";
import abilityMap from "@config/features/ability.json";
import CourseSummary from "./panels/courses/CourseSummary.react";
import CourseLearners from "./panels/courses/CourseLearners.react";
import CourseContent from "./panels/courses/CourseContent.react";
import CourseQuizzes from "./panels/courses/CourseQuizzes.react";
import CourseSurveys from "./panels/courses/CourseSurveys.react";
import CourseGames from "./panels/courses/CourseGames.react";
import VersionList from "../courses/panels/VersionList.react";
import CourseAssignments from "./panels/courses/CourseAssignments.react";

export default function CourseDashboard(props) {
    const { course } = props;
    const user = useSelector((state) => state.auth.user);
    const {
        data: audienceData,
        isSuccess,
        refetch,
    } = useGetCourseLearnersDataQuery({
        userId: user._id,
        courseId: course._id,
        scope: _.includes(
            abilityMap.analytics.universal,
            user.license.license_type
        )
            ? "universe"
            : "team",
    });
    const { data: versionList, isSuccess: versionSuccess } =
        useGetVersionListQuery(course._id);
    const { t } = useTranslation("common");

    const contentMap = _(course.locations) // Start chaining
        .flatMap("contentList") // Flatten and map contentList in one go
        .countBy("readerType") // Count occurrences of readerType
        .value(); // Terminate the chain and get the resulting value

    const dashMap = {
        summary: CourseSummary,
        learners: CourseLearners,
        content: CourseContent,
        quizzes: contentMap.assessment && CourseQuizzes,
        surveys: contentMap.survey && CourseSurveys,
        games: contentMap.game && CourseGames,
        assignments: contentMap.assignment && CourseAssignments,
        versions: versionSuccess && versionList.length > 0 && VersionList,
    };

    const panes = isSuccess
        ? _.map(
              _.filter(_.keys(dashMap), (key) => dashMap[key]),
              (key) => ({
                  menuItem: t(`analytics.${key}`),
                  render: () => {
                      const PaneComponent = dashMap[key];
                      return (
                          <Tab.Pane>
                              <PaneComponent
                                  course={course}
                                  contentMap={contentMap}
                                  courseData={audienceData}
                                  refetch={refetch}
                              />
                          </Tab.Pane>
                      );
                  },
              })
          )
        : [];

    return <Tab panes={panes} />;
}
