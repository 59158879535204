import React, { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import ReactTable from "react-table-v6";
import _ from "lodash";
import { Loader } from "semantic-ui-react";
import { Icon } from "semantic-ui-react";
import { Div, Button } from "@components/Generics.react";

import { useGetCourseLeaderboardQuery } from "@api/apiV6";
import { useParams } from "react-router-dom";

export default function CourseLeaderboard(props) {
    const { id } = useParams();
    const reactTableRef = useRef(null);
    const {
        data: courseData,
        isSuccess: isLogDataSuccess,
        isError: isLogDataError,
        isLoading: isLogDataLoading,
        refetch,
    } = useGetCourseLeaderboardQuery(id);
    const { t } = useTranslation("common");

    const sortedData = useMemo(() => {
        return _.orderBy(
            courseData,
            ["points", "completionDate"],
            ["desc", "desc"]
        );
    }, [courseData]);

    let fullColumns = [
        {
            Header: "Name",
            accessor: "name",
            Cell: (row) =>
                `${row.original.first_name} ${row.original.last_name}`,
            filterMethod: (filter, row) =>
                _.includes(
                    `${row._original.first_name} ${row._original.last_name}`.toLowerCase(),
                    filter.value.toLowerCase()
                ),
        },
        {
            Header: "Points",
            id: "points",
            accessor: (d) => d.points,
            style: { textAlign: "center" },
        },
        {
            Header: "Completion",
            id: "completion",
            accessor: (d) => d.completion,
            Cell: ({ value }) => (value ? "Yes" : "No"),
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                }
                if (filter.value === "true") {
                    return row[filter.id];
                }
                return !row[filter.id];
            },
            Filter: ({ filter, onChange }) => (
                <select
                    onChange={(event) => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">Show All</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </select>
            ),
            style: { textAlign: "center" },
        },
    ];
    const downloadCSV = () => {
        const items = _.map(
            reactTableRef.current.getResolvedState().sortedData,
            "_original"
        );

        if (items.length > 0) {
            const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
            const header = Object.keys(items[0]);
            let csv = items.map((row) =>
                header
                    .map((fieldName) =>
                        JSON.stringify(row[fieldName], replacer)
                    )
                    .join(",")
            );
            csv.unshift(header.join(","));
            csv = csv.join("\r\n");
            var hiddenElement = document.createElement("a");
            hiddenElement.href =
                "data:text/csv;charset=utf-8," + encodeURI(csv);
            hiddenElement.target = "_blank";
            hiddenElement.download = "LeaderboardData.csv";
            hiddenElement.click();
        }
    };
    if (isLogDataLoading) {
        return <Loader />;
    } else if (isLogDataSuccess && !isLogDataError) {
        return (
            <Div white basepad>
                <Div basepad>
                    <Div flex spaceBetween gapTop gapBottom>
                        <Div bold left-align big uppercase>
                            <Div inline small clickable>
                                <Icon
                                    circular
                                    inverted
                                    name="sync"
                                    onClick={refetch}
                                />
                            </Div>{" "}
                            {t("analytics.leaderboard")}
                        </Div>
                        <Button
                            primary
                            small={+true}
                            onClick={downloadCSV}
                            icon="download"
                            labelPosition="right"
                            content="Download"
                        />
                    </Div>
                </Div>
                <ReactTable
                    ref={reactTableRef}
                    data={sortedData}
                    filterable
                    defaultFilterMethod={(filter, row, column) => {
                        const id = filter.pivotId || filter.id;
                        return row[id] !== undefined
                            ? String(row[id])
                                  .toLowerCase()
                                  .includes(filter.value.toLowerCase())
                            : true;
                    }}
                    columns={fullColumns}
                    pageSizeOptions={[5, 15, 30, 50]}
                    defaultPageSize={15}
                    style={{
                        height: `${window.innerHeight - 180}px`, // This will force the table body to overflow and scroll, since there is not enough room
                    }}
                    className="-striped -highlight"
                />
            </Div>
        );
    } else {
        return <Div>Error</Div>;
    }
}
