import React from "react";
import _ from "lodash";
import { Div } from "@components/Generics.react";
import { clientAssetsPath } from "@api/apiV6";
import "@styles/readers.scss";

export default function Conversation(props) {
    const { conversation, avatars } = props.data;
    const convavatars = _.map(avatars, (image) => {
        return image.avatars && !_.startsWith(image.avatars, "data:image")
            ? `${clientAssetsPath}/uploads/qdf/slides/${image.avatars.id}${image.avatars.extension}`
            : image.avatars;
    });

    return (
        <Div basepad className="conversation">
            {_.map(conversation, (message, index) => (
                <Div
                    key={index}
                    className={`message ${index % 2 === 0 ? "theirs" : "mine"}`}
                >
                    <Div className={`conversation-avatar ${index % 2 === 0 ? "theirs" : "mine"}`}>
                        <img src={convavatars[index % 2]} alt="Avatar" />
                    </Div>
                    <Div className="message-content">
                        {message.text || message.conversation}
                    </Div>
                </Div>
            ))}
        </Div>
    );
}
