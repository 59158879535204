import React, { useState } from "react";
import { CompactPicker } from "react-color";
import { Div } from "@components/Generics.react";

export default function ColorBlock(props) {
    const { color, colorType, colorName, onChange } = props;
    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    const handleChangeComplete = (color) => {
        onChange(colorType, color);
    };

    const popover = {
        position: "absolute",
        zIndex: "99999999",
    };

    const cover = {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
    };

    const previewColor = {
        width: "30px",
        height: "15px",
        display: "inline-block",
        background: color,
        verticalAlign: "middle",
        marginRight: "5px",
        border: "1px solid #ccc",
    };

    return (
        <Div clickable>
            <Div style={previewColor} onClick={handleClick} />
            <Div inlineBlock onClick={handleClick}>
                {colorName}
            </Div>
            {displayColorPicker && (
                <Div style={popover}>
                    <Div style={cover} onClick={handleClose} />
                    <CompactPicker
                        color={color}
                        onChangeComplete={handleChangeComplete}
                    />
                </Div>
            )}
        </Div>
    );
}
