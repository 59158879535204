import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSendBannerMutation, useCreateBannerMutation } from "@api/apiV6";
import { toast } from "react-toastify";

import { Div, Button } from "@components/Generics.react";
import { Modal, Icon, Input, Image, Checkbox } from "semantic-ui-react";
import ImageUploader from "@components/ImageUploader.react";

export default function EditBannerModal(props) {
    const { isOpen, setOpen, banner } = props;
    const { t } = useTranslation("common");

    const [name, setName] = useState(banner ? banner.name : "");
    const [imgEditorOpen, setImgEditorOpen] = useState(false);
    const [image, setImage] = useState(banner ? banner.image : null);
    const [description, setDescription] = useState(
        banner ? banner.description : ""
    );
    const [priority, setPriority] = useState(banner ? banner.priority : "");
    const [btnLink, setBtnLink] = useState(banner ? banner.link : "");
    const [published, setPublished] = useState(
        banner ? banner.published : false
    );

    useEffect(() => {
        if (banner != null) {
            setName(banner.name);
            setDescription(banner.description);
            setPriority(banner.priority);
            setBtnLink(banner.link);
            setImage(banner.image);
            setPublished(banner.published);
        }
    }, [banner]);

    const [createBanner] = useCreateBannerMutation();
    const [editBanner] = useSendBannerMutation();

    async function tryUpdate() {
        if (banner) {
            const updateResponse = await editBanner({
                id: banner._id,
                name: name,
                description: description,
                priority: priority,
                link: btnLink,
                image: image,
                published: published,
            });
            if (updateResponse) {
                toast("Banner Updated");
                setOpen(false);
            }
        } else {
            const updateResponse = await createBanner({
                name: name,
                description: description,
                priority: priority,
                link: btnLink,
                image: image,
                published: published,
            });
            if (updateResponse) {
                toast("Banner Created");
                setOpen(false);
            }
        }
    }

    // ========================= Render Function =================================
    return (
        <Modal size="small" open={isOpen} onClose={() => setOpen(false)}>
            <Modal.Header>
                Manage Banner
                <Div float-right clickable>
                    <Icon name="times" onClick={() => setOpen(false)} />
                </Div>
            </Modal.Header>
            <Modal.Content>
                <Div white basepad>
                    <Div fluid gutter flex flexStart gapped>
                        {image && (
                            <Div wd="128px" snubbed noOverflow>
                                <Image src={image} fluid />
                            </Div>
                        )}
                        <Button
                            content={t("builder.dic.changeImage")}
                            secondary
                            onClick={() => setImgEditorOpen(true)}
                        />
                    </Div>
                    <Div fluid gutter>
                        <Input
                            fluid
                            label="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Div>
                    <Div fluid gutter>
                        <Input
                            fluid
                            label="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Div>
                    <Div fluid gutter>
                        <Input
                            fluid
                            type="number"
                            label="Priority"
                            value={priority}
                            onChange={(e) => setPriority(e.target.value)}
                        />
                    </Div>
                    <Div fluid trench>
                        <Input
                            fluid
                            label="Button Link"
                            value={btnLink}
                            onChange={(e) => setBtnLink(e.target.value)}
                        />
                    </Div>
                    <Div fluid smoke relaxed gutter>
                        <Checkbox
                            toggle
                            label="Published"
                            checked={published}
                            onChange={() => setPublished(!published)}
                        />
                    </Div>
                    <ImageUploader
                        modalOpen={imgEditorOpen}
                        handleModalClose={() => setImgEditorOpen(false)}
                        handleModalSave={(image) => {
                            setImage(image);
                            setImgEditorOpen(false);
                        }}
                        value={image}
                        aspectRatio={0.5}
                    />
                </Div>
                <br />
                <Button primary content="Submit Banner" onClick={tryUpdate} />
            </Modal.Content>
        </Modal>
    );
}
