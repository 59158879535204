import React, { useContext } from "react";
import ReactPlayer from "react-player/lazy";
import { Div } from "@components/Generics.react";
import { LayoutContext } from "@layouts/MasterLayout.react";
import "@styles/readers.scss";

export default function Video(props) {
    const { video, caption } = props.data;
    const { fullScreen } = useContext(LayoutContext);
    const isPortrait = window.innerHeight > window.innerWidth || !fullScreen;
    // ========================= Render Function =================================
    if (isPortrait)
        return (
            <Div fluid zoom>
                <Div className="video-responsive">
                    <ReactPlayer
                        className="react-player"
                        url={video}
                        width="100%"
                        height="100%"
                    />
                </Div>
                {caption && (
                    <Div superpad ash big>
                        {caption}
                    </Div>
                )}
            </Div>
        );
    else
        return (
            <Div fluid flex flexStart>
                <Div wd="50%" zoom slightShadow rounded noOverflow>
                    <Div className="video-responsive">
                        <ReactPlayer
                            className="react-player"
                            url={video}
                            width="100%"
                            height="100%"
                        />
                    </Div>
                </Div>
                {caption && (
                    <Div wd="50%" superpad ash big>
                        {caption}
                    </Div>
                )}
            </Div>
        );
}
