import React, { useState } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import {
    useSendRemoveLearnerMutation,
    useGetCourseInventoryQuery,
} from "@api/apiV6";

import { Div, Button } from "@components/Generics.react";
import { Modal, Icon, Dropdown, Loader, Progress } from "semantic-ui-react";

export default function AdminUnenrolModal(props) {
    const { isOpen, setOpen, user, users } = props;
    const { data: courseList, isSuccess: courseListSuccess } =
        useGetCourseInventoryQuery();
    const [removeLearner] = useSendRemoveLearnerMutation();

    const [selectedCourse, setSelectedCourse] = useState(null);
    const [percent, setPercent] = useState(0);

    async function tryUpdate() {
        if (user != null) {
            const updateResponse = await removeLearner({
                id: selectedCourse,
                user_id: user._id,
            });
            if (updateResponse) {
                toast("User Unenrolled");
                setOpen(false);
            }
        }
        if (users != null) {
            let processedUsers = 0;
            for (let i = 0; i < users.length; i++) {
                const updateResponse = await removeLearner({
                    id: selectedCourse,
                    user_id: users[i],
                });
                if (updateResponse) processedUsers++;
                setPercent(parseInt((processedUsers * 100) / users.length, 0));
            }
            toast(`${processedUsers} users unenrolled`);
            setOpen(false);
        }
    }

    // ========================= Render Function =================================
    if (courseListSuccess)
        return (
            <Modal size="small" open={isOpen} onClose={() => setOpen(false)}>
                <Modal.Header>
                    Unenrol from Course
                    <Div float-right clickable>
                        <Icon name="times" onClick={() => setOpen(false)} />
                    </Div>
                </Modal.Header>
                <Modal.Content>
                    <Dropdown
                        placeholder={"Select Course"}
                        fluid
                        selection
                        search
                        options={_.map(courseList, (l) => ({
                            key: l._id,
                            text: l.name,
                            value: l._id,
                        }))}
                        value={selectedCourse}
                        onChange={(e, { value }) => setSelectedCourse(value)}
                    />
                    {users && (
                        <Div gapTop>
                            <Progress
                                size="large"
                                percent={percent}
                                indicating
                            />
                        </Div>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        primary
                        fluid
                        content="Unenroll"
                        onClick={tryUpdate}
                    />
                </Modal.Actions>
            </Modal>
        );
    else return <Loader />;
}
