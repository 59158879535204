import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import {
    useCreateSubscriptionMutation,
    useVerifySubscriptionMutation,
    useSendContactUsRequstMutation,
} from "@api/apiV6";
import { useFeatures } from "flagged";

import _ from "lodash";
import moment from "moment";
import { Div, HDiv, Button } from "@components/Generics.react";
import CreatorLayout from "@layouts/CreatorLayout.react";
import BuilderHeader from "@components/BuilderHeader.react";
import UserAvatar from "@components/UserAvatar.react";

import { Tab, Image, Checkbox, Modal, Icon } from "semantic-ui-react";
import {
    licenseSchema,
    accountBanner,
    showKeys,
    showLicenses,
    comparePlansLink,
} from "@schemas/licenseSchema";

export default function MyAccount(props) {
    const user = useSelector((state) => state.auth.user);
    const navigate = useNavigate();
    const [createSubscription] = useCreateSubscriptionMutation();
    const [verifySubscription] = useVerifySubscriptionMutation();
    const [sendContactUsRequst] = useSendContactUsRequstMutation();
    const { t } = useTranslation("common");
    const features = useFeatures();

    const [searchParams] = useSearchParams();
    const [monthly, setMonthly] = useState(false);
    const [contactUsModal, setContactUsModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const thisTarget = searchParams.get("target");
    const activeIndex = thisTarget === "redeem" ? 1 : 0;

    console.log(user);

    const loadScript = (src) => {
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => resolve();
            script.onerror = (error) =>
                reject(new Error(`Failed to load script: ${src}`));
            document.head.appendChild(script);
        });
    };
    const startSubscription = (planName) => {
        const licensePlanSchema = licenseSchema[planName];
        loadScript("https://checkout.razorpay.com/v1/checkout.js")
            .then(() => {
                createSubscription({
                    id: monthly
                        ? licensePlanSchema.planId.monthly
                        : licensePlanSchema.planId.annual,
                })
                    .then((result) => {
                        const options = {
                            key: result.data.key,
                            subscription_id: result.data.subscription_id,
                            handler: function (response) {
                                const responseHash = {
                                    razorpayPaymentId:
                                        response.razorpay_payment_id,
                                    razorpaySignature:
                                        response.razorpay_signature,
                                    planId: result.data.plan_id,
                                    planName: planName,
                                    subscriptionId: result.data.subscription_id,
                                    maxUsers: licensePlanSchema.maxUsers,
                                    maxDecks: licensePlanSchema.maxDecks,
                                    maxSpace: licensePlanSchema.maxSpace,
                                };
                                verifySubscription(responseHash).then(
                                    (confirmation) => {
                                        console.log(
                                            "Subscription verified successfully",
                                            confirmation
                                        );
                                    }
                                );
                            },
                            prefill: {
                                name: `${user.first_name} ${user.last_name}`,
                                email: `${user.email}`,
                            },
                            notes: result.data.notes,
                        };

                        const paymentObject = new window.Razorpay(options);
                        paymentObject.open();
                    })
                    .catch((e) => {
                        console.error("Error creating subscription:", e);
                    });
            })
            .catch((e) => {
                console.error("Error loading script:", e);
            });
    };

    const sendContactRequest = (data) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setContactUsModal(true);
        }, 2000);
        sendContactUsRequst({
            username: data?.username,
            name: data?.first_name + " " + data?.last_name,
            phonenumber: data?.phonenumber,
            workemail: data?.email,
            message: "This user wishes to upgrade to the Enterprise Plan.",
            subject: "Requesting for upgrade to the Enterprise Plan",
        });
    };

    function maxSpace(space) {
        return space > 99999 ? 20 : space > 49999 ? 10 : space > 29999 ? 4 : 2;
    }

    function showRow(label, value) {
        return (
            <Div flex white snug>
                <Div wd="120px" ash padded>
                    {label}
                </Div>
                <Div wd="calc(100% - 120px)" white padded>
                    {value}
                </Div>
            </Div>
        );
    }

    function showPlan(plan) {
        const schema = licenseSchema[plan];
        return (
            <Div basepad vapor>
                <Div gutter>
                    <Div large bold uppercase medpad white>
                        {`${schema.name} ${t("account.plan")}`}
                    </Div>
                    <Div gapTop altText large center-align>
                        {monthly ? schema.price.monthly : schema.price.annual}
                    </Div>
                </Div>
                <Div gutter>
                    {showKeys.includes("maxUsers") &&
                        showRow(
                            t("account.maxUsers"),
                            schema.maxUsers.toLocaleString()
                        )}
                    {showKeys.includes("maxDecks") &&
                        showRow(t("account.maxDecks"), schema.maxDecks * 12)}
                    {showKeys.includes("maxCourses") &&
                        showRow(t("account.maxCourses"), schema.maxCourses)}
                    {showKeys.includes("maxArticles") &&
                        showRow(t("account.maxArticles"), schema.maxArticles)}
                    {showKeys.includes("maxSpace") &&
                        showRow(
                            t("account.maxSpace"),
                            `${maxSpace(schema.maxSpace)}MB`
                        )}
                </Div>
                <Div gutter>
                    {_.map(schema.common, (lr, idx) => {
                        return (
                            <HDiv
                                fluid
                                white
                                padded
                                snug
                                key={`lrx-${idx}`}
                                content={lr}
                            />
                        );
                    })}
                </Div>
                <Div gutter>
                    <Button
                        fluid
                        primary
                        loading={loading}
                        content={
                            plan.toLowerCase().includes("enterprise")
                                ? "Contact Us"
                                : monthly
                                    ? `Upgrade to ${plan} Monthly`
                                    : `Upgrade to ${plan} Annual`
                        }
                        onClick={() => {
                            if (plan.toLowerCase().includes("enterprise")) {
                                sendContactRequest(user);
                            } else {
                                startSubscription(plan);
                            }
                        }}
                        disabled={
                            schema.level <=
                            licenseSchema[user.license.license_name].level
                        }
                    />
                </Div>
            </Div>
        );
    }

    const leftPanel = (
        <Div wd="300px" fullht peat>
            {window.innerHeight > 720 && (
                <Div wd="300px" ht="145px" noOverflow slightShadow relative>
                    <Image src="/assets/images/configurable/appaccount.jpg" />
                    <Div
                        absolute
                        bold
                        large
                        uppercase
                        className="browser-bar-header"
                    >
                        {t("builder.welcome.accountHeader")}
                    </Div>
                </Div>
            )}
            <Div fullht basepad vapor slightShadow>
                <Div white flex slightShadow basepad gutter>
                    <Div wd="70px">
                        <UserAvatar
                            name={`${user?.first_name} ${user?.last_name}`}
                            image={user?.image}
                            avatarConfig={user?.avatarConfig}
                            size={64}
                        />
                    </Div>
                    <Div wd="calc(100% - 70px)" bold uppercase>
                        {`${user?.first_name} ${user?.last_name}`}
                        <Div gapTop>
                            <Button
                                small={+true}
                                primary
                                content={t("navbar.editProfile")}
                                onClick={() => navigate(`/editProfile/avatar`)}
                            />
                        </Div>
                    </Div>
                </Div>
                <Div large bold uppercase gutter>
                    {`${licenseSchema[user.license.license_name].name} ${t(
                        "account.plan"
                    )}`}
                </Div>
                <Div gutter>
                    {showKeys.includes("maxUsers") &&
                        showRow(
                            t("account.maxUsers"),
                            user.license.maxUsers.toLocaleString()
                        )}
                    {showKeys.includes("maxDecks") &&
                        showRow(
                            t("account.maxDecks"),
                            licenseSchema[user.license.license_name].maxDecks
                        )}
                    {showKeys.includes("maxCourses") &&
                        showRow(
                            t("account.maxCourses"),
                            licenseSchema[user.license.license_name].maxCourses
                        )}
                    {showKeys.includes("maxArticles") &&
                        showRow(
                            t("account.maxArticles"),
                            licenseSchema[user.license.license_name].maxArticles
                        )}
                    {showKeys.includes("maxSpace") &&
                        showRow(
                            t("account.maxSpace"),
                            `${maxSpace(user.maxSpace)}MB`
                        )}
                </Div>
                <Div gutter>
                    {_.map(
                        licenseSchema[user.license.license_name].common,
                        (lr, idx) => {
                            return (
                                <HDiv
                                    fluid
                                    white
                                    padded
                                    snug
                                    key={`lr-${idx}`}
                                    content={lr}
                                />
                            );
                        }
                    )}
                </Div>
                <Div charcoal basepad gutter>
                    <Div bold>{t("account.expiry")}</Div>
                    {moment(
                        new Date(user.license.end_date),
                        "D/M/YYYY, h:mm:ss a"
                    ).format("Do MMM YY")}
                </Div>
            </Div>
        </Div>
    );
    const panes = [
        {
            menuItem: t("account.plans"),
            render: () => (
                <Tab.Pane>
                    <Div flex gapped bold uppercase relaxed large>
                        Monthly{" "}
                        <Checkbox
                            toggle
                            checked={!monthly}
                            onClick={() => setMonthly(!monthly)}
                        />{" "}
                        Annual
                    </Div>
                    <Div gutter flex gapped top-align flexStart>
                        {_.map(showLicenses, (l, idx) => (
                            <Div
                                key={`plan-${idx}`}
                                wd={`calc(${100 / showLicenses.length}% - 5px)`}
                            >
                                {showPlan(l)}
                            </Div>
                        ))}
                    </Div>
                    <Div fluid center-align trench big bold ivory medpad>
                        {/* <Link
                            to={comparePlansLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t("account.compare")}
                        </Link> */}
                        <a
                            href={comparePlansLink}
                            target="_blank">
                            {t("account.compare")}
                        </a>
                    </Div>
                </Tab.Pane>
            ),
        },
        features.general.dealCodes && {
            menuItem: t("account.redeem"),
            render: () => <Tab.Pane>Coming Soon...</Tab.Pane>,
        },
        features.general.aiMarket && {
            menuItem: t("account.aiMarket"),
            render: () => (
                <Tab.Pane>
                    <Div superpad ash flex spaceBetween>
                        <Div wd="25%">
                            <Image
                                src="/assets/images/account/share2win.jpg"
                                fluid
                            />
                        </Div>
                        <HDiv padded content={t("account.getAICredits")} />
                    </Div>
                </Tab.Pane>
            ),
        },
        user.license.subscriptionId != null && {
            menuItem: t("account.invoices"),
            render: () => <Tab.Pane>Coming Soon...</Tab.Pane>,
        },
    ].filter(Boolean);

    const rightPanel = (
        <Div wd="calc(100% - 300px)" fullht autoOverflowY>
            <BuilderHeader
                image={"/assets/images/configurable/appaccount.jpg"}
                header={t("builder.headers.account.header")}
                description={t("builder.headers.account.help")}
            />
            {accountBanner && (
                <Div fluid basepad>
                    <Div flex primary slightShadow>
                        <Div wd="40%">
                            <Image fluid src={accountBanner.image} />
                        </Div>
                        <Div wd="40%" padSides>
                            <Div bold gutter>
                                {accountBanner.header}
                            </Div>
                            {accountBanner.description}
                        </Div>
                        <Div wd="20%" basepad flex column gapped>
                            {_.map(accountBanner.buttons, (btn, idx) => (
                                <Button
                                    key={`banner-btn-${idx}`}
                                    fluid
                                    secondary
                                    content={btn.text}
                                    href={btn.link}
                                    target={btn.target}
                                />
                            ))}
                        </Div>
                    </Div>
                </Div>
            )}
            <Div basepad>
                <Tab panes={panes} defaultActiveIndex={activeIndex} />
            </Div>
            <Modal
                size="small"
                open={contactUsModal}
                onClose={() => setContactUsModal(false)}
            >
                <Modal.Header>
                    {t("account.contactUsModal.header")}
                    <Div float-right clickable>
                        <Icon
                            name="times"
                            onClick={() => {
                                setContactUsModal(false);
                            }}
                        />
                    </Div>
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <p>{t("account.contactUsModal.message")}</p>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </Div>
    );
    const mainPanel = (
        <Div fluid fullht>
            <Div flex flexStart top-align fullht>
                {leftPanel}
                {rightPanel}
            </Div>
        </Div>
    );
    return (
        <CreatorLayout
            title={t("account.navName")}
            navName={t("account.navName")}
            pageCode={"account"}
            mainPanel={mainPanel}
            rootPage={true}
        />
    );
}
