import React, { useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Dropdown, Grid, Loader } from "semantic-ui-react";
import {
    useGetLearnerPerformanceQuery,
    useGetCourseLibraryQuery,
} from "@api/apiV6";
import { Div } from "@components/Generics.react";
import { settings } from "@config/settings/app.settings";

import RadialProgressChart from "@components/charts/RadialProgressChart.react";
import ScatterChart from "@components/charts/ScatterChart.react";
import CoursePerformance from "./CoursePerformance.react";

export default function PerformanceAnalytics(props) {
    const user = useSelector((state) => state.auth.user);
    const {
        data: performanceData,
        isSuccess: isPerformanceSuccess,
        isError: isPerformanceError,
        isLoading: isPerformanceLoading,
    } = useGetLearnerPerformanceQuery(user._id);
    const { data: library, isSuccess: librarySuccess } =
        useGetCourseLibraryQuery();
    const [activeCourse, setActiveCourse] = useState(null);

    if (isPerformanceLoading) {
        return <Loader />;
    } else if (isPerformanceSuccess && !isPerformanceError) {
        return (
            <Div fitted>
                <Grid doubling columns={2}>
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <Div
                                basepad
                                huge
                                charcoal
                                rounded
                                center-align
                                gutter
                            >
                                PERFORMANCE
                            </Div>
                            <Div basepad vapor rounded center-align>
                                <Div center-align compact bold uppercase gutter>
                                    Average Course Score
                                </Div>
                                <RadialProgressChart
                                    width={120}
                                    height={120}
                                    color={user.branding?.colors?.main || settings.colors.main}
                                    value={Math.round(
                                        performanceData.average_final_score
                                    )}
                                />
                            </Div>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Div medpad vapor rounded center-align>
                                <Div gapTop big uppercase gutter bold>
                                    Score vs Content Coverage
                                </Div>
                                <ScatterChart
                                    color={user.branding?.colors?.main || settings.colors.main}
                                    data={performanceData.score_completion}
                                    width={380}
                                    series={[
                                        {
                                            type: "number",
                                            dataKey: "completion",
                                            name: "Coverage",
                                            unit: "%",
                                        },
                                        {
                                            type: "number",
                                            dataKey: "score",
                                            name: "Score",
                                            unit: "%",
                                        },
                                    ]}
                                />
                            </Div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <br />

                {librarySuccess && (
                    <Div medpad vapor>
                        <Div gapTop big uppercase gapBottom bold>
                            Select a Course:
                        </Div>

                        <Dropdown
                            placeholder="Select a course to get performance details"
                            search
                            fluid
                            selection
                            options={_.map(
                                _.keys(library.courses),
                                (courseId) => {
                                    return {
                                        key: courseId,
                                        value: courseId,
                                        text: library.courses[courseId].name,
                                    };
                                }
                            )}
                            onChange={(e, { value }) => setActiveCourse(value)}
                        />
                        <br />
                        {activeCourse && (
                            <CoursePerformance
                                userId={user._id}
                                courseId={activeCourse}
                            />
                        )}
                    </Div>
                )}
            </Div>
        );
    } else {
        return <Div>Error</Div>;
    }
}
