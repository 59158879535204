import React from "react";
import { useGetAncestorsQuery, useGetDescendantsQuery, useGetReporteesQuery } from "@api/apiV6";
import _ from "lodash";

import { Div } from "@components/Generics.react";
export default function UserHierarchy(props) {
    const { userId } = props;
    const { data: ancestors, isSuccess: aSuccess } =
        useGetAncestorsQuery(userId);
    const { data: reportees, isSuccess: rSuccess } =
        useGetReporteesQuery(userId);
    const { data: descendants, isSuccess: dSuccess } =
        useGetDescendantsQuery(userId);
    // ========================= Render Function =================================
    return (
        <Div basepad ash fullht>
            {aSuccess && (
                <Div basepad white gutter>
                    <Div bold>Managers</Div>
                    <Div gapTop>{_.map(ancestors, "username").join("> ")}</Div>
                </Div>
            )}
            {rSuccess && (
                <Div basepad white gutter>
                    <Div bold>Direct Reportees</Div>
                    <Div gapTop>
                        {_.map(reportees, "username").join(", ")}
                    </Div>
                </Div>
            )}
            {dSuccess && (
                <Div basepad white gutter>
                    <Div bold>Full Reportee Span</Div>
                    <Div gapTop>
                        {_.map(descendants, "username").join(", ")}
                    </Div>
                </Div>
            )}
        </Div>
    );
}
