import React from "react";
import _ from "lodash";
import moment from "moment";
import { useGetActivityLogQuery, useGetCoursesStatusQuery } from "@api/apiV6";
import { useTranslation } from "react-i18next";
import { Loader, Tab } from "semantic-ui-react";
import { Div } from "@components/Generics.react";
import CoursesStatusTable from "../components/CoursesStatusTable.react";
import UserDashboard from "./UserDashboard.react";

export default function UserAnalytics(props) {
    const { user, courses } = props;
    const {
        data: coursesData,
        isSuccess,
        refetch,
    } = useGetCoursesStatusQuery(user._id);
    const { data: activityLog, isSuccess: logSuccess } = useGetActivityLogQuery(
        user._id
    );
    const { t } = useTranslation("common");

    const panes = [
        {
            menuItem: "Dashboard",
            render: () => (
                <Tab.Pane>
                    <UserDashboard id={user._id} data={coursesData} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Course Status",
            render: () => (
                <Tab.Pane>
                    <CoursesStatusTable
                        header={t("analytics.courseProgress")}
                        data={coursesData}
                        refetch={refetch}
                        showIndividual={true}
                        user={user}
                        courses={courses}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Activity Log",
            render: () => (
                <Tab.Pane>
                    {logSuccess && (
                        <Div basepad smoke slightShadow>
                            <Div bold big uppercase gutter>
                                {t("admin.userMgmt.activityLog")}
                            </Div>
                            <Div ht="250px" autoOverflowY>
                                {_.map(activityLog, (logEntry, idx) => (
                                    <Div
                                        key={`log-${idx}`}
                                        flex
                                        white
                                        snug
                                        padded
                                        spaceBetween
                                    >
                                        <Div wd="160px">
                                            {moment(
                                                new Date(logEntry.time),
                                                "D/M/YYYY, h:mm:ss a"
                                            ).format("Do MMM YY h:mm a")}
                                        </Div>
                                        <Div wd="calc(100% - 200px)">
                                            {logEntry.event}
                                        </Div>
                                    </Div>
                                ))}
                            </Div>
                        </Div>
                    )}
                </Tab.Pane>
            ),
        },
    ];

    if (isSuccess) return <Tab panes={panes} />;
    else return <Loader />;
}
