import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import "@styles/readers.scss";
import { Div, HDiv, Button } from "@components/Generics.react";
import Accordion from "@components/Accordion.react";

export default function FAQReader(props) {
    const { deck, recordAttempt } = props;

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let panels =
        typeof deck?.content === "string" || deck?.content instanceof String
            ? [
                  {
                      title: <Div small>Answer:</Div>,
                      content: <HDiv content={deck?.content} />,
                  },
              ]
            : _.map(deck?.content, (item) => {
                  return {
                      title: <Div small>{item?.title}</Div>,
                      content: (
                          <Div>
                              <HDiv content={item?.description} />
                              {item?.button?.btnLink && (
                                  <Div padTop>
                                      {_.includes(
                                          item?.button?.btnLink,
                                          "http"
                                      ) ? (
                                          <a
                                              href={item?.button?.btnLink}
                                              target="_blank"
                                              rel="noreferrer"
                                          >
                                              <Button
                                                  primary
                                                  fluid
                                                  content={item?.button.text}
                                              />
                                          </a>
                                      ) : (
                                          <NavLink to={item?.button?.btnLink}>
                                              <Button
                                                  primary
                                                  fluid
                                                  content={item?.button.text}
                                              />
                                          </NavLink>
                                      )}
                                  </Div>
                              )}
                          </Div>
                      ),
                  };
              });

    return (
        <Div
            fluid
            fullht
            noOverflow
            rounded
            left-align
            slightShadow
            className="faq-reader"
        >
            <Accordion panels={panels}></Accordion>
        </Div>
    );
}
