import React, { useState, useMemo } from "react";
import _ from "lodash";
import {
    Grid,
    Card,
    Image,
    Dropdown,
    Menu,
    Segment,
    Label,
    Icon,
} from "semantic-ui-react";
import { useGetStoreListQuery } from "@api/apiV6";
import "semantic-ui-css/semantic.min.css";
import RangeSlider from "react-range-slider-input";
import { Div, Button } from "@components/Generics.react";
import { settings } from "@config/settings/app.settings";

export default function ContentMarketplace(props) {
    const { processQFile } = props;
    const { data: digitalFiles, isSuccess } = useGetStoreListQuery();

    const [filters, setFilters] = useState({
        name: "",
        subject: "",
        target: "",
        difficulty: [1, 10],
        cost: [0, 100],
    });

    const subjectOptions = useMemo(
        () =>
            isSuccess
                ? _.map(_.uniq(_.map(digitalFiles, "subject")), (s) => ({
                      key: s,
                      value: s,
                      text: s,
                  }))
                : [],
        [isSuccess, digitalFiles]
    );
    const targetOptions = useMemo(
        () =>
            isSuccess
                ? _.map(_.uniq(_.map(digitalFiles, "target")), (s) => ({
                      key: s,
                      value: s,
                      text: s,
                  }))
                : [],
        [isSuccess, digitalFiles]
    );

    const handleFilterChange = (name, value) => {
        setFilters({ ...filters, [name]: value });
    };

    const filteredFiles = digitalFiles;
    // const filteredFiles = digitalFiles.filter((file) =>
    //     Object.entries(filters).every(([key, value]) =>
    //         Array.isArray(value)
    //             ? parseInt(file[key], 10) >= value[0] &&
    //               parseInt(file[key], 10) <= value[1]
    //             : value
    //             ? key === "subject" // Adjust for the changed field name "subject" instead of "subject"
    //                 ? file[key] === value
    //                 : file[key].toString() === value.toString() // Ensure comparison works for strings
    //             : true
    //     )
    // );

    async function fetchAndProcessFile(url, processFile) {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const blob = await response.blob();
            // Creating a File object from the Blob
            // Note: The last parameter 'filename.csv' is the name of the file and should match your actual file's name
            const file = new File([blob], "filename.csv", { type: blob.type });

            // Now, you can use your existing processFile function with the file
            processQFile(file, "add");
        } catch (error) {
            console.error("Error fetching or processing file:", error);
        }
    }

    return (
        <Grid>
            <Grid.Column width={4}>
                <Menu vertical fluid>
                    <Menu.Header>
                        <Div big padded ash>
                            Filters:
                        </Div>
                    </Menu.Header>
                    <Menu.Item>
                        <Dropdown
                            placeholder="Select Category"
                            fluid
                            selection
                            options={subjectOptions}
                            onChange={(e, { name, value }) =>
                                handleFilterChange(name, value)
                            }
                            name="subject"
                            value={filters.subject}
                        />
                    </Menu.Item>
                    <Menu.Item>
                        <Dropdown
                            placeholder="Select Target"
                            fluid
                            selection
                            options={targetOptions}
                            onChange={(e, { name, value }) =>
                                handleFilterChange(name, value)
                            }
                            name="target"
                            value={filters.target}
                        />
                    </Menu.Item>
                    <Menu.Item>
                        <Div gutter>
                            Cost: {filters.cost[0]} - {filters.cost[1]}
                        </Div>
                        <RangeSlider
                            min={0}
                            max={100}
                            step={1}
                            value={filters.cost}
                            onChange={(value) =>
                                handleFilterChange("cost", value)
                            }
                        />
                    </Menu.Item>
                    <Menu.Item>
                        <Div gutter>
                            Difficulty: {filters.difficulty[0]} -{" "}
                            {filters.difficulty[1]}
                        </Div>
                        <RangeSlider
                            min={1}
                            max={10}
                            step={1}
                            value={filters.difficulty}
                            onChange={(value) =>
                                handleFilterChange("difficulty", value)
                            }
                        />
                    </Menu.Item>
                    <br />
                </Menu>
            </Grid.Column>
            <Grid.Column stretched width={12}>
                <Segment>
                    <Card.Group itemsPerRow={3}>
                        {isSuccess &&
                            filteredFiles.map((file, index) => {
                                const isFree = file.cost === "0";
                                return (
                                    <Card key={`csv-file-${index}`}>
                                        <Image
                                            src={file.image}
                                            wrapped
                                            fluid
                                            label={
                                                isFree
                                                    ? undefined
                                                    : {
                                                          color: "red",
                                                          corner: "right",
                                                          icon: "money",
                                                      }
                                            }
                                        />
                                        <Card.Content>
                                            <Card.Header>
                                                {file.name}
                                            </Card.Header>
                                            <Card.Meta>
                                                <span className="date">
                                                    {file.subject}
                                                </span>
                                            </Card.Meta>
                                            <Card.Description>
                                                <Label>
                                                    <Icon name="user" />{" "}
                                                    {file.target}
                                                </Label>
                                                <Label>
                                                    <Icon name="star" />{" "}
                                                    Difficulty:{" "}
                                                    {file.difficulty}
                                                </Label>
                                            </Card.Description>
                                        </Card.Content>
                                        <Card.Content extra>
                                            <Div fluid flex spaceBetween>
                                                <Div>
                                                    Cost:{" "}
                                                    {file.cost > 0
                                                        ? `$${file.cost}`
                                                        : "Free"}
                                                </Div>
                                                <Button
                                                    size="small"
                                                    primary
                                                    content="Use this"
                                                    onClick={() =>
                                                        fetchAndProcessFile(
                                                            `${settings.baseUrl}public/marketplace/${file.file}`
                                                        )
                                                    }
                                                />
                                            </Div>
                                        </Card.Content>
                                    </Card>
                                );
                            })}
                    </Card.Group>
                </Segment>
            </Grid.Column>
        </Grid>
    );
}
