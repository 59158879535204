// Import from NPM
// -------------------------------------
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Div } from "@components/Generics.react";
import "@styles/editor.scss";

import SingleBtn from "@components/ribbon/SingleBtn.react";
import RowBtns from "@components/ribbon/RowBtns.react";
import OneTwoBtns from "@components/ribbon/OneTwoBtns.react";
import { downloadJSONAsFile, encrypt, decrypt } from "@utilities/helpers";

export default function HomeMenu(props) {
    const {
        id,
        deck,
        updateDeck,
        config,
        saveDeck,
        saveDeckAndExit,
        resetDeck,
        cancelExit,
        rightPanel,
        setRightPanel,
    } = props;
    // Reference for the file input element
    const fileInputRef = useRef(null);

    const { t } = useTranslation("common");
    // Handle file upload
    const handleUpload = (event) => {
        const file = event.target.files[0]; // Get the uploaded file

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                try {
                    const encryptedFile = e.target.result.replace(/['"]+/g, ""); // Get the file content
                    const uploadedData = decrypt(encryptedFile); // Decrypt the file content
                    const parsedData = JSON.parse(uploadedData); // Parse the decrypted content
                    // Check if the parsed data is valid
                    if (parsedData && Array.isArray(parsedData.content)) {
                        updateDeck({ ...deck, content: parsedData.content }); // Update the deck
                        toast(t("builder.ribbon.contentUpdated")); // Show success toast
                    } else {
                        throw new Error("Uploaded data format is invalid.");
                    }
                    // Reset the file input after 3 seconds
                    setTimeout(() => {
                        if (fileInputRef.current) { // Ensure fileInputRef.current is not null
                            fileInputRef.current.value = null;
                        }
                    }, 3000);
                } catch (error) {
                    console.error(t("builder.ribbon.jsonError"), error); // Log error
                }
            };
            reader.readAsText(file); // Read the file as text
        }
    };

    // Handle file download
    function handleDownload() {
        const encryptedData = encrypt(JSON.stringify(deck)); // Encrypt the deck data
        downloadJSONAsFile(
            encryptedData,
            `${id}_${new Date(Date.now()).toLocaleDateString("en-IN")}.qdf`
        ); // Download the encrypted data as a file
        toast(t("builder.ribbon.contentDownloaded")); // Show success toast
    }

    // ========================= Render Function =================================
    return (
        <Div flex flexStart>
            {config.includes("settings") && (
                <SingleBtn
                    name={t("components.save")}
                    icon="save"
                    action={saveDeck}
                    leftBordered={false}
                    title="Press Ctrl/Cmd + S to Save"
                />
            )}
            {config.includes("save") && (
                <OneTwoBtns
                    name="Save Operations"
                    btns={[
                        {
                            name: t("components.saveExit"),
                            icon: "exit-up",
                            action: saveDeckAndExit,
                        },
                        {
                            name: t("builder.ribbon.reset"),
                            icon: "exit-up",
                            action: resetDeck,
                        },
                        {
                            name: t("components.cancelExit"),
                            icon: "cancel-square",
                            action: cancelExit,
                        },
                    ]}
                />
            )}
            {config.includes("file") && (
                <RowBtns
                    name={t("builder.ribbon.fileHandle")}
                    btns={[
                        {
                            name: t("components.upload"),
                            icon: "upload",
                            action: () => fileInputRef.current.click(),
                        },
                        {
                            name: t("components.download"),
                            icon: "download",
                            action: handleDownload,
                        },
                    ]}
                />
            )}
            {config.includes("view") && (
                <RowBtns
                    name={t("builder.ribbon.viewToggle")}
                    btns={[
                        {
                            name: t("builder.ribbon.mobile"),
                            icon: "smartphone",
                            active: rightPanel === "mobile",
                            action: () => setRightPanel("mobile"),
                        },
                        {
                            name: t("builder.ribbon.sorter"),
                            icon: "sort-amount-asc3",
                            active: rightPanel === "sorter",
                            action: () => setRightPanel("sorter"),
                        },
                        {
                            name: t("builder.ribbon.notes"),
                            icon: "bubbles8",
                            active: rightPanel === "notes",
                            action: () => setRightPanel("notes"),
                        },
                    ]}
                />
            )}
            {config.includes("settings") && (
                <SingleBtn
                    name={t("builder.ribbon.settings")}
                    icon="cog9"
                    action={() => setRightPanel("settings")}
                />
            )}
            <Div hidden>
                <input
                    ref={fileInputRef}
                    type="file"
                    accept=".qdf"
                    onChange={handleUpload}
                />
            </Div>
        </Div>
    );
}