import React, { useState, useRef, useEffect, useContext } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import { Document, Page, pdfjs } from "react-pdf";
import _ from "lodash";

import { LayoutContext } from "@layouts/MasterLayout.react";
import "@styles/readers.scss";

import { Div } from "@components/Generics.react";
import DeckToolbar from "@components/DeckToolbar.react";
import { clientAssetsPath } from "@api/apiV6";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function DocumentReader(props) {
    const { deck, recordAttempt } = props;
    const { fullScreen, setFullScreen } = useContext(LayoutContext);

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);
    const [maxRead, setMaxRead] = useState(1);
    const [scale, setScale] = useState(1);
    const docref = useRef();
    const { events } = useDraggable(docref, {
        applyRubberBandEffect: true, // activate rubber band effect
    });

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        // if (currentState?.viewedPercentage) {
        //     setPageNumber(
        //         Math.floor((currentState.viewedPercentage * numPages) / 100)
        //     );
        // }
    }

    useEffect(() => {
        if (maxRead <= pageNumber + 1) setMaxRead(pageNumber);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber]);

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        if (numPages > 0) {
            recordAttempt({
                viewedPercentage:
                    Math.min(
                        100,
                        parseInt(((maxRead + 1) * 100) / numPages, 0)
                    ) || 0,
                completion: numPages && maxRead >= numPages - 1,
                pointsDelta: 0,
                score: null,
                showEnding: maxRead >= numPages,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numPages, maxRead]);

    const targetWidth = docref.current?.getBoundingClientRect().width - 15;
    const targetHeight = docref.current?.getBoundingClientRect().height - 15;

    // ========================= Render Function =================================

    const targetFile = _.startsWith(
        deck?.content,
        "data:application/pdf;base64"
    )
        ? deck?.content
        : `${clientAssetsPath}/uploads/docdecks/${deck._id||deck.id}.pdf`;

    return (
        <Div
            fullht
            fluid
            centered
            center-align
            compact
            noOverflow
            relative
            half
            gapTop
            className="fullscreen-target"
        >
            <div {...events} ref={docref} className="doc-viewport">
                <Document
                    file={targetFile}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onContextMenu={(e) => e.preventDefault()} // Used to prevent download of the PDF
                >
                    <Page
                        pageNumber={pageNumber + 1}
                        width={targetWidth}
                        height={targetHeight}
                        scale={scale}
                    />
                </Document>
            </div>
            <DeckToolbar
                {...{
                    deck,
                    scale,
                    setScale,
                    pageNumber,
                    setPageNumber,
                    numPages,
                    fullScreen,
                    setFullScreen,
                }}
            />
        </Div>
    );
}
