import React from "react";
import _ from "lodash";
import { Tab } from "semantic-ui-react";
import QuizLearnersResponses from "../quizzes/QuizLearnersResponses.react";
import QuizQuestionsResponses from "../quizzes/QuizQuestionsResponses.react";

export default function ArticleQuiz(props) {
    const { article } = props;
    const selectedFile = article.decks[0];

    function isCorrect(deckData, slideId, response) {
        const deckBlob = _.find(deckData.content, {
            slideSeq: parseInt(slideId.split("#")[1], 0),
        });
        let correct = false;
        if (deckBlob) {
            switch (deckBlob.template) {
                case "single_select":
                    if (
                        response &&
                        response ===
                            _.find(deckBlob.data.options, {
                                correct: "checked",
                            })?.text
                    )
                        correct = true;
                    break;
                case "multiple_select":
                    const correctSelected = _(deckBlob.data.multiselect)
                        .filter({ correct: "checked" })
                        .map("text")
                        .value();
                    if (
                        response &&
                        _.every(correctSelected, (item) =>
                            response.includes(item)
                        ) &&
                        _.every(response, (item) =>
                            correctSelected.includes(item)
                        )
                    )
                        correct = true;
                    break;
                case "true_false":
                    if (
                        response &&
                        response ===
                            _.find(deckBlob.data.choice, {
                                correct: "checked",
                            })?.text
                    )
                        correct = true;
                    break;
                case "fill_in_the_blanks":
                    if (
                        response &&
                        response?.toLowerCase() ===
                            deckBlob.data.answer?.toLowerCase()
                    )
                        correct = true;
                    break;
                case "pick_picture":
                    if (
                        response &&
                        response ===
                            _.findIndex(deckBlob.data.options, {
                                correct: "checked",
                            })
                    )
                        correct = true;
                    break;
                case "rank_them_right":
                    if (_.isEqual(deckBlob.data.bullets, response))
                        correct = true;
                    break;
                default:
                    if (
                        response &&
                        _.isString(response) &&
                        response?.toLowerCase() ===
                            deckBlob.data.answer?.toLowerCase()
                    )
                        correct = true;
            }
        }
        return correct;
    }

    const panes = [
        {
            menuItem: "Learner Responses",
            render: () => (
                <Tab.Pane>
                    <QuizLearnersResponses
                        deckId={selectedFile}
                        isCorrect={isCorrect}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Question Responses",
            render: () => (
                <Tab.Pane>
                    <QuizQuestionsResponses
                        deckId={selectedFile}
                        isCorrect={isCorrect}
                    />
                </Tab.Pane>
            ),
        },
    ];

    return <Tab panes={panes} />;
}
