import React, { useState } from "react";
import _ from "lodash";
import { Dropdown, Statistic, Icon, Grid, Loader } from "semantic-ui-react";
import {
    useGetLearnerProgressQuery,
    useGetCourseLibraryQuery,
} from "@api/apiV6";
import PieChart from "@components/charts/PieChart.react";
import { Div } from "@components/Generics.react";
import CourseProgress from "./CourseProgress.react";

export default function ProgressAnalytics(props) {
    const { userId } = props;
    const {
        data: progressData,
        isSuccess: isProgressSuccess,
        isError: isProgressError,
        isLoading: isProgressLoading,
    } = useGetLearnerProgressQuery(userId);
    const { data: library, isSuccess: librarySuccess } =
        useGetCourseLibraryQuery();
    const [activeCourse, setActiveCourse] = useState(null);
    const isPortrait = window.innerHeight > window.innerWidth;

    const progress = [
        { name: "Completed", value: progressData?.completed_courses || 0 },
        { name: "Started", value: progressData?.started_courses || 0 },
        { name: "Not Started", value: progressData?.notstarted_courses || 0 },
    ];

    if (isProgressLoading) {
        return <Loader />;
    } else if (isProgressSuccess && !isProgressError) {
        let showPie =
            progressData?.enrolled_courses &&
            parseInt(progressData?.enrolled_courses, 10) !== 0;

        return (
            <Div fitted>
                <Grid doubling columns={2}>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Div
                                basepad
                                huge
                                charcoal
                                rounded
                                center-align
                                gutter
                            >
                                PROGRESS REPORT
                            </Div>
                            <Div superpad vapor rounded center-align>
                                <Statistic size="large">
                                    <Statistic.Value>
                                        <Icon name="clipboard" />{" "}
                                        {progressData?.enrolled_courses}
                                    </Statistic.Value>
                                    <Statistic.Label>
                                        <Div large gapTop>
                                            Enrolled Courses
                                        </Div>
                                    </Statistic.Label>
                                </Statistic>
                            </Div>
                        </Grid.Column>
                        {showPie && (
                            <Grid.Column width={8}>
                                <Div medpad vapor rounded center-align>
                                    <Div gapTop big uppercase gapBottom bold>
                                        Completion Status
                                    </Div>
                                    <PieChart
                                        width={120}
                                        height={120}
                                        data={progress}
                                    />
                                    <br />
                                </Div>
                            </Grid.Column>
                        )}
                    </Grid.Row>
                </Grid>
                <br />

                {librarySuccess && (
                    <Div medpad vapor>
                        <Div gapTop big uppercase gapBottom bold>
                            Select a Course:
                        </Div>

                        <Dropdown
                            placeholder="Select a course to get progress details"
                            search
                            fluid
                            selection
                            upward={isPortrait}
                            options={_.map(
                                _.keys(library.courses),
                                (courseId) => {
                                    return {
                                        key: courseId,
                                        value: courseId,
                                        text: library.courses[courseId].name,
                                    };
                                }
                            )}
                            onChange={(e, { value }) => setActiveCourse(value)}
                        />
                        <br />
                        {activeCourse && (
                            <CourseProgress
                                userId={userId}
                                courseId={activeCourse}
                            />
                        )}
                    </Div>
                )}
            </Div>
        );
    } else {
        return <Div>Error</Div>;
    }
}
