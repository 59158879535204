// Import from NPM
// -------------------------------------
import React from "react";
import { useParams } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import _ from "lodash";

// Import from Config
// -------------------------------------
import { useGetUnauthArticleQuery } from "@api/apiV6";
import { deckFormats } from "@schemas/deckFormats";

// Import Components
// -------------------------------------
import MasterLayout from "@layouts/MasterLayout.react";
import ArticleViewer from "./ArticleViewer.react";

export default function UnauthArticle(props) {
    const { identifier } = useParams();
    const {
        data: article,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetUnauthArticleQuery(identifier);
    const readerType =
        isSuccess &&
        _.find(_.values(deckFormats), {
            category: article.category,
        }).readerType;

    const articleReadertype = deckFormats[readerType]
        ? deckFormats[readerType].readerType
        : readerType;
    const inPlayer = deckFormats[readerType]
        ? deckFormats[readerType].inPlayer
        : _.find(deckFormats, { readerType: readerType })?.inPlayer;

    // ------------------------------------------
    // Select Main Panel Component
    // ------------------------------------------
    function mainPanel() {
        return (
            isSuccess && (
                <ArticleViewer
                    articles={[]}
                    article={article}
                    readerType={articleReadertype}
                    inPlayer={inPlayer}
                    isolated={true}
                />
            )
        );
    }

    // const associatedArticles = useMemo(() => {
    //     if (article?.tags) {
    //         const articleTags = _.map(article.tags, (tag) => snaked(tag.name));
    //         return _.filter(
    //             _.uniqBy(
    //                 _.flatten(
    //                     _.map(
    //                         articleTags,
    //                         (tag) => articles.taggedList[tag].articles
    //                     )
    //                 ),
    //                 "_id"
    //             ),
    //             (art) => art._id !== article._id
    //         );
    //     }
    // }, [article, articles]);

    // const rightPanelName = t("article.rightHeader");
    // const rightContent = isSuccess && (
    //     <Div nopad fullht>
    //         <Div big padTop gutter bold uppercase>
    //             {t("article.rightHeader")}
    //         </Div>
    //         <Div autoOverflow padBottom ht="calc(100% - 45px)">
    //             {_.map(article.suggestReading, (article) => {
    //                 return (
    //                     <ArticleCard
    //                         key={`article-right-suggested-${article._id}`}
    //                         article={article}
    //                         feedStyle
    //                     />
    //                 );
    //             })}
    //             {_.map(associatedArticles, (article) => {
    //                 return (
    //                     <ArticleCard
    //                         key={`article-right-associated-${article._id}`}
    //                         article={article}
    //                         feedStyle
    //                     />
    //                 );
    //             })}
    //         </Div>
    //     </Div>
    // );

    // ========================= Render Function =================================

    if (isLoading) return <Loader active />;
    if (isError) return <div>{error.error}</div>;
    if (isSuccess) {
        return (
            <MasterLayout
                title={article.name}
                mainPanel={mainPanel()}
                // rightPanelName={rightPanelName}
                // rightPanel={
                //     (article.suggestReading?.length || 0) +
                //         (associatedArticles?.length || 0) ===
                //     0 ? (
                //         <RecommendedArticles />
                //     ) : (
                //         rightContent
                //     )
                // }
            />
        );
    } else {
        return <Loader active />;
    }
}
