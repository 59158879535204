export const learnerFaqTag = "Learner Queries";
export const supportSchema = {
    ticketCategory: [
        "System down",
        "App Access issues",
        "Login issues",
        "Communication issues",
        "Deck completion issues",
        "Enrollment issues",
        "Reporting issues/Analytics",
        "Completion reset issues",
        "Social Feed issues",
        "User addition issues",
        "Creation issues",
        "Role-related issues",
        "Other issues",
    ],
    problemCategory: [
        "Technical problem",
        "Functional problem",
        "Feature Request",
        "UI/UX changes",
    ],
};

export const faqSchema = [
    {
        question: "What is the Library tab used for?",
        answer: "The Library tab serves as a central repository for creating and managing courses, articles, and examinations on the platform. It allows creators to organize content and programs effectively.",
    },
    {
        question: "How do I create a new program in the Library?",
        answer: "To create a new program, click on the Create New button and select the Program tab in the overlay. Provide program details and ensure that the content type is set as course if you intend to create a course program. Click Submit to create the program."
    },
    {
        question: "What are the different tabs available within a course?",
        answer: "Within a course, you'll find four tabs: Course Settings, Edit Content, Launch Course, and View Analytics. Each tab offers specific settings and functionalities related to course management and administration.",
    },
    {
        question: "How can I customize the theme and access settings for a course?",
        answer: "In the Course Settings tab, you can customize the course theme (formal, standard, animated) and set access preferences, including sequential access and course priority. These settings determine how learners interact with the course content.",
    },
    {
        question: "What options are available in the Launch Course tab?",
        answer: "The Launch Course tab allows creators to officially launch the course for learners. Additionally, you can set up enrollment rules to automatically enroll users based on predefined criteria. You can also manually invite users or upload an Excel file to enroll them in the course."
    },
    {
        question: "How can I manage course content within a course?",
        answer: "The Edit Content tab allows creators to add topics and various types of content (e.g., assessments, surveys, certificates) to the course. You can edit content within topics and organize the course structure according to your preferences."
    },
    {
        question: "Can I track learner engagement and performance within a course?",
        answer: "Yes, creators can view detailed analytics for the course by accessing the View Analytics section. This provides insights into learner engagement, progress, and performance."
    },
    {
        question: "How do I enroll users in a course?",
        answer: "You can enroll users in a course by either inviting them individually or uploading an Excel file containing user details. The Enrollment Rules tab within the Launch Course section allows for automatic enrollment based on predefined rules."
    },
    {
        question: "What options do I have for managing notifications related to course enrollment?",
        answer: "Within the Course Settings tab, you can enable course enrollment notifications and set reminder parameters to ensure learners stay informed about course updates and deadlines. These notifications can be sent via the app and email to enrolled learners."
    },
    {
        question: "What are the main differences between examination courses and regular courses?",
        answer: "Examination courses have a simplified structure compared to regular courses. There is no option to customize themes, set sequential access, or add multiple topics within examination courses. Instead, examination courses have a single topic for organizing content." 
    },
    {
        question: "Are there any limitations to consider when creating examination courses?",
        answer: "While examination courses offer a simplified structure for delivering assessments, it's important to note that certain customization options available in regular courses, such as themes and sequential access, are not applicable to examination courses."
    },
    {
        question: "How do I create an article within the Library tab?",
        answer: "To create an article, start by creating an article program. Then, click on the Create New button again, select the Article tab in the overlay, and fill in the article details. Ensure that you select the parent program previously created for the article. Choose the content type (assessment, game, survey, video, etc.) and submit to create the article."
    },
    {
        question: "What steps are involved in designing an article?",
        answer: "After creating the article, you can design it by adding a summary, specifying languages, and suggesting readings. These details help provide context and additional resources for the article."
    },
    {
        question: "How do I publish an article?",
        answer: "In the Publish Article section, you can enable the toggle option for Published to make the article available to users. Set the priority to determine the order in which the article appears in lists. You can also enable sharing, clean view, and interactions options as needed. Assign tags to the article for easier categorization and organization."
    },
    {
        question: "Can I track analytics for my articles?",
        answer: "Yes, you can track analytics for your articles by clicking on the View Analytics option. This allows you to access data related to article views, interactions, and engagement metrics. The analytics section provides insights into the performance and impact of your articles."
    },
    {
        question: "What is the Learners tab used for?",
        answer: "The Learners tab provides an overview of all existing learners on the platform. It serves as a central hub for managing user accounts and accessing learner details and activity logs."
    },
    {
        question: "What learner details can I view in the Learners tab?",
        answer: "In the Learners tab, you can view essential details such as the username, first name, last name, email, and status (active or deactivated) for each learner."
    },
    {
        question: "How can I access and manage individual learner accounts?",
        answer: "By clicking on a learner's username, you can access their detailed profile, including personal information and activity logs. Additionally, you have the option to edit user details, such as name and email, or deactivate the user if necessary."
    },
    {
        question: "How do I create new learners on the platform?",
        answer: "To create a new learner, click on the Add Learners button, which opens an overlay with two tabs: Create Single User and Bulk Upload User. You can choose the appropriate tab based on whether you're creating a single user or multiple users at once."
    },
    {
        question: "What is the Analytics Tab?",
        answer: "The Analytics Tab is a feature within our platform that provides detailed insights and reports related to learners, courses, articles, and examinations. It allows creators to analyze user activity and performance to improve the learning experience."
    },
    {
        question: "What are the main sections available in the Learners Analytics?",
        answer: "The Learners Analytics section comprises three tabs: Dashboard, Course Status, and Activity Log. Each tab offers specific insights into learner engagement, course progress, and activity history."
    },
    {
        question: "What types of actions are recorded in the Activity Log tab?",
        answer: "The Activity Log tab logs various learner actions, such as login/logout events, course access, completion events, and other relevant activities. Creators can use this log to track user interactions and identify patterns in learner behavior."
    },
    {
        question: "Can I export analytics reports for further analysis or sharing?",
        answer: "Yes, creators have the option to download analytics reports in CSV for further analysis or sharing. This feature enables collaboration and informed decision-making based on data-driven insights."
    },
    {
        question: "How often is the analytics data updated?",
        answer: "The analytics data is updated in real-time or at regular intervals, ensuring that creators have access to the most up-to-date information on learner activity and course performance."
    },
    {
        question: "What is the Courses tab in the Analytics section?",
        answer: "The Courses tab provides comprehensive insights and analytics for each course available on our platform. It offers detailed information about course content, learner engagement, progress, and performance."
    },
    {
        question: "What information does the Summary tab provide for a course in analytics?",
        answer: "The Summary tab offers an overview of key metrics related to the selected course, including content distribution, progress status, time spent, course structure (sequential/non-sequential), number of topics, decks (content), and enrolled learners. Creators can also track the course's status (draft or published) and monitor completion progress using a progress bar."
    },
    {
        question: "How can I monitor learner performance in quizzes, surveys, and games embedded within course analytics?",        
        answer: "The Quizzes, Surveys, and Games tabs within a course offer insights into learner responses. Creators can view learner-wise and question-wise responses, download reports, and analyze performance metrics to assess learning outcomes and course effectiveness."
    },
    {
        question: "What is the Article Analytics section?",
        answer: "The Article Analytics section provides insights and data related to articles published on our platform. It offers detailed information about article type, audience engagement, comments, and content interactions."
    },
    {
        question: "What information does the Summary tab provide for an article?",
        answer: "The Summary tab offers an overview of key metrics and settings associated with the selected article, including article type, shareability, view mode (full view/clean view), interaction settings (interactions on/off), and engagement metrics such as views and time spent."
    },
    {
        question: "Can I view and manage comments received on an article?",
        answer: "Yes, the Comments tab allows creators to view a list of comments received on the selected article. This feature enables creators to monitor and engage with audience feedback effectively."
    },
    {
        question: "What is the Community Tab?",
        answer: "The Community Tab is a feature within our platform that allows creators to share posts with the community. It provides a space for users to engage with each other through various types of content."
    },
    {
        question: "What are the different types of posts I can create in the Community Tab?",
        answer: "You can create four types of posts: Text, Link, Image, and Video. Each type allows you to share different kinds of content with the community."
    },
    {
        question: "How can I search for a specific post within the Community Tab?",
        answer: "You can search for a post by entering keywords from its text content or by typing the author's name in the search bar. Our search feature will then display relevant posts based on your query."
    },
    {
        question: "Can creators delete posts created by other users?",
        answer: "Yes, creators have the option to delete any post within the Community Tab, regardless of who created it. This feature gives creators control over the content shared within the community."
    },
    {
        question: "What actions can I take on posts within the Community Tab?",
        answer: "You can like, comment, share, and delete posts within the Community Tab. Liking a post shows your appreciation for the content, commenting allows you to share your thoughts or feedback, and sharing enables you to distribute the post link across various social platforms."
    },
];
