// Import from NPM
// -------------------------------------
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import axios from "axios";
import { baseUrl, useGetCreditsQuery } from "@api/apiV6";

import _ from "lodash";
import { Div, Button } from "@components/Generics.react";
import {
    Checkbox,
    Divider,
    Dropdown,
    Input,
    TextArea,
    Dimmer,
    Loader,
} from "semantic-ui-react";
import { settings } from "@config/settings/app.settings";

export default function AISGenerator(props) {
    const { deck, setDeck, setRightPanel } = props;
    const token = useSelector((state) => state.auth.token);
    const { data: aiStatus, isSuccess, refetch } = useGetCreditsQuery();
    const { t } = useTranslation("common");

    const [source, setSource] = useState("");
    const [sCount, setSCount] = useState(5);

    const [fetching, setFetching] = useState(false);
    const [langValue, setLangValue] = useState("English");
    const [mode, setMode] = useState(true);

    const creditsNeeded = Math.ceil(source.length / 300 + sCount / 2);

    async function getAIResponse() {
        setFetching(true);
        const response = await axios({
            method: "post",
            url: `${baseUrl}api/v3/ai/genSlides`,
            data: {
                source: source,
                scount: sCount,
                lang: langValue,
                cost: creditsNeeded,
                basis: "source",
            },
            headers: { "access-token": token },
        });
        const output = formatOutput(
            JSON.parse(response.data.response.message.content).slides
        );
        if (mode)
            setDeck({
                ...deck,
                ...{ content: output },
            });
        else
            setDeck({
                ...deck,
                ...{ content: _.concat(deck.content, output) },
            });
        setFetching(false);
        refetch();
        setRightPanel("mobile");
    }

    function formatOutput(data) {
        return _.map(data, (row, rowNo) => {
            return {
                template: row.template,
                slideSeq: mode ? rowNo : rowNo + deck.content.length,
                nav: {
                    next: true,
                    prev: true,
                },
                data: row.data,
            };
        });
    }

    // ========================= Render Function =================================
    return (
        <Div
            minWd="400px"
            wd="400px"
            fullht
            relative
            peat
            basepad
            ht={`calc(100vh - 225px)`}
            darkInset
        >
            <Div white fullht rounded basepad slightShadow>
                <Div bold large compact uppercase>
                    {t("builder.deck.aisgen")}:
                </Div>
                <Div italics gutter compact>
                    {t("builder.deck.aisgenHelp")}
                </Div>
                {isSuccess && (
                    <Div rounded>
                        <Div padBottom>
                            <Checkbox
                                toggle
                                label={
                                    mode
                                        ? "Replace the Deck with"
                                        : "Add to the Deck"
                                }
                                checked={mode}
                                onChange={() => setMode(!mode)}
                            />
                        </Div>
                        <Input
                            fluid
                            type="number"
                            label="Slides"
                            labelPosition="right"
                            value={sCount}
                            onChange={(e) =>
                                setSCount(Math.max(e.target.value, 4))
                            }
                        />
                        <Divider horizontal>based on</Divider>
                        <TextArea
                            style={{
                                width: "100%",
                                border: "1px solid #cccccc",
                            }}
                            rows={8}
                            value={source}
                            onChange={(e) => setSource(e.target.value)}
                        />
                        <Divider horizontal>in</Divider>
                        <Dropdown
                            placeholder={t("builder.dic.selectLanguage")}
                            fluid
                            selection
                            options={_.map(settings.languages, (l) => ({
                                key: l.code,
                                text: l.identifier,
                                value: l.identifier,
                            }))}
                            value={langValue}
                            onChange={(e, { value }) => setLangValue(value)}
                        />
                        <br />
                        <Button
                            size="small"
                            primary
                            onClick={getAIResponse}
                            disabled={aiStatus.credits <= 0}
                        >
                            Generate ({creditsNeeded}{" "}
                            {creditsNeeded === 1 ? "credit" : "credits"})
                        </Button>
                        <Div
                            small
                            italics
                            float-right
                            nudgeRight
                            ht="40px"
                            flex
                        >
                            {aiStatus.credits} generative credits left
                        </Div>
                    </Div>
                )}
            </Div>
            {fetching && (
                <Div superpad ivory>
                    <Dimmer active>
                        <Loader>
                            This will take time. Please be patient...
                        </Loader>
                    </Dimmer>
                </Div>
            )}
        </Div>
    );
}
