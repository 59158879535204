import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import "@styles/readers.scss";
import CircularButton from "@components/CircularButton.react";
import { Image } from "semantic-ui-react";
import { Div, HDiv, Button } from "@components/Generics.react";

export default function ProductCard(props) {
    const { deck, colors, currentState, recordAttempt } = props;
    const imageRef = useRef(null);
    const [activeSectionIdx, setActiveBtnIdx] = useState(0);

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentState]);

    const sections = deck?.content?.sections;
    const activeSection = sections ? sections[activeSectionIdx] : null;

    return (
        <Div maxWd="400px" ht="500px" medpad centered grey rounded center-align>
            <Div fluid fullht charcoal priText className="product-card-reader">
                <Div relative topRounded noOverflow>
                    <div ref={imageRef}>
                        <Image fluid src={deck?.content?.image} />
                        <Div
                            midnight
                            centered
                            bold
                            topRounded
                            className="product-card-name"
                            uppercase
                        >
                            {deck?.content?.name}
                        </Div>
                    </div>
                </Div>

                {sections && (
                    <Div
                        fluid
                        ht={
                            imageRef.current
                                ? `calc(100% - ${imageRef.current?.clientHeight}px)`
                                : "calc(100% - 140px)"
                        }
                        bottomRounded
                        noOverflow
                    >
                        <Div fullht autoOverflowY relative>
                            <Div className="button-container">
                                {sections.map((button, index) => (
                                    <CircularButton
                                        key={`product-card-btn-${index}`}
                                        active={index === activeSectionIdx}
                                        colors={colors}
                                        color={button.color}
                                        icon={button.icon}
                                        onClick={() => setActiveBtnIdx(index)}
                                    />
                                ))}
                            </Div>
                            {activeSection && (
                                <Div
                                    className="product-card-content"
                                    fullht
                                    float-right
                                    white
                                    flex
                                    left-align
                                >
                                    {activeSection?.image && (
                                        <Image
                                            fluid
                                            src={activeSection?.image}
                                        />
                                    )}
                                    <Div className="product-card-inner">
                                        <Div big bold padTop>
                                            {activeSection?.title}
                                        </Div>
                                        {activeSection?.description && (
                                            <HDiv
                                                gapTop
                                                content={
                                                    activeSection?.description
                                                }
                                            />
                                        )}
                                        {activeSection.buttons && (
                                            <Div gapTop flex flexStart gapped>
                                                {activeSection.buttons.map(
                                                    (btn, index) => {
                                                        if (!btn?.btnLink)
                                                            return (
                                                                <Div>
                                                                    {btn?.text}
                                                                </Div>
                                                            );
                                                        else if (
                                                            btn?.btnLink.includes(
                                                                "http"
                                                            )
                                                        )
                                                            return (
                                                                <a
                                                                    href={
                                                                        btn?.btnLink
                                                                    }
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    key={`prod-card-link-${index}`}
                                                                >
                                                                    <Div fluid>
                                                                        <Button
                                                                            primary
                                                                            fluid
                                                                        >
                                                                            {
                                                                                btn?.text
                                                                            }
                                                                        </Button>
                                                                    </Div>
                                                                </a>
                                                            );
                                                        else
                                                            return (
                                                                <NavLink
                                                                    to={
                                                                        btn?.btnLink
                                                                    }
                                                                    key={`prod-card-link-${index}`}
                                                                >
                                                                    <Div fluid>
                                                                        <Button
                                                                            primary
                                                                            fluid
                                                                        >
                                                                            {
                                                                                btn
                                                                                    .buttons
                                                                                    ?.text
                                                                            }
                                                                        </Button>
                                                                    </Div>
                                                                </NavLink>
                                                            );
                                                    }
                                                )}
                                            </Div>
                                        )}
                                    </Div>
                                </Div>
                            )}
                        </Div>
                    </Div>
                )}
            </Div>
        </Div>
    );
}
