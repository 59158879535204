import React from "react";
import { Div } from "@components/Generics.react";
import { useTranslation } from "react-i18next";
import RadialProgressChart from "@components/charts/RadialProgressChart.react";

export default function EngagementRadials(props) {
    const { user, aggregateData, size = 80 } = props;
    const { t } = useTranslation("common");

    return (
        <Div flex column flexStart top-align center-align padSides>
            <Div fluid gutter>
                <RadialProgressChart
                    value={parseInt(
                        (aggregateData.liked_by * 100) /
                            aggregateData.teamCount,
                        0
                    )}
                    width={size}
                    height={size}
                    color={user.branding?.colors?.main}
                />
                <Div uppercase gapTop>
                    {t("analytics.likedBy")}
                </Div>
            </Div>
            <Div fluid gutter>
                <RadialProgressChart
                    value={parseInt(
                        (aggregateData.disliked_by * 100) /
                            aggregateData.teamCount,
                        0
                    )}
                    width={size}
                    height={size}
                    color={user.branding?.colors?.main}
                />
                <Div uppercase gapTop>
                    {t("analytics.dislikedBy")}
                </Div>
            </Div>
            <Div fluid gutter>
                <RadialProgressChart
                    value={parseInt(
                        (aggregateData.commented_by * 100) /
                            aggregateData.teamCount,
                        0
                    )}
                    width={size}
                    height={size}
                    color={user.branding?.colors?.main}
                />
                <Div uppercase gapTop>
                    {t("analytics.commentedBy")}
                </Div>
            </Div>
        </Div>
    );
}
