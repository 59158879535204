import React from "react";
import _ from "lodash";
import { Grid, Image } from "semantic-ui-react";

import { Div } from "@components/Generics.react";
import Banner from "@components/Banner.react";
import TopicCard from "@components/TopicCard.react";
import CourseHeader from "./CourseHeader.react";
import { deckFormats } from "@schemas/deckFormats";
export default function ExamWrapper(props) {
    const {
        locData,
        course,
        showMenu,
        openDeck,
        courseHeader = true,
    } = props;
    const isPortrait = window.innerHeight > window.innerWidth;
    const imageSrc = !course?.image
        ? "/assets/images/configurable/missing.jpg"
        : course.image;

    // ========================= Render Function =================================
    return (
        <Div
            fullht={isPortrait && showMenu}
            padded={!isPortrait}
            noOverflow={isPortrait && showMenu}
        >
            <Div trench={!isPortrait}>
                <Image fluid src="/assets/images/configurable/appexam.jpg" />
            </Div>
            <Grid stackable divided="vertically">
                <Grid.Row>
                    <Grid.Column width={16}>
                        {courseHeader && (
                            <CourseHeader course={course} compact noTrench/>
                        )}
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Banner ads={[{ image: imageSrc }]} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        {locData.length > 0 && (
                            <Div rounded noOverflow slightShadow>
                                <Div
                                    relative
                                    basepad
                                    style={{
                                        backgroundImage: `url(${locData[0].image})`,
                                        backgroundSize: "cover",
                                    }}
                                    half
                                >
                                    <Div
                                        absolute
                                        fullht
                                        fluid
                                        charcoal
                                        opacity="0.65"
                                        style={{ top: 0, left: 0 }}
                                    />
                                    <Div
                                        large
                                        gutter
                                        altText
                                        uppercase
                                        relative
                                    >
                                        {locData[0].name}
                                    </Div>
                                    <Div gutter relative>
                                        {locData[0].description}
                                    </Div>
                                </Div>
                                <Div big>
                                    {locData[0].contentList.map(
                                        (thisLoc, locIndex) => {
                                            if (thisLoc != null) {
                                                const active =
                                                    locIndex > 0
                                                        ? locData[0]
                                                              .currentState
                                                              .subStates[
                                                              locData[0]
                                                                  ?.contentList[
                                                                  locIndex - 1
                                                              ]._id
                                                          ]
                                                        : true;
                                                return (
                                                    <TopicCard
                                                        key={`topic-${locIndex}`}
                                                        locIndex={locIndex}
                                                        colors={course.colors}
                                                        topic={_.omit(
                                                            thisLoc,
                                                            "status"
                                                        )}
                                                        completed={
                                                            locData[0]
                                                                .currentState
                                                                .subStates[
                                                                locData[0]
                                                                    ?.contentList[
                                                                    locIndex
                                                                ]._id
                                                            ]
                                                        }
                                                        status={
                                                            active
                                                                ? "complete"
                                                                : "incomplete"
                                                        }
                                                        clickable={
                                                            thisLoc.status !==
                                                            "incomplete"
                                                        }
                                                        handleClick={() => {
                                                            if (active)
                                                                openDeck(
                                                                    thisLoc._id,
                                                                    _.findKey(
                                                                        deckFormats,
                                                                        {
                                                                            readerType:
                                                                                thisLoc.readerType,
                                                                        }
                                                                    )
                                                                );
                                                        }}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <Div
                                                        key={
                                                            "location-" +
                                                            locIndex
                                                        }
                                                        className="no-loc"
                                                    />
                                                );
                                            }
                                        }
                                    )}
                                </Div>
                            </Div>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Div>
    );
}
