import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFeatures } from "flagged";
import { useGetTeamUsersQuery } from "@api/apiV6";
import { useSearchParams } from "react-router-dom";

import BrowserBar from "@components/BrowserBar.react";
import BuilderHeader from "@components/BuilderHeader.react";
import CreatorLayout from "@layouts/CreatorLayout.react";
import { Div, Button } from "@components/Generics.react";
import { Label, Loader, Icon, Divider } from "semantic-ui-react";
import AddUserModal from "./panels/AddUserModal.react";
import UserDetails from "./panels/UserDetails.react";
import { toast } from "react-toastify";
import UserTable from "./panels/UserTable.react";

export default function UserManagement(props) {
    const user = useSelector((state) => state.auth.user);
    const features = useFeatures();
    const {
        data: audienceData,
        isSuccess,
        isLoading,
        refetch,
    } = useGetTeamUsersQuery(
        features.tenanting.multiTenanted ? user.team : "overall"
    );
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [newModalOpen, setNewModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [target, setTarget] = useState(null);
    const [showTable, setShowTable] = useState(null);
    const { t } = useTranslation("common");

    useEffect(() => {
        if (target) setSelectedUser(target.key);
    }, [target]);

    const userList = [
        {
            section: "users",
            name: t("builder.welcome.userLibrary"),
            data: isSuccess
                ? _.map(
                      _.orderBy(
                          audienceData,
                          ["active", "first_name", "last_name"],
                          ["desc", "asc", "asc"]
                      ),
                      (l) => {
                          return {
                              ...l,
                              name: `${l.first_name} ${l.last_name} (${l.username})`,
                              parent: {
                                  _id: t("builder.dic.unallocated"),
                                  name: t("builder.dic.unallocated"),
                              },
                          };
                      }
                  )
                : [],
            extra: [],
            pin: [],
            collection: t("builder.dic.programs"),
            groupBy: null,
        },
    ].filter(Boolean);

    const btnSections = [
        {
            name: t("admin.userMgmt.addUser"),
            icon: "add",
            action: () => setNewModalOpen(true),
        },
    ];
    const [activeSection, setActiveSection] = useState(userList[0].name);
    useEffect(() => {
        const thisTarget = searchParams.get("target");
        const thisSection = searchParams.get("section");
        if (thisTarget != null && thisSection != null) {
            setTarget({
                key: thisTarget,
                type: "child",
                section: thisSection,
            });
        }
    }, [searchParams]);

    const setSelected = (selectedTarget) => {
        setSearchParams(
            selectedTarget?.type === "child"
                ? {
                      target: selectedTarget.key,
                      section: selectedTarget.section,
                  }
                : {}
        );
        setTarget(selectedTarget);
        if (selectedTarget == null) setSelectedUser(null);
    };

    const activeItem = searchParams.get("target");

    const limitBust =
        audienceData &&
        _.filter(audienceData, { active: true }).length >=
            user.license.maxUsers;

    async function refreshData() {
        await refetch();
        toast(t("builder.welcome.pageRefreshed"));
    }

    const mainPanel =
        isLoading || !isSuccess ? (
            <Loader active />
        ) : (
            <Div fluid fullht>
                <Div fluid fullht flex spaceBetween>
                    <Div wd="300px" fullht vapor slightShadow>
                        <BrowserBar
                            name={t("builder.welcome.usersHeader")}
                            contentSections={userList}
                            btnSections={btnSections}
                            setTarget={setSelected}
                            setSection={setActiveSection}
                            activeSec={activeSection}
                            activeItem={activeItem}
                            localStore="userRecents"
                            maxRecents={3}
                            image="appusers.jpg"
                            showListing
                            refetch={refreshData}
                        />
                    </Div>
                    <Div wd="calc(100% - 300px)" fullht>
                        <BuilderHeader
                            image={"/assets/images/configurable/appusers.jpg"}
                            header={t("builder.headers.users.header")}
                            description={t("builder.headers.users.help")}
                        />
                        {selectedUser == null ? (
                            <Div
                                flex={!showTable}
                                ht="calc(100% - 200px)"
                                fluid
                            >
                                {showTable ? (
                                    <Div basepad>
                                        <UserTable
                                            audienceData={audienceData}
                                            refetch={refetch}
                                        />
                                    </Div>
                                ) : (
                                    <Div
                                        ash
                                        megapad
                                        center-align
                                        slightShadow
                                        wd="500px"
                                    >
                                        <Icon
                                            size="huge"
                                            name="users"
                                            circular
                                            inverted
                                            color="grey"
                                        />
                                        <Div
                                            padTop
                                            headline
                                            uppercase
                                            fluid
                                            altText
                                            gutter
                                        >
                                            {t("builder.welcome.userLibrary")}
                                        </Div>
                                        <Div large>
                                            {t("builder.welcome.selectUser")}
                                        </Div>
                                        <Divider horizontal>
                                            {t("components.or")}
                                        </Divider>
                                        {!features.general.subscribable ? (
                                            <Button
                                                size="small"
                                                primary
                                                icon="plus"
                                                labelPosition="right"
                                                content={t(
                                                    "admin.userMgmt.addUser"
                                                )}
                                                onClick={() =>
                                                    setNewModalOpen(true)
                                                }
                                            />
                                        ) : (
                                            <Div flex gapped>
                                                <Label
                                                    color={
                                                        limitBust
                                                            ? "red"
                                                            : "teal"
                                                    }
                                                >
                                                    <Icon name="users" />
                                                    {
                                                        _.filter(audienceData, {
                                                            active: true,
                                                        }).length
                                                    }{" "}
                                                    / {user.license.maxUsers}
                                                </Label>
                                                <Button
                                                    size="small"
                                                    primary
                                                    icon={
                                                        limitBust
                                                            ? "caret square up"
                                                            : "plus"
                                                    }
                                                    labelPosition={"right"}
                                                    content={
                                                        limitBust
                                                            ? "Upgrade"
                                                            : t(
                                                                  "admin.userMgmt.addUser"
                                                              )
                                                    }
                                                    onClick={() =>
                                                        limitBust
                                                            ? navigate(
                                                                  "/builder/account"
                                                              )
                                                            : setNewModalOpen(
                                                                  true
                                                              )
                                                    }
                                                />
                                            </Div>
                                        )}
                                        {features.general.subscribable && (
                                            <Button
                                                size="small"
                                                secondary
                                                icon={"table"}
                                                labelPosition={"right"}
                                                content={t(
                                                    "admin.userMgmt.viewTable"
                                                )}
                                                onClick={() =>
                                                    setShowTable(true)
                                                }
                                            />
                                        )}
                                    </Div>
                                )}
                            </Div>
                        ) : (
                            <Div flex ht="calc(100% - 150px)" fluid>
                                <UserDetails
                                    userId={selectedUser}
                                    setSelectedUser={setSelected}
                                />
                            </Div>
                        )}
                    </Div>
                </Div>
                <AddUserModal
                    isOpen={newModalOpen}
                    setOpen={setNewModalOpen}
                    ownerTeam={user.team}
                />
            </Div>
        );

    // ========================= Render Function =================================
    return (
        <CreatorLayout
            title={t("appCreator")}
            navName={t("admin.userMgmt.navName")}
            pageCode="users"
            mainPanel={mainPanel}
            rootPage={true}
        />
    );
}
