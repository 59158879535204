import React, { useMemo } from "react";
import _ from "lodash";
import { useGetArticlesQuery } from "@api/apiV6";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Div } from "@components/Generics.react";
import "@styles/components.scss";
import ArticleCard from "@components/ArticleCard.react";

export default function RecommendedArticles(props) {
    const { isSuccess } = useGetArticlesQuery();
    const articles = useSelector((state) => state.articles);
    // const user = useSelector((state) => state.auth.user);
    const { t } = useTranslation("common");

    const recommended = useMemo(
        () =>
            isSuccess
                ? _.orderBy(
                      articles?.list,
                      ["priority", "updatedAt"],
                      ["desc", "desc"]
                  ).slice(0, 10)
                : [],
        [articles, isSuccess]
    );

    // ========================= Render Function =================================
    return (
        <Div nopad fullht>
            <Div big padTop gutter bold uppercase>
                {t("components.recommended")}
            </Div>
            {isSuccess && (
                <Div autoOverflow padBottom ht="calc(100% - 45px)">
                    {_.map(recommended, (article) => {
                        return (
                            <ArticleCard
                                key={`article-right-${article?._id}`}
                                article={article}
                                feedStyle
                            />
                        );
                    })}
                </Div>
            )}
        </Div>
    );
}
