import { useState } from "react";
import { Icon, List, Image } from "semantic-ui-react";
import AudioPlayer from "react-h5-audio-player";
import ReactPlayer from "react-player/lazy";
import { Div } from "@components/Generics.react";

import "react-h5-audio-player/lib/styles.css";
import "@styles/readers.scss";

export default function PlaylistReader(props) {
    let dummyData = {
        type: "audio",
        playlist: [
            {
                name: "Moonlight",
                description: "Ocean Bloom",
                image: "https://picsum.photos/1000/700",
                link: "https://file-examples.com/storage/fec91aecf9647d0f79cc0bb/2017/11/file_example_MP3_700KB.mp3",
            },
            {
                name: "Life is Beautiful",
                description: "Aylex",
                image: "https://picsum.photos/1000/700",
                link: "https://filesamples.com/samples/audio/mp3/Symphony%20No.6%20(1st%20movement).mp3",
            },
            {
                name: "Information Flow",
                description: "Aylex",
                image: "https://picsum.photos/1000/700",
                link: "https://file-examples.com/storage/fec91aecf9647d0f79cc0bb/2017/11/file_example_MP3_700KB.mp3",
            },
            {
                name: "Runaway",
                description: "Ocean Bloom",
                image: "https://picsum.photos/1000/700",
                link: "https://filesamples.com/samples/audio/mp3/Symphony%20No.6%20(1st%20movement).mp3",
            },
            {
                name: "Departure",
                description: "Soyb",
                image: "https://picsum.photos/1000/600",
                link: "https://file-examples.com/storage/fec91aecf9647d0f79cc0bb/2017/11/file_example_MP3_700KB.mp3",
            },
        ],
    };

    const { type, playlist } = dummyData;
    const [activeIndex, setActiveIndex] = useState(0);

    const DetailsSection = () => {
        return (
            <Div spaced relative layer={2}>
                <Div center-align bold big>
                    {playlist[activeIndex].name}
                </Div>
                <Div center-align normal txtColor="#5780b7">
                    {playlist[activeIndex].description}
                </Div>
            </Div>
        );
    };

    const onSelectItem = (index) => {
        setActiveIndex(index);
        const element = document.getElementById("active-panel");
        element.scrollIntoView({ behavior: "smooth" });
    };

    const onEndEventHandler = () => {
        let active = activeIndex;
        let playlistLength = playlist.length;

        if (active === playlistLength - 1) {
            setActiveIndex(0);
        } else {
            setActiveIndex(active + 1);
        }
    };

    return (
        <Div
            fullht
            priText
            scrollOverflow
            bgColor="#02052e"
            className="playlist-reader"
        >
            <Div id="active-panel" centered white bottomRounded basepad>
                {type === "audio" && (
                    <>
                        <Div
                            wd="calc(100% - 60px)"
                            centered
                            relative
                            trench
                            layer={1}
                        >
                            <Image
                                src={playlist[activeIndex].image}
                                className="image-radius playlist-active-image"
                            />
                        </Div>
                        {DetailsSection()}
                        <AudioPlayer
                            autoPlay
                            src={playlist[activeIndex].link}
                            customIcons={{
                                play: (
                                    <Icon
                                        name="play circle outline"
                                        color="black"
                                    />
                                ),
                                pause: (
                                    <Icon name="pause circle " color="black" />
                                ),
                            }}
                            onEnded={onEndEventHandler}
                        />
                    </>
                )}
                {type === "video" && (
                    <>
                        <Div className="video-responsive playlist-active-image image-radius">
                            <ReactPlayer
                                url={playlist[activeIndex].link}
                                className="react-player"
                                playing={true}
                                width="100%"
                                height="100%"
                                controls
                                onEnded={onEndEventHandler}
                            />
                        </Div>
                        {DetailsSection()}
                    </>
                )}
            </Div>

            <Div basepad priText>
                <List size="huge" verticalAlign="middle">
                    {playlist.map((item, index) => {
                        return (
                            <List.Item
                                key={`playlist-item-${index}`}
                                onClick={() => onSelectItem(index)}
                                className="list-item"
                            >
                                <Image
                                    size="tiny"
                                    className="image-radius"
                                    src="https://picsum.photos/1000/750"
                                />
                                <List.Content>
                                    <Div bold tiny>
                                        {item.name}
                                    </Div>
                                    <Div tiny txtColor="#5780b7">
                                        {item.description}
                                    </Div>
                                </List.Content>
                            </List.Item>
                        );
                    })}
                </List>
            </Div>
        </Div>
    );
}
