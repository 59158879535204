import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDeactivateUserMutation, useActivateUserMutation } from "@api/apiV6";
import _ from "lodash";
import ReactTable from "react-table-v6";
import { toast } from "react-toastify";

import { Div, Button } from "@components/Generics.react";
import { Button as XButton, Checkbox, Confirm } from "semantic-ui-react";

export default function UserTable(props) {
    const { audienceData, refetch } = props;
    const { t } = useTranslation("common");
    const reactTableRef = useRef(null);

    const [confirmFn, setConfirmFn] = useState(null);
    const [pickedUsers, setPickedUsers] = useState([]);
    const [pickedAll, setPickedAll] = useState(false);

    const [deactivateUser] = useDeactivateUserMutation();
    const [activateUser] = useActivateUserMutation();

    useEffect(() => {
        if (pickedAll) {
            const items = _.map(
                reactTableRef.current.getResolvedState().sortedData,
                "_original"
            );
            setPickedUsers(
                _.map(
                    _.filter(items, (item) => item.username !== "sysadmin"),
                    "_id"
                )
            );
        } else {
            setPickedUsers([]);
        }
    }, [pickedAll]);

    async function bulkActivate() {
        let processedUsers = 0;
        for (let i = 0; i < pickedUsers.length; i++) {
            const updateResponse = await activateUser(pickedUsers[i]);
            if (updateResponse) processedUsers++;
        }
        toast(`${processedUsers} users Activated`);
        setConfirmFn(false);
    }
    async function bulkDeactivate() {
        let processedUsers = 0;
        for (let i = 0; i < pickedUsers.length; i++) {
            const updateResponse = await deactivateUser(pickedUsers[i]);
            if (updateResponse) processedUsers++;
        }
        toast(`${processedUsers} users Deactivated`);
        setConfirmFn(false);
    }

    const downloadCSV = () => {
        const items = _.map(audienceData, (a) =>
            _.pick(a, [
                "username",
                "first_name",
                "last_name",
                "email",
                "active",
            ])
        );
        if (items.length > 0) {
            const replacer = (key, value) => (value == null ? "" : value); // specify how you want to handle null values here
            const header = Object.keys(items[0]);
            let csv = items.map((row) =>
                header
                    .map((fieldName) =>
                        JSON.stringify(row[fieldName], replacer)
                    )
                    .join(",")
            );
            csv.unshift(header.join(","));
            csv = csv.join("\r\n");
            var hiddenElement = document.createElement("a");
            hiddenElement.href =
                "data:text/csv;charset=utf-8," + encodeURI(csv);
            hiddenElement.target = "_blank";
            hiddenElement.download = `Userlist.csv`;
            hiddenElement.click();
        }
    };

    let fullColumns = [
        {
            Header: ({ row }) => (
                <Checkbox
                    checked={pickedAll}
                    onClick={() => setPickedAll(!pickedAll)}
                />
            ),
            id: `check`,
            accessor: (row) => row._id,
            Cell: ({ row }) =>
                row._original.username !== "sysadmin" ? (
                    <Checkbox
                        checked={_.includes(pickedUsers, row._original._id)}
                        onClick={() =>
                            setPickedUsers(
                                _.includes(pickedUsers, row._original._id)
                                    ? _.without(pickedUsers, row._original._id)
                                    : _.concat(pickedUsers, row._original._id)
                            )
                        }
                    />
                ) : (
                    <Div />
                ),
            maxWidth: 50,
            style: { textAlign: "center" },
        },
        {
            Header: "Name",
            id: "name",
            accessor: (u) => `${u.first_name} ${u.last_name}`,
            minWidth: 128,
        },
        {
            Header: "Username",
            id: "username",
            accessor: "username",
            minWidth: 240,
        },
        {
            Header: "Active",
            id: "active",
            accessor: "active",
            maxWidth: 100,
            Cell: ({ row }) => (row._original.active ? "Yes" : "No"),
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                }
                if (filter.value === "Yes") {
                    return row[filter.id];
                }
                if (filter.value === "No") {
                    return !row[filter.id];
                }
                return row[filter.id];
            },
            Filter: ({ filter, onChange }) => (
                <select
                    onChange={(event) => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">All</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            ),
        },
        {
            Header: "Email",
            id: "email",
            accessor: "email",
        },
    ];

    // ========================= Render Function =================================
    return (
        <Div fullht clearfix>
            <Div fluid fullht>
                <Div white ht="calc(100% - 123px)" basepad>
                    <Div bold uppercase big>
                        {t("admin.userMgmt.viewTableHeader")}
                    </Div>
                    <Div gapTop gutter italics>
                        {t("admin.userMgmt.tableHelp")}
                    </Div>

                    {pickedUsers.length > 0 ? (
                        <Div rimmed half flex spaceBetween>
                            <Div big wd="50%" padded>
                                <Div bold>
                                    {t("admin.userMgmt.bulkOperations")}
                                </Div>
                                <Div tiny italics>
                                    {t("admin.userMgmt.onlyActive")}
                                </Div>
                            </Div>
                            <Div wd="50%" rimmed>
                                <XButton.Group fluid>
                                    <Button
                                        success={+true}
                                        content={`Activate ${pickedUsers.length} users`}
                                        onClick={() => setConfirmFn("activate")}
                                    />
                                    <Button
                                        danger={+true}
                                        content={`Deactivate ${pickedUsers.length} users`}
                                        onClick={() =>
                                            setConfirmFn("deactivate")
                                        }
                                    />
                                </XButton.Group>
                            </Div>
                        </Div>
                    ) : (
                        <Div fluid ash rimmed flex spaceBetween>
                            <Button
                                size="small"
                                secondary
                                icon="refresh"
                                labelPosition="left"
                                content={t("admin.userMgmt.refresh")}
                                onClick={refetch}
                            />
                            <Div>
                                <Button
                                    size="small"
                                    secondary
                                    icon="download"
                                    labelPosition="left"
                                    content="Download"
                                    onClick={downloadCSV}
                                />
                            </Div>
                        </Div>
                    )}
                    <Div ht={`${window.innerHeight - 330}px`} autoOverflowY>
                        <ReactTable
                            ref={reactTableRef}
                            data={_.orderBy(
                                audienceData,
                                ["first_name", "last_name"],
                                ["asc", "asc"]
                            )}
                            filterable
                            defaultFilterMethod={(filter, row, column) => {
                                const id = filter.pivotId || filter.id;
                                return row[id] !== undefined
                                    ? String(row[id])
                                          .toLowerCase()
                                          .includes(filter.value.toLowerCase())
                                    : true;
                            }}
                            columns={fullColumns}
                            pageSizeOptions={[5, 7, 10, 15, 30, 50, 100]}
                            defaultPageSize={7}
                            className="-striped -highlight"
                        />
                    </Div>
                </Div>
            </Div>
            <Confirm
                open={confirmFn === "activate"}
                onCancel={() => setConfirmFn(false)}
                onConfirm={bulkActivate}
            />
            <Confirm
                open={confirmFn === "deactivate"}
                onCancel={() => setConfirmFn(false)}
                onConfirm={bulkDeactivate}
            />
        </Div>
    );
}
