// Import from NPM
// -------------------------------------
import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Divider, Dropdown, Icon } from "semantic-ui-react";
import { Div } from "@components/Generics.react";
import { TemplateList } from "@schemas/templateSchema";

export default function SlideToolbar(props) {
    const {
        readerType,
        slideType,
        slideNum,
        setSlideNum,
        slideCount,
        currentTemplateName,
        setCurrentTemplateName,
        addSlide,
        cloneSlide,
        deleteSlide,
    } = props;
    const { t } = useTranslation("common");

    function gotoFirstSlide() {
        setSlideNum(0);
    }
    function prevSlide() {
        if (slideNum > 0) setSlideNum(slideNum - 1);
    }
    function nextSlide() {
        if (slideNum < slideCount - 1) setSlideNum(slideNum + 1);
    }
    function gotoLastSlide() {
        setSlideNum(slideCount - 1);
    }

    const templateListSchema = _.filter(TemplateList, (template) => {
        return template.target[readerType];
    });
    const selectedTemplateList = _.map(_.toPairs(templateListSchema), (elm) => {
        return {
            key: elm[1].key,
            value: elm[1].key,
            text: elm[1].name,
        };
    });

    const firstSlide = slideNum === 0;
    const lastSlide = slideNum === slideCount - 1;

    // ========================= Render Function =================================
    return (
        <Div fluid>
            <Div flex spaceBetween>
                <Div flex gapped nudgeLeft>
                    <Div
                        medpad
                        half={!firstSlide}
                        disabled={firstSlide}
                        slightShadow
                        clickable={!firstSlide}
                        onClick={gotoFirstSlide}
                        hoverBold={!firstSlide}
                    >
                        <Icon name="step backward" />
                    </Div>
                    <Div
                        medpad
                        half={!firstSlide}
                        disabled={firstSlide}
                        slightShadow
                        clickable={!firstSlide}
                        onClick={prevSlide}
                        hoverBold={!firstSlide}
                    >
                        <Icon name="chevron left" />
                    </Div>
                    <Div
                        wd="128px"
                        medpad
                        vapor
                        center-align
                        slightShadow
                        uppercase
                    >
                        {slideType} {slideNum + 1}/{slideCount}
                    </Div>
                    <Div
                        medpad
                        charcoal
                        clickable
                        slightShadow
                        hoverBold
                        onClick={addSlide}
                    >
                        <Icon name="plus" />
                    </Div>
                    <Div
                        medpad
                        charcoal
                        clickable
                        slightShadow
                        hoverBold
                        onClick={cloneSlide}
                    >
                        <Icon name="copy" />
                    </Div>
                    <Div
                        medpad
                        danger
                        clickable
                        slightShadow
                        hoverBold
                        onClick={deleteSlide}
                    >
                        <Icon name="close" />
                    </Div>
                    <Div
                        medpad
                        half={!lastSlide}
                        disabled={lastSlide}
                        slightShadow
                        hoverBold={!lastSlide}
                        clickable={!lastSlide}
                        onClick={nextSlide}
                    >
                        <Icon name="chevron right" />
                    </Div>
                    <Div
                        medpad
                        half={!lastSlide}
                        disabled={lastSlide}
                        slightShadow
                        hoverBold={!lastSlide}
                        clickable={!lastSlide}
                        onClick={gotoLastSlide}
                    >
                        <Icon name="step forward" />
                    </Div>
                </Div>
                <Div nudgeRight flex>
                    <Div medpad half>
                        {t("builder.deck.template")}:
                    </Div>
                    <Div wd="200px">
                        <Dropdown
                            placeholder="Select a template"
                            fluid
                            selection
                            search
                            options={selectedTemplateList}
                            value={currentTemplateName}
                            onChange={(e, { value }) => {
                                setCurrentTemplateName(value);
                            }}
                        />
                    </Div>
                </Div>
            </Div>
            <Divider style={{ marginTop: "-18.5px" }} />
        </Div>
    );
}
