export const Tutorials = [
    {
        name: "Create Your Lessons on TeachiQuo",
        meta: ["courseware"],
        description: "Let us explain how you can create Lessons on the platform",
        image: "/assets/images/configurable/lession.jpg",
        video: "https://youtu.be/QkIY7W_ganM-",
        home: true,
    },
    {
        name: "Create Game based Lesson on TeachiQuo",
        meta: ["learningCenter"],
        description:
            "Integrate captivating games into your platform for interactive learning",
        image: "/assets/images/configurable/game.jpg",
        video: "https://youtu.be/VmeV26tzkFA",
        home: true,
    },
    {
        name: "Manage your students on TeachiQuo",
        meta: ["social"],
        description: " Effortlessly add and manage Students for seamless educational experiences",
        image: "/assets/images/configurable/student.jpg",
        video: "https://youtu.be/V1uGVgPrM4k",
        home: true,
    },
    {
        name: "Create and Manage Exams on TeachiQuo",
        meta: ["common"],
        description:
            "Let us explain how you can create and manage Exams on the platform",
        image: "/assets/images/configurable/exam.jpg",
        video: "https://youtu.be/f_e_b4S4Dbo",
        home: true,
    }
];
