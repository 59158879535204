import React, { useState, useEffect } from "react";
import "@styles/components.scss";

const Countdown = ({ initialSeconds = 60, color, onZero }) => {
    const [seconds, setSeconds] = useState(initialSeconds);

    useEffect(() => {
        if (seconds > 0) {
            const interval = setInterval(() => {
                setSeconds(seconds - 1);
            }, 1000);

            return () => clearInterval(interval);
        } else {
            onZero();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seconds]);

    return (
        <div className="quo-countdown">
            {seconds > 0 && (
                <div
                    className="quo-pulse"
                    style={{ backgroundColor: color, opacity: 0.75 }}
                ></div>
            )}
            {seconds}
        </div>
    );
};

export default Countdown;
