import React from "react";
import { Div, HDiv } from "@components/Generics.react";
import { Divider } from "semantic-ui-react";

export default function ParagraphLine(props) {
    const { paragraph, caption } = props.data;

    // ========================= Render Function =================================
    return (
        <Div superpad big>
            <Div fluid bold zoom>
                {caption}
            </Div>
            <Divider />
            <HDiv fluid zoom content={paragraph} />
        </Div>
    );
}
