import React, { useState } from "react";
import _ from "lodash";
import { Div, Icon } from "@components/Generics.react";
import ReportList from "./analytics/ReportList.react";

export default function OrgReports(props) {
    const reportsList = [
        {
            name: "User List",
            description:
                "You can get the user list on the system here as on the date of the report",
            section: "userList",
            url: "crons/jobs/userList",
            filters: [],
        },
        {
            name: "Users Sessions",
            description:
                "You can get the access history for all the users in the specified time period. Session count is the number of days in the period that the user accessed the system.",
            section: "userSessions",
            url: "crons/jobs/userSessions/:timePeriod",
            filters: [
                "All",
                "Daily",
                "Weekly",
                "Monthly",
                "Annual",
                "Lifetime",
            ],
        },
        {
            name: "Users Activity",
            description:
                "You can get the activity log of all users in the specified time period",
            section: "userActivity",
            url: "crons/jobs/userActivity/:timePeriod",
            filters: ["All", "Daily", "Weekly", "Monthly"],
        },
        {
            name: "Users Courses",
            description:
                "You can get the progress and performance for all users on the system for all the courses assigned to them as on the date of the report.",
            section: "userCourses",
            url: "crons/jobs/userCourses/:timePeriod",
            filters: ["All", "Daily", "Lifetime"],
        },
        {
            name: "Users Articles",
            description:
                "You can get the progress and performance for all users across learning center articles as on the date of the report.",
            section: "userArticles",
            url: "crons/jobs/userArticles/:timePeriod",
            filters: [
                "All",
                "Daily",
                "Weekly",
                "Monthly",
                "Annual",
                "Lifetime",
            ],
        },
    ];
    const [activeSection, setActiveSection] = useState(null);
    return (
        <Div flex fluid fullht spaceBetween top-aligned>
            <Div wd="250px" smoke basepad>
                <Div bold uppercase gutter>
                    Select a Report:
                </Div>
                {_.map(reportsList, (r, idx) => (
                    <Div
                        padded
                        white={idx !== activeSection}
                        primary={idx === activeSection}
                        snug
                        clickable
                        key={`reportName-${idx}`}
                        onClick={() => setActiveSection(idx)}
                    >
                        {r.name}
                        <Div float-right>
                            <Icon name="chevron right" />
                        </Div>
                    </Div>
                ))}
            </Div>
            {activeSection != null ? (
                <Div wd="calc(100% - 270px)" charcoal fullht>
                    <Div basepad>
                        <Div big bold uppercase>
                            {reportsList[activeSection].name}
                        </Div>
                        <Div padBottom>
                            {reportsList[activeSection].description}
                        </Div>
                    </Div>
                    <ReportList
                        name={reportsList[activeSection].name}
                        description={reportsList[activeSection].description}
                        section={reportsList[activeSection].section}
                        filters={reportsList[activeSection].filters}
                        url={reportsList[activeSection].url}
                    />
                </Div>
            ) : (
                <Div flex ht="200px" wd="calc(100% - 270px)" vapor>
                    Please click on a report to progress...
                </Div>
            )}
        </Div>
    );
}
