import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Div } from "@components/Generics.react";
import CreatorLayout from "@layouts/CreatorLayout.react";
import { useGetPostsQuery, useSendSocialPostMutation } from "@api/apiV6";
import BuilderHeader from "@components/BuilderHeader.react";
import PostHandler from "./panels/PostHandler.react";
import { Input, Image, Icon } from "semantic-ui-react";
import PostCreator from "./panels/PostCreator.react";
import { toast } from "react-toastify";

export default function CommunityManager(props) {
    const { data: posts, refetch } = useGetPostsQuery();
    const { t } = useTranslation("common");
    const [searchText, setSearchText] = useState("");
    const [sendSocialPost] = useSendSocialPostMutation();

    async function refreshData() {
        await refetch();
        toast(t("builder.welcome.pageRefreshed"));
    }

    const isString = (value) => typeof value === "string";
    const allPosts = _.filter(posts, (post) => {
        const searchTextLower = searchText.toLowerCase();
        const includesIgnoreCase = (val) =>
            isString(val) && _.includes(val.toLowerCase(), searchTextLower);
        return (
            includesIgnoreCase(post.content) ||
            includesIgnoreCase(post.description) ||
            includesIgnoreCase(post.owner.first_name) ||
            includesIgnoreCase(post.owner.last_name)
        );
    });

    const mainPanel = (
        <Div fluid fullht>
            <Div flex flexStart top-align fullht>
                <Div wd="300px" fullht peat>
                    {window.innerHeight > 720 && (
                        <Div
                            wd="300px"
                            ht="145px"
                            noOverflow
                            slightShadow
                            relative
                        >
                            <Image src="/assets/images/configurable/appsocial.jpg" />
                            <Div
                                absolute
                                bold
                                large
                                uppercase
                                className="browser-bar-header"
                            >
                                {t("builder.welcome.socialHeader")}
                            </Div>
                            <Div
                                absolute
                                big
                                pureCircle
                                primary
                                clickable
                                rimmed
                                dropShadow
                                className="browser-bar-resync"
                            >
                                <Icon name="sync" onClick={refreshData} />
                            </Div>
                        </Div>
                    )}
                    <Div fullht basepad vapor>
                        <Div big bold uppercase gutter>
                            {t("social.createHeader")}:
                        </Div>
                        <PostCreator onSubmit={sendSocialPost} />
                    </Div>
                </Div>
                <Div wd="calc(100% - 300px)" fullht>
                    <BuilderHeader
                        image={"/assets/images/configurable/appsocial.jpg"}
                        header={t("builder.headers.social.header")}
                        description={t("builder.headers.social.help")}
                    />
                    <Div ash medpad ht="calc(100% - 123px)">
                        <Input
                            fluid
                            placeholder={t("components.search")}
                            icon={{ name: "search" }}
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        <Div basepad vapor autoOverflow ht="calc(100% - 40px)">
                            {_.map(allPosts, (post) => (
                                <PostHandler
                                    key={`Post-${post._id}`}
                                    post={post}
                                />
                            ))}
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Div>
    );
    return (
        <CreatorLayout
            title={t("social.navName")}
            navName={t("social.navName")}
            pageCode={"social"}
            mainPanel={mainPanel}
            rootPage={true}
        />
    );
}
