import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { primaryStyle, getPointsDelta } from "@utilities/helpers";
import "@styles/readers.scss";

import { Div } from "@components/Generics.react";
import Badge from "@components/Badge.react";

export default function BadgeReader(props) {
    const { deck, colors, currentState, recordAttempt } = props;
    const { t } = useTranslation("common");

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta:
                currentState && deck.points
                    ? getPointsDelta(currentState, deck.points)
                    : 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentState]);

    // ========================= Render Function =================================
    return (
        <Div maxWd="400px" superpad centered smoke rounded center-align>
            <Div wd="256px" centered gutter>
                <Badge deck={deck} />
            </Div>
            <Div fit-content large bold centered relaxed>
                {deck.content?.points} {t("deck.points")}
            </Div>
            <Div
                fit-content
                big
                centered
                relaxed
                rounded
                style={primaryStyle(colors)}
            >
                {deck.content?.name}
            </Div>
            <Div txtHalf centered center-align superpad gutter>
                {deck.content?.description}
            </Div>
            {/* <Div className="close-icon" onClick={closeDeck}>
                {t("deck.close")} <Icon name="close" inline fitted />
            </Div> */}
        </Div>
    );
}
