import React, { useState, useMemo } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Tab, Dropdown } from "semantic-ui-react";
import { Div } from "@components/Generics.react";
import QuizLearnersResponses from "../quizzes/QuizLearnersResponses.react";
import QuizQuestionsResponses from "../quizzes/QuizQuestionsResponses.react";

export default function CourseQuizzes(props) {
    const { course } = props;
    const [selectedFile, setSelectedFile] = useState(null);
    const { t } = useTranslation("common");
    const isPortrait = window.innerHeight > window.innerWidth;

    const fileList = useMemo(
        () =>
            _.flatMap(course.locations, (topic) => {
                return _.map(
                    _.filter(topic.contentList, { readerType: "assessment" }),
                    (deck) => {
                        return {
                            key: deck._id,
                            text: topic.name + " :: " + deck.name,
                            value: deck._id,
                            readertype: deck.readerType,
                            dname: deck.name,
                        };
                    }
                );
            }),
        [course]
    );

    function isCorrect(deckData, slideId, response) {
        const deckBlob = _.find(deckData.content, {
            slideSeq: parseInt(slideId.split("#")[1], 0),
        });
        let correct = false;
        if (deckBlob) {
            switch (deckBlob.template) {
                case "single_select":
                    if (
                        response ===
                        _.find(deckBlob.data.options, {
                            correct: "checked",
                        })?.text
                    )
                        correct = true;
                    break;
                case "multiple_select":
                    const correctSelected = _(deckBlob.data.multiselect)
                        .filter({ correct: "checked" })
                        .map("text")
                        .value();
                    if (
                        _.every(correctSelected, (item) =>
                            response.includes(item)
                        ) &&
                        _.every(response, (item) =>
                            correctSelected.includes(item)
                        )
                    )
                        correct = true;
                    break;
                case "true_false":
                    if (
                        response ===
                        _.find(deckBlob.data.choice, {
                            correct: "checked",
                        })?.text
                    )
                        correct = true;
                    break;
                case "fill_in_the_blanks":
                    if (
                        response.toLowerCase() ===
                        deckBlob.data.answer.toLowerCase()
                    )
                        correct = true;
                    break;
                case "pick_picture":
                    if (
                        response ===
                        _.findIndex(deckBlob.data.options, {
                            correct: "checked",
                        })
                    )
                        correct = true;
                    break;
                default:
                    if (
                        response.toLowerCase() ===
                        deckBlob.data.answer.toLowerCase()
                    )
                        correct = true;
            }
        }
        return correct;
    }

    const panes = [
        {
            menuItem: "Learner Responses",
            render: () => (
                <Tab.Pane>
                    <QuizLearnersResponses
                        deckId={selectedFile}
                        isCorrect={isCorrect}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Question Responses",
            render: () => (
                <Tab.Pane>
                    <QuizQuestionsResponses
                        deckId={selectedFile}
                        isCorrect={isCorrect}
                    />
                </Tab.Pane>
            ),
        },
    ];

    return (
        <>
            <Div flex clearfix gapBottom spaceBetween>
                <Div wd="190px" big uppercase bold>
                    {t("analytics.selectQuiz")}
                </Div>
                <Div wd={`calc(100% - 210px)`} bold>
                    <Dropdown
                        placeholder={t("analytics.selectResponse")}
                        search
                        fluid
                        selection
                        upward={isPortrait}
                        options={fileList}
                        value={selectedFile}
                        onChange={(e, { value }) => setSelectedFile(value)}
                    />
                </Div>
            </Div>
            {selectedFile != null ? (
                <Tab panes={panes} />
            ) : (
                <Div center-align large bold megapad>
                    {t("analytics.selectResponse")}
                </Div>
            )}
        </>
    );
}
