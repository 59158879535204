// Import from NPM
// -------------------------------------
import React, { useState } from "react";
import _ from "lodash";
import shortid from "shortid";
import { Dropdown, Input, Modal, Icon } from "semantic-ui-react";

import { useCreateDeckMutation } from "@api/apiV6";
import { useTranslation } from "react-i18next";
import { deckFormats } from "@schemas/deckFormats";

import { Div, Button } from "@components/Generics.react";
import "@styles/navbars.scss";

export default function ContestContentModal(props) {
    const { newModalOpen, setNewModalOpen, contestId, topicId, sequence } =
        props;

    const [readerType, setReaderType] = useState(null);
    const [deckName, setDeckName] = useState("");
    const [createDeck] = useCreateDeckMutation();
    const { t } = useTranslation("common");

    async function saveNewContent() {
        await createDeck({
            name: deckName,
            shortid: shortid.generate(),
            readerType: readerType,
            contest_id: contestId,
            topic_id: topicId,
            sequence: sequence,
            content: "",
        }).unwrap();
        setNewModalOpen(false);
    }

    // ========================= Render Function =================================
    return (
        <Modal
            size="tiny"
            open={newModalOpen}
            onClose={() => setNewModalOpen(false)}
        >
            <Modal.Header>
                {t("builder.contest.contentModalHeader")}
                <Div float-right clickable>
                    <Icon name="times" onClick={() => setNewModalOpen(false)} />
                </Div>
            </Modal.Header>
            <Modal.Content>
                <Div ash basepad>
                    <Div fullht>
                        <Div gapBottom clearfix>
                            <Div fit-content float-left medpad smoke snubbed>
                                {t("builder.contest.contentModalSelect")}:
                            </Div>
                            <Div wd="60%" float-left nudgeLeft>
                                <Dropdown
                                    fluid
                                    selection
                                    search
                                    placeholder={t(
                                        "builder.contest.contentModalSelect"
                                    )}
                                    value={readerType}
                                    options={_.map(deckFormats, (p, idx) => {
                                        return {
                                            key: idx,
                                            value: p.readerType,
                                            text: p.name,
                                        };
                                    })}
                                    onChange={(e, { value }) =>
                                        setReaderType(value)
                                    }
                                />
                            </Div>
                        </Div>
                        <Div gapBottom clear>
                            <Input
                                fluid
                                label={t("builder.dic.name")}
                                value={deckName}
                                onChange={(e, { value }) => setDeckName(value)}
                            />
                        </Div>
                    </Div>
                </Div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    primary
                    content={t("components.save")}
                    onClick={saveNewContent}
                />
            </Modal.Actions>
        </Modal>
    );
}
