import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Input, Divider, Image, Icon, Modal } from "semantic-ui-react";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player/lazy";
import { useFeatures } from "flagged";

import axios from "axios";
import { baseUrl } from "@api/apiV6";

import { Div, Button } from "@components/Generics.react";
import AuthLayout from "@layouts/AuthLayout.react";
import { settings } from "@config/settings/app.settings";
import PreviewLayout from "../../layouts/PreviewLayout.react";

export default function ArticleLanding(props) {
    const { code } = useParams();
    const [regModalOpen, setRegModalOpen] = useState(false);
    const [previewModalOpen, setPreviewModalOpen] = useState(false);

    const [username, setUsername] = useState("");
    const [validUsername, setValidUsername] = useState(false);
    const [unameHelp, setUnameHelp] = useState(false);
    const [fnameHelp, setFnameHelp] = useState(false);
    const [passwordHelp, setPasswordHelp] = useState(false);
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(false);
    const [validPassword, setValidPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    // const [validPassword, setValidPassword] = useState(false);
    const [details, setDetails] = useState({});
    const detailParams = settings.detailParams;
    const features = useFeatures();
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation("common");

    const wPageHt = `${window.innerHeight}px`;
    const isPortrait = window.innerHeight > window.innerWidth;

    useEffect(() => {
        (async () => {
            if (username.length > 3) {
                const response = await axios({
                    method: "get",
                    url: `${baseUrl}api/v3/users/check/${username}`,
                });
                response.data.available
                    ? setValidUsername(true)
                    : setValidUsername(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username]);
    useEffect(() => {
        // eslint-disable-next-line
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
            ? setValidEmail(true)
            : setValidEmail(false);
    }, [email]);

    useEffect(() => {
        // eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
            password
        )
            ? setValidPassword(true)
            : setValidPassword(false);
    }, [password]);

    const tryRegister = async () => {
        const nameSplit = fullName.split(" ");
        let formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);
        formData.append("email", email);
        formData.append("first_name", nameSplit[0]);
        formData.append("last_name", nameSplit[1] || " ");
        formData.append("creatorLicense", true);
        formData.append("details", JSON.stringify(details));
        formData.append(
            "team",
            features.tenanting.multiTenanted
                ? username.replace(/[&\\#, +()$~%.'":*?<>{}]/g, "_")
                : "Company"
        );

        try {
            const signupResponse = await axios({
                method: "post",
                url: `${baseUrl}api/v3/auth/register`,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            });
            if (signupResponse.status === 201) {
                if (features.tenanting.teamCourses) {
                    await axios({
                        method: "post",
                        url: `${baseUrl}api/v3/users/${signupResponse.data._id}/team_enrollment`,
                    });
                }
                navigate("/builder/registered");
            }
        } catch (error) {
            console.log(error);
            setError(error.response.data.message);
        }
    };

    const checkStyle = { float: "right", margin: "-30px 10px 0 0" };

    const registrationBox = (
        <Div fluid flex centered maxWd="480px">
            <Div fluid white fullHt superpad rounded>
                <Div massive altText fluid center-align gutter>
                    SIGN UP
                </Div>
                {error && (
                    <Div padded fluid danger gutter>
                        {error}
                    </Div>
                )}
                <Div fluid gutter>
                    <Input
                        fluid
                        label={t("auth.registration.usernameLabel")}
                        placeholder={t("auth.registration.usernamePlaceholder")}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        onFocus={() => setUnameHelp(true)}
                        onBlur={() => setUnameHelp(false)}
                    />
                    {!validUsername && username?.length > 3 && (
                        <Div relaxed danger fluid>
                            <i className="fa fa-times" /> &nbsp;{" "}
                            {t("auth.registration.usernameUnavailable")}
                        </Div>
                    )}
                    {validUsername && (
                        <Div txtColor="success" style={checkStyle}>
                            <i className="fa fa-check-circle" />
                        </Div>
                    )}
                    {unameHelp && (
                        <Div charcoal padded small italics>
                            {t("auth.registration.usernameHelp")}
                        </Div>
                    )}
                </Div>
                <Div fluid gutter>
                    <Input
                        fluid
                        label={t("auth.registration.nameLabel")}
                        placeholder={t("auth.registration.namePlaceholder")}
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        onFocus={() => setFnameHelp(true)}
                        onBlur={() => setFnameHelp(false)}
                    />
                    {fnameHelp && (
                        <Div charcoal padded small italics>
                            {t("auth.registration.nameHelp")}
                        </Div>
                    )}
                </Div>
                <Div fluid gutter>
                    <Input
                        fluid
                        label="Email"
                        type="email"
                        placeholder="Where can we contact you?"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {!validEmail && email?.length > 0 && (
                        <Div relaxed danger fluid>
                            <i className="fa fa-times" /> &nbsp; Malformed email
                            address.
                        </Div>
                    )}
                    {validEmail && (
                        <Div txtColor="success" style={checkStyle}>
                            <i className="fa fa-check-circle" />
                        </Div>
                    )}
                </Div>
                <Div fluid gutter>
                    <Input
                        fluid
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Input Password"
                        onChange={(e) => setPassword(e.target.value)}
                        icon={
                            <Icon
                                name={showPassword ? "eye slash" : "eye"}
                                link
                                onClick={() => setShowPassword(!showPassword)}
                            />
                        }
                        onFocus={() => setPasswordHelp(true)}
                        onBlur={() => setPasswordHelp(false)}
                    />
                    {passwordHelp && (
                        <Div charcoal padded small italics>
                            {t("auth.registration.passwordHelp")}
                        </Div>
                    )}
                    {!validPassword && password?.length > 0 && (
                        <Div relaxed danger fluid>
                            <i className="fa fa-times" /> &nbsp; Password is
                            invalid
                        </Div>
                    )}
                </Div>
                {settings.functionality.registrationDetailParams && (
                    <Div fluid gutter>
                        <Divider horizontal>User Additional Details</Divider>
                        {_.map(_.keys(detailParams), (dp, idx) => {
                            return (
                                <Div fluid snug key={`detail-param-${idx}`}>
                                    <Input
                                        fluid
                                        label={detailParams[dp].name}
                                        value={details[dp]}
                                        onChange={(e) =>
                                            setDetails({
                                                ...details,
                                                [dp]: e.target.value,
                                            })
                                        }
                                    />
                                </Div>
                            );
                        })}
                    </Div>
                )}
                <Div fluid gutter>
                    <Button
                        fluid
                        primary
                        content="SUBMIT"
                        onClick={tryRegister}
                        disabled={
                            username.length < 3 ||
                            !validUsername ||
                            !validEmail ||
                            password.length < 3
                        }
                    />
                    <Div padTop flex column gapped clickable>
                        {settings.socialauth.length > 0 &&
                            _.map(settings.socialauth, (btn, idx) => {
                                return (
                                    <Image
                                        key={`social-auth-${idx}`}
                                        fluid
                                        src={btn.image}
                                    />
                                );
                            })}
                    </Div>
                    <Divider horizontal>OR</Divider>
                    <Div center-align>
                        <NavLink to={"/login"}>
                            <Button success={+true} content={t("auth.login")} />
                        </NavLink>
                    </Div>
                </Div>
            </Div>
        </Div>
    );

    // ========================= Render Function =================================

    return (
        <AuthLayout title={`Register on ${t("appName")}`} teamLogin={true}>
            <Div
                fluid
                flex
                ht={isPortrait ? "auto" : wPageHt}
                maxWd={isPortrait ? "360px" : "100%"}
                spaceBetween
            >
                <Div
                    wd={isPortrait ? "100%" : "calc(100% - 420px)"}
                    megapad={!isPortrait}
                    superpad={isPortrait}
                    big={!isPortrait}
                >
                    <Div fluid trench>
                        <Div wd="256px" gutter>
                            <Image
                                src={
                                    "/assets/images/configurable/full-logo-inverted.png"
                                }
                            />
                        </Div>
                        <Div large>
                            Digital Worksheets to create your own Learning
                            Academy
                        </Div>
                        <Divider />
                        <ReactPlayer
                            url={
                                "https://youtu.be/ZL1HBgGINYo?si=OfXlaZG7sFLF3N36"
                            }
                            width={isPortrait ? "300px" : "480px"}
                            height={isPortrait ? "170px" : "270px"}
                            controls
                        />
                    </Div>
                    <Div trench big>
                        Register now and get instant access to the digital
                        version of all the worksheets available on the TeachiQuo
                        store. Enjoy free access to all TeachiQuo features and
                        start building your own academy today!
                    </Div>
                    <Divider />
                    <Div
                        flex={!isPortrait}
                        center-align={isPortrait}
                        uppercase
                        bold
                        spaceBetween
                        wd={isPortrait ? "100%" : "400px"}
                    >
                        {isPortrait && (
                            <Div gutter>
                                <Button
                                    label={code}
                                    secondary
                                    fluid
                                    content="View Worksheet"
                                    onClick={() => setPreviewModalOpen(true)}
                                    style={{ width: "100%" }}
                                />
                            </Div>
                        )}
                        <Div gutter={isPortrait}>To share this worksheet:</Div>
                        <Button
                            primary
                            content="Register for free"
                            onClick={() => setRegModalOpen(true)}
                        />
                    </Div>
                </Div>
                {!isPortrait && (
                    <Div
                        wd="420px"
                        fullht
                        flex
                        style={{ alignContent: "flex-end" }}
                    >
                        <PreviewLayout
                            url={`a/${code}?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2Nlc3N0b2tlbiI6InJhdmkuc2luZ2hAcXVvZGVjay5jb20iLCJuYW1lIjoiUmF2aSBTaW5naCJ9.A70ebq4vz3nnFmwaZNoGuWeOvNTilw1I3npTsOtSMys`}
                            hasToken
                        />
                    </Div>
                )}
                <Div
                    basepad
                    charcoal
                    center-align={isPortrait}
                    style={{
                        marginTop: isPortrait ? "-10px" : "-40px",
                        paddingLeft: !isPortrait && "60px",
                    }}
                    fluid
                >
                    Visit us at{" "}
                    <a
                        href="https://teachiquo.com"
                        style={{
                            color: "#ffffff",
                            textDecoration: "underline",
                        }}
                    >
                        https://teachiquo.com
                    </a>{" "}
                    to find out more...
                </Div>
            </Div>
            <Modal
                open={regModalOpen}
                onClose={() => setRegModalOpen(false)}
                size="tiny"
                closeIcon
            >
                <Modal.Content>
                    <Div fluid fullht>
                        {registrationBox}
                    </Div>
                </Modal.Content>
            </Modal>
            <Modal
                open={previewModalOpen}
                onClose={() => setPreviewModalOpen(false)}
                basic
                style={{ height: "90vh", width: "90vw", marginLeft: "-75px" }}
            >
                <Div fluid fullht>
                    <PreviewLayout
                        url={`a/${code}?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2Nlc3N0b2tlbiI6InJhdmkuc2luZ2hAcXVvZGVjay5jb20iLCJuYW1lIjoiUmF2aSBTaW5naCJ9.A70ebq4vz3nnFmwaZNoGuWeOvNTilw1I3npTsOtSMys`}
                        hasToken
                    />
                    <Div style={{ marginLeft: "75px", marginTop: "-15px" }}>
                        <Button
                            fluid
                            danger={+true}
                            content="Close"
                            onClick={() => setPreviewModalOpen(false)}
                        />
                    </Div>
                </Div>
            </Modal>
        </AuthLayout>
    );
}
