import React from "react";
import { Div } from "@components/Generics.react";
import CommentsSection from "@components/CommentsSection.react";
export default function ArticleComments(props) {
    const { article } = props;
    return (
        <Div
            maxHt={`${window.innerHeight - 280}px`}
            autoOverflowY
            rounded
            compact
            gapTop
        >
            <CommentsSection comments={article.comments} />
        </Div>
    );
}
