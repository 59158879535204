import React from "react";
import { PieChart as QPieChart, Pie, Cell, Legend } from "recharts";
import { Div } from "@components/Generics.react";

export default function PieChart(props) {
    const { width = 100, height = 100, data, flatLegend = false } = props;

    const colors = [
        "#47b39c", // Orange Red
        "#ffc154", // Orange
        "#ec6b56", // Teal
        "#800080", // Purple
        "#00FFFF", // Cyan
        "#FF8C00", // Dark Orange
        "#FFD700", // Gold
        "#32CD32", // Lime Green
        "#FF1493", // Deep Pink
        "#FF6347", // Tomato
    ];

    return (
        <Div relative centered fit-content>
            <QPieChart width={width + 150} height={height + 50}>
                <Pie
                    data={data}
                    cx={"50%"}
                    cy={"50%"}
                    innerRadius={width / 2 - width / 4}
                    outerRadius={width / 2}
                    paddingAngle={5}
                    dataKey="value"
                    label
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index]} />
                    ))}
                </Pie>
                {!flatLegend ? (
                    <Legend
                        layout="vertical"
                        verticalAlign="bottom"
                        align="right"
                        height={36}
                    />
                ) : (
                    <Legend />
                )}
            </QPieChart>
        </Div>
    );
}

// Data Format
// -------------------------------
/*
    <PieChart
        width={150}
        height={150}
        data={[
                {
                    "name": "Completed",
                    "value": 19
                },
                {
                    "name": "Started",
                    "value": 37
                },
                {
                    "name": "Not Started",
                    "value": -7
                }
            ]}
    />
*/
