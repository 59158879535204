import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Div } from "@components/Generics.react";
import "@styles/components.scss";
import { gradient } from "@utilities/helpers";
import { settings } from "@config/settings/app.settings";

import { clientAssetsPath } from "../config/api/apiV6";
import CompletionIcon from "./CompletionIcon.react";

export default function CategoryCard(props) {
    const { category, completion } = props;
    const user = useSelector((state) => state.auth.user);
    const navigate = useNavigate();
    const isPortrait = window.innerHeight > window.innerWidth;
    const imageExtensions = ["png", "jpeg", "jpg"];
    const extension = category.image?.split(".").pop().toLowerCase();
    const imageUrl = `${clientAssetsPath}/uploads/images/program/${category._id}.${extension}`;
    // ========================= Render Function =================================
    return (
        <Div
            className="category-card"
            rounded
            smoke
            slightShadow
            noOverflow
            clickable
            onClick={() => navigate(`/programs/${category._id}`)}
        >
            <Div
                className="category-card-img-container"
                style={{
                    backgroundImage: `url(${
                        !imageExtensions.includes(extension)
                            ? "/assets/images/configurable/missing.jpg"
                            : imageUrl
                    })`,
                }}
            />
            <Div
                className="card-content"
                style={{
                    background: gradient(
                        user.branding?.colors?.primary ||
                            settings.colors.primary
                    ),
                    color:
                        user.branding?.colors?.primaryText ||
                        settings.colors.primaryText,
                }}
            >
                <Div padded big>
                    {category.name}
                </Div>
                <Div className="category-progress-radial">
                    <CompletionIcon
                        completed={completion === 100}
                        percentage={completion}
                        size={isPortrait ? 20 : 30}
                    />
                </Div>
            </Div>
        </Div>
    );
}
