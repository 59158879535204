import React from "react";
import "@styles/components.scss";
import "@styles/icomoon.css";
import { primaryStyle, darkStyle } from "@utilities/helpers";

export default function CircularButton(props) {
    const { colors, color, icon, onClick, active } = props;

    return (
        <div
            className={`circular-button ${active ? "active" : ""}`}
            onClick={onClick}
        >
            <div className="circular-ring" style={primaryStyle(colors)} />
            <div
                className="circular-surface"
                style={active ? darkStyle("#666666") : { background: color }}
            >
                <i className={`circular-icon icm ${icon} icon`} />
            </div>
        </div>
    );
}
