export const updateLearner = (currentState, attempt) => {
    const presentState = {
        timeSpent: currentState.timeSpent ?? 0,
        points: currentState.points ?? 0,
        sessions: currentState.sessions ?? 0,
    };
    return {
        timeSpent: presentState.timeSpent + (attempt.timeSpent ?? 0),
        points: presentState.points + (attempt.pointsDelta ?? 0),
        sessions: attempt.addSession
            ? presentState.sessions + 1
            : presentState.sessions,
    };
};
