// Import from NPM
// -------------------------------------
import React, { useState } from "react";
import { Image } from "semantic-ui-react";
import { Div } from "@components/Generics.react";

export default function BuilderHeader(props) {
    const {
        image,
        header,
        description,
        imgWidth = "256px",
        forceImage = false,
    } = props;
    const ImageWithFallback = ({ src, fallbackSrc }) => {
        const [imgSrc, setImgSrc] = useState(src);

        const handleError = () => {
            setImgSrc(fallbackSrc);
        };

        return <Image src={imgSrc} onError={handleError} />;
    };

    // ========================= Render Function =================================
    return (
        <Div medpad white flex snubbed hintShadow fluid flexStart>
            {image && (window.innerHeight < 720 || forceImage) && (
                <Div wd={imgWidth} noOverflow slightShadow>
                    <ImageWithFallback
                        src={image}
                        fallbackSrc={"/assets/images/configurable/missing.jpg"}
                        style={{ width: imgWidth }}
                    />
                </Div>
            )}
            <Div
                wd={`calc(100% - ${
                    image && (window.innerHeight < 720 || forceImage)
                        ? imgWidth
                        : 0
                })`}
            >
                <Div fluid relaxed basepad ash big bold>
                    {header}
                </Div>
                <Div fluid relaxed basepad>
                    {description}
                </Div>
            </Div>
        </Div>
    );
}
