import React, { useState, useEffect, useMemo } from "react";
import { Loader } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useFeatures } from "flagged";
import { useParams, useSearchParams } from "react-router-dom";
import _ from "lodash";
import "@styles/components.scss";

import { MapList, PickedLocations } from "@schemas/programMapSchema";
import MasterLayout from "@layouts/MasterLayout.react";
import { useGetProgramQuery, useGetCourseLibraryQuery } from "@api/apiV6";
import RecommendedArticles from "@components/RecommendedArticles.react";
import FormalProgram from "./panels/FormalProgram.react";
import GamifiedProgram from "./panels/GamifiedProgram.react";

export default function Program(props) {
    const { programId } = useParams();
    const { data: library, isSuccess: librarySuccess } =
        useGetCourseLibraryQuery();
    const { data: program, isSuccess } = useGetProgramQuery(programId);
    const { t } = useTranslation("common");
    const features = useFeatures();
    const hasWrapper = program?.wrapper !== "none" && MapList[program?.wrapper];
    const [activeLoc, setActiveLoc] = useState(null);
    const [search] = useSearchParams();
    const isPreview = search.get("preview") === "true";
    const isPortrait = window.innerHeight > window.innerWidth;

    const filteredNodeList = useMemo(
        () =>
            librarySuccess &&
            _.map(
                _.filter(program?.node_list, (crs) => {
                    _.includes(_.keys(library.courses), crs._id);
                    return (
                        (_.includes(_.keys(library.courses), crs._id) &&
                            crs.launch) ||
                        isPreview
                    );
                }),
                (crs) => library.courses[crs._id]
            ).filter(Boolean),
        [program, librarySuccess, library, isPreview]
    );

    useEffect(() => {
        if (program) {
            updateLocData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [program]);

    function updateLocData() {
        // Set Maximum Locations allowed in a wrapper
        const maxLocations = 12;
        // Pick the appropriate location array [1,3,5] based on the number of locations with content in the course
        const locationMap = PickedLocations[program.node_list.length];

        // Create the location array
        let lData = [];
        if (locationMap) {
            for (let i = 0; i < maxLocations; i++) {
                let mapIndex = locationMap.indexOf(i); // The index of this node amongst the highlighted locations
                if (mapIndex !== -1) {
                    lData.push(program.node_list[mapIndex]);
                } else {
                    // If this location is disabled
                    lData.push(null);
                }
            }
        }
        // setLocData(lData);
        if (activeLoc == null && lData.length > 0) {
            setActiveLoc(0);
        }
    }

    const pageContent = isSuccess ? (
        hasWrapper ? (
            filteredNodeList &&
            filteredNodeList.length > 0 && (
                <GamifiedProgram
                    {...{
                        program: { ...program, node_list: filteredNodeList },
                        library,
                        locData: filteredNodeList,
                    }}
                />
            )
        ) : (
            filteredNodeList &&
            filteredNodeList.length > 0 && (
                <FormalProgram
                    program={{ ...program, node_list: filteredNodeList }}
                    library={library}
                />
            )
        )
    ) : (
        <Loader active inverted>
            Loading Content...
        </Loader>
    );

    // ========================= Render Function =================================
    return (
        <MasterLayout
            title={"Welcome"}
            mainPanel={pageContent}
            rightPanelName={t(`components.recommended`)}
            rightPanel={<RecommendedArticles />}
            startFullScreen={!isPortrait && !features.learningCenter}
        />
    );
}
