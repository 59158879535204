// Import from NPM
// -------------------------------------
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import axios from "axios";
import { baseUrl, useGetCreditsQuery } from "@api/apiV6";

import { Div, HDiv, Button } from "@components/Generics.react";
import {
    Icon,
    Modal,
    Input,
    Dimmer,
    Loader,
    Divider,
    Dropdown,
} from "semantic-ui-react";
import { settings } from "@config/settings/app.settings";

export default function AIGenerator(props) {
    const { modalOpen, handleModalClose, handleSave } = props;
    const token = useSelector((state) => state.auth.token);
    const { data: aiStatus, isSuccess, refetch } = useGetCreditsQuery();

    const { t } = useTranslation("common");

    const [inputValue, setInputValue] = useState("");
    const [lengthValue, setLengthValue] = useState(100);
    const [langValue, setLangValue] = useState("English");
    const [fetching, setFetching] = useState(false);
    const [resultValue, setResultValue] = useState("");

    const creditsNeeded = Math.ceil(lengthValue / 100);

    async function getAIResponse() {
        setFetching(true);
        const response = await axios({
            method: "post",
            url: `${baseUrl}api/v3/ai/generic`,
            data: {
                question: `Give a ${Math.max(
                    lengthValue,
                    10
                )} words article in ${langValue} on ${inputValue} as properly formatted html`,
                cost: creditsNeeded,
            },
            headers: { "access-token": token },
        });
        setResultValue(response.data.response.message.content);
        setFetching(false);
        refetch();
    }

    // ========================= Render Function =================================
    return (
        <Modal
            open={modalOpen}
            onClose={() => handleModalClose(false)}
            size="tiny"
        >
            <Modal.Header>
                <Icon name="magic" />
                Generate content with AI
                <Div float-right clickable>
                    <Icon
                        name="times"
                        onClick={() => handleModalClose(false)}
                    />
                </Div>
            </Modal.Header>
            {isSuccess && (
                <Modal.Content>
                    {!fetching && resultValue === "" && (
                        <Div>
                            <Div gutter>
                                <Input
                                    fluid
                                    type="text"
                                    placeholder="Input your topic..."
                                    label="Tell me about"
                                    labelPosition="left"
                                    value={inputValue}
                                    onChange={(e) =>
                                        setInputValue(e.target.value)
                                    }
                                />
                                <Divider horizontal>with a length of</Divider>
                                <Input
                                    fluid
                                    type="number"
                                    value={lengthValue}
                                    onChange={(e) =>
                                        setLengthValue(e.target.value)
                                    }
                                    label="words"
                                    labelPosition="right"
                                />
                                <Divider horizontal>in language</Divider>
                                <Dropdown
                                    placeholder={t(
                                        "builder.dic.selectLanguage"
                                    )}
                                    fluid
                                    selection
                                    options={_.map(settings.languages, (l) => ({
                                        key: l.code,
                                        text: l.identifier,
                                        value: l.identifier,
                                    }))}
                                    value={langValue}
                                    onChange={(e, { value }) =>
                                        setLangValue(value)
                                    }
                                />
                            </Div>
                            <Button
                                primary
                                onClick={getAIResponse}
                                disabled={aiStatus.credits <= 0}
                            >
                                Generate ({creditsNeeded}{" "}
                                {creditsNeeded === 1 ? "credit" : "credits"})
                            </Button>
                            <Div italics float-right ht="40px" flex>
                                {aiStatus.credits} generative credits left
                            </Div>
                        </Div>
                    )}
                    {fetching && (
                        <Div superpad ivory>
                            <Dimmer active>
                                <Loader>
                                    This will take time. Please be patient...
                                </Loader>
                            </Dimmer>
                        </Div>
                    )}
                    {resultValue !== "" && (
                        <>
                            <Div basepad ivory>
                                <Div bold gutter>
                                    In {Math.max(lengthValue, 10)} words, tell
                                    me about {inputValue} written in $
                                    {langValue}:
                                </Div>
                                <HDiv content={resultValue} />
                            </Div>
                            <Button
                                fluid
                                primary
                                content="Generate again"
                                onClick={() => setResultValue("")}
                            />
                        </>
                    )}
                </Modal.Content>
            )}
            {resultValue !== "" && (
                <Modal.Actions>
                    <Div float-left>
                        <Button
                            danger={+true}
                            onClick={() => handleModalClose(false)}
                            content="Cancel"
                            icon="delete"
                            labelPosition="left"
                        />
                    </Div>
                    <Button
                        primary
                        content="Save"
                        icon="checkmark"
                        labelPosition="right"
                        onClick={() => {
                            handleSave(resultValue);
                            handleModalClose(false);
                        }}
                    />
                </Modal.Actions>
            )}
        </Modal>
    );
}
