import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "@styles/readers.scss";
import { Image } from "semantic-ui-react";
import ReactPlayer from "react-player/lazy";

import { Div, HDiv, Button } from "@components/Generics.react";

export default function WebinarReader(props) {
    const { deck, currentState, recordAttempt } = props;
    const { t } = useTranslation("common");

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentState]);

    const currentDate = new Date();
    const event_date = new Date(deck.content?.date === undefined ? currentDate : deck.content?.date); // Replace this with your event_date
    const localeDate = event_date.toLocaleDateString("en-IN", {
        day: "numeric",
        month: "short",
        year: "numeric",
    });
    const localeTime = event_date.toLocaleTimeString("en-IN", {
        hour12: true,
        hour: "numeric",
        minute: "numeric",
    });

    const linkValid = typeof deck.content?.link === "string";
    const hasVideoLink =
        linkValid &&
        ["youtu.be", "youtube", "vimeo"].some((element) =>
            deck.content?.link.includes(element)
        );

    // ========================= Render Function =================================
    return (
        <Div white rounded center-align centered maxWd="400px">
            {hasVideoLink ? (
                <Div rounded gutter className="video-responsive">
                    <ReactPlayer
                        url={deck.content?.link}
                        className="react-player"
                        width="100%"
                        height="100%"
                        controls
                    />
                </Div>
            ) : (
                <Div rounded noOverflow>
                    <Image src={deck.content?.image} />
                </Div>
            )}
            <Div fluid flex center-aligned column fullht superpad>
                <Div
                    txtColor="#333333"
                    gutter
                    altText
                    uppercase
                    headline
                    padTop
                >
                    {deck.content?.name}
                </Div>
                <Div gutter bold big>
                    {localeDate} at {localeTime}
                </Div>
                <HDiv gutter normal small content={deck.content?.description} />
                {linkValid && !hasVideoLink && (
                    <a
                        href={deck.content?.link}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Button
                            primary
                            content={t("deck.webinar.buttonText")}
                        />
                    </a>
                )}
            </Div>
        </Div>
    );
}
