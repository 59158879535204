import React, { useContext } from "react";
import { SlideContext } from "@layouts/SlideLayout.react";
import { gradient } from "@utilities/helpers";
import { Div } from "@components/Generics.react";

export default function Title(props) {
    const { title } = props.data;
    const { colors } = useContext(SlideContext);

    // ========================= Render Function =================================
    return (
        <Div
            fluid
            fullht
            altText
            headline
            flex
            column
            superpad
            maxHt="450px"
            style={{
                background: gradient(colors?.primary, "to top left", true),
                color: colors?.primaryText,
            }}
        >
            {title}
        </Div>
    );
}
