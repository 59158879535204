import React, { useState } from "react";
import { useGetTeamsListQuery, useDeleteTeamMutation } from "@api/apiV6";
import _ from "lodash";
import ReactTable from "react-table-v6";

import { Div, Button } from "@components/Generics.react";
import { Loader, Icon, Confirm } from "semantic-ui-react";
import AdminTeamDesign from "./AdminTeamDesign.react";
import AddTeamModal from "./AddTeamModal.react";
import AdminTeamEnrolModal from "./AdminTeamEnrolModal.react";
import AdminTeamDetails from "./AdminTeamDetails.react";

export default function AdminTeamManagement(props) {
    const {
        data: teamsList,
        isSuccess,
        isLoading,
        refetch,
    } = useGetTeamsListQuery();
    const [deleteTeam] = useDeleteTeamMutation();

    const [newModalOpen, setNewModalOpen] = useState(false);
    const [enrolModalOpen, setEnrolModalOpen] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [showDesign, setShowDesign] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [targetTeam, setTargetTeam] = useState(null);

    let fullColumns = [
        {
            Header: "Team Name",
            id: "name",
            accessor: "name",
            Cell: ({ row }) => {
                return (
                    <Div
                        txtMain
                        clickable
                        onClick={() =>
                            setSelectedTeam(row._original.identifier)
                        }
                    >
                        {row._original.name}
                    </Div>
                );
            },
        },
        {
            Header: "Identifier",
            id: "identifier",
            accessor: "identifier",
        },
        {
            Header: "Colors",
            id: "colors",
            accessor: "colors",
            Cell: (row) => (
                <div style={{ textAlign: "center" }}>
                    {_.values(row.value).join(", ")}
                </div>
            ),
        },
        {
            Header: "Shareable",
            id: "shareable",
            accessor: "shareable",
            Cell: (row) => (
                <div style={{ textAlign: "center" }}>
                    {row.value ? "Yes" : "No"}
                </div>
            ),
        },
        {
            Header: "Allocated Repos",
            id: "repos",
            accessor: "repos",
            Cell: (row) => (
                <div style={{ textAlign: "center" }}>{row.value}</div>
            ),
        },
        {
            Header: "Actions",
            id: "id",
            accessor: "id",
            Cell: ({ row }) => {
                return (
                    <Div center-align txtDanger clickable>
                        <Icon
                            name="trash"
                            onClick={() => {
                                setTargetTeam(row._original._id);
                                setConfirmDelete(true);
                            }}
                        />
                    </Div>
                );
            },
        },
    ];

    // ========================= Render Function =================================
    if (isLoading || !isSuccess) return <Loader active />;
    else
        return (
            <Div fullht fluid>
                {selectedTeam && showDesign ? (
                    <AdminTeamDesign
                        team={selectedTeam}
                        closePanel={setShowDesign}
                    />
                ) : (
                    <Div fluid fullht flex top-aligned>
                        <Div
                            wd="calc(100% - 360px)"
                            white
                            medpad
                            ht="calc(100% - 123px)"
                        >
                            <Div fluid ash rimmed flex spaceBetween>
                                <Button
                                    size="small"
                                    secondary
                                    icon="refresh"
                                    labelPosition="left"
                                    content={"Refresh Teams List"}
                                    onClick={refetch}
                                />
                                <Div>
                                    <Button
                                        size="small"
                                        primary
                                        icon="plus"
                                        labelPosition="left"
                                        content={"Create New Team"}
                                        onClick={() => setNewModalOpen(true)}
                                    />
                                    <Button
                                        size="small"
                                        secondary
                                        icon="graduation cap"
                                        labelPosition="left"
                                        content={"Enrol Team"}
                                        onClick={() => setEnrolModalOpen(true)}
                                    />
                                </Div>
                            </Div>
                            <ReactTable
                                data={teamsList}
                                filterable
                                defaultFilterMethod={(filter, row, column) => {
                                    const id = filter.pivotId || filter.id;
                                    return row[id] !== undefined
                                        ? String(row[id])
                                              .toLowerCase()
                                              .includes(
                                                  filter.value.toLowerCase()
                                              )
                                        : true;
                                }}
                                columns={fullColumns}
                                pageSizeOptions={[5, 8]}
                                defaultPageSize={8}
                                className="-striped -highlight"
                            />
                        </Div>
                        <Div wd="360px" white medpad ht="calc(100% - 123px)">
                            {selectedTeam ? (
                                <AdminTeamDetails
                                    setShowDesign={setShowDesign}
                                    selectedTeam={selectedTeam}
                                />
                            ) : (
                                <Div fullht basepad vapor flex>
                                    Click a team name in the table to view team
                                    members
                                </Div>
                            )}
                        </Div>
                    </Div>
                )}
                <Confirm
                    open={confirmDelete}
                    onCancel={() => setConfirmDelete(false)}
                    onConfirm={() => {
                        deleteTeam(targetTeam);
                        setConfirmDelete(false);
                    }}
                />
                <AddTeamModal isOpen={newModalOpen} setOpen={setNewModalOpen} />
                <AdminTeamEnrolModal
                    isOpen={enrolModalOpen}
                    setOpen={setEnrolModalOpen}
                    teams={teamsList}
                />
            </Div>
        );
}
