import React, { useState } from "react";
import { useGetArticleInventoryQuery } from "@api/apiV6";
import _ from "lodash";

import { Dropdown } from "semantic-ui-react";
import { Div } from "@components/Generics.react";
import ArticleDashboard from "../../creator/analytics/ArticleDashboard.react";
export default function AnalyticsArticles(props) {
    const [selectedArticle, setSelectedArticle] = useState(null);
    const { data: articles, isSuccess } = useGetArticleInventoryQuery();
    // ========================= Render Function =================================
    return (
        <Div basepad ash fullht>
            {isSuccess && (
                <Dropdown
                    placeholder={"Select an article"}
                    fluid
                    selection
                    search
                    options={_.map(articles, (l) => ({
                        key: l._id,
                        text: l.name,
                        value: l._id,
                    }))}
                    value={selectedArticle}
                    onChange={(e, { value }) => setSelectedArticle(value)}
                />
            )}
            <br />
            {selectedArticle && (
                <ArticleDashboard article={{ _id: selectedArticle }} />
            )}
        </Div>
    );
}
