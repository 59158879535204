// Import from NPM
// -------------------------------------
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { SlideContext } from "@layouts/SlideLayout.react";
import {
    AbsWrapper,
    Div,
    HDiv,
    Image,
    Button,
} from "@components/Generics.react";
import { primaryStyle } from "@utilities/helpers";
import { settings } from "@config/settings/app.settings";

export default function ResponseOverlay(props) {
    const { feedback, correct, closeResponse } = props;
    const { colors } = useContext(SlideContext);
    const { t } = useTranslation("common");

    // ========================= Render Function =================================
    return (
        <AbsWrapper zoom layer={2} center-aligned>
            <Div pageht fluid basepad relative style={primaryStyle(colors)}>
                <Div
                    large
                    altText
                    topRounded
                    relaxed
                    success={correct}
                    danger={!correct}
                >
                    {correct
                        ? t("deck.response.correct")
                        : t("deck.response.incorrect")}
                </Div>
                {!settings.functionality.htmlFeedback && (
                    <Div noOverflow>
                        <Image
                            fluid
                            src={
                                correct
                                    ? t("deck.response.correctImg")
                                    : t("deck.response.incorrectImg")
                            }
                        />
                    </Div>
                )}
                {settings.functionality.htmlFeedback ? (
                    <HDiv
                        fluid
                        ivory
                        bottomRounded
                        basepad
                        content={feedback[correct ? 0 : 1]}
                        className="html-feedback"
                    />
                ) : (
                    <Div superpad ivory bottomRounded>
                        {feedback[correct ? 0 : 1]}
                    </Div>
                )}
                <br />
                <Button primary onClick={closeResponse}>
                    {t("deck.continue")}
                </Button>
            </Div>
        </AbsWrapper>
    );
}
