// Import from NPM
// -------------------------------------
import React, { useCallback, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import update from "immutability-helper";
import _ from "lodash";
import { Div, Icon } from "@components/Generics.react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TemplateList } from "@schemas/templateSchema";

// Import from Config
// -------------------------------------

// Import Components
// -------------------------------------

export default function SlideSorter(props) {
    const { deck, setDeck, slideNum, setSlideNum } = props;
    const [cards, setCards] = useState(deck.content);
    const { t } = useTranslation("common");

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (cards !== deck.content) {
                const newContent = cards.map((item, index) => ({
                    ...item,
                    slideSeq: index,
                }));
                setDeck({ ...deck, ...{ content: newContent } });
            }
        }, 1500);
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cards, deck]);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setCards((prevCards) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex]],
                ],
            })
        );
    }, []);

    // ========================= Render Function =================================
    return (
        <Div
            minWd="400px"
            wd="400px"
            fullht
            relative
            peat
            ht={`calc(100vh - 225px)`}
            basepad
            darkInset
        >
            <Div white fullht rounded basepad autoOverflowY dropShadow>
                <Div bold large compact>
                    {t("builder.deck.sorter")}:
                </Div>
                <Div italics gutter compact>
                    {t("builder.deck.sorterHelp")}
                </Div>
                <DndProvider backend={HTML5Backend}>
                    {_.map(cards, (item, index) => {
                        return (
                            <Card
                                key={`${deck._id}-${item.slideSeq}`}
                                index={index}
                                active={index === slideNum}
                                id={item.slideSeq}
                                text={
                                    item.data.title && item.data.title !== ""
                                        ? item.data.title
                                        : item.data.question &&
                                          item.data.question !== ""
                                        ? item.data.question
                                        : TemplateList[item.template].name +
                                          " Slide"
                                }
                                moveCard={moveCard}
                                setSlideNum={setSlideNum}
                            />
                        );
                    })}
                </DndProvider>
            </Div>
        </Div>
    );
}

const Card = ({ id, text, index, moveCard, active, setSlideNum }) => {
    const ref = useRef(null);
    const [{ handlerId }, drop] = useDrop({
        accept: "card",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: "card",
        item: () => {
            return { id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));
    return (
        <div
            ref={ref}
            data-handler-id={handlerId}
            style={{ cursor: "move", opacity: opacity, margin: "2px" }}
        >
            <Div
                ash={!active}
                half={active}
                slightShadow
                onClick={() => setSlideNum(index)}
            >
                <Div
                    inlineBlock
                    primary={!active}
                    midnight={active}
                    padded
                    style={{ verticalAlign: "middle" }}
                >
                    <Icon name="list" />
                </Div>
                <Div inlineBlock compact>
                    {text}
                </Div>
            </Div>
        </div>
    );
};
