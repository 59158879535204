import { Image } from "semantic-ui-react";
import { Div } from "@components/Generics.react";
import { useSelector } from "react-redux";
import RadialProgressChart from "@components/charts/RadialProgressChart.react";

import "@styles/components.scss";
import "@styles/icomoon.css";

export default function CompletionIcon(props) {
    const { completed, icon, percentage, size } = props;
    const user = useSelector((state) => state.auth.user);

    // ========================= Render Function =================================
    return (
        <Div inline>
            {completed ? (
                <Image
                    src="/assets/images/configurable/completeIcon.png"
                    style={{ width: `${size || 24}px` }}
                />
            ) : icon ? (
                <i className={`icon icm icon-${icon}`} />
            ) : (
                <RadialProgressChart
                    value={percentage}
                    width={size ? size : 25}
                    height={size ? size : 25}
                    color={user.branding?.colors?.main}
                    noText
                />
            )}
        </Div>
    );
}
