import React, { useContext } from "react";
import _ from "lodash";
import { Div, Image } from "@components/Generics.react";
import { clientAssetsPath } from "@api/apiV6";
import { LayoutContext } from "@layouts/MasterLayout.react";

export default function ImageCaption(props) {
    const { caption, image } = props.data;
    const { fullScreen } = useContext(LayoutContext);
    const isPortrait = window.innerHeight > window.innerWidth || !fullScreen;

    const img =
        image && !_.startsWith(image, "data:image")
            ? `${clientAssetsPath}/uploads/qdf/slides/${image.id}${image.extension}`
            : image;

    if (isPortrait)
        return (
            <Div fluid zoom>
                <Image fluid src={img} />
                {caption && (
                    <Div superpad ash big>
                        {caption}
                    </Div>
                )}
            </Div>
        );
    else
        return (
            <Div fluid flex flexStart>
                <Div wd="50%" zoom slightShadow rounded noOverflow>
                    <Image fluid src={img} />
                </Div>
                {caption && (
                    <Div wd="50%" superpad ash big>
                        {caption}
                    </Div>
                )}
            </Div>
        );
}
